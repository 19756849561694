/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, PasswordField } from "@aws-amplify/ui-react";
export default function PasswordFieldA(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="0"
      direction="column"
      position="relative"
      padding="16px 16px 0px 16px"
      {...rest}
      {...getOverrideProps(overrides, "PasswordFieldA")}
    >
      <PasswordField
        display="flex"
        gap="8px"
        direction="column"
        width="343px"
        height="62px"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        size="small"
        isDisabled={false}
        labelHidden={false}
        variation="default"
        hideShowPassword={false}
        {...getOverrideProps(overrides, "PasswordField")}
      ></PasswordField>
    </Flex>
  );
}
