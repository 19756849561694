import { ListTwolineATextButton } from "../ui-components";
import { isMobile } from "react-device-detect";
export default function (props) {
  let formatedData = props.value;
  if (props.type == "date") {
    const splitedNengetsu = props?.value?.split("-");
    formatedData = splitedNengetsu ? splitedNengetsu[0] + "年" + parseInt(splitedNengetsu[1]) + "月" + parseInt(splitedNengetsu[2]) + "日" : "";
  }
  if (props.isEmptyHide && !props.value) {
    return <></>;
  } else {
    if (isMobile) {
      return (
        <ListTwolineATextButton
          width={"auto"}
          overrides={{
            "Flex.Flex[0].Text[0]": {
              children: props.label,
            },
            "Flex.Flex[0].Text[1]": {
              children: formatedData,
              className: "text-overflow-ellipsis",
              maxWidth: "calc(100% - 20px)",
              whiteSpace: "pre",
              overflow: "hidden",
              display: "block"
            },
            "Flex.TextButton[0]": {
              className: "pointer",
              onClick: props.onClick,
              overrides: {
                "Flex.Text[0]": {
                  children: props.buttonLabel,
                },
              },
            },
          }}
        />
      );
    } else {
      return (
        <ListTwolineATextButton
          className="pccomponent"
          margin={"auto"}
          width={"auto"}
          overrides={{
            "Flex.Flex[0].Text[0]": {
              children: props.label,
            },
            "Flex.Flex[0].Text[1]": {
              children: formatedData,
            },
            "Flex.TextButton[0]": {
              className: "pointer",
              onClick: props.onClick,
              overrides: {
                "Flex.Text[0]": {
                  children: props.buttonLabel,
                },
              },
            },
          }}
        />
      );
    }
  }
}
