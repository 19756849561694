import { Text } from "@aws-amplify/ui-react";
import { isMobile } from "react-device-detect";
import { ListTwolineA } from "../ui-components";

export default function ListTwoLineButton(props) {
  return (
    <div
      style={{
        display: "flex",
        maxWidth: isMobile ? "100%" : "960px",
        margin: "auto",
        width: "100%",
        alignItems: "center",
        justifyContent: "flex-start",
        paddingRight: "16px",
        ...props,
      }}
    >
      <ListTwolineA
        width={"fit-content"}
        padding={isMobile ? "7px 16px 12px 16px" : "0"}
        marginRight="10px"
        overrides={{
          "Flex.Flex[0].Text[0]": {
            children: props.label,
            width: "fit-content",
          },
          "Flex.Flex[0].Text[1]": {
            children: props.value,
            textAlign: "justify",
            className: "word-break",
            width: "fit-content",
            fontSize: isMobile ? "14px" : "16px",
          },
        }}
      />
      <div
        style={{
          display: "flex",
          gap: isMobile ? "22.25px" : "32.25px",
        }}
      >
        {props?.buttons.map((ele, idx) => {
          return (
            <div
              key={idx}
              className="pointer"
              style={{
                display: "flex",
                alignItems: "center",
              }}
              onClick={ele?.onClick}
            >
              {ele.icon}
              <Text
                fontSize={isMobile ? "14px" : "16px"}
                marginLeft="10.25px"
                color="var(--my-theme-color)"
                fontWeight="700"
              >
                {ele.label}
              </Text>
            </div>
          );
        })}
      </div>
    </div>
  );
}
