import React from "react";
import { isMobile } from "react-device-detect";
import { PCSelectField, SelectFieldA } from "../ui-components";

export default function (props) {
  const children = props.options ? (
    <React.Fragment>{props.options}</React.Fragment>
  ) : (
    props.children
  );
  if (isMobile) {
    return (
      <SelectFieldA
        {...props?.overrideStyle}
        width={"auto"}
        overrides={{
          "Flex.SelectField[0]": {
            ...props,
            height: "auto",
            width: "auto",
            hasError: !!props.errorMessage,
            label: props.isRequired ? (
              <div>
                {props.label}
                <font className="required">※</font>
              </div>
            ) : (
              props.label
            ),
            children: children,
            options: null,
          },
        }}
      />
    );
  } else {
    return (
      <PCSelectField
        {...props?. overrideStyle}
        className={props.className ? props.className : "pccomponent"}
        margin="0px auto 16px auto"
        overrides={{
          "Flex.SelectFieldA[0]": {
            overrides: {
              "Flex.SelectField[0]": {
                ...props,
                height: "auto",
                width: "auto",
                style: {fontSize: "16px"},
                hasError: !!props.errorMessage,
                label: (
                  <div style={{ fontSize: "16px" }}>
                    {props.isRequired ? (
                      <>
                        {props.label}
                        <font className="required">※</font>
                      </>
                    ) : (
                      props.label
                    )}
                  </div>
                ),
                children: children,
                options: null,
              },
            },
          },
        }}
      />
    );
  }
}
