import { isMobile } from "react-device-detect";
import { PCTextField, TextFieldA } from "../ui-components";

export default function (props) {
  if (isMobile) {
    return (
      <TextFieldA
        {...props?. overrideStyle}
        overrides={{
          "Flex.TextField[0]": {
            isMultiline: true,
            maxLength: 400,
            ...props,
            width: "auto",
            hasError: !!props.errorMessage,
            whiteSpace: "break-spaces",
            label: props.isRequired ? (
              <div>
                {props.label}
                <font className="required">※</font>
              </div>
            ) : (
              props.label
            ),
          },
        }}
      />
    );
  } else {
    return (
      <PCTextField
        {...props?. overrideStyle}
        className={props.className ? props.className : "pccomponent"}
        margin="0px auto 16px auto"
        overrides={{
          "Flex.TextFieldA[0]": {
            overrides: {
              "Flex.TextField[0]": {
                isMultiline: true,
                maxLength: 400,
                ...props,
                width: "auto",
                fontSize: "16px",
                hasError: !!props.errorMessage,
                label: (
                  <div style={{ fontSize: "16px" }}>
                    {props.isRequired ? (
                      <>
                        {props.label}
                        <font className="required">※</font>
                      </>
                    ) : (
                      props.label
                    )}
                  </div>
                ),
                whiteSpace: "break-spaces",
              },
            },
          },
        }}
      />
    );
  }
}
/*
.ListSingleLineA {
  margin: 16px;
}
.TextFieldA input {
  width: 100% !important;
  height: auto !important;
}
.TextFieldA textarea {
  width: 100% !important;
  height: auto !important;
}
.SelectFieldA {
  width: 100% !important;
  height: auto !important;
}
*/
