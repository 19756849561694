/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Image, Text, View } from "@aws-amplify/ui-react";
export default function PCCardData(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="528px"
      height="300px"
      position="relative"
      boxShadow="3px 3px 6px rgba(0, 0, 0, 0.1599999964237213)"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "PCCardData")}
    >
      <Flex
        gap="10px"
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        direction="row"
        width="528px"
        height="300px"
        alignItems="flex-start"
        borderRadius="3px"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "Back")}
      >
        <Flex
          gap="0"
          direction="row"
          width="fit-content"
          alignItems="flex-start"
          shrink="0"
          height="100px"
          overflow="hidden"
          position="relative"
          borderRadius="3px"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "Icon")}
        >
          <Image
            width="100px"
            height="100px"
            shrink="0"
            position="relative"
            borderRadius="3px 0px 0px 0px"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Data")}
          ></Image>
        </Flex>
      </Flex>
      <Flex
        gap="20px"
        position="absolute"
        top="0%"
        bottom="0%"
        left="calc(50% - 264px - 0px)"
        direction="column"
        width="528px"
        height="300px"
        padding="28px 20px 25px 20px"
        {...getOverrideProps(overrides, "Content")}
      >
        <Flex
          gap="29px"
          direction="column"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Text")}
        >
          <Flex
            gap="5px"
            direction="column"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Title")}
          >
            <Text
              fontFamily="Zen Kaku Gothic New"
              fontSize="28px"
              fontWeight="500"
              color="rgba(28,26,29,1)"
              lineHeight="41px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="私のデータ"
              {...getOverrideProps(overrides, "\u79C1\u306E\u30C7\u30FC\u30BF")}
            ></Text>
            <Text
              fontFamily="Zen Kaku Gothic New"
              fontSize="15px"
              fontWeight="500"
              color="rgba(28,26,29,1)"
              lineHeight="33px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              shrink="0"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="MY DATA"
              {...getOverrideProps(overrides, "MY DATA")}
            ></Text>
          </Flex>
          <Text
            fontFamily="Zen Kaku Gothic New"
            fontSize="17px"
            fontWeight="500"
            color="rgba(28,26,29,1)"
            lineHeight="31px"
            textAlign="left"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="金融資産、保険、友人の連絡先、スマホやPCの情報、ペットのことなどを整理しておくことができます。“もしもの時”に自分自身や家族がとても助かります。"
            {...getOverrideProps(
              overrides,
              "\u91D1\u878D\u8CC7\u7523\u3001\u4FDD\u967A\u3001\u53CB\u4EBA\u306E\u9023\u7D61\u5148\u3001\u30B9\u30DE\u30DB\u3084PC\u306E\u60C5\u5831\u3001\u30DA\u30C3\u30C8\u306E\u3053\u3068\u306A\u3069\u3092\u6574\u7406\u3057\u3066\u304A\u304F\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002\u201C\u3082\u3057\u3082\u306E\u6642\u201D\u306B\u81EA\u5206\u81EA\u8EAB\u3084\u5BB6\u65CF\u304C\u3068\u3066\u3082\u52A9\u304B\u308A\u307E\u3059\u3002"
            )}
          ></Text>
        </Flex>
        <Flex
          gap="10px"
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 5px 0px 0px"
          {...getOverrideProps(overrides, "Line")}
        >
          <Image
            width="27px"
            height="27px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Next")}
          ></Image>
        </Flex>
      </Flex>
    </View>
  );
}
