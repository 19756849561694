/* eslint-disable import/no-anonymous-default-export */
import { isMobile } from "react-device-detect";
import {
  CategorySwapper,
  LabelM,
  ListSingleLineA,
  ListSingleLineTwoColum,
  MessageButton,
} from "../page-components";
import { getLabel, useInitializePage } from "../utils/CommonUtils";

export default function () {
  const navigate = useInitializePage("lifepast");

  return (
    <div>
      {isMobile ? (
        <div style={{ marginBottom: "94px" }}>
          <LabelM label={getLabel("lifepast")} />
          <ListSingleLineA
            onClick={() => navigate("history")}
            label={getLabel("jibunshi")}
          />
          <ListSingleLineA
            onClick={() => navigate("collections")}
            label={getLabel("takaramonolistcollection")}
          />
          <ListSingleLineA
            onClick={() => navigate("familytree")}
            label={getLabel("kakeizu")}
          />
        </div>
      ) : (
        <>
          <div className="swapper">
            <LabelM label={getLabel("lifepast")} isDivide />
            <CategorySwapper>
              <ListSingleLineTwoColum
                onClick={() => navigate("history")}
                label={getLabel("jibunshi")}
              />
              <ListSingleLineTwoColum
                onClick={() => navigate("collections")}
                label={getLabel("takaramonolistcollection")}
              />
              <ListSingleLineTwoColum
                onClick={() => navigate("familytree")}
                label={getLabel("kakeizu")}
              />
            </CategorySwapper>
          </div>
        </>
      )}
      <MessageButton
        onClick={() => {
          window.gtag("event", "ContactHistory", {
            event_category: "Contact",
            event_label: "相談大切なもの",
          });
        }}
      />
    </div>
  );
}
