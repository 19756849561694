/* eslint-disable import/no-anonymous-default-export */
import { Flex } from "@aws-amplify/ui-react";
import { DataStore } from "aws-amplify";
import { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import UserContext from "../UserContext";
import { withNonScrollBody } from "../hooks";
import { LeavesSougi, LeavesUsers, LeavesYuujinChijin } from "../models";
import {
  Card,
  Description,
  FABEdit,
  FABEditShare,
  FABShare,
  LabelM,
  ListSingleLineCEdit,
  ListTwolineA
} from "../page-components";
import {
  getLabel,
  isEmptySougi,
  useInitializePage,
} from "../utils/CommonUtils";
import { isShare, queryData, queryDatas } from "../utils/ShareUtils";

/*
  sougiyouhi: String
  kibousougikeishiki: String
  kibousuruhuniki: String
  hiyoukyoshutsu: String
  hokenkyousainado: String
  bikou: String
*/
export default withNonScrollBody(function () {
  const navigate = useInitializePage("souginitsuite", "title.detail");
  const [data, setData] = useState();
  const [souginiyondehoshiyuujinchijin, setSouginiyondehoshiyuujinchijin] =
    useState();
  const [
    souginiyobukakazokuniitininsuruyuujinchijin,
    setSouginiyobukakazokuniitininsuruyuujinchijin,
  ] = useState();

  const [isEmptyData, setIsEmptyData] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const params = useParams();

  const context = useContext(UserContext);

  const eventTracking = () => {
    if (isEmptyData) {
      window.gtag("event", "FirstWrite", {
        event_category: "Edit",
        event_label: "葬儀について書込開始",
      });
    } else {
      window.gtag("event", "Change", {
        event_category: "Edit",
        event_label: "葬儀について変更開始",
      });
    }
  };

  useEffect(() => {
    const getData = async () => {
      const data = await queryData(params, LeavesSougi);
      console.log("data", data);
      if (data.length > 0) {
        setData(data[0]);
        setIsEmptyData(isEmptySougi(data[0]));
        if (!context.leavesUser.leavesUsersLeavesSougiId) {
          DataStore.save(
            LeavesUsers.copyOf(context.leavesUser, (updated) => {
              updated.leavesUsersLeavesSougiId = data[0].id;
            }),
          );
        }
      } else {
        const newData = await DataStore.save(
          new LeavesSougi({
            sougiyouhi: "",
            kibousougikeishiki: "",
            souginosyurui: "",
            sankaninzuukibo: "",
            kibousuruhuniki: "",
            junbishiteiruhiyou: "",
            hiyoukyoshutsu: "",
            hokenkyousainado: "",
            yondehoshiihitonituite: "",
            itininsuruhitonituite: "",
            souginoannaiwoshinakutemoyoihitonituite: "",
            bikou: "",
          }),
        );
        DataStore.save(
          LeavesUsers.copyOf(context.leavesUser, (updated) => {
            updated.leavesUsersLeavesSougiId = newData.id;
          }),
        );
      }
      if (data[0].souginiyondehoshiyuujinchijin) {
        const friends = await queryDatas(params, LeavesYuujinChijin);
        setSouginiyondehoshiyuujinchijin(
          friends
            .filter(
              (f) => data[0].souginiyondehoshiyuujinchijin.indexOf(f.id) >= 0,
            )
            .map((f) => f.shimei)
            .join("、"),
        );
      }
      if (data[0].souginiyobukakazokuniitininsuruyuujinchijin) {
        const friends = await queryDatas(params, LeavesYuujinChijin);
        setSouginiyobukakazokuniitininsuruyuujinchijin(
          friends
            .filter(
              (f) =>
                data[0].souginiyobukakazokuniitininsuruyuujinchijin.indexOf(
                  f.id,
                ) >= 0,
            )
            .map((f) => f.shimei)
            .join("、"),
        );
      }
      setIsLoaded(true);
    };
    getData();
  }, [context.leavesUser]);

  return (
    <div>
      {isMobile ? (
        <>
          <LabelM label={getLabel("souginitsuite")} />

          {isEmptyData && isLoaded ? (
            isShare(params) ? (
              <Description text={getLabel("shared.des")} />
            ) : (
              <Flex marginBottom="32px">
                <Card
                  type="will"
                  title={getLabel(`souginitsuite`)}
                  titleEn={getLabel(`souginitsuite.en`)}
                  mainDescription={getLabel(`souginitsuite.des`)}
                />
              </Flex>
            )
          ) : (
            isLoaded && (
              <>
                <ListTwolineA
                  label={getLabel("sougiyouhi")}
                  value={data?.sougiyouhi}
                />
                <ListTwolineA
                  label={getLabel("kibousougikeishiki")}
                  value={data?.kibousougikeishiki}
                />
                <ListTwolineA
                  label={getLabel("souginosyurui")}
                  value={data?.souginosyurui}
                />
                <ListTwolineA
                  label={getLabel("sankaninzuukibo")}
                  value={data?.sankaninzuukibo}
                />
                <ListTwolineA
                  label={getLabel("kibousuruhuniki")}
                  value={data?.kibousuruhuniki}
                />
                <ListTwolineA
                  label={getLabel("junbishiteiruhiyou")}
                  value={data?.junbishiteiruhiyou}
                />
                <ListTwolineA
                  label={getLabel("hiyoukyoshutsu")}
                  value={data?.hiyoukyoshutsu}
                />
                <ListTwolineA
                  label={getLabel("hokenkyousainado")}
                  value={data?.hokenkyousainado}
                />
                <ListTwolineA
                  label={getLabel("souginiyondehoshiyuujinchijin")}
                  value={souginiyondehoshiyuujinchijin}
                />
                <ListTwolineA
                  label={getLabel("yondehoshiihitonituite")}
                  value={data?.yondehoshiihitonituite}
                />
                <ListTwolineA
                  label={getLabel(
                    "souginiyobukakazokuniitininsuruyuujinchijin",
                  )}
                  value={souginiyobukakazokuniitininsuruyuujinchijin}
                />
                <ListTwolineA
                  label={getLabel("itininsuruhitonituite")}
                  value={data?.itininsuruhitonituite}
                />
                <ListTwolineA
                  label={getLabel("souginoannaiwoshinakutemoyoihitonituite")}
                  value={data?.souginoannaiwoshinakutemoyoihitonituite}
                />
                <ListTwolineA label={getLabel("bikou")} value={data?.bikou} />
              </>
            )
          )}

          {!isShare(params) && (
            <FABEditShare
             onShare={() => {
               navigate(`share`);
             }}
             onEdit={() => {
               navigate("edit");
               eventTracking();
             }}
            />
          )}
        </>
      ) : (
        <div className="swapper">
          <LabelM label={getLabel("souginitsuite")} isDivide isShareBtn={!isShare(params)} isNonList/>
          {isEmptyData && isLoaded && (
            <Description
              text={
                isShare(params)
                  ? getLabel(`shared.des`)
                  : getLabel(`souginitsuite.des`)
              }
              paddingBottom="0"
            />
          )}
          <div
            className={`hide-scroll content-swapper ${
              isEmptyData && "min-height-0"
            }`}
            style={{ overflow: "auto", maxHeight: "calc(100vh - 411px)" }}
          >
            <ListSingleLineCEdit
              isHideEdit={isShare(params)}
              list={
                !isEmptyData && [
                  {
                    label: getLabel("sougiyouhi"),
                    value: data?.sougiyouhi,
                  },
                  {
                    label: getLabel("kibousougikeishiki"),
                    value: data?.kibousougikeishiki,
                  },
                  {
                    label: getLabel("souginosyurui"),
                    value: data?.souginosyurui,
                  },
                  {
                    label: getLabel("sankaninzuukibo"),
                    value: data?.sankaninzuukibo,
                  },
                  {
                    label: getLabel("kibousuruhuniki"),
                    value: data?.kibousuruhuniki,
                  },
                  {
                    label: getLabel("junbishiteiruhiyou"),
                    value: data?.junbishiteiruhiyou,
                  },
                  {
                    label: getLabel("hiyoukyoshutsu"),
                    value: data?.hiyoukyoshutsu,
                  },
                  {
                    label: getLabel("hokenkyousainado"),
                    value: data?.hokenkyousainado,
                  },
                  {
                    label: getLabel("souginiyondehoshiyuujinchijin"),
                    value: souginiyondehoshiyuujinchijin,
                  },
                  {
                    label: getLabel("yondehoshiihitonituite"),
                    value: data?.yondehoshiihitonituite,
                  },
                  {
                    label: getLabel(
                      "souginiyobukakazokuniitininsuruyuujinchijin",
                    ),
                    value: souginiyobukakazokuniitininsuruyuujinchijin,
                  },
                  {
                    label: getLabel("itininsuruhitonituite"),
                    value: data?.itininsuruhitonituite,
                  },
                  {
                    label: getLabel("souginoannaiwoshinakutemoyoihitonituite"),
                    value: data?.souginoannaiwoshinakutemoyoihitonituite,
                  },
                  {
                    label: getLabel("bikou"),
                    value: data?.bikou,
                  },
                ]
              }
              editOnClick={() => {
                navigate("edit");
                eventTracking();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
});
