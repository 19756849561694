import { isMobile } from "react-device-detect";
import { PasswordFieldA } from "../ui-components";

export default function (props) {
  if (isMobile) {
    return (
      <PasswordFieldA
        margin="0px auto 16px auto"
        padding="0px 16px"
        overrides={{
          PasswordField: {
            padding: "initial !important",
            height: "auto",
            width: "auto",
            ...props,
            maxLength: 100,
            errorMessage: props.errorMessage,
            hasError: !!props.errorMessage,
            label: props.isRequired ? (
              <div>
                {props.label}
                <font className="required">※</font>
              </div>
            ) : (
              props.label
            ),
          },
        }}
      />
    );
  } else {
    return (
      <PasswordFieldA
        margin="0px auto 0px auto"
        padding="initial !important"
        overrides={{
          PasswordField: {
            className: "pccomponent passwordField",
            padding: "initial !important",
            height: "auto",
            fontSize: "16px",
            ...props,
            maxLength: 100,
            errorMessage: props.errorMessage,
            hasError: !!props.errorMessage,
            label: (
              <div style={{ fontSize: "16px" }}>
                {props.isRequired ? (
                  <>
                    {props.label}
                    <font className="required">※</font>
                  </>
                ) : (
                  props.label
                )}
              </div>
            ),
          },
        }}
      />
    );
  }
}
