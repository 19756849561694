import { getLabel } from "../utils/CommonUtils";
import FABDeleteEdit from "./FABDeleteEdit";
import LabelM from "./LabelM";
import ListTwolineA from "./ListTwolineA";
import StrageImage from "./StrageImage";

export default function ListViewDetail(props) {
  const { label, list, isHideEdit, onDelete, onEdit, isChallengeCate } = props;

  return (
    <div>
      <LabelM label={label} />
      {list.map((ele, index) => {
        return ele.type === "image" ? (
          ele.value && <StrageImage key={index} src={ele.value} isSmall={ele.isSmall} />
        ) : (
          <ListTwolineA
            key={index}
            isEmptyHide
            label={getLabel(ele.label)}
            value={ele.value}
            isMulti={ele.isMulti}
            type={ele.type}
          />
        );
      })}
      {(!isHideEdit && !isChallengeCate) && (
        <FABDeleteEdit onDelete={onDelete} onEdit={onEdit}/>
      )}
    </div>
  );
}
