/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Image, Text } from "@aws-amplify/ui-react";
export default function PCCardDo(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="12px"
      direction="column"
      width="528px"
      height="200px"
      alignItems="center"
      position="relative"
      borderRadius="3px"
      padding="32px 0px 22px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "PCCardDo")}
    >
      <Flex
        gap="10px"
        direction="row"
        width="fit-content"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Icon")}
      >
        <Image
          width="50px"
          height="50px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Do")}
        ></Image>
      </Flex>
      <Flex
        gap="2px"
        direction="column"
        height="84px"
        alignItems="center"
        grow="1"
        basis="84px"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Text")}
      >
        <Text
          fontFamily="Zen Kaku Gothic New"
          fontSize="17px"
          fontWeight="500"
          color="rgba(28,26,29,1)"
          lineHeight="31px"
          textAlign="center"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="人生でやりたい"
          {...getOverrideProps(
            overrides,
            "\u6B7B\u306C\u307E\u3067\u306B\u3084\u308A\u305F\u3044"
          )}
        ></Text>
        <Flex
          gap="0"
          direction="row"
          height="51px"
          alignItems="flex-end"
          grow="1"
          basis="51px"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "TextBold")}
        >
          <Text
            fontFamily="Zen Kaku Gothic New"
            fontSize="50px"
            fontWeight="500"
            color="rgba(28,26,29,1)"
            lineHeight="56px"
            textAlign="center"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="10"
            {...getOverrideProps(overrides, "10")}
          ></Text>
          <Text
            fontFamily="Zen Kaku Gothic New"
            fontSize="26px"
            fontWeight="500"
            color="rgba(0,0,0,1)"
            lineHeight="33px"
            textAlign="center"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="のこと"
            {...getOverrideProps(overrides, "\u306E\u3053\u3068")}
          ></Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
