import { DataStore } from "aws-amplify";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LeavesPet } from "../models";
import { ListViewDetail } from "../page-components";
import { getLabel, useInitializePage } from "../utils/CommonUtils";
import { isShare, queryData } from "../utils/ShareUtils";

function PetViewPage() {
  const navigate = useInitializePage("petnitsuite", "title.detail");
  const [dataPet, setDataPet] = useState();
  let params = useParams();
  const deleteData = useCallback(async () => {
    await DataStore.delete(dataPet);
    navigate(-1, { replace: true });
  }, [dataPet, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const data = await queryData(params, LeavesPet);
      if (data?.length > 0) {
        setDataPet(data[0]);
      }
    };
    getData();
  }, [params.id]);

  const listLabel = [
    { label: "namae", value: dataPet?.namae },
    { label: "shurui", value: dataPet?.shurui },
    { label: "seinengappi", value: dataPet?.seinengappi, type: "date" },
    { label: "seibetsu", value: dataPet?.seibetsu },
    { label: "tourokubangou", value: dataPet?.tourokubangou },
    {
      label: "hokennoumu",
      value: dataPet?.hokennoumu
        ? getLabel(`Hokennoumu.${dataPet?.hokennoumu}`)
        : "",
    },
    { label: "hokennkaisyamei", value: dataPet?.hokennkaisyamei },
    { label: "kakaritsukenobyouinmei", value: dataPet?.kakaritsukenobyouinmei },
    {
      label: "kakaritsukenobyouinnorenrakusaki",
      value: dataPet?.kakaritsukenobyouinnorenrakusaki,
      isMulti: true,
    },
    { label: "bikou", value: dataPet?.bikou, isMulti: true },
  ];

  return (
    <ListViewDetail
      label={dataPet?.namae}
      list={listLabel}
      isHideEdit={isShare(params)}
      onDelete={deleteData}
      onEdit={() => {
        navigate("edit");
        window.gtag("event", "Change", {
          event_category: "Edit",
          event_label: "ペットについて変更開始",
        });
      }}
    />
  );
}

export default PetViewPage;
