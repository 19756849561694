import { isMobile } from "react-device-detect";
import { LabelS, PCLabelS } from "../ui-components";
export default function (props) {
  if (isMobile) {
    return (
      <LabelS
        width={"auto"}
        margin={"16px 0 0 0"}
        overrides={{
          "View.Text[0]": {
            children: props.label,
            width: "fit-content",
            className: "text-overflow-ellipsis",
            maxWidth: "calc(100vw - 50px)",
            whiteSpace: "pre",
            overflow: "hidden",
            display: "block",
          },
        }}
        {...props}
      />
    );
  } else {
    return (
      <PCLabelS
        margin="auto"
        display="block"
        maxWidth="896px"
        className="pccomponent"
        width="100%"
        overrides={{
          "Flex.Text[0]": {
            children: props.label,
            width: "100%",
            className: "text-overflow-ellipsis",
            whiteSpace: "pre",
            overflow: "hidden",
            display: "block",
          },
        }}
        {...props}
      />
    );
  }
}
