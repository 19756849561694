export const frDict = {
  'Account recovery requires verified contact information':
    'La récupération du compte nécessite des informations de contact vérifiées',
  'An account with the given email already exists.':
    'Un utilisateur avec cette adresse email existe déjà.',
  'Back to Sign In': 'Retour à la connexion',
  Change: 'Modifier',
  'Change Password': 'Modifier le mot de passe',
  Code: 'Code',
  Confirm: 'Confirmer',
  'Confirm a Code': 'Confirmer un code',
  'Confirm Password': 'Confirmez le mot de passe',
  'Confirm Sign In': 'Confirmer la connexion',
  'Confirm Sign Up': "Confirmer l'inscription",
  'Confirm SMS Code': 'Confirmer le code SMS',
  'Confirm TOTP Code': 'Confirmer le code TOTP',
  'Confirmation Code': 'Code de confirmation',
  'Create a new account': 'Créer un nouveau compte',
  'Create account': 'Créer un compte',
  'Create Account': 'Créer un compte',
  Email: 'Email',
  'Enter your code': 'Saisissez cotre code de confirmation',
  'Enter your email': 'Saisissez votre adresse email',
  'Enter your password': 'Saisissez votre mot de passe',
  'Enter your phone number': 'Saisissez votre numéro de téléphone',
  'Enter your username': "Saisissez votre nom d'utilisateur",
  'Forgot Password': 'Mot de passe oublié',
  'Forgot your password?': 'Mot de passe oublié ? ',
  'Have an account? ': 'Déjà un compte ? ',
  Hello: 'Bonjour',
  'Incorrect username or password': 'Identifiant ou mot de passe incorrect',
  'Invalid password format': 'Format de mot de passe invalide',
  'Invalid phone number format': `Format de numéro de téléphone invalide. Veuillez utiliser un format +12345678900`,
  'Loading...': 'Chargement...',
  'Lost your code? ': 'Vous avez perdu votre code ? ',
  'Network error': 'Erreur réseau',
  'New Password': 'Nouveau mot de passe',
  'No account? ': 'Pas de compte ? ',
  or: 'ou',
  Password: 'Mot de passe',
  'Phone Number': 'Numéro de téléphone',
  'Resend a Code': 'Renvoyer un code',
  'Resend Code': 'Renvoyer le code',
  'Reset password': 'Réinitialisez votre mot de passe',
  'Reset your password': 'Réinitialisez votre mot de passe',
  Send: 'Envoyer',
  'Send Code': "M'envoyer un code",
  'Sign in': 'Se connecter',
  'Sign In': 'Se connecter',
  'Sign in to your account': 'Connexion à votre compte',
  'Sign In with Amazon': 'Se connecter avec Amazon',
  'Sign In with AWS': 'Se connecter avec AWS',
  'Sign In with Facebook': 'Se connecter avec Facebook',
  'Sign In with Google': 'Se connecter avec Google',
  'Sign Out': 'Déconnexion',
  'Sign Up': "S'inscrire",
  Skip: 'Passer',
  SMS: 'SMS',
  Submit: 'Soumettre',
  'User already exists': "L'utilisateur existe déjà",
  'User does not exist': "L'utilisateur n'existe pas",
  Username: "Nom d'utilisateur",
  'Username cannot be empty': "Le nom d'utilisateur doit être renseigné",
  'Username/client id combination not found.': "L'utilisateur n'existe pas",
  Verify: 'Vérifier',
  'Verify Contact': 'Vérifier le contact',
};
