/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Image, View } from "@aws-amplify/ui-react";
export default function Header(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="375px"
      height="60px"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(104,134,73,1)"
      {...rest}
      {...getOverrideProps(overrides, "Header")}
    >
      <Image
        width="24px"
        height="24px"
        position="absolute"
        top="18px"
        left="326px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Menu")}
      ></Image>
      <Image
        position="absolute"
        top="10px"
        bottom="10px"
        height="calc(100% - 20px)"
        left="108px"
        right="108.84px"
        width="calc(100% - 216.84px)"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Logo")}
      ></Image>
    </View>
  );
}
