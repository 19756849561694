import { DataStore } from "aws-amplify";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { LeavesIryousochi } from "../models";
import { ListField } from "../page-components";
import { getLabel, isEmptyIryou, useInitializePage } from "../utils/CommonUtils";
import {
  ENMEISOCHIKIBOUNOUMU,
  KOKUCHIKIBOUNOUMU,
  useDatalist,
  ZOUKITEIKYOUKIBOUNOUMU,
} from "../utils/DatalistUtils";
import { usePrompt } from "../utils/Prompt";

function MedicalEditPage() {
  const navigate = useInitializePage("iryousochinitsuite", "title.update");
  const data = useRef();

  const [iryousochi, setIryousochi] = useState({
    handanwoyudanetaihito: "",
    ishikiganakunattarashirasetehoshiiyuujinchijin: [],
    bikou: "",
  });
  const [isUpdated, setIsUpdated] = useState(false);

  const [update, setUpdate] = useState(false);
  const [isEmptyData, setIsEmptyData] = useState(true);

  const [
    KokuchikibounoumuDataList,
    setKokuchikibounoumu,
    getKokuchikibounoumu,
    hasErrorKokuchikibounoumu,
  ] = useDatalist({
    list: KOKUCHIKIBOUNOUMU,
    dataUpdateHook: setUpdate,
  });

  const [
    EnmeisochikibounoumuDataList,
    setEnmeisochikibounoumu,
    getEnmeisochikibounoumu,
    hasErrorEnmeisochikibounoumu,
  ] = useDatalist({
    list: ENMEISOCHIKIBOUNOUMU,
    dataUpdateHook: setUpdate,
  });

  const [
    ZoukiteikyounoumuDataList,
    setZoukiteikyounoumu,
    getZoukiteikyounoumu,
    hasErrorZoukiteikyounoumu,
  ] = useDatalist({
    list: ZOUKITEIKYOUKIBOUNOUMU,
    dataUpdateHook: setUpdate,
  });

  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const dsData = await DataStore.query(LeavesIryousochi);
      if (dsData?.length > 0) {
        data.current = dsData[0];
        setIsEmptyData(isEmptyIryou(dsData[0]));
        setKokuchikibounoumu(dsData[0].kokuchikibounoumu);
        setEnmeisochikibounoumu(dsData[0].enmeisochikibounoumu);
        setZoukiteikyounoumu(dsData[0].zoukiteikyounoumu);
        setIryousochi({
          handanwoyudanetaihito: dsData[0].handanwoyudanetaihito,
          ishikiganakunattarashirasetehoshiiyuujinchijin:
            dsData[0].ishikiganakunattarashirasetehoshiiyuujinchijin,
          bikou: dsData[0].bikou,
        });
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const eventTracking = () => {
    if (isEmptyData) {
      window.gtag("event", "FirstWriteComplete", {
        event_category: "Edit",
        event_label: "医療措置について書込完了",
      });
    } else {
      window.gtag("event", "ChangeComplete", {
        event_category: "Edit",
        event_label: "医療措置について変更完了",
      });
    }
  };

  const updateData = useCallback(async () => {
    let valid = true;
    if (hasErrorKokuchikibounoumu()) valid = false;
    if (hasErrorEnmeisochikibounoumu()) valid = false;
    if (hasErrorZoukiteikyounoumu()) valid = false;
    if (valid) {
      setIsUpdated(true);
      await DataStore.save(
        LeavesIryousochi.copyOf(data.current, (updated) => {
          updated.kokuchikibounoumu = getKokuchikibounoumu();
          updated.enmeisochikibounoumu = getEnmeisochikibounoumu();
          updated.zoukiteikyounoumu = getZoukiteikyounoumu();
          updated.handanwoyudanetaihito = iryousochi.handanwoyudanetaihito;
          updated.ishikiganakunattarashirasetehoshiiyuujinchijin =
            iryousochi.ishikiganakunattarashirasetehoshiiyuujinchijin;
          updated.bikou = iryousochi.bikou;
        }),
      );
      eventTracking();
      navigate(-1, { replace: true });
    }
  }, [
    getEnmeisochikibounoumu,
    getKokuchikibounoumu,
    getZoukiteikyounoumu,
    hasErrorEnmeisochikibounoumu,
    hasErrorKokuchikibounoumu,
    hasErrorZoukiteikyounoumu,
    iryousochi.bikou,
    iryousochi.handanwoyudanetaihito,
    iryousochi.ishikiganakunattarashirasetehoshiiyuujinchijin,
    navigate,
  ]);

  const listField = [
    {
      type: "dataList",
      child: <KokuchikibounoumuDataList label="kokuchikibounoumu" />,
    },
    {
      type: "dataList",
      child: <EnmeisochikibounoumuDataList label="enmeisochikibounoumu" />,
    },
    {
      type: "dataList",
      child: <ZoukiteikyounoumuDataList label="zoukiteikyounoumu" />,
    },
    {
      type: "textField",
      label: "handanwoyudanetaihito",
      placeHolder: "namae",
      value: iryousochi.handanwoyudanetaihito,
    },
    {
      type: "contactFriend",
      label: "ishikiganakunattarashirasetehoshiiyuujinchijin",
      value: iryousochi.ishikiganakunattarashirasetehoshiiyuujinchijin,
    },
    {
      type: "textArea",
      label: "bikou",
      value: iryousochi.bikou,
    },
  ];

  return (
    <ListField
      label={getLabel("iryousochinitsuite")}
      list={listField}
      setState={setIryousochi}
      setUpdate={setUpdate}
      onSave={updateData}
      onCancel={() => navigate(-1, { replace: true })}
      isDisabled={isUpdated}
    />
  );
}

export default MedicalEditPage;
