/* eslint-disable import/no-anonymous-default-export */
import { DataStore } from "aws-amplify";
import React, { useContext, useState } from "react";
import { isMobile } from "react-device-detect";
import { emailRegex } from "../constants";
import { LeavesKazokuShinzoku } from "../models";
import {
  ButtonCancelSave,
  SwapperContent,
  TextField,
  TextFieldMultiline,
} from "../page-components";
import UserContext from "../UserContext";
import {
  getLabel,
  isEmpty,
  isInvalidDateFormat,
  isNotEmpty,
  isNotEmptyDate,
  useInitializePage,
} from "../utils/CommonUtils";
import { log } from "../utils/Logger";
import { usePrompt } from "../utils/Prompt";

/*
  shimei: String!
  zokugara: String!
  seinengappi: AWSDate
  yuubinbangou: String
  juusho: String
  denwabangou: String
  nyuuinjirenraku: Renrakushurui
  sougijirenraku: Renrakushurui
  bikou: String
*/
export default function () {
  const navigate = useInitializePage("kazokushinzokuichiran", "title.add");

  const context = useContext(UserContext);

  const [shimei, setShimei] = useState("");
  const [zokugara, setZokugara] = useState("");
  const [seinengappi, setSeinengappi] = useState("");
  const [yuubinbangou, setYuubinbangou] = useState("");
  const [juusho, setJuusho] = useState("");
  const [denwabangou, setDenwabangou] = useState("");
  const [mailaddress, setMailaddress] = useState("");
  const [bikou, setBikou] = useState("");

  const [shimeiError, setShimeiError] = useState(null);
  const [zokugaraError, setZokugaraError] = useState(null);
  const [seinengappiError, setSeinengappiError] = useState(null);
  const [yuubinbangouError, setYuubinbangouError] = useState(null);
  const [juushoError, setJuushoError] = useState(null);
  const [denwabangouError, setDenwabangouError] = useState(null);
  const [mailaddressError, setMailaddressError] = useState(null);
  const [bikouError, setBikouError] = useState(null);
  const [isAdded, setIsAdded] = useState(false);

  const [update, setUpdate] = useState(false);
  usePrompt((location, action) => {
    if (action == "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  const addData = async () => {
    log.debug(
      shimei +
        "," +
        zokugara +
        "," +
        seinengappi +
        "," +
        yuubinbangou +
        "," +
        juusho +
        "," +
        denwabangou +
        "," +
        mailaddress +
        "," +
        bikou,
    );
    let valid = true;
    if (isEmpty(shimei)) {
      setShimeiError(getLabel("error.required"));
      valid = false;
    } else {
      setShimeiError(null);
    }
    if (isEmpty(zokugara)) {
      setZokugaraError(getLabel("error.required"));
      valid = false;
    } else {
      setZokugaraError(null);
    }
    const dateFormatCheck = isInvalidDateFormat(seinengappi);
    if (dateFormatCheck) {
      setSeinengappiError(getLabel("error.dateformat"));
      valid = false;
    } else {
      setSeinengappiError(null);
    }
    if (isNotEmpty(yuubinbangou)) {
      if (!/^\d{3}[-]{0,1}\d{4}$/.test(yuubinbangou)) {
        setYuubinbangouError(getLabel("error.postcode"));
        valid = false;
      } else {
        setYuubinbangouError(null);
      }
    }
    if (isNotEmpty(denwabangou)) {
      if (!/^[0-9-]{4,13}$/.test(denwabangou)) {
        setDenwabangouError(getLabel("error.telno"));
        valid = false;
      } else {
        setDenwabangouError(null);
      }
    }
    const checkEmail = isNotEmpty(mailaddress) && !emailRegex.test(mailaddress);
    if (checkEmail) {
      setMailaddressError(getLabel("error.emailformat"));
      valid = false;
    } else {
      setMailaddressError(null);
    }

    if (valid) {
      //   console.log(context.leavesUser);
      setIsAdded(true);
      await DataStore.save(
        new LeavesKazokuShinzoku({
          shimei: shimei,
          zokugara: zokugara,
          seinengappi: isNotEmptyDate(seinengappi) ? seinengappi : null,
          yuubinbangou: yuubinbangou,
          juusho: juusho,
          denwabangou: denwabangou,
          mailaddress: mailaddress,
          bikou: bikou,
          leavesusersID: context.leavesUser.id,
        }),
      );
      window.gtag("event", "FirstWriteComplete", {
        event_category: "Edit",
        event_label: "家族・親族書込完了",
      });
      navigate(-1, { replace: true });
    }
  };

  return (
    <SwapperContent label={getLabel("shinkitsuika")}>
      <TextField
        isRequired
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("shimei")}
        errorMessage={shimeiError}
        onChange={(event) => {
          setShimei(event.target.value);
          setUpdate(true);
        }}
      />
      <TextField
        isRequired
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("zokugara")}
        errorMessage={zokugaraError}
        onChange={(event) => {
          setZokugara(event.target.value);
          setUpdate(true);
        }}
      />
      <TextField
        className={isMobile ? "" : "maxwidth800"}
        type="date"
        label={getLabel("seinengappi")}
        errorMessage={seinengappiError}
        onChange={(event) => {
          setSeinengappi(event.target.value);
          setUpdate(true);
        }}
      />
      <TextField
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("yuubinbangou")}
        errorMessage={yuubinbangouError}
        onChange={(event) => {
          setYuubinbangou(event.target.value);
          setUpdate(true);
        }}
      />
      <TextField
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("juusho")}
        errorMessage={juushoError}
        onChange={(event) => {
          setJuusho(event.target.value);
          setUpdate(true);
        }}
      />
      <TextField
        className={isMobile ? "" : "maxwidth800"}
        type="tel"
        label={getLabel("denwabangou")}
        errorMessage={denwabangouError}
        onChange={(event) => {
          setDenwabangou(event.target.value);
          setUpdate(true);
        }}
      />
      <TextField
        className={isMobile ? "" : "maxwidth800"}
        type="email"
        label={getLabel("mailaddress")}
        errorMessage={mailaddressError}
        placeholder={getLabel("nyuuryokumiss")}
        onChange={(event) => {
          setMailaddress(event.target.value);
          setUpdate(true);
        }}
      />
      <TextFieldMultiline
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("bikou")}
        errorMessage={bikouError}
        placeholder={getLabel("kazokugashiranaiepisode")}
        onChange={(event) => {
          setBikou(event.target.value);
          setUpdate(true);
        }}
      />
      <ButtonCancelSave
        cancelOnClick={() => navigate(-1, { replace: true })}
        saveOnClick={() => addData()}
        isDisabled={isAdded}
      />
    </SwapperContent>
  );
}
