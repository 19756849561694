/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Image, Text } from "@aws-amplify/ui-react";
export default function CardHowto(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="0"
      direction="column"
      width="325px"
      position="relative"
      boxShadow="2px 2px 6px rgba(0.30588236451148987, 0.3019607961177826, 0.3019607961177826, 0.20000000298023224)"
      borderRadius="3px"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "CardHowto")}
    >
      <Flex
        gap="10px"
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        objectFit="cover"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "VisualContent")}
      >
        <Image
          width="325px"
          height="325px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "KeyVisual")}
        ></Image>
      </Flex>
      <Flex
        gap="0"
        direction="column"
        shrink="0"
        alignSelf="stretch"
        objectFit="cover"
        position="relative"
        padding="20px 20px 20px 20px"
        {...getOverrideProps(overrides, "Body")}
      >
        <Text
          fontFamily="Zen Kaku Gothic New"
          fontSize="22px"
          fontWeight="500"
          color="rgba(0,0,0,1)"
          lineHeight="34px"
          textAlign="center"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="HIRAQL NOTEの&#xA;使い方"
          {...getOverrideProps(
            overrides,
            "HIRAQL NOTE\u306E \u4F7F\u3044\u65B9"
          )}
        ></Text>
        <Flex
          gap="10px"
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Line")}
        >
          <Image
            width="30px"
            height="30px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Next")}
          ></Image>
        </Flex>
      </Flex>
    </Flex>
  );
}
