/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Image, Text } from "@aws-amplify/ui-react";
import TextButton from "./TextButton";
export default function Footer(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="0"
      direction="column"
      width="375px"
      alignItems="center"
      position="relative"
      padding="32px 32px 32px 32px"
      backgroundColor="var(--my-theme-header-footer-color)"
      {...rest}
      {...getOverrideProps(overrides, "Footer")}
    >
      <Flex
        gap="22px"
        direction="column"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 553")}
      >
        <Flex
          gap="20px"
          direction="row"
          width="fit-content"
          justifyContent="center"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "SNS")}
        >
          <Image
            width="35px"
            height="35px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Facebook")}
          ></Image>
          <Image
            width="35px"
            height="35px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Twitter")}
          ></Image>
        </Flex>
        <Flex
          gap="8px"
          direction="row"
          width="fit-content"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Link")}
        >
          <TextButton
            display="flex"
            gap="0"
            direction="row"
            width="fit-content"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            height="39px"
            position="relative"
            borderRadius="4px"
            padding="8px 8px 8px 8px"
            {...getOverrideProps(overrides, "TextButton579420633")}
          ></TextButton>
          <TextButton
            display="flex"
            gap="0"
            direction="row"
            width="fit-content"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            height="39px"
            position="relative"
            borderRadius="4px"
            padding="8px 8px 8px 8px"
            {...getOverrideProps(overrides, "TextButton579421517")}
          ></TextButton>
          <TextButton
            display="flex"
            gap="0"
            direction="row"
            width="fit-content"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            height="39px"
            position="relative"
            borderRadius="4px"
            padding="8px 8px 8px 8px"
            {...getOverrideProps(overrides, "TextButton579422086")}
          ></TextButton>
        </Flex>
        <Text
          fontFamily="Zen Kaku Gothic New"
          fontSize="10px"
          fontWeight="400"
          color="rgba(255,255,255,1)"
          lineHeight="25px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="© 2022 Tokyu La viere"
          {...getOverrideProps(overrides, "\u00A9 2022 Tokyu La viere")}
        ></Text>
      </Flex>
    </Flex>
  );
}
