/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, IconCancel, IconSave, Text } from "@aws-amplify/ui-react";
export default function ButtonCancelSave(props) {
  const { overrides: overridesProp, ...rest } = props;
  const overrides = { ...overridesProp };
  return (
    <Flex
      gap="16px"
      direction="row"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "Flex")}
    >
      <Flex
        gap="8px"
        direction="row"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        height="36px"
        position="relative"
        border="1px SOLID rgba(174.00000482797623,179.000004529953,183.00000429153442,1)"
        borderRadius="4px"
        padding="5px 15px 5px 11px"
        {...getOverrideProps(overrides, "Flex.Flex[0]")}
      >
        <IconCancel
          width="24px"
          height="24px"
          shrink="0"
          overflow="hidden"
          position="relative"
          padding="0px 0px 0px 0px"
          color="rgba(13.000000175088644,26.000000350177288,38.0000015348196,1)"
          type="cancel"
          fontSize="24px"
          {...getOverrideProps(overrides, "Flex.Flex[0].IconCancel[0]")}
        ></IconCancel>
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="700"
          color="rgba(13.000000175088644,26.000000350177288,38.0000015348196,1)"
          lineHeight="18px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          letterSpacing="0.41000000000000003px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          children="キャンセル"
          {...getOverrideProps(overrides, "Flex.Flex[0].Text[0]")}
        ></Text>
      </Flex>
      <Flex
        gap="8px"
        direction="row"
        width="133px"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        height="36px"
        position="relative"
        borderRadius="4px"
        padding="6px 16px 6px 12px"
        backgroundColor="rgba(4.000000236555934,125.00000014901161,149.00000631809235,1)"
        {...getOverrideProps(overrides, "Flex.Flex[1]")}
      >
        <IconSave
          width="24px"
          height="24px"
          shrink="0"
          overflow="hidden"
          position="relative"
          padding="0px 0px 0px 0px"
          color="rgba(255,255,255,1)"
          type="save"
          fontSize="24px"
          {...getOverrideProps(overrides, "Flex.Flex[1].IconSave[0]")}
        ></IconSave>
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="700"
          color="rgba(255,255,255,1)"
          lineHeight="18px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          letterSpacing="0.41000000000000003px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          children="保存"
          {...getOverrideProps(overrides, "Flex.Flex[1].Text[0]")}
        ></Text>
      </Flex>
    </Flex>
  );
}
