import { useEffect } from "react";
const withNonScrollBody = (Component) => {
  const WithComponent = (props) => {
    useEffect(() => {
      document.body.classList.add("body-scroll");
      return () => {
        document.body.classList.remove("body-scroll");
      };
    }, []);
    return <Component {...props} />;
  };

  return (props) => <WithComponent {...props} />;
};

export default withNonScrollBody;
