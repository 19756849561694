import { DataStore } from "aws-amplify";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { MODEL_NAME } from "../constants";
import { LeavesShareInfo } from "../models";
import { ListField } from "../page-components";
import UserContext from "../UserContext";
import {
  getLabel,
  isEmpty,
  isEmptyDate,
  isInvalidDateFormat,
  isNotEmptyDate,
  useInitializePage,
} from "../utils/CommonUtils";
import { log } from "../utils/Logger";
import { usePrompt } from "../utils/Prompt";

export default function InviteAddPage() {
  const navigate = useInitializePage("invite", "title.add"); /**TODO: */
  const context = useContext(UserContext);
  const { state } = useLocation();
  const checkOptions = [
    "LeavesJitsubutsuShisan",
    "LeavesKinyuuShisan",
    "LeavesHoken",
    "LeavesCreditcard",
    "LeavesKouza",
    "LeavesNenkin",
    "LeavesLoankariirekin",
    "LeavesSumahoPc",
    "LeavesLoginId",
    "LeavesKazokuShinzoku",
    "LeavesYuujinChijin",
    "LeavesKenkoukanri",
    "LeavesSeikatsunokoto",
    "LeavesPet",
    "LeavesDaijinishitaikinenbi",
    "LeavesLifeFuture",
    "LeavesTaisetsunishitaihitohenomessage",
    "LeavesKaigo",
    "LeavesIryousochi",
    "LeavesYuigonshonitsuite",
    "LeavesSougi",
    "LeavesHakanitsuite",
    "LeavesJibunshi",
    "LeavesTakaramono",
    "LeavesKakeizu",
  ];

  const [shareData, setShareData] = useState({
    shareName: "",
    shareDate: null,
    shareTarget: [],
  });
  const [isAdded, setIsAdded] = useState(false);
  const [shareNameError, setShareNameError] = useState("");
  const [shareDateError, setShareDateError] = useState("");
  const [shareTargetError, setShareTargetError] = useState("");
  const [targetDateError, setTargetDateError] = useState({});
  const [update, setUpdate] = useState(false);
  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  const listField = [
    {
      type: "textField",
      label: "shareName",
      isRequire: true,
      errorMessage: shareNameError,
    },
    {
      type: "textField",
      label: "shareDate",
      isRequire: true,
      typeInput: "date",
      errorMessage: shareDateError,
    },
    {
      type: "description",
      label: getLabel("shareDate.desTitle"),
      description: getLabel("shareDate.des"),
    },
    {
      type: "checkListShare",
      label: "shareTarget",
      isRequire: true,
      isAddPage: true,
      value: shareData?.shareTarget,
      errorList: targetDateError,
      options: checkOptions.map((o) => {
        return { option: getLabel("Datastore." + o), value: o };
      }),
      errorMessage: shareTargetError,
    },
  ]; /**TODO: */

  const addData = async () => {
    let valid = true;
    if (isEmpty(shareData.shareName)) {
      setShareNameError(getLabel("error.required"));
      valid = false;
    } else setShareNameError("");

    const dateFormatCheck = isInvalidDateFormat(shareData.shareDate);
    if (dateFormatCheck) {
      setShareDateError(getLabel("error.dateformat"));
      valid = false;
    } else if (isEmptyDate(shareData.shareDate)) {
      setShareDateError(getLabel("error.required"));
      valid = false;
    } else {
      setShareDateError(null);
    }

    if (shareData.shareTarget?.length === 0) {
      setShareTargetError(getLabel("error.required"));
      valid = false;
    } else setShareTargetError("");

    const errorTargetDate = {};
    shareData.shareTarget.forEach((ele) => {
      const checkDate = isInvalidDateFormat(ele.shareDate);
      if (checkDate) {
        errorTargetDate[ele.value] = getLabel("error.dateformat");
        valid = false;
      } else {
        errorTargetDate[ele.value] = null;
      }
    });
    setTargetDateError(errorTargetDate);

    if (valid) {
      setUpdate(false);
      setIsAdded(true);
      const targetList = shareData.shareTarget.map((t) => {
        return {
          dataName: t.value,
          shareDate: isEmptyDate(t.shareDate)
            ? shareData.shareDate
            : t.shareDate,
        };
      });
      const loanItem = targetList.find(
        (ele) => ele.dataName === MODEL_NAME.LOANKARIIREKIN
      );
      const bucketListItem = targetList.find(
        (ele) => ele.dataName === MODEL_NAME.LIFEFUTURE
      );

      if (loanItem) {
        targetList.push({
          dataName: MODEL_NAME.HOSYOUSAIMU,
          shareDate: loanItem.shareDate,
        });
      }
      if (bucketListItem) {
        targetList.push({
          dataName: MODEL_NAME.SHUUKAN,
          shareDate: bucketListItem.shareDate,
        });
      }

      const data = await DataStore.save(
        new LeavesShareInfo({
          name: shareData.shareName,
          originDate: shareData.shareDate,
          target: targetList,
          leavesusersID: context.leavesUser.id,
        })
      );
      log.debug("create shareInfo", data);
      const idInvite = data.id;
      window.gtag("event", "SaveInviteInfo", {
        event_category: "Share",
        event_label: "招待情報保存 (Save)",
      });
      navigate(idInvite);
    }
  };
  
  useEffect(() => {
    window.scrollTo(0, 0);
    if (state) {
      const targets = [{ value: state, shareDate: "" }];
      setShareData({
        ...shareData,
        shareTarget: targets,
      });
    }
  }, []);

  return (
    <div>
      <ListField
        label={getLabel("kyoyusurujohoosettei")}
        isDivide
        list={listField}
        setState={setShareData}
        setUpdate={setUpdate}
        onSave={addData}
        onCancel={() => navigate(-1, { replace: true })}
        isDisabled={isAdded}
      />
      {/**TODO: */}
    </div>
  );
}
