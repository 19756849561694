import { DataStore } from "aws-amplify";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LeavesSumahoPc } from "../models";
import {
  ListSelectContent,
  ListSingleLineA,
  ListSingleLineD,
} from "../page-components";
import { getLabel, useInitializePage } from "../utils/CommonUtils";
import { isShare, queryDatas } from "../utils/ShareUtils";

function DevicesPage() {
  const navigate = useInitializePage("sumahoyapcnitsuite");
  const [listDevice, setListDevice] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selected, setSelected] = useState(null);
  const params = useParams();

  const handledChangeActive = (item) => {
    setSelected(item);
  };

  useEffect(() => {
    if (listDevice?.length > 0) {
      setSelected(listDevice[0]);
    } else {
      setSelected(null);
    }
  }, [listDevice]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const modelData = await queryDatas(params, LeavesSumahoPc);
      if (modelData?.length > 0) setListDevice(modelData);
      setIsLoaded(true);
    };
    getData();
  }, []);

  const deleteData = async (data) => {
    await DataStore.delete(data);
    const newList = await DataStore.query(LeavesSumahoPc);
    setListDevice(newList);
  };

  return (
    <ListSelectContent
      label={getLabel("sumahoyapcnitsuite")}
      mobileList={listDevice.map((data, index) => {
        return (
          <ListSingleLineA
            key={data?.id}
            label={data?.kikishubetsu}
            onClick={() => navigate(data?.id)}
          />
        );
      })}
      description={{
        type: "data",
        category: "sumahoyapcnitsuite",
      }}
      isShared={isShare(params)}
      addButtonLabel={
        "デバイス" + getLabel("button.addsuffix") //**TODO: using getLabel when デバイス is defined in vocabulary */
      }
      onAddClick={() => {
        navigate("add");
        window.gtag("event", "FirstWrite", {
          event_category: "Edit",
          event_label: "スマホやPCについて書込開始",
        });
      }}
      pcListItem={listDevice.map((ele, index) => {
        return (
          <ListSingleLineD
            key={ele?.id}
            label={ele?.kikishubetsu}
            selected={selected?.id === ele?.id}
            onClick={() => {
              handledChangeActive(ele);
            }}
          />
        );
      })}
      selectedItemList={[
        {
          label: getLabel("kikishubetsu"),
          value: selected?.kikishubetsu,
        },
        {
          isEmptyHide: true,
          label: getLabel("keiyakusaki"),
          value: selected?.keiyakusaki,
        },
        {
          isEmptyHide: true,
          label: getLabel("denwabangou"),
          value: selected?.denwabangou,
        },
        {
          isEmptyHide: true,
          label: getLabel("meiginin"),
          value: selected?.meiginin,
        },
        {
          isEmptyHide: true,
          label: getLabel("kishumei"),
          value: selected?.kishumei,
        },
        {
          isEmptyHide: true,
          label: getLabel("bikou"),
          value: selected?.bikou,
        },
      ]}
      selectedItemLabel={selected?.kikishubetsu}
      onDelete={() => deleteData(selected)}
      onEdit={() => {
        navigate(selected?.id + "/edit");
        window.gtag("event", "Change", {
          event_category: "Edit",
          event_label: "スマホやPCについて変更開始",
        });
      }}
      isLoaded={isLoaded}
    />
  );
}

export default DevicesPage;
