/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Divider, Flex, Image, Text, View } from "@aws-amplify/ui-react";
export default function PCCardHint(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="528px"
      height="300px"
      position="relative"
      boxShadow="3px 3px 6px rgba(0, 0, 0, 0.1599999964237213)"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "PCCardHint")}
    >
      <Flex
        gap="10px"
        position="absolute"
        top="0%"
        bottom="57%"
        left="0%"
        right="0%"
        direction="row"
        width="528px"
        height="129px"
        alignItems="flex-start"
        borderRadius="3px"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "Back")}
      >
        <Flex
          gap="0"
          direction="row"
          width="fit-content"
          alignItems="flex-start"
          shrink="0"
          height="100px"
          overflow="hidden"
          position="relative"
          borderRadius="3px"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "Icon")}
        >
          <Image
            width="100px"
            height="100px"
            shrink="0"
            position="relative"
            borderRadius="3px 0px 0px 0px"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Hint")}
          ></Image>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        direction="column"
        width="528px"
        height="300px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Content")}
      >
        <Flex
          gap="10px"
          direction="row"
          width="528px"
          height="128px"
          justifyContent="center"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="28px 20px 32px 20px"
          {...getOverrideProps(overrides, "Title")}
        >
          <Flex
            gap="5px"
            direction="column"
            width="488px"
            alignItems="center"
            grow="1"
            basis="488px"
            height="79px"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 555")}
          >
            <Text
              fontFamily="Zen Kaku Gothic New"
              fontSize="28px"
              fontWeight="500"
              color="rgba(0,0,0,1)"
              lineHeight="41px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="お気に入りヒント"
              {...getOverrideProps(
                overrides,
                "\u304A\u6C17\u306B\u5165\u308A\u30D2\u30F3\u30C8"
              )}
            ></Text>
            <Text
              fontFamily="Zen Kaku Gothic New"
              fontSize="15px"
              fontWeight="500"
              color="rgba(0,0,0,1)"
              lineHeight="33px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="HINT"
              {...getOverrideProps(overrides, "HINT")}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="528px"
          height="86px"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="5px 15px 8px 21px"
          backgroundColor="rgba(245,245,245,1)"
          {...getOverrideProps(overrides, "CardColum")}
        >
          <Flex
            gap="20px"
            direction="row"
            width="465px"
            alignItems="center"
            grow="1"
            basis="465px"
            height="62px"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "ContentC")}
          >
            <Image
              width="40px"
              height="40px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Colum")}
            ></Image>
            <Flex
              gap="0"
              direction="column"
              width="405px"
              grow="1"
              basis="405px"
              height="62px"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "TextH")}
            >
              <Text
                fontFamily="Zen Kaku Gothic New"
                fontSize="17px"
                fontWeight="500"
                color="rgba(0,0,0,1)"
                lineHeight="31px"
                textAlign="left"
                display="flex"
                direction="column"
                justifyContent="flex-start"
                shrink="0"
                alignSelf="stretch"
                objectFit="cover"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="お気に入りのコラム"
                {...getOverrideProps(
                  overrides,
                  "\u304A\u6C17\u306B\u5165\u308A\u306E\u30B3\u30E9\u30E0"
                )}
              ></Text>
              <Text
                fontFamily="Zen Kaku Gothic New"
                fontSize="17px"
                fontWeight="500"
                color="rgba(112,112,112,1)"
                lineHeight="31px"
                textAlign="left"
                display="flex"
                direction="column"
                justifyContent="flex-start"
                shrink="0"
                alignSelf="stretch"
                objectFit="cover"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="気に入った記事、読み返したい記事をストック"
                {...getOverrideProps(
                  overrides,
                  "\u6C17\u306B\u5165\u3063\u305F\u8A18\u4E8B\u3001\u8AAD\u307F\u8FD4\u3057\u305F\u3044\u8A18\u4E8B\u3092\u30B9\u30C8\u30C3\u30AF"
                )}
              ></Text>
            </Flex>
          </Flex>
          <Image
            width="27px"
            height="27px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Next584557548")}
          ></Image>
        </Flex>
        <Divider
          height="1px"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          size="small"
          orientation="horizontal"
          {...getOverrideProps(overrides, "Divider")}
        ></Divider>
        <Flex
          gap="0"
          direction="row"
          width="528px"
          height="86px"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="5px 15px 8px 21px"
          backgroundColor="rgba(245,245,245,1)"
          {...getOverrideProps(overrides, "CardEvent")}
        >
          <Flex
            gap="20px"
            direction="row"
            width="465px"
            alignItems="center"
            grow="1"
            basis="465px"
            height="62px"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "ContentE")}
          >
            <Image
              width="40px"
              height="40px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Event")}
            ></Image>
            <Flex
              gap="0"
              direction="column"
              width="405px"
              grow="1"
              basis="405px"
              height="62px"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "TextE")}
            >
              <Flex
                gap="5px"
                direction="row"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                objectFit="cover"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "TitleE")}
              >
                <Text
                  fontFamily="Zen Kaku Gothic New"
                  fontSize="17px"
                  fontWeight="500"
                  color="rgba(0,0,0,1)"
                  lineHeight="31px"
                  textAlign="left"
                  display="flex"
                  direction="column"
                  justifyContent="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="予約済みイベント"
                  {...getOverrideProps(
                    overrides,
                    "\u4E88\u7D04\u6E08\u307F\u30A4\u30D9\u30F3\u30C8"
                  )}
                ></Text>
                <Image
                  width="15px"
                  height="15px"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "One_badge 1")}
                ></Image>
              </Flex>
              <Text
                fontFamily="Zen Kaku Gothic New"
                fontSize="17px"
                fontWeight="500"
                color="rgba(112,112,112,1)"
                lineHeight="31px"
                textAlign="left"
                display="flex"
                direction="column"
                justifyContent="flex-start"
                shrink="0"
                alignSelf="stretch"
                objectFit="cover"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="あなたの参加予定、過去に参加したイベント一覧"
                {...getOverrideProps(
                  overrides,
                  "\u3042\u306A\u305F\u306E\u53C2\u52A0\u4E88\u5B9A\u3001\u904E\u53BB\u306B\u53C2\u52A0\u3057\u305F\u30A4\u30D9\u30F3\u30C8\u4E00\u89A7"
                )}
              ></Text>
            </Flex>
          </Flex>
          <Image
            width="27px"
            height="27px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Next584557569")}
          ></Image>
        </Flex>
      </Flex>
    </View>
  );
}
