/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, IconCreate, Text } from "@aws-amplify/ui-react";
export default function PCPrimaryButtonSave(props) {
  const { overrides: overridesProp, ...rest } = props;
  const overrides = { ...overridesProp };
  return (
    <Flex
      gap="8px"
      direction="row"
      justifyContent="center"
      alignItems="center"
      position="relative"
      borderRadius="4px"
      padding="8px 16px 8px 12px"
      backgroundColor="rgba(4.000000236555934,125.00000014901161,149.00000631809235,1)"
      {...rest}
      {...getOverrideProps(overrides, "Flex")}
    >
      <IconCreate
        width="24px"
        height="24px"
        shrink="0"
        overflow="hidden"
        position="relative"
        padding="0px 0px 0px 0px"
        color="rgba(255,255,255,1)"
        type="create"
        fontSize="24px"
        {...getOverrideProps(overrides, "Flex.IconCreate[0]")}
      ></IconCreate>
      <Text
        fontFamily="Inter"
        fontSize="16px"
        fontWeight="700"
        color="rgba(255,255,255,1)"
        lineHeight="20px"
        textAlign="left"
        display="flex"
        direction="column"
        justifyContent="flex-start"
        letterSpacing="0.49px"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        children="編集"
        {...getOverrideProps(overrides, "Flex.Text[0]")}
      ></Text>
    </Flex>
  );
}
