/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateLeavesUsers = /* GraphQL */ `
  subscription OnCreateLeavesUsers(
    $filter: ModelSubscriptionLeavesUsersFilterInput
    $owner: String
  ) {
    onCreateLeavesUsers(filter: $filter, owner: $owner) {
      id
      name
      loggedin
      kanshinnoaruCategory
      LeavesJitsubutsuShisans {
        nextToken
        startedAt
        __typename
      }
      LeavesKinyuuShisans {
        nextToken
        startedAt
        __typename
      }
      LeavesHokens {
        nextToken
        startedAt
        __typename
      }
      LeavesKaigo {
        id
        handanwoyudanetaihito
        kiboukaigoshurui
        kiboushisetsu
        hiyoukyoshutsu
        zaisankanriwomakaseruhito
        zaisankanriwomakaseruhitonokeiyakujoukyou
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesSougi {
        id
        sougiyouhi
        kibousougikeishiki
        souginosyurui
        sankaninzuukibo
        kibousuruhuniki
        junbishiteiruhiyou
        hiyoukyoshutsu
        hokenkyousainado
        souginiyondehoshiyuujinchijin
        yondehoshiihitonituite
        souginiyobukakazokuniitininsuruyuujinchijin
        itininsuruhitonituite
        souginoannaiwoshinakutemoyoihitonituite
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesLifeFutures {
        nextToken
        startedAt
        __typename
      }
      LeavesShuukans {
        nextToken
        startedAt
        __typename
      }
      LeavesMemos {
        nextToken
        startedAt
        __typename
      }
      LeavesKazokuShinzokus {
        nextToken
        startedAt
        __typename
      }
      LeavesJibunshis {
        nextToken
        startedAt
        __typename
      }
      LeavesHints {
        nextToken
        startedAt
        __typename
      }
      LeavesPets {
        nextToken
        startedAt
        __typename
      }
      LeavesIryousochi {
        id
        kokuchikibounoumu
        enmeisochikibounoumu
        zoukiteikyounoumu
        handanwoyudanetaihito
        ishikiganakunattarashirasetehoshiiyuujinchijin
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesCreditcards {
        nextToken
        startedAt
        __typename
      }
      LeavesKouzas {
        nextToken
        startedAt
        __typename
      }
      LeavesNenkins {
        nextToken
        startedAt
        __typename
      }
      LeavesKankonsousais {
        nextToken
        startedAt
        __typename
      }
      LeavesSumahoPcs {
        nextToken
        startedAt
        __typename
      }
      LeavesLoginIds {
        nextToken
        startedAt
        __typename
      }
      LeavesYuujinChijins {
        nextToken
        startedAt
        __typename
      }
      LeavesKenkoukanris {
        nextToken
        startedAt
        __typename
      }
      LeavesSeikatsunokoto {
        id
        seikatsunokotonitsuite
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesTakaramonos {
        nextToken
        startedAt
        __typename
      }
      LeavesMyEvents {
        nextToken
        startedAt
        __typename
      }
      LeavesHakanitsuite {
        id
        hairuyoteinoohakanoumu
        kibousuruohakanokeishiki
        kibousurubasyo
        hiyoukyoshutsu
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesDaijinishitaikinenbis {
        nextToken
        startedAt
        __typename
      }
      LeavesKakeizus {
        nextToken
        startedAt
        __typename
      }
      LeavesYuigonshonitsuites {
        id
        yuigonshonoumu
        yuigonshonoshurui
        yuigonshonosaisyuusakuseibi
        yuigonshikkousha
        yuigonshikkoushanorenrakusaki
        iraisoudanwoshiteirusenmonka
        iraisoudanwoshiteirusenmonkanorenrakusaki
        sonotasouzokunikansurukibounado
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesTaisetsunishitaihitohenomessages {
        nextToken
        startedAt
        __typename
      }
      LeavesLoankariirekins {
        nextToken
        startedAt
        __typename
      }
      LeavesHosyousaimus {
        nextToken
        startedAt
        __typename
      }
      LeavesShareInfos {
        nextToken
        startedAt
        __typename
      }
      LeavesSharedInfos {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      leavesUsersLeavesKaigoId
      leavesUsersLeavesSougiId
      leavesUsersLeavesIryousochiId
      leavesUsersLeavesSeikatsunokotoId
      leavesUsersLeavesHakanitsuiteId
      leavesUsersLeavesYuigonshonitsuitesId
      owner
      __typename
    }
  }
`;
export const onUpdateLeavesUsers = /* GraphQL */ `
  subscription OnUpdateLeavesUsers(
    $filter: ModelSubscriptionLeavesUsersFilterInput
    $owner: String
  ) {
    onUpdateLeavesUsers(filter: $filter, owner: $owner) {
      id
      name
      loggedin
      kanshinnoaruCategory
      LeavesJitsubutsuShisans {
        nextToken
        startedAt
        __typename
      }
      LeavesKinyuuShisans {
        nextToken
        startedAt
        __typename
      }
      LeavesHokens {
        nextToken
        startedAt
        __typename
      }
      LeavesKaigo {
        id
        handanwoyudanetaihito
        kiboukaigoshurui
        kiboushisetsu
        hiyoukyoshutsu
        zaisankanriwomakaseruhito
        zaisankanriwomakaseruhitonokeiyakujoukyou
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesSougi {
        id
        sougiyouhi
        kibousougikeishiki
        souginosyurui
        sankaninzuukibo
        kibousuruhuniki
        junbishiteiruhiyou
        hiyoukyoshutsu
        hokenkyousainado
        souginiyondehoshiyuujinchijin
        yondehoshiihitonituite
        souginiyobukakazokuniitininsuruyuujinchijin
        itininsuruhitonituite
        souginoannaiwoshinakutemoyoihitonituite
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesLifeFutures {
        nextToken
        startedAt
        __typename
      }
      LeavesShuukans {
        nextToken
        startedAt
        __typename
      }
      LeavesMemos {
        nextToken
        startedAt
        __typename
      }
      LeavesKazokuShinzokus {
        nextToken
        startedAt
        __typename
      }
      LeavesJibunshis {
        nextToken
        startedAt
        __typename
      }
      LeavesHints {
        nextToken
        startedAt
        __typename
      }
      LeavesPets {
        nextToken
        startedAt
        __typename
      }
      LeavesIryousochi {
        id
        kokuchikibounoumu
        enmeisochikibounoumu
        zoukiteikyounoumu
        handanwoyudanetaihito
        ishikiganakunattarashirasetehoshiiyuujinchijin
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesCreditcards {
        nextToken
        startedAt
        __typename
      }
      LeavesKouzas {
        nextToken
        startedAt
        __typename
      }
      LeavesNenkins {
        nextToken
        startedAt
        __typename
      }
      LeavesKankonsousais {
        nextToken
        startedAt
        __typename
      }
      LeavesSumahoPcs {
        nextToken
        startedAt
        __typename
      }
      LeavesLoginIds {
        nextToken
        startedAt
        __typename
      }
      LeavesYuujinChijins {
        nextToken
        startedAt
        __typename
      }
      LeavesKenkoukanris {
        nextToken
        startedAt
        __typename
      }
      LeavesSeikatsunokoto {
        id
        seikatsunokotonitsuite
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesTakaramonos {
        nextToken
        startedAt
        __typename
      }
      LeavesMyEvents {
        nextToken
        startedAt
        __typename
      }
      LeavesHakanitsuite {
        id
        hairuyoteinoohakanoumu
        kibousuruohakanokeishiki
        kibousurubasyo
        hiyoukyoshutsu
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesDaijinishitaikinenbis {
        nextToken
        startedAt
        __typename
      }
      LeavesKakeizus {
        nextToken
        startedAt
        __typename
      }
      LeavesYuigonshonitsuites {
        id
        yuigonshonoumu
        yuigonshonoshurui
        yuigonshonosaisyuusakuseibi
        yuigonshikkousha
        yuigonshikkoushanorenrakusaki
        iraisoudanwoshiteirusenmonka
        iraisoudanwoshiteirusenmonkanorenrakusaki
        sonotasouzokunikansurukibounado
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesTaisetsunishitaihitohenomessages {
        nextToken
        startedAt
        __typename
      }
      LeavesLoankariirekins {
        nextToken
        startedAt
        __typename
      }
      LeavesHosyousaimus {
        nextToken
        startedAt
        __typename
      }
      LeavesShareInfos {
        nextToken
        startedAt
        __typename
      }
      LeavesSharedInfos {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      leavesUsersLeavesKaigoId
      leavesUsersLeavesSougiId
      leavesUsersLeavesIryousochiId
      leavesUsersLeavesSeikatsunokotoId
      leavesUsersLeavesHakanitsuiteId
      leavesUsersLeavesYuigonshonitsuitesId
      owner
      __typename
    }
  }
`;
export const onDeleteLeavesUsers = /* GraphQL */ `
  subscription OnDeleteLeavesUsers(
    $filter: ModelSubscriptionLeavesUsersFilterInput
    $owner: String
  ) {
    onDeleteLeavesUsers(filter: $filter, owner: $owner) {
      id
      name
      loggedin
      kanshinnoaruCategory
      LeavesJitsubutsuShisans {
        nextToken
        startedAt
        __typename
      }
      LeavesKinyuuShisans {
        nextToken
        startedAt
        __typename
      }
      LeavesHokens {
        nextToken
        startedAt
        __typename
      }
      LeavesKaigo {
        id
        handanwoyudanetaihito
        kiboukaigoshurui
        kiboushisetsu
        hiyoukyoshutsu
        zaisankanriwomakaseruhito
        zaisankanriwomakaseruhitonokeiyakujoukyou
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesSougi {
        id
        sougiyouhi
        kibousougikeishiki
        souginosyurui
        sankaninzuukibo
        kibousuruhuniki
        junbishiteiruhiyou
        hiyoukyoshutsu
        hokenkyousainado
        souginiyondehoshiyuujinchijin
        yondehoshiihitonituite
        souginiyobukakazokuniitininsuruyuujinchijin
        itininsuruhitonituite
        souginoannaiwoshinakutemoyoihitonituite
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesLifeFutures {
        nextToken
        startedAt
        __typename
      }
      LeavesShuukans {
        nextToken
        startedAt
        __typename
      }
      LeavesMemos {
        nextToken
        startedAt
        __typename
      }
      LeavesKazokuShinzokus {
        nextToken
        startedAt
        __typename
      }
      LeavesJibunshis {
        nextToken
        startedAt
        __typename
      }
      LeavesHints {
        nextToken
        startedAt
        __typename
      }
      LeavesPets {
        nextToken
        startedAt
        __typename
      }
      LeavesIryousochi {
        id
        kokuchikibounoumu
        enmeisochikibounoumu
        zoukiteikyounoumu
        handanwoyudanetaihito
        ishikiganakunattarashirasetehoshiiyuujinchijin
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesCreditcards {
        nextToken
        startedAt
        __typename
      }
      LeavesKouzas {
        nextToken
        startedAt
        __typename
      }
      LeavesNenkins {
        nextToken
        startedAt
        __typename
      }
      LeavesKankonsousais {
        nextToken
        startedAt
        __typename
      }
      LeavesSumahoPcs {
        nextToken
        startedAt
        __typename
      }
      LeavesLoginIds {
        nextToken
        startedAt
        __typename
      }
      LeavesYuujinChijins {
        nextToken
        startedAt
        __typename
      }
      LeavesKenkoukanris {
        nextToken
        startedAt
        __typename
      }
      LeavesSeikatsunokoto {
        id
        seikatsunokotonitsuite
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesTakaramonos {
        nextToken
        startedAt
        __typename
      }
      LeavesMyEvents {
        nextToken
        startedAt
        __typename
      }
      LeavesHakanitsuite {
        id
        hairuyoteinoohakanoumu
        kibousuruohakanokeishiki
        kibousurubasyo
        hiyoukyoshutsu
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesDaijinishitaikinenbis {
        nextToken
        startedAt
        __typename
      }
      LeavesKakeizus {
        nextToken
        startedAt
        __typename
      }
      LeavesYuigonshonitsuites {
        id
        yuigonshonoumu
        yuigonshonoshurui
        yuigonshonosaisyuusakuseibi
        yuigonshikkousha
        yuigonshikkoushanorenrakusaki
        iraisoudanwoshiteirusenmonka
        iraisoudanwoshiteirusenmonkanorenrakusaki
        sonotasouzokunikansurukibounado
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesTaisetsunishitaihitohenomessages {
        nextToken
        startedAt
        __typename
      }
      LeavesLoankariirekins {
        nextToken
        startedAt
        __typename
      }
      LeavesHosyousaimus {
        nextToken
        startedAt
        __typename
      }
      LeavesShareInfos {
        nextToken
        startedAt
        __typename
      }
      LeavesSharedInfos {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      leavesUsersLeavesKaigoId
      leavesUsersLeavesSougiId
      leavesUsersLeavesIryousochiId
      leavesUsersLeavesSeikatsunokotoId
      leavesUsersLeavesHakanitsuiteId
      leavesUsersLeavesYuigonshonitsuitesId
      owner
      __typename
    }
  }
`;
export const onCreateLeavesJitsubutsuShisan = /* GraphQL */ `
  subscription OnCreateLeavesJitsubutsuShisan(
    $filter: ModelSubscriptionLeavesJitsubutsuShisanFilterInput
    $owner: String
  ) {
    onCreateLeavesJitsubutsuShisan(filter: $filter, owner: $owner) {
      id
      shisanmei
      shisanshurui
      shozaibasho
      bikou
      _shisanshurui
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLeavesJitsubutsuShisan = /* GraphQL */ `
  subscription OnUpdateLeavesJitsubutsuShisan(
    $filter: ModelSubscriptionLeavesJitsubutsuShisanFilterInput
    $owner: String
  ) {
    onUpdateLeavesJitsubutsuShisan(filter: $filter, owner: $owner) {
      id
      shisanmei
      shisanshurui
      shozaibasho
      bikou
      _shisanshurui
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLeavesJitsubutsuShisan = /* GraphQL */ `
  subscription OnDeleteLeavesJitsubutsuShisan(
    $filter: ModelSubscriptionLeavesJitsubutsuShisanFilterInput
    $owner: String
  ) {
    onDeleteLeavesJitsubutsuShisan(filter: $filter, owner: $owner) {
      id
      shisanmei
      shisanshurui
      shozaibasho
      bikou
      _shisanshurui
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateLeavesKinyuuShisan = /* GraphQL */ `
  subscription OnCreateLeavesKinyuuShisan(
    $filter: ModelSubscriptionLeavesKinyuuShisanFilterInput
    $owner: String
  ) {
    onCreateLeavesKinyuuShisan(filter: $filter, owner: $owner) {
      id
      shisanmei
      shisanshurui
      kinyuukikan
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLeavesKinyuuShisan = /* GraphQL */ `
  subscription OnUpdateLeavesKinyuuShisan(
    $filter: ModelSubscriptionLeavesKinyuuShisanFilterInput
    $owner: String
  ) {
    onUpdateLeavesKinyuuShisan(filter: $filter, owner: $owner) {
      id
      shisanmei
      shisanshurui
      kinyuukikan
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLeavesKinyuuShisan = /* GraphQL */ `
  subscription OnDeleteLeavesKinyuuShisan(
    $filter: ModelSubscriptionLeavesKinyuuShisanFilterInput
    $owner: String
  ) {
    onDeleteLeavesKinyuuShisan(filter: $filter, owner: $owner) {
      id
      shisanmei
      shisanshurui
      kinyuukikan
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateLeavesHoken = /* GraphQL */ `
  subscription OnCreateLeavesHoken(
    $filter: ModelSubscriptionLeavesHokenFilterInput
    $owner: String
  ) {
    onCreateLeavesHoken(filter: $filter, owner: $owner) {
      id
      hokenmei
      hokenshurui
      hokengaisha
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLeavesHoken = /* GraphQL */ `
  subscription OnUpdateLeavesHoken(
    $filter: ModelSubscriptionLeavesHokenFilterInput
    $owner: String
  ) {
    onUpdateLeavesHoken(filter: $filter, owner: $owner) {
      id
      hokenmei
      hokenshurui
      hokengaisha
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLeavesHoken = /* GraphQL */ `
  subscription OnDeleteLeavesHoken(
    $filter: ModelSubscriptionLeavesHokenFilterInput
    $owner: String
  ) {
    onDeleteLeavesHoken(filter: $filter, owner: $owner) {
      id
      hokenmei
      hokenshurui
      hokengaisha
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateLeavesKaigo = /* GraphQL */ `
  subscription OnCreateLeavesKaigo(
    $filter: ModelSubscriptionLeavesKaigoFilterInput
    $owner: String
  ) {
    onCreateLeavesKaigo(filter: $filter, owner: $owner) {
      id
      handanwoyudanetaihito
      kiboukaigoshurui
      kiboushisetsu
      hiyoukyoshutsu
      zaisankanriwomakaseruhito
      zaisankanriwomakaseruhitonokeiyakujoukyou
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLeavesKaigo = /* GraphQL */ `
  subscription OnUpdateLeavesKaigo(
    $filter: ModelSubscriptionLeavesKaigoFilterInput
    $owner: String
  ) {
    onUpdateLeavesKaigo(filter: $filter, owner: $owner) {
      id
      handanwoyudanetaihito
      kiboukaigoshurui
      kiboushisetsu
      hiyoukyoshutsu
      zaisankanriwomakaseruhito
      zaisankanriwomakaseruhitonokeiyakujoukyou
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLeavesKaigo = /* GraphQL */ `
  subscription OnDeleteLeavesKaigo(
    $filter: ModelSubscriptionLeavesKaigoFilterInput
    $owner: String
  ) {
    onDeleteLeavesKaigo(filter: $filter, owner: $owner) {
      id
      handanwoyudanetaihito
      kiboukaigoshurui
      kiboushisetsu
      hiyoukyoshutsu
      zaisankanriwomakaseruhito
      zaisankanriwomakaseruhitonokeiyakujoukyou
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateLeavesSougi = /* GraphQL */ `
  subscription OnCreateLeavesSougi(
    $filter: ModelSubscriptionLeavesSougiFilterInput
    $owner: String
  ) {
    onCreateLeavesSougi(filter: $filter, owner: $owner) {
      id
      sougiyouhi
      kibousougikeishiki
      souginosyurui
      sankaninzuukibo
      kibousuruhuniki
      junbishiteiruhiyou
      hiyoukyoshutsu
      hokenkyousainado
      souginiyondehoshiyuujinchijin
      yondehoshiihitonituite
      souginiyobukakazokuniitininsuruyuujinchijin
      itininsuruhitonituite
      souginoannaiwoshinakutemoyoihitonituite
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLeavesSougi = /* GraphQL */ `
  subscription OnUpdateLeavesSougi(
    $filter: ModelSubscriptionLeavesSougiFilterInput
    $owner: String
  ) {
    onUpdateLeavesSougi(filter: $filter, owner: $owner) {
      id
      sougiyouhi
      kibousougikeishiki
      souginosyurui
      sankaninzuukibo
      kibousuruhuniki
      junbishiteiruhiyou
      hiyoukyoshutsu
      hokenkyousainado
      souginiyondehoshiyuujinchijin
      yondehoshiihitonituite
      souginiyobukakazokuniitininsuruyuujinchijin
      itininsuruhitonituite
      souginoannaiwoshinakutemoyoihitonituite
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLeavesSougi = /* GraphQL */ `
  subscription OnDeleteLeavesSougi(
    $filter: ModelSubscriptionLeavesSougiFilterInput
    $owner: String
  ) {
    onDeleteLeavesSougi(filter: $filter, owner: $owner) {
      id
      sougiyouhi
      kibousougikeishiki
      souginosyurui
      sankaninzuukibo
      kibousuruhuniki
      junbishiteiruhiyou
      hiyoukyoshutsu
      hokenkyousainado
      souginiyondehoshiyuujinchijin
      yondehoshiihitonituite
      souginiyobukakazokuniitininsuruyuujinchijin
      itininsuruhitonituite
      souginoannaiwoshinakutemoyoihitonituite
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateLeavesLifeFuture = /* GraphQL */ `
  subscription OnCreateLeavesLifeFuture(
    $filter: ModelSubscriptionLeavesLifeFutureFilterInput
    $owner: String
  ) {
    onCreateLeavesLifeFuture(filter: $filter, owner: $owner) {
      id
      yaritaikoto
      jitsugennenreikigen
      tasseijoukyou
      tasseijoukyoushurui
      category
      yuusendo
      episode
      jitsugenstep {
        jitsugenstep
        shinchoku
        bikou
        __typename
      }
      leavesusersID
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLeavesLifeFuture = /* GraphQL */ `
  subscription OnUpdateLeavesLifeFuture(
    $filter: ModelSubscriptionLeavesLifeFutureFilterInput
    $owner: String
  ) {
    onUpdateLeavesLifeFuture(filter: $filter, owner: $owner) {
      id
      yaritaikoto
      jitsugennenreikigen
      tasseijoukyou
      tasseijoukyoushurui
      category
      yuusendo
      episode
      jitsugenstep {
        jitsugenstep
        shinchoku
        bikou
        __typename
      }
      leavesusersID
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLeavesLifeFuture = /* GraphQL */ `
  subscription OnDeleteLeavesLifeFuture(
    $filter: ModelSubscriptionLeavesLifeFutureFilterInput
    $owner: String
  ) {
    onDeleteLeavesLifeFuture(filter: $filter, owner: $owner) {
      id
      yaritaikoto
      jitsugennenreikigen
      tasseijoukyou
      tasseijoukyoushurui
      category
      yuusendo
      episode
      jitsugenstep {
        jitsugenstep
        shinchoku
        bikou
        __typename
      }
      leavesusersID
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateLeavesShuukan = /* GraphQL */ `
  subscription OnCreateLeavesShuukan(
    $filter: ModelSubscriptionLeavesShuukanFilterInput
    $owner: String
  ) {
    onCreateLeavesShuukan(filter: $filter, owner: $owner) {
      id
      yaritaikoto
      yuusendo
      episode
      leavesusersID
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLeavesShuukan = /* GraphQL */ `
  subscription OnUpdateLeavesShuukan(
    $filter: ModelSubscriptionLeavesShuukanFilterInput
    $owner: String
  ) {
    onUpdateLeavesShuukan(filter: $filter, owner: $owner) {
      id
      yaritaikoto
      yuusendo
      episode
      leavesusersID
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLeavesShuukan = /* GraphQL */ `
  subscription OnDeleteLeavesShuukan(
    $filter: ModelSubscriptionLeavesShuukanFilterInput
    $owner: String
  ) {
    onDeleteLeavesShuukan(filter: $filter, owner: $owner) {
      id
      yaritaikoto
      yuusendo
      episode
      leavesusersID
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateLeavesMemo = /* GraphQL */ `
  subscription OnCreateLeavesMemo(
    $filter: ModelSubscriptionLeavesMemoFilterInput
    $owner: String
  ) {
    onCreateLeavesMemo(filter: $filter, owner: $owner) {
      id
      title
      memo
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLeavesMemo = /* GraphQL */ `
  subscription OnUpdateLeavesMemo(
    $filter: ModelSubscriptionLeavesMemoFilterInput
    $owner: String
  ) {
    onUpdateLeavesMemo(filter: $filter, owner: $owner) {
      id
      title
      memo
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLeavesMemo = /* GraphQL */ `
  subscription OnDeleteLeavesMemo(
    $filter: ModelSubscriptionLeavesMemoFilterInput
    $owner: String
  ) {
    onDeleteLeavesMemo(filter: $filter, owner: $owner) {
      id
      title
      memo
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateLeavesKazokuShinzoku = /* GraphQL */ `
  subscription OnCreateLeavesKazokuShinzoku(
    $filter: ModelSubscriptionLeavesKazokuShinzokuFilterInput
    $owner: String
  ) {
    onCreateLeavesKazokuShinzoku(filter: $filter, owner: $owner) {
      id
      shimei
      zokugara
      seinengappi
      yuubinbangou
      juusho
      denwabangou
      mailaddress
      nyuuinjirenraku
      sougijirenraku
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLeavesKazokuShinzoku = /* GraphQL */ `
  subscription OnUpdateLeavesKazokuShinzoku(
    $filter: ModelSubscriptionLeavesKazokuShinzokuFilterInput
    $owner: String
  ) {
    onUpdateLeavesKazokuShinzoku(filter: $filter, owner: $owner) {
      id
      shimei
      zokugara
      seinengappi
      yuubinbangou
      juusho
      denwabangou
      mailaddress
      nyuuinjirenraku
      sougijirenraku
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLeavesKazokuShinzoku = /* GraphQL */ `
  subscription OnDeleteLeavesKazokuShinzoku(
    $filter: ModelSubscriptionLeavesKazokuShinzokuFilterInput
    $owner: String
  ) {
    onDeleteLeavesKazokuShinzoku(filter: $filter, owner: $owner) {
      id
      shimei
      zokugara
      seinengappi
      yuubinbangou
      juusho
      denwabangou
      mailaddress
      nyuuinjirenraku
      sougijirenraku
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateLeavesJibunshi = /* GraphQL */ `
  subscription OnCreateLeavesJibunshi(
    $filter: ModelSubscriptionLeavesJibunshiFilterInput
    $owner: String
  ) {
    onCreateLeavesJibunshi(filter: $filter, owner: $owner) {
      id
      nengetsu
      eventmei
      episode
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLeavesJibunshi = /* GraphQL */ `
  subscription OnUpdateLeavesJibunshi(
    $filter: ModelSubscriptionLeavesJibunshiFilterInput
    $owner: String
  ) {
    onUpdateLeavesJibunshi(filter: $filter, owner: $owner) {
      id
      nengetsu
      eventmei
      episode
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLeavesJibunshi = /* GraphQL */ `
  subscription OnDeleteLeavesJibunshi(
    $filter: ModelSubscriptionLeavesJibunshiFilterInput
    $owner: String
  ) {
    onDeleteLeavesJibunshi(filter: $filter, owner: $owner) {
      id
      nengetsu
      eventmei
      episode
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateLeavesHint = /* GraphQL */ `
  subscription OnCreateLeavesHint(
    $filter: ModelSubscriptionLeavesHintFilterInput
    $owner: String
  ) {
    onCreateLeavesHint(filter: $filter, owner: $owner) {
      id
      url
      title
      image
      type
      leavesusersID
      publishdate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLeavesHint = /* GraphQL */ `
  subscription OnUpdateLeavesHint(
    $filter: ModelSubscriptionLeavesHintFilterInput
    $owner: String
  ) {
    onUpdateLeavesHint(filter: $filter, owner: $owner) {
      id
      url
      title
      image
      type
      leavesusersID
      publishdate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLeavesHint = /* GraphQL */ `
  subscription OnDeleteLeavesHint(
    $filter: ModelSubscriptionLeavesHintFilterInput
    $owner: String
  ) {
    onDeleteLeavesHint(filter: $filter, owner: $owner) {
      id
      url
      title
      image
      type
      leavesusersID
      publishdate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateLeavesPet = /* GraphQL */ `
  subscription OnCreateLeavesPet(
    $filter: ModelSubscriptionLeavesPetFilterInput
    $owner: String
  ) {
    onCreateLeavesPet(filter: $filter, owner: $owner) {
      id
      namae
      shurui
      seinengappi
      seibetsu
      tourokubangou
      hokennoumu
      hokennkaisyamei
      kakaritsukenobyouinmei
      kakaritsukenobyouinnorenrakusaki
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLeavesPet = /* GraphQL */ `
  subscription OnUpdateLeavesPet(
    $filter: ModelSubscriptionLeavesPetFilterInput
    $owner: String
  ) {
    onUpdateLeavesPet(filter: $filter, owner: $owner) {
      id
      namae
      shurui
      seinengappi
      seibetsu
      tourokubangou
      hokennoumu
      hokennkaisyamei
      kakaritsukenobyouinmei
      kakaritsukenobyouinnorenrakusaki
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLeavesPet = /* GraphQL */ `
  subscription OnDeleteLeavesPet(
    $filter: ModelSubscriptionLeavesPetFilterInput
    $owner: String
  ) {
    onDeleteLeavesPet(filter: $filter, owner: $owner) {
      id
      namae
      shurui
      seinengappi
      seibetsu
      tourokubangou
      hokennoumu
      hokennkaisyamei
      kakaritsukenobyouinmei
      kakaritsukenobyouinnorenrakusaki
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateLeavesIryousochi = /* GraphQL */ `
  subscription OnCreateLeavesIryousochi(
    $filter: ModelSubscriptionLeavesIryousochiFilterInput
    $owner: String
  ) {
    onCreateLeavesIryousochi(filter: $filter, owner: $owner) {
      id
      kokuchikibounoumu
      enmeisochikibounoumu
      zoukiteikyounoumu
      handanwoyudanetaihito
      ishikiganakunattarashirasetehoshiiyuujinchijin
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLeavesIryousochi = /* GraphQL */ `
  subscription OnUpdateLeavesIryousochi(
    $filter: ModelSubscriptionLeavesIryousochiFilterInput
    $owner: String
  ) {
    onUpdateLeavesIryousochi(filter: $filter, owner: $owner) {
      id
      kokuchikibounoumu
      enmeisochikibounoumu
      zoukiteikyounoumu
      handanwoyudanetaihito
      ishikiganakunattarashirasetehoshiiyuujinchijin
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLeavesIryousochi = /* GraphQL */ `
  subscription OnDeleteLeavesIryousochi(
    $filter: ModelSubscriptionLeavesIryousochiFilterInput
    $owner: String
  ) {
    onDeleteLeavesIryousochi(filter: $filter, owner: $owner) {
      id
      kokuchikibounoumu
      enmeisochikibounoumu
      zoukiteikyounoumu
      handanwoyudanetaihito
      ishikiganakunattarashirasetehoshiiyuujinchijin
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateLeavesCreditcard = /* GraphQL */ `
  subscription OnCreateLeavesCreditcard(
    $filter: ModelSubscriptionLeavesCreditcardFilterInput
    $owner: String
  ) {
    onCreateLeavesCreditcard(filter: $filter, owner: $owner) {
      id
      creditcardmei
      cardbrand
      cardhakkougaisha
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLeavesCreditcard = /* GraphQL */ `
  subscription OnUpdateLeavesCreditcard(
    $filter: ModelSubscriptionLeavesCreditcardFilterInput
    $owner: String
  ) {
    onUpdateLeavesCreditcard(filter: $filter, owner: $owner) {
      id
      creditcardmei
      cardbrand
      cardhakkougaisha
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLeavesCreditcard = /* GraphQL */ `
  subscription OnDeleteLeavesCreditcard(
    $filter: ModelSubscriptionLeavesCreditcardFilterInput
    $owner: String
  ) {
    onDeleteLeavesCreditcard(filter: $filter, owner: $owner) {
      id
      creditcardmei
      cardbrand
      cardhakkougaisha
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateLeavesKouza = /* GraphQL */ `
  subscription OnCreateLeavesKouza(
    $filter: ModelSubscriptionLeavesKouzaFilterInput
    $owner: String
  ) {
    onCreateLeavesKouza(filter: $filter, owner: $owner) {
      id
      hikiotoshikoumoku
      hikiotoshikinyuukikan
      hikiotoshibi
      hikiotoshisakiKinyuuShisanYochokin
      hikiotoshisakiCreditcard
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLeavesKouza = /* GraphQL */ `
  subscription OnUpdateLeavesKouza(
    $filter: ModelSubscriptionLeavesKouzaFilterInput
    $owner: String
  ) {
    onUpdateLeavesKouza(filter: $filter, owner: $owner) {
      id
      hikiotoshikoumoku
      hikiotoshikinyuukikan
      hikiotoshibi
      hikiotoshisakiKinyuuShisanYochokin
      hikiotoshisakiCreditcard
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLeavesKouza = /* GraphQL */ `
  subscription OnDeleteLeavesKouza(
    $filter: ModelSubscriptionLeavesKouzaFilterInput
    $owner: String
  ) {
    onDeleteLeavesKouza(filter: $filter, owner: $owner) {
      id
      hikiotoshikoumoku
      hikiotoshikinyuukikan
      hikiotoshibi
      hikiotoshisakiKinyuuShisanYochokin
      hikiotoshisakiCreditcard
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateLeavesNenkin = /* GraphQL */ `
  subscription OnCreateLeavesNenkin(
    $filter: ModelSubscriptionLeavesNenkinFilterInput
    $owner: String
  ) {
    onCreateLeavesNenkin(filter: $filter, owner: $owner) {
      id
      nenkinmei
      nenkinshurui
      jyukyuugaku
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLeavesNenkin = /* GraphQL */ `
  subscription OnUpdateLeavesNenkin(
    $filter: ModelSubscriptionLeavesNenkinFilterInput
    $owner: String
  ) {
    onUpdateLeavesNenkin(filter: $filter, owner: $owner) {
      id
      nenkinmei
      nenkinshurui
      jyukyuugaku
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLeavesNenkin = /* GraphQL */ `
  subscription OnDeleteLeavesNenkin(
    $filter: ModelSubscriptionLeavesNenkinFilterInput
    $owner: String
  ) {
    onDeleteLeavesNenkin(filter: $filter, owner: $owner) {
      id
      nenkinmei
      nenkinshurui
      jyukyuugaku
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateLeavesKankonsousai = /* GraphQL */ `
  subscription OnCreateLeavesKankonsousai(
    $filter: ModelSubscriptionLeavesKankonsousaiFilterInput
    $owner: String
  ) {
    onCreateLeavesKankonsousai(filter: $filter, owner: $owner) {
      id
      eventmei
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLeavesKankonsousai = /* GraphQL */ `
  subscription OnUpdateLeavesKankonsousai(
    $filter: ModelSubscriptionLeavesKankonsousaiFilterInput
    $owner: String
  ) {
    onUpdateLeavesKankonsousai(filter: $filter, owner: $owner) {
      id
      eventmei
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLeavesKankonsousai = /* GraphQL */ `
  subscription OnDeleteLeavesKankonsousai(
    $filter: ModelSubscriptionLeavesKankonsousaiFilterInput
    $owner: String
  ) {
    onDeleteLeavesKankonsousai(filter: $filter, owner: $owner) {
      id
      eventmei
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateLeavesSumahoPc = /* GraphQL */ `
  subscription OnCreateLeavesSumahoPc(
    $filter: ModelSubscriptionLeavesSumahoPcFilterInput
    $owner: String
  ) {
    onCreateLeavesSumahoPc(filter: $filter, owner: $owner) {
      id
      kikishubetsu
      keiyakusaki
      denwabangou
      meiginin
      kishumei
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLeavesSumahoPc = /* GraphQL */ `
  subscription OnUpdateLeavesSumahoPc(
    $filter: ModelSubscriptionLeavesSumahoPcFilterInput
    $owner: String
  ) {
    onUpdateLeavesSumahoPc(filter: $filter, owner: $owner) {
      id
      kikishubetsu
      keiyakusaki
      denwabangou
      meiginin
      kishumei
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLeavesSumahoPc = /* GraphQL */ `
  subscription OnDeleteLeavesSumahoPc(
    $filter: ModelSubscriptionLeavesSumahoPcFilterInput
    $owner: String
  ) {
    onDeleteLeavesSumahoPc(filter: $filter, owner: $owner) {
      id
      kikishubetsu
      keiyakusaki
      denwabangou
      meiginin
      kishumei
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateLeavesLoginId = /* GraphQL */ `
  subscription OnCreateLeavesLoginId(
    $filter: ModelSubscriptionLeavesLoginIdFilterInput
    $owner: String
  ) {
    onCreateLeavesLoginId(filter: $filter, owner: $owner) {
      id
      servicemei
      siteurl
      loginid
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLeavesLoginId = /* GraphQL */ `
  subscription OnUpdateLeavesLoginId(
    $filter: ModelSubscriptionLeavesLoginIdFilterInput
    $owner: String
  ) {
    onUpdateLeavesLoginId(filter: $filter, owner: $owner) {
      id
      servicemei
      siteurl
      loginid
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLeavesLoginId = /* GraphQL */ `
  subscription OnDeleteLeavesLoginId(
    $filter: ModelSubscriptionLeavesLoginIdFilterInput
    $owner: String
  ) {
    onDeleteLeavesLoginId(filter: $filter, owner: $owner) {
      id
      servicemei
      siteurl
      loginid
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateLeavesYuujinChijin = /* GraphQL */ `
  subscription OnCreateLeavesYuujinChijin(
    $filter: ModelSubscriptionLeavesYuujinChijinFilterInput
    $owner: String
  ) {
    onCreateLeavesYuujinChijin(filter: $filter, owner: $owner) {
      id
      shimei
      kankeisei
      seinengappi
      juusho
      denwabangou
      mailaddress
      nyuuinjinorenraku
      sougijinorenraku
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLeavesYuujinChijin = /* GraphQL */ `
  subscription OnUpdateLeavesYuujinChijin(
    $filter: ModelSubscriptionLeavesYuujinChijinFilterInput
    $owner: String
  ) {
    onUpdateLeavesYuujinChijin(filter: $filter, owner: $owner) {
      id
      shimei
      kankeisei
      seinengappi
      juusho
      denwabangou
      mailaddress
      nyuuinjinorenraku
      sougijinorenraku
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLeavesYuujinChijin = /* GraphQL */ `
  subscription OnDeleteLeavesYuujinChijin(
    $filter: ModelSubscriptionLeavesYuujinChijinFilterInput
    $owner: String
  ) {
    onDeleteLeavesYuujinChijin(filter: $filter, owner: $owner) {
      id
      shimei
      kankeisei
      seinengappi
      juusho
      denwabangou
      mailaddress
      nyuuinjinorenraku
      sougijinorenraku
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateLeavesKenkoukanri = /* GraphQL */ `
  subscription OnCreateLeavesKenkoukanri(
    $filter: ModelSubscriptionLeavesKenkoukanriFilterInput
    $owner: String
  ) {
    onCreateLeavesKenkoukanri(filter: $filter, owner: $owner) {
      id
      byoumei
      hukuyouchuunokusuri
      youto
      youhou
      byouinmei
      chiryoukikan
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLeavesKenkoukanri = /* GraphQL */ `
  subscription OnUpdateLeavesKenkoukanri(
    $filter: ModelSubscriptionLeavesKenkoukanriFilterInput
    $owner: String
  ) {
    onUpdateLeavesKenkoukanri(filter: $filter, owner: $owner) {
      id
      byoumei
      hukuyouchuunokusuri
      youto
      youhou
      byouinmei
      chiryoukikan
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLeavesKenkoukanri = /* GraphQL */ `
  subscription OnDeleteLeavesKenkoukanri(
    $filter: ModelSubscriptionLeavesKenkoukanriFilterInput
    $owner: String
  ) {
    onDeleteLeavesKenkoukanri(filter: $filter, owner: $owner) {
      id
      byoumei
      hukuyouchuunokusuri
      youto
      youhou
      byouinmei
      chiryoukikan
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateLeavesSeikatsunokoto = /* GraphQL */ `
  subscription OnCreateLeavesSeikatsunokoto(
    $filter: ModelSubscriptionLeavesSeikatsunokotoFilterInput
    $owner: String
  ) {
    onCreateLeavesSeikatsunokoto(filter: $filter, owner: $owner) {
      id
      seikatsunokotonitsuite
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLeavesSeikatsunokoto = /* GraphQL */ `
  subscription OnUpdateLeavesSeikatsunokoto(
    $filter: ModelSubscriptionLeavesSeikatsunokotoFilterInput
    $owner: String
  ) {
    onUpdateLeavesSeikatsunokoto(filter: $filter, owner: $owner) {
      id
      seikatsunokotonitsuite
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLeavesSeikatsunokoto = /* GraphQL */ `
  subscription OnDeleteLeavesSeikatsunokoto(
    $filter: ModelSubscriptionLeavesSeikatsunokotoFilterInput
    $owner: String
  ) {
    onDeleteLeavesSeikatsunokoto(filter: $filter, owner: $owner) {
      id
      seikatsunokotonitsuite
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateLeavesTakaramono = /* GraphQL */ `
  subscription OnCreateLeavesTakaramono(
    $filter: ModelSubscriptionLeavesTakaramonoFilterInput
    $owner: String
  ) {
    onCreateLeavesTakaramono(filter: $filter, owner: $owner) {
      id
      collectionmei
      hokanbasyo
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLeavesTakaramono = /* GraphQL */ `
  subscription OnUpdateLeavesTakaramono(
    $filter: ModelSubscriptionLeavesTakaramonoFilterInput
    $owner: String
  ) {
    onUpdateLeavesTakaramono(filter: $filter, owner: $owner) {
      id
      collectionmei
      hokanbasyo
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLeavesTakaramono = /* GraphQL */ `
  subscription OnDeleteLeavesTakaramono(
    $filter: ModelSubscriptionLeavesTakaramonoFilterInput
    $owner: String
  ) {
    onDeleteLeavesTakaramono(filter: $filter, owner: $owner) {
      id
      collectionmei
      hokanbasyo
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateLeavesEvents = /* GraphQL */ `
  subscription OnCreateLeavesEvents(
    $filter: ModelSubscriptionLeavesEventsFilterInput
  ) {
    onCreateLeavesEvents(filter: $filter) {
      id
      code
      name
      startDate
      endDate
      date
      time
      closingDay
      address
      place
      mapUrl
      price
      confirmationNeeded
      applyEnabled
      imagePath
      eventPageUrl
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateLeavesEvents = /* GraphQL */ `
  subscription OnUpdateLeavesEvents(
    $filter: ModelSubscriptionLeavesEventsFilterInput
  ) {
    onUpdateLeavesEvents(filter: $filter) {
      id
      code
      name
      startDate
      endDate
      date
      time
      closingDay
      address
      place
      mapUrl
      price
      confirmationNeeded
      applyEnabled
      imagePath
      eventPageUrl
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteLeavesEvents = /* GraphQL */ `
  subscription OnDeleteLeavesEvents(
    $filter: ModelSubscriptionLeavesEventsFilterInput
  ) {
    onDeleteLeavesEvents(filter: $filter) {
      id
      code
      name
      startDate
      endDate
      date
      time
      closingDay
      address
      place
      mapUrl
      price
      confirmationNeeded
      applyEnabled
      imagePath
      eventPageUrl
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateLeavesMyEvent = /* GraphQL */ `
  subscription OnCreateLeavesMyEvent(
    $filter: ModelSubscriptionLeavesMyEventFilterInput
    $owner: String
  ) {
    onCreateLeavesMyEvent(filter: $filter, owner: $owner) {
      id
      leavesusersID
      leavesEventsID
      confirmed
      applied
      note
      rate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLeavesMyEvent = /* GraphQL */ `
  subscription OnUpdateLeavesMyEvent(
    $filter: ModelSubscriptionLeavesMyEventFilterInput
    $owner: String
  ) {
    onUpdateLeavesMyEvent(filter: $filter, owner: $owner) {
      id
      leavesusersID
      leavesEventsID
      confirmed
      applied
      note
      rate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLeavesMyEvent = /* GraphQL */ `
  subscription OnDeleteLeavesMyEvent(
    $filter: ModelSubscriptionLeavesMyEventFilterInput
    $owner: String
  ) {
    onDeleteLeavesMyEvent(filter: $filter, owner: $owner) {
      id
      leavesusersID
      leavesEventsID
      confirmed
      applied
      note
      rate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateLeavesHakanitsuite = /* GraphQL */ `
  subscription OnCreateLeavesHakanitsuite(
    $filter: ModelSubscriptionLeavesHakanitsuiteFilterInput
    $owner: String
  ) {
    onCreateLeavesHakanitsuite(filter: $filter, owner: $owner) {
      id
      hairuyoteinoohakanoumu
      kibousuruohakanokeishiki
      kibousurubasyo
      hiyoukyoshutsu
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLeavesHakanitsuite = /* GraphQL */ `
  subscription OnUpdateLeavesHakanitsuite(
    $filter: ModelSubscriptionLeavesHakanitsuiteFilterInput
    $owner: String
  ) {
    onUpdateLeavesHakanitsuite(filter: $filter, owner: $owner) {
      id
      hairuyoteinoohakanoumu
      kibousuruohakanokeishiki
      kibousurubasyo
      hiyoukyoshutsu
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLeavesHakanitsuite = /* GraphQL */ `
  subscription OnDeleteLeavesHakanitsuite(
    $filter: ModelSubscriptionLeavesHakanitsuiteFilterInput
    $owner: String
  ) {
    onDeleteLeavesHakanitsuite(filter: $filter, owner: $owner) {
      id
      hairuyoteinoohakanoumu
      kibousuruohakanokeishiki
      kibousurubasyo
      hiyoukyoshutsu
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateLeavesDaijinishitaikinenbi = /* GraphQL */ `
  subscription OnCreateLeavesDaijinishitaikinenbi(
    $filter: ModelSubscriptionLeavesDaijinishitaikinenbiFilterInput
    $owner: String
  ) {
    onCreateLeavesDaijinishitaikinenbi(filter: $filter, owner: $owner) {
      id
      kinenbimei
      hiduke
      konohigachikazuitaratsuuchi
      tsuuchisakiKazokuShinzoku
      tsuuchisakiYuujinChijin
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLeavesDaijinishitaikinenbi = /* GraphQL */ `
  subscription OnUpdateLeavesDaijinishitaikinenbi(
    $filter: ModelSubscriptionLeavesDaijinishitaikinenbiFilterInput
    $owner: String
  ) {
    onUpdateLeavesDaijinishitaikinenbi(filter: $filter, owner: $owner) {
      id
      kinenbimei
      hiduke
      konohigachikazuitaratsuuchi
      tsuuchisakiKazokuShinzoku
      tsuuchisakiYuujinChijin
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLeavesDaijinishitaikinenbi = /* GraphQL */ `
  subscription OnDeleteLeavesDaijinishitaikinenbi(
    $filter: ModelSubscriptionLeavesDaijinishitaikinenbiFilterInput
    $owner: String
  ) {
    onDeleteLeavesDaijinishitaikinenbi(filter: $filter, owner: $owner) {
      id
      kinenbimei
      hiduke
      konohigachikazuitaratsuuchi
      tsuuchisakiKazokuShinzoku
      tsuuchisakiYuujinChijin
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateLeavesKakeizu = /* GraphQL */ `
  subscription OnCreateLeavesKakeizu(
    $filter: ModelSubscriptionLeavesKakeizuFilterInput
    $owner: String
  ) {
    onCreateLeavesKakeizu(filter: $filter, owner: $owner) {
      id
      filemei
      hokanbasyo
      bikou
      s3key
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLeavesKakeizu = /* GraphQL */ `
  subscription OnUpdateLeavesKakeizu(
    $filter: ModelSubscriptionLeavesKakeizuFilterInput
    $owner: String
  ) {
    onUpdateLeavesKakeizu(filter: $filter, owner: $owner) {
      id
      filemei
      hokanbasyo
      bikou
      s3key
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLeavesKakeizu = /* GraphQL */ `
  subscription OnDeleteLeavesKakeizu(
    $filter: ModelSubscriptionLeavesKakeizuFilterInput
    $owner: String
  ) {
    onDeleteLeavesKakeizu(filter: $filter, owner: $owner) {
      id
      filemei
      hokanbasyo
      bikou
      s3key
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateLeavesYuigonshonitsuite = /* GraphQL */ `
  subscription OnCreateLeavesYuigonshonitsuite(
    $filter: ModelSubscriptionLeavesYuigonshonitsuiteFilterInput
    $owner: String
  ) {
    onCreateLeavesYuigonshonitsuite(filter: $filter, owner: $owner) {
      id
      yuigonshonoumu
      yuigonshonoshurui
      yuigonshonosaisyuusakuseibi
      yuigonshikkousha
      yuigonshikkoushanorenrakusaki
      iraisoudanwoshiteirusenmonka
      iraisoudanwoshiteirusenmonkanorenrakusaki
      sonotasouzokunikansurukibounado
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLeavesYuigonshonitsuite = /* GraphQL */ `
  subscription OnUpdateLeavesYuigonshonitsuite(
    $filter: ModelSubscriptionLeavesYuigonshonitsuiteFilterInput
    $owner: String
  ) {
    onUpdateLeavesYuigonshonitsuite(filter: $filter, owner: $owner) {
      id
      yuigonshonoumu
      yuigonshonoshurui
      yuigonshonosaisyuusakuseibi
      yuigonshikkousha
      yuigonshikkoushanorenrakusaki
      iraisoudanwoshiteirusenmonka
      iraisoudanwoshiteirusenmonkanorenrakusaki
      sonotasouzokunikansurukibounado
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLeavesYuigonshonitsuite = /* GraphQL */ `
  subscription OnDeleteLeavesYuigonshonitsuite(
    $filter: ModelSubscriptionLeavesYuigonshonitsuiteFilterInput
    $owner: String
  ) {
    onDeleteLeavesYuigonshonitsuite(filter: $filter, owner: $owner) {
      id
      yuigonshonoumu
      yuigonshonoshurui
      yuigonshonosaisyuusakuseibi
      yuigonshikkousha
      yuigonshikkoushanorenrakusaki
      iraisoudanwoshiteirusenmonka
      iraisoudanwoshiteirusenmonkanorenrakusaki
      sonotasouzokunikansurukibounado
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateLeavesTaisetsunishitaihitohenomessage = /* GraphQL */ `
  subscription OnCreateLeavesTaisetsunishitaihitohenomessage(
    $filter: ModelSubscriptionLeavesTaisetsunishitaihitohenomessageFilterInput
    $owner: String
  ) {
    onCreateLeavesTaisetsunishitaihitohenomessage(
      filter: $filter
      owner: $owner
    ) {
      id
      messagetitle
      soushinsaki
      soushinsakiYuujinChijin
      messagenaiyou
      soushinyoteibi
      isSentMessage
      soushinbi
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLeavesTaisetsunishitaihitohenomessage = /* GraphQL */ `
  subscription OnUpdateLeavesTaisetsunishitaihitohenomessage(
    $filter: ModelSubscriptionLeavesTaisetsunishitaihitohenomessageFilterInput
    $owner: String
  ) {
    onUpdateLeavesTaisetsunishitaihitohenomessage(
      filter: $filter
      owner: $owner
    ) {
      id
      messagetitle
      soushinsaki
      soushinsakiYuujinChijin
      messagenaiyou
      soushinyoteibi
      isSentMessage
      soushinbi
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLeavesTaisetsunishitaihitohenomessage = /* GraphQL */ `
  subscription OnDeleteLeavesTaisetsunishitaihitohenomessage(
    $filter: ModelSubscriptionLeavesTaisetsunishitaihitohenomessageFilterInput
    $owner: String
  ) {
    onDeleteLeavesTaisetsunishitaihitohenomessage(
      filter: $filter
      owner: $owner
    ) {
      id
      messagetitle
      soushinsaki
      soushinsakiYuujinChijin
      messagenaiyou
      soushinyoteibi
      isSentMessage
      soushinbi
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateLeavesLoankariirekin = /* GraphQL */ `
  subscription OnCreateLeavesLoankariirekin(
    $filter: ModelSubscriptionLeavesLoankariirekinFilterInput
    $owner: String
  ) {
    onCreateLeavesLoankariirekin(filter: $filter, owner: $owner) {
      id
      kariiresaki
      kariiresakinorenrakusaki
      kariirebi
      kariiregaku
      hensaihouhou
      tanponoumu
      kariirezandaka
      kariirezandakakisaibi
      kariiremokuteki
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLeavesLoankariirekin = /* GraphQL */ `
  subscription OnUpdateLeavesLoankariirekin(
    $filter: ModelSubscriptionLeavesLoankariirekinFilterInput
    $owner: String
  ) {
    onUpdateLeavesLoankariirekin(filter: $filter, owner: $owner) {
      id
      kariiresaki
      kariiresakinorenrakusaki
      kariirebi
      kariiregaku
      hensaihouhou
      tanponoumu
      kariirezandaka
      kariirezandakakisaibi
      kariiremokuteki
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLeavesLoankariirekin = /* GraphQL */ `
  subscription OnDeleteLeavesLoankariirekin(
    $filter: ModelSubscriptionLeavesLoankariirekinFilterInput
    $owner: String
  ) {
    onDeleteLeavesLoankariirekin(filter: $filter, owner: $owner) {
      id
      kariiresaki
      kariiresakinorenrakusaki
      kariirebi
      kariiregaku
      hensaihouhou
      tanponoumu
      kariirezandaka
      kariirezandakakisaibi
      kariiremokuteki
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateLeavesHosyousaimu = /* GraphQL */ `
  subscription OnCreateLeavesHosyousaimu(
    $filter: ModelSubscriptionLeavesHosyousaimuFilterInput
    $owner: String
  ) {
    onCreateLeavesHosyousaimu(filter: $filter, owner: $owner) {
      id
      syusaimusya
      syusaimusyanorenrakusaki
      hosyoushitahi
      hosyoushitakingaku
      saikensya
      saikensyanorenrakusaki
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLeavesHosyousaimu = /* GraphQL */ `
  subscription OnUpdateLeavesHosyousaimu(
    $filter: ModelSubscriptionLeavesHosyousaimuFilterInput
    $owner: String
  ) {
    onUpdateLeavesHosyousaimu(filter: $filter, owner: $owner) {
      id
      syusaimusya
      syusaimusyanorenrakusaki
      hosyoushitahi
      hosyoushitakingaku
      saikensya
      saikensyanorenrakusaki
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLeavesHosyousaimu = /* GraphQL */ `
  subscription OnDeleteLeavesHosyousaimu(
    $filter: ModelSubscriptionLeavesHosyousaimuFilterInput
    $owner: String
  ) {
    onDeleteLeavesHosyousaimu(filter: $filter, owner: $owner) {
      id
      syusaimusya
      syusaimusyanorenrakusaki
      hosyoushitahi
      hosyoushitakingaku
      saikensya
      saikensyanorenrakusaki
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateLeavesShareInfo = /* GraphQL */ `
  subscription OnCreateLeavesShareInfo(
    $filter: ModelSubscriptionLeavesShareInfoFilterInput
    $owner: String
  ) {
    onCreateLeavesShareInfo(filter: $filter, owner: $owner) {
      id
      name
      originDate
      target {
        dataName
        shareDate
        __typename
      }
      shareId
      shareIdExpiredDate
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLeavesShareInfo = /* GraphQL */ `
  subscription OnUpdateLeavesShareInfo(
    $filter: ModelSubscriptionLeavesShareInfoFilterInput
    $owner: String
  ) {
    onUpdateLeavesShareInfo(filter: $filter, owner: $owner) {
      id
      name
      originDate
      target {
        dataName
        shareDate
        __typename
      }
      shareId
      shareIdExpiredDate
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLeavesShareInfo = /* GraphQL */ `
  subscription OnDeleteLeavesShareInfo(
    $filter: ModelSubscriptionLeavesShareInfoFilterInput
    $owner: String
  ) {
    onDeleteLeavesShareInfo(filter: $filter, owner: $owner) {
      id
      name
      originDate
      target {
        dataName
        shareDate
        __typename
      }
      shareId
      shareIdExpiredDate
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateLeavesSharedInfo = /* GraphQL */ `
  subscription OnCreateLeavesSharedInfo(
    $filter: ModelSubscriptionLeavesSharedInfoFilterInput
    $owner: String
  ) {
    onCreateLeavesSharedInfo(filter: $filter, owner: $owner) {
      id
      name
      shareInfoId
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLeavesSharedInfo = /* GraphQL */ `
  subscription OnUpdateLeavesSharedInfo(
    $filter: ModelSubscriptionLeavesSharedInfoFilterInput
    $owner: String
  ) {
    onUpdateLeavesSharedInfo(filter: $filter, owner: $owner) {
      id
      name
      shareInfoId
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLeavesSharedInfo = /* GraphQL */ `
  subscription OnDeleteLeavesSharedInfo(
    $filter: ModelSubscriptionLeavesSharedInfoFilterInput
    $owner: String
  ) {
    onDeleteLeavesSharedInfo(filter: $filter, owner: $owner) {
      id
      name
      shareInfoId
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateLeavesSummaryWriting = /* GraphQL */ `
  subscription OnCreateLeavesSummaryWriting(
    $filter: ModelSubscriptionLeavesSummaryWritingFilterInput
    $owner: String
  ) {
    onCreateLeavesSummaryWriting(filter: $filter, owner: $owner) {
      id
      firstLoginAt
      lastLoginAt
      writingItems {
        itemModelName
        itemId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLeavesSummaryWriting = /* GraphQL */ `
  subscription OnUpdateLeavesSummaryWriting(
    $filter: ModelSubscriptionLeavesSummaryWritingFilterInput
    $owner: String
  ) {
    onUpdateLeavesSummaryWriting(filter: $filter, owner: $owner) {
      id
      firstLoginAt
      lastLoginAt
      writingItems {
        itemModelName
        itemId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLeavesSummaryWriting = /* GraphQL */ `
  subscription OnDeleteLeavesSummaryWriting(
    $filter: ModelSubscriptionLeavesSummaryWritingFilterInput
    $owner: String
  ) {
    onDeleteLeavesSummaryWriting(filter: $filter, owner: $owner) {
      id
      firstLoginAt
      lastLoginAt
      writingItems {
        itemModelName
        itemId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateLeavesMindTypeAnswerId = /* GraphQL */ `
  subscription OnCreateLeavesMindTypeAnswerId(
    $filter: ModelSubscriptionLeavesMindTypeAnswerIdFilterInput
    $owner: String
  ) {
    onCreateLeavesMindTypeAnswerId(filter: $filter, owner: $owner) {
      id
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateLeavesMindTypeAnswerId = /* GraphQL */ `
  subscription OnUpdateLeavesMindTypeAnswerId(
    $filter: ModelSubscriptionLeavesMindTypeAnswerIdFilterInput
    $owner: String
  ) {
    onUpdateLeavesMindTypeAnswerId(filter: $filter, owner: $owner) {
      id
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteLeavesMindTypeAnswerId = /* GraphQL */ `
  subscription OnDeleteLeavesMindTypeAnswerId(
    $filter: ModelSubscriptionLeavesMindTypeAnswerIdFilterInput
    $owner: String
  ) {
    onDeleteLeavesMindTypeAnswerId(filter: $filter, owner: $owner) {
      id
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateLeavesMindTypeAnswer = /* GraphQL */ `
  subscription OnCreateLeavesMindTypeAnswer(
    $filter: ModelSubscriptionLeavesMindTypeAnswerFilterInput
    $owner: String
  ) {
    onCreateLeavesMindTypeAnswer(filter: $filter, owner: $owner) {
      answerId
      page
      questionNo
      answer
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLeavesMindTypeAnswer = /* GraphQL */ `
  subscription OnUpdateLeavesMindTypeAnswer(
    $filter: ModelSubscriptionLeavesMindTypeAnswerFilterInput
    $owner: String
  ) {
    onUpdateLeavesMindTypeAnswer(filter: $filter, owner: $owner) {
      answerId
      page
      questionNo
      answer
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLeavesMindTypeAnswer = /* GraphQL */ `
  subscription OnDeleteLeavesMindTypeAnswer(
    $filter: ModelSubscriptionLeavesMindTypeAnswerFilterInput
    $owner: String
  ) {
    onDeleteLeavesMindTypeAnswer(filter: $filter, owner: $owner) {
      answerId
      page
      questionNo
      answer
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
