import { isMobile } from "react-device-detect";
import { PrimaryButtonIcon as PrimaryButton } from "../ui-components";

export default function PrimaryButtonIcon(props) {
  const disableProps = { ...props };
  disableProps.onClick = null;

  return (
    <>
      {props.isDisabled ? (
        <PrimaryButton
          width="fit-content"
          margin="32px auto"
          backgroundColor="#EFF0F0"
          overrides={{
            "Flex.Flex[0].Text[0]": {
              children: props.label,
              color: "#5C6670",
              fontSize: isMobile ? "14px" : "16px",
            },
            "Flex.Flex[0].IconAdd[0]": {
              color: "#5C6670",
            },
          }}
          {...disableProps}
        />
      ) : (
        <PrimaryButton
          className={"pointer"}
          width="fit-content"
          margin="32px auto"
          onClick={props.onClick}
          overrides={{
            "Flex.Flex[0].Text[0]": {
              children: props.label,
              fontSize: isMobile ? "14px" : "16px",
            },
          }}
          {...props}
        />
      )}
    </>
  );
}
