import { DataStore } from "aws-amplify";
import { useCallback, useEffect, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useParams } from "react-router-dom";
import { LeavesLoginId } from "../models";
import { ListViewDetail } from "../page-components";
import { useInitializePage } from "../utils/CommonUtils";
import { isShare, queryData } from "../utils/ShareUtils";

function LoginIDViewPage() {
  const navigate = useInitializePage("kakushuserviceid", "title.detail");
  const [loginIDData, setLoginIDData] = useState({
    servicemei: "",
    siteurl: "",
    loginid: "",
    bikou: "",
  });
  const params = useParams();
  const deleteData = useCallback(async () => {
    await DataStore.delete(loginIDData);
    navigate(-1, { replace: true });
  }, [loginIDData, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const data = await queryData(params, LeavesLoginId);
      if (data?.length > 0) {
        setLoginIDData(data[0]);
      }
    };
    getData();
  }, [params.id]);

  const listLabel = [
    { label: "servicemei", value: loginIDData?.servicemei, isRequire: true },
    { label: "siteurl", value: loginIDData?.siteurl, isMulti: true },
    { label: "loginid", value: loginIDData?.loginid },
    { label: "bikou", value: loginIDData?.bikou, isMulti: true },
  ];

  return (
    <ListViewDetail
      label={loginIDData?.servicemei}
      list={listLabel}
      isHideEdit={isShare(params)}
      onDelete={deleteData}
      onEdit={() => {
        navigate("edit");
        window.gtag("event", "Change", {
          event_category: "Edit",
          event_label: "WEBサービスのログイン情報変更開始",
        });
      }}
    />
  );
}

export default LoginIDViewPage;
