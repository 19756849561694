import { DataStore } from "aws-amplify";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { LeavesHosyousaimu, LeavesLoankariirekin } from "../models";
import { ListField } from "../page-components";
import UserContext from "../UserContext";
import {
  getLabel,
  isEmpty,
  isNotEmpty,
  isNotEmptyDate,
  isInvalidDateFormat,
  isValidNumberFormat,
  useInitializePage,
} from "../utils/CommonUtils";
import { TANPONOUMU, useDatalist } from "../utils/DatalistUtils";
import { usePrompt } from "../utils/Prompt";

function LoanAddPage() {
  const navigate = useInitializePage("loankariirekin", "title.add");
  const context = useContext(UserContext);
  const [category, setCategory] = useState({ torokunaiyo: "loankariirekin" });
  const [loanData, setLoanData] = useState({
    kariiresaki: "",
    kariiresakinorenrakusaki: "",
    kariirebi: "",
    kariiregaku: "",
    hensaihouhou: "",
    kariirezandaka: "",
    kariirezandakakisaibi: "",
    kariiremokuteki: "",
    bikou: "",
    tanponoumu: "",
  });
  const [hosyoData, setHosyoData] = useState({
    syusaimusya: "",
    syusaimusyanorenrakusaki: "",
    hosyoushitahi: "",
    hosyoushitakingaku: "",
    saikensya: "",
    saikensyanorenrakusaki: "",
    bikou: "",
  });
  const sonotaData = useRef({ value: "" });
  const [loanDataError, setLoanDataError] = useState({
    kariiresakiError: "",
    kariirebiError: "",
    kariiregakuError: "",
    kariirezandakakisaibiError: "",
    kariirezandakaError: "",
  });
  const [hosyoDataError, setHosyoDataError] = useState({
    syusaimusyaError: "",
    hosyoushitahiError: "",
    hosyoushitakingakuError: "",
  });
  const [isAdded, setIsAdded] = useState(false);
  const [update, setUpdate] = useState(false);
  const [TanponoumuDataList, setTanponoumu, getTanponoumu, hasErrorTanponoumu] =
    useDatalist({
      list: TANPONOUMU,
      dataUpdateHook: setUpdate,
      custemField: {
        value: "ARU",
        placeHolder: getLabel("aru.placeholder"),
      },
    });
  const loanListField = useMemo(() => {
    return [
      {
        type: "textField",
        label: "kariiresaki",
        isRequire: true,
        placeHolder: "kinyuukikanmeinado",
        value: loanData?.kariiresaki,
        hasError: isNotEmpty(loanDataError?.kariiresakiError),
        errorMessage: loanDataError?.kariiresakiError,
      },
      {
        type: "textArea",
        label: "kariiresakinorenrakusaki",
        value: loanData?.kariiresakinorenrakusaki,
      },
      {
        type: "textField",
        label: "kariirebi",
        typeInput: "date",
        value: loanData?.kariirebi,
        hasError: isNotEmpty(loanDataError?.kariirebiError),
        errorMessage: loanDataError?.kariirebiError,
      },
      {
        type: "textField",
        label: "kariiregaku",
        typeInput: "number",
        maxLength: 100,
        value: loanData?.kariiregaku,
        hasError: isNotEmpty(loanDataError?.kariiregakuError),
        errorMessage: loanDataError?.kariiregakuError,
      },
      {
        type: "textField",
        label: "hensaihouhou",
        value: loanData?.hensaihouhou,
        placeHolder: "hutsuuyokinnado",
      },
      {
        type: "dataList",
        child: (
          <TanponoumuDataList
            label="tanponoumu"
            initValue={sonotaData.current.value}
          />
        ),
        hasError: hasErrorTanponoumu,
      },
      {
        type: "textField",
        label: "kariirezandaka",
        typeInput: "number",
        maxLength: 100,
        value: loanData?.kariirezandaka,
        hasError: isNotEmpty(loanDataError?.kariirezandakaError),
        errorMessage: loanDataError?.kariirezandakaError,
      },
      {
        type: "textField",
        label: "kariirezandakakisaibi",
        typeInput: "date",
        value: loanData?.kariirezandakakisaibi,
        hasError: isNotEmpty(loanDataError?.kariirezandakakisaibiError),
        errorMessage: loanDataError?.kariirezandakakisaibiError,
      },
      {
        type: "textField",
        label: "kariiremokuteki",
        value: loanData?.kariiremokuteki,
        placeHolder: "jyuutakuloannado",
      },
      { type: "textArea", label: "bikou", value: loanData?.bikou },
    ];
  }, [
    hasErrorTanponoumu,
    loanData?.bikou,
    loanData?.hensaihouhou,
    loanData?.kariirebi,
    loanData?.kariiregaku,
    loanData?.kariiremokuteki,
    loanData?.kariiresaki,
    loanData?.kariiresakinorenrakusaki,
    loanData?.kariirezandaka,
    loanData?.kariirezandakakisaibi,
    loanDataError?.kariirebiError,
    loanDataError?.kariiregakuError,
    loanDataError?.kariiresakiError,
    loanDataError?.kariirezandakaError,
    loanDataError?.kariirezandakakisaibiError,
  ]);

  const hosyoListField = useMemo(() => {
    return [
      {
        type: "textField",
        label: "syusaimusya",
        isRequire: true,
        value: hosyoData?.syusaimusya,
        placeHolder: "anatagahosyousitahitononamae",
        hasError: isNotEmpty(hosyoDataError?.syusaimusyaError),
        errorMessage: hosyoDataError?.syusaimusyaError,
      },
      {
        type: "textArea",
        label: "syusaimusyanorenrakusaki",
        value: hosyoData?.syusaimusyanorenrakusaki,
      },
      {
        type: "textField",
        label: "hosyoushitahi",
        typeInput: "date",
        value: hosyoData?.hosyoushitahi,
        hasError: isNotEmpty(hosyoDataError?.hosyoushitahiError),
        errorMessage: hosyoDataError?.hosyoushitahiError,
      },
      {
        type: "textField",
        label: "hosyoushitakingaku",
        typeInput: "number",
        maxLength: 100,
        value: hosyoData?.hosyoushitakingaku,
        hasError: isNotEmpty(hosyoDataError?.hosyoushitakingakuError),
        errorMessage: hosyoDataError?.hosyoushitakingakuError,
      },
      {
        type: "textField",
        label: "saikensya",
        placeHolder: "okanewokashitahitononamaeyakikanmei",
        value: hosyoData?.saikensya,
      },
      {
        type: "textArea",
        label: "saikensyanorenrakusaki",
        value: hosyoData?.saikensyanorenrakusaki,
      },
      {
        type: "textArea",
        label: "bikou",
        value: hosyoData?.bikou,
      },
    ];
  }, [
    hosyoData?.bikou,
    hosyoData?.hosyoushitahi,
    hosyoData?.hosyoushitakingaku,
    hosyoData?.saikensya,
    hosyoData?.saikensyanorenrakusaki,
    hosyoData?.syusaimusya,
    hosyoData?.syusaimusyanorenrakusaki,
    hosyoDataError?.hosyoushitahiError,
    hosyoDataError?.hosyoushitakingakuError,
    hosyoDataError?.syusaimusyaError,
  ]);

  const categoryField = useMemo(() => {
    if (category?.torokunaiyo === "loankariirekin") {
      return loanListField;
    } else {
      return hosyoListField;
    }
  }, [category?.torokunaiyo, hosyoListField, loanListField]);

  const listField = [
    {
      type: "ratioGroup",
      label: "torokunaiyo",
      value: category?.torokunaiyo,
      setStateRatio: setCategory,
      options: [
        {
          id: 0,
          text: getLabel("loankariirekin"),
          value: "loankariirekin",
        },
        {
          id: 1,
          text: getLabel("hosyousaimu"),
          value: "hosyousaimu",
        },
      ],
    },
    ...categoryField,
  ];

  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  const addLoanData = useCallback(async () => {
    let valid = true;
    sonotaData.current.value = getTanponoumu();
    const dateFormatCheckKariirebi = isInvalidDateFormat(loanData?.kariirebi);
    const dateFormatCheckKariirezandakakisaibi = isInvalidDateFormat(
      loanData?.kariirezandakakisaibi,
    );
    const validNumberKariiregaku = isValidNumberFormat(loanData?.kariiregaku);
    const validNumberKariirezandaka = isValidNumberFormat(
      loanData?.kariirezandaka,
    );
    const error = { ...loanDataError };
    if (isEmpty(loanData?.kariiresaki)) {
      error.kariiresakiError = getLabel("error.required");
      valid = false;
    } else {
      error.kariiresakiError = "";
    }
    if (dateFormatCheckKariirebi) {
      error.kariirebiError = getLabel("error.dateformat");
      valid = false;
    } else {
      error.kariirebiError = "";
    }
    if (dateFormatCheckKariirezandakakisaibi) {
      error.kariirezandakakisaibiError = getLabel("error.dateformat");
      valid = false;
    } else {
      error.kariirezandakakisaibiError = "";
    }
    if (!validNumberKariiregaku) {
      error.kariiregakuError = getLabel("error.number");
      valid = false;
    } else {
      error.kariiregakuError = "";
    }
    if (!validNumberKariirezandaka) {
      error.kariirezandakaError = getLabel("error.number");
      valid = false;
    } else {
      error.kariirezandakaError = "";
    }
    if (hasErrorTanponoumu()) valid = false;

    setLoanDataError(error);
    if (valid) {
      setIsAdded(true);
      await DataStore.save(
        new LeavesLoankariirekin({
          kariiresaki: loanData?.kariiresaki,
          kariiresakinorenrakusaki: loanData?.kariiresakinorenrakusaki,
          kariirebi: isNotEmptyDate(loanData?.kariirebi)
            ? loanData?.kariirebi
            : null,
          kariiregaku: loanData?.kariiregaku,
          hensaihouhou: loanData?.hensaihouhou,
          tanponoumu: getTanponoumu(),
          kariirezandaka: loanData?.kariirezandaka,
          kariirezandakakisaibi: isNotEmptyDate(loanData?.kariirezandakakisaibi)
            ? loanData?.kariirezandakakisaibi
            : null,
          kariiremokuteki: loanData?.kariiremokuteki,
          bikou: loanData?.bikou,
          leavesusersID: context.leavesUser.id,
        }),
      );
      navigate(-1, { replace: true });
    }
  }, [
    context.leavesUser.id,
    getTanponoumu,
    hasErrorTanponoumu,
    loanData?.bikou,
    loanData?.hensaihouhou,
    loanData?.kariirebi,
    loanData?.kariiregaku,
    loanData?.kariiremokuteki,
    loanData?.kariiresaki,
    loanData?.kariiresakinorenrakusaki,
    loanData?.kariirezandaka,
    loanData?.kariirezandakakisaibi,
    loanDataError,
    navigate,
  ]);

  const addHosyoData = useCallback(async () => {
    let valid = true;
    const dateFormatCheckHosyoushitahi = isInvalidDateFormat(
      hosyoData?.hosyoushitahi,
    );
    const validNumberHosyoushitakingaku = isValidNumberFormat(
      hosyoData?.hosyoushitakingaku,
    );
    const error = { ...hosyoDataError };
    if (isEmpty(hosyoData?.syusaimusya)) {
      error.syusaimusyaError = getLabel("error.required");
      valid = false;
    } else {
      error.syusaimusyaError = "";
    }
    if (dateFormatCheckHosyoushitahi) {
      error.hosyoushitahiError = getLabel("error.dateformat");
      valid = false;
    } else {
      error.hosyoushitahiError = "";
    }
    if (!validNumberHosyoushitakingaku) {
      error.hosyoushitakingakuError = getLabel("error.number");
      valid = false;
    } else {
      error.hosyoushitakingakuError = "";
    }

    setHosyoDataError(error);
    if (valid) {
      setIsAdded(true);
      await DataStore.save(
        new LeavesHosyousaimu({
          syusaimusya: hosyoData?.syusaimusya,
          syusaimusyanorenrakusaki: hosyoData?.syusaimusyanorenrakusaki,
          hosyoushitahi: isNotEmptyDate(hosyoData?.hosyoushitahi)
            ? hosyoData?.hosyoushitahi
            : null,
          hosyoushitakingaku: hosyoData?.hosyoushitakingaku,
          saikensya: hosyoData?.saikensya,
          saikensyanorenrakusaki: hosyoData?.saikensyanorenrakusaki,
          bikou: hosyoData?.bikou,
          leavesusersID: context.leavesUser.id,
        }),
      );
      navigate(-1, { replace: true });
    }
  }, [
    context.leavesUser.id,
    hosyoData?.bikou,
    hosyoData?.hosyoushitahi,
    hosyoData?.hosyoushitakingaku,
    hosyoData?.saikensya,
    hosyoData?.saikensyanorenrakusaki,
    hosyoData?.syusaimusya,
    hosyoData?.syusaimusyanorenrakusaki,
    hosyoDataError,
    navigate,
  ]);

  const addData = useCallback(async () => {
    if (category?.torokunaiyo === "loankariirekin") {
      addLoanData();
    } else {
      addHosyoData();
    }
  }, [addHosyoData, addLoanData, category?.torokunaiyo]);

  useEffect(() => {
    if (category?.torokunaiyo === "hosyousaimu")
      sonotaData.current = { value: getTanponoumu() };
  }, [category?.torokunaiyo]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <ListField
        label={getLabel("shinkitsuika")}
        list={listField}
        setState={
          category?.torokunaiyo === "loankariirekin"
            ? setLoanData
            : setHosyoData
        }
        setUpdate={setUpdate}
        onSave={addData}
        onCancel={() => navigate(-1, { replace: true })}
        isDisabled={isAdded}
      />
    </>
  );
}

export default LoanAddPage;
