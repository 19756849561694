import { Flex, Image, Text } from "@aws-amplify/ui-react";
import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import forward from "../assets/image/forward.png";

export default function DropdownDescription(props) {
  const { title, description } = props;
  const [isOpen, setIsOpen] = useState(false);
  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Flex
      className={!isMobile && "maxwidth800"}
      padding="12px 22px 12px 16px"
      border="1px solid #AEB3B7"
      borderRadius="4px"
      margin="16px 16px 0px"
      fontSize="14px"
      position="relative"
      direction="column"
      onClick={onToggle}
      style={{ cursor: "pointer" }}
      justifyContent="space-between"
      gap="16px"
      marginBottom={isMobile ? "0" : "16px"}
    >
      <Flex position="relative" width="100%">
        <Text width="100%" paddingRight="22px">
          {title}
        </Text>
        <Image
          src={forward}
          width="7px"
          height="11px"
          transform={
            isOpen
              ? "translateY(-50%) rotate(270deg)"
              : "translateY(-50%) rotate(90deg)"
          }
          position="absolute"
          right="0"
          top="50%"
        />
      </Flex>
      <Text
        display={isOpen ? "block" : "none"}
        width="100%"
        textAlign="justify"
        whiteSpace="pre-wrap"
        style={{ transition: "0.3s" }}
      >
        {description}
      </Text>
    </Flex>
  );
}
