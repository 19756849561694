import Amplify, { Logger, Auth, AWSCloudWatchProvider } from "aws-amplify";
import awsconfig from "../aws-exports";
Amplify.configure({
  Logging: {
    logGroupName: `/amplify-logger/leavesapp/${window.location.hostname}`,
    logStreamName: "app-logs",
  },
  ...awsconfig,
});

const LOG_LEVEL = "DEBUG";

const LeavesAWSCloudWatchProvider = /** @class */ function () {};
// function LeavesAWSCloudWatchProvider() {
//   console.log("+ Derived constructor called");
//   AWSCloudWatchProvider.call(this);
// }
LeavesAWSCloudWatchProvider.prototype = new AWSCloudWatchProvider();
LeavesAWSCloudWatchProvider.prototype.constructor = LeavesAWSCloudWatchProvider;
LeavesAWSCloudWatchProvider.prototype._initiateLogPushInterval = function () {
  //   console.log("_initiateLogPushInterval", this._dataTracker);
  return AWSCloudWatchProvider.prototype._initiateLogPushInterval();
};

LeavesAWSCloudWatchProvider.prototype.configure = function (config) {
  return AWSCloudWatchProvider.prototype.configure(config);
};
LeavesAWSCloudWatchProvider.prototype._sendLogEvents = function (params) {
  params.logEvents.map((e) => {
    e.timestamp = Date.now();
  });
  const response = AWSCloudWatchProvider.prototype._sendLogEvents(params);
  response.then((e) => {
    if (e == undefined) {
      console.log("cloudwatch log send error", e);
      this._nextSequenceToken = undefined;
      //   return AWSCloudWatchProvider.prototype._getNewSequenceTokenAndSubmit(params);
    }
  });
  return response;
};

const logger = new Logger(__filename, LOG_LEVEL);
Amplify.register(logger);

logger.addPluggable(new LeavesAWSCloudWatchProvider());
// cwp._getNextSequenceToken().then((d) => console.log("cwp", d));

export const log = {
  debug: (message, value) => {
    const logmsg = JSON.stringify({ page: document.title, message: message, value: value });
    Auth.currentAuthenticatedUser()
      .then((user) => {
        logger.debug(user.username, logmsg);
      })
      .catch((e) => {
        logger.debug("authenticated user", logmsg);
      });
    // cwp._getNextSequenceToken().then((d) => console.log("cwp", d));
  },
  info: (message, value) => {
    const logmsg = JSON.stringify({ page: document.title, message: message, value: value });
    Auth.currentAuthenticatedUser()
      .then((user) => {
        logger.info(user.username, logmsg);
      })
      .catch((e) => {
        logger.debug("authenticated user", logmsg);
      });
    // cwp._getNextSequenceToken().then((d) => console.log("cwp", d));
  },
  warn: (message, value) => {
    const logmsg = JSON.stringify({ page: document.title, message: message, value: value });
    Auth.currentAuthenticatedUser()
      .then((user) => {
        logger.warn(user.username, logmsg);
      })
      .catch((e) => {
        logger.debug("authenticated user", logmsg);
      });
    // cwp._getNextSequenceToken().then((d) => console.log("cwp", d));
  },
  error: (message, value) => {
    const logmsg = JSON.stringify({ page: document.title, message: message, value: value });
    Auth.currentAuthenticatedUser()
      .then((user) => {
        logger.error(user.username, logmsg);
      })
      .catch((e) => {
        logger.debug("authenticated user", logmsg);
      });
    // cwp._getNextSequenceToken().then((d) => console.log("cwp", d));
  },
};
