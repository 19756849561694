import { isMobile } from "react-device-detect";
import {
  Description as MobileDescription,
  PCDescription,
} from "../ui-components";
export default function Description(props) {
  if (isMobile) {
    return (
      <MobileDescription
        width={"auto"}
        padding={"0px 16px 16px 16px"}
        overrides={{
          Text: {
            width: "fit-content",
            children: props.text,
          },
        }}
        {...props}
      />
    );
  } else {
    return (
      <PCDescription
        maxWidth="960px"
        width="100%"
        margin="auto"
        overrides={{
          Text: {
            width: "fit-content",
            children: props.text,
          },
        }}
        {...props}
      />
    );
  }
}
