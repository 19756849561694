import { DataStore } from "aws-amplify";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LeavesKenkoukanri } from "../models";
import {
  ListSelectContent,
  ListSingleLineA,
  ListSingleLineD,
} from "../page-components";
import { getLabel, useInitializePage } from "../utils/CommonUtils";
import { isShare, queryDatas } from "../utils/ShareUtils";

function HealthPage() {
  const navigate = useInitializePage("kenkoukanrinitsuite");
  const [listHealth, setListHealth] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selected, setSelected] = useState(null);
  const params = useParams();

  const handledChangeActive = (item) => {
    setSelected(item);
  };

  useEffect(() => {
    if (listHealth?.length > 0) {
      setSelected(listHealth[0]);
    } else {
      setSelected(null);
    }
  }, [listHealth]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const modelData = await queryDatas(params, LeavesKenkoukanri);
      if (modelData?.length > 0) setListHealth(modelData);
      setIsLoaded(true);
    };
    getData();
  }, []);

  const deleteData = async (data) => {
    await DataStore.delete(data);
    const newList = await DataStore.query(LeavesKenkoukanri);
    setListHealth(newList);
  };

  return (
    <ListSelectContent
      label={getLabel("kenkoukanrinitsuite")}
      mobileList={listHealth.map((data, index) => {
        return (
          <ListSingleLineA
            key={data?.id}
            label={data?.byoumei}
            onClick={() => navigate(data?.id)}
          />
        );
      })}
      description={{
        type: "data",
        category: "healthcare",
      }}
      isShared={isShare(params)}
      addButtonLabel={
        getLabel("健康情報") +
        getLabel(
          "button.addsuffix",
        ) /**TODO: 健康情報 to their key When 健康情報 is defined in vocabulary */
      }
      onAddClick={() => {
        navigate("add");
        window.gtag("event", "FirstWrite", {
          event_category: "Edit",
          event_label: "健康管理について（常備薬・通院記録）書込開始",
        });
      }}
      pcListItem={listHealth.map((ele, index) => {
        return (
          <ListSingleLineD
            key={ele?.id}
            label={ele?.byoumei}
            selected={selected?.id === ele?.id}
            onClick={() => {
              handledChangeActive(ele);
            }}
          />
        );
      })}
      selectedItemList={[
        {
          label: getLabel("byoumei"),
          value: selected?.byoumei,
        },
        {
          isEmptyHide: true,
          label: getLabel("hukuyouchuunokusuri"),
          value: selected?.hukuyouchuunokusuri,
        },
        {
          isEmptyHide: true,
          label: getLabel("youto"),
          value: selected?.youto,
        },
        {
          isEmptyHide: true,
          label: getLabel("youhou"),
          value: selected?.youhou,
        },
        {
          isEmptyHide: true,
          label: getLabel("byouinmei"),
          value: selected?.byouinmei,
        },
        {
          isEmptyHide: true,
          label: getLabel("chiryoukikan"),
          value: selected?.chiryoukikan,
        },
        {
          isEmptyHide: true,
          label: getLabel("bikou"),
          value: selected?.bikou,
        },
      ]}
      selectedItemLabel={selected?.byoumei}
      onDelete={() => deleteData(selected)}
      onEdit={() => {
        navigate(selected?.id + "/edit");
        window.gtag("event", "Change", {
          event_category: "Edit",
          event_label: "健康管理について（常備薬・通院記録）変更開始",
        });
      }}
      isLoaded={isLoaded}
    />
  );
}

export default HealthPage;
