import { DataStore } from "aws-amplify";
import { useCallback, useEffect, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useParams } from "react-router-dom";
import { LeavesYuujinChijin } from "../models";
import { ListViewDetail } from "../page-components";
import { useInitializePage } from "../utils/CommonUtils";
import { isShare, queryData } from "../utils/ShareUtils";

function FriendsViewPage() {
  const navigate = useInitializePage("yuujinchijin", "title.detail");
  const [friendData, setFriendData] = useState({
    shimei: "",
    kankeisei: "",
    seinengappi: "",
    juusho: "",
    denwabangou: "",
    mailaddress: "",
    bikou: "",
  });
  const params = useParams();
  const deleteData = useCallback(async () => {
    await DataStore.delete(friendData);
    navigate(-1, { replace: true });
  }, [friendData, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const data = await queryData(params, LeavesYuujinChijin);
      if (data?.length > 0) {
        setFriendData(data[0]);
      }
    };
    getData();
  }, [params.id]);

  const listLabel = [
    { label: "shimei", value: friendData?.shimei, isRequire: true },
    { label: "kankeisei", value: friendData?.kankeisei },
    { label: "seinengappi", value: friendData?.seinengappi, type: "date" },
    { label: "juusho", value: friendData?.juusho },
    { label: "denwabangou", value: friendData?.denwabangou },
    { label: "mailaddress", value: friendData?.mailaddress },
    { label: "bikou", value: friendData?.bikou, isMulti: true },
  ];

  return (
    <ListViewDetail
      label={friendData?.shimei}
      list={listLabel}
      isHideEdit={isShare(params)}
      onDelete={deleteData}
      onEdit={() => {
        navigate("edit");
        window.gtag("event", "Change", {
          event_category: "Edit",
          event_label: "友人・知人変更開始",
        });
      }}
    />
  );
}

export default FriendsViewPage;
