/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Text } from "@aws-amplify/ui-react";
import PCTextButton from "./PCTextButton";
export default function PCListSingleLineCTextButton(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="32px"
      direction="row"
      width="1040px"
      alignItems="flex-start"
      position="relative"
      padding="12px 0px 12px 0px"
      {...rest}
      {...getOverrideProps(overrides, "PCListSingleLineCTextButton")}
    >
      <Text
        fontFamily="Inter"
        fontSize="16px"
        fontWeight="400"
        color="rgba(28,26,29,1)"
        lineHeight="24px"
        textAlign="left"
        display="flex"
        direction="column"
        justifyContent="flex-start"
        letterSpacing="0.01px"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Category"
        {...getOverrideProps(overrides, "Category")}
      ></Text>
      <Flex
        gap="0"
        direction="column"
        shrink="0"
        height="60px"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Content")}
      >
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="400"
          color="rgba(28,26,29,1)"
          lineHeight="24px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          letterSpacing="0.01px"
          width="791px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Item"
          {...getOverrideProps(overrides, "Item")}
        ></Text>
        <Flex
          gap="16px"
          direction="row"
          width="fit-content"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 549")}
        >
          <PCTextButton
            display="flex"
            gap="10px"
            direction="row"
            width="fit-content"
            alignItems="flex-start"
            shrink="0"
            height="36px"
            position="relative"
            padding="8px 8px 8px 0px"
            {...getOverrideProps(overrides, "PC_TextButton531329624")}
          ></PCTextButton>
          <PCTextButton
            display="flex"
            gap="10px"
            direction="row"
            width="fit-content"
            alignItems="flex-start"
            shrink="0"
            height="36px"
            position="relative"
            padding="8px 8px 8px 0px"
            {...getOverrideProps(overrides, "PC_TextButton574415494")}
          ></PCTextButton>
        </Flex>
      </Flex>
    </Flex>
  );
}
