import { DataStore, Storage } from "aws-amplify";
import { useCallback, useEffect, useRef, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useParams } from "react-router-dom";
import {
  LeavesKazokuShinzoku,
  LeavesTaisetsunishitaihitohenomessage,
  LeavesYuujinChijin,
} from "../models";
import { ListViewDetail } from "../page-components";
import { useInitializePage } from "../utils/CommonUtils";
import { isShare, listObjects, queryData } from "../utils/ShareUtils";

function MessageViewPage() {
  const navigate = useInitializePage(
    "taisetsunishitaihitohenomessage",
    "title.detail",
  );
  const [messageData, setMessageData] = useState({
    messagetitle: "",
    soushinsaki: "",
    messagenaiyou: "",
    isSentMessage: false,
  });
  const [soushinsaki, setSoushinsaki] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const data = useRef();
  const params = useParams();
  const deleteData = useCallback(async () => {
    await DataStore.delete(data.current);
    await Storage.remove(imageUrl, {
      level: "private",
    });
    navigate(-1, { replace: true });
  }, [imageUrl, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const dataQuery = await queryData(
        params,
        LeavesTaisetsunishitaihitohenomessage,
      );
      if (dataQuery?.length > 0) {
        data.current = dataQuery[0];
        setMessageData({
          ...dataQuery[0],
        });
        if (dataQuery[0]?.soushinsaki) {
          const getData = async () => {
            const contact = await DataStore.query(LeavesKazokuShinzoku, (c) =>
              c.id("eq", dataQuery[0]?.soushinsaki),
            );
            if (contact?.length > 0) {
              setSoushinsaki(contact[0]?.shimei);
              return;
            }
          };
          getData();
        } else if (dataQuery[0]?.soushinsakiYuujinChijin) {
          const getData = async () => {
            const contact = await DataStore.query(LeavesYuujinChijin, (c) =>
              c.id("eq", dataQuery[0]?.soushinsakiYuujinChijin),
            );
            if (contact?.length > 0) {
              setSoushinsaki(contact[0]?.shimei);
              return;
            }
          };
          getData();
        }
      }
    };
    getData();
  }, [params, params.id]);

  useEffect(() => {
    if (messageData.id) {
      listObjects(
        params,
        LeavesTaisetsunishitaihitohenomessage,
        messageData,
      ).then((res) => {
        const url = res.length > 0 ? res[0].key : null;
        setImageUrl(url);
      });
    }
  }, [messageData, messageData.id, params]);

  const listLabel = [
    {
      label: "messagetitle",
      value: messageData?.messagetitle,
      isRequire: true,
    },
    {
      label: "soushinsaki",
      value: soushinsaki,
    },
    {
      label: "messagenaiyou",
      value: messageData?.messagenaiyou,
      isMulti: true,
    },
    {
      type: "image",
      value: imageUrl,
    },
    {
      label: "soushinbi",
      value: messageData?.soushinbi,
      type: isNaN(new Date(messageData?.soushinbi)) ? "text" : "date",
    },
  ];

  return (
    <ListViewDetail
      label={messageData?.messagetitle}
      list={listLabel}
      isHideEdit={isShare(params) || messageData?.isSentMessage}
      onDelete={deleteData}
      onEdit={() => {
        navigate("edit");
        window.gtag("event", "Change", {
          event_category: "Edit",
          event_label: "大切にしたい人へのメッセージ変更開始",
        });
      }}
    />
  );
}

export default MessageViewPage;
