/* eslint-disable import/no-anonymous-default-export */
import { Flex } from "@aws-amplify/ui-react";
import { DataStore } from "aws-amplify";
import { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import UserContext from "../UserContext";
import { withNonScrollBody } from "../hooks";
import { LeavesKaigo, LeavesUsers } from "../models";
import {
  Card,
  Description,
  FABEdit,
  FABEditShare,
  FABShare,
  LabelM,
  ListSingleLineCEdit,
  ListTwolineA
} from "../page-components";
import {
  defaultString,
  getLabel,
  isEmptyKaigon,
  useInitializePage,
} from "../utils/CommonUtils";
import { isShare, queryData } from "../utils/ShareUtils";

/*
    handanwoyudanetaihito: "判断を委ねたい人",
    kiboukaigoshurui: "希望介護種類",
    kiboushisetsu: "希望施設",
    hiyoukyoshutsu: "費用拠出",
    zaisankanriwomakaseruhito: "財産管理を任せる人",
    zaisankanriwomakaseruhitonokeiyakujoukyou: "財産管理を任せる人の契約状況",
    bikou
*/
export default withNonScrollBody(function () {
  const navigate = useInitializePage("kaigonitsuite", "title.detail");
  const [data, setData] = useState();
  const [isEmptyData, setIsEmptyData] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const params = useParams();
  const context = useContext(UserContext);

  useEffect(async () => {
    const data = await queryData(params, LeavesKaigo);
    console.log("data", data);
    if (data.length > 0) {
      setData(data[0]);
      setIsEmptyData(isEmptyKaigon(data[0]));
      if (!context.leavesUser.leavesUsersLeavesKaigoId) {
        DataStore.save(
          LeavesUsers.copyOf(context.leavesUser, (updated) => {
            updated.leavesUsersLeavesKaigoId = data[0].id;
          }),
        );
      }
    } else {
      const newData = await DataStore.save(
        new LeavesKaigo({
          handanwoyudanetaihito: "",
          kiboukaigoshurui: "",
          kiboushisetsu: "",
          hiyoukyoshutsu: "",
          zaisankanriwomakaseruhito: "",
          zaisankanriwomakaseruhitonokeiyakujoukyou: "",
          bikou: "",
        }),
      );
      DataStore.save(
        LeavesUsers.copyOf(context.leavesUser, (updated) => {
          updated.leavesUsersLeavesKaigoId = newData.id;
        }),
      );
    }
    setIsLoaded(true);
  }, []);

  const eventTracking = () => {
    if (isEmptyData) {
      window.gtag("event", "FirstWrite", {
        event_category: "Edit",
        event_label: "介護について書込開始",
      });
    } else {
      window.gtag("event", "Change", {
        event_category: "Edit",
        event_label: "介護について変更開始",
      });
    }
  };

  return (
    <div>
      {isMobile ? (
        <>
          <LabelM label={getLabel("kaigonitsuite")} />
          {isEmptyData
            ? isLoaded && (
                <Flex marginBottom="32px">
                  <Card
                    type="will"
                    title={getLabel(`kaigonitsuite`)}
                    titleEn={getLabel(`kaigonitsuite.en`)}
                    mainDescription={getLabel(`kaigonitsuite.des`)}
                  />
                </Flex>
              )
            : isLoaded && (
                <>
                  <ListTwolineA
                    label={getLabel("handanwoyudanetaihito")}
                    value={data?.handanwoyudanetaihito}
                  />
                  <ListTwolineA
                    label={getLabel("kiboukaigoshurui")}
                    value={data?.kiboukaigoshurui}
                  />
                  <ListTwolineA
                    label={getLabel("kiboushisetsu")}
                    value={data?.kiboushisetsu}
                  />
                  <ListTwolineA
                    label={getLabel("hiyoukyoshutsu")}
                    value={data?.hiyoukyoshutsu}
                  />
                  <ListTwolineA
                    label={getLabel("zaisankanriwomakaseruhito")}
                    value={data?.zaisankanriwomakaseruhito}
                  />
                  <ListTwolineA
                    label={getLabel(
                      "zaisankanriwomakaseruhitonokeiyakujoukyou",
                    )}
                    value={defaultString(
                      data?.zaisankanriwomakaseruhitonokeiyakujoukyou,
                      getLabel(
                        "KaigoJoukyou." +
                          data?.zaisankanriwomakaseruhitonokeiyakujoukyou,
                      ),
                    )}
                  />
                  <ListTwolineA label={getLabel("bikou")} value={data?.bikou} />
                </>
              )}

          {!isShare(params) && (
            <FABEditShare
              onShare={() => {
                navigate(`share`);
              }}
              onEdit={() => {
                navigate("edit");
                eventTracking();
              }}
            />
          )}
        </>
      ) : (
        <div className="swapper">
          <LabelM label={getLabel("kaigonitsuite")} isDivide isShareBtn={!isShare(params)} isNonList />
          {isEmptyData && isLoaded && (
            <Description
              text={
                isShare(params)
                  ? getLabel("shared.des")
                  : getLabel(`kaigonitsuite.des`)
              }
              paddingBottom="0"
            />
          )}
          <div
            className={`hide-scroll content-swapper ${
              isEmptyData && "min-height-0"
            }`}
            style={{ overflow: "auto", maxHeight: "calc(100vh - 411px)" }}
          >
            <ListSingleLineCEdit
              isHideEdit={isShare(params)}
              list={
                !isEmptyData && [
                  {
                    label: getLabel("handanwoyudanetaihito"),
                    value: data?.handanwoyudanetaihito,
                  },
                  {
                    label: getLabel("kiboukaigoshurui"),
                    value: data?.kiboukaigoshurui,
                  },
                  {
                    label: getLabel("kiboushisetsu"),
                    value: data?.kiboushisetsu,
                  },
                  {
                    label: getLabel("hiyoukyoshutsu"),
                    value: data?.hiyoukyoshutsu,
                  },
                  {
                    label: getLabel("zaisankanriwomakaseruhito"),
                    value: data?.zaisankanriwomakaseruhito,
                  },
                  {
                    label: getLabel(
                      "zaisankanriwomakaseruhitonokeiyakujoukyou",
                    ),
                    value: defaultString(
                      data?.zaisankanriwomakaseruhitonokeiyakujoukyou,
                      getLabel(
                        "KaigoJoukyou." +
                          data?.zaisankanriwomakaseruhitonokeiyakujoukyou,
                      ),
                    ),
                  },
                  {
                    label: getLabel("bikou"),
                    value: data?.bikou,
                  },
                ]
              }
              editOnClick={() => {
                navigate("edit");
                eventTracking();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
});
