/* eslint-disable import/no-anonymous-default-export */
import { DataStore, Storage } from "aws-amplify";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
// import { DataStore } from "@aws-amplify/datastore";
import { LeavesJibunshi } from "../models";
import {
  ListSelectContent,
  ListSingleLineC,
  ListTwoLineAPC,
  StrageImage,
} from "../page-components";
import { getLabel, useInitializePage } from "../utils/CommonUtils";
import { log } from "../utils/Logger";
import { isShare, listObjects, queryDatas } from "../utils/ShareUtils";

/*
  nengetsu: AWSDate!
  eventmei: String!
*/

export default function () {
  const navigate = useInitializePage("jibunshi");
  const locate = useLocation();
  const { state } = locate;

  const [isLoaded, setIsLoaded] = useState(false);
  const [imageList, setImageList] = useState({});
  const [list, setList] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [selected, setSelected] = useState(null);
  const params = useParams();

  const handledChangeActive = (item) => {
    setSelected(item);
  };

  useEffect(() => {
    const getData = async () => {
      const dsData = await queryDatas(params, LeavesJibunshi);
      log.debug("dsData", dsData);

      setIsLoaded(true);
      setList(dsData);
    };
    getData();
  }, []);

  const deleteData = async (data) => {
    log.debug("delete data", data);
    await DataStore.delete(data);
    await Storage.remove(imageList[data.id], {
      level: "private",
    });
    const newList = await DataStore.query(LeavesJibunshi);
    setList(newList);
  };

  useEffect(() => {
    let mount = true;
    if (list?.length > 0) {
      const images = {};
      const getList = async () => {
        const listPromise = [];
        for (let i = 0; i < list.length; i++) {
          listPromise.push(
            new Promise((resolve) => {
              listObjects(params, LeavesJibunshi, list[i]).then((res) => {
                if (res.length > 0) {
                  resolve({
                    id: list[i].id,
                    key: res[0].key,
                  });
                } else {
                  resolve({
                    id: list[i].id,
                    key: null,
                  });
                }
              });
            }),
          );
        }
        const result = await Promise.all(listPromise);
        result.forEach((ele) => {
          images[ele.id] = ele.key;
        });
        if (mount) setImageList(images);
      };
      getList();
    }
    return () => (mount = false);
  }, [list, params]);

  useEffect(() => {
    if (isLoaded) {
      if (list.length >= 10) setIsDisabled(true); // limit item is 10
      else setIsDisabled(false);
    }
  }, [list, isLoaded]);

  const sortList = useMemo(() => {
    return list.sort(
      (a, b) => new Date(b.nengetsu).getTime() - new Date(a.nengetsu).getTime(),
    );
  }, [list]);

  useEffect(() => {
    if (list?.length > 0) {
      const item = list.find((ele) => ele?.id === state?.id);
      if (item) {
        setSelected(item);
        window.history.replaceState(
          { ...window.history?.state, usr: null },
          document.title,
        );
      } else setSelected(list[0]);
    } else {
      setSelected(null);
    }
  }, [list, state?.id]);

  return (
    <ListSelectContent
      label={getLabel("jibunshi")}
      mobileList={sortList.map((data, index) => {
        return (
          <ListSingleLineC
            key={data?.id}
            date={data?.nengetsu}
            imageSrc={imageList[data?.id]}
            label={data?.eventmei}
            onClick={() => navigate(data?.id)}
          />
        );
      })}
      description={{
        type: "history",
        category: "jibunshi",
        subDescription: getLabel("description.jibunshi"),
      }}
      isShared={isShare(params)}
      addButtonLabel={getLabel("jibunshi") + getLabel("button.addsuffix")}
      isDisabled={isDisabled}
      onAddClick={() => {
        navigate("add");
        window.gtag("event", "FirstWrite", {
          event_category: "Edit",
          event_label: "自分史書込開始",
        });
      }}
      style={{ maxHeight: "calc(100vh - 455px)", marginTop: "0" }}
      pcListItem={sortList.map((ele, index) => {
        return (
          <ListTwoLineAPC
            key={ele?.id}
            label={ele?.eventmei}
            value={ele?.nengetsu}
            type="date"
            selected={selected?.id === ele?.id}
            onClick={() => {
              handledChangeActive(ele);
            }}
          />
        );
      })}
      selectedItemList={[
        {
          label: getLabel("nengetsu"),
          value: selected?.nengetsu,
          type: "date",
        },
        {
          label: getLabel("eventmei"),
          value: selected?.eventmei,
        },
        {
          label: getLabel("episode"),
          value: selected?.episode,
        },
        {
          label: getLabel("tenpufile"),
          value: imageList[selected?.id] && (
            <StrageImage key={selected?.id} src={imageList[selected?.id]} />
          ),
        },
      ]}
      selectedItemLabel={selected?.eventmei}
      onDelete={() => deleteData(selected)}
      onEdit={() => {
        navigate(selected?.id + "/edit");
        window.gtag("event", "Change", {
          event_category: "Edit",
          event_label: "自分史変更開始",
        });
      }}
      isLoaded={isLoaded}
    />
  );
}
