import { DataStore } from "aws-amplify";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { LeavesHakanitsuite } from "../models";
import { ListField } from "../page-components";
import { getLabel, isEmptyHakani, useInitializePage } from "../utils/CommonUtils";
import {
  HAIRUYOTEINOOHAKANOUMU,
  HIYOUKYOSHUTSU,
  KIBOUSURUOHAKANOKEISHIKI,
  useDatalist,
} from "../utils/DatalistUtils";
import { usePrompt } from "../utils/Prompt";

function TombEditPage() {
  const navigate = useInitializePage("hakanitsuite", "title.update");
  const data = useRef();

  const [hakanitsuite, setHakanitsuite] = useState({
    kibousurubasyo: "",
    bikou: "",
  });

  const [update, setUpdate] = useState(false);
  const [isEmptyData, setIsEmptyData] = useState(true);

  const [
    HairuyoteinoohakanoumuDataList,
    setHairuyoteinoohakanoumu,
    getHairuyoteinoohakanoumu,
    hasErrorHairuyoteinoohakanoumu,
  ] = useDatalist({
    list: HAIRUYOTEINOOHAKANOUMU,
    dataUpdateHook: setUpdate,
  });

  const [
    KibousuruohakanokeishikiDataList,
    setKibousuruohakanokeishiki,
    getKibousuruohakanokeishiki,
    hasErrorKibousuruohakanokeishiki,
  ] = useDatalist({
    list: KIBOUSURUOHAKANOKEISHIKI,
    dataUpdateHook: setUpdate,
  });

  const [
    HiyoukyoshutsuDataList,
    setHiyoukyoshutsu,
    getHiyoukyoshutsu,
    hasErrorHiyoukyoshutsu,
  ] = useDatalist({
    list: HIYOUKYOSHUTSU,
    dataUpdateHook: setUpdate,
  });

  const [isUpdated, setIsUpdated] = useState(false);

  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const dsData = await DataStore.query(LeavesHakanitsuite);
      if (dsData?.length > 0) {
        data.current = dsData[0];
        setIsEmptyData(isEmptyHakani(dsData[0]));
        setHairuyoteinoohakanoumu(dsData[0].hairuyoteinoohakanoumu);
        setKibousuruohakanokeishiki(dsData[0].kibousuruohakanokeishiki);
        setHiyoukyoshutsu(dsData[0].hiyoukyoshutsu);
        setHakanitsuite({
          kibousurubasyo: dsData[0].kibousurubasyo,
          bikou: dsData[0].bikou,
        });
      }
    };
    getData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const eventTracking = () => {
    if (isEmptyData) {
      window.gtag("event", "FirstWriteComplete", {
        event_category: "Edit",
        event_label: "墓について書込完了",
      });
    } else {
      window.gtag("event", "ChangeComplete", {
        event_category: "Edit",
        event_label: "墓について変更完了",
      });
    }
  };

  const updateData = useCallback(async () => {
    let valid = true;
    if (hasErrorHairuyoteinoohakanoumu()) valid = false;
    if (hasErrorKibousuruohakanokeishiki()) valid = false;
    if (hasErrorHiyoukyoshutsu()) valid = false;
    if (valid) {
      setIsUpdated(true);
      await DataStore.save(
        LeavesHakanitsuite.copyOf(data.current, (updated) => {
          updated.hairuyoteinoohakanoumu = getHairuyoteinoohakanoumu();
          updated.kibousuruohakanokeishiki = getKibousuruohakanokeishiki();
          updated.kibousurubasyo = hakanitsuite.kibousurubasyo;
          updated.hiyoukyoshutsu = getHiyoukyoshutsu();
          updated.bikou = hakanitsuite.bikou;
        }),
      );
      eventTracking();
      navigate(-1, { replace: true });
    }
  }, [
    getHairuyoteinoohakanoumu,
    getHiyoukyoshutsu,
    getKibousuruohakanokeishiki,
    hakanitsuite.bikou,
    hakanitsuite.kibousurubasyo,
    hasErrorHairuyoteinoohakanoumu,
    hasErrorHiyoukyoshutsu,
    hasErrorKibousuruohakanokeishiki,
    navigate,
  ]);

  const listField = [
    {
      type: "dataList",
      child: <HairuyoteinoohakanoumuDataList label="hairuyoteinoohakanoumu" />,
    },
    {
      type: "dataList",
      child: (
        <KibousuruohakanokeishikiDataList label="kibousuruohakanokeishiki" />
      ),
    },
    {
      type: "textField",
      label: "kibousurubasyo",
      placeHolder: getLabel("kokyounomiyazaki"),
      value: hakanitsuite.kibousurubasyo,
    },
    {
      type: "dataList",
      child: <HiyoukyoshutsuDataList label="hiyoukyoshutsu" />,
    },
    {
      type: "textArea",
      label: "bikou",
      value: hakanitsuite.bikou,
    },
  ];

  return (
    <ListField
      label={getLabel("hakanitsuite")}
      list={listField}
      setState={setHakanitsuite}
      setUpdate={setUpdate}
      onSave={updateData}
      onCancel={() => navigate(-1, { replace: true })}
      isDisabled={isUpdated}
    />
  );
}

export default TombEditPage;
