import { Flex, Grid } from "@aws-amplify/ui-react";
import LabelS from "./LabelS";

export default function Swapper(props) {
  return (
    <Flex display="block" width="100%">
      <LabelS label={props.label} />
      <Grid
        width="100%"
        templateColumns="calc(50% - 16px) calc(50% - 16px)"
        columnGap="32px"
        rowGap="16px"
      >
        {props.children}
      </Grid>
    </Flex>
  );
}
