import { Flex } from '@aws-amplify/ui-react';
import { isMobile, isTablet } from 'react-device-detect';
import { PrimaryButton } from '../page-components';
import { useNavigate } from 'react-router-dom';
import awsExports from '../aws-exports';
import Amplify, { Auth, API } from 'aws-amplify';
import { createLeavesMindTypeAnswer } from '../graphql/mutations';
import Cookies from 'js-cookie';

const PAGE_FINAL = 5;

export default function MindTypeCheckNextButton(props) {
    const nextPage = parseInt(props.page) + 1;
    const navigate = useNavigate();
    const answerId = Cookies.get('mindtypeAnswerId');

    const getAnswerFullCountByPage = (page) => {
        let answerFullCount = 1;
        if ( page == 1 ) {
            answerFullCount = 5;
        }
        else if ( page == 5 ) {
            answerFullCount = 16;
        }
        else {
            answerFullCount = 8;
        }
        return answerFullCount;
    };

    const getAnswerStartCountByPage = (page) => {
        let answerStartCount = 1;
        if ( page == 5 ) {
            answerStartCount = 9;
        }
        return answerStartCount;
    };

    const checkAnswerCompleteByPage = (page) => {

        for ( let i = getAnswerStartCountByPage(page); i <= getAnswerFullCountByPage(page); i++ ) {
            let answerKey = page + '-' + i; 
            if ( !props.answerTable[answerKey] ) {
                return false;
            }
        }
        return true;
    };

    const saveAnswerByPage = async(page) => {
        Amplify.configure(awsExports);

        const user = await Auth.currentAuthenticatedUser();

        for ( let questionNo = getAnswerStartCountByPage(page); questionNo <= getAnswerFullCountByPage(page); questionNo++ ) {
            let answerKey = page + '-' + questionNo; 

            // DB記録
            const setAnswer = await API.graphql({
                query: createLeavesMindTypeAnswer,
                variables: {
                    input: {
                        answerId: answerId,
                        page: page,
                        questionNo: questionNo,
                        answer: props.answerTable[answerKey]
                    }
                }
            });
        }

        return true;
    }

    const submitAnswerByPage = (page) => {

        // すべての質問回答済みチェック
        if ( !checkAnswerCompleteByPage(page) ) {
            alert("回答していない項目があります");
            return;
        }

        // 回答記録
        saveAnswerByPage(page); 

        // ページ回答済みデータをCookieに登録
        let answeredCheckKey = "pageAnswered_" + answerId;
        let pageAnsweredString = Cookies.get(answeredCheckKey);
        let pageAnsweredList = pageAnsweredString ? pageAnsweredString.split("_") : [];
        pageAnsweredList.push(page);
        pageAnsweredString = pageAnsweredList.join("_");
        Cookies.set(answeredCheckKey, pageAnsweredString, { expires: 30 });
        
        if ( page < PAGE_FINAL ) {
            navigate("/mindtype/check/"+nextPage, { replace: true });
        }
        else {
            navigate("/mindtype/result", { replace: true });
        }

    };


    if ( props.page < PAGE_FINAL ) {

        return(
            <Flex
                justifyContent="center"
                alignItems="left"
                marginTop={(isMobile || isTablet) ? '-150px' : '0'}
            >
                <PrimaryButton
                    justifyContent="center"
                    margin="0"
                    onClick={() => submitAnswerByPage(props.page)}
                    padding={isMobile ? "8px 12px" : "8px 40px"}
                    overrides={{
                        "Flex.Text[0]": {
                        fontSize: (isMobile || isTablet) ? "14px" : "16px",
                        children: "次へ"
                        }
                    }}
                />
            </Flex>
        );
    }
    else {
        return(
            <Flex
                justifyContent="center"
                alignItems="left"
                marginTop={(isMobile || isTablet) ? '-150px' : '0'}
            >
                <PrimaryButton
                    justifyContent="center"
                    margin="0"
                    onClick={() => submitAnswerByPage(props.page)}
                    padding={isMobile ? "8px 12px" : "8px 40px"}
                    overrides={{
                        "Flex.Text[0]": {
                            fontSize: isMobile ? "14px" : "16px",
                            children: "診断結果"
                        }
                    }}
                 />
            </Flex>
        );
    }
}
