import { DataStore } from "aws-amplify";
import { useContext, useEffect, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useParams } from "react-router-dom";
import { LeavesSeikatsunokoto, LeavesUsers } from "../models";
import { ListTwolineA, ListView } from "../page-components";
import UserContext from "../UserContext";
import {
  getLabel,
  isEmptySeika,
  useInitializePage,
} from "../utils/CommonUtils";
import { isShare, queryData } from "../utils/ShareUtils";

function LifeViewPage() {
  const navigate = useInitializePage("seikatsunokotonitsuite", "title.detail");
  const [data, setData] = useState({
    seikatsunokotonitsuite: "",
  });
  const [isEmptyData, setIsEmptyData] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const params = useParams();

  const context = useContext(UserContext);

  const eventTracking = () => {
    if (isEmptyData) {
      window.gtag("event", "FirstWrite", {
        event_category: "Edit",
        event_label: "生活のことについて書込開始",
      });
    } else {
      window.gtag("event", "Change", {
        event_category: "Edit",
        event_label: "生活のことについて変更開始",
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const data = await queryData(params, LeavesSeikatsunokoto);
      if (data?.length > 0) {
        setData(data[0]);
        setIsEmptyData(isEmptySeika(data[0]));
        if (!context.leavesUser.leavesUsersLeavesSeikatsunokotoId) {
          DataStore.save(
            LeavesUsers.copyOf(context.leavesUser, (updated) => {
              updated.leavesUsersLeavesSeikatsunokotoId = data[0].id;
            }),
          );
        }
      } else {
        const newData = await DataStore.save(
          new LeavesSeikatsunokoto({
            seikatsunokotonitsuite: "",
          }),
        );
        DataStore.save(
          LeavesUsers.copyOf(context.leavesUser, (updated) => {
            updated.leavesUsersLeavesSeikatsunokotoId = newData.id;
          }),
        );
      }
      setIsLoaded(true);
    };
    getData();
  }, [context.leavesUser]);

  const listData = [
    {
      label: getLabel("seikatsunokotonitsuite"),
      value: data?.seikatsunokotonitsuite,
    },
  ];

  return (
    <ListView
      label={getLabel("seikatsunokotonitsuite")}
      description={{
        type: "data",
        category: "seikatsunokotonitsuite",
      }}
      isShared={isShare(params)}
      isLoaded={isLoaded}
      isEmptyCategory={isEmptyData}
      onEdit={() => {
        navigate("edit");
        eventTracking();
      }}
      listMobile={listData.map((ele, index) => {
        return <ListTwolineA key={index} label={ele.label} value={ele.value} />;
      })}
      listPC={listData}
    />
  );
}

export default LifeViewPage;
