/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function HeaderButton(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="0"
      direction="row"
      width="365px"
      alignItems="flex-start"
      position="relative"
      padding="10px 5px 0px 5px"
      backgroundColor="rgba(104,134,73,1)"
      {...rest}
      {...getOverrideProps(overrides, "HeaderButton")}
    >
      <Flex
        gap="4px"
        direction="row"
        width="177.5px"
        height="50px"
        justifyContent="center"
        alignItems="flex-end"
        grow="1"
        basis="177.5px"
        position="relative"
        borderRadius="22px 22px 0px 0px"
        padding="12px 16px 12px 16px"
        backgroundColor="rgba(221,121,47,1)"
        {...getOverrideProps(overrides, "Colum")}
      >
        <Text
          fontFamily="Shippori Mincho"
          fontSize="22px"
          fontWeight="600"
          color="rgba(232,232,232,1)"
          lineHeight="20px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-end"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="コラム"
          {...getOverrideProps(overrides, "\u30B3\u30E9\u30E0")}
        ></Text>
        <Text
          fontFamily="Shippori Mincho"
          fontSize="14px"
          fontWeight="400"
          color="rgba(232,232,232,1)"
          lineHeight="20px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="を読む"
          {...getOverrideProps(overrides, "\u3092\u8AAD\u3080")}
        ></Text>
      </Flex>
      <Flex
        gap="4px"
        direction="row"
        width="177.5px"
        height="50px"
        justifyContent="center"
        alignItems="flex-end"
        grow="1"
        basis="177.5px"
        position="relative"
        boxShadow="0px 0px 8px rgba(0, 0, 0, 0.36000001430511475)"
        borderRadius="22px 22px 0px 0px"
        padding="12px 16px 12px 16px"
        backgroundColor="rgba(104,134,73,1)"
        {...getOverrideProps(overrides, "Note")}
      >
        <Text
          fontFamily="Shippori Mincho"
          fontSize="22px"
          fontWeight="600"
          color="rgba(232,232,232,1)"
          lineHeight="20px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-end"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="NOTE"
          {...getOverrideProps(overrides, "NOTE")}
        ></Text>
        <Text
          fontFamily="Shippori Mincho"
          fontSize="14px"
          fontWeight="400"
          color="rgba(232,232,232,1)"
          lineHeight="20px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="を使う"
          {...getOverrideProps(overrides, "\u3092\u4F7F\u3046")}
        ></Text>
      </Flex>
    </Flex>
  );
}
