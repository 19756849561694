import { Flex, Image, Text } from "@aws-amplify/ui-react";
import React, { useEffect, useState, useRef, useContext } from "react";
import { confirmAlert } from "react-confirm-alert";
import { isMobile } from "react-device-detect";
import { Dialog, Stepper } from "../ui-components";
import {
  getCurrentUserAge,
  getLabel,
  getListRangeAge,
  isEmpty,
} from "../utils/CommonUtils";
import TextField from "./TextField";
import SelectField from "./SelectField";
import UserContext from "../UserContext";
import { Priority, PriorityLabel } from "../constants";
import TextFieldMultiline from "./TextFieldMultiline";
import Cookies from "js-cookie";
import { DataStore } from "aws-amplify";
import { LeavesShuukan } from "../models";
import { useNavigate } from "react-router-dom";
import { LeavesLifeFuture } from "../models";
import { Tasseijoukyoushurui } from "../models";
import memo from "../assets/image/memo.png";

const GUIDE_STEP = {
  CLOSE: "CLOSE",
  CONFIRM: "CONFIRM",
  SELECT_CATEGORY: "SELECT_CATEGORY",
  REASON: "REASON",
  CHALLENGE_AGE: "CHALLENGE_AGE",
  CHALLENGE_PRIORITY: "CHALLENGE_PRIORITY",
  CHALLENGE_DONE: "CHALLENGE_DONE",
  HABIT_PRIORITY: "HABIT_PRIORITY",
  HABIT_DONE: "HABIT_DONE",
};

const circelFillActive = [
  {
    d: "M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6Z",
    fill: "rgba(104,134,73,1)",
    fillRule: "nonzero",
  },
];

export default function BucketListGuideDialog(props) {
  const [step, setStep] = useState(GUIDE_STEP.CONFIRM);
  const context = useContext(UserContext);
  const currentUserAge = getCurrentUserAge(context.user?.attributes.birthdate);
  const ageRangeOption = getListRangeAge(currentUserAge);
  const categoryRef = useRef();
  const navigate = useNavigate();
  const [data, setData] = useState({
    yaritaikoto: "",
    category: "",
    challengeAge: "",
    habitPriority: "",
    challengePriority: "",
    reason: "",
  });

  const [dataError, setDataError] = useState({
    yaritaikoto: "",
    category: "",
    challengeAge: "",
    habitPriority: "",
    challengePriority: "",
    reason: "",
  });

  const handleChangeStep = (nextStep) => () => {
    setStep(nextStep);
  };

  const handleChangeData = (key) => (event) => {
    setData({ ...data, [key]: event?.target?.value });
  };

  const handleNextReason = () => {
    let valid = true;
    const error = { ...dataError };
    if (isEmpty(data?.reason)) {
      error.reason = getLabel("error.required");
      valid = false;
    } else {
      error.reason = "";
    }
    setDataError(error);
    if (!valid) return;
    if (data?.category === "habit") {
      setStep(GUIDE_STEP.HABIT_PRIORITY);
    } else {
      setStep(GUIDE_STEP.CHALLENGE_AGE);
    }
  };

  const handleSave = (onClose) => async () => {
    if (data?.category === "habit") {
      await DataStore.save(
        new LeavesShuukan({
          yaritaikoto: data?.yaritaikoto,
          yuusendo: data?.habitPriority,
          episode: data?.reason,
          leavesusersID: context.leavesUser.id,
        })
      );
      navigate("/will/tobelist");
    } else {
      await DataStore.save(
        new LeavesLifeFuture({
          yaritaikoto: data?.yaritaikoto,
          jitsugennenreikigen: Number(data?.challengeAge),
          tasseijoukyoushurui: Tasseijoukyoushurui.JYUNBICHUU,
          yuusendo: data?.challengePriority,
          episode: data?.reason,
          tasseijoukyou: false,
          leavesusersID: context.leavesUser.id,
        })
      );
      navigate("/will/bucketlist");
    }
    onClose();
    Cookies.set("isShowedBucketListGuide", true);
  };

  const handleSelectCategory = () => {
    let valid = true;
    const error = { ...dataError };
    if (isEmpty(data?.yaritaikoto)) {
      error.yaritaikoto = getLabel("error.required");
      valid = false;
    } else {
      error.yaritaikoto = "";
    }
    if (isEmpty(data?.category)) {
      error.category = getLabel("error.required");
      valid = false;
    } else {
      error.category = "";
    }
    setDataError(error);
    if (valid) setStep(GUIDE_STEP.REASON);
  };

  const handleSelectHabitPriority = () => {
    let valid = true;
    const error = { ...dataError };
    if (isEmpty(data?.habitPriority)) {
      error.habitPriority = getLabel("error.required");
      valid = false;
    } else {
      error.habitPriority = "";
    }
    setDataError(error);
    if (valid) setStep(GUIDE_STEP.HABIT_DONE);
  };

  const handleSelectChallengeAge = () => {
    let valid = true;
    const error = { ...dataError };
    if (isEmpty(data?.challengeAge)) {
      error.challengeAge = getLabel("error.required");
      valid = false;
    } else {
      error.challengeAge = "";
    }
    setDataError(error);
    if (valid) setStep(GUIDE_STEP.CHALLENGE_PRIORITY);
  };

  const handleSelectChallengePriority = () => {
    let valid = true;
    const error = { ...dataError };
    if (isEmpty(data?.challengePriority)) {
      error.challengePriority = getLabel("error.required");
      valid = false;
    } else {
      error.challengePriority = "";
    }
    setDataError(error);
    if (valid) setStep(GUIDE_STEP.CHALLENGE_DONE);
  };

  const renderContentIcon = (content)=>{
    if(!content) return "";
    const splitString  = content?.split("<slot>");
    return <>
      {splitString[0]}
      <Image src={memo} width="24px" height="24px" transform="translateY(6px)" />
      {splitString[1]}
    </>
  }

  const DialogConfirm = () => {
    return confirmAlert({
      overlayClassName: "react-confirm-alert-overlay-custom",
      closeOnClickOutside: false,
      closeOnEscape: false,
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <Dialog
              width={isMobile ? "295px" : "512px"}
              minHeight="405px"
              justifyContent="space-between"
              overrides={{
                "Flex.Flex[0]": {
                  paddingLeft: isMobile? "23px" : "48px",
                  paddingRight: isMobile? "23px" : "48px",
                  paddingBottom: "14px",
                  className: "width100",
                },
                "Flex.Flex[0].Text[0]": {
                  children: getLabel("bucketlist.guide.confirmTitle"),
                  className: "width100",
                },
                "Flex.Flex[0].Text[1]": {
                  className: "width100",
                  children: (
                    <Flex>
                      <Text
                        fontSize={isMobile ? "14px" : "16px"}
                        marginBottom="8px"
                        textAlign="left"
                        whiteSpace="pre-wrap"
                      >
                        {getLabel("bucketlist.guide.confirmContent")}
                      </Text>
                    </Flex>
                  ),

                  textAlign: "justify",
                  color: "black",
                },
                "Flex.Flex[1]":{
                  className: "width100",
                },
                "Flex.Flex[1].DefaultButton[0]": {
                  className: "pointer",
                  overrides: {
                    "Flex.Button[0]": {
                      width: "110px",
                      children: getLabel("sukippu"),
                      fontSize: isMobile ? "14px" : "16px",
                      onClick: () => {
                        onClose();
                        Cookies.set("isShowedBucketListGuide", true);
                      },
                    },
                  },
                },
                "Flex.Flex[1].PrimaryButton[0]": {
                  className: "pointer",
                  width: "110px",
                  onClick: handleChangeStep(GUIDE_STEP.SELECT_CATEGORY),
                  overrides: {
                    "Flex.Text[0]": {
                      children: getLabel("hajimeru"),
                      fontSize: isMobile ? "14px" : "16px",
                    },
                  },
                },
              }}
            />
          </div>
        );
      },
    });
  };

  const DialogSelectCategory = () => {
    return confirmAlert({
      overlayClassName: "react-confirm-alert-overlay-custom",
      closeOnClickOutside: false,
      closeOnEscape: false,
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <Dialog
              width={isMobile ? "295px" : "512px"}
              minHeight="405px"
              justifyContent="space-between"
              overrides={{
                "Flex.Flex[0]": {
                  gap: "8px",
                  paddingLeft: isMobile? "23px" : "48px",
                  paddingRight: isMobile? "23px" : "48px",
                  paddingBottom: "14px",
                  className: "width100",
                },
                "Flex.Flex[0].Text[0]": {
                  children: getLabel("bucketlist.guide.categoryTitle"),
                  className: "width100",
                },
                "Flex.Flex[0].Text[1]": {
                  className: "width100",
                  children: (
                    <Flex direction="column" gap="0" >
                      <Stepper margin="auto" marginBottom="14px" />
                      <Text
                        fontSize={isMobile ? "14px" : "16px"}
                        textAlign="left"
                        whiteSpace="pre-wrap"
                      >
                        {getLabel("bucketlist.guide.categoryContent")}
                      </Text>
                      <TextField
                        className={isMobile ? "" : "maxwidth800"}
                        isRequired
                        value={data?.yaritaikoto}
                        label={getLabel("yaritaikoto")}
                        errorMessage={dataError.yaritaikoto}
                        onChange={handleChangeData("yaritaikoto")}
                        placeholder={getLabel("fujisanninoborunado")}
                        overrideStyle={{ padding: "14px 0px 0px 0px", margin: isMobile ? "0" :"0 0 16px 0" }}
                      />
                      <SelectField
                        className={isMobile ? "" : "maxwidth800"}
                        isRequired
                        value={data.category}
                        overrideStyle={isMobile && { padding: "14px 0px 0px 0px" }}
                        onChange={handleChangeData("category")}
                        label={getLabel("yaritaikotonoshurui")}
                        errorMessage={dataError.category}
                        children={[
                          <option ref={categoryRef} key={0} value={""}>
                            {getLabel("list.select")}
                          </option>,
                          <option key={1} value={"challenge"}>
                            {getLabel("challenge")}
                          </option>,
                          <option key={2} value={"habit"}>
                            {getLabel("shuukan")}
                          </option>,
                        ]}
                      />
                    </Flex>
                  ),

                  textAlign: "justify",
                  color: "black",
                },
                "Flex.Flex[1]":{
                  className: "width100",
                },
                "Flex.Flex[1].DefaultButton[0]": {
                  className: "pointer",
                  overrides: {
                    "Flex.Button[0]": {
                      width: "110px",
                      fontSize: isMobile ? "14px" : "16px",
                      children: getLabel("dialog.back"),
                      onClick: handleChangeStep(GUIDE_STEP.CONFIRM),
                    },
                  },
                },
                "Flex.Flex[1].PrimaryButton[0]": {
                  className: "pointer",
                  width: "110px",
                  onClick: handleSelectCategory,
                  overrides: {
                    "Flex.Text[0]": {
                      children: getLabel("tsugie"),
                      fontSize: isMobile ? "14px" : "16px",
                    },
                  },
                },
              }}
            />
          </div>
        );
      },
    });
  };

  const DialogReason = () => {
    return confirmAlert({
      overlayClassName: "react-confirm-alert-overlay-custom",
      closeOnClickOutside: false,
      closeOnEscape: false,
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <Dialog
              width={isMobile ? "295px" : "512px"}
              minHeight="405px"
              justifyContent="space-between"
              overrides={{
                "Flex.Flex[0]": {
                  gap: "8px",
                  paddingLeft: isMobile? "23px" : "48px",
                  paddingRight: isMobile? "23px" : "48px",
                  paddingBottom: "14px",
                  className: "width100",
                },
                "Flex.Flex[0].Text[0]": {
                  className: "width100",
                  children: getLabel("bucketlist.guide.reasonTitle"),
                },
                "Flex.Flex[0].Text[1]": {
                  className: "width100",
                  children: (
                    <Flex direction="column" gap="0">
                      <Stepper
                        margin="auto"
                        marginBottom="14px"
                        overrides={{
                          circle2: {
                            paths: circelFillActive,
                          },
                          circle4: {
                            display:
                              data?.category === "habit" ? "none" : "block",
                          },
                        }}
                      />
                      <Text
                        fontSize={isMobile ? "14px" : "16px"}
                        textAlign="left"
                        whiteSpace="pre-wrap"
                      >
                        {getLabel("bucketlist.guide.reasonContent")}
                      </Text>
                      <TextFieldMultiline
                        className={isMobile ? "area-h58" : "maxwidth800 area-h65"}
                        value={data?.reason}
                        isRequired
                        errorMessage={dataError.reason}
                        label={getLabel("yaritairiyu")}
                        onChange={handleChangeData("reason")}
                        placeholder={getLabel("yaritairiyu.placeholder")}
                        overrideStyle={{ padding: "14px 0px 0px 0px" }}
                      />
                    </Flex>
                  ),

                  textAlign: "justify",
                  color: "black",
                },
                "Flex.Flex[1]":{
                  className: "width100",
                },
                "Flex.Flex[1].DefaultButton[0]": {
                  className: "pointer",
                  overrides: {
                    "Flex.Button[0]": {
                      width: "110px",
                      fontSize: isMobile ? "14px" : "16px",
                      children: getLabel("dialog.back"),
                      onClick: handleChangeStep(GUIDE_STEP.SELECT_CATEGORY),
                    },
                  },
                },
                "Flex.Flex[1].PrimaryButton[0]": {
                  className: "pointer",
                  width: "110px",
                  onClick: handleNextReason,
                  overrides: {
                    "Flex.Text[0]": {
                      fontSize: isMobile ? "14px" : "16px",
                      children: getLabel("tsugie"),
                    },
                  },
                },
              }}
            />
          </div>
        );
      },
    });
  };

  const DialogSelectChallengeAge = () => {
    return confirmAlert({
      overlayClassName: "react-confirm-alert-overlay-custom",
      closeOnClickOutside: false,
      closeOnEscape: false,
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <Dialog
              width={isMobile ? "295px" : "512px"}
              minHeight="405px"
              justifyContent="space-between"
              overrides={{
                "Flex.Flex[0]": {
                  gap: "8px",
                  paddingLeft: isMobile? "23px" : "48px",
                  paddingRight: isMobile? "23px" : "48px",
                  paddingBottom: "14px",
                  className: "width100",
                },
                "Flex.Flex[0].Text[0]": {
                  children: getLabel("bucketlist.guide.challengeAgeTitle"),
                  className: "width100",
                },
                "Flex.Flex[0].Text[1]": {
                  className: "width100",
                  children: (
                    <Flex direction="column" gap="0">
                      <Stepper
                        margin="auto"
                        marginBottom="14px"
                        overrides={{
                          circle2: {
                            paths: circelFillActive,
                          },
                          circle3: {
                            paths: circelFillActive,
                          },
                        }}
                      />
                      <Text
                        fontSize={isMobile ? "14px" : "16px"}
                        textAlign="left"
                        whiteSpace="pre-wrap"
                      >
                        {getLabel("bucketlist.guide.challengeAgeContent")}
                      </Text>
                      <SelectField
                        className={isMobile ? "" : "maxwidth800"}
                        isRequired
                        value={data.challengeAge}
                        errorMessage={dataError.challengeAge}
                        onChange={handleChangeData("challengeAge")}
                        label={getLabel("jitsugennenreikigen")}
                        overrideStyle={{ padding: "14px 0px 0px 0px" }}
                        children={[
                          <option
                            key={"challengeData.jitsugennenreikigen.unselect"}
                            value=""
                          >
                            {getLabel("list.select")}
                          </option>,
                          ...ageRangeOption?.map((ele) => {
                            return (
                              <option key={ele?.value} value={ele?.value}>
                                {ele?.label}
                              </option>
                            );
                          }),
                        ]}
                      />
                    </Flex>
                  ),

                  textAlign: "justify",
                  color: "black",
                },
                "Flex.Flex[1]":{
                  className: "width100",
                },
                "Flex.Flex[1].DefaultButton[0]": {
                  className: "pointer",
                  overrides: {
                    "Flex.Button[0]": {
                      width: "110px",
                      fontSize: isMobile ? "14px" : "16px",
                      children: getLabel("dialog.back"),
                      onClick: handleChangeStep(GUIDE_STEP.REASON),
                    },
                  },
                },
                "Flex.Flex[1].PrimaryButton[0]": {
                  className: "pointer",
                  width: "110px",
                  onClick: handleSelectChallengeAge,
                  overrides: {
                    "Flex.Text[0]": {
                      fontSize: isMobile ? "14px" : "16px",
                      children: getLabel("tsugie"),
                    },
                  },
                },
              }}
            />
          </div>
        );
      },
    });
  };

  const DialogSelectHabitPriority = () => {
    return confirmAlert({
      overlayClassName: "react-confirm-alert-overlay-custom",
      closeOnClickOutside: false,
      closeOnEscape: false,
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <Dialog
              width={isMobile ? "295px" : "512px"}
              minHeight="405px"
              justifyContent="space-between"
              overrides={{
                "Flex.Flex[0]": {
                  gap: "8px",
                  paddingLeft: isMobile? "23px" : "48px",
                  paddingRight: isMobile? "23px" : "48px",
                  paddingBottom: "14px",
                  className: "width100",
                },
                "Flex.Flex[0].Text[0]": {
                  children: getLabel("bucketlist.guide.priorityTitle"),
                  className: "width100",
                },
                "Flex.Flex[0].Text[1]": {
                  className: "width100",
                  children: (
                    <Flex direction="column" gap="0">
                      <Stepper
                        margin="auto"
                        marginBottom="14px"
                        overrides={{
                          circle2: {
                            paths: circelFillActive,
                          },
                          circle3: {
                            paths: circelFillActive,
                          },
                          circle4: {
                            display: "none",
                          },
                        }}
                      />
                      <Text
                        fontSize={isMobile ? "14px" : "16px"}
                        textAlign="left"
                        whiteSpace="pre-wrap"
                      >
                        {getLabel("bucketlist.guide.habitPriorityContent")}
                      </Text>
                      <SelectField
                      className={isMobile ? "" : "maxwidth800"}
                        isRequired
                        value={data.habitPriority}
                        errorMessage={dataError.habitPriority}
                        onChange={handleChangeData("habitPriority")}
                        label={getLabel("yuusendo")}
                        overrideStyle={{ padding: "14px 0px 0px 0px" }}
                        children={[
                          <option
                            key={"challengeData.yuusendo.unselect"}
                            value={""}
                          >
                            {getLabel("list.select")}
                          </option>,
                          <option key={Priority.KOU} value={Priority.KOU}>
                            {getLabel(PriorityLabel.KOU)}
                          </option>,
                          <option key={Priority.CHUU} value={Priority.CHUU}>
                            {getLabel(PriorityLabel.CHUU)}
                          </option>,
                          <option key={Priority.TEI} value={Priority.TEI}>
                            {getLabel(PriorityLabel.TEI)}
                          </option>,
                        ]}
                      />
                    </Flex>
                  ),

                  textAlign: "justify",
                  color: "black",
                },
                "Flex.Flex[1]":{
                  className: "width100",
                },
                "Flex.Flex[1].DefaultButton[0]": {
                  className: "pointer",
                  overrides: {
                    "Flex.Button[0]": {
                      width: "110px",
                      fontSize: isMobile ? "14px" : "16px",
                      children: getLabel("dialog.back"),
                      onClick: handleChangeStep(GUIDE_STEP.REASON),
                    },
                  },
                },
                "Flex.Flex[1].PrimaryButton[0]": {
                  className: "pointer",
                  width: "110px",
                  onClick: handleSelectHabitPriority,
                  overrides: {
                    "Flex.Text[0]": {
                      fontSize: isMobile ? "14px" : "16px",
                      children: getLabel("tsugie"),
                    },
                  },
                },
              }}
            />
          </div>
        );
      },
    });
  };

  const DialogSelectChallengePriority = () => {
    return confirmAlert({
      overlayClassName: "react-confirm-alert-overlay-custom",
      closeOnClickOutside: false,
      closeOnEscape: false,
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <Dialog
              width={isMobile ? "295px" : "512px"}
              minHeight="405px"
              justifyContent="space-between"
              overrides={{
                "Flex.Flex[0]": {
                  gap: "8px",
                  paddingLeft: isMobile? "23px" : "48px",
                  paddingRight: isMobile? "23px" : "48px",
                  paddingBottom: "14px",
                  className: "width100",
                },
                "Flex.Flex[0].Text[0]": {
                  children: getLabel("bucketlist.guide.priorityTitle"),
                  className: "width100",
                },
                "Flex.Flex[0].Text[1]": {
                  className: "width100",
                  children: (
                    <Flex direction="column" gap="0">
                      <Stepper
                        margin="auto"
                        marginBottom="14px"
                        overrides={{
                          circle2: {
                            paths: circelFillActive,
                          },
                          circle3: {
                            paths: circelFillActive,
                          },
                          circle4: {
                            paths: circelFillActive,
                          },
                        }}
                      />
                      <Text
                        fontSize={isMobile ? "14px" : "16px"}
                        textAlign="left"
                        whiteSpace="pre-wrap"
                      >
                        {getLabel("bucketlist.guide.challengePriorityContent")}
                      </Text>
                      <SelectField
                      className={isMobile ? "" : "maxwidth800"}
                        isRequired
                        value={data.challengePriority}
                        onChange={handleChangeData("challengePriority")}
                        label={getLabel("yuusendo")}
                        errorMessage={dataError.challengePriority}
                        overrideStyle={{ padding: "14px 0px 0px 0px" }}
                        children={[
                          <option
                            key={"challengeData.yuusendo.unselect"}
                            value={""}
                          >
                            {getLabel("list.select")}
                          </option>,
                          <option key={Priority.KOU} value={Priority.KOU}>
                            {getLabel(PriorityLabel.KOU)}
                          </option>,
                          <option key={Priority.CHUU} value={Priority.CHUU}>
                            {getLabel(PriorityLabel.CHUU)}
                          </option>,
                          <option key={Priority.TEI} value={Priority.TEI}>
                            {getLabel(PriorityLabel.TEI)}
                          </option>,
                        ]}
                      />
                    </Flex>
                  ),

                  textAlign: "justify",
                  color: "black",
                },
                "Flex.Flex[1]":{
                  className: "width100",
                },
                "Flex.Flex[1].DefaultButton[0]": {
                  className: "pointer",
                  overrides: {
                    "Flex.Button[0]": {
                      width: "110px",
                      children: getLabel("dialog.back"),
                      fontSize: isMobile ? "14px" : "16px",
                      onClick: handleChangeStep(GUIDE_STEP.CHALLENGE_AGE),
                    },
                  },
                },
                "Flex.Flex[1].PrimaryButton[0]": {
                  className: "pointer",
                  width: "110px",
                  onClick: handleSelectChallengePriority,
                  overrides: {
                    "Flex.Text[0]": {
                      children: getLabel("tsugie"),
                      fontSize: isMobile ? "14px" : "16px",
                    },
                  },
                },
              }}
            />
          </div>
        );
      },
    });
  };

  const DialogChallengeDone = () => {
    return confirmAlert({
      overlayClassName: "react-confirm-alert-overlay-custom",
      closeOnClickOutside: false,
      closeOnEscape: false,
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <Dialog
              width={isMobile ? "295px" : "512px"}
              minHeight="405px"
              justifyContent="space-between"
              overrides={{
                "Flex.Flex[0]": {
                  className: "width100",
                  paddingLeft: isMobile? "23px" : "48px",
                  paddingRight: isMobile? "23px" : "48px",
                },
                "Flex.Flex[0].Text[0]": {
                  children: getLabel("bucketlist.guide.challengeDoneTitle"),
                  className: "width100",
                },
                "Flex.Flex[0].Text[1]": {
                  className: "width100",
                  children: (
                    <Text
                      fontSize={isMobile ? "14px" : "16px"}
                      marginBottom="8px"
                      textAlign="left"
                      whiteSpace="pre-wrap"
                    >
                      {renderContentIcon(getLabel("bucketlist.guide.challengeDoneContent"))}
                    </Text>
                  ),

                  textAlign: "justify",
                  color: "black",
                },
                "Flex.Flex[1]":{
                  className: "width100",
                },
                "Flex.Flex[1].DefaultButton[0]": {
                  className: "pointer",
                  display: "none",
                },
                "Flex.Flex[1].PrimaryButton[0]": {
                  className: "pointer",
                  width: "231px",
                  onClick: handleSave(onClose),
                  overrides: {
                    "Flex.Text[0]": {
                      children: getLabel("keikakuwotateru"),
                      fontSize: isMobile ? "14px" : "16px",
                    },
                  },
                },
              }}
            />
          </div>
        );
      },
    });
  };

  const DialogHabitDone = () => {
    return confirmAlert({
      overlayClassName: "react-confirm-alert-overlay-custom",
      closeOnClickOutside: false,
      closeOnEscape: false,
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <Dialog
              width={isMobile ? "295px" : "512px"}
              minHeight="405px"
              justifyContent="space-between"
              overrides={{
                "Flex.Flex[0]": {
                  className: "width100",
                  paddingLeft: isMobile? "23px" : "48px",
                  paddingRight: isMobile? "23px" : "48px",
                },
                "Flex.Flex[0].Text[0]": {
                  children: getLabel("bucketlist.guide.habitDoneTitle"),
                  className: "width100",
                },
                "Flex.Flex[0].Text[1]": {
                  className: "width100",
                  children: (
                    <Text
                      fontSize={isMobile ? "14px" : "16px"}
                      marginBottom="8px"
                      textAlign="left"
                      whiteSpace="pre-wrap"
                    >
                      {renderContentIcon(getLabel("bucketlist.guide.habitDoneContent"))}
                    </Text>
                  ),

                  textAlign: "justify",
                  color: "black",
                },
                "Flex.Flex[1]":{
                  className: "width100",
                },
                "Flex.Flex[1].DefaultButton[0]": {
                  className: "pointer",
                  display: "none",
                },
                "Flex.Flex[1].PrimaryButton[0]": {
                  className: "pointer",
                  width: "231px",
                  onClick: handleSave(onClose),
                  overrides: {
                    "Flex.Text[0]": {
                      fontSize: isMobile ? "14px" : "16px",
                      children: getLabel("keikakuwotateru"),
                    },
                  },
                },
              }}
            />
          </div>
        );
      },
    });
  };

  useEffect(() => {
    switch (step) {
      case GUIDE_STEP.CONFIRM:
        DialogConfirm();
        break;
      case GUIDE_STEP.SELECT_CATEGORY:
        DialogSelectCategory();
        break;
      case GUIDE_STEP.REASON:
        DialogReason();
        break;
      case GUIDE_STEP.CHALLENGE_AGE:
        DialogSelectChallengeAge();
        break;
      case GUIDE_STEP.HABIT_PRIORITY:
        DialogSelectHabitPriority();
        break;
      case GUIDE_STEP.CHALLENGE_PRIORITY:
        DialogSelectChallengePriority();
        break;
      case GUIDE_STEP.CHALLENGE_DONE:
        DialogChallengeDone();
        break;
      case GUIDE_STEP.HABIT_DONE:
        DialogHabitDone();
        break;
      default:
        break;
    }
  }, [step, data, dataError]);

  return <></>;
}
