import { Text } from "@aws-amplify/ui-react";
import { isMobile } from "react-device-detect";

export default function SubText(props) {
  return (
    <Text
      position="relative"
      padding={isMobile ? "8px 16px 0px 16px" : "0"}
      fontSize={isMobile ? "12px" : "14px"}
      maxWidth={isMobile ? "100%" : "800px"}
      top={isMobile ? "0" : "-8px"}
      margin={isMobile ? "auto" : "0 auto 8px auto"}
      width="100%"
      color="#707070"
    >
      {props.text}
    </Text>
  );
}
