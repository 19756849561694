import { DataStore } from "aws-amplify";
import { useCallback, useEffect, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useParams } from "react-router-dom";
import { LeavesTakaramono } from "../models";
import { ListViewDetail } from "../page-components";
import { useInitializePage } from "../utils/CommonUtils";
import { isShare, queryData } from "../utils/ShareUtils";

function CollectionViewPage() {
  const navigate = useInitializePage(
    "takaramonolistcollection",
    "title.detail",
  );
  const [collectData, setCollectData] = useState({
    collectionmei: "",
    hokanbasyo: "",
    bikou: "",
  });
  const params = useParams();
  const deleteData = useCallback(async () => {
    await DataStore.delete(collectData);
    navigate(-1, { replace: true });
  }, [collectData, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const data = await queryData(params, LeavesTakaramono);
      if (data?.length > 0) {
        setCollectData(data[0]);
      }
    };
    getData();
  }, [params.id]);

  const listLabel = [
    {
      label: "collectionmei",
      value: collectData?.collectionmei,
      isRequire: true,
    },
    { label: "hokanbasyo", value: collectData?.hokanbasyo },
    { label: "bikou", value: collectData?.bikou, isMulti: true },
  ];

  return (
    <ListViewDetail
      label={collectData?.collectionmei}
      list={listLabel}
      isHideEdit={isShare(params)}
      onDelete={deleteData}
      onEdit={() => {
        navigate("edit");
        window.gtag("event", "Change", {
            event_category: "Edit",
            event_label: "宝物リスト・コレクション変更開始",
        });
    }}
    />
  );
}

export default CollectionViewPage;
