import { Button, Flex, Radio, RadioGroupField, Text } from "@aws-amplify/ui-react";
import { DataStore } from "aws-amplify";
import { useCallback, useEffect, useRef, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { LeavesKazokuShinzoku, LeavesYuujinChijin } from "../models";
import { DialogButton, TextButton } from "../ui-components";
import { preventBodyScrollMobile, getLabel } from "../utils/CommonUtils";
import Description from "./Description";

const Type = {
  SELECT: "select",
  FAMILY: "family",
  FRIEND: "friend",
  EMPTY: "empty",
};

export default function FamilyAndFriendContact(props) {
  const navigate = useNavigate();
  const [dialog, setDialog] = useState(false);
  const dialogRef = useRef();
  const selected = useRef(null);
  const [listData, setListData] = useState();
  const [selectedContact, setSelectedContact] = useState(getLabel("list.select"));
  const [dialogType, setDialogType] = useState(Type.SELECT);
  const [isLoadItem, setIsLoadItem] = useState(false);
  const firstRender = useRef(false);

  const handlSelect = (type) => {
    setDialogType(type);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        setDialog(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [dialogRef]);

  useEffect(() => {
    const getData = async () => {
      setIsLoadItem(true);
      let data = [];
      if (dialogType === Type.FAMILY) {
        data = await DataStore.query(LeavesKazokuShinzoku);
        setIsLoadItem(false);
        const item = data?.filter((ele) => ele.id === props.selected);
        if (item?.length === 0) selected.current = { value: "" };
        else {
          selected.current = { value: item[0]?.id };
        }
      } else if (dialogType === Type.FRIEND) {
        data = await DataStore.query(LeavesYuujinChijin);
        setIsLoadItem(false);
        const item = data?.filter((ele) => ele.id === props.selected);
        if (item?.length === 0) selected.current = { value: "" };
        else {
          selected.current = { value: item[0]?.id };
        }
      }
      setListData(data?.filter((d) => d.mailaddress));
    };
    getData();
  }, [dialogType, props.selected]);

  useEffect(() => {
    if (props?.selected) {
      const getData = async () => {
        const contact = await DataStore.query(LeavesKazokuShinzoku, (c) => c.id("eq", props.selected));
        if (contact?.length > 0) {
          setSelectedContact(contact[0]?.shimei);
        } else {
          const contact = await DataStore.query(LeavesYuujinChijin, (c) => c.id("eq", props.selected));
          if (contact?.length > 0) {
            setSelectedContact(contact[0]?.shimei);
          } else {
            selected.current = { value: "" };
          }
        }
      };
      getData();
      selected.current = { value: props.selected };
    } else {
      selected.current = { value: "" };
      setSelectedContact(getLabel("list.select"));
    }
  }, [props.selected]);

  useEffect(() => {
    if (!dialog) {
      setDialogType(Type.SELECT);
    }
  }, [dialog]);

  useEffect(() => {
    if (firstRender.current) {
      preventBodyScrollMobile(dialog);
    } else firstRender.current = true;
  }, [dialog]);

  const complete = useCallback(() => {
    if (selected.current.value === "") {
      setSelectedContact(getLabel("list.select"));
    } else {
      setSelectedContact(listData.filter((f) => selected.current.value === f.id).map((f) => f.shimei));
    }
    if (props.onComplete) {
      props.onComplete({ type: dialogType, id: selected.current.value });
    }
    setDialog(false);
  }, [dialogType, listData, props]);

  const autoSave = useCallback(() => {
    if (props.autoSave) {
      return props.autoSave();
    }
    return true;
  }, [dialogType, props]);

  const DialogSelect = useCallback((props) => {
    return (
      <Flex display="block" paddingBottom="19px">
        <Text fontWeight="700" marginBottom="14px">
          {getLabel("renrakusenrisutoosentaku")}
        </Text>
        <Description text={getLabel("renrakusenrisutoosentaku.des")} fontSize="14px" padding="0 16px 0 16px" width="fit-content" margin="auto" />
        <Button width="100%" maxWidth="250px" fontWeight="700" marginTop="16px" padding="6px 16px" fontSize="14px" onClick={() => handlSelect(Type.FAMILY)}>
          {getLabel("kazokushinzoku")}
        </Button>
        <Button width="100%" maxWidth="250px" fontWeight="700" marginTop="16px" padding="6px 16px" fontSize="14px" onClick={() => handlSelect(Type.FRIEND)}>
          {getLabel("yuujinchijin")}
        </Button>
      </Flex>
    );
  }, []);

  const EmptyDialog = useCallback(
    (props) => {
      return (
        <Flex display="block" paddingBottom="8px">
          <Text fontWeight="700" fontSize="16px" children={getLabel("renrakusengatorokusareteimasen")} marginBottom="8px" />
          <Text fontSize="14px" marginBottom="30px" textAlign="left" color="#5C6670">
            {getLabel("contact.detail")}
          </Text>
          <DialogButton
            margin="auto"
            overrides={{
              DefaultButton: {
                className: "pointer",
              },
              Button: {
                children: getLabel("atodesuru"),
                onClick: () => {
                  setDialogType(Type.SELECT);
                },
                onFocus: () => {},
              },
              "\u270F\uFE0F Button text": {
                children: getLabel("torokuosuru"),
                className: "pointer",
                onClick: () => {
                  const valid = autoSave();
                  if (valid) {
                    navigate(`/data/contact/${dialogType === Type.FAMILY ? "family" : "friends"}`, { state: { dialog: false } });
                  } else {
                    setDialog(false);
                  }
                },
              },
            }}
          />
        </Flex>
      );
    },
    [dialogType, navigate]
  );

  const ContactList = useCallback(
    (props) => {
      return (
        <Flex display="block" paddingBottom="8px">
          <Text fontWeight="700" fontSize="16px" children={getLabel("soushinnsakiwosentaku")} marginBottom="8px" />
          <Text fontSize="14px" padding="0 16px"  marginBottom="8px" textAlign="left">
            {getLabel("familiescontactaddressfield.description1")}
            <font
              className="pointer"
              style={{ color: "var(--my-theme-color)" }}
              onClick={() => {
                const valid = autoSave();
                if (valid) {
                  navigate(`/data/contact/${dialogType === Type.FAMILY ? "family" : "friends"}`, { state: { dialog: false } });
                } else {
                  setDialog(false);
                }
              }}
            >
              {getLabel("renrakusakilist")}
            </font>
            {getLabel("familiescontactaddressfield.description2")}
          </Text>
          <Flex maxHeight="300px" padding="0 16px">
            <RadioGroupField
              defaultValue={selected?.current?.value}
              className="radio-swapper"
              paddingBottom="16px"
              margin="2px"
              name="familylist"
              width="100%"
              onChange={(e) => {
                selected.current = e.target;
              }}
            >
              <Radio value="">{getLabel("list.select")}</Radio>
              {props?.list?.map((f, i) => {
                return (
                  <Radio key={i} value={f.id}>
                    {f.shimei}
                  </Radio>
                );
              })}
            </RadioGroupField>
          </Flex>

          <DialogButton
            margin="auto"
            overrides={{
              DefaultButton: {
                className: "pointer",
              },
              Button: {
                children: getLabel("dialog.back"),
                onClick: () => {
                  setDialogType(Type.SELECT);
                },
              },
              PrimaryButton: {
                className: "pointer",
                onClick: () => {
                  complete();
                },
              },
            }}
          />
        </Flex>
      );
    },
    [complete, dialogType, navigate]
  );
  const Dialog = useCallback(
    (props) => {
      return (
        <div className="react-confirm-alert">
          <div className="react-confirm-alert-overlay react-confirm-alert-overlay-custom">
            <div className="custom-ui" ref={dialogRef}>
              <Flex
                width={isMobile ? "295px" : "512px"}
                display={dialog ? "block" : "none"}
                boxShadow="0px 15px 12px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.25)"
                padding="21px 23px 0px 23px"
                direction="column"
                backgroundColor="rgba(255,255,255,1)"
              >
                {props.type === Type.SELECT && <DialogSelect {...props} />}
                {(props.type === Type.FAMILY || props.type === Type.FRIEND) &&
                  (listData.length > 0 ? <ContactList list={listData} /> : !isLoadItem && <EmptyDialog />)}
              </Flex>
            </div>
          </div>
        </div>
      );
    },
    [dialog, isLoadItem, listData]
  );

  return (
    <Flex
      className={isMobile ? "" : props.className ? props.className : "pccomponent"}
      direction="column"
      padding={isMobile ? "16px 16px 0px" : "0"}
      gap="0"
    >
      <Text fontSize={isMobile ? "14px" : "16px"}>{props.label}</Text>
      <Text
        color="#5c6670"
        fontSize={isMobile ? "14px" : "16px"}
        textAlign="justify"
        className="word-break"
        width="fit-content"
        maxWidth="100vw"
        children={
          props?.defaultValueShow
            ? `${props.defaultValueShow}${selectedContact !== getLabel("list.select") ? "、" + selectedContact : ""}`
            : selectedContact
        }
      />
      <TextButton
        className="pointer"
        onClick={() => {
          setDialog(true);
        }}
        overrides={{
          "Flex.Text[0]": {
            fontSize: isMobile ? "14px" : "16px",
            children: getLabel("renrakusakilistkarasentaku"),
          },
        }}
      />
      {dialog ? <Dialog type={dialogType} {...props} /> : null}
    </Flex>
  );
}
