/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getLeavesUsers = /* GraphQL */ `
  query GetLeavesUsers($id: ID!) {
    getLeavesUsers(id: $id) {
      id
      name
      loggedin
      kanshinnoaruCategory
      LeavesJitsubutsuShisans {
        nextToken
        startedAt
        __typename
      }
      LeavesKinyuuShisans {
        nextToken
        startedAt
        __typename
      }
      LeavesHokens {
        nextToken
        startedAt
        __typename
      }
      LeavesKaigo {
        id
        handanwoyudanetaihito
        kiboukaigoshurui
        kiboushisetsu
        hiyoukyoshutsu
        zaisankanriwomakaseruhito
        zaisankanriwomakaseruhitonokeiyakujoukyou
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesSougi {
        id
        sougiyouhi
        kibousougikeishiki
        souginosyurui
        sankaninzuukibo
        kibousuruhuniki
        junbishiteiruhiyou
        hiyoukyoshutsu
        hokenkyousainado
        souginiyondehoshiyuujinchijin
        yondehoshiihitonituite
        souginiyobukakazokuniitininsuruyuujinchijin
        itininsuruhitonituite
        souginoannaiwoshinakutemoyoihitonituite
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesLifeFutures {
        nextToken
        startedAt
        __typename
      }
      LeavesShuukans {
        nextToken
        startedAt
        __typename
      }
      LeavesMemos {
        nextToken
        startedAt
        __typename
      }
      LeavesKazokuShinzokus {
        nextToken
        startedAt
        __typename
      }
      LeavesJibunshis {
        nextToken
        startedAt
        __typename
      }
      LeavesHints {
        nextToken
        startedAt
        __typename
      }
      LeavesPets {
        nextToken
        startedAt
        __typename
      }
      LeavesIryousochi {
        id
        kokuchikibounoumu
        enmeisochikibounoumu
        zoukiteikyounoumu
        handanwoyudanetaihito
        ishikiganakunattarashirasetehoshiiyuujinchijin
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesCreditcards {
        nextToken
        startedAt
        __typename
      }
      LeavesKouzas {
        nextToken
        startedAt
        __typename
      }
      LeavesNenkins {
        nextToken
        startedAt
        __typename
      }
      LeavesKankonsousais {
        nextToken
        startedAt
        __typename
      }
      LeavesSumahoPcs {
        nextToken
        startedAt
        __typename
      }
      LeavesLoginIds {
        nextToken
        startedAt
        __typename
      }
      LeavesYuujinChijins {
        nextToken
        startedAt
        __typename
      }
      LeavesKenkoukanris {
        nextToken
        startedAt
        __typename
      }
      LeavesSeikatsunokoto {
        id
        seikatsunokotonitsuite
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesTakaramonos {
        nextToken
        startedAt
        __typename
      }
      LeavesMyEvents {
        nextToken
        startedAt
        __typename
      }
      LeavesHakanitsuite {
        id
        hairuyoteinoohakanoumu
        kibousuruohakanokeishiki
        kibousurubasyo
        hiyoukyoshutsu
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesDaijinishitaikinenbis {
        nextToken
        startedAt
        __typename
      }
      LeavesKakeizus {
        nextToken
        startedAt
        __typename
      }
      LeavesYuigonshonitsuites {
        id
        yuigonshonoumu
        yuigonshonoshurui
        yuigonshonosaisyuusakuseibi
        yuigonshikkousha
        yuigonshikkoushanorenrakusaki
        iraisoudanwoshiteirusenmonka
        iraisoudanwoshiteirusenmonkanorenrakusaki
        sonotasouzokunikansurukibounado
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesTaisetsunishitaihitohenomessages {
        nextToken
        startedAt
        __typename
      }
      LeavesLoankariirekins {
        nextToken
        startedAt
        __typename
      }
      LeavesHosyousaimus {
        nextToken
        startedAt
        __typename
      }
      LeavesShareInfos {
        nextToken
        startedAt
        __typename
      }
      LeavesSharedInfos {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      leavesUsersLeavesKaigoId
      leavesUsersLeavesSougiId
      leavesUsersLeavesIryousochiId
      leavesUsersLeavesSeikatsunokotoId
      leavesUsersLeavesHakanitsuiteId
      leavesUsersLeavesYuigonshonitsuitesId
      owner
      __typename
    }
  }
`;
export const listLeavesUsers = /* GraphQL */ `
  query ListLeavesUsers(
    $filter: ModelLeavesUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        loggedin
        kanshinnoaruCategory
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        leavesUsersLeavesKaigoId
        leavesUsersLeavesSougiId
        leavesUsersLeavesIryousochiId
        leavesUsersLeavesSeikatsunokotoId
        leavesUsersLeavesHakanitsuiteId
        leavesUsersLeavesYuigonshonitsuitesId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesUsers = /* GraphQL */ `
  query SyncLeavesUsers(
    $filter: ModelLeavesUsersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        loggedin
        kanshinnoaruCategory
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        leavesUsersLeavesKaigoId
        leavesUsersLeavesSougiId
        leavesUsersLeavesIryousochiId
        leavesUsersLeavesSeikatsunokotoId
        leavesUsersLeavesHakanitsuiteId
        leavesUsersLeavesYuigonshonitsuitesId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesJitsubutsuShisan = /* GraphQL */ `
  query GetLeavesJitsubutsuShisan($id: ID!) {
    getLeavesJitsubutsuShisan(id: $id) {
      id
      shisanmei
      shisanshurui
      shozaibasho
      bikou
      _shisanshurui
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listLeavesJitsubutsuShisans = /* GraphQL */ `
  query ListLeavesJitsubutsuShisans(
    $filter: ModelLeavesJitsubutsuShisanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesJitsubutsuShisans(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shisanmei
        shisanshurui
        shozaibasho
        bikou
        _shisanshurui
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesJitsubutsuShisans = /* GraphQL */ `
  query SyncLeavesJitsubutsuShisans(
    $filter: ModelLeavesJitsubutsuShisanFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesJitsubutsuShisans(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        shisanmei
        shisanshurui
        shozaibasho
        bikou
        _shisanshurui
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesKinyuuShisan = /* GraphQL */ `
  query GetLeavesKinyuuShisan($id: ID!) {
    getLeavesKinyuuShisan(id: $id) {
      id
      shisanmei
      shisanshurui
      kinyuukikan
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listLeavesKinyuuShisans = /* GraphQL */ `
  query ListLeavesKinyuuShisans(
    $filter: ModelLeavesKinyuuShisanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesKinyuuShisans(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shisanmei
        shisanshurui
        kinyuukikan
        bikou
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesKinyuuShisans = /* GraphQL */ `
  query SyncLeavesKinyuuShisans(
    $filter: ModelLeavesKinyuuShisanFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesKinyuuShisans(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        shisanmei
        shisanshurui
        kinyuukikan
        bikou
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesHoken = /* GraphQL */ `
  query GetLeavesHoken($id: ID!) {
    getLeavesHoken(id: $id) {
      id
      hokenmei
      hokenshurui
      hokengaisha
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listLeavesHokens = /* GraphQL */ `
  query ListLeavesHokens(
    $filter: ModelLeavesHokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesHokens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        hokenmei
        hokenshurui
        hokengaisha
        bikou
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesHokens = /* GraphQL */ `
  query SyncLeavesHokens(
    $filter: ModelLeavesHokenFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesHokens(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        hokenmei
        hokenshurui
        hokengaisha
        bikou
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesKaigo = /* GraphQL */ `
  query GetLeavesKaigo($id: ID!) {
    getLeavesKaigo(id: $id) {
      id
      handanwoyudanetaihito
      kiboukaigoshurui
      kiboushisetsu
      hiyoukyoshutsu
      zaisankanriwomakaseruhito
      zaisankanriwomakaseruhitonokeiyakujoukyou
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listLeavesKaigos = /* GraphQL */ `
  query ListLeavesKaigos(
    $filter: ModelLeavesKaigoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesKaigos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        handanwoyudanetaihito
        kiboukaigoshurui
        kiboushisetsu
        hiyoukyoshutsu
        zaisankanriwomakaseruhito
        zaisankanriwomakaseruhitonokeiyakujoukyou
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesKaigos = /* GraphQL */ `
  query SyncLeavesKaigos(
    $filter: ModelLeavesKaigoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesKaigos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        handanwoyudanetaihito
        kiboukaigoshurui
        kiboushisetsu
        hiyoukyoshutsu
        zaisankanriwomakaseruhito
        zaisankanriwomakaseruhitonokeiyakujoukyou
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesSougi = /* GraphQL */ `
  query GetLeavesSougi($id: ID!) {
    getLeavesSougi(id: $id) {
      id
      sougiyouhi
      kibousougikeishiki
      souginosyurui
      sankaninzuukibo
      kibousuruhuniki
      junbishiteiruhiyou
      hiyoukyoshutsu
      hokenkyousainado
      souginiyondehoshiyuujinchijin
      yondehoshiihitonituite
      souginiyobukakazokuniitininsuruyuujinchijin
      itininsuruhitonituite
      souginoannaiwoshinakutemoyoihitonituite
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listLeavesSougis = /* GraphQL */ `
  query ListLeavesSougis(
    $filter: ModelLeavesSougiFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesSougis(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sougiyouhi
        kibousougikeishiki
        souginosyurui
        sankaninzuukibo
        kibousuruhuniki
        junbishiteiruhiyou
        hiyoukyoshutsu
        hokenkyousainado
        souginiyondehoshiyuujinchijin
        yondehoshiihitonituite
        souginiyobukakazokuniitininsuruyuujinchijin
        itininsuruhitonituite
        souginoannaiwoshinakutemoyoihitonituite
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesSougis = /* GraphQL */ `
  query SyncLeavesSougis(
    $filter: ModelLeavesSougiFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesSougis(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sougiyouhi
        kibousougikeishiki
        souginosyurui
        sankaninzuukibo
        kibousuruhuniki
        junbishiteiruhiyou
        hiyoukyoshutsu
        hokenkyousainado
        souginiyondehoshiyuujinchijin
        yondehoshiihitonituite
        souginiyobukakazokuniitininsuruyuujinchijin
        itininsuruhitonituite
        souginoannaiwoshinakutemoyoihitonituite
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesLifeFuture = /* GraphQL */ `
  query GetLeavesLifeFuture($id: ID!) {
    getLeavesLifeFuture(id: $id) {
      id
      yaritaikoto
      jitsugennenreikigen
      tasseijoukyou
      tasseijoukyoushurui
      category
      yuusendo
      episode
      jitsugenstep {
        jitsugenstep
        shinchoku
        bikou
        __typename
      }
      leavesusersID
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listLeavesLifeFutures = /* GraphQL */ `
  query ListLeavesLifeFutures(
    $filter: ModelLeavesLifeFutureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesLifeFutures(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        yaritaikoto
        jitsugennenreikigen
        tasseijoukyou
        tasseijoukyoushurui
        category
        yuusendo
        episode
        leavesusersID
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesLifeFutures = /* GraphQL */ `
  query SyncLeavesLifeFutures(
    $filter: ModelLeavesLifeFutureFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesLifeFutures(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        yaritaikoto
        jitsugennenreikigen
        tasseijoukyou
        tasseijoukyoushurui
        category
        yuusendo
        episode
        leavesusersID
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesShuukan = /* GraphQL */ `
  query GetLeavesShuukan($id: ID!) {
    getLeavesShuukan(id: $id) {
      id
      yaritaikoto
      yuusendo
      episode
      leavesusersID
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listLeavesShuukans = /* GraphQL */ `
  query ListLeavesShuukans(
    $filter: ModelLeavesShuukanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesShuukans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        yaritaikoto
        yuusendo
        episode
        leavesusersID
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesShuukans = /* GraphQL */ `
  query SyncLeavesShuukans(
    $filter: ModelLeavesShuukanFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesShuukans(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        yaritaikoto
        yuusendo
        episode
        leavesusersID
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesMemo = /* GraphQL */ `
  query GetLeavesMemo($id: ID!) {
    getLeavesMemo(id: $id) {
      id
      title
      memo
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listLeavesMemos = /* GraphQL */ `
  query ListLeavesMemos(
    $filter: ModelLeavesMemoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesMemos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        memo
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesMemos = /* GraphQL */ `
  query SyncLeavesMemos(
    $filter: ModelLeavesMemoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesMemos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        memo
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesKazokuShinzoku = /* GraphQL */ `
  query GetLeavesKazokuShinzoku($id: ID!) {
    getLeavesKazokuShinzoku(id: $id) {
      id
      shimei
      zokugara
      seinengappi
      yuubinbangou
      juusho
      denwabangou
      mailaddress
      nyuuinjirenraku
      sougijirenraku
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listLeavesKazokuShinzokus = /* GraphQL */ `
  query ListLeavesKazokuShinzokus(
    $filter: ModelLeavesKazokuShinzokuFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesKazokuShinzokus(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shimei
        zokugara
        seinengappi
        yuubinbangou
        juusho
        denwabangou
        mailaddress
        nyuuinjirenraku
        sougijirenraku
        bikou
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesKazokuShinzokus = /* GraphQL */ `
  query SyncLeavesKazokuShinzokus(
    $filter: ModelLeavesKazokuShinzokuFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesKazokuShinzokus(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        shimei
        zokugara
        seinengappi
        yuubinbangou
        juusho
        denwabangou
        mailaddress
        nyuuinjirenraku
        sougijirenraku
        bikou
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesJibunshi = /* GraphQL */ `
  query GetLeavesJibunshi($id: ID!) {
    getLeavesJibunshi(id: $id) {
      id
      nengetsu
      eventmei
      episode
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listLeavesJibunshis = /* GraphQL */ `
  query ListLeavesJibunshis(
    $filter: ModelLeavesJibunshiFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesJibunshis(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nengetsu
        eventmei
        episode
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesJibunshis = /* GraphQL */ `
  query SyncLeavesJibunshis(
    $filter: ModelLeavesJibunshiFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesJibunshis(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        nengetsu
        eventmei
        episode
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesHint = /* GraphQL */ `
  query GetLeavesHint($id: ID!) {
    getLeavesHint(id: $id) {
      id
      url
      title
      image
      type
      leavesusersID
      publishdate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listLeavesHints = /* GraphQL */ `
  query ListLeavesHints(
    $filter: ModelLeavesHintFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesHints(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        url
        title
        image
        type
        leavesusersID
        publishdate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesHints = /* GraphQL */ `
  query SyncLeavesHints(
    $filter: ModelLeavesHintFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesHints(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        url
        title
        image
        type
        leavesusersID
        publishdate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesPet = /* GraphQL */ `
  query GetLeavesPet($id: ID!) {
    getLeavesPet(id: $id) {
      id
      namae
      shurui
      seinengappi
      seibetsu
      tourokubangou
      hokennoumu
      hokennkaisyamei
      kakaritsukenobyouinmei
      kakaritsukenobyouinnorenrakusaki
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listLeavesPets = /* GraphQL */ `
  query ListLeavesPets(
    $filter: ModelLeavesPetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesPets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        namae
        shurui
        seinengappi
        seibetsu
        tourokubangou
        hokennoumu
        hokennkaisyamei
        kakaritsukenobyouinmei
        kakaritsukenobyouinnorenrakusaki
        bikou
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesPets = /* GraphQL */ `
  query SyncLeavesPets(
    $filter: ModelLeavesPetFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesPets(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        namae
        shurui
        seinengappi
        seibetsu
        tourokubangou
        hokennoumu
        hokennkaisyamei
        kakaritsukenobyouinmei
        kakaritsukenobyouinnorenrakusaki
        bikou
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesIryousochi = /* GraphQL */ `
  query GetLeavesIryousochi($id: ID!) {
    getLeavesIryousochi(id: $id) {
      id
      kokuchikibounoumu
      enmeisochikibounoumu
      zoukiteikyounoumu
      handanwoyudanetaihito
      ishikiganakunattarashirasetehoshiiyuujinchijin
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listLeavesIryousochis = /* GraphQL */ `
  query ListLeavesIryousochis(
    $filter: ModelLeavesIryousochiFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesIryousochis(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        kokuchikibounoumu
        enmeisochikibounoumu
        zoukiteikyounoumu
        handanwoyudanetaihito
        ishikiganakunattarashirasetehoshiiyuujinchijin
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesIryousochis = /* GraphQL */ `
  query SyncLeavesIryousochis(
    $filter: ModelLeavesIryousochiFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesIryousochis(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        kokuchikibounoumu
        enmeisochikibounoumu
        zoukiteikyounoumu
        handanwoyudanetaihito
        ishikiganakunattarashirasetehoshiiyuujinchijin
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesCreditcard = /* GraphQL */ `
  query GetLeavesCreditcard($id: ID!) {
    getLeavesCreditcard(id: $id) {
      id
      creditcardmei
      cardbrand
      cardhakkougaisha
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listLeavesCreditcards = /* GraphQL */ `
  query ListLeavesCreditcards(
    $filter: ModelLeavesCreditcardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesCreditcards(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        creditcardmei
        cardbrand
        cardhakkougaisha
        bikou
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesCreditcards = /* GraphQL */ `
  query SyncLeavesCreditcards(
    $filter: ModelLeavesCreditcardFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesCreditcards(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        creditcardmei
        cardbrand
        cardhakkougaisha
        bikou
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesKouza = /* GraphQL */ `
  query GetLeavesKouza($id: ID!) {
    getLeavesKouza(id: $id) {
      id
      hikiotoshikoumoku
      hikiotoshikinyuukikan
      hikiotoshibi
      hikiotoshisakiKinyuuShisanYochokin
      hikiotoshisakiCreditcard
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listLeavesKouzas = /* GraphQL */ `
  query ListLeavesKouzas(
    $filter: ModelLeavesKouzaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesKouzas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        hikiotoshikoumoku
        hikiotoshikinyuukikan
        hikiotoshibi
        hikiotoshisakiKinyuuShisanYochokin
        hikiotoshisakiCreditcard
        bikou
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesKouzas = /* GraphQL */ `
  query SyncLeavesKouzas(
    $filter: ModelLeavesKouzaFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesKouzas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        hikiotoshikoumoku
        hikiotoshikinyuukikan
        hikiotoshibi
        hikiotoshisakiKinyuuShisanYochokin
        hikiotoshisakiCreditcard
        bikou
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesNenkin = /* GraphQL */ `
  query GetLeavesNenkin($id: ID!) {
    getLeavesNenkin(id: $id) {
      id
      nenkinmei
      nenkinshurui
      jyukyuugaku
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listLeavesNenkins = /* GraphQL */ `
  query ListLeavesNenkins(
    $filter: ModelLeavesNenkinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesNenkins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nenkinmei
        nenkinshurui
        jyukyuugaku
        bikou
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesNenkins = /* GraphQL */ `
  query SyncLeavesNenkins(
    $filter: ModelLeavesNenkinFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesNenkins(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        nenkinmei
        nenkinshurui
        jyukyuugaku
        bikou
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesKankonsousai = /* GraphQL */ `
  query GetLeavesKankonsousai($id: ID!) {
    getLeavesKankonsousai(id: $id) {
      id
      eventmei
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listLeavesKankonsousais = /* GraphQL */ `
  query ListLeavesKankonsousais(
    $filter: ModelLeavesKankonsousaiFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesKankonsousais(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventmei
        bikou
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesKankonsousais = /* GraphQL */ `
  query SyncLeavesKankonsousais(
    $filter: ModelLeavesKankonsousaiFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesKankonsousais(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        eventmei
        bikou
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesSumahoPc = /* GraphQL */ `
  query GetLeavesSumahoPc($id: ID!) {
    getLeavesSumahoPc(id: $id) {
      id
      kikishubetsu
      keiyakusaki
      denwabangou
      meiginin
      kishumei
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listLeavesSumahoPcs = /* GraphQL */ `
  query ListLeavesSumahoPcs(
    $filter: ModelLeavesSumahoPcFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesSumahoPcs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        kikishubetsu
        keiyakusaki
        denwabangou
        meiginin
        kishumei
        bikou
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesSumahoPcs = /* GraphQL */ `
  query SyncLeavesSumahoPcs(
    $filter: ModelLeavesSumahoPcFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesSumahoPcs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        kikishubetsu
        keiyakusaki
        denwabangou
        meiginin
        kishumei
        bikou
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesLoginId = /* GraphQL */ `
  query GetLeavesLoginId($id: ID!) {
    getLeavesLoginId(id: $id) {
      id
      servicemei
      siteurl
      loginid
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listLeavesLoginIds = /* GraphQL */ `
  query ListLeavesLoginIds(
    $filter: ModelLeavesLoginIdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesLoginIds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        servicemei
        siteurl
        loginid
        bikou
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesLoginIds = /* GraphQL */ `
  query SyncLeavesLoginIds(
    $filter: ModelLeavesLoginIdFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesLoginIds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        servicemei
        siteurl
        loginid
        bikou
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesYuujinChijin = /* GraphQL */ `
  query GetLeavesYuujinChijin($id: ID!) {
    getLeavesYuujinChijin(id: $id) {
      id
      shimei
      kankeisei
      seinengappi
      juusho
      denwabangou
      mailaddress
      nyuuinjinorenraku
      sougijinorenraku
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listLeavesYuujinChijins = /* GraphQL */ `
  query ListLeavesYuujinChijins(
    $filter: ModelLeavesYuujinChijinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesYuujinChijins(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shimei
        kankeisei
        seinengappi
        juusho
        denwabangou
        mailaddress
        nyuuinjinorenraku
        sougijinorenraku
        bikou
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesYuujinChijins = /* GraphQL */ `
  query SyncLeavesYuujinChijins(
    $filter: ModelLeavesYuujinChijinFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesYuujinChijins(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        shimei
        kankeisei
        seinengappi
        juusho
        denwabangou
        mailaddress
        nyuuinjinorenraku
        sougijinorenraku
        bikou
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesKenkoukanri = /* GraphQL */ `
  query GetLeavesKenkoukanri($id: ID!) {
    getLeavesKenkoukanri(id: $id) {
      id
      byoumei
      hukuyouchuunokusuri
      youto
      youhou
      byouinmei
      chiryoukikan
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listLeavesKenkoukanris = /* GraphQL */ `
  query ListLeavesKenkoukanris(
    $filter: ModelLeavesKenkoukanriFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesKenkoukanris(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        byoumei
        hukuyouchuunokusuri
        youto
        youhou
        byouinmei
        chiryoukikan
        bikou
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesKenkoukanris = /* GraphQL */ `
  query SyncLeavesKenkoukanris(
    $filter: ModelLeavesKenkoukanriFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesKenkoukanris(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        byoumei
        hukuyouchuunokusuri
        youto
        youhou
        byouinmei
        chiryoukikan
        bikou
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesSeikatsunokoto = /* GraphQL */ `
  query GetLeavesSeikatsunokoto($id: ID!) {
    getLeavesSeikatsunokoto(id: $id) {
      id
      seikatsunokotonitsuite
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listLeavesSeikatsunokotos = /* GraphQL */ `
  query ListLeavesSeikatsunokotos(
    $filter: ModelLeavesSeikatsunokotoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesSeikatsunokotos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        seikatsunokotonitsuite
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesSeikatsunokotos = /* GraphQL */ `
  query SyncLeavesSeikatsunokotos(
    $filter: ModelLeavesSeikatsunokotoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesSeikatsunokotos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        seikatsunokotonitsuite
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesTakaramono = /* GraphQL */ `
  query GetLeavesTakaramono($id: ID!) {
    getLeavesTakaramono(id: $id) {
      id
      collectionmei
      hokanbasyo
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listLeavesTakaramonos = /* GraphQL */ `
  query ListLeavesTakaramonos(
    $filter: ModelLeavesTakaramonoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesTakaramonos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        collectionmei
        hokanbasyo
        bikou
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesTakaramonos = /* GraphQL */ `
  query SyncLeavesTakaramonos(
    $filter: ModelLeavesTakaramonoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesTakaramonos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        collectionmei
        hokanbasyo
        bikou
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesEvents = /* GraphQL */ `
  query GetLeavesEvents($id: ID!) {
    getLeavesEvents(id: $id) {
      id
      code
      name
      startDate
      endDate
      date
      time
      closingDay
      address
      place
      mapUrl
      price
      confirmationNeeded
      applyEnabled
      imagePath
      eventPageUrl
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listLeavesEvents = /* GraphQL */ `
  query ListLeavesEvents(
    $filter: ModelLeavesEventsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        name
        startDate
        endDate
        date
        time
        closingDay
        address
        place
        mapUrl
        price
        confirmationNeeded
        applyEnabled
        imagePath
        eventPageUrl
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesEvents = /* GraphQL */ `
  query SyncLeavesEvents(
    $filter: ModelLeavesEventsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesEvents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        code
        name
        startDate
        endDate
        date
        time
        closingDay
        address
        place
        mapUrl
        price
        confirmationNeeded
        applyEnabled
        imagePath
        eventPageUrl
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesMyEvent = /* GraphQL */ `
  query GetLeavesMyEvent($id: ID!) {
    getLeavesMyEvent(id: $id) {
      id
      leavesusersID
      leavesEventsID
      confirmed
      applied
      note
      rate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listLeavesMyEvents = /* GraphQL */ `
  query ListLeavesMyEvents(
    $filter: ModelLeavesMyEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesMyEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        leavesusersID
        leavesEventsID
        confirmed
        applied
        note
        rate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesMyEvents = /* GraphQL */ `
  query SyncLeavesMyEvents(
    $filter: ModelLeavesMyEventFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesMyEvents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        leavesusersID
        leavesEventsID
        confirmed
        applied
        note
        rate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesHakanitsuite = /* GraphQL */ `
  query GetLeavesHakanitsuite($id: ID!) {
    getLeavesHakanitsuite(id: $id) {
      id
      hairuyoteinoohakanoumu
      kibousuruohakanokeishiki
      kibousurubasyo
      hiyoukyoshutsu
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listLeavesHakanitsuites = /* GraphQL */ `
  query ListLeavesHakanitsuites(
    $filter: ModelLeavesHakanitsuiteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesHakanitsuites(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        hairuyoteinoohakanoumu
        kibousuruohakanokeishiki
        kibousurubasyo
        hiyoukyoshutsu
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesHakanitsuites = /* GraphQL */ `
  query SyncLeavesHakanitsuites(
    $filter: ModelLeavesHakanitsuiteFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesHakanitsuites(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        hairuyoteinoohakanoumu
        kibousuruohakanokeishiki
        kibousurubasyo
        hiyoukyoshutsu
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesDaijinishitaikinenbi = /* GraphQL */ `
  query GetLeavesDaijinishitaikinenbi($id: ID!) {
    getLeavesDaijinishitaikinenbi(id: $id) {
      id
      kinenbimei
      hiduke
      konohigachikazuitaratsuuchi
      tsuuchisakiKazokuShinzoku
      tsuuchisakiYuujinChijin
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listLeavesDaijinishitaikinenbis = /* GraphQL */ `
  query ListLeavesDaijinishitaikinenbis(
    $filter: ModelLeavesDaijinishitaikinenbiFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesDaijinishitaikinenbis(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        kinenbimei
        hiduke
        konohigachikazuitaratsuuchi
        tsuuchisakiKazokuShinzoku
        tsuuchisakiYuujinChijin
        bikou
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesDaijinishitaikinenbis = /* GraphQL */ `
  query SyncLeavesDaijinishitaikinenbis(
    $filter: ModelLeavesDaijinishitaikinenbiFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesDaijinishitaikinenbis(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        kinenbimei
        hiduke
        konohigachikazuitaratsuuchi
        tsuuchisakiKazokuShinzoku
        tsuuchisakiYuujinChijin
        bikou
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesKakeizu = /* GraphQL */ `
  query GetLeavesKakeizu($id: ID!) {
    getLeavesKakeizu(id: $id) {
      id
      filemei
      hokanbasyo
      bikou
      s3key
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listLeavesKakeizus = /* GraphQL */ `
  query ListLeavesKakeizus(
    $filter: ModelLeavesKakeizuFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesKakeizus(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        filemei
        hokanbasyo
        bikou
        s3key
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesKakeizus = /* GraphQL */ `
  query SyncLeavesKakeizus(
    $filter: ModelLeavesKakeizuFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesKakeizus(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        filemei
        hokanbasyo
        bikou
        s3key
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesYuigonshonitsuite = /* GraphQL */ `
  query GetLeavesYuigonshonitsuite($id: ID!) {
    getLeavesYuigonshonitsuite(id: $id) {
      id
      yuigonshonoumu
      yuigonshonoshurui
      yuigonshonosaisyuusakuseibi
      yuigonshikkousha
      yuigonshikkoushanorenrakusaki
      iraisoudanwoshiteirusenmonka
      iraisoudanwoshiteirusenmonkanorenrakusaki
      sonotasouzokunikansurukibounado
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listLeavesYuigonshonitsuites = /* GraphQL */ `
  query ListLeavesYuigonshonitsuites(
    $filter: ModelLeavesYuigonshonitsuiteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesYuigonshonitsuites(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        yuigonshonoumu
        yuigonshonoshurui
        yuigonshonosaisyuusakuseibi
        yuigonshikkousha
        yuigonshikkoushanorenrakusaki
        iraisoudanwoshiteirusenmonka
        iraisoudanwoshiteirusenmonkanorenrakusaki
        sonotasouzokunikansurukibounado
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesYuigonshonitsuites = /* GraphQL */ `
  query SyncLeavesYuigonshonitsuites(
    $filter: ModelLeavesYuigonshonitsuiteFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesYuigonshonitsuites(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        yuigonshonoumu
        yuigonshonoshurui
        yuigonshonosaisyuusakuseibi
        yuigonshikkousha
        yuigonshikkoushanorenrakusaki
        iraisoudanwoshiteirusenmonka
        iraisoudanwoshiteirusenmonkanorenrakusaki
        sonotasouzokunikansurukibounado
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesTaisetsunishitaihitohenomessage = /* GraphQL */ `
  query GetLeavesTaisetsunishitaihitohenomessage($id: ID!) {
    getLeavesTaisetsunishitaihitohenomessage(id: $id) {
      id
      messagetitle
      soushinsaki
      soushinsakiYuujinChijin
      messagenaiyou
      soushinyoteibi
      isSentMessage
      soushinbi
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listLeavesTaisetsunishitaihitohenomessages = /* GraphQL */ `
  query ListLeavesTaisetsunishitaihitohenomessages(
    $filter: ModelLeavesTaisetsunishitaihitohenomessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesTaisetsunishitaihitohenomessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        messagetitle
        soushinsaki
        soushinsakiYuujinChijin
        messagenaiyou
        soushinyoteibi
        isSentMessage
        soushinbi
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesTaisetsunishitaihitohenomessages = /* GraphQL */ `
  query SyncLeavesTaisetsunishitaihitohenomessages(
    $filter: ModelLeavesTaisetsunishitaihitohenomessageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesTaisetsunishitaihitohenomessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        messagetitle
        soushinsaki
        soushinsakiYuujinChijin
        messagenaiyou
        soushinyoteibi
        isSentMessage
        soushinbi
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesLoankariirekin = /* GraphQL */ `
  query GetLeavesLoankariirekin($id: ID!) {
    getLeavesLoankariirekin(id: $id) {
      id
      kariiresaki
      kariiresakinorenrakusaki
      kariirebi
      kariiregaku
      hensaihouhou
      tanponoumu
      kariirezandaka
      kariirezandakakisaibi
      kariiremokuteki
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listLeavesLoankariirekins = /* GraphQL */ `
  query ListLeavesLoankariirekins(
    $filter: ModelLeavesLoankariirekinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesLoankariirekins(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        kariiresaki
        kariiresakinorenrakusaki
        kariirebi
        kariiregaku
        hensaihouhou
        tanponoumu
        kariirezandaka
        kariirezandakakisaibi
        kariiremokuteki
        bikou
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesLoankariirekins = /* GraphQL */ `
  query SyncLeavesLoankariirekins(
    $filter: ModelLeavesLoankariirekinFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesLoankariirekins(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        kariiresaki
        kariiresakinorenrakusaki
        kariirebi
        kariiregaku
        hensaihouhou
        tanponoumu
        kariirezandaka
        kariirezandakakisaibi
        kariiremokuteki
        bikou
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesHosyousaimu = /* GraphQL */ `
  query GetLeavesHosyousaimu($id: ID!) {
    getLeavesHosyousaimu(id: $id) {
      id
      syusaimusya
      syusaimusyanorenrakusaki
      hosyoushitahi
      hosyoushitakingaku
      saikensya
      saikensyanorenrakusaki
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listLeavesHosyousaimus = /* GraphQL */ `
  query ListLeavesHosyousaimus(
    $filter: ModelLeavesHosyousaimuFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesHosyousaimus(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        syusaimusya
        syusaimusyanorenrakusaki
        hosyoushitahi
        hosyoushitakingaku
        saikensya
        saikensyanorenrakusaki
        bikou
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesHosyousaimus = /* GraphQL */ `
  query SyncLeavesHosyousaimus(
    $filter: ModelLeavesHosyousaimuFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesHosyousaimus(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        syusaimusya
        syusaimusyanorenrakusaki
        hosyoushitahi
        hosyoushitakingaku
        saikensya
        saikensyanorenrakusaki
        bikou
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesShareInfo = /* GraphQL */ `
  query GetLeavesShareInfo($id: ID!) {
    getLeavesShareInfo(id: $id) {
      id
      name
      originDate
      target {
        dataName
        shareDate
        __typename
      }
      shareId
      shareIdExpiredDate
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listLeavesShareInfos = /* GraphQL */ `
  query ListLeavesShareInfos(
    $filter: ModelLeavesShareInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesShareInfos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        originDate
        shareId
        shareIdExpiredDate
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesShareInfos = /* GraphQL */ `
  query SyncLeavesShareInfos(
    $filter: ModelLeavesShareInfoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesShareInfos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        originDate
        shareId
        shareIdExpiredDate
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesSharedInfo = /* GraphQL */ `
  query GetLeavesSharedInfo($id: ID!) {
    getLeavesSharedInfo(id: $id) {
      id
      name
      shareInfoId
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listLeavesSharedInfos = /* GraphQL */ `
  query ListLeavesSharedInfos(
    $filter: ModelLeavesSharedInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesSharedInfos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        shareInfoId
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesSharedInfos = /* GraphQL */ `
  query SyncLeavesSharedInfos(
    $filter: ModelLeavesSharedInfoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesSharedInfos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        shareInfoId
        leavesusersID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesSummaryWriting = /* GraphQL */ `
  query GetLeavesSummaryWriting($id: ID!) {
    getLeavesSummaryWriting(id: $id) {
      id
      firstLoginAt
      lastLoginAt
      writingItems {
        itemModelName
        itemId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listLeavesSummaryWritings = /* GraphQL */ `
  query ListLeavesSummaryWritings(
    $filter: ModelLeavesSummaryWritingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesSummaryWritings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstLoginAt
        lastLoginAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesSummaryWritings = /* GraphQL */ `
  query SyncLeavesSummaryWritings(
    $filter: ModelLeavesSummaryWritingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesSummaryWritings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        firstLoginAt
        lastLoginAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesMindTypeAnswerId = /* GraphQL */ `
  query GetLeavesMindTypeAnswerId($id: ID!) {
    getLeavesMindTypeAnswerId(id: $id) {
      id
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listLeavesMindTypeAnswerIds = /* GraphQL */ `
  query ListLeavesMindTypeAnswerIds(
    $filter: ModelLeavesMindTypeAnswerIdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesMindTypeAnswerIds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesMindTypeAnswerIds = /* GraphQL */ `
  query SyncLeavesMindTypeAnswerIds(
    $filter: ModelLeavesMindTypeAnswerIdFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesMindTypeAnswerIds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeavesMindTypeAnswer = /* GraphQL */ `
  query GetLeavesMindTypeAnswer($id: ID!) {
    getLeavesMindTypeAnswer(id: $id) {
      answerId
      page
      questionNo
      answer
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listLeavesMindTypeAnswers = /* GraphQL */ `
  query ListLeavesMindTypeAnswers(
    $filter: ModelLeavesMindTypeAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeavesMindTypeAnswers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        answerId
        page
        questionNo
        answer
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeavesMindTypeAnswers = /* GraphQL */ `
  query SyncLeavesMindTypeAnswers(
    $filter: ModelLeavesMindTypeAnswerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeavesMindTypeAnswers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        answerId
        page
        questionNo
        answer
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
