import { Flex, Text } from "@aws-amplify/ui-react";
import { API, Auth, DataStore, Storage } from "aws-amplify";
import React, { Component, useEffect, useState, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import Hashids from "hashids";
const hashids = new Hashids("hiraql");

export default function () {
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(async () => {
    const key = searchParams.get("key");
    // var hex = Array.from(
    //   new TextEncoder().encode(
    //     "private/ap-northeast-1:ccbb1f14-9add-408f-b4fb-4825f98e6327/LeavesTaisetsunishitaihitohenomessage/6d5aa34a-e57c-4e73-8fbc-5fb5f7e71cd6/02IconImage_Taprica_outline.png"
    //   )
    // )
    //   .map((v) => v.toString(16))
    //   .join("");
    // var encoded = hashids.encodeHex(hex);
    // console.log("encoded", encoded);

    if (key != null) {
      const decoded = hashids.decodeHex(key);
      if (decoded) {
        console.log("decoded", decoded);
        const arr = new Uint8Array(decoded.match(/.{1,2}/g).map((v) => parseInt(v, 16)));
        const path = new TextDecoder().decode(arr);
        console.log("path", path);
        try {
          let params = {
            body: {
              path: path,
            },
            headers: {
              "Content-Type": "application/json",
            },
          };
          const response = await API.post("getAtachmentFilePath", "/", params);
          window.location.href = response.url;
          // console.log(response.url);
        } catch (e) {
          console.log(e);
        }
      }
    } else {
      window.location.href = "/";
    }
  }, []);

  return <div className="App"></div>;
}
