import { DataStore } from "aws-amplify";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LeavesHoken } from "../models";
import { FABDeleteEdit, LabelM, ListTwolineA } from "../page-components";
import { getLabel, useInitializePage } from "../utils/CommonUtils";
import { log } from "../utils/Logger";
import { isShare, queryData } from "../utils/ShareUtils";

/*
  hokenmei: String
  hokenshurui: Hokenshurui
  hokengaisha: String
  bikou: String
*/
export default function () {
  const navigate = useInitializePage("hoken", "title.detail");
  const [shisan, setShisan] = useState();

  let params = useParams();

  useEffect(async () => {
    const shisan = await queryData(params, LeavesHoken);
    log.debug("shisan", shisan);
    if (shisan.length > 0) {
      setShisan(shisan[0]);
    }
  }, []);

  const deleteData = async () => {
    log.debug("delete data", shisan);
    await DataStore.delete(shisan);
    navigate(-1, { replace: true });
  };

  return (
    <div>
      <LabelM label={shisan?.hokengaisha} />
      <ListTwolineA label={getLabel("hokenshurui")} value={shisan?.hokenshurui} />
      <ListTwolineA isEmptyHide label={getLabel("hokengaisha")} value={shisan?.hokengaisha} />
      <ListTwolineA isEmptyHide label={getLabel("hokenmei")} value={shisan?.hokenmei} />
      <ListTwolineA isEmptyHide label={getLabel("bikou")} value={shisan?.bikou} />
      {isShare(params) ? null : (
        <FABDeleteEdit
          onDelete={() => deleteData()}
          onEdit={() => {
            navigate("edit");
            window.gtag("event", "Change", {
              event_category: "Edit",
              event_label: "保険変更開始",
            });
          }}
        />
      )}
    </div>
  );
}
