/* eslint-disable import/no-anonymous-default-export */
import { DataStore } from "aws-amplify";
import { Fragment, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import { JitsubutsuShisanshurui, LeavesJitsubutsuShisan } from "../models";
import { LabelS, ListSelectContent, ListSingleLineA, ListSingleLineD } from "../page-components";
import { getLabel, useInitializePage } from "../utils/CommonUtils";
import { log } from "../utils/Logger";
import { isShare, queryDatas } from "../utils/ShareUtils";

export default function () {
  const navigate = useInitializePage("jitsubutsushisan");
  const [isLoaded, setIsLoaded] = useState(false);
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState(null);
  const [renderList, setRenderList] = useState();
  const firstItemAdded = useRef(false);
  let params = useParams();

  useEffect(() => {
    let mount = true;
    const getData = async () => {
      const assets = await queryDatas(params, LeavesJitsubutsuShisan);
      log.debug("assets", assets);
      if (!mount) return;
      if (assets.length > 0) {
        setList(assets);
      }
      setIsLoaded(true);
    };
    getData();

    return () => {
      mount = false;
    };
  }, []);

  useEffect(() => {
    if (list?.length > 0) {
      const sorted = Object.keys(JitsubutsuShisanshurui).map((type, index) => {
        const jsxList = [];
        const dsList = list.filter(function (item, index, array) {
          return item.shisanshurui === type;
        });
        if (dsList.length > 0) {
          jsxList.push(<LabelS key={type} label={getLabel("JitsubutsuShisanshurui." + type)} margin="0" />);
          dsList.forEach((asset, index) => {
            if (!firstItemAdded.current && asset) {
              setSelected(asset);
              firstItemAdded.current = true;
            }
            if (isMobile) {
              jsxList.push(<ListSingleLineA key={asset.id} label={asset.shisanmei} onClick={() => navigate(asset.id)} />);
            } else {
              jsxList.push(
                <ListSingleLineD
                  key={asset?.id}
                  label={asset?.shisanmei}
                  selected={selected?.id === asset?.id}
                  onClick={() => {
                    handledChangeActive(asset);
                  }}
                />
              );
            }
          });
        }
        return jsxList;
      });
      setRenderList(sorted);
    } else {
      setRenderList([]);
      setSelected(null);
    }
  }, [list, navigate, selected, selected?.id]);

  const deleteData = async (data) => {
    log.debug("delete data", data);
    firstItemAdded.current = false;
    await DataStore.delete(data);
    const newList = await DataStore.query(LeavesJitsubutsuShisan);
    setList(newList);
  };

  const handledChangeActive = (item) => {
    setSelected(item);
  };

  return (
    <ListSelectContent
      label={getLabel("jitsubutsushisan")}
      mobileList={
        renderList &&
        renderList.map((ele, index) => {
          return <Fragment key={index}>{ele}</Fragment>;
        })
      }
      description={{
        type: "data",
        category: "jitsubutsushisan",
      }}
      isShared={isShare(params)}
      addButtonLabel={
        getLabel("jitsubutsushisan") + getLabel("button.addsuffix")
      }
      onAddClick={() => {
        navigate("add");
        window.gtag("event", "FirstWrite", {
          event_category: "Edit",
          event_label: "実物資産書込開始",
        });
      }}
      pcListItem={
        renderList &&
        renderList.map((ele, index) => {
          return <Fragment key={index}>{ele}</Fragment>;
        })
      }
      selectedItemList={[
        {
          label: getLabel("shisanshurui"),
          value: getLabel("JitsubutsuShisanshurui." + selected?.shisanshurui),
        },
        {
          label: getLabel("shisanmei"),
          value: selected?.shisanmei,
        },
        {
          label: getLabel("shozaibasho"),
          value: selected?.shozaibasho,
        },
        {
          label: getLabel("bikou"),
          value: selected?.bikou,
        },
      ]}
      selectedItemLabel={selected?.shisanmei}
      onDelete={() => deleteData(selected)}
      onEdit={() => {
        navigate(selected?.id + "/edit");
        window.gtag("event", "Change", {
          event_category: "Edit",
          event_label: "実物資産変更開始",
        });
      }}
      isLoaded={isLoaded}
    />
  );
}
