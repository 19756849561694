/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Icon } from "@aws-amplify/ui-react";
export default function Stepper(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="16px"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="8px 8px 8px 8px"
      {...rest}
      {...getOverrideProps(overrides, "Stepper")}
    >
      <Icon
        width="12px"
        height="12px"
        viewBox={{ minX: 0, minY: 0, width: 12, height: 12 }}
        paths={[
          {
            d: "M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6Z",
            fill: "rgba(104,134,73,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        {...getOverrideProps(overrides, "circle1")}
      ></Icon>
      <Icon
        width="12px"
        height="12px"
        viewBox={{ minX: 0, minY: 0, width: 12, height: 12 }}
        paths={[
          {
            d: "M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6Z",
            fill: "rgba(239,240,240,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        {...getOverrideProps(overrides, "circle2")}
      ></Icon>
      <Icon
        width="12px"
        height="12px"
        viewBox={{ minX: 0, minY: 0, width: 12, height: 12 }}
        paths={[
          {
            d: "M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6Z",
            fill: "rgba(239,240,240,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        {...getOverrideProps(overrides, "circle3")}
      ></Icon>
      <Icon
        width="12px"
        height="12px"
        viewBox={{ minX: 0, minY: 0, width: 12, height: 12 }}
        paths={[
          {
            d: "M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6Z",
            fill: "rgba(239,240,240,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        {...getOverrideProps(overrides, "circle4")}
      ></Icon>
    </Flex>
  );
}
