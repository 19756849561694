import { DataStore } from "aws-amplify";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LeavesCreditcard } from "../models";
import {
  ListSelectContent,
  ListSingleLineA,
  ListSingleLineD,
} from "../page-components";
import { getLabel, useInitializePage } from "../utils/CommonUtils";
import { isShare, queryDatas } from "../utils/ShareUtils";

function CardPage() {
  const navigate = useInitializePage("creditcard");
  const [listCard, setListCard] = useState([]);
  const [selected, setSelected] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const params = useParams();

  const handledChangeActive = (item) => {
    setSelected(item);
  };

  useEffect(() => {
    if (listCard?.length > 0) {
      setSelected(listCard[0]);
    } else {
      setSelected(null);
    }
  }, [listCard]);

  const deleteData = useCallback(async (cardData) => {
    await DataStore.delete(cardData);
    const modelData = await DataStore.query(LeavesCreditcard);
    setListCard(modelData);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const modelData = await queryDatas(params, LeavesCreditcard);
      if (modelData?.length > 0) setListCard(modelData);
      setIsLoaded(true);
    };
    getData();
  }, []);

  return (
    <ListSelectContent
      label={getLabel("creditcard")}
      mobileList={listCard.map((ele) => {
        return (
          <ListSingleLineA
            key={ele?.id}
            label={ele?.creditcardmei}
            onClick={() => navigate(ele?.id)}
          />
        );
      })}
      description={{
        type: "data",
        category: "creditcard",
      }}
      isShared={isShare(params)}
      addButtonLabel={getLabel("creditcard") + getLabel("button.addsuffix")}
      onAddClick={() => {
        navigate("add");
        window.gtag("event", "FirstWrite", {
          event_category: "Edit",
          event_label: "クレジットカード書込開始",
        });
      }}
      pcListItem={listCard.map((ele, index) => {
        return (
          <ListSingleLineD
            key={ele?.id}
            label={ele?.creditcardmei}
            selected={selected?.id === ele?.id}
            onClick={() => {
              handledChangeActive(ele);
            }}
          />
        );
      })}
      selectedItemList={[
        {
          isEmptyHide: true,
          label: getLabel("creditcardmei"),
          value: selected?.creditcardmei,
        },
        {
          isEmptyHide: true,
          label: getLabel("cardbrand"),
          value: selected?.cardbrand,
        },
        {
          isEmptyHide: true,
          label: getLabel("cardhakkougaisha"),
          value: selected?.cardhakkougaisha,
        },
        {
          isEmptyHide: true,
          label: getLabel("bikou"),
          value: selected?.bikou,
        },
      ]}
      selectedItemLabel={selected?.creditcardmei}
      onDelete={() => deleteData(selected)}
      onEdit={() => {
        navigate(selected?.id + "/edit");
        window.gtag("event", "Change", {
          event_category: "Edit",
          event_label: "クレジットカード変更開始",
        });
      }}
      isLoaded={isLoaded}
    />
  );
}

export default CardPage;
