import { DataStore } from "aws-amplify";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LeavesHakanitsuite, LeavesUsers } from "../models";
import { ListTwolineA, ListView } from "../page-components";
import UserContext from "../UserContext";
import {
  getLabel,
  isEmptyHakani,
  useInitializePage,
} from "../utils/CommonUtils";
import { isShare, queryData } from "../utils/ShareUtils";

function TombViewPage() {
  const navigate = useInitializePage("hakanitsuite", "title.detail");
  const [data, setData] = useState({
    hairuyoteinoohakanoumu: "",
    kibousuruohakanokeishiki: "",
    kibousurubasyo: "",
    hiyoukyoshutsu: "",
    bikou: "",
  });

  const [isEmptyData, setIsEmptyData] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const params = useParams();
  const context = useContext(UserContext);

  const eventTracking = () => {
    if (isEmptyData) {
      window.gtag("event", "FirstWrite", {
        event_category: "Edit",
        event_label: "墓について書込開始",
      });
    } else {
      window.gtag("event", "Change", {
        event_category: "Edit",
        event_label: "墓について変更開始",
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const data = await queryData(params, LeavesHakanitsuite);
      if (data?.length > 0) {
        setData(data[0]);
        setIsEmptyData(isEmptyHakani(data[0]));
        if (!context.leavesUser.leavesUsersLeavesHakanitsuiteId) {
          DataStore.save(
            LeavesUsers.copyOf(context.leavesUser, (updated) => {
              updated.leavesUsersLeavesHakanitsuiteId = data[0].id;
            }),
          );
        }
      } else {
        const newData = await DataStore.save(
          new LeavesHakanitsuite({
            hairuyoteinoohakanoumu: "",
            kibousuruohakanokeishiki: "",
            kibousurubasyo: "",
            hiyoukyoshutsu: "",
            bikou: "",
          }),
        );
        DataStore.save(
          LeavesUsers.copyOf(context.leavesUser, (updated) => {
            updated.leavesUsersLeavesHakanitsuiteId = newData.id;
          }),
        );
      }
      setIsLoaded(true);
    };
    getData();
  }, [context.leavesUser]);

  const listData = [
    {
      label: getLabel("hairuyoteinoohakanoumu"),
      value: data?.hairuyoteinoohakanoumu,
    },
    {
      label: getLabel("kibousuruohakanokeishiki"),
      value: data?.kibousuruohakanokeishiki,
    },
    { label: getLabel("kibousurubasyo"), value: data?.kibousurubasyo },
    { label: getLabel("hiyoukyoshutsu"), value: data?.hiyoukyoshutsu },
    { label: getLabel("bikou"), value: data?.bikou },
  ];

  return (
    <ListView
      label={getLabel("hakanitsuite")}
      description={{
        type: "will",
        category: "hakanitsuite",
      }}
      isShared={isShare(params)}
      isLoaded={isLoaded}
      isEmptyCategory={isEmptyData}
      onEdit={() => {
        navigate("edit");
        eventTracking();
      }}
      listMobile={listData.map((ele, index) => {
        return <ListTwolineA key={index} label={ele.label} value={ele.value} />;
      })}
      listPC={listData}
      isHideButtonEdit={isShare(params)}
    />
  );
}

export default TombViewPage;
