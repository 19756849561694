import { LabelM, ListSingleLineA } from "../page-components";
import { useInitializePage, getLabel } from "../utils/CommonUtils";

export default function () {
  const navigate = useInitializePage("shisankanren");

  return (
    <div>
      <LabelM label={getLabel("shisankanren")} />
      <ListSingleLineA onClick={() => navigate("real")} label={getLabel("jitsubutsushisan")} />
      <ListSingleLineA onClick={() => navigate("financial")} label={getLabel("kinyuushisan")} />
      <ListSingleLineA onClick={() => navigate("insurance")} label={getLabel("hoken")} />
      <ListSingleLineA onClick={() => navigate("cards")} label={getLabel("creditcard")} />
      <ListSingleLineA onClick={() => navigate("claim")} label={getLabel("kouzajidouhikiotoshi")} />
      <ListSingleLineA onClick={() => navigate("pension")} label={getLabel("nenkin")} />
      <ListSingleLineA onClick={() => navigate("loan")} label={getLabel("loankariirekin")} />
    </div>
  );
}

