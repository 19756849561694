const MODEL_NAME = {
    JITSUBUTSUSHISAN :"LeavesJitsubutsuShisan",
    KINYUUSHISAN: "LeavesKinyuuShisan",
    HOKEN: "LeavesHoken",
    CREDITCARD: "LeavesCreditcard",
    KOUZA: "LeavesKouza",
    NENKIN: "LeavesNenkin",
    LOGINID: "LeavesLoginId",
    SUMAHOPC: "LeavesSumahoPc",
    KAZOKUSHINZOKU: "LeavesKazokuShinzoku",
    YUUJINCHIJIN:"LeavesYuujinChijin",
    KENKOUKANRI: "LeavesKenkoukanri",
    SEIKATSUNOKOTO: "LeavesSeikatsunokoto",
    DAIJINISHITAIKINENBI: "LeavesDaijinishitaikinenbi",
    PET: "LeavesPet",
    LIFEFUTURE: "LeavesLifeFuture",
    MESSAGE: "LeavesTaisetsunishitaihitohenomessage",
    KAIGO: "LeavesKaigo",
    IRYOUSOCHI: "LeavesIryousochi",
    YUIGONSHONITSUITE: "LeavesYuigonshonitsuite",
    SOUGI: "LeavesSougi",
    HAKANITSUITE: "LeavesHakanitsuite",
    JIBUNSHI: "LeavesJibunshi",
    TAKARAMONO: "LeavesTakaramono",
    KAKEIZU: "LeavesKakeizu",
    LOANKARIIREKIN:"LeavesLoankariirekin",
    HOSYOUSAIMU:"LeavesHosyousaimu",
    SHUUKAN:"LeavesShuukan",
}

const MODEL_TITLE = {
    JITSUBUTSUSHISAN :"jitsubutsushisan",
    KINYUUSHISAN: "kinyuushisan",
    HOKEN: "hoken",
    CREDITCARD: "creditcard",
    KOUZA: "kouzajidouhikiotoshi",
    NENKIN: "nenkin",
    LOGINID: "kakushuserviceid",
    SUMAHOPC: "sumahoyapcnitsuite",
    KAZOKUSHINZOKU: "kazokushinzokuichiran",
    YUUJINCHIJIN:"yuujinchijin",
    KENKOUKANRI: "kenkoukanrinitsuite",
    SEIKATSUNOKOTO: "seikatsunokotonitsuite",
    DAIJINISHITAIKINENBI: "daijinishitaikinenbi",
    PET: "petnitsuite",
    LIFEFUTURE: "yaritaikoto",
    MESSAGE: "taisetsunishitaihitohenomessage",
    KAIGO: "kaigonitsuite",
    IRYOUSOCHI: "iryousochinitsuite",
    YUIGONSHONITSUITE: "yuigonshonitsuite",
    SOUGI: "souginitsuite",
    HAKANITSUITE: "hakanitsuite",
    JIBUNSHI: "jibunshi",
    TAKARAMONO: "takaramonolistcollection",
    KAKEIZU: "kakeizu",
    LOANKARIIREKIN:"loankariirekin",
}

const MODEL_TITLE_SHARE = {
    JITSUBUTSUSHISAN :"jitsubutsushisan",
    KINYUUSHISAN: "kinyuushisan",
    HOKEN: "hoken",
    CREDITCARD: "creditcard",
    KOUZA: "kouzajidouhikiotoshi",
    NENKIN: "nenkin",
    LOGINID: "account",
    SUMAHOPC: "debaisu",
    KAZOKUSHINZOKU: "kazokushinzoku",
    YUUJINCHIJIN:"yuujinchijin",
    KENKOUKANRI: "kenkojoho",
    SEIKATSUNOKOTO: "seikatsunokoto",
    DAIJINISHITAIKINENBI: "kinenbi",
    PET: "pet",
    LIFEFUTURE: "yaritaikoto",
    MESSAGE: "message",
    KAIGO: "kaigo",
    IRYOUSOCHI: "iryosochi",
    YUIGONSHONITSUITE: "igonsho",
    SOUGI: "sogi",
    HAKANITSUITE: "haka",
    JIBUNSHI: "jibunshi",
    TAKARAMONO: "korekushon",
    KAKEIZU: "file",
    LOANKARIIREKIN:"loankariiresaki",
}

export { MODEL_NAME, MODEL_TITLE, MODEL_TITLE_SHARE }