/* eslint-disable import/no-anonymous-default-export */
import { Text } from "@aws-amplify/ui-react";
import { DataStore } from "aws-amplify";
import React, { useCallback, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { LeavesSougi } from "../models";
import {
  ButtonCancelSave, FriendsContactAddressField,
  SwapperContent,
  TextField,
  TextFieldMultiline
} from "../page-components";
import { getLabel, isEmptySougi, useInitializePage } from "../utils/CommonUtils";
import {
  HIYOUKYOSHUTSU,
  KIBOUSOUGIKEISHIKI,
  KIBOUSURUHUNIKI,
  SANKANINZUUKIBO,
  SOUGINOSYURUI,
  SOUGIYOUHI,
  useDatalist
} from "../utils/DatalistUtils";
import { log } from "../utils/Logger";
import { usePrompt } from "../utils/Prompt";

/*
  sougiyouhi: String
  kibousougikeishiki: String
  kibousuruhuniki: String
  hiyoukyoshutsu: String
  hokenkyousainado: String
  bikou: String
*/
export default function () {
  const navigate = useInitializePage("souginitsuite", "title.update");
  const [data, setData] = useState();

  const [hokenkyousainado, setHokenkyousainado] = useState("");
  const [souginiyondehoshiyuujinchijin, setSouginiyondehoshiyuujinchijin] =
    useState([]);
  const [bikou, setBikou] = useState("");
  const [junbishiteiruhiyou, setJunbishiteiruhiyou] = useState("");
  const [yondehoshiihitonituite, setYondehoshiihitonituite] = useState("");
  const [souginiyobukakazokuniitininsuruyuujinchijin, setSouginiyobukakazokuniitininsuruyuujinchijin] = useState("");
  const [itininsuruhitonituite, setItininsuruhitonituite] = useState("");
  const [souginoannaiwoshinakutemoyoihitonituite, setSouginoannaiwoshinakutemoyoihitonituite] = useState("");

  const [sougiyouhiError, setSougiyouhiError] = useState(null);
  const [kibousougikeishikiError, setKibousougikeishikiError] = useState(null);
  const [kibousuruhunikiError, setKibousuruhunikiError] = useState(null);
  const [hiyoukyoshutsuError, setHiyoukyoshutsuError] = useState(null);
  const [hokenkyousainadoError, setHokenkyousainadoError] = useState(null);
  const [bikouError, setBikouError] = useState(null);
  const [junbishiteiruhiyouError, setJunbishiteiruhiyouError] = useState(null);
  const [yondehoshiihitonituiteError, setYondehoshiihitonituiteError] = useState(null);
  const [itininsuruhitonituiteError, setItininsuruhitonituiteError] = useState(null);
  const [souginoannaiwoshinakutemoyoihitonituiteError, setSouginoannaiwoshinakutemoyoihitonituiteError] = useState(null);

  const [update, setUpdate] = useState(false);
  const [isEmptyData, setIsEmptyData] = useState(true);
  const [isUpdated, setIsUpdated] = useState(false);

  const [SougiyouhiDataList, setSougiyouhi, getSougiyouhi, hasErrorSougiyouhi] =
    useDatalist({
      list: SOUGIYOUHI,
      required: false,
      dataUpdateHook: setUpdate,
    });
  const [
    KibousougikeishikiDataList,
    setKibousougikeishiki,
    getKibousougikeishiki,
    hasErrorKibousougikeishiki,
  ] = useDatalist({
    list: KIBOUSOUGIKEISHIKI,
    required: false,
    dataUpdateHook: setUpdate,
  });
  const [
    KibousuruhunikiDataList,
    setKibousuruhuniki,
    getKibousuruhuniki,
    hasErrorKibousuruhuniki,
  ] = useDatalist({
    list: KIBOUSURUHUNIKI,
    required: false,
    dataUpdateHook: setUpdate,
  });
  const [
    HiyoukyoshutsuDataList,
    setHiyoukyoshutsu,
    getHiyoukyoshutsu,
    hasErrorHiyoukyoshutsu,
  ] = useDatalist({
    list: HIYOUKYOSHUTSU,
    required: false,
    dataUpdateHook: setUpdate,
  });
  const [SouginosyuruiDataList, setSouginosyurui, getSouginosyurui, hasErrorSouginosyurui] = useDatalist({
    list: SOUGINOSYURUI,
    required: false,
    dataUpdateHook: setUpdate,
  });
  const [SankaninzuukiboDataList, setSankaninzuukibo, getSankaninzuukibo, hasErrorSankaninzuukibo] = useDatalist({
    list: SANKANINZUUKIBO,
    required: false,
    dataUpdateHook: setUpdate,
  });

  usePrompt((location, action) => {
    if (action == "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  useEffect(async () => {
    const dsData = await DataStore.query(LeavesSougi);
    log.debug("sougi", dsData);
    if (dsData.length > 0) {
      setData(dsData[0]);
      setIsEmptyData(isEmptySougi(dsData[0]));
      setSougiyouhi(dsData[0].sougiyouhi);
      setKibousougikeishiki(dsData[0].kibousougikeishiki);
      setSouginosyurui(dsData[0].souginosyurui);
      setSankaninzuukibo(dsData[0].sankaninzuukibo);
      setKibousuruhuniki(dsData[0].kibousuruhuniki);
      setJunbishiteiruhiyou(dsData[0].junbishiteiruhiyou);
      setHiyoukyoshutsu(dsData[0].hiyoukyoshutsu);
      setHokenkyousainado(dsData[0].hokenkyousainado);
      setSouginiyondehoshiyuujinchijin(dsData[0].souginiyondehoshiyuujinchijin);
      setYondehoshiihitonituite(dsData[0].yondehoshiihitonituite);
      setSouginiyobukakazokuniitininsuruyuujinchijin(dsData[0].souginiyobukakazokuniitininsuruyuujinchijin);
      setItininsuruhitonituite(dsData[0].itininsuruhitonituite);
      setSouginoannaiwoshinakutemoyoihitonituite(dsData[0].souginoannaiwoshinakutemoyoihitonituite);
      setBikou(dsData[0].bikou);
    }
  }, []);

  const eventTracking = () => {
    if (isEmptyData) {
      window.gtag("event", "FirstWriteComplete", {
        event_category: "Edit",
        event_label: "葬儀について書込完了",
      });
    } else {
      window.gtag("event", "ChangeComplete", {
        event_category: "Edit",
        event_label: "葬儀について変更完了",
      });
    }
  };

  const updateData = async () => {
    log.debug(
      getSougiyouhi() +
        "," +
        getKibousougikeishiki() +
        "," +
        getKibousuruhuniki() +
        "," +
        getHiyoukyoshutsu() +
        "," +
        hokenkyousainado +
        "," +
        souginiyondehoshiyuujinchijin +
        "," +
        bikou,
    );
    let valid = true;
    if (hasErrorSougiyouhi()) {
      valid = false;
    }
    if (hasErrorKibousougikeishiki()) {
      valid = false;
    }
    if (hasErrorKibousuruhuniki()) {
      valid = false;
    }
    if (hasErrorHiyoukyoshutsu()) {
      valid = false;
    }
    if (hasErrorSouginosyurui()) {
      valid = false;
    }
    if (hasErrorSankaninzuukibo()) {
      valid = false;
    }
    if (valid) {
      setIsUpdated(true);
      DataStore.save(
        LeavesSougi.copyOf(data, (updated) => {
          updated.sougiyouhi = getSougiyouhi();
          updated.kibousougikeishiki = getKibousougikeishiki();
          updated.souginosyurui = getSouginosyurui();
          updated.sankaninzuukibo = getSankaninzuukibo();
          updated.kibousuruhuniki = getKibousuruhuniki();
          updated.junbishiteiruhiyou = junbishiteiruhiyou;
          updated.hiyoukyoshutsu = getHiyoukyoshutsu();
          updated.hokenkyousainado = hokenkyousainado;
          updated.souginiyondehoshiyuujinchijin = souginiyondehoshiyuujinchijin;
          updated.yondehoshiihitonituite = yondehoshiihitonituite;
          updated.souginiyobukakazokuniitininsuruyuujinchijin = souginiyobukakazokuniitininsuruyuujinchijin;
          updated.itininsuruhitonituite = itininsuruhitonituite;
          updated.souginoannaiwoshinakutemoyoihitonituite = souginoannaiwoshinakutemoyoihitonituite;
          updated.bikou = bikou;
        }),
      );
      eventTracking();
      navigate(-1, { replace: true });
    }
  };

  const onChangeData = useCallback((setState) => { // avoid unnecessary re-render
    return (event) => {
      setState(event.target.value);
      setUpdate(true);
    };
  }, []);

  const onChangeListFriend = useCallback((setState) => {
    return (list) => {
      setState(list);
      setUpdate(true);
    };
  }, []);


  return (
    <SwapperContent label={getLabel("souginitsuite")}>
      <SougiyouhiDataList label="sougiyouhi" />
      <KibousougikeishikiDataList label="kibousougikeishiki" />
      <SouginosyuruiDataList label="souginosyurui" />
      <SankaninzuukiboDataList label="sankaninzuukibo" />
      <KibousuruhunikiDataList label="kibousuruhuniki" />
      <TextField
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("junbishiteiruhiyou")}
        value={junbishiteiruhiyou}
        errorMessage={junbishiteiruhiyouError}
        placeHolder={getLabel("100manennado")}
        onChange={onChangeData(setJunbishiteiruhiyou)}
      />
      <Text
        className={isMobile ? "" : "maxwidth800"}
        padding={isMobile ? "7px 16px 0px 16px" : "0px 0px 16px 0px"}
        color="#707070"
        fontSize={isMobile ? "12px" : "14px"}
        whiteSpace="pre-wrap"
      >
        {getLabel("junbishiteiruhiyou.subtitle")}
      </Text>
      <HiyoukyoshutsuDataList label="hiyoukyoshutsu" />
      <TextField
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("hokenkyousainado")}
        value={hokenkyousainado}
        errorMessage={hokenkyousainadoError}
        placeHolder={getLabel("gojyokainado")}
        onChange={(event) => {
          setHokenkyousainado(event.target.value);
          setUpdate(true);
        }}
      />
      <FriendsContactAddressField
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("souginiyondehoshiyuujinchijin")}
        dialogLabel={getLabel("呼んでほしい友人・知人を選択")} /**TODO: */
        selected={souginiyondehoshiyuujinchijin}
        onComplete={(list) => {
          setSouginiyondehoshiyuujinchijin(list);
          setUpdate(true);
        }}
      />
      <TextFieldMultiline
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("yondehoshiihitonituite")}
        value={yondehoshiihitonituite}
        errorMessage={yondehoshiihitonituiteError}
        placeholder={getLabel("satosannikoewokaketehoshiinado")}
        onChange={onChangeData(setYondehoshiihitonituite)}
      />
      <FriendsContactAddressField
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("souginiyobukakazokuniitininsuruyuujinchijin")}
        dialogLabel={getLabel("一任する友人・知人を選択")} /**TODO: */
        selected={souginiyobukakazokuniitininsuruyuujinchijin}
        onComplete={onChangeListFriend(setSouginiyobukakazokuniitininsuruyuujinchijin)}
      />
      <TextFieldMultiline
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("itininsuruhitonituite")}
        value={itininsuruhitonituite}
        placeholder={getLabel("kaijoukiboniawasetekimetehoshiinado")}
        errorMessage={itininsuruhitonituiteError}
        onChange={onChangeData(setItininsuruhitonituite)}
      />
      <TextFieldMultiline
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("souginoannaiwoshinakutemoyoihitonituite")}
        value={souginoannaiwoshinakutemoyoihitonituite}
        placeholder={getLabel("doukyuuseinihakoewokakenaidehoshiinado")}
        errorMessage={souginoannaiwoshinakutemoyoihitonituiteError}
        onChange={onChangeData(setSouginoannaiwoshinakutemoyoihitonituite)}
      />
      <TextFieldMultiline
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("bikou")}
        value={bikou}
        errorMessage={bikouError}
        onChange={(event) => {
          setBikou(event.target.value);
          setUpdate(true);
        }}
      />
      <ButtonCancelSave
        cancelOnClick={() => navigate(-1, { replace: true })}
        saveOnClick={() => updateData()}
        isDisabled={isUpdated}
      />
    </SwapperContent>
  );
}
