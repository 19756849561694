export const vocabularies = {
  ja: {
    "Sign In": "ログイン",
    "Signing in": "ログイン中",
    "Sign Up": "アカウント登録",
    "Sign Out": "ログアウト",
    "Sign in to your account": "アカウントにログイン",
    "Username *": "メールアドレス *",
    "Password *": "パスワード *",
    password: "パスワード",
    Username: "メールアドレスを入力",
    Password: "パスワードを入力",
    Email: "メールを入力",
    "Email Address *": "メール *",
    "Phone Number *": "電話番号 *",
    "Your passwords must match": "パスワードが一致していません",
    "Enter your username": "メールアドレスを入力",
    "Enter your password": "パスワードを入力",
    "Enter your email": "メールアドレスを入力",
    "Enter your code": "認証コードを入力",
    "We Emailed You": "コードの検証",
    "Creating Account": "アカウント登録中",
    Confirming: "確認中",
    "Password did not conform with policy: Password must have uppercase characters": "パスワードポリシーを満たしていません：大文字英字が必要です",
    "Password did not conform with policy: Password must have numeric characters": "パスワードポリシーを満たしていません：数字が必要です",
    "Password did not conform with policy: Password must have lowercase characters": "パスワードポリシーを満たしていません：小文字英字が必要です",
    "Password did not conform with policy: Password must have symbol characters": "パスワードポリシーを満たしていません：記号が必要です",
    "Password did not conform with policy: Password not long enough": "パスワードは8文字以上にしてください",
    "Password does not conform to policy: Password must have uppercase characters": "パスワードポリシーを満たしていません：大文字英字が必要です",
    "Password does not conform to policy: Password must have numeric characters": "パスワードポリシーを満たしていません：数字が必要です",
    "Password does not conform to policy: Password must have lowercase characters": "パスワードポリシーを満たしていません：小文字英字が必要です",
    "Password does not conform to policy: Password must have symbol characters": "パスワードポリシーを満たしていません：記号が必要です",
    "Password does not conform to policy: Password not long enough": "パスワードは8文字以上にしてください",
    "2 validation errors detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6; Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[S]+.*[S]+$":
      "パスワードは8文字以上、大文字小文字を含む英数字を指定してください",
    "Password must have at least 8 characters": "８文字以上にしてください",
    "Password must have lower case letters": "英小文字が１文字以上必要です",
    "Password must have numbers": "数字が１文字以上必要です",
    "Password must have special characters": "記号が１文字以上必要です",
    "Password must have upper case letters": "英大文字が１文字以上必要です",
    "User does not exist.": "メールアドレスまたはパスワードが違います",
    "Incorrect username or password.": "メールアドレスまたはパスワードが違います",
    "User is not confirmed.": "ユーザーは検証されていません",
    "User already exists": "ユーザーは既に存在します",
    "Invalid verification code provided, please try again.": "指定された確認コードが無効です。もう一度お試しください",
    "Invalid code provided, please request a code again.": "指定された確認コードが無効です。もう一度お試しください",
    "Invalid password format": "パスワードのフォーマットが不正です",
    "Invalid phone number format": "不正な電話番号フォーマットです。 電話番号は次のフォーマットで入力してください: +12345678900",
    "An account with the given email already exists.": "そのメールアドレスは既に存在します",
    "Username cannot be empty": "メールアドレスは必須です",
    "Password attempts exceeded": "パスワード試行回数が超過しました",
    "Password cannot be empty": "パスワードは必須入力です",
    "Attempt limit exceeded, please try after some time.": "試行制限を超過しました。しばらくしてからもう一度お試しください",
    "Username/client id combination not found.": "ユーザーが存在しません",
    "CUSTOM_AUTH is not enabled for the client.": "パスワードは必須です",
    "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
      "パスワードは8文字以上、大文字小文字を含む英数字を指定してください",
    "1 validation error detected: Value null at 'attributeName' failed to satisfy constraint: Member must not be null":
      "メールアドレスを選択してください",
    "1 validation error detected: Value at 'username' failed to satisfy constraint: Member must have length less than or equal to 128":
      "メールアドレスは128文字以下で入力してください",
    "Confirm Password": "パスワードを入力（確認）",
    "No account?": "アカウントが未登録ですか？",
    "Forgot your password?": "パスワードをお忘れですか？",
    "Reset password": "パスワードをリセット",
    "User does not exist": "ユーザーが存在しません",
    "User already exists": "ユーザーは既に存在します",
    "Incorrect username or password.": "メールアドレスまたはパスワードが違います",
    "Invalid password format": "パスワードのフォーマットが不正です",
    "Create account": "アカウント登録",
    "Forgot Password": "パスワードを忘れた",
    "Change Password": "パスワードを変更",
    "New Password": "新しいパスワード",
    Email: "メールアドレス",
    man: "男性",
    woman: "女性",
    unselected: "選択しない",
    "Phone Number": "電話番号",
    "Send code": "コードを送信",
    "Confirm a Code": "コードを確認",
    "Confirm Sign In": "ログインの確認",
    "Confirm Sign up": "アカウント登録の確認",
    "Back to Sign In": "ログインに戻る",
    "Send Code": "コードを送信",
    Confirm: "確認",
    "Resend Code": "コードを再送",
    Submit: "送信",
    Skip: "スキップ",
    Verify: "検証",
    "Verify Contact": "連絡先を検証",
    Code: "認証コード",
    "New password": "新しいパスワード",
    "Confirmation Code": "確認コード",
    "Lost your code?": "コードがありませんか？",
    "Account recovery requires verified contact information": "Account recovery requires verified contact information",
    "Invalid phone number format": "不正な電話番号フォーマットです。 電話番号は次のフォーマットで入力してください: +12345678900",
    "Create Account": "アカウント登録",
    "Have an account?": "アカウントをお持ちですか？",
    "Sign in": "ログイン",
    "Create a new account": "新しいアカウントを登録（無料）",
    "Reset your password": "パスワードをリセット",
    "Email Address": "メール",
    "An account with the given email already exists.": "そのメールアドレスは既に存在します",
    "Username cannot be empty": "ユーザー名は必須です",
    "Password attempts exceeded": "パスワード試行回数が超過しました",
    "Sending email": "メールを送信しました",
    "Code description": "送信した認証コードを入力してください。",
    "Code description info": "※メールが届くまでに数分かかる可能性があります。",
    "Account recovery requires verified contact information":
      "メールアドレスの本人確認が出来ていません。メールアドレスを選択して検証を開始してください。",
    "JitsubutsuShisanshurui.FUDOUSAN": "不動産",
    "JitsubutsuShisanshurui.KIKINZOKU": "貴金属",
    "JitsubutsuShisanshurui.COLLECTION": "コレクション",
    "JitsubutsuShisanshurui.SONOTA": "その他",
    "Hokenshurui.SEIMEI": "生命保険",
    "Hokenshurui.IRYOU": "医療保険",
    "Hokenshurui.KOJIN_NENKIN": "個人年金保険",
    "Hokenshurui.KASAI": "火災保険",
    "Hokenshurui.JIDOUSHA": "自動車保険",
    "Hokenshurui.GAKUSHI": "学資保険",
    "Hokenshurui.SONOTA": "その他",
    "KinyuuShisanshurui.YOCHOKIN": "預貯金",
    "KinyuuShisanshurui.KABUSHKI": "株式",
    "KinyuuShisanshurui.SAIKEN": "債券",
    "KinyuuShisanshurui.TOSHI_SHINTAKU": "投資信託",
    "KinyuuShisanshurui.SHOHINKEN_KOGITTE": "商品券・小切手",
    "KinyuuShisanshurui.SONOTA": "その他",
    "KaigoJoukyou.ININKOUKEN": "任意後見契約",
    "KaigoJoukyou.ININ": "委任契約",
    "KaigoJoukyou.NASHI": "契約はない",
    "Renrakushurui.SURU": "する",
    "Renrakushurui.SHINAI": "しない",
    "Renrakushurui.DOCHIRADEMO": "どちらでも",
    "Hokennoumu.KANYUU": "加入",
    "Hokennoumu.MIKANYUU": "未加入",
    "Tasseijoukyoushurui.JYUNBICHUU": "準備中",
    "Tasseijoukyoushurui.TRYCHUU": "トライ中",
    "Tasseijoukyoushurui.TASSEI": "達成",
    "Datastore.LeavesJitsubutsuShisan": "実物資産",
    "Datastore.LeavesKinyuuShisan": "金融資産",
    "Datastore.LeavesHoken": "保険",
    "Datastore.LeavesCreditcard": "クレジットカード名",
    "Datastore.LeavesKouza": "定期支払い",
    "Datastore.LeavesNenkin": "年金",
    "Datastore.LeavesLoginId": "WEBサービスのログイン情報",
    "Datastore.LeavesSumahoPc": "スマホやPCについて",
    "Datastore.LeavesKazokuShinzoku": "家族・親族",
    "Datastore.LeavesYuujinChijin": "友人・知人",
    "Datastore.LeavesKenkoukanri": "健康管理について",
    "Datastore.LeavesSeikatsunokoto": "生活のことについて",
    "Datastore.LeavesDaijinishitaikinenbi": "大事にしたい日",
    "Datastore.LeavesPet": "ペットについて",
    "Datastore.LeavesLifeFuture": "やりたいこと",
    "Datastore.LeavesTaisetsunishitaihitohenomessage": "大切にしたい人へのメッセージ",
    "Datastore.LeavesKaigo": "介護について",
    "Datastore.LeavesIryousochi": "医療措置について",
    "Datastore.LeavesYuigonshonitsuite": "遺言書について",
    "Datastore.LeavesSougi": "葬儀について",
    "Datastore.LeavesHakanitsuite": "墓について",
    "Datastore.LeavesJibunshi": "自分史",
    "Datastore.LeavesTakaramono": "宝物リスト・コレクション",
    "Datastore.LeavesKakeizu": "家系図",
    "Datastore.LeavesLoankariirekin": "ローン・借入金",
    sonota: "その他",
    update: "変更",
    hissulistcheck: "必須リストをチェックする",
    shisankanren: "資産関連",
    shinkitsuika: "新規追加",
    henshu: "編集",
    shousai: "詳細",
    digitalkanren: "デジタル関連",
    renrakusakikanren: "連絡先関連",
    kinyuushisan: "金融資産",
    "kinyuushisan.en": "FINANCIAL ASSETS",
    "kinyuushisan.des":
      "預貯金や株式、貸しているお金などを記録しましょう。ネットバンクなど、口座の存在を忘れないよう、その口座の主な用途も書き残すと便利です。老後資金の管理や、相続準備の助けになります。",
    jitsubutsushisan: "実物資産",
    "jitsubutsushisan.en": "REAL ASSETS",
    "jitsubutsushisan.des": "不動産や美術品、貴金属、自動車などを記録し、資産を把握しましょう。老後資金の管理や、相続準備の助けになります。",
    hoken: "保険",
    "hoken.en": "INSURANCE",
    "hoken.des":
      "加入している保険を記録しましょう。生命保険、医療保険、個人年金保険、火災保険、自動車保険、学資保険など、主にどんな時に請求するか（入院、ガンなど）を書いておくと、もしもの時も家族がスムーズに手続きできます。",
    iryousochinitsuite: "医療措置について",
    pet: "ペット",
    petnitsuite: "ペットについて",
    "petnitsuite.en": "PETS",
    "petnitsuite.des": "ペットの基本情報やかかりつけ病院などを記録しましょう。もしもの時も、家族が安心してペットの面倒を見られるようになります。",
    kaigonitsuite: "介護について",
    souginitsuite: "葬儀について",
    kazokushinzoku: "家族・親族",
    "kazokushinzoku.en": "FAMILY CONTACTS",
    "kazokushinzoku.des":
      "家族や親族の連絡先をまとめておきましょう。家族の間で普段使っている呼び名（●●のおばさん）や具体的な間柄（●●おじさんの次男）も書いておくと、もしもの時、遺された家族がどこに連絡をすれば良いかがすぐに分かって安心です。",
    kazokushinzokuichiran: "家族・親族",
    jibunshi: "自分史",
    "jibunshi.en": "MY HISTORY",
    "jibunshi.des": "思い出に残っている光景はなんですか？生い立ちから振り返って、あなたのルーツを探ってみましょう。",
    yaritaikoto: "やりたいこと",
    "yaritaikoto.en": "BUCKET LIST",
    "yaritaikoto.des":
      "人生の最期、あれやっておけば良かったな...と思うのではなく、やりたいことをやり遂げられた、最高の人生だったって思いたいですよね。一度きりの人生、夢を叶えるために、遅すぎることはありません。\nこれからを心豊かに生きるために、体験したい、挑戦したい、実現したいことを「チャレンジ」に、コツコツ続けたいことは「習慣」に書き出してみましょう。",
    fujisanninoborunado: "富士山に登るなど",
    shisanmei: "資産名",
    shisanshurui: "資産種類",
    kinyuukikan: "金融機関",
    ginkousyoukengaisyanado: "銀行、証券会社名など",
    shozaibasho: "所在場所",
    hokenmei: "保険名",
    marumaruhokennado: "〇〇保険など",
    hokenshurui: "保険種類",
    hokengaisha: "保険会社",
    bikou: "備考",
    okiniiri: "お気に入り",
    episode: "エピソード",
    joukyounotokiosewaninattanado: "上京の時お世話になったなど",
    oyakaranopresentnonecktiewoshimetehatsusyukkinshitanado: "親からのプレゼントのネクタイを締めて初出勤したなど",
    prompt: "保存していないデータが残っています。\nページを移動しますか？",
    "prompt.updateemail": "メールアドレスの変更は完了していません。\n変更をキャンセルしますか？",
    senmonkanisoudan: "専門家に相談する",
    handanwoyudanetaihito: "判断能力がなくなった時に判断を委ねたい人",
    kiboukaigoshurui: "希望介護種類",
    kiboushisetsu: "希望施設",
    hiyoukyoshutsu: "費用拠出",
    zaisankanriwomakaseruhito: "財産管理を任せる人",
    zaisankanriwomakaseruhitonokeiyakujoukyou: "財産管理を任せる人の契約状況",
    sougiyouhi: "葬儀要否",
    kibousougikeishiki: "希望葬儀形式",
    kibousuruhuniki: "希望する雰囲気",
    hokenkyousainado: "保険・共済など",
    gojyokainado: "互助会など",
    jitsugennenreikigen: "達成年齢",
    65: "65",
    kokuchikibounoumu: "余命告知希望の有無",
    enmeisochikibounoumu: "延命措置希望の有無",
    zoukiteikyounoumu: "臓器提供希望の有無",
    namae: "名前",
    shurui: "種類",
    sibakennado: "柴犬など",
    seibetsu: "性別",
    tourokubangou: "登録番号",
    hokennoumu: "保険の有無",
    hokennkaisyamei: "保険会社名",
    kakaritsukenobyouinmei: "かかりつけの病院名",
    kakaritsukenobyouinnorenrakusaki: "かかりつけの病院の連絡先",
    sai: "歳",
    tasseijoukyou: "達成状況",
    tasseijoukyoushurui: "達成状況",
    tassei: "達成",
    mitassei: "未達成",
    category: "カテゴリー",
    nengetsu: "年月",
    eventmei: "イベント名",
    "eventmei.placeholder": "〇〇社入社など",
    shimei: "氏名",
    zokugara: "続柄",
    seinengappi: "生年月日（西暦）",
    yuubinbangou: "郵便番号",
    juusho: "住所",
    denwabangou: "電話番号",
    nyuuinjirenraku: "入院時の連絡",
    sougijirenraku: "葬儀時の連絡",
    "title.add": "（新規）",
    "title.update": "（編集）",
    "title.delete": "（削除）",
    "title.detail": "（詳細）",
    "button.add": "追加",
    "button.addsuffix": "を追加",
    "button.update": "編集",
    "button.delete": "削除",
    "button.save": "保存",
    "button.cancel": "キャンセル",
    "list.select": "未選択",
    "sonota.placeholder": "入力してください（必須）",
    home: "ホーム",
    memo: "私のデータ",
    heart: "私の意思",
    lifepast: "大切なもの",
    lifefuture: "やりたいコト",
    hint: "ヒント",
    event: "イベント",
    check: "チェック",
    servicepolicy: "HIRAQL利用規約",
    company: "運営会社",
    contact: "問い合わせ",
    eventBannerMessage: "イベントに参加して新しい扉を開こう",
    eventBannerButton: "イベントを探す",
    columBannerMessage: "HIRAQLで新しい考え方を手に入れよう",
    columBannerButton: "ヒントを探す",
    mousikomizuminoevent: "申し込み済みのイベント",
    kaisaichuu: "開催中！",
    kinjitsukaisai: "近日開催！",
    kaiki: "会期",
    kaijou: "会場",
    kaisaizikan: "開催時間",
    kyuukanbi: "休館日",
    nyuujouryou: "入場料",
    mapwomiru: "地図を見る",
    eventnonyuujoukakunin: "イベントの入場確認（スタッフにて操作）",
    eventnonyuujoukakuninzumi: "イベントの入場確認済み",
    other: "その他",
    logout: "ログアウト",
    accountjouhou: "アカウント情報",
    mailaddress: "メールアドレス",
    creditcardmei: "クレジットカード名",
    toukyucardnado: "東急カードなど",
    cardbrand: "カードブランド",
    visa: "VISA",
    okiniiricolumnashi: "お気に入りに登録されているコラムはありません。",
    moushikomizumieventnashi: "申し込み済みのイベントはありません。",
    cardhakkougaisha: "カード発行会社",
    "bikou.creditcard": "備考（クレジットカード番号や暗証番号などはメモしないでください）",
    "bikou.anniversary": "備考（通知メールに記載されます）",
    hikiotoshikoumoku: "引き落とし項目",
    hikiotoshikinyuukikan: "引き落とし先",
    hikiotoshibi: "引き落とし日",
    "bikou.kouzajidouhikiotoshi": "備考（口座番号や暗証番号などはメモしないでください）",
    nenkinmei: "年金名",
    nenkinshurui: "受給頻度",
    jyukyuugaku: "受給額（円）",
    kokuminnenkinnado: "国民年金、厚生年金、共済年金、iDeCoなど",
    shiharaitsukinado: "支払い月など",
    kinyuukikancardmeinado: "金融機関、カード名など",
    eventmei: "イベント名",
    meinichimei: "命日名",
    sofumeinichinado: "祖父命日など",
    meinichi: "命日",
    hakanitsuite: "墓について",
    hairuyoteinoohakanoumu: "入る予定のお墓の有無",
    kibousuruohakanokeishiki: "希望するお墓の形式",
    kibousurubasyo: "希望する場所",
    kokyounomiyazaki: "故郷の宮崎",
    kikishubetsu: "機器種別",
    keiyakusaki: "契約先",
    carrieryaprovidernado: "キャリアやプロバイダなど",
    denwabangou: "電話番号",
    meiginin: "名義人",
    kishumei: "機種名",
    iphone12nado: "iPhone12など",
    servicemei: "サービス名",
    facebooknado: "Facebookなど",
    siteurl: "サイトURL",
    id: "ID",
    kankeisei: "関係性",
    koukounodoukyuuseinado: "高校の同級生など",
    oseibonoyaritorinado: "お歳暮のやりとりなど",
    marumarusyanyuusyanado: "〇〇社入社など",
    nyuuinjinorenraku: "入院時の連絡",
    sougijinorenraku: "葬儀時の連絡",
    byoumei: "病名",
    tounyoubyounado: "糖尿病など",
    hukuyouchuunokusuri: "服用中の薬",
    youto: "用途",
    itamidomenado: "痛み止めなど",
    youhou: "用法",
    yoruikkainado: "夜１回など",
    byouinmei: "病院名",
    chiryoukikan: "治療期間",
    "2020nenkarachiryounado": "2020年から治療開始など",
    denwabangouyasiteurlnado: "電話番号やサイトURLなど",
    internetnado: "インターネットなど",
    seikatsunokotonitsuite: "生活のことについて",
    collectionmei: "コレクション名",
    hondananoakaifilenado: "本棚の赤いファイルなど",
    hokanbasyo: "保管場所",
    kittecollectionnado: "切手コレクションなど",
    creditcard: "クレジットカード",
    "creditcard.en": "CREDIT CARDS",
    "creditcard.des": "クレジットカードや電子マネーを記録しましょう。紛失時の連絡先を記入にしておくと、もしもの時に便利です。",
    kouzajidouhikiotoshi: "定期支払い",
    "kouzajidouhikiotoshi.en": "SUBSCRIPTION",
    "kouzajidouhikiotoshi.des":
      "インターネット料金など定期的に支払っているサービスを記録しましょう。どの口座から、毎月何日に引き落とされるかを書いておくと、もしもの時、家族がスムーズに手続きできます。",
    nenkin: "年金",
    "nenkin.en": "PENSIONS",
    "nenkin.des":
      "加入している公的年金（国民年金、厚生年金など）のほか、企業年金や個人年金についても書いておきましょう。申請や死亡時の連絡先も記入しておくと、もしもの時も家族がスムーズに手続きできます。",
    ikkaiatarinokingaku: "1回あたりの金額",
    nikagetuniikkainado: "2ヶ月に1回など",
    kazokugashiranaiepisode: "家族が知らないこの人とのエピソード・間柄など",
    kankonsousaimemo: "冠婚葬祭メモ",
    meinichimemo: "命日メモ",
    sumahoyapcnitsuite: "スマホやPCについて",
    "sumahoyapcnitsuite.en": "DEVICES",
    "sumahoyapcnitsuite.des":
      "契約しているスマホなどの情報を記録しましょう。デジタルデータの処理の仕方について書いておくと、もしもの時も家族がスムーズに手続きできます。",
    kakushuserviceid: "WEBサービスのログイン情報",
    account: "ログイン情報",
    "account.en": "ACCOUNTS",
    "account.des":
      "利用しているWEBサービスとそのログインIDを記録しましょう。ネットショッピングやよく利用するサイト、その登録メールアドレス、パスワードの再発行に必要な情報など書いておくと、もしもの時も安心です。",
    loginjouhou: "ログイン情報",
    "loginjouhou.en": "ACCOUNTS",
    "loginjouhou.des": "利用しているWEBサービスとそのログインIDを記録しましょう。もしもの時も家族がスムーズに手続きをとることができます。",
    loginid: "ログインID",
    yuujinchijin: "友人・知人",
    "yuujinchijin.en": "FRIENDS CONTACTS",
    "yuujinchijin.des":
      "友人や知人の連絡先をまとめておきましょう。今後、誰とどういうことをして過ごしていきたいか、考えるきっかけにもなります。どんな間柄なのか、分かりやすいように記録しておく（高校の友人、職場の先輩など）と、良いでしょう。",
    kinenbimei: "タイトル",
    kekkonkinenbinado: "結婚記念日など",
    kenkoukanrinitsuite: "健康管理について（常備薬・通院記録）",
    healthcare: "健康管理について",
    "healthcare.en": "HEALTH CARE",
    "healthcare.des":
      "治療中の病気や服用中の薬、アレルギーやかかりつけ医、過去にかかったことのある病気などを記録しましょう。認知力が低下してきた時や、急病で倒れてしまった時など、家族が介護士や医師と連携がとりやすくなります。",
    seikatsunokotonitsuite: "生活のことについて",
    takaramonolistcollection: "宝物リスト・コレクション",
    collections: "宝物リスト",
    "collections.en": "COLLECTIONS",
    "collections.des": "あなたが大切にしているものはなんですか？リストを作成しながら、託したい人を考え、話し始めるのも良いかもしれません。",
    kankonsousai: "冠婚葬祭",
    digitalkanren: "デジタル関連",
    renrakusakikanren: "連絡先関連",
    shinshinnokoto: "心身のこと",
    termsofservice: "HIRAQL利用規約",
    privacypolicy: "プライバシーポリシー",
    agree: "に同意する",
    kihonjouhou: "基本データ",
    mailaddresswohenkou: "メールアドレスを変更",
    "mailaddress update description":
      "メールアドレスを変更するためには、メールアドレスでの本人確認が必要です。新しいメールアドレス宛に「認証コード」をお送りします。",
    atarashiimailaddress: "新しいメールアドレス",
    atarashiimailaddresssainyuuryoku: "新しいメールアドレス（再入力）",
    ninshoukodowosoushin: "認証コードを送信",
    honninkakunin: "本人確認",
    "honninkakunin description": "メール本文に記載されている認証コードを入力してください。",
    ninshoucode: "認証コード",
    codewoninshou: "コードを認証",
    ninshoucodewosaisousuru: "認証コードを再送する",
    mailgatodokanaibaai: "メールが届かない場合",
    "mailgatodokanaibaai description":
      "下記をご確認ください。\n・入力されたメールアドレスはあっていますか\n・迷惑メール設定などによって、「@XXX」からのメールが拒否されていませんか\n・迷惑メールフォルダに振り分けられていませんか",
    passwordwohenkou: "パスワードを変更",
    genzainopassword: "現在のパスワード",
    atarashiipassword: "新しいパスワード",
    atarashiipasswordsainyuuryoku: "新しいパスワード（再入力）",
    passwordformat: "※大文字、小文字、数字、記号を混合。8文字以上",
    tsuika: "追加",
    yujinchijinwosentaku: "友人・知人を選択",
    kazokushinzokuwosentaku: "家族・親族を選択",
    renrakusakilistkarasentaku: "連絡先リストから選択",
    daijinishitaikinenbi: "大事にしたい日",
    "daijinishitaikinenbi.en": "ANNIVERSARY",
    "daijinishitaikinenbi.des":
      "結婚記念日や友人の誕生日など、大切な記念日を記録しましょう。冠婚葬祭のメモも残しておくと便利です。記念日を通して、あなたのこれまでを振り返ることができます。",
    daijinishitaiotsukiai: "大事にしたいお付き合い",
    souginiyondehoshiyuujinchijin: "葬儀に呼んでほしい友人・知人",
    "familiescontactaddressfield.description1":
      "メールアドレスが登録されている連絡先のみ表示されます。以下リストに送信したい相手の名前がない場合は、",
    "familiescontactaddressfield.description2": "から編集または新規追加してください。",
    renrakusakilist: "連絡先リスト",
    hiduke: "日付",
    renrakusaki: "連絡先",
    kakeizu: "家系図",
    "kakeizu.en": "FAMILY TREE",
    "kakeizu.des": "家系図をアップロードし、自分のルーツを探ってみましょう。家族にシェアして、家系図を見ながら思い出話をするのも良いかもしれません。",
    filemei: "ファイル名",
    yamadakenokakeizunado: "山田家の家系図など",
    tenpufile: "添付ファイル",
    chounannojitakunado: "長男の自宅など",
    filewosentaku: "ファイルを選択",
    filewosakujo: "ファイルを削除",
    file: "ファイル",
    yuigonshonitsuite: "遺言書について",
    yuigonshonoumu: "遺言書の有無",
    yuigonshonoshurui: "遺言書の種類",
    yuigonshonosaisyuusakuseibi: "遺言書の最終作成日",
    yuigonshikkousha: "遺言執行者",
    yuigonshikkoushanorenrakusaki: "遺言執行者の連絡先",
    iraisoudanwoshiteirusenmonka: "依頼・相談をしている専門家",
    iraisoudanwoshiteirusenmonkanorenrakusaki: "依頼・相談をしている専門家の連絡先",
    sonotasouzokunikansurukibounado: "その他相続に関する希望など",
    jimushoyatantoushameinado: "事務所や担当者名など",
    denwabangouyasiteurlnado: "電話番号やサイトURLなど",
    souzokunokibou: "誰に何を相続させたいかなどの考えや希望など",
    taisetsunishitaihitohenomessage: "大切にしたい人へのメッセージ",
    soushinnsakiwosentaku: "送信先を選択",
    messagetitle: "メッセージタイトル",
    message: "メッセージ",
    "message.en": "MESSAGE",
    "message.des": "大切な人にメッセージを書きましょう。どのような時間を共にしてきたかを思い返すことは、自分を見つめ直すきっかけにもなります。",
    soushinsaki: "送信先",
    messagenaiyou: "メッセージ内容",
    mailnotitleninarimasu: "メールのタイトルになります",
    mailnohonbunninarimasu: "メールの本文になります",
    ishikiganakunattarashirasetehoshiiyuujinchijin: "意識がなくなったら知らせてほしい友人・知人",
    minomawarinokoto: "お金の管理や食事、習い事についてなど、もしもの時に家族が知らないと困る身の回りのこと",
    "description.jibunshi": "自分史は、写真や画像とともに、10件まで登録できます。",
    "description.kakeizu": "家系図は3件まで登録できます",
    "description.taisetsunishitaihitohenomessage": "大切な人へのメッセージは、動画でも音声でも、合計3件まで登録できます。",
    "error.required": "必須項目です",
    "error.number": "数値を入力してください",
    "error.dateformat": "正しい日付を入力してください。",
    "error.postcode": "正しい郵便番号を入力してください。",
    "error.acknowledgement": "HIRAQL利用規約に同意が必要です。",
    "error.telno": "正しい電話番号を入力してください。",
    "error.email.AliasExistsException": "このメールアドレスはすでに登録されています。",
    "error.email.CodeMismatchException": "認証コードが正しくありません。",
    "error.email.LimitExceededException": "試行回数の上限に達しました。もう一度最初からお試しください。",
    "error.email.ExpiredCodeException": "指定された確認コードが無効です。もう一度お試しください。",
    "error.password.NotAuthorizedException": "現在のパスワードが違います。",
    "error.emailformat": "メールアドレスの形式が正しくありません。",
    "error.passwordformat": "パスワードの形式が正しくありません。",
    "error.differentemail": "メールアドレスが一致していません",
    "error.differentpassword": "パスワードが一致していません",
    "error.samepassword": "現在と同じパスワードは利用できません",
    "error.filesize": "ファイルサイズが大きすぎます",
    "error.fileextentions": "このファイルはアップロードできません",
    "error.onlyImage": "このファイルをアップロードできません",
    "error.regist": "登録エラー",
    "error.share.other": "他のアカウントで登録済みの招待です。",
    "error.share.expired": "招待の有効期限が切れています。",
    "error.share.invalid": "招待が削除されているか招待URLが正しくありません。",
    "dialog.delete.title": "本当に削除しますか？",
    "dialog.delete.body": "この操作はもとに戻せません。",
    "dialog.deleteaccount.body":
      "アカウントを削除すると登録したデータは全て削除されます。\n※データの復元はできません。\n※HIRAQL利用規約第8条『退会手続き』に該当します。",
    "dialog.resend.title": "認証コードを送信しました",
    "dialog.resend.body": "に認証コードを記載したメールを再送信しました。",
    "dialog.cancel": "キャンセル",
    "dialog.delete": "データを削除",
    "dialog.deleteaccount": "アカウントを削除",
    "dialog.close": "閉じる",
    "dialog.back": "戻る",
    "dialog.done": "完了",
    "Datalist.TOKUNIKIBOUHANAI": "特に希望はない",
    "Datalist.SONOTA": "その他",
    "Datalist.SHISETSUDE": "施設で",
    "Datalist.NENKINKARA": "年金から",
    "Datalist.JITAKUDEHELPERNI": "自宅でヘルパーに",
    "Datalist.MINKANSHISETSU": "民間施設",
    "Datalist.HOKENKARA": "保険から",
    "Datalist.JITAKUDEKAZOKUNI": "自宅で家族に",
    "Datalist.KOUTEKISHISETSU": "公的施設",
    "Datalist.CHOKINKARA": "貯金から",
    "Datalist.SHINAKUTEYOI": "しなくてよい",
    "Datalist.CHRISTKYOUSHIKI": "キリスト教式",
    "Datalist.FUTSUUNI": "普通に",
    "Datalist.SHITEHOSHII": "してほしい",
    "Datalist.FUTSUSHIKI": "仏式",
    "Datalist.SEIDAINI": "盛大に",
    "Datalist.OMAKASE": "おまかせ",
    "Datalist.SHINTOUSHIKI": "神道式",
    "Datalist.KOJINMARITO": "こじんまりと",
    "Datalist.KABUSHIKI": "株式",
    "Datalist.SAIKEN": "債権",
    "Datalist.TOUSHISHINTAKU": "投資信託",
    "Datalist.YOCHOKIN": "預貯金",
    "Datalist.SHOUHINKENKOGITTE": "商品券・小切手",
    "Datalist.KIKINZOKU": "貴金属",
    "Datalist.COLLECTION": "コレクション",
    "Datalist.FUDOUSAN": "不動産",
    "Datalist.IRYOUHOKEN": "医療保険",
    "Datalist.KOJINNENKINHOKEN": "個人年金保険",
    "Datalist.KASAIHOKEN": "火災保険",
    "Datalist.SEIMEIHOKEN": "生命保険",
    "Datalist.JIDOUSHAHOKEN": "自動車保険",
    "Datalist.KAIGOHOKEN": "介護保険",
    "Datalist.GAKUSHIHOKEN": "学資保険",
    "Datalist.KOUTEKINENKIN": "公的年金",
    "Datalist.SHITEKINENKIN": "私的年金",
    "Datalist.KOTEIINTERNET": "固定インターネット",
    "Datalist.TABLET": "タブレット",
    "Datalist.PASOKON": "パソコン",
    "Datalist.SMARTPHONE": "スマートフォン",
    "Datalist.DOCHIRADEMOYOI": "どちらでもよい",
    "Datalist.RENRAKUSHINAI": "連絡しない",
    "Datalist.RENRAKUSURU": "連絡する",
    "Datalist.ARU": "ある",
    "Datalist.NAI": "ない",
    "Datalist.DAIDAINOOHAKA": "代々のお墓",
    "Datalist.KOUNYUZUMINOKAZOKUNOOHAKA": "購入済みの家族用のお墓",
    "Datalist.SHINKIKOUNYUUKIBOU": "新規購入希望",
    "Datalist.EITAIKUYOUKIBOU": "永代供養希望",
    "Datalist.NOUKOTSUDOUKIBOU": "納骨堂希望",
    "Datalist.JYUMOKUSOUKIBOU": "樹木葬希望",
    "Datalist.SANKOTSUKIBOU": "散骨希望",
    "Datalist.KIBOUNASHI": "希望なし",
    "Datalist.SAKUSEISITEIRU": "作成している",
    "Datalist.SAKUSEISHITEINAI": "作成していない",
    "Datalist.JIHITSUSHOUSHOYUIGON": "自筆証書遺言",
    "Datalist.KOUSEISHOUSHOYUIGON": "公正証書遺言",
    "Datalist.HIMITSUSHOUSHOYUIGON": "秘密証書遺言",
    souginosyurui: "葬儀の種類",
    "Datalist.IPPANSOU": "一般葬",
    "Datalist.ICHINICHISOU": "一日葬",
    "Datalist.KAZOKUSOU": "家族葬",
    "Datalist.KASOUSHIKI": "火葬式（直葬）",
    sankaninzuukibo: "参加人数規模",
    "Datalist.JYUUMEIIKA": "10名以下",
    "Datalist.SANJYUUMEIIKA": "10名〜30名",
    "Datalist.GOJUUMEIIKA": "30名〜50名",
    "Datalist.HYAKUMEIIKA": "50名〜100名",
    "Datalist.NIHYAKUMEIIKA": "100名〜200名",
    "Datalist.SANBYAKUMEIIKA": "200名〜300名",
    "Datalist.SANBYAKUMEIIJOU": "300名以上",
    junbishiteiruhiyou: "準備している費用",
    yondehoshiihitonituite: "呼んで欲しい人について",
    souginiyobukakazokuniitininsuruyuujinchijin: "葬儀に呼ぶか家族に一任する友人・知人",
    itininsuruhitonituite: "一任する人について",
    souginoannaiwoshinakutemoyoihitonituite: "葬儀の案内をしなくてもよい人について",
    "100manennado": "100万円など",
    satosannikoewokaketehoshiinado:
      "自分の家族と従兄弟の加藤家のみで葬儀をしてほしい、佐藤さんから高校の同級生に連絡してくれるから最初に佐藤さんに声をかけてほしいなど",
    "Datalist.TOUJITU": "当日",
    "Datalist.ZENJITU": "前日",
    "Datalist.ISSYUUKANMAE": "１週間前",
    "Datalist.HINITISHITEI": "日にち指定",
    "Datalist.NANNICHIMAEKASHITEI": "何日前か指定",
    soushinbi: "送信日",
    "Datalist.JIBUNNOSHIGAKAKUNINSARETATIMING": "自分の死が確認されたタイミング",
    konohigachikazuitaratsuuchi: "この日が近づいたらメールで通知",
    "konohigachikazuitaratsuuchi.subtitle": "※毎年通知されます",
    "nannichimaekashitei.placeholder": "数字を入力してください",
    kasaburankawoyouisurukotonado: "ウエディングブーケと同じカサブランカを用意することなど",
    tsuuchisaki: "通知先",
    kaijoukiboniawasetekimetehoshiinado: "職場の人は、会場規模に合わせて呼ぶ呼ばないを決めてほしいなど",
    doukyuuseinihakoewokakenaidehoshiinado: "小学校では良い思い出がないので、同級生には声をかけないでほしいなど",
    "yuigonshonoumu.en": "TESTAMENT",
    "yuigonshonoumu.des":
      "まだそんな歳じゃない、財産なんてない、それでも、遺言書は書いておくのが良いと言われます。相続の手続きは煩雑で、トラブルも多く、遺言書があれば、あなたがいなくなって悲しみにくれる家族や大切な人の心労を少しでも軽くすることができます。遺言書があるかどうかを記録しておくだけでも、遺された人がないものを探す負担が省けます。",
    "kaigonitsuite.en": "CARE",
    "kaigonitsuite.des":
      "自分の思うように生活できなくなった時、あなたはどんな暮らしをしてどんなサービスを受けたいですか？元気なうちに、見学を始めるのも良いかもしれません。",
    "souginitsuite.en": "FUNERAL",
    "souginitsuite.des":
      "どのようなお別れの場を用意したいですか？あなたの考えを伝えることで、家族の負担が軽くなります。遺される人たちに、自分の意思を伝えましょう。",
    "hakanitsuite.en": "TOMB",
    "hakanitsuite.des": "あなたのお墓について考えや希望を記入しておきましょう。あなたの考えを伝えることで、家族の負担が軽くなります。",
    "iryousochinitsuite.en": "MEDICAL MEASURES",
    "iryousochinitsuite.des":
      "もしあなたが重病になった時、あなたに病名・余命を告知するかを家族が判断しなくてはならないことがあります。あなたが意識のない状態で、延命措置を行うかを家族が決めなくてはならないこともあります。大変難しい判断ですし、家族だけに委ねるのは大きな負担になります。あなたの考えを伝えることで、家族の負担を少しでも軽くできます。",
    "seikatsunokotonitsuite.en": "MY LIFE",
    "seikatsunokotonitsuite.des":
      "手元に置いておきたい大切なもの、好きな食べ物、苦手なもの、服装や音楽、本、テレビ番組などの趣味・嗜好など、身のまわりのことを記入しましょう。あなたが病気やケガ、認知症などでコミュニケーション能力が低下したとき、あなたの希望が分かると家族もあなたも負担が減ります。",
    notset: "未設定",
    "shared.des": "この項目については、まだノートを書かれていないようです。",
    shareName: "共有する大切な人の名前",
    shareDate: "ノート公開日",
    "shareDate.suffix": "公開日",
    shareTarget: "共有するページ",
    shareStatus: "ステータス",
    inviteURL: "招待URL",
    copy: "コピー",
    share: "共有",
    shotaishoninzumi: "招待承認済み",
    shotaichu: "招待中",
    kyoyusurujohoosettei: "共有する情報を設定",
    shotaishanomeruadoresu: "招待者のメールアドレス",
    shotaisaretahi: "招待された日",
    notononamaeotoroku: "ノートの名前を登録",
    notononamaeohenko: "ノートの名前を変更",
    "dialogNote.des": "共有されたノートに名前をつけてください。「共有されたノート一覧」にその名前が表示されます。",
    "noteName.error": "名前を入力してください。",
    "noteName.placeholder": "大切な人の名前やニックネームなど",
    notononamae: "ノートの名前",
    kyoyusaretanotoichiran: "共有されたノート一覧",
    invited: "共有されたノート一覧",
    "invited.des": "共有されているノートはありません。",
    notonokihonjohokakuninhenko: "ノートの基本情報確認・変更",
    notonokyoyu: "ノートの共有",
    urlkaratorokushitekudasai: "URLから登録してください。",
    kazokuninotookyoyu: "大切な人にノートを共有",
    invite: "大切な人にノートを共有",
    "invite.des":
      "家族やパートナー、友人など大切な人を招待して、ノートを共有しましょう。ノート共有までの流れは以下の通りです\n１. 「大切な人を招待」をタップし、名前、ノートの公開日、共有するページを設定\n２. 大切な人に招待URLを共有\n３.招待された大切な人は、招待URLを開いてHIRAQL NOTEのアカウントを作成またはログイン\n４.メニュー「共有されたノート一覧」から、あなたが共有したページを公開日以降、確認できるようになります",
    copied: "コピーしました。",
    copyFail: "コピーに失敗しました。\nもう一度お試しください。",
    "invitePreview.des1":
      "ノートを共有する大切な人に、以下の招待URLを共有してください。招待URLは、招待した大切な人一覧の詳細ページで後からコピー・共有することもできます。",
    "invitePreview.des2":
      "ノートを共有する大切な人に、以下の招待URLを共有してください。\n招待URLは、招待した大切な人一覧の詳細ページで後からコピー・共有することもできます。",
    shotaioshuryosuru: "招待を終了する",
    shotaiotsudzukeru: "招待を続ける",
    shotaiurlokyoyu: "招待URLを共有",
    shotaishitakazokuichiran: "招待した大切な人一覧",
    kazokuoshotaisuru: "大切な人を招待",
    subetesentakusuru: "すべて選択する",
    subetesentaku: "すべて選択",
    "junbishiteiruhiyou.subtitle":
      "【費用の目安】\n・一般葬（数十名〜数百名）：百数十万円程度〜\n・一日葬、家族葬（十名前後〜）：百万円前後〜\n・火葬式：数十万円\n ※病院やご自宅の間の移動、祭壇やお食事、ご返礼品ほか、ご参列の人数規模によって大きく異なります",
    renrakusenrisutoosentaku: "連絡先リストを選択",
    "renrakusenrisutoosentaku.des": "参照する連絡先リストを選択してください。",
    hikuma: "日前",
    "junbishiteiruhiyou.subtitle":
      "【費用の目安】\n・一般葬（数十名〜数百名）：百数十万円程度〜\n・一日葬、家族葬（十名前後〜）：百万円前後〜\n・火葬式：数十万円\n ※病院やご自宅の間の移動、祭壇やお食事、ご返礼品ほか、ご参列の人数規模によって大きく異なります",
    loankariirekin: "ローン・借入金",
    loankariiresaki: "ローン・借入先",
    "loankariirekin.en": "LOAN",
    "loankariirekin.des":
      "負債も相続の対象になります。家族が、あなたの借金や、知人の借金の保証人になっていることを知らないと、思わぬトラブルの可能性もありますので、記入しておくと安心です。",
    kariiresaki: "借入先",
    kariiresakinorenrakusaki: "借入先の連絡先",
    kariirebi: "借入日",
    kariiregaku: "借入額（円）",
    hensaihouhou: "返済方法",
    tanponoumu: "担保の有無",
    kariirezandaka: "借入残高（円）",
    kariirezandakakisaibi: "借入残高記載日",
    kariiremokuteki: "借入目的",
    kinyuukikanmeinado: "金融機関名など",
    hutsuuyokinnado: "〇〇銀行普通預金など",
    jyuutakuloannado: "住宅ローンなど",
    hosyousaimu: "保証債務",
    syusaimusya: "主債務者",
    syusaimusyanorenrakusaki: "主債務者の連絡先",
    hosyoushitahi: "保証した日",
    hosyoushitakingaku: "保証した金額（円）",
    saikensya: "債権者",
    saikensyanorenrakusaki: "債権者の連絡先",
    torokunaiyo: "登録内容",
    anatagahosyousitahitononamae: "あなたが保証した人の名前",
    okanewokashitahitononamaeyakikanmei: "お金を貸した人の名前や機関名",
    "aru.placeholder": "保証人や不動産など（必須）",
    notonotenpufairuichiran: "ノートの添付ファイル一覧",
    taisetsunahitohenomesseji: "大切な人へのメッセージ",
    igonsho: "遺言書",
    renrakusengatorokusareteimasen: "連絡先が登録されていません",
    "contact.detail":
      "メッセージの送信には、連絡先を作成の上、メールアドレスの登録が必要です。「登録をする」を選択すると、連絡先リストに移動します。入力中のメッセージは自動保存されます。",
    atodesuru: "後でする",
    torokuosuru: "登録をする",
    "list.mysefl": "自分",
    nonsetting: "未設定",
    nyuuryokumiss: "入力ミスにご注意ください",
    createdAt: "登録日",
    updatedAt: "更新日",
    _shisanshurui: "資産種類",
    hikiotoshisakiKinyuuShisanYochokin: "引き落とし先金融資産預貯金",
    hikiotoshisakiCreditcard: "引き落とし先クレジットカード",
    tsuuchisakiKazokuShinzoku: "通知先家族親族",
    tsuuchisakiYuujinChijin: "通知先友人知人",
    soushinsakiYuujinChijin: "送信先友人知人",
    soushinyoteibi: "送信予定日",
    isSentMessage: "メッセージが送信されました",
    ekuserukeishiki: "エクセル形式",
    puranningukomokurawdeta: "プランニング項目rawデータ",
    torokuyuza: "登録ユーザ",
    sanshosurukomokuosentakushitekudasai: "参照する項目を選択してください。",
    hikiotoshisakiosentaku: "引き落とし先を選択",
    nyuryokusuru: "入力する",
    torokuzuminojohokaraerabu: "登録済みの情報から選ぶ",
    "yochokinkinyushisan": "預貯金 (金融資産)",
    "kinyushisanCard": "預貯金 (金融資産)・クレジットカードから選択",
    leavesusersID: "ユーザーID",
    "active.sharesuffix": "ページの共有設定",
    nitsuite: "について",
    "button.sharesuffix": "ページを共有",
    kyoyusuru: "共有する",
    kyoyushinai: "共有しない",
    shotaisuru: "招待する",
    "share.dialogConfirm": "「メニュー」>「大切な人にノートを共有」の追加ページに移動して、大切な人を招待しますか？",
    "share.dialogTitle": "ここを離れて招待しますか？",
    "share.categoryDes": "家族やパートナー、友人など大切な人を招待して、このページを共有しましょう。",
    "share.disableLabel": "共有設定していない大切な人一覧",
    "share.enableLabelPC": "共有する大切な人一覧",
    "share.enableLabel": "このページを共有する大切な人一覧",
    kyoyusurutaisetsunahitooatarashikutsuika: "共有する大切な人を新しく追加",
    debaisu: "デバイス",
    kenkoukanri: "健康管理",
    seikatsunokoto: "生活のこと",
    kinenbi: "記念日",
    kaigo: "介護",
    iryosochi: "医療措置",
    sogi: "葬儀",
    haka: "墓",
    korekushon: "コレクション",
    kenkojoho: "健康情報",
    "shareEnable.des": "どなたにも共有していません",
    "shareDisable.des": "全員に共有しています",
    "pejikokaibi": "ページ公開日",
    "shareDate.desTitle": "ページごとに公開日を設定したい場合",
    "shareDate.desPC": "共有するページごとに個別の公開日を設定したい場合は、ページ名の右に表示されている設定アイコンをクリックすると個別設定できるようになります。",
    "shareDate.des": "「共有するページ」のページタイトルをタップし、表示される「ページ公開日」に日付を入力してください。\n「ページ公開日」に日付が入力されていない場合は、「ノート公開日」に公開され、読めるようになります。",
    nikokaisurupeji: "に公開するページ",
    shuukan: "習慣",
    challenge: "チャレンジ",
    yaritaikotoshurui: "やりたいこと種類",
    yuusendo: "優先度",
    imagegazou: "イメージ画像",
    jitsugenstep: "実現ステップ",
    shinchoku: "進捗",
    maiasajoggingsurunado: "毎朝ジョギングをするなど",
    "yaritairiyuunado.challenge": "友人と共に大きな達成感を味わいたいからなど",
    "yaritairiyuunado.habit": "孫と楽しい時間を過ごせるように健康を維持したいからなど",
    "yuusendo.KOU": "高",
    "yuusendo.CHUU": "中",
    "yuusendo.TEI": "低",
    "atode": "あとで",
    "hajimeru": "はじめる",
    "yaritaikotonoshurui": "やりたいことの種類",
    "tsugie": "次へ",
    "yaritairiyu": "やりたい理由",
    "yaritairiyu.placeholder": "友人と一緒に大きな達成感を味わいたいからなど",
    "keikakuwotateru": "計画を立てる",
    "bucketlist.guide.confirmTitle": "心豊かに生きるために",
    "bucketlist.guide.confirmContent": "これからの人生を考えるとしたら、どんな自分を、誰と過ごすどんな時間を思い浮かべますか？\nやりたいことを考えるだけでなく、なぜやりたいのかも深掘りしていくことで、自分だけのウェルビーイングが見つかるかもしれません。\n\nあなたが思い浮かべたことをまずは１つ、書き出してみましょう。",
    "bucketlist.guide.categoryTitle": "やりたいことは何ですか？",
    "bucketlist.guide.categoryContent": "あなたのやりたいことは、チャレンジ系（体験したい、挑戦したい、実現したいこと）ですか？それとも習慣系（コツコツ続けたいこと）ですか？",
    "bucketlist.guide.reasonTitle": "それをやりたい理由やきっかけは何ですか？",
    "bucketlist.guide.reasonContent": "長い間の憧れ？感謝を伝えたい誰かのため？一緒に過ごす大切な時間のため？過去から現在までを振り返り、理由を探ってみましょう。",
    "bucketlist.guide.challengeAgeTitle": "いつチャレンジしますか？",
    "bucketlist.guide.challengeAgeContent": "今だからこそできることもあれば、時間をかけて準備することもあるでしょう。体力や資金、環境など具体的にイメージして、考えてみましょう。",
    "bucketlist.guide.priorityTitle": "優先度を考えてみましょう",
    "bucketlist.guide.habitPriorityContent": "自分にとってそれはどれくらい大切なことでしょうか？限りある人生、他のやりたいことと比較しながら、優先度を決めて取り組みましょう。",
    "bucketlist.guide.challengePriorityContent": "自分にとってそれはどれくらい大切なことでしょうか？限りある人生、他のやりたいことと比較しながら、優先度を決めて取り組みましょう。",
    "bucketlist.guide.challengeDoneTitle": "素敵なチャレンジですね！",
    "bucketlist.guide.challengeDoneContent": "チャレンジが早速１つ登録されました。チャレンジは、優先度の高いものから順に表示されます。\n\n自分にとって大切なものや価値観など、新しい発見はありましたか？\nそれらの気づきは、<slot>自由帳にテキストや画像などで残すこともできます。\n\nやりたいことを計画して、生きている今をもっと楽しみましょう！",
    "bucketlist.guide.habitDoneTitle": "素敵な習慣ですね！",
    "bucketlist.guide.habitDoneContent": "習慣が早速１つ登録されました！習慣は、優先度の高いものから順に表示されます。\n\n自分にとって大切なものや価値観など、新しい発見はありましたか？\nそれらの気づきは、<slot>自由帳にテキストや画像などで残すこともできます。\n\nやりたいことを計画して、生きている今をもっと楽しみましょう！",
    "memoCategory": "メモ",
    "title": "タイトル",
    "episode.bucketlist": "やりたい理由",
    "jiyucho": "自由帳",
    "sukippu": "スキップ",
    "shinchoku.MIKANRYOU": "未完了",
    "shinchoku.KANRYOU": "完了",
    "mitoroku": "未登録",
    "achieveDialog.title": "おめでとうございます！",
    "achieveDialog.des": "達成したものを教えてください。",
    "challengeDialog.title": "どちらを編集しますか",
    "challengeDialog.des": "実現ステップでは、やりたいことの達成に必要な準備などの目標が登録でき、達成までの進捗を管理できます。",
    "narabegae": "並べ替え",
    "sankamemberwoatsumerunado": "参加メンバーを集めるなど",
    "step.des": "やりたいことの達成に必要な準備などの目標を登録して、実現するための計画を立てましょう。",
    "dialogGuideStep.title": "続けて、実現ステップを登録しますか",
    "dialogGuideStep.des": "やりたいことの達成に必要な準備などの目標が登録でき、達成までの進捗を管理できます。",
    "toroku": "登録",
    "bucketlist.archive.status": "達成に更新",
    "mindtype": "幸福度診断テスト"
  },
};
