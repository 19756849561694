/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Divider, Flex, Icon, Image, Text } from "@aws-amplify/ui-react";
export default function EventListChecked(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="0"
      direction="column"
      width="375px"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="0px 16px 8px 16px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "EventListChecked")}
    >
      <Flex
        gap="16px"
        direction="column"
        shrink="0"
        alignSelf="stretch"
        objectFit="cover"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 547")}
      >
        <Divider
          height="1px"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          size="small"
          orientation="horizontal"
          {...getOverrideProps(overrides, "Divider")}
        ></Divider>
        <Flex
          gap="16px"
          direction="row"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 546")}
        >
          <Flex
            gap="16px"
            direction="row"
            width="303px"
            alignItems="flex-start"
            grow="1"
            basis="303px"
            height="56px"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 545")}
          >
            <Flex
              padding="0px 0px 0px 0px"
              width="100px"
              height="56px"
              shrink="0"
              position="relative"
              {...getOverrideProps(overrides, "Group 9")}
            >
              <Image
                width="100px"
                height="56px"
                position="absolute"
                top="0px"
                left="0px"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Image")}
              ></Image>
              <Image
                width="24px"
                height="24px"
                position="absolute"
                top="0px"
                left="0px"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "circle_check 2")}
              ></Image>
            </Flex>
            <Flex
              gap="4px"
              direction="column"
              width="187px"
              grow="1"
              basis="187px"
              height="24px"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 498")}
            >
              <Text
                fontFamily="Inter"
                fontSize="14px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="flex"
                direction="column"
                justifyContent="flex-start"
                shrink="0"
                alignSelf="stretch"
                objectFit="cover"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="EventTitle"
                {...getOverrideProps(overrides, "EventTitle")}
              ></Text>
            </Flex>
          </Flex>
          <Icon
            width="24px"
            height="24px"
            shrink="0"
            overflow="hidden"
            position="relative"
            padding="0px 0px 0px 0px"
            viewBox={{ minX: 0, minY: 0, width: 24, height: 24 }}
            paths={[
              {
                d: "M1.41 0L0 1.41L4.58 6L0 10.59L1.41 12L7.41 6L1.41 0Z",
                fill: "rgba(13,26,38,1)",
                fillRule: "nonzero",
                style: { transform: "translate(35.79%, 25%)" },
              },
            ]}
            type="chevron_right"
            fontSize="24px"
            {...getOverrideProps(overrides, "Icon")}
          ></Icon>
        </Flex>
      </Flex>
    </Flex>
  );
}
