import { DataStore } from "aws-amplify";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { phoneRegex } from "../constants";
import { LeavesSumahoPc } from "../models";
import { ListField } from "../page-components";
import { getLabel, isNotEmpty, useInitializePage } from "../utils/CommonUtils";
import { KIKISHUBETSU, useDatalist } from "../utils/DatalistUtils";
import { usePrompt } from "../utils/Prompt";

function DevicesEditPage() {
  const navigate = useInitializePage("sumahoyapcnitsuite", "title.update");
  const [data, setData] = useState();
  const [deviceData, setDeviceData] = useState({
    kikishubetsu: "",
    keiyakusaki: "",
    denwabangou: "",
    meiginin: "",
    kishumei: "",
    bikou: "",
  });
  const [denwabangouError, setdenwabangouError] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);
  let params = useParams();
  const [update, setUpdate] = useState(false);
  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const data = await DataStore.query(LeavesSumahoPc, (c) =>
        c.id("eq", params.id),
      );
      if (data?.length > 0) {
        setData(data[0]);
        setDeviceData({
          kikishubetsu: setKikishubetsu(data[0].kikishubetsu),
          keiyakusaki: data[0].keiyakusaki,
          denwabangou: data[0].denwabangou,
          meiginin: data[0].meiginin,
          kishumei: data[0].kishumei,
          bikou: data[0].bikou,
        });
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  const [
    KikishubetsuDataList,
    setKikishubetsu,
    getKikishubetsu,
    hasErrorKikishubetsu,
  ] = useDatalist({
    list: KIKISHUBETSU,
    required: true,
    dataUpdateHook: setUpdate,
  });

  const listField = [
    {
      type: "dataList",
      child: <KikishubetsuDataList label="kikishubetsu" />,
    },
    {
      type: "textField",
      label: "keiyakusaki",
      value: deviceData?.keiyakusaki,
      placeHolder: getLabel("carrieryaprovidernado"),
    },
    {
      type: "textField",
      label: "denwabangou",
      value: deviceData?.denwabangou,
      errorMessage: denwabangouError,
    },
    {
      type: "textField",
      label: "meiginin",
      value: deviceData?.meiginin,
    },
    {
      type: "textField",
      label: "kishumei",
      value: deviceData?.kishumei,
      placeHolder: getLabel("iphone12nado"),
    },
    {
      type: "textArea",
      label: "bikou",
      value: deviceData?.bikou,
    },
  ];

  const updateDataDevice = useCallback(async () => {
    let valid = true;
    const validPhone =
      !phoneRegex.test(deviceData.denwabangou) &&
      isNotEmpty(deviceData.denwabangou);
    if (hasErrorKikishubetsu() || validPhone) {
      setdenwabangouError(validPhone ? getLabel("error.telno") : null);
      valid = false;
    }
    if (valid) {
      setIsUpdated(true);
      await DataStore.save(
        LeavesSumahoPc.copyOf(data, (updated) => {
          updated.kikishubetsu = getKikishubetsu();
          updated.keiyakusaki = deviceData.keiyakusaki;
          updated.denwabangou = deviceData.denwabangou;
          updated.meiginin = deviceData.meiginin;
          updated.kishumei = deviceData.kishumei;
          updated.bikou = deviceData.bikou;
        }),
      );
      window.gtag("event", "ChangeComplete", {
        event_category: "Edit",
        event_label: "スマホやPCについて変更完了",
      });
      navigate(-1, { replace: true });
    }
  }, [
    data,
    deviceData.bikou,
    deviceData.denwabangou,
    deviceData.keiyakusaki,
    deviceData.kishumei,
    deviceData.meiginin,
    getKikishubetsu,
    hasErrorKikishubetsu,
    navigate,
  ]);

  return (
    <ListField
      label={getLabel("button.update")}
      list={listField}
      setState={setDeviceData}
      setUpdate={setUpdate}
      onSave={updateDataDevice}
      onCancel={() => navigate(-1, { replace: true })}
      isDisabled={isUpdated}
    />
  );
}

export default DevicesEditPage;
