import React, { useState } from 'react';
import { isMobile, isTablet } from "react-device-detect";
import { Flex, Text } from '@aws-amplify/ui-react';
import awsExports from '../aws-exports';
import Amplify, { Auth, API } from 'aws-amplify';
import { listLeavesMindTypeAnswers } from '../graphql/queries';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Legend, ResponsiveContainer } from 'recharts';

export default function MindTypeResultChart(props) {

    // 各項目の全国平均値
    const AVERAGE_RESULT_1 = 22.54;
    const AVERAGE_RESULT_2 = 24.37;
    const AVERAGE_RESULT_3 = 21.69;
    const AVERAGE_RESULT_4_YATTEMIYO = 17.85;
    const AVERAGE_RESULT_4_ARIGATO   = 23.8;
    const AVERAGE_RESULT_4_NANTOKA   = 17.41;
    const AVERAGE_RESULT_4_ANATA     = 18.99

    // チャート表示用データ
    const [ stopLoading, setStopLoading ] = useState(false);
    const [ chartData, setChartData ] = useState([]);

    Amplify.configure(awsExports);

    /* APIから結果値を取得、集計 */
    async function getAnswer (answerId) {        

        let answerCount = 0;
        let items = [];

        while ( answerCount != 37 ) {
                const user = await Auth.currentAuthenticatedUser();
                let result = await API.graphql({
                    query: listLeavesMindTypeAnswers,
                    variables: {
                        filter: {
                            answerId: { eq: answerId }
                        },
                        limit: 3000
                    }
                });

                items = result.data.listLeavesMindTypeAnswers ? result.data.listLeavesMindTypeAnswers.items : [];

                while (result.data.listLeavesMindTypeAnswers.nextToken) {
                    result = await API.graphql({
                        query: listLeavesMindTypeAnswers,
                        variables: {
                            filter: {
                                answerId: { eq: answerId }
                            },
                            nextToken: result.data.listLeavesMindTypeAnswers.nextToken
                        },
                        limit: 3000
                    });       
                    result.data.listLeavesMindTypeAnswers.items.forEach((i) => items.push(i));
                }

                answerCount = items.length;
        }

        let result_1 = 0;
        let result_2 = 0;
        let result_3 = 0;
        let result_4_yattemiyo = 0;
        let result_4_arigato = 0;
        let result_4_nantoka = 0;
        let result_4_anata = 0;

        items.forEach(function(item) {
            let questionNo = parseInt(item.questionNo);
            let page = parseInt(item.page);
            let answer = parseInt(item.answer);

            if ( page == 1 ) {
                result_1 = result_1 + answer;
            }
            else if ( page == 2 ) {
                result_2 = result_2 + answer;
            }
            else if ( page == 3 ) {
                result_3 = result_3 + answer;
            }
            else if ( page == 4 ) {
                if ( questionNo >= 1 && questionNo <= 4 ) {
                    result_4_yattemiyo = result_4_yattemiyo + answer;
                }
                else if ( questionNo >= 5 && questionNo <= 8 ) {
                    result_4_arigato = result_4_arigato + answer;
                }
            }
            else if ( page == 5 ) {
                if ( questionNo >= 9 && questionNo <= 12 ) {
                    result_4_nantoka = result_4_nantoka + answer;
                }
                else {
                    result_4_anata = result_4_anata + answer;
                }
            }
        });

        // チャート描画用データ
        let tmpChartData = [
            {
                subject: 'ディーナーの人生満足尺度',
                result: ( result_1 / 35 ),
                average: ( AVERAGE_RESULT_1 / 35 ),
                fullMark: ( 35 / 35 )
            },
            {
                subject: 'ポジティブ感情',
                result: ( result_2 / 48 ),
                average: ( AVERAGE_RESULT_2 / 48 ),
                fullMark: ( 48 / 48 )
            },
            {
                subject: 'ネガティブ感情',
                result: ( result_3 / 48 ),
                average: ( AVERAGE_RESULT_3 / 48 ),
                fullMark: ( 48 / 48 )
            },
            {
                subject: 'やってみよう！因子',
                result: ( result_4_yattemiyo / 28 ),
                average: ( AVERAGE_RESULT_4_YATTEMIYO / 28 ),
                fullMark: ( 28 / 28 )
            },
            {
                subject: 'ありがとう！因子',
                result: ( result_4_arigato / 28 ),
                average: ( AVERAGE_RESULT_4_ARIGATO / 28 ),
                fullMark: ( 28 / 28 )
            },
            {
                subject: 'なんとかなる！因子',
                result: ( result_4_nantoka / 28 ),
                average: ( AVERAGE_RESULT_4_NANTOKA / 28 ),
                fullMark: ( 28 / 28 )
            },
            {
                subject: 'あなたらしく！因子',
                result: ( result_4_anata / 28 ),
                average: ( AVERAGE_RESULT_4_ANATA / 28 ),
                fullMark: ( 28 / 28 )
            }
        ];

        return(tmpChartData);
    };

    if ( !stopLoading ) {
        const answerPromise = getAnswer(props.answerId);
        answerPromise.then( (tmpChartData) => {
            setChartData(tmpChartData);
            setStopLoading(true);
        });
    }

    if ( !stopLoading && !chartData.length ) {
        return (
                <Flex width="100%" height="100px" position="relative" top="-20px" justifyContent="center" alignItems="center">
                    <Text align="center" fontSize="18px">
                    ご回答ありがとうございました！<br />
                    結果の表示までお待ちください...
                    </Text>
                </Flex>
        );
    }
    else {
        return(
            <ResponsiveContainer width="100%" height="100%">
            <RadarChart cx="50%" cy="50%" outerRadius="80%" fontSize={(isMobile || isTablet) ? 8 : 14 } data={chartData}>
            <Legend verticalAlign="top" height={(isMobile || isTablet) ? 120 : 80} />
                    <PolarGrid />
                    <PolarAngleAxis dataKey="subject" />
                    <Radar
                        name="回答結果"
                        dataKey="result"
                        fillOpacity={0}
                        stroke="#E16E2D"
                        strokeWidth={3}
                        dot="true"
                        legendType="line"
                        isAnimationActive={false}
                    />
                    <Radar
                        name="全国平均"
                        dataKey="average"
                        fillOpacity={0}
                        stroke="#135B7B"
                        strokeWidth={3}
                        dot="true"
                        legendType="line"
                        isAnimationActive={false}
                    />
                </RadarChart>
            </ResponsiveContainer>
        );
    }
}
