import React, { useEffect, useState } from "react";
import { LabelM, LabelS, ListTwolineATextButton, DefaultButton, RawDataButton } from "../../page-components";
import { getLabel, isEmpty, useInitializePage } from "../../utils/CommonUtils";
import { log } from "../../utils/Logger";
import { Auth, API, DataStore, Predicates, Storage } from "aws-amplify";
import "../../assets/css/loading.css"; // Import css
import { Flex, Text, Card, Button } from "@aws-amplify/ui-react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import ExcelJS from "exceljs";
import { listLeavesMyEvents } from "../../graphql/queries";
import { LeavesEvents } from "../../models";
import { ConsoleLogger } from "@aws-amplify/core";

export default function () {
  const navigate = useInitializePage("dashboard");

  const [isUserListLoaded, setIsUserListLoaded] = useState(false);
  const [loadingUserCount, setLoadingUserCount] = useState(0);
  const [isEventLoaded, setIsEventLoaded] = useState(false);
  const [usersInfo, setUsersInfo] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [users, setUsers] = useState();
  //   const [ageDatasets, setAgeDatasets] = useState({});
  const [ageData, setAgeData] = useState({
    man: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    woman: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    unselected: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  });
  const [regDateData, setRegDateData] = useState({
    man: new Map(),
    woman: new Map(),
    unselected: new Map(),
  });
  const [regDateRange, setRegDateRange] = useState([]);

  const [eventData, setEventData] = useState([]);
  const [eventConfirmData, setEventConfirmData] = useState({});
  const [eventConfirmRange, setEventConfirmRange] = useState([]);

  const GenderLabel = {
    man: "男",
    woman: "女",
    unselected: "選択しない",
  };
  const StatusLabel = {
    CONFIRMED: "メール検証済み",
    UNCONFIRMED: "メール未検証",
  };

  const Loading = () => {
    return (
      <div className="loading-position">
        <div className="loadingio-spinner-ellipsis-c2saky3xck">
          <div className="ldio-tsajfowb9ik">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    );
  };
  ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

  useEffect(async () => {
    const user = await Auth.currentAuthenticatedUser();
    const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
    log.debug("groups", groups);
    if (groups && groups.indexOf("Admin") >= 0) {
      setIsAdmin(true);

      const getUserList = async () => {
        // const getUserList = async () => {
        // Cognitoユーザリスト取得
        let apiName = "AdminQueries";
        let path = "/listUsers";
        let myInit = {
          queryStringParameters: {},
          headers: {
            "Content-Type": "application/json",
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
          },
        };
        const usersMap = new Map();
        let userDone = false;
        try {
          // ユーザ情報取得
          while (!userDone) {
            const response = await API.get(apiName, path, myInit);
            response.Users.filter((u) => u.UserStatus === "CONFIRMED").map((u) => {
              const attr = {};
              u.Attributes.map((e) => {
                attr[e.Name] = e.Value;
              });
              usersMap.set(u.Username, {
                ...u,
                Attributes: attr,
              });
            });
            setLoadingUserCount(usersMap.size);
            if (response.NextToken) {
              myInit.queryStringParameters.token = response.NextToken;
            } else {
              userDone = true;
            }
          }
        } catch (e) {
          console.log(e);
        }

        // console.log(usersMap);
        setUsers(usersMap);
        const now = new Date();

        setUsersInfo({
          count: usersMap.size,
          toDayCount: Array.from(usersMap.values()).filter((u) => {
            const createDate = new Date(u.UserCreateDate);
            return now.getYear() == createDate.getYear() && now.getMonth() == createDate.getMonth() && now.getDate() == createDate.getDate();
          }).length,
        });

        // 登録日レンジ初期設定
        const range = [];
        var rangeDate = new Date();
        rangeDate.setDate(rangeDate.getDate() - 31);
        for (let i = 0; i < 31; i++) {
          rangeDate.setDate(rangeDate.getDate() + 1);

          const formatedCreateDate = new String(rangeDate.getFullYear()).concat(
            "/" + ("0" + (rangeDate.getMonth() + 1)).slice(-2),
            "/" + ("0" + rangeDate.getDate()).slice(-2)
          );
          range.push(formatedCreateDate);
        }
        //   console.log(regDateData);
        setRegDateRange(range);
        setEventConfirmRange(range);

        setIsUserListLoaded(true);

        // 年齢別データ生成
        // const ageData = { man: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], woman: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], unselected: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0] };
        Array.from(usersMap.values()).map((u) => {
          if (u.Attributes.gender && u.Attributes.birthdate) {
            const today = parseInt(new String(now.getFullYear()).concat(("0" + (now.getMonth() + 1)).slice(-2), ("0" + now.getDate()).slice(-2)));
            const ageBase = Math.round((today - parseInt(u.Attributes.birthdate.replaceAll("-", ""))) / 100000);
            const ageBaseAjust = ageBase > 9 ? 9 : ageBase;
            ageData[u.Attributes.gender][ageBaseAjust] = ageData[u.Attributes.gender][ageBaseAjust] + 1;
          }
        });
        // console.log(ageData);
        setAgeData(JSON.parse(JSON.stringify(ageData)));

        // 登録日データ生成
        const regDateDataInit = {
          man: new Map(),
          woman: new Map(),
          unselected: new Map(),
        };
        Array.from(usersMap.values()).map((u) => {
          const createDate = new Date(u.UserCreateDate);
          const formatedCreateDate = new String(createDate.getFullYear()).concat(
            "/" + ("0" + (createDate.getMonth() + 1)).slice(-2),
            "/" + ("0" + createDate.getDate()).slice(-2)
          );
          if (u.Attributes.gender) {
            if (regDateDataInit[u.Attributes.gender].has(formatedCreateDate)) {
              regDateDataInit[u.Attributes.gender].set(formatedCreateDate, regDateDataInit[u.Attributes.gender].get(formatedCreateDate) + 1);
            } else {
              regDateDataInit[u.Attributes.gender].set(formatedCreateDate, 1);
            }
          } else {
            if (regDateDataInit["unselected"].has(formatedCreateDate)) {
              regDateDataInit["unselected"].set(formatedCreateDate, regDateDataInit["unselected"].get(formatedCreateDate) + 1);
            } else {
              regDateDataInit["unselected"].set(formatedCreateDate, 1);
            }
          }
        });

        setRegDateData(regDateDataInit);
      };
      getUserList();
      const getEvent = async () => {
        // イベント情報
        const allEvents = await DataStore.query(LeavesEvents);
        const nowEventsList = allEvents
          .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
          .filter((e) => {
            const eventEndDate = new Date(e.endDate + "T23:59:59Z");
            const eventStartDate = new Date(e.startDate + "T00:00:00Z");
            // return new Date() <= eventEndDate && new Date() > eventStartDate;
            return new Date() > eventStartDate; // 過去イベントも表示する
          });
        // console.log(nowEventsList);
        const eventDataInit = {};
        const eventConfirmDataInit = {};

        for (const ev of nowEventsList) {
          let eventDone = false;
          const eventList = new Array();
          const eventRequest = {
            query: listLeavesMyEvents,
            variables: { filter: { leavesEventsID: { eq: ev.id } } },
            authMode: "AWS_IAM",
          };
          while (!eventDone) {
            const myEvents = await API.graphql(eventRequest);
            eventList.push(...myEvents.data.listLeavesMyEvents.items.filter((e) => !e._deleted));
            if (myEvents.data.listLeavesMyEvents.nextToken) {
              eventRequest.variables.nextToken = myEvents.data.listLeavesMyEvents.nextToken;
            } else {
              eventDone = true;
            }
          }
          eventDataInit[ev.id] = { name: ev.name, list: eventList };

          // 参加登録日別リスト生成
          const eventConfirmDateMap = new Map();
          eventList
            .filter((e) => e.confirmed)
            .map((e) => {
              const confirmedDate = new Date(e.updatedAt);
              const formatedConfirmedDate = new String(confirmedDate.getFullYear()).concat(
                "/" + ("0" + (confirmedDate.getMonth() + 1)).slice(-2),
                "/" + ("0" + confirmedDate.getDate()).slice(-2)
              );
              if (eventConfirmDateMap.has(formatedConfirmedDate)) {
                eventConfirmDateMap.set(formatedConfirmedDate, eventConfirmDateMap.get(formatedConfirmedDate) + 1);
              } else {
                eventConfirmDateMap.set(formatedConfirmedDate, 1);
              }
            });

          eventConfirmDataInit[ev.id] = eventConfirmDateMap;
        }
        setEventData(eventDataInit);
        setEventConfirmData(eventConfirmDataInit);
        // console.log("eventConfirmDataInit", eventConfirmDataInit);

        setIsEventLoaded(true);
      };
      getEvent();
    }
  }, []);

  const getRegDateRangeData = (gender, isTotal = true) => {
    let count = 0;
    isTotal && Array.from(regDateData[gender].keys()).map((d) => {
      if (d < regDateRange[0]) {
        count = count + regDateData[gender].get(d);
      }
    });
    const rangeData = [];
    regDateRange.map((r) => {
      if (isTotal)
        count =
          count + (regDateData[gender].has(r) ? regDateData[gender].get(r) : 0);
      else count = regDateData[gender].has(r) ? regDateData[gender].get(r) : 0;
      rangeData.push(count);
    });
    return rangeData;
  };

  const handlerClickDownloadButton = async (e, format) => {
    e.preventDefault();

    const workbook = new ExcelJS.Workbook();
    workbook.addWorksheet("sheet1");
    const worksheet = workbook.getWorksheet("sheet1");

    worksheet.columns = [
      { header: "識別ID", key: "username" },
      { header: "メールアドレス", key: "email" },
      { header: "ステータス", key: "status" },
      { header: "作成日時", key: "createdAt" },
      { header: "郵便番号", key: "postcode" },
      { header: "性別", key: "gender" },
      { header: "生年月日", key: "birthdate" },
    ];
    Array.from(users.values()).forEach((u) => {
      worksheet.addRow({
        username: u.Username,
        email: u.Attributes.email,
        status: StatusLabel[u.UserStatus],
        createdAt: u.UserCreateDate.replaceAll("-", "/").replace("T", " ").substring(0, 19),
        postcode: u.Attributes.given_name,
        gender: GenderLabel[u.Attributes.gender],
        birthdate: u.Attributes.birthdate?.replaceAll("-", "/"),
      });
    });

    const uint8Array =
      format === "xlsx"
        ? await workbook.xlsx.writeBuffer() //xlsxの場合
        : await workbook.csv.writeBuffer(); //csvの場合
    const blob = new Blob([uint8Array], { type: "application/octet-binary" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "HIRAQL NOTE登録ユーザ." + format; //フォーマットによってファイル拡張子を変えている
    a.click();
    a.remove();
  };
  const handlerClickEventDownloadButton = async (e, format, eventId) => {
    e.preventDefault();

    const workbook = new ExcelJS.Workbook();
    workbook.addWorksheet("sheet1");
    const worksheet = workbook.getWorksheet("sheet1");

    worksheet.columns = [
      { header: "イベント名", key: "name" },
      { header: "メールアドレス", key: "email" },
      { header: "申し込み日", key: "createdAt" },
      { header: "確認状態", key: "confirmed" },
      { header: "確認日", key: "updatedAt" },
    ];
    eventData[eventId].list.forEach((e) => {
      worksheet.addRow({
        name: eventData[eventId].name,
        email: users.has(e.owner) ? users.get(e.owner).Attributes.email : "削除済みユーザ",
        createdAt: e.createdAt.replaceAll("-", "/").replace("T", " ").substring(0, 19),
        confirmed: e.confirmed,
        updatedAt: e.confirmed ? e.updatedAt.replaceAll("-", "/").replace("T", " ").substring(0, 19) : "",
      });
    });

    const uint8Array =
      format === "xlsx"
        ? await workbook.xlsx.writeBuffer() //xlsxの場合
        : await workbook.csv.writeBuffer(); //csvの場合
    const blob = new Blob([uint8Array], { type: "application/octet-binary" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "HIRAQL NOTEイベント登録情報." + format; //フォーマットによってファイル拡張子を変えている
    a.click();
    a.remove();
  };
  return (
    <div>
      {isAdmin ? (
        <>
          <LabelM label="登録者データ" />
          {isUserListLoaded ? (
            <div className="pccomponent" style={{ marginBottom: "32px" }}>
              <Flex gap="16px" direction="column">
                <Flex gap="10px">
                  <Card width="250px" height="150px" variation="elevated" style={{ textAlign: "center" }}>
                    <Text>登録者数</Text>
                    <Text style={{ color: "#808080" }}>総数</Text>
                    <Text>
                      <font style={{ fontSize: "40px" }}>{usersInfo.count}</font> ユーザ
                    </Text>
                  </Card>
                  <Card width="250px" height="150px" variation="elevated" style={{ textAlign: "center" }}>
                    <Text>登録者数</Text>
                    <Text style={{ color: "#808080" }}>今日</Text>
                    <Text>
                      <font style={{ fontSize: "40px" }}>{usersInfo.toDayCount}</font> ユーザ
                    </Text>
                  </Card>
                  <Flex direction="column" gap="0" marginLeft="24px">
                    <Text fontSize="16px" fontWeight="700" padding="7px 0">
                      {getLabel("torokuyuza")}
                    </Text>
                    <Flex alignItems="center">
                      <Button
                        width="fit-content"
                        padding="6px 14px"
                        style={{ backgroundColor: "#ffffff" }}
                        loadingText=""
                        onClick={(e) => handlerClickDownloadButton(e, "xlsx")}
                        ariaLabel=""
                      >
                        エクセル形式
                      </Button>
                      <Button
                        width="fit-content"
                        padding="6px 14px"
                        style={{ backgroundColor: "#ffffff" }}
                        loadingText=""
                        onClick={(e) => handlerClickDownloadButton(e, "csv")}
                        ariaLabel=""
                      >
                        CSV形式
                      </Button>
                    </Flex>
                    <Text fontSize="16px" fontWeight="700" padding="7px 0">
                      {getLabel("puranningukomokurawdeta")}
                    </Text>
                    <Flex>
                      <RawDataButton />
                    </Flex>
                  </Flex>
                </Flex>
                <Bar
                  options={{
                    responsive: true,
                    scales: {
                      x: {
                        grid: {
                          display: false,
                        }
                      },
                      y: {
                        ticks: {
                          precision: 0,
                        }
                      }
                    },
                    plugins: {
                      legend: {
                        position: "top",
                        labels: {
                          boxWidth: 8,
                          boxHeight: 8,
                        },
                      },
                      title: {
                        display: true,
                        font: {
                          size: 16
                        },
                        text: "新規登録者数推移（過去30日間）",
                      },
                    },
                  }}
                  data={{
                    labels: regDateRange,
                    datasets: [
                      {
                        label: "男性",
                        data: getRegDateRangeData("man", false),
                        borderColor: "#3d71be",
                        backgroundColor: "#3d71be",
                        cubicInterpolationMode: "monotone",
                        tension: 0.5,
                        stack: "1"
                      },
                      {
                        label: "女性",
                        data: getRegDateRangeData("woman", false),
                        borderColor: "#f37c41",
                        backgroundColor: "#f37c41",
                        cubicInterpolationMode: "monotone",
                        tension: 0.5,
                        stack: "1"
                      },
                      {
                        label: "選択しない",
                        data: getRegDateRangeData("unselected", false),
                        borderColor: "#a4a4a4",
                        backgroundColor: "#a4a4a4",
                        cubicInterpolationMode: "monotone",
                        tension: 0.5,
                        stack: "1"
                      },
                    ],
                  }}
                />
                <Bar
                  options={{
                    responsive: true,
                    scales: {
                      x: {
                        grid: {
                          display: false,
                        }
                      },
                      y: {
                        ticks: {
                          precision: 0,
                        }
                      }
                    },
                    plugins: {
                      legend: {
                        position: "top",
                        labels: {
                          boxWidth: 8,
                          boxHeight: 8,
                        },
                      },
                      title: {
                        display: true,
                        font: {
                          size: 16
                        },
                        text: "累計登録者数推移（過去30日間）",
                      },
                    },
                  }}
                  data={{
                    labels: regDateRange,
                    datasets: [
                      {
                        label: "男性",
                        data: getRegDateRangeData("man"),
                        borderColor: "#3d71be",
                        backgroundColor: "#3d71be",
                        cubicInterpolationMode: "monotone",
                        tension: 0.5,
                        stack: "1"
                      },
                      {
                        label: "女性",
                        data: getRegDateRangeData("woman"),
                        borderColor: "#f37c41",
                        backgroundColor: "#f37c41",
                        cubicInterpolationMode: "monotone",
                        tension: 0.5,
                        stack: "1"
                      },
                      {
                        label: "選択しない",
                        data: getRegDateRangeData("unselected"),
                        borderColor: "#a4a4a4",
                        backgroundColor: "#a4a4a4",
                        cubicInterpolationMode: "monotone",
                        tension: 0.5,
                        stack: "1"
                      },
                    ],
                  }}
                />
                <Bar
                  options={{
                    responsive: true,
                    scales: {
                      x: {
                        grid: {
                          display: false,
                        }
                      },
                      y: {
                        ticks: {
                          precision: 0,
                        }
                      }
                    },
                    plugins: {
                      legend: {
                        position: "top",
                        labels: {
                          boxWidth: 8,
                          boxHeight: 8,
                        },
                      },
                      title: {
                        display: true,
                        font: {
                          size: 16
                        },
                        text: "年代別累計登録者数",
                      },
                    },
                  }}
                  data={{
                    labels: ["10歳未満", "10代", "20代", "30代", "40代", "50代", "60代", "70代", "80代", "90代以上"],
                    datasets: [
                      {
                        label: "男性",
                        data: ageData.man,
                        borderColor: "#3d71be",
                        backgroundColor: "#3d71be",
                        stack: "1"
                      },
                      {
                        label: "女性",
                        data: ageData.woman,
                        borderColor: "#f37c41",
                        backgroundColor: "#f37c41",
                        stack: "1"
                      },
                      {
                        label: "選択しない",
                        data: ageData.unselected,
                        borderColor: "#a4a4a4",
                        backgroundColor: "#a4a4a4",
                        stack: "1"
                      },
                    ],
                  }}
                />

                <div>
                  <LabelM label="イベント情報" />
                  {isEventLoaded ? (
                    Object.keys(eventData).map((event, index) => {
                      return (
                        <Flex key={index} direction="column">
                          <LabelS label={eventData[event].name} />
                          <Flex>
                            <Card width="250px" height="150px" variation="elevated" style={{ textAlign: "center" }}>
                              <Text>登録者数</Text>
                              <Text style={{ color: "#808080" }}>総数</Text>
                              <Text>
                                <font style={{ fontSize: "40px" }}>{eventData[event].list.length}</font> ユーザ
                              </Text>
                            </Card>
                            <Card width="250px" height="150px" variation="elevated" style={{ textAlign: "center" }}>
                              <Text>来場者数</Text>
                              <Text style={{ color: "#808080" }}>総数</Text>
                              <Text>
                                <font style={{ fontSize: "40px" }}>{eventData[event].list.filter((l) => l.confirmed).length}</font> ユーザ
                              </Text>
                            </Card>
                            <Flex direction="column" alignItems="center">
                              <Text>エクスポート</Text>
                              <Button
                                width="130px"
                                style={{ backgroundColor: "#ffffff" }}
                                loadingText=""
                                onClick={(e) => handlerClickEventDownloadButton(e, "xlsx", event)}
                                ariaLabel=""
                              >
                                エクセル形式
                              </Button>
                              <Button
                                width="130px"
                                style={{ backgroundColor: "#ffffff" }}
                                loadingText=""
                                onClick={(e) => handlerClickEventDownloadButton(e, "csv", event)}
                                ariaLabel=""
                              >
                                CSV形式
                              </Button>
                            </Flex>
                          </Flex>
                          <Bar
                            options={{
                              responsive: true,
                              plugins: {
                                legend: {
                                  position: "top",
                                },
                                title: {
                                  display: true,
                                  text: "日付別来場者数(過去３０日)",
                                },
                              },
                              scales: {
                                y: {
                                  ticks: {
                                    precision: 0,
                                  }
                                }
                              }
                            }}
                            data={{
                              labels: eventConfirmRange.map((r) => r.slice(-5)),
                              datasets: [
                                {
                                  label: "来場者数",
                                  data: eventConfirmRange.map((r) => (eventConfirmData[event].has(r) ? eventConfirmData[event].get(r) : 0)),
                                  borderColor: "rgb(104, 134, 73)",
                                  backgroundColor: "rgba(104, 134, 73, 0.5)",
                                },
                              ],
                            }}
                          />
                        </Flex>
                      );
                    })
                  ) : (
                    <Loading />
                  )}
                </div>
              </Flex>
            </div>
          ) : (
            <Flex>
              <Flex>
                <Loading />
              </Flex>
              <Flex>
                <Text
                  style={{
                    position: "absolute",
                    top: "calc(50% - 50px)",
                    left: "50%",
                    transform: "translateY(-50%) translateX(-50%)",
                    webkitTransform: "translateY(-50%) translateX(-50%)",
                  }}
                >
                  読み込みユーザ数: {loadingUserCount}
                </Text>
              </Flex>
            </Flex>
          )}
        </>
      ) : null}
    </div>
  );
}
