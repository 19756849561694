import { PCListTwoLineA } from "../ui-components";

export default function ListTwoLineAPC(props) {
  const { label, value, onClick } = props;

  if (props.isEmptyHide && !props.value) {
    return <></>;
  } else {
    return (
      <PCListTwoLineA
        className="pointer"
        backgroundColor={props.selected && "#DFE2DC"}
        padding="12px 16px 12px 16px"
        width="100%"
        maxWidth="288px"
        overrides={{
          List: {
            minHeight: "48px",
            gap: 0,
            justifyContent: "center"
          },
          item: {
            display: "none"
          },
          overline: {
            className: "text-overflow-ellipsis vertical-elipsis",
            display: "block",
            width: "100%",
            fontWeight: "400",
            overflow: "hidden",
            fontSize: "16px",
            color: "#1C1A1D",
            children: label,
          },
        }}
        onClick={onClick}
      />
    );
  }
}
