import { DataStore } from "aws-amplify";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LeavesPet } from "../models";
import {
  ListSelectContent,
  ListSingleLineA,
  ListSingleLineD,
} from "../page-components";
import { getLabel, useInitializePage } from "../utils/CommonUtils";
import { isShare, queryDatas } from "../utils/ShareUtils";

function PetPage() {
  const navigate = useInitializePage("petnitsuite");
  const [listPet, setListPet] = useState([]);
  const [selected, setSelected] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const params = useParams();

  const handledChangeActive = (item) => {
    setSelected(item);
  };

  useEffect(() => {
    if (listPet?.length > 0) {
      setSelected(listPet[0]);
    } else {
      setSelected(null);
    }
  }, [listPet]);

  const deleteData = useCallback(async (dataPet) => {
    await DataStore.delete(dataPet);
    const modelData = await DataStore.query(LeavesPet);
    setListPet(modelData);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const modelData = await queryDatas(params, LeavesPet);
      if (modelData?.length > 0) setListPet(modelData);
      setIsLoaded(true);
    };
    getData();
  }, []);

  return (
    <ListSelectContent
      label={getLabel("petnitsuite")}
      mobileList={listPet.map((ele) => {
        return (
          <ListSingleLineA
            key={ele?.id}
            label={ele?.namae}
            onClick={() => navigate(ele?.id)}
          />
        );
      })}
      description={{
        type: "data",
        category: "petnitsuite",
      }}
      isShared={isShare(params)}
      addButtonLabel={getLabel("pet") + getLabel("button.addsuffix")}
      onAddClick={() => {
        navigate("add");
        window.gtag("event", "FirstWrite", {
          event_category: "Edit",
          event_label: "ペットについて書込開始",
        });
      }}
      pcListItem={listPet.map((ele, index) => {
        return (
          <ListSingleLineD
            key={ele?.id}
            label={ele?.namae}
            selected={selected?.id === ele?.id}
            onClick={() => {
              handledChangeActive(ele);
            }}
          />
        );
      })}
      selectedItemList={[
        {
          isEmptyHide: true,
          label: getLabel("namae"),
          value: selected?.namae,
        },
        {
          isEmptyHide: true,
          label: getLabel("shurui"),
          value: selected?.shurui,
        },
        {
          isEmptyHide: true,
          label: getLabel("seinengappi"),
          value: selected?.seinengappi,
          type: "date",
        },
        {
          isEmptyHide: true,
          label: getLabel("seibetsu"),
          value: selected?.seibetsu,
        },
        {
          isEmptyHide: true,
          label: getLabel("tourokubangou"),
          value: selected?.tourokubangou,
        },
        {
          isEmptyHide: true,
          label: getLabel("hokennoumu"),
          value: selected?.hokennoumu
            ? getLabel(`Hokennoumu.${selected?.hokennoumu}`)
            : "",
        },
        {
          isEmptyHide: true,
          label: getLabel("hokennkaisyamei"),
          value: selected?.hokennkaisyamei,
        },
        {
          isEmptyHide: true,
          label: getLabel("kakaritsukenobyouinmei"),
          value: selected?.kakaritsukenobyouinmei,
        },
        {
          isEmptyHide: true,
          label: getLabel("kakaritsukenobyouinnorenrakusaki"),
          value: selected?.kakaritsukenobyouinnorenrakusaki,
        },
        {
          isEmptyHide: true,
          label: getLabel("bikou"),
          value: selected?.bikou,
        },
      ]}
      selectedItemLabel={selected?.namae}
      onDelete={() => deleteData(selected)}
      onEdit={() => {
        navigate(selected?.id + "/edit");
        window.gtag("event", "Change", {
          event_category: "Edit",
          event_label: "ペットについて変更開始",
        });
      }}
      isLoaded={isLoaded}
    />
  );
}

export default PetPage;
