import React, { useState, useEffect } from 'react';
import { Flex, View, Text, Image } from '@aws-amplify/ui-react';
import { isMobile, isTablet } from 'react-device-detect';
import { useParams, useNavigate } from 'react-router-dom';
import {
        LabelM,
        PrimaryButton,
        MindTypeCheckPages,
        MindTypeCheckCategories,
        MindTypeCheckNextButton,
        MindTypeCheckStatus
} from '../page-components';
import mobileIcon from '../assets/image/data_cardMIND.png';
import Cookies from 'js-cookie';
import awsExports from '../aws-exports';
import Amplify, { Auth, API } from 'aws-amplify';
import { getLeavesMindTypeAnswerId } from '../graphql/queries';

export default function MindTypeCheck() {
  const navigate = useNavigate();
  const {page} = useParams();
  const [ answerTable, setAnswerTable ] = useState({});

  async function checkValid(page) {

    /* answerIdチェック */
    const answerId = Cookies.get("mindtypeAnswerId");
    if ( !answerId ) {
        return false;
    }
    else {
        // answerIdが自分のものでなければNG
        const user = await Auth.currentAuthenticatedUser();
        let result = await API.graphql({
            query: getLeavesMindTypeAnswerId,
            variables: { id: answerId }
        });

        if ( !result.data.getLeavesMindTypeAnswerId ) {
            return false;
        }
        else if( result.data.getLeavesMindTypeAnswerId.owner != user.username ) {
            return false;
        }
    }

    /* ページ回答済みチェック */
    let answeredCheckKey = "pageAnswered_" + answerId;
    let pageAnswered = Cookies.get(answeredCheckKey);

    // そもそも回答済みページがない状態＝初期画面ならOK
    if ( !pageAnswered ) {
        return true;
    }

    let pageAnswerList = pageAnswered.split('_');
    let answerPageTable = {};

    pageAnswerList.forEach( (pageNum) => {
        answerPageTable[pageNum] = 1;
    });

    //すでに指定ページが回答済みであればNG
    if ( answerPageTable[page] ) {
        return false;
    }

    for ( let i=1; i < page; i++ ) {
        if (!answerPageTable[i]) {
            // 途中回答飛ばしのページがあればNG
            return false;
        }
    }

  };

  // チェックがNGであればクッションページへ戻す
  const checkValidPromise = checkValid(page);
  checkValidPromise.then( (result) => {
    if (result === false) {
        window.location.href = "/mindtype";
    }
  });

  if ( isMobile || isTablet ) {
    return (
        <div>
           <LabelM label="幸福度診断テスト" />
           <View
             width="325px"
             height="auto"
             position="relative"
             boxShadow="2px 2px 6px rgba(0.30416667461395264, 0.3028993010520935, 0.3028993010520935, 0.20000000298023224)"
             padding="0px 0px 0px 0px"
             margin="0 auto"
             marginBottom="-60px"
           >
                <Flex
                  gap="10px"
                  position="absolute"
                  top="0%"
                  bottom="0%"
                  left="0%"
                  right="0%"
                  direction="row"
                  width="100%"
                  height="auto"
                  alignItems="flex-start"
                  borderRadius="3px"
                  padding="8px 20px 20px 15px"
                  backgroundColor="rgba(255,255,255,1)"
                >
                      <Image
                        width="45px"
                        height="45px"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        src={mobileIcon}
                      ></Image>
                </Flex>
                <MindTypeCheckCategories page={page} />
                <MindTypeCheckStatus page={page} />
                <Flex
                  position="relative"
                  top="calc(50% - 150px - 0px)"
                  left="0%"
                  right="0%"
                  direction="column"
                  width="346px"
                  height="auto"
                  alignItems="center"
                  padding="80px 20px 0px 0px"
                >
                    <Flex
                      justifyContent="center"
                      gap="40px"
                      direction="column"
                      alignItems="center"
                      shrink="0"
                      alignSelf="stretch"
                      objectFit="cover"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      marginBottom="20px"
                    >
                        <div class="MindTypeCheckFieldSP">
                            <MindTypeCheckPages
                                page={page}
                                answerTable={answerTable}
                                setAnswerTable={setAnswerTable} />
                        </div>
                        <MindTypeCheckNextButton
                            page={page}
                            answerTable={answerTable}
                            setAnswerTable={setAnswerTable}
                        />
                    </Flex>
                </Flex>
              </View>
            </div>
    );
  }
  else {
    return (
      <div className="swapper swapperMindTypeCheck">
        <LabelM label="幸福度診断テスト" isDivide isNonList/>
        <MindTypeCheckStatus page={page} />
        <View
          max-width="960px"
          width="100%"
          height="100%"
          display="block"
          position="relative"
          borderRadius="4px"
          backgroundColor="rgba(255,255,255,158)"
          margin="20px 0 0 0"
        >
          <MindTypeCheckCategories page={page} />
           <div class="MindTypeCheckField">
               <MindTypeCheckPages
                   page={page}
                   answerTable={answerTable}
                   setAnswerTable={setAnswerTable} />
           </div>

           <MindTypeCheckNextButton
                page={page}
                answerTable={answerTable}
                setAnswerTable={setAnswerTable} />
        </View>
     </div>
    );
  }
}
