/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import SelectFieldA from "./SelectFieldA";
import TextFieldA from "./TextFieldA";
import { Flex } from "@aws-amplify/ui-react";
export default function SelectFieldAOther(props) {
  const { overrides: overridesProp, ...rest } = props;
  const overrides = { ...overridesProp };
  return (
    <Flex
      gap="8px"
      direction="column"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "Flex")}
    >
      <SelectFieldA
        display="flex"
        shrink="0"
        width="375px"
        {...getOverrideProps(overrides, "Flex.SelectFieldA[0]")}
      ></SelectFieldA>
      <TextFieldA
        padding="0px 16px 0px 16px"
        display="flex"
        shrink="0"
        width="375px"
        {...getOverrideProps(overrides, "Flex.TextFieldA[0]")}
      ></TextFieldA>
    </Flex>
  );
}
