import { DataStore } from "aws-amplify";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { LeavesTakaramono } from "../models";
import { ListField } from "../page-components";
import { getLabel, isEmpty, useInitializePage } from "../utils/CommonUtils";
import { usePrompt } from "../utils/Prompt";

function CollectionsEditPage() {
  const navigate = useInitializePage(
    "takaramonolistcollection",
    "title.update",
  );
  const data = useRef();
  const [collectionData, setCollectionData] = useState({
    collectionmei: "",
    hokanbasyo: "",
    bikou: "",
  });
  const [collectionDataError, setCollectionDataError] = useState({
    collectionmeiError: "",
    hokanbasyoError: "",
    bikouError: "",
  });
  const [isUpdated, setIsUpdated] = useState(false);
  const params = useParams();
  const [update, setUpdate] = useState(false);
  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const dataQuery = await DataStore.query(LeavesTakaramono, (c) =>
        c.id("eq", params.id),
      );
      if (dataQuery?.length > 0) {
        data.current = dataQuery[0];
        setCollectionData({
          collectionmei: dataQuery[0].collectionmei,
          hokanbasyo: dataQuery[0].hokanbasyo,
          bikou: dataQuery[0].bikou,
        });
      }
    };
    getData();
  }, [params.id]);

  const listField = [
    {
      type: "textField",
      label: "collectionmei",
      value: collectionData?.collectionmei,
      placeHolder:
        "切手コレクションなど" /**TODO: using getLabel when 切手コレクションなど is defined in vocabulary*/,
      isRequire: true,
      errorMessage: collectionDataError.collectionmeiError,
    },
    {
      type: "textField",
      label: "hokanbasyo",
      placeHolder:
        "本棚の赤いファイルなど" /**TODO: using getLabel when 本棚の赤いファイルなど is defined in vocabulary*/,
      value: collectionData?.hokanbasyo,
    },
    {
      type: "textArea",
      label: "bikou",
      value: collectionData?.bikou,
    },
  ];

  const updateDataCollection = useCallback(async () => {
    let valid = true;
    if (isEmpty(collectionData.collectionmei)) {
      setCollectionDataError({
        ...collectionDataError,
        collectionmeiError: getLabel("error.required"),
      });
      valid = false;
    }
    if (valid) {
      setIsUpdated(true);
      await DataStore.save(
        LeavesTakaramono.copyOf(data.current, (updated) => {
          updated.collectionmei = collectionData.collectionmei;
          updated.hokanbasyo = collectionData.hokanbasyo;
          updated.bikou = collectionData.bikou;
        }),
      );
      window.gtag("event", "ChangeComplete", {
        event_category: "Edit",
        event_label: "宝物リスト・コレクション変更完了",
      });
      navigate(-1, { replace: true });
    }
  }, [
    collectionData.bikou,
    collectionData.collectionmei,
    collectionData.hokanbasyo,
    collectionDataError,
    data,
    navigate,
  ]);

  return (
    <ListField
      label={getLabel("button.update")}
      list={listField}
      setState={setCollectionData}
      setUpdate={setUpdate}
      onSave={updateDataCollection}
      onCancel={() => navigate(-1, { replace: true })}
      isDisabled={isUpdated}
    />
  );
}

export default CollectionsEditPage;
