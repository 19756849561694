/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Divider, Flex, Image, Text, View } from "@aws-amplify/ui-react";
export default function CardHint(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="325px"
      height="418px"
      position="relative"
      boxShadow="2px 2px 6px rgba(0.30588236451148987, 0.3019607961177826, 0.3019607961177826, 0.20000000298023224)"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "CardHint")}
    >
      <Flex
        gap="10px"
        position="absolute"
        top="-0.47%"
        bottom="75.35%"
        left="0%"
        right="0%"
        direction="row"
        width="325px"
        height="104.99px"
        alignItems="flex-start"
        borderRadius="4px"
        padding="0px 16px 16px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "BackImage")}
      >
        <Image
          width="70px"
          height="70px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Hint")}
        ></Image>
      </Flex>
      <Flex
        gap="0"
        position="absolute"
        top="calc(50% - 78px - 28px)"
        left="0%"
        right="0%"
        direction="column"
        width="325px"
        padding="16px 20px 20px 14px"
        backgroundColor="rgba(245,245,245,1)"
        {...getOverrideProps(overrides, "ColumContent")}
      >
        <Flex
          gap="16px"
          direction="row"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ColumBody")}
        >
          <Flex
            gap="10px"
            direction="row"
            width="fit-content"
            alignItems="flex-start"
            shrink="0"
            height="40px"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 558")}
          >
            <Image
              width="40px"
              height="40px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Colum")}
            ></Image>
          </Flex>
          <Flex
            gap="4px"
            direction="column"
            width="235px"
            grow="1"
            basis="235px"
            height="95px"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "ColumText")}
          >
            <Text
              fontFamily="Zen Kaku Gothic New"
              fontSize="17px"
              fontWeight="700"
              color="rgba(28,26,29,1)"
              lineHeight="31px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              shrink="0"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="お気に入りのコラム"
              {...getOverrideProps(
                overrides,
                "\u304A\u6C17\u306B\u5165\u308A\u306E\u30B3\u30E9\u30E0"
              )}
            ></Text>
            <Text
              fontFamily="Zen Kaku Gothic New"
              fontSize="15px"
              fontWeight="500"
              color="rgba(28,26,29,1)"
              lineHeight="30px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              shrink="0"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="気に入った記事、読み返したい記事をストック"
              {...getOverrideProps(
                overrides,
                "\u6C17\u306B\u5165\u3063\u305F\u8A18\u4E8B\u3001\u8AAD\u307F\u8FD4\u3057\u305F\u3044\u8A18\u4E8B\u3092\u30B9\u30C8\u30C3\u30AF"
              )}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="10px"
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ColumNext")}
        >
          <Image
            width="25px"
            height="25px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Next")}
          ></Image>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        position="absolute"
        top="calc(50% - 78px - -129px)"
        left="0%"
        right="0%"
        direction="column"
        width="325px"
        borderRadius="0px 0px 3px 3px"
        padding="16px 20px 20px 14px"
        backgroundColor="rgba(245,245,245,1)"
        {...getOverrideProps(overrides, "EventContent")}
      >
        <Flex
          gap="16px"
          direction="row"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "EvnetBody")}
        >
          <Flex
            gap="10px"
            direction="row"
            width="fit-content"
            alignItems="flex-start"
            shrink="0"
            height="40px"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 557")}
          >
            <Image
              width="40px"
              height="40px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Event")}
            ></Image>
          </Flex>
          <Flex
            gap="4px"
            direction="column"
            width="235px"
            grow="1"
            basis="235px"
            height="95px"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "EventText")}
          >
            <Flex
              gap="5px"
              direction="row"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "EventCount")}
            >
              <Text
                fontFamily="Zen Kaku Gothic New"
                fontSize="17px"
                fontWeight="700"
                color="rgba(28,26,29,1)"
                lineHeight="31px"
                textAlign="left"
                display="flex"
                direction="column"
                justifyContent="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="予約済みイベント"
                {...getOverrideProps(
                  overrides,
                  "\u4E88\u7D04\u6E08\u307F\u30A4\u30D9\u30F3\u30C8"
                )}
              ></Text>
              <Image
                width="18px"
                height="18px"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "One_badge 1")}
              ></Image>
            </Flex>
            <Text
              fontFamily="Zen Kaku Gothic New"
              fontSize="15px"
              fontWeight="500"
              color="rgba(28,26,29,1)"
              lineHeight="30px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              shrink="0"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="あなたの参加予定、過去に参加したイベント一覧"
              {...getOverrideProps(
                overrides,
                "\u3042\u306A\u305F\u306E\u53C2\u52A0\u4E88\u5B9A\u3001\u904E\u53BB\u306B\u53C2\u52A0\u3057\u305F\u30A4\u30D9\u30F3\u30C8\u4E00\u89A7"
              )}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="10px"
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "EventNext")}
        >
          <Image
            width="25px"
            height="25px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Next2")}
          ></Image>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        position="absolute"
        top="calc(50% - 52px - 158.5px)"
        left="13.08%"
        right="13.08%"
        direction="column"
        justifyContent="center"
        alignItems="center"
        padding="24px 20px 17px 20px"
        {...getOverrideProps(overrides, "Title")}
      >
        <Text
          fontFamily="Zen Kaku Gothic New"
          fontSize="25px"
          fontWeight="500"
          color="rgba(0,0,0,1)"
          lineHeight="33px"
          textAlign="center"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="お気に入りヒント"
          {...getOverrideProps(
            overrides,
            "\u304A\u6C17\u306B\u5165\u308A\u30D2\u30F3\u30C8"
          )}
        ></Text>
        <Text
          fontFamily="Zen Kaku Gothic New"
          fontSize="15px"
          fontWeight="500"
          color="rgba(0,0,0,1)"
          lineHeight="30px"
          textAlign="center"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="HINT"
          {...getOverrideProps(overrides, "HINT")}
        ></Text>
      </Flex>
      <Divider
        position="absolute"
        top="61.99%"
        bottom="37.78%"
        left="0%"
        right="0%"
        padding="0px 0px 0px 0px"
        size="small"
        orientation="horizontal"
        {...getOverrideProps(overrides, "Divider")}
      ></Divider>
    </View>
  );
}
