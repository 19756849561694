/* eslint-disable import/no-anonymous-default-export */
import { forwardRef } from "react";
import { isMobile, isSafari } from "react-device-detect";
import { PCTextField, TextFieldA } from "../ui-components";
import { restrictFieldDate, restrictFieldNumber } from "../utils/CommonUtils";

export default forwardRef(function (props, ref) {
  let onChange = props.onChange;
  const typeInputMobile = props.type === "number" || (props.type === "date" && isMobile) ? "tel" : props.type;

  const onKeyDown = (event) => {
    //using keydown because keypress is nolonger support
    const ignoreKey = ["Backspace", "Control", "ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown", "Tab", "Alt", "Enter", "Shift"];
    if (ignoreKey.findIndex((ele) => ele === event.key) < 0 && !event.ctrlKey) {
      if (!/^\d+$/.test(event.key) && props.type === "number") {
        // prevent "e" character on PC mode
        event.preventDefault();
      }
    }
  };

  const onPaste = (event) => {
    if (!/^\d+$/.test(event.clipboardData.getData("Text")) && typeInputMobile === "tel") {
      // Control paste value
      event.preventDefault();
    }
  };

  if (props.type === "number" && props.maxLength) {
    onChange = (event) => {
      event.target.value = restrictFieldNumber(event.target.value, props.maxLength);
      props.onChange({
        target: {
          value: event.target.value,
        },
      });
    };
  }

  if (props.type === "date" && !isMobile) {
    onChange = (event) => {
      if (event.target.value.length > 10 && !isSafari) {
        props.onChange({ target: { value: event.target.value + "--" } }); //バリデーションエラーにさせるため
      } else {
        props.onChange({
          target: {
            value: event.target.value,
          },
        });
      }
    };
  }

  if (isMobile) {
    const overridesProps = {};
    if (props.type === "date") {
      overridesProps.maxLength = 8;
      overridesProps.label =
        props.label && props.isRequired ? (
          <div>
            {props.label}
            <font className="required">※</font>
            <br />
            <font color="#5C6670" fontSize="0.8rem">
              【入力例】2022年1月20日 → 20220120
            </font>
          </div>
        ) : (
          <div>
            {props.label}
            <br />
            <font color="#5C6670" fontSize="0.8rem">
              【入力例】2022年1月20日 → 20220120
            </font>
          </div>
        );
      overridesProps.onChange = (event) => {
        event.target.value = restrictFieldDate(event.target.value);
        if (event.target.value.length !== 8) {
          props.onChange({ target: { value: event.target.value + "--" } }); //バリデーションエラーにさせるため
        } else {
          props.onChange({
            target: {
              value: event.target.value.slice(0, 4) + "-" + event.target.value.slice(4, 6) + "-" + event.target.value.slice(6, 8),
            },
          });
        }
      };
      if (props.value) {
        overridesProps.value = props.value.replaceAll("-", "");
      }
    }

    return (
      <TextFieldA
        margin="0px auto 0px auto"
        {...props.overrideStyle}
        overrides={{
          "Flex.TextField[0]": {
            maxLength: 100,
            ...props,
            value: props.value,
            errorMessage: props.errorMessage,
            width: "auto",
            hasError: !!props.errorMessage,
            ref: ref,
            autocomplete: "off",
            label: props.isRequired ? (
              <div>
                {props.label}
                <font className="required">※</font>
              </div>
            ) : (
              props.label
            ),
            onChange: onChange,
            ...overridesProps,
            type: typeInputMobile,
            onPaste: onPaste,
          },
        }}
      />
    );
  } else {
    return (
      <PCTextField
        className={props.className ? props.className : "pccomponent"}
        margin="0px auto 16px auto"
        {...props.overrideStyle}
        overrides={{
          "Flex.TextFieldA[0]": {
            overrides: {
              "Flex.TextField[0]": {
                maxLength: 100,
                ...props,
                width: "auto",
                fontSize: "16px",
                hasError: !!props.errorMessage,
                autocomplete: "off",
                ref: ref,
                label: (
                  <div style={{ fontSize: "16px" }}>
                    {props.isRequired ? (
                      <>
                        {props.label}
                        <font className="required">※</font>
                      </>
                    ) : (
                      props.label
                    )}
                  </div>
                ),
                onChange: onChange,
                onKeyDown: onKeyDown,
                onPaste: onPaste,
                max: "9999-12-31"
              },
            },
          },
        }}
      />
    );
  }
});
/*
.ListSingleLineA {
  margin: 16px;
}
.TextFieldA input {
  width: 100% !important;
  height: auto !important;
}
.TextFieldA textarea {
  width: 100% !important;
  height: auto !important;
}
.SelectFieldA {
  width: 100% !important;
  height: auto !important;
}
*/
