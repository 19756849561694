/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Image, Text, View } from "@aws-amplify/ui-react";
export default function CardWill(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="325px"
      height="300px"
      position="relative"
      boxShadow="2px 2px 6px rgba(0.30416667461395264, 0.3028993010520935, 0.3028993010520935, 0.20000000298023224)"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "CardWill")}
    >
      <Flex
        gap="10px"
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        direction="row"
        width="325px"
        height="300px"
        alignItems="flex-start"
        borderRadius="3px"
        padding="0px 20px 20px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "BackImage")}
      >
        <Image
          width="70px"
          height="70px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "will_cardIC 1")}
        ></Image>
        <Flex
          gap="10px"
          direction="row"
          height="280px"
          justifyContent="flex-end"
          alignItems="flex-end"
          grow="1"
          basis="225px"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Line")}
        >
          <Image
            width="25px"
            height="25px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "foward_circle 1")}
          ></Image>
        </Flex>
      </Flex>
      <Flex
        gap="25px"
        position="absolute"
        top="calc(50% - 150px - 0px)"
        left="0%"
        right="0%"
        direction="column"
        width="325px"
        height="300px"
        alignItems="center"
        padding="24px 20px 20px 20px"
        {...getOverrideProps(overrides, "Content")}
      >
        <Flex
          gap="21px"
          direction="column"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Text")}
        >
          <Flex
            gap="0"
            direction="column"
            width="285px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Title")}
          >
            <Text
              fontFamily="Zen Kaku Gothic New"
              fontSize="25px"
              fontWeight="500"
              color="rgba(28,26,29,1)"
              lineHeight="33px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              shrink="0"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="私の意思"
              {...getOverrideProps(overrides, "\u79C1\u306E\u610F\u601D")}
            ></Text>
            <Text
              fontFamily="Zen Kaku Gothic New"
              fontSize="15px"
              fontWeight="500"
              color="rgba(28,26,29,1)"
              lineHeight="30px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              shrink="0"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="WILL"
              {...getOverrideProps(overrides, "WILL")}
            ></Text>
          </Flex>
          <Text
            fontFamily="Zen Kaku Gothic New"
            fontSize="15px"
            fontWeight="500"
            color="rgba(28,26,29,1)"
            lineHeight="30px"
            textAlign="left"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="人生でやりたいことの実現プラン、介護や医療措置、相続、葬儀、お墓、何をどう遺していくのかなど、自分の意思を示すができます。自分らしい人生を生きるための手助けに。"
            {...getOverrideProps(
              overrides,
              "\u4EBA\u751F\u3067\u3084\u308A\u305F\u3044\u3053\u3068\u306E\u5B9F\u73FE\u30D7\u30E9\u30F3\u3001\u4ECB\u8B77\u3084\u533B\u7642\u63AA\u7F6E\u3001\u76F8\u7D9A\u3001\u846C\u5100\u3001\u304A\u5893\u3001\u4F55\u3092\u3069\u3046\u907A\u3057\u3066\u3044\u304F\u306E\u304B\u306A\u3069\u3001\u81EA\u5206\u306E\u610F\u601D\u3092\u793A\u3059\u304C\u3067\u304D\u307E\u3059\u3002\u81EA\u5206\u3089\u3057\u3044\u4EBA\u751F\u3092\u751F\u304D\u308B\u305F\u3081\u306E\u624B\u52A9\u3051\u306B\u3002"
            )}
          ></Text>
        </Flex>
      </Flex>
    </View>
  );
}
