import { CheckboxField, Grid, Text } from "@aws-amplify/ui-react";
import { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { getLabel } from "../utils/CommonUtils";

export default function CheckList(props) {
  const [checkedAll, setCheckedAll] = useState(false);
  const { list, onChange, listItemChecked } = props;
  const mountRef = useRef(true);

  const [listSelected, setListSelected] = useState([]);
  const preSelect = useRef([]);

  useEffect(() => {
    if (listItemChecked.length > 0 && mountRef.current) {
      setListSelected(listItemChecked);
      if (listItemChecked?.length === list?.length) setCheckedAll(true);
      preSelect.current = listItemChecked;
      mountRef.current = false;
    }
  }, [listItemChecked, mountRef.current]);

  const onChecked = (value, checked) => {
    let tempList = [...listSelected];
    if (listSelected.find((ele) => ele === value)) {
      if (!checked) {
        tempList = tempList.filter((ele) => ele !== value);
      }
    } else {
      tempList.push(value);
    }
    setListSelected(tempList);
    preSelect.current = tempList;
    if (tempList.length <= list.length) {
      setCheckedAll(false);
    }
  };

  const onCheckedAll = (e) => {
    setCheckedAll(e.target.checked);
  };

  const isCheckedItem = (value) => {
    return listSelected.findIndex((ele) => ele === value) >= 0;
  };

  useEffect(() => {
    if (checkedAll && preSelect.current.length < list.length)
      setListSelected(list.map((ele) => ele.value));
  }, [checkedAll]);

  useEffect(() => {
    onChange && onChange(listSelected);
  }, [listSelected]);

  return (
    <div
      style={
        isMobile
          ? { padding: "16px 16px 0 16px" }
          : {
              maxWidth: "800px",
              margin: "auto",
              marginBottom: "16px",
            }
      }
    >
      <Text fontSize={isMobile ? "14px" : "16px"} color="rgb(48, 64, 80)">
        {props.isRequired ? (
          <span>
            {props.label}
            <font className="required">※</font>
          </span>
        ) : (
          props.label
        )}
        {props.errorMessage && (
          <Text
            color="var(--amplify-components-fieldmessages-error-color)"
            fontSize="14px"
          >
            {props.errorMessage}
          </Text>
        )}
      </Text>
      {isMobile ? (
        <div style={{ marginTop: "12px" }}>
          <CheckboxField
            label={getLabel("subetesentaku")}
            paddingBottom="16px"
            checked={checkedAll}
            onChange={onCheckedAll}
          />
          {props?.list.map((ele, idx) => {
            return (
              <CheckboxField
                key={idx}
                checked={isCheckedItem(ele.value)}
                label={ele.option}
                marginBottom="24px"
                onChange={(e) => {
                  onChecked(ele.value, e.target.checked);
                }}
              />
            );
          })}
        </div>
      ) : (
        <>
          <CheckboxField
            label={getLabel("subetesentakusuru")}
            paddingTop="16px"
            checked={checkedAll}
            onChange={onCheckedAll}
          />
          <Grid
            width="100%"
            templateColumns="auto auto auto"
            // columnGap="16px"
            rowGap="16px"
            marginTop="16px"
          >
            {props?.list.map((ele, idx) => {
              return (
                <CheckboxField
                  key={idx}
                  checked={isCheckedItem(ele.value)}
                  label={ele.option}
                  onChange={(e) => {
                    onChecked(ele.value, e.target.checked);
                  }}
                />
              );
            })}
          </Grid>
        </>
      )}
    </div>
  );
}
