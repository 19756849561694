/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, IconCreate, IconDelete, Text } from "@aws-amplify/ui-react";
import PCPrimaryButtonSave from "./PCPrimaryButtonSave";
export default function PCButtonDeleteEdit(props) {
  const { overrides: overridesProp, ...rest } = props;
  const overrides = { ...overridesProp };
  return (
    <Flex
      gap="16px"
      direction="row"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "Flex")}
    >
      <Flex
        gap="8px"
        direction="row"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        height="40px"
        position="relative"
        border="1px SOLID rgba(174.00000482797623,179.000004529953,183.00000429153442,1)"
        borderRadius="4px"
        padding="7px 15px 7px 11px"
        {...getOverrideProps(overrides, "Flex.Flex[0]")}
      >
        <IconDelete
          width="24px"
          height="24px"
          shrink="0"
          overflow="hidden"
          position="relative"
          padding="0px 0px 0px 0px"
          color="rgba(13.000000175088644,26.000000350177288,38.0000015348196,1)"
          type="delete"
          fontSize="24px"
          {...getOverrideProps(overrides, "Flex.Flex[0].IconDelete[0]")}
        ></IconDelete>
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="700"
          color="rgba(13.000000175088644,26.000000350177288,38.0000015348196,1)"
          lineHeight="20px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          letterSpacing="0.49px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          children="削除"
          {...getOverrideProps(overrides, "Flex.Flex[0].Text[0]")}
        ></Text>
      </Flex>
      <PCPrimaryButtonSave
        display="flex"
        gap="8px"
        direction="row"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        height="40px"
        position="relative"
        borderRadius="4px"
        padding="8px 16px 8px 12px"
        backgroundColor="rgba(4.000000236555934,125.00000014901161,149.00000631809235,1)"
        {...getOverrideProps(overrides, "Flex.PCPrimaryButtonSave[0]")}
      >
        <IconCreate
          width="24px"
          height="24px"
          shrink="0"
          overflow="hidden"
          position="relative"
          padding="0px 0px 0px 0px"
          color="rgba(255,255,255,1)"
          type="create"
          fontSize="24px"
          {...getOverrideProps(
            overrides,
            "Flex.PCPrimaryButtonSave[0].IconCreate[0]"
          )}
        ></IconCreate>
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="700"
          color="rgba(255,255,255,1)"
          lineHeight="20px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          letterSpacing="0.49px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          children="編集"
          {...getOverrideProps(
            overrides,
            "Flex.PCPrimaryButtonSave[0].Text[0]"
          )}
        ></Text>
      </PCPrimaryButtonSave>
    </Flex>
  );
}
