import React, { useEffect, useState, useContext, useRef } from "react";
import { ListViewDetail, FABAchieveEditDelete } from "../page-components";
import { DataStore, Storage } from "aws-amplify";
import { LeavesLifeFuture } from "../models";
import { useParams } from "react-router-dom";
import {
  useInitializePage,
  getLabel,
  getCurrentUserAge,
  getListRangeAge,
} from "../utils/CommonUtils";
import { isShare, listObjects, queryData } from "../utils/ShareUtils";
import { PriorityLabel, Priority } from "../constants";
import UserContext from "../UserContext";
import { Flex } from "@aws-amplify/ui-react";
import { Tasseijoukyoushurui } from "../models";
/*
  yaritaikoto: String!
  jitsugennenreikigen: Int!
  tasseijoukyou: Boolean!
  category: String
  bikou: String
*/
export default function LifeFutureViewPage() {
  const navigate = useInitializePage("challenge", "title.detail");
  const [data, setData] = useState({
    yaritaikoto: "",
    jitsugennenreikigen: 0,
    tasseijoukyoushurui: "",
    yuusendo: Priority.KOU,
    episode: "",
    jitsugenstep: [],
    bikou: "",
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const dataRef = useRef();
  const [isAchieved, setIsAchieved] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const context = useContext(UserContext);
  const currentUserAge = getCurrentUserAge(context.user?.attributes.birthdate);
  const ageRangeOption = getListRangeAge(currentUserAge);
  const params = useParams();

  useEffect(() => {
    const getData = async () => {
      const dsData = await queryData(params, LeavesLifeFuture);
      if (dsData.length > 0) {
        setData(dsData[0]);
        dataRef.current = dsData[0];
        setIsAchieved(
          dataRef.current?.tasseijoukyoushurui === Tasseijoukyoushurui.TASSEI
        );
      }
      setIsLoaded(true);
    };
    getData();
  }, [params, params?.id]);

  useEffect(() => {
    if (data?.id) {
      listObjects(params, LeavesLifeFuture, data).then((res) => {
        const url = res.length > 0 ? res[0].key : null;
        setImageUrl(url);
      });
    }
  }, [data, params]);

  const quickAchieve = async () => {
    await DataStore.save(
      LeavesLifeFuture.copyOf(dataRef.current, (updated) => {
        updated.tasseijoukyoushurui = Tasseijoukyoushurui.TASSEI;
      })
    );
    navigate(-1, { replace: true });
  };

  const listLabel = [
    { label: "yaritaikoto", value: data?.yaritaikoto, isRequire: true },
    {
      type: "image",
      value: imageUrl,
      isSmall: true,
    },
    { label: "episode.bucketlist",  value: data?.episode, isMulti: true },
    {
      label: "jitsugennenreikigen",
      value: ageRangeOption.find(
        (ele, idx) =>
          (data?.jitsugennenreikigen >= Number(ele.value) &&
            data?.jitsugennenreikigen <= Number(ele.value) + 4) ||
          (idx === 0 && data?.jitsugennenreikigen <= Number(ele?.value)) ||
          (idx === ageRangeOption?.length - 1 &&
            data?.jitsugennenreikigen >= Number(ele?.value))
      )?.label,
      isRequire: true,
    },
    {
      label: "yuusendo",
      value: getLabel(PriorityLabel[data?.yuusendo]),
      isRequire: true,
    },
    {
      label: "tasseijoukyoushurui",
      value: getLabel("Tasseijoukyoushurui." + data?.tasseijoukyoushurui),
      isRequire: true,
    },
    { label: "bikou", value: data?.bikou, isMulti: true },
  ];

  const deleteData = async () => {
    await DataStore.delete(data);
    await Storage.remove(imageUrl, {
      level: "private",
    });
    navigate(-1, { replace: true });
  };

  return (
    <Flex>
      <ListViewDetail
        label={data?.yaritaikoto}
        list={isLoaded ? listLabel : []}
        isHideEdit={isShare(params)}
        onDelete={deleteData}
        isChallengeCate
      />
      {!isShare(params) && (
        <FABAchieveEditDelete
          isAchieved={isAchieved}
          quickAchieve={quickAchieve}
          onEdit={() => {
            navigate("edit");
            window.gtag("event", "Change", {
              event_category: "Edit",
              event_label: "やりたいこと変更開始",
            });
          }}
          onDelete={deleteData}
        />
      )}
    </Flex>
  );
}
