import { View } from "@aws-amplify/ui-react";
import { Storage } from "aws-amplify";
import { useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { Colum, LabelM, LabelS } from "../page-components";
import { getLabel, isImage, isVideo } from "../utils/CommonUtils";

const categoryToPath = {
  LeavesJibunshi: "/history/history",
  LeavesKakeizu: "/history/familytree",
  LeavesTaisetsunishitaihitohenomessage: "/will/message",
  LeavesYuigonshonitsuite: "/will/will",
};

export default function AttachmentPage() {
  const [listAttachment, setListAttachment] = useState([]);

  useEffect(() => {
    let mount = true;
    const getData = async () => {
      const listObject = [];
      const list = await Storage.list("", {
        level: "private",
      });
      if (list?.length > 0) {
        for (let i = 0; i < list?.length; i++) {
          const key = list[i]?.key;
          const parseKey = list[i]?.key.split("/");
          const mobilePath =
            parseKey[0] === "LeavesYuigonshonitsuite"
              ? `${categoryToPath[parseKey[0]]}`
              : `${categoryToPath[parseKey[0]]}/${parseKey[1]}`;
          const fileName = parseKey[parseKey.length - 1];
          const lastModified = list[i]?.lastModified.getTime();
          const url = await Storage.get(key, {
            level: "private",
          });
          let type = "other";

          if (isImage(fileName)) type = "image";
          if (isVideo(fileName)) type = "video";

          const result = {
            id: parseKey[1],
            url: url,
            mobilePath: mobilePath,
            fileName: fileName,
            lastModified: lastModified,
            pcPath: categoryToPath[parseKey[0]],
            type: type,
            category: parseKey[0],
          };
          listObject.push(result);
        }

        if (mount) setListAttachment(listObject);
      }
    };
    getData();
    return () => {
      mount = false;
    };
  }, []);

  const swapperContentStyle = useMemo(() => {
    return {
      overflowY: "auto",
      display: "block",
      marginTop: 0,
      maxHeight: "calc(100vh - 395px)",
      minHeight: 0,
    };
  }, []);

  const isHaveItem = (category) =>
    listAttachment?.findIndex((ele) => ele?.category === category) >= 0;

  const renderItem = (label, category) => {
    return (
      isHaveItem(category) && (
        <>
          <View marginBottom="16px">
            <LabelS label={getLabel(label)} />
            <div className="list-attachment">
              {listAttachment?.length > 0 &&
                listAttachment
                  ?.filter((ele) => ele?.category === category)
                  ?.sort((a, b) => b.lastModified - a.lastModified)
                  ?.map((ele) => {
                    return (
                      <Colum
                        key={ele?.id}
                        fileName={ele?.fileName}
                        fileSrc={ele?.url}
                        mobilePath={ele?.mobilePath}
                        pcPath={ele?.pcPath}
                        type={ele?.type}
                      />
                    );
                  })}
            </div>
          </View>
        </>
      )
    );
  };

  return (
    <div className={isMobile ? "" : "swapper"}>
      <LabelM label={getLabel("notonotenpufairuichiran")} marginBottom="-8px" />
      <div
        className={isMobile ? "" : "content-swapper hide-scroll"}
        style={isMobile ? {} : swapperContentStyle}
      >
        {renderItem("jibunshi", "LeavesJibunshi")}
        {renderItem(
          "taisetsunahitohenomesseji",
          "LeavesTaisetsunishitaihitohenomessage",
        )}
        {renderItem("kakeizu", "LeavesKakeizu")}

        {renderItem("igonsho", "LeavesYuigonshonitsuite")}
      </div>
    </div>
  );
}
