import { useRef } from "react";
import { isMobile, isTablet } from "react-device-detect";
import AChrome1 from "../assets/image/about/AChrome1.png";
import AChrome2 from "../assets/image/about/AChrome2.png";
import AChrome3 from "../assets/image/about/AChrome3.png";
import AChrome4 from "../assets/image/about/AChrome4.png";
import DataFull from "../assets/image/about/DataFull.png";
import HintFull from "../assets/image/about/HintFull.png";
import HistoryFull from "../assets/image/about/HistoryFull.png";
import IChrome1 from "../assets/image/about/IChrome1.png";
import IChrome10 from "../assets/image/about/IChrome10.png";
import IChrome11 from "../assets/image/about/IChrome11.png";
import IChrome12 from "../assets/image/about/IChrome12.png";
import IChrome2 from "../assets/image/about/IChrome2.png";
import IChrome3 from "../assets/image/about/IChrome3.png";
import IChrome4 from "../assets/image/about/IChrome4.png";
import IChrome5 from "../assets/image/about/IChrome5.png";
import IChrome6 from "../assets/image/about/IChrome6.png";
import IChrome7 from "../assets/image/about/IChrome7.png";
import IChrome8 from "../assets/image/about/IChrome8.png";
import IChrome9 from "../assets/image/about/IChrome9.png";
import ISafari1 from "../assets/image/about/ISafari1.png";
import ISafari2 from "../assets/image/about/ISafari2.png";
import ISafari3 from "../assets/image/about/ISafari3.png";
import ISafari4 from "../assets/image/about/ISafari4.png";
import WillFull from "../assets/image/about/WillFull.png";
import Check from "../assets/image/check_cardIC.png";
import DataSample from "../assets/image/DataSample.png";
import data_cardIC from "../assets/image/data_cardIC.png";
import HintSample from "../assets/image/HintSample.png";
import hint_cardIC from "../assets/image/hint_cardIC.png";
import HistorySample from "../assets/image/HistorySample.png";
import history_cardIC from "../assets/image/history_cardIC.png";
import Share from "../assets/image/Share_no_margin.png";
import WillSample from "../assets/image/WillSample.png";
import will_cardIC from "../assets/image/will_cardIC.png";
import { AboutHiraqlNote, PCAboutHiraqlNote } from "../ui-components";

export default function HowToPage() {
  const addHomeRef = useRef(null);
  const aChromeRef = useRef(null);
  const iChromeRef = useRef(null);
  const iSafariRef = useRef(null);

  const getTopPosition = (type) => {
    if (type === "AddHome" && addHomeRef.current) {
      return addHomeRef.current.offsetTop;
    } else if (type === "AChrome" && aChromeRef.current) {
      return aChromeRef.current.offsetTop;
    } else if (type === "IChrome" && iChromeRef.current) {
      return iChromeRef.current.offsetTop;
    } else if (type === "ISafari" && iSafariRef.current) {
      return iSafariRef.current.offsetTop;
    } else return 0;
  };

  const handleScroll = (type) => {
    const top = getTopPosition(type);

    window.scrollTo({
      left: 0,
      top: top + 60,
      behavior: "smooth",
    });
  };

  const mobileRef = {
    AddHomeText: {
      ref: addHomeRef,
      width: "100vw",
      transform: "translateX(calc((187.5px - 50vw)))",
    },
    AChromeTitle: {
      ref: aChromeRef,
      width: "100vw",
      transform: "translateX(calc((187.5px - 50vw)))",
    },
    IChromeTitle: {
      ref: iChromeRef,
      width: "100vw",
      transform: "translateX(calc((187.5px - 50vw)))",
    },
    ISafariTitle: {
      ref: iSafariRef,
      width: "100vw",
      transform: "translateX(calc((187.5px - 50vw)))",
    },
  };

  const pcRef = {
    AddHome: {
      ref: addHomeRef,
    },

    AChrome: {
      ref: aChromeRef,
    },

    IChrome: {
      ref: iChromeRef,
    },

    ISafari: {
      ref: iSafariRef,
    },
  };

  const checkIcon = {
    paths: [
      {
        d: "M5.6 10.6L1.4 6.4L0 7.8L5.6 13.4L17.6 1.4L16.2 0L5.6 10.6Z",
        fill: "#688649",
        fillRule: "nonzero",
        style: { transform: "translate(14.17%, 23.33%)" },
      },
    ],
  };

  const mobileIcon = {
    paths: [
      {
        d: "M15 0.00999999L5 0C3.9 0 3 0.9 3 2L3 5L5 5L5 4L15 4L15 18L5 18L5 17L3 17L3 20C3 21.1 3.9 22 5 22L15 22C16.1 22 17 21.1 17 20L17 2C17 0.9 16.1 0.00999999 15 0.00999999ZM7 14L9 14L9 7L2 7L2 9L5.59 9L0 14.59L1.41 16L7 10.41L7 14Z",
        fill: "#688649",
        fillRule: "nonzero",
        style: { transform: "translate(12.5%, 4.17%)" },
      },
    ],
  };

  const overridesProps = {
    HiraqlNoteHowto: {
      width: "100vw",
      transform: "translateX(calc((187.5px - 50vw)))",
    },
    "Hiraql NOTE\u306E\u4F7F\u3044\u65B9": {
      grow: 0,
      margin: "auto",
    },
    "\u30DB\u30FC\u30E0\u753B\u9762\u306B\u8FFD\u52A0": {
      transform: "translateX(calc((50vw - 187.5px)))",
    },
    "Android\u7AEF\u672B": {
      width: "fit-content",
      transform: "translateX(calc((50vw - 187.5px)))",
    },
    "Chrome\u3067Hiraql NOTE\u3092\u30DB\u30FC\u30E0\u753B\u9762\u306B\u8FFD\u52A0\u3059\u308B790918264":
      {
        width: "fit-content",
        transform: "translateX(calc((50vw - 187.5px)))",
      },
    "iOS\u7AEF\u672B790918266": {
      width: "fit-content",
      transform: "translateX(calc((50vw - 187.5px)))",
    },
    "Safari\u3067Hiraql NOTE\u3092\u30DB\u30FC\u30E0\u753B\u9762\u306B\u8FFD\u52A0\u3059\u308B":
      {
        width: "fit-content",
        transform: "translateX(calc((50vw - 187.5px)))",
      },
    "iOS\u7AEF\u672B790918260": {
      width: "fit-content",
      transform: "translateX(calc((50vw - 187.5px)))",
    },
    "Chrome\u3067Hiraql NOTE\u3092\u30DB\u30FC\u30E0\u753B\u9762\u306B\u8FFD\u52A0\u3059\u308B790918261":
      {
        width: "fit-content",
        transform: "translateX(calc((50vw - 187.5px)))",
      },
    Link_AddHome: {
      className: "pointer",
      onClick: () => handleScroll("AddHome"),
    },
    Link_AChrome: {
      className: "pointer",
      onClick: () => handleScroll("AChrome"),
    },
    Link_ISafari: {
      className: "pointer",
      onClick: () => handleScroll("ISafari"),
    },
    Link_IChrome: {
      className: "pointer",
      onClick: () => handleScroll("IChrome"),
    },
    data_cardIC: {
      src: data_cardIC,
    },
    DataFull: {
      src: DataFull,
    },
    DataSample: {
      src: DataSample,
    },
    will_cardIC: {
      src: will_cardIC,
    },
    WillFull: {
      src: WillFull,
    },
    WillSample: {
      src: WillSample,
    },
    history_cardIC: {
      src: history_cardIC,
    },
    HistoryFull: {
      src: HistoryFull,
    },
    HistorySample: {
      src: HistorySample,
    },
    hint_cardIC: {
      src: hint_cardIC,
    },
    HintFull: {
      src: HintFull,
    },
    HintSample: {
      src: HintSample,
    },
    Check: {
      src: Check,
    },
    Share: {
      src: Share,
    },
    "\u300C\u307E\u305A\u306F\u3053\u306E\u9805\u76EE\u3092\u8A18\u5165\u3057\u3066\u304A\u3051\u3070\u3001\u201D\u3082\u3057\u3082\u306E\u6642\u201D\u306B\u5B89\u5FC3\uFF01\u52A9\u304B\u308B\uFF01\u300D\u3068\u3044\u3046\u9805\u76EE\u3092\u53B3\u9078\u3057\u307E\u3057\u305F\u3002\u4F55\u304B\u3089\u59CB\u3081\u3088\u3046\u304B...\u3068\u9014\u65B9\u306B\u66AE\u308C\u3066\u3044\u308B\u65B9\u306F\u3001\u307E\u305A\u306F\u3053\u3053\u304B\u3089\u59CB\u3081\u3066\u307F\u3066\u304F\u3060\u3055\u3044\u3002790918304":
      {
        children:
          "HIRAQL NOTEに記入したデータや意思を家族など大切な人にシェアできます。誰に、どの情報を、いつ共有するのか設定をしましょう。設定をしておくと”もしもの時”に確実にご自身の意思が伝わり、大切な人を助けることができます。\n\n記入ができた項目から気軽に設定をはじめてみましょう。公開日は未来日付に設定できます。また、設定後も編集は可能です。",
        textAlign: "justify",
      },
    Share777517832: {
      src: Share,
    },
    "\u300C\u307E\u305A\u306F\u3053\u306E\u9805\u76EE\u3092\u8A18\u5165\u3057\u3066\u304A\u3051\u3070\u3001\u201D\u3082\u3057\u3082\u306E\u6642\u201D\u306B\u5B89\u5FC3\uFF01\u52A9\u304B\u308B\uFF01\u300D\u3068\u3044\u3046\u9805\u76EE\u3092\u53B3\u9078\u3057\u307E\u3057\u305F\u3002\u4F55\u304B\u3089\u59CB\u3081\u3088\u3046\u304B...\u3068\u9014\u65B9\u306B\u66AE\u308C\u3066\u3044\u308B\u65B9\u306F\u3001\u307E\u305A\u306F\u3053\u3053\u304B\u3089\u59CB\u3081\u3066\u307F\u3066\u304F\u3060\u3055\u3044\u3002777517831":
      {
        children:
          "HIRAQL NOTEに記入したデータや意思を家族など大切な人にシェアできます。誰に、どの情報を、いつ共有するのか設定をしましょう。設定をしておくと”もしもの時”に確実にご自身の意思が伝わり、大切な人を助けることができます。\n\n記入ができた項目から気軽に設定をはじめてみましょう。公開日は未来日付に設定できます。また、設定後も編集は可能です。",
      },
    AChrome1: {
      src: AChrome1,
    },
    AChrome2: {
      src: AChrome2,
    },
    AChrome3: {
      src: AChrome3,
    },
    AChrome4: {
      src: AChrome4,
    },
    IChrome1: {
      src: IChrome1,
    },
    IChrome2: {
      src: IChrome2,
    },
    IChrome3: {
      src: IChrome3,
    },
    IChrome4: {
      src: IChrome4,
    },
    IChrome5: {
      src: IChrome5,
    },
    IChrome6: {
      src: IChrome6,
    },
    IChrome7: {
      src: IChrome7,
    },
    IChrome8: {
      src: IChrome8,
    },
    IChrome9: {
      src: IChrome9,
    },
    IChrome10: {
      src: IChrome10,
    },
    IChrome11: {
      src: IChrome11,
    },
    IChrome12: {
      src: IChrome12,
    },
    ISafari1: {
      src: ISafari1,
    },
    ISafari2: {
      src: ISafari2,
    },
    ISafari3: {
      src: ISafari3,
    },
    ISafari4: {
      src: ISafari4,
    },
    Icon790918118: { ...checkIcon },
    Icon790918122: { ...checkIcon },
    Icon790918137: { ...checkIcon },
    Icon790918141: { ...checkIcon },
    Icon790918145: { ...checkIcon },
    Icon790918160: { ...checkIcon },
    Icon790918164: { ...checkIcon },
    Icon613016059: { ...checkIcon },
    Icon613016073: { ...checkIcon },
    Icon613016052: { ...checkIcon },
    Icon613016069: { ...checkIcon },
    Icon613016084: { ...checkIcon },
    Icon613016094: { ...checkIcon },
    Icon613016098: { ...checkIcon },
    Icon613016132: { ...mobileIcon },
    Icon790918269: {
      transform: "translateX(calc((50vw - 187.5px)))",
      ...mobileIcon,
    },
    Icon613016163: { ...checkIcon },
    Icon614216269: { ...checkIcon },
    Icon613016215: { ...checkIcon },
    ...(isMobile && !isTablet ? mobileRef : pcRef),
  };

  return isMobile && !isTablet ? (
    <AboutHiraqlNote
      margin="auto"
      marginBottom="16px"
      overrides={overridesProps}
    />
  ) : (
    <div className={isTablet ? "tablet-howto" : ""}>
      <PCAboutHiraqlNote
        margin={isTablet ? "" : "auto"}
        marginBottom="32px"
        overrides={overridesProps}
      />
    </div>
  );
}
