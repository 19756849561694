import { View, Text } from '@aws-amplify/ui-react';
import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { isMobile, isTablet } from 'react-device-detect';

export default function AnswerButton(props) {

    const getButtonStatus = (answer) => {

        const statusTable = {
            "1" : "全く\n当てはまらない",
            "2" : "ほとんど\n当てはまらない",
            "3" : "あまり\n当てはまらない",
            "4" : "どちらとも\nいえない",
            "5" : "少し\n当てはまる",
            "6" : "かなり\n当てはまる",
            "7" : "非常によく\n当てはまる"
        };

        const statusTable_2_3 = {
            "1" : "全く\n当てはまらない",
            "2" : "当てはまらない",
            "3" : "どちらかといえば\n当てはまらない",
            "4" : "どちらかといえば\n当てはまる",
            "5" : "当てはまる",
            "6" : "非常によく\n当てはまる"
        };

        if ( props.page != 2 && props.page != 3 ) {
            return statusTable[answer];
        }
        else {
            return statusTable_2_3[answer];
        }
    };

    const answerId = Cookies.get('mindtypeAnswerId');
    const answerKey = props.page + '-' + props.questionNo;

    const handlerClick = async(param) => {
       props.setAnswerTable( (prestate) => {
          let newState = { ...prestate };
          delete newState[answerKey];
          newState[answerKey] = param.answer;
          return newState;
       });
    };

    // ページによってボタン幅調整
    const getButtonWidth = () => {
        if ( isMobile || isTablet ) {
            if ( props.page != 2 && props.page != 3 ) {
                return "35px";
            }
            else {
                return "42px";
            }
        }
        else {
            if ( props.page != 2 && props.page != 3 ) {
                return "60px";
            }
            else {
                return "70px";
            }
        }
    };

    return (
      <View
          width={getButtonWidth()}
          height="33px"
          display="block"
          position="relative"
          top="28.57%"
          bottom="0%"
          right="0%"
          margin={(isMobile || isTablet) ? '0 8px 0 0' : '0 2px 0 0'}
      >
          <Text
              fontFamily="Inter"
              fontSize={(isMobile || isTablet) ? '7px' : '8px'}
              fontWeight="400"
              color="rgba(28,26,29,1)"
              lineHeight={(isMobile || isTablet) ? '8px' : '10px'}
              textAlign="center"
              display="block"
              width={getButtonWidth()}
              position="absolute"
              top="109.23%"
              bottom="-40%"
              left="0%"
              whiteSpace="pre-wrap"
          >
            {getButtonStatus(props.answer)}
          </Text>
    <View
        width={getButtonWidth()}
        height="30px"
        display="block"
        position="relative"
        onClick={() => handlerClick({ page: props.page, questionNo: props.questionNo, answer: props.answer })}
    >
        <View
            width={getButtonWidth()}
            height="30px"
            display="block"
            position="absolute"
            borderRadius="10px"
            className={props.answerTable[answerKey] == props.answer ? "MindTypeAnswerHighlighted" : "MindTypeAnswerDefault" }
        />
        <Text
            fontFamily="Shippori Mincho"
            fontSize="14px"
            fontWeight="600"
            lineHeight="26px"
            textAlign="center"
            display="block"
            width={getButtonWidth()}
            height="26px"
            position="absolute"
            whiteSpace="pre-wrap"
            className={props.answerTable[answerKey] == props.answer ? "MindTypeAnswerHighlighted" : "MindTypeAnswerDefault" }
        >
            {props.answer}
        </Text>
    </View>
    </View>
   );
}
