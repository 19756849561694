/* eslint-disable import/no-anonymous-default-export */
import { useEffect, useState } from "react";
import { ListSingleLineCPhoto } from "../ui-components";
import { getObject } from "../utils/ShareUtils";

export default function (props) {
  const splitedNengetsu = props.date.split("-");
  const formatedNengetsu = splitedNengetsu[0] + "年" + parseInt(splitedNengetsu[1]) + "月" + parseInt(splitedNengetsu[2]) + "日";
  const [img, setImg] = useState(null);

  useEffect(() => {
    if (props.imageSrc) {
      const getUrl = async () => {
        const { fileName, url } = await getObject(props.imageSrc);
        if (isImage(fileName)) {
          setImg(url);
        }
      };
      getUrl();
    }
  }, [props.imageSrc]);

  const isImage = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "png":
      case "jpg":
      case "jpeg":
      case "gif":
      case "bmp":
        return true;
    }
    return false;
  };
  const getExtension = (filename) => {
    if (filename) {
      var parts = filename.split(".");
      return parts[parts.length - 1];
    } else {
      return "";
    }
  };
  return (
    <ListSingleLineCPhoto
      margin="16px"
      width="auto"
      onClick={props.onClick}
      className="pointer"
      overrides={{
        List: {
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        },
        date: {
          width: "120px",
          children: formatedNengetsu,
        },
        Label: {
          className: "text-overflow-ellipsis",
          maxWidth: "calc(100vw - 100px)",
          whiteSpace: "pre",
          overflow: "hidden",
          display: "block",
          children: props.label,
        },
        Image: {
          display: img ? "block" : "none",
          width: "auto",
          src: img,
        },
      }}
    />
    // <ListSingleLineC
    //   className="pccomponent"
    //   width="auto"
    //   margin="0 16px 0 16px"
    //   onClick={props.onClick}
    //   className="pointer"
    //   overrides={{
    //     Flex: {
    //       margin: "16px",
    //       // className: "pointer",
    //     },
    //     "Flex.Flex[0].Flex[0].Text[0]": {
    //       width: "120px",
    //       children: formatedNengetsu,
    //     },
    //     "Flex.Flex[0].Flex[0].Text[1]": {
    //       className: "text-overflow-ellipsis",
    //       maxWidth: "calc(100vw - 100px)",
    //       whiteSpace: "pre",
    //       overflow: "hidden",
    //       display: "block",
    //       children: props.label,
    //     },
    //   }}
    // />
  );
}
