import { DataStore } from "aws-amplify";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { LeavesCreditcard } from "../models";
import { LeavesKinyuuShisan } from "../models";
import { LeavesKouza } from "../models";
import {
  ListSelectContent,
  ListSingleLineA,
  ListSingleLineD,
} from "../page-components";
import { getLabel, useInitializePage } from "../utils/CommonUtils";
import { isShare, queryDatas } from "../utils/ShareUtils";

function ClaimPage() {
  const navigate = useInitializePage("kouzajidouhikiotoshi");
  const [listClaim, setListClaim] = useState([]);
  const [selected, setSelected] = useState(null);
  const [hikiotoshikinyuukikan, setHikiotoshikinyuukikan] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const params = useParams();

  const handledChangeActive = (item) => {
    setSelected(item);
  };

  useEffect(() => {
    if (listClaim?.length > 0) {
      setSelected(listClaim[0]);
    } else {
      setSelected(null);
    }
  }, [listClaim]);

  const deleteData = useCallback(async (claimData) => {
    await DataStore.delete(claimData);
    const modelData = await DataStore.query(LeavesKouza);
    setListClaim(modelData);
  }, []);

  useEffect(() => {
    const getData = async () => {
      if (selected?.hikiotoshikinyuukikan)
        setHikiotoshikinyuukikan(selected?.hikiotoshikinyuukikan);
      else if (selected?.hikiotoshisakiKinyuuShisanYochokin) {
        const contact = await DataStore.query(LeavesKinyuuShisan, (c) =>
          c.id("eq", selected?.hikiotoshisakiKinyuuShisanYochokin),
        );
        contact.length > 0 && setHikiotoshikinyuukikan(contact[0]?.kinyuukikan);
      } else if (selected?.hikiotoshisakiCreditcard) {
        const contact = await DataStore.query(LeavesCreditcard, (c) =>
          c.id("eq", selected?.hikiotoshisakiCreditcard),
        );
        contact.length > 0 &&
          setHikiotoshikinyuukikan(contact[0]?.creditcardmei);
      } else setHikiotoshikinyuukikan(null);
    };
    getData();
  }, [selected]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const modelData = await queryDatas(params, LeavesKouza);
      if (modelData?.length > 0) setListClaim(modelData);
      setIsLoaded(true);
    };
    getData();
  }, []);

  return (
    <ListSelectContent
      label={getLabel("kouzajidouhikiotoshi")}
      mobileList={listClaim.map((ele) => {
        return (
          <ListSingleLineA
            key={ele?.id}
            label={ele.hikiotoshikoumoku}
            onClick={() => navigate(ele?.id)}
          />
        );
      })}
      description={{
        type: "data",
        category: "kouzajidouhikiotoshi",
      }}
      isShared={isShare(params)}
      addButtonLabel={
        getLabel("kouzajidouhikiotoshi") + getLabel("button.addsuffix")
      }
      onAddClick={() => {
        navigate("add");
        window.gtag("event", "FirstWrite", {
          event_category: "Edit",
          event_label: "定期支払い書込開始",
        });
      }}
      pcListItem={listClaim.map((ele, index) => {
        return (
          <ListSingleLineD
            key={ele?.id}
            label={ele?.hikiotoshikoumoku}
            selected={selected?.id === ele?.id}
            onClick={() => {
              handledChangeActive(ele);
            }}
          />
        );
      })}
      selectedItemList={[
        {
          isEmptyHide: true,
          label: getLabel("hikiotoshikoumoku"),
          value: selected?.hikiotoshikoumoku,
        },
        {
          isEmptyHide: true,
          label: getLabel("hikiotoshikinyuukikan"),
          value: hikiotoshikinyuukikan,
        },
        {
          isEmptyHide: true,
          label: getLabel("hikiotoshibi"),
          value: selected?.hikiotoshibi,
        },
        {
          isEmptyHide: true,
          label: getLabel("bikou"),
          value: selected?.bikou,
        },
      ]}
      selectedItemLabel={selected?.hikiotoshikoumoku}
      onDelete={() => deleteData(selected)}
      onEdit={() => {
        navigate(selected?.id + "/edit");
        window.gtag("event", "Change", {
          event_category: "Edit",
          event_label: "定期支払い変更開始",
        });
      }}
      isLoaded={isLoaded}
    />
  );
}

export default ClaimPage;
