import { DataStore, Storage } from "aws-amplify";
import { useContext, useEffect, useMemo, useState } from "react";
import { Priority, PriorityLabel } from "../constants";
import { Tasseijoukyoushurui } from "../models";
import { LeavesShuukan } from "../models";
import { LeavesLifeFuture } from "../models";
import { ListField } from "../page-components";
import UserContext from "../UserContext";
import {
  getLabel,
  isEmpty,
  isNotEmpty,
  useInitializePage,
  getCurrentUserAge,
  getListRangeAge,
} from "../utils/CommonUtils";
import { usePrompt } from "../utils/Prompt";

function LifeFutureAddPage() {
  const navigate = useInitializePage("yaritaikoto", "title.add");
  const context = useContext(UserContext);
  const srcCate = window.history.state?.usr;
  const currentUserAge = getCurrentUserAge(context.user?.attributes.birthdate);
  const ageRangeOption = getListRangeAge(currentUserAge);
  const [category, setCategory] = useState({
    yaritaikotoshurui: srcCate ? srcCate : "challenge",
  });
  const [isAdded, setIsAdded] = useState(false);
  const [update, setUpdate] = useState(false);
  const [habitData, setHabitData] = useState({
    yaritaikoto: "",
    yuusendo: "",
    episode: "",
    bikou: "",
  });
  const [habitDataError, setHabitDataError] = useState({
    yaritaikoto: "",
  });
  const [challengeData, setChallengeData] = useState({
    yaritaikoto: "",
    jitsugennenreikigen: "",
    tasseijoukyoushurui: "",
    yuusendo: "",
    episode: "",
    jitsugenstep: [],
    bikou: "",
  });
  const [challengeDataError, setChallengeDataError] = useState({
    yaritaikoto: "",
  });

  const habitListField = useMemo(() => {
    return [
      {
        type: "textField",
        label: "yaritaikoto",
        placeHolder: "maiasajoggingsurunado",
        isRequire: true,
        value: habitData?.yaritaikoto,
        hasError: isNotEmpty(habitDataError?.yaritaikoto),
        errorMessage: habitDataError?.yaritaikoto,
      },
      {
        key: "image-habit",
        type: "image",
        label: "imagegazou",
        defaultFile: habitData?.imagegazou,
      },
      {
        type: "textArea",
        label: "episode",
        subLabel: ".bucketlist",
        value: habitData?.episode,
        placeHolder: "yaritairiyuunado.habit",
        isRequire: true,
        hasError: isNotEmpty(habitDataError?.episode),
        errorMessage: habitDataError?.episode,
      },
      {
        type: "selectField",
        label: "yuusendo",
        isRequire: true,
        value: habitData?.yuusendo,
        hasError: isNotEmpty(habitDataError?.yuusendo),
        errorMessage: habitDataError?.yuusendo,
        options: [
          <option key={"habitData.yuusendo.unselect"} value={""}>
            {getLabel("list.select")}
          </option>,
          <option key={Priority.KOU} value={Priority.KOU}>
            {getLabel(PriorityLabel.KOU)}
          </option>,
          <option key={Priority.CHUU} value={Priority.CHUU}>
            {getLabel(PriorityLabel.CHUU)}
          </option>,
          <option key={Priority.TEI} value={Priority.TEI}>
            {getLabel(PriorityLabel.TEI)}
          </option>,
        ],
      },
      { type: "textArea", value: habitData?.bikou, label: "bikou" },
    ];
  }, [
    habitData?.bikou,
    habitData?.episode,
    habitData?.imagegazou,
    habitData?.yaritaikoto,
    habitData?.yuusendo,
    habitDataError?.episode,
    habitDataError?.yaritaikoto,
    habitDataError?.yuusendo,
  ]);

  const challengeListField = useMemo(() => {
    return [
      {
        type: "textField",
        label: "yaritaikoto",
        placeHolder: "fujisanninoborunado",
        value: challengeData?.yaritaikoto,
        isRequire: true,
        hasError: isNotEmpty(challengeDataError?.yaritaikoto),
        errorMessage: challengeDataError?.yaritaikoto,
      },
      {
        key: "image-challenge",
        type: "image",
        label: "imagegazou",
        defaultFile: challengeData?.imagegazou,
      },
      {
        type: "textArea",
        label: "episode",
        subLabel: ".bucketlist",
        value: challengeData?.episode,
        placeHolder: "yaritairiyuunado.challenge",
        isRequire: true,
        hasError: isNotEmpty(challengeDataError?.episode),
        errorMessage: challengeDataError?.episode,
      },
      {
        type: "selectField",
        label: "jitsugennenreikigen",
        value: challengeData?.jitsugennenreikigen,
        isRequire: true,
        hasError: isNotEmpty(challengeDataError?.jitsugennenreikigen),
        errorMessage: challengeDataError?.jitsugennenreikigen,
        options: [
          <option key={"challengeData.jitsugennenreikigen.unselect"} value="">
            {getLabel("list.select")}
          </option>,
          ...ageRangeOption?.map((ele) => {
            return (
              <option key={ele?.value} value={ele?.value}>
                {ele?.label}
              </option>
            );
          }),
        ],
        isSelect: true,
      },
      {
        type: "selectField",
        label: "yuusendo",
        value: challengeData?.yuusendo,
        isRequire: true,
        hasError: isNotEmpty(challengeDataError?.yuusendo),
        errorMessage: challengeDataError?.yuusendo,
        options: [
          <option key={"challengeData.yuusendo.unselect"} value={""}>
            {getLabel("list.select")}
          </option>,
          <option key={Priority.KOU} value={Priority.KOU}>
            {getLabel(PriorityLabel.KOU)}
          </option>,
          <option key={Priority.CHUU} value={Priority.CHUU}>
            {getLabel(PriorityLabel.CHUU)}
          </option>,
          <option key={Priority.TEI} value={Priority.TEI}>
            {getLabel(PriorityLabel.TEI)}
          </option>,
        ],
      },
      {
        type: "selectField",
        label: "tasseijoukyoushurui",
        isRequire: true,
        value: challengeData?.tasseijoukyoushurui,
        hasError: isNotEmpty(challengeDataError?.tasseijoukyoushurui),
        errorMessage: challengeDataError?.tasseijoukyoushurui,
        options: [
          <option key={"Tasseijoukyoushurui.unselect"} value={""}>
            {getLabel("list.select")}
          </option>,
          ...Object.keys(Tasseijoukyoushurui).map((type) => {
            return (
              <option key={type} value={type}>
                {getLabel("Tasseijoukyoushurui." + type)}
              </option>
            );
          }),
        ],
      },
      { type: "textArea", value: challengeData?.bikou, label: "bikou" },
    ];
  }, [
    ageRangeOption,
    challengeData?.bikou,
    challengeData?.episode,
    challengeData?.imagegazou,
    challengeData?.jitsugennenreikigen,
    challengeData?.tasseijoukyoushurui,
    challengeData?.yaritaikoto,
    challengeData?.yuusendo,
    challengeDataError?.episode,
    challengeDataError?.jitsugennenreikigen,
    challengeDataError?.tasseijoukyoushurui,
    challengeDataError?.yaritaikoto,
    challengeDataError?.yuusendo,
  ]);

  const categoryField = useMemo(() => {
    if (category?.yaritaikotoshurui === "habit") {
      return habitListField;
    } else {
      return challengeListField;
    }
  }, [category?.yaritaikotoshurui, habitListField, challengeListField]);

  const listField = [
    {
      type: "ratioGroup",
      label: "yaritaikotoshurui",
      value: category?.yaritaikotoshurui,
      setStateRatio: setCategory,
      options: [
        {
          id: 0,
          text: getLabel("challenge"),
          value: "challenge",
        },
        {
          id: 1,
          text: getLabel("shuukan"),
          value: "habit",
        },
      ],
    },
    ...categoryField,
  ];

  const handleAddHabit = async () => {
    let valid = true;
    const error = { ...habitDataError };
    if (isEmpty(habitData?.yaritaikoto)) {
      error.yaritaikoto = getLabel("error.required");
      valid = false;
    } else {
      error.yaritaikoto = "";
    }
    if (isEmpty(habitData?.yuusendo)) {
      error.yuusendo = getLabel("error.required");
      valid = false;
    } else {
      error.yuusendo = "";
    }
    if (isEmpty(habitData?.episode)) {
      error.episode = getLabel("error.required");
      valid = false;
    } else {
      error.episode = "";
    }

    setHabitDataError(error);

    if (valid) {
      setIsAdded(true);
      const saved = await DataStore.save(
        new LeavesShuukan({
          yaritaikoto: habitData.yaritaikoto,
          yuusendo: habitData.yuusendo,
          episode: habitData.episode,
          bikou: habitData.bikou,
          leavesusersID: context.leavesUser.id,
        })
      );
      if (habitData?.imagegazou) {
        await Storage.put(
          `LeavesShuukan/${saved.id}/${habitData?.imagegazou?.name}`,
          habitData?.imagegazou,
          {
            contentType: habitData.imagegazou.type,
            level: "private",
          }
        );
      }
      navigate("/will/tobelist", { replace: true });
    }
  };

  const handleAddchallenge = async () => {
    let valid = true;
    const error = { ...challengeDataError };
    if (isEmpty(challengeData?.yaritaikoto)) {
      error.yaritaikoto = getLabel("error.required");
      valid = false;
    } else {
      error.yaritaikoto = "";
    }
    if (isEmpty(challengeData?.jitsugennenreikigen)) {
      error.jitsugennenreikigen = getLabel("error.required");
      valid = false;
    } else {
      error.jitsugennenreikigen = "";
    }
    if (isEmpty(challengeData?.yuusendo)) {
      error.yuusendo = getLabel("error.required");
      valid = false;
    } else {
      error.yuusendo = "";
    }
    if (isEmpty(challengeData?.tasseijoukyoushurui)) {
      error.tasseijoukyoushurui = getLabel("error.required");
      valid = false;
    } else {
      error.tasseijoukyoushurui = "";
    }
    if (isEmpty(challengeData?.episode)) {
      error.episode = getLabel("error.required");
      valid = false;
    } else {
      error.episode = "";
    }

    setChallengeDataError(error);

    if (valid) {
      setIsAdded(true);
      const saved = await DataStore.save(
        new LeavesLifeFuture({
          yaritaikoto: challengeData?.yaritaikoto,
          jitsugennenreikigen: Number(challengeData?.jitsugennenreikigen),
          tasseijoukyoushurui: challengeData?.tasseijoukyoushurui,
          yuusendo: challengeData?.yuusendo,
          episode: challengeData?.episode,
          bikou: challengeData?.bikou,
          tasseijoukyou:
            challengeData?.tasseijoukyoushurui === Tasseijoukyoushurui.TASSEI,
          leavesusersID: context.leavesUser.id,
        })
      );
      if (challengeData?.imagegazou) {
        await Storage.put(
          `LeavesLifeFuture/${saved.id}/${challengeData?.imagegazou?.name}`,
          challengeData?.imagegazou,
          {
            contentType: challengeData.imagegazou.type,
            level: "private",
          }
        );
      }
      navigate("/will/bucketlist", { replace: true });
    }
  };

  const addData = async () => {
    if (category?.yaritaikotoshurui === "habit") await handleAddHabit();
    else await handleAddchallenge();
  };

  usePrompt((location, action) => {
    if (isAdded) return true;
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update, isAdded);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <ListField
        label={getLabel("shinkitsuika")}
        list={listField}
        setState={
          category?.yaritaikotoshurui === "habit"
            ? setHabitData
            : setChallengeData
        }
        setUpdate={setUpdate}
        onSave={addData}
        onCancel={() => navigate(-1, { replace: true })}
        isDisabled={isAdded}
      />
    </>
  );
}

export default LifeFutureAddPage;
