import { DataStore } from "aws-amplify";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LeavesCreditcard } from "../models";
import { ListField } from "../page-components";
import {
  getLabel,
  isEmpty,
  isNotEmpty,
  useInitializePage,
} from "../utils/CommonUtils";
import { usePrompt } from "../utils/Prompt";

function CardEditPage() {
  const navigate = useInitializePage("creditcard", "title.update");
  const [data, setData] = useState();

  const [update, setUpdate] = useState(false);
  const [cardData, setCardData] = useState({
    creditcardmei: "",
    cardbrand: "",
    cardhakkougaisha: "",
    bikou: "",
  });
  const [cardDataError, setCardDataError] = useState({
    creditcardmeiError: "",
    cardbrandError: "",
    cardhakkougaishaError: "",
    bikouError: "",
  });
  const [isUpdated, setIsUpdated] = useState(false);

  let params = useParams();

  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const data = await DataStore.query(LeavesCreditcard, (c) =>
        c.id("eq", params.id),
      );

      if (data?.length > 0) {
        setData(data[0]);
        setCardData({
          creditcardmei: data[0].creditcardmei,
          cardbrand: data[0].cardbrand,
          cardhakkougaisha: data[0].cardhakkougaisha,
          bikou: data[0].bikou,
        });
      }
    };

    getData();
  }, [params.id]);

  const listField = [
    {
      type: "textField",
      label: "creditcardmei",
      placeHolder: "toukyucardnado",
      isRequire: true,
      hasError: isNotEmpty(cardDataError.creditcardmeiError),
      errorMessage: cardDataError.creditcardmeiError,
      value: cardData.creditcardmei,
    },
    {
      type: "textField",
      label: "cardbrand",
      placeHolder: "visa",
      value: cardData.cardbrand,
    },
    {
      type: "textField",
      label: "cardhakkougaisha",
      value: cardData.cardhakkougaisha,
    },
    {
      type: "textArea",
      label: "bikou",
      subLabel: ".creditcard",
      value: cardData.bikou,
    },
  ];

  const updateDataCard = useCallback(async () => {
    let valid = true;
    if (isEmpty(cardData.creditcardmei)) {
      setCardDataError({
        ...cardDataError,
        creditcardmeiError: getLabel("error.required"),
      });
      valid = false;
    }
    if (valid) {
      setIsUpdated(true);
      await DataStore.save(
        LeavesCreditcard.copyOf(data, (updated) => {
          updated.creditcardmei = cardData.creditcardmei;
          updated.cardbrand = cardData.cardbrand;
          updated.cardhakkougaisha = cardData.cardhakkougaisha;
          updated.bikou = cardData.bikou;
        }),
      );
      window.gtag("event", "ChangeComplete", {
        event_category: "Edit",
        event_label: "クレジットカード変更完了",
      });
      navigate(-1, { replace: true });
    }
  }, [
    cardData.bikou,
    cardData.cardbrand,
    cardData.cardhakkougaisha,
    cardData.creditcardmei,
    cardDataError,
    data,
    navigate,
  ]);

  return (
    <ListField
      label={getLabel("button.update")}
      list={listField}
      setState={setCardData}
      setUpdate={setUpdate}
      onSave={updateDataCard}
      onCancel={() => navigate(-1, { replace: true })}
      isDisabled={isUpdated}
    />
  );
}

export default CardEditPage;
