import { DataStore, Storage } from "aws-amplify";
import { useCallback, useEffect, useRef, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useParams } from "react-router-dom";
import { LeavesMemo } from "../models";
import { ListViewDetail } from "../page-components";
import { useInitializePage } from "../utils/CommonUtils";
import { listObjects, queryData } from "../utils/ShareUtils";

export default function MemoViewPage() {
  const navigate = useInitializePage("jiyucho", "title.detail");
  const [memoData, setMemoData] = useState({
    title: "",
    memoCategory: "",
  });
  const [imageUrl, setImageUrl] = useState(null);
  const data = useRef();
  const params = useParams();

  const deleteData = useCallback(async () => {
    await DataStore.delete(data.current);
    await Storage.remove(imageUrl, {
      level: "private",
    });
    navigate(-1, { replace: true });
  }, [imageUrl, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const dataQuery = await queryData(params, LeavesMemo);
      if (dataQuery?.length > 0) {
        data.current = dataQuery[0];
        setMemoData({
          ...dataQuery[0],
          memoCategory: dataQuery[0].memo,
        });
      }
    };
    getData();
  }, [params]);

  useEffect(() => {
    if (memoData.id) {
      listObjects(params, LeavesMemo, memoData).then((res) => {
        const url = res.length > 0 ? res[0].key : null;
        setImageUrl(url);
      });
    }
  }, [memoData, params]);

  const listLabel = [
    {
      label: "title",
      value: memoData?.title,
      isRequire: true,
    },
    {
      label: "memoCategory",
      value: memoData?.memoCategory,
      isMulti: true,
    },
    {
      type: "image",
      value: imageUrl,
    },
  ];

  return (
    <ListViewDetail
      label={memoData?.title}
      list={listLabel}
      onDelete={deleteData}
      onEdit={() => {
        navigate("edit");
      }}
    />
  );
}
