import { DataStore } from "aws-amplify";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LeavesLoginId } from "../models";
import { ListField } from "../page-components";
import {
  getLabel,
  isEmpty,
  isNotEmpty,
  useInitializePage,
} from "../utils/CommonUtils";
import { usePrompt } from "../utils/Prompt";

function LoginIDEditPage() {
  const navigate = useInitializePage("kakushuserviceid", "title.update");
  const [data, setData] = useState();
  const [loginIDData, setLoginIDData] = useState({
    servicemei: "",
    siteurl: "",
    loginid: "",
    bikou: "",
  });
  const [loginIDDataError, setLoginIDDataError] = useState({
    servicemeiError: "",
    siteurlError: "",
    loginidError: "",
    bikouError: "",
  });
  const [isUpdated, setIsUpdated] = useState(false);
  let params = useParams();
  const [update, setUpdate] = useState(false);
  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const data = await DataStore.query(LeavesLoginId, (c) =>
        c.id("eq", params.id),
      );
      if (data?.length > 0) {
        setData(data[0]);
        setLoginIDData({
          servicemei: data[0].servicemei,
          siteurl: data[0].siteurl,
          loginid: data[0].loginid,
          bikou: data[0].bikou,
        });
      }
    };
    getData();
  }, [params.id]);

  const listField = [
    {
      type: "textField",
      label: "servicemei",
      value: loginIDData?.servicemei,
      isRequire: true,
      placeHolder: "facebooknado",
      hasError: isNotEmpty(loginIDDataError.servicemeiError),
      errorMessage: loginIDDataError.servicemeiError,
    },
    {
      type: "textArea",
      label: "siteurl",
      value: loginIDData?.siteurl,
    },
    {
      type: "textField",
      label: "loginid",
      value: loginIDData?.loginid,
    },
    {
      type: "textArea",
      label: "bikou",
      value: loginIDData?.bikou,
    },
  ];

  const updateDataLogin = useCallback(async () => {
    let valid = true;
    if (isEmpty(loginIDData.servicemei)) {
      setLoginIDDataError({
        ...loginIDDataError,
        servicemeiError: getLabel("error.required"),
      });
      valid = false;
    }
    if (valid) {
      setIsUpdated(true);
      await DataStore.save(
        LeavesLoginId.copyOf(data, (updated) => {
          updated.servicemei = loginIDData.servicemei;
          updated.siteurl = loginIDData.siteurl;
          updated.loginid = loginIDData.loginid;
          updated.bikou = loginIDData.bikou;
        }),
      );
      window.gtag("event", "ChangeComplete", {
        event_category: "Edit",
        event_label: "WEBサービスのログイン情報変更完了",
      });
      navigate(-1, { replace: true });
    }
  }, [
    data,
    loginIDData.bikou,
    loginIDData.loginid,
    loginIDData.servicemei,
    loginIDData.siteurl,
    loginIDDataError,
    navigate,
  ]);

  return (
    <ListField
      label={getLabel("button.update")}
      list={listField}
      setState={setLoginIDData}
      setUpdate={setUpdate}
      onSave={updateDataLogin}
      onCancel={() => navigate(-1, { replace: true })}
      isDisabled={isUpdated}
    />
  );
}

export default LoginIDEditPage;
