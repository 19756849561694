import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Dialog, SmallFAB } from "../ui-components";
import { getLabel } from "../utils/CommonUtils";

export default function FABDelete(props) {
  const { deleteOnClick, ...rest } = props;

  return (
    <SmallFAB
      {...rest}
      marginLeft="auto"
      width="40px"
      height="40px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      padding="8px"
      onClick={() => {
        confirmAlert({
          overlayClassName: "react-confirm-alert-overlay-custom",
          customUI: ({ onClose }) => {
            return (
              <div className="custom-ui">
                <Dialog
                  overrides={{
                    "Flex.Flex[0].Text[0]": {
                      children: getLabel("dialog.delete.title"),
                    },
                    "Flex.Flex[0].Text[1]": {
                      children: getLabel("dialog.delete.body"),
                    },
                    "Flex.Flex[1].DefaultButton[0]": {
                      className: "pointer",
                      onClick: () => onClose(),
                      overrides: {
                        "Flex.Button[0]": {
                          children: getLabel("dialog.cancel"),
                        },
                      },
                    },
                    "Flex.Flex[1].PrimaryButton[0]": {
                      className: "pointer",
                      onClick: async () => {
                        await deleteOnClick();
                        onClose();
                      },
                      overrides: {
                        "Flex.Text[0]": {
                          children: getLabel("dialog.delete"),
                        },
                      },
                    },
                  }}
                />
              </div>
            );
          },
        });
      }}
      overrides={{
        Icon: {
          width: "100%",
          height: "100%",
          paths: [
            {
              d: "M16 9V19H8V9H16ZM14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM18 7H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7Z",
              fill: "#688649",
            },
          ],
        },
      }}
    />
  );
}
