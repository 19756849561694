import classNames from 'classnames';

import { ComponentClassNames } from '../../shared';
import { View } from '../../View';

export const IconLocationDisabled = (props) => {
  const { className, ...rest } = props;
  return (
    <View
      as="span"
      width="1em"
      height="1em"
      className={classNames(ComponentClassNames.Icon, className)}
      {...rest}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23 13V11H20.94C20.48 6.83 17.17 3.52 13 3.06V1H11V3.06C10.02 3.17 9.09 3.44 8.23 3.84L9.76 5.37C10.46 5.13 11.22 5 12 5C15.87 5 19 8.13 19 12C19 12.79 18.87 13.54 18.63 14.24L20.16 15.77C20.56 14.91 20.83 13.98 20.94 13H23ZM4.41 2.86L3 4.27L5.04 6.31C3.97 7.62 3.26 9.23 3.06 11H1V13H3.06C3.52 17.17 6.83 20.48 11 20.94V23H13V20.94C14.77 20.74 16.38 20.03 17.69 18.96L19.73 21L21.14 19.59L4.41 2.86ZM12 19C8.13 19 5 15.87 5 12C5 10.39 5.55 8.91 6.46 7.73L16.27 17.54C15.09 18.45 13.61 19 12 19Z"
          fill="currentColor"
        />
      </svg>
    </View>
  );
};
