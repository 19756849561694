// import { PCLabelM, PCSelectField, PCTextField, PCTextFieldMultiline, PCButtonCancelSave } from "../page-components";
import { DataStore } from "aws-amplify";
import React, { useContext, useState } from "react";
import { isMobile } from "react-device-detect";
import { KinyuuShisanshurui, LeavesKinyuuShisan } from "../models";
import {
  ButtonCancelSave,
  SelectField,
  SwapperContent,
  TextField,
  TextFieldMultiline,
} from "../page-components";
import UserContext from "../UserContext";
import { getLabel, isEmpty, useInitializePage } from "../utils/CommonUtils";
import { log } from "../utils/Logger";
import { usePrompt } from "../utils/Prompt";

/*
  shisanmei: String
  shisanshurui: Shisanshurui
  shozaibasho: String
  bikou: String
  _shisanshurui: String
  leavesusersID: ID! @index(name: "byLeavesUsers")
*/
export default function () {
  const navigate = useInitializePage("kinyuushisan", "title.add");

  const context = useContext(UserContext);

  const [shisanshurui, setShisanshurui] = useState("");
  const [kinyuukikan, setKinyuukikan] = useState("");
  const [bikou, setBikou] = useState("");

  const [shisanshuruiError, setShisanshuruiError] = useState(null);
  const [kinyuukikanError, setKinyuukikanError] = useState(null);
  const [bikouError, setBikouError] = useState(null);
  const [isAdded, setIsAdded] = useState(false);

  const [update, setUpdate] = useState(false);
  usePrompt((location, action) => {
    if (action == "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  const addData = async () => {
    log.debug(shisanshurui + "," + kinyuukikan + "," + bikou);
    let valid = true;
    if (isEmpty(kinyuukikan)) {
      setKinyuukikanError(getLabel("error.required"));
      valid = false;
    } else {
      setKinyuukikanError(null);
    }
    if (isEmpty(shisanshurui)) {
      setShisanshuruiError(getLabel("error.required"));
      valid = false;
    } else {
      setShisanshuruiError(null);
    }
    if (valid) {
      setIsAdded(true);
      //   console.log(context.leavesUser);
      await DataStore.save(
        new LeavesKinyuuShisan({
          shisanshurui: shisanshurui,
          kinyuukikan: kinyuukikan,
          bikou: bikou,
          leavesusersID: context.leavesUser.id,
        }),
      );
      window.gtag("event", "FirstWriteComplete", {
        event_category: "Edit",
        event_label: "金融資産書込完了",
      });
      navigate(-1, { replace: true });
    }
  };

  const shisanshuruiList = [];
  shisanshuruiList.push(
    <option key="default" value="">
      {getLabel("list.select")}
    </option>,
  );
  Object.keys(KinyuuShisanshurui).forEach((type) => {
    shisanshuruiList.push(
      <option key={type} value={type}>
        {getLabel("KinyuuShisanshurui." + type)}
      </option>,
    );
  });

  return (
    <SwapperContent label={getLabel("shinkitsuika")}>
      <SelectField
        isRequired
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("shisanshurui")}
        errorMessage={shisanshuruiError}
        options={shisanshuruiList}
        onChange={(event) => {
          setShisanshurui(event.target.value);
          setUpdate(true);
        }}
      />
      <TextField
        isRequired
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("kinyuukikan")}
        errorMessage={kinyuukikanError}
        placeHolder={getLabel("ginkousyoukengaisyanado")}
        onChange={(event) => {
          setKinyuukikan(event.target.value);
          setUpdate(true);
        }}
      />
      <TextFieldMultiline
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("bikou")}
        errorMessage={bikouError}
        onChange={(event) => {
          setBikou(event.target.value);
          setUpdate(true);
        }}
      />

      <ButtonCancelSave
        cancelOnClick={() => navigate(-1, { replace: true })}
        saveOnClick={() => addData()}
        isDisabled={isAdded}
      />
      {/* </BrowserView> */}
    </SwapperContent>
  );
}
