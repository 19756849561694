/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createLeavesUsers = /* GraphQL */ `
  mutation CreateLeavesUsers(
    $input: CreateLeavesUsersInput!
    $condition: ModelLeavesUsersConditionInput
  ) {
    createLeavesUsers(input: $input, condition: $condition) {
      id
      name
      loggedin
      kanshinnoaruCategory
      LeavesJitsubutsuShisans {
        nextToken
        startedAt
        __typename
      }
      LeavesKinyuuShisans {
        nextToken
        startedAt
        __typename
      }
      LeavesHokens {
        nextToken
        startedAt
        __typename
      }
      LeavesKaigo {
        id
        handanwoyudanetaihito
        kiboukaigoshurui
        kiboushisetsu
        hiyoukyoshutsu
        zaisankanriwomakaseruhito
        zaisankanriwomakaseruhitonokeiyakujoukyou
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesSougi {
        id
        sougiyouhi
        kibousougikeishiki
        souginosyurui
        sankaninzuukibo
        kibousuruhuniki
        junbishiteiruhiyou
        hiyoukyoshutsu
        hokenkyousainado
        souginiyondehoshiyuujinchijin
        yondehoshiihitonituite
        souginiyobukakazokuniitininsuruyuujinchijin
        itininsuruhitonituite
        souginoannaiwoshinakutemoyoihitonituite
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesLifeFutures {
        nextToken
        startedAt
        __typename
      }
      LeavesShuukans {
        nextToken
        startedAt
        __typename
      }
      LeavesMemos {
        nextToken
        startedAt
        __typename
      }
      LeavesKazokuShinzokus {
        nextToken
        startedAt
        __typename
      }
      LeavesJibunshis {
        nextToken
        startedAt
        __typename
      }
      LeavesHints {
        nextToken
        startedAt
        __typename
      }
      LeavesPets {
        nextToken
        startedAt
        __typename
      }
      LeavesIryousochi {
        id
        kokuchikibounoumu
        enmeisochikibounoumu
        zoukiteikyounoumu
        handanwoyudanetaihito
        ishikiganakunattarashirasetehoshiiyuujinchijin
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesCreditcards {
        nextToken
        startedAt
        __typename
      }
      LeavesKouzas {
        nextToken
        startedAt
        __typename
      }
      LeavesNenkins {
        nextToken
        startedAt
        __typename
      }
      LeavesKankonsousais {
        nextToken
        startedAt
        __typename
      }
      LeavesSumahoPcs {
        nextToken
        startedAt
        __typename
      }
      LeavesLoginIds {
        nextToken
        startedAt
        __typename
      }
      LeavesYuujinChijins {
        nextToken
        startedAt
        __typename
      }
      LeavesKenkoukanris {
        nextToken
        startedAt
        __typename
      }
      LeavesSeikatsunokoto {
        id
        seikatsunokotonitsuite
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesTakaramonos {
        nextToken
        startedAt
        __typename
      }
      LeavesMyEvents {
        nextToken
        startedAt
        __typename
      }
      LeavesHakanitsuite {
        id
        hairuyoteinoohakanoumu
        kibousuruohakanokeishiki
        kibousurubasyo
        hiyoukyoshutsu
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesDaijinishitaikinenbis {
        nextToken
        startedAt
        __typename
      }
      LeavesKakeizus {
        nextToken
        startedAt
        __typename
      }
      LeavesYuigonshonitsuites {
        id
        yuigonshonoumu
        yuigonshonoshurui
        yuigonshonosaisyuusakuseibi
        yuigonshikkousha
        yuigonshikkoushanorenrakusaki
        iraisoudanwoshiteirusenmonka
        iraisoudanwoshiteirusenmonkanorenrakusaki
        sonotasouzokunikansurukibounado
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesTaisetsunishitaihitohenomessages {
        nextToken
        startedAt
        __typename
      }
      LeavesLoankariirekins {
        nextToken
        startedAt
        __typename
      }
      LeavesHosyousaimus {
        nextToken
        startedAt
        __typename
      }
      LeavesShareInfos {
        nextToken
        startedAt
        __typename
      }
      LeavesSharedInfos {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      leavesUsersLeavesKaigoId
      leavesUsersLeavesSougiId
      leavesUsersLeavesIryousochiId
      leavesUsersLeavesSeikatsunokotoId
      leavesUsersLeavesHakanitsuiteId
      leavesUsersLeavesYuigonshonitsuitesId
      owner
      __typename
    }
  }
`;
export const updateLeavesUsers = /* GraphQL */ `
  mutation UpdateLeavesUsers(
    $input: UpdateLeavesUsersInput!
    $condition: ModelLeavesUsersConditionInput
  ) {
    updateLeavesUsers(input: $input, condition: $condition) {
      id
      name
      loggedin
      kanshinnoaruCategory
      LeavesJitsubutsuShisans {
        nextToken
        startedAt
        __typename
      }
      LeavesKinyuuShisans {
        nextToken
        startedAt
        __typename
      }
      LeavesHokens {
        nextToken
        startedAt
        __typename
      }
      LeavesKaigo {
        id
        handanwoyudanetaihito
        kiboukaigoshurui
        kiboushisetsu
        hiyoukyoshutsu
        zaisankanriwomakaseruhito
        zaisankanriwomakaseruhitonokeiyakujoukyou
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesSougi {
        id
        sougiyouhi
        kibousougikeishiki
        souginosyurui
        sankaninzuukibo
        kibousuruhuniki
        junbishiteiruhiyou
        hiyoukyoshutsu
        hokenkyousainado
        souginiyondehoshiyuujinchijin
        yondehoshiihitonituite
        souginiyobukakazokuniitininsuruyuujinchijin
        itininsuruhitonituite
        souginoannaiwoshinakutemoyoihitonituite
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesLifeFutures {
        nextToken
        startedAt
        __typename
      }
      LeavesShuukans {
        nextToken
        startedAt
        __typename
      }
      LeavesMemos {
        nextToken
        startedAt
        __typename
      }
      LeavesKazokuShinzokus {
        nextToken
        startedAt
        __typename
      }
      LeavesJibunshis {
        nextToken
        startedAt
        __typename
      }
      LeavesHints {
        nextToken
        startedAt
        __typename
      }
      LeavesPets {
        nextToken
        startedAt
        __typename
      }
      LeavesIryousochi {
        id
        kokuchikibounoumu
        enmeisochikibounoumu
        zoukiteikyounoumu
        handanwoyudanetaihito
        ishikiganakunattarashirasetehoshiiyuujinchijin
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesCreditcards {
        nextToken
        startedAt
        __typename
      }
      LeavesKouzas {
        nextToken
        startedAt
        __typename
      }
      LeavesNenkins {
        nextToken
        startedAt
        __typename
      }
      LeavesKankonsousais {
        nextToken
        startedAt
        __typename
      }
      LeavesSumahoPcs {
        nextToken
        startedAt
        __typename
      }
      LeavesLoginIds {
        nextToken
        startedAt
        __typename
      }
      LeavesYuujinChijins {
        nextToken
        startedAt
        __typename
      }
      LeavesKenkoukanris {
        nextToken
        startedAt
        __typename
      }
      LeavesSeikatsunokoto {
        id
        seikatsunokotonitsuite
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesTakaramonos {
        nextToken
        startedAt
        __typename
      }
      LeavesMyEvents {
        nextToken
        startedAt
        __typename
      }
      LeavesHakanitsuite {
        id
        hairuyoteinoohakanoumu
        kibousuruohakanokeishiki
        kibousurubasyo
        hiyoukyoshutsu
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesDaijinishitaikinenbis {
        nextToken
        startedAt
        __typename
      }
      LeavesKakeizus {
        nextToken
        startedAt
        __typename
      }
      LeavesYuigonshonitsuites {
        id
        yuigonshonoumu
        yuigonshonoshurui
        yuigonshonosaisyuusakuseibi
        yuigonshikkousha
        yuigonshikkoushanorenrakusaki
        iraisoudanwoshiteirusenmonka
        iraisoudanwoshiteirusenmonkanorenrakusaki
        sonotasouzokunikansurukibounado
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesTaisetsunishitaihitohenomessages {
        nextToken
        startedAt
        __typename
      }
      LeavesLoankariirekins {
        nextToken
        startedAt
        __typename
      }
      LeavesHosyousaimus {
        nextToken
        startedAt
        __typename
      }
      LeavesShareInfos {
        nextToken
        startedAt
        __typename
      }
      LeavesSharedInfos {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      leavesUsersLeavesKaigoId
      leavesUsersLeavesSougiId
      leavesUsersLeavesIryousochiId
      leavesUsersLeavesSeikatsunokotoId
      leavesUsersLeavesHakanitsuiteId
      leavesUsersLeavesYuigonshonitsuitesId
      owner
      __typename
    }
  }
`;
export const deleteLeavesUsers = /* GraphQL */ `
  mutation DeleteLeavesUsers(
    $input: DeleteLeavesUsersInput!
    $condition: ModelLeavesUsersConditionInput
  ) {
    deleteLeavesUsers(input: $input, condition: $condition) {
      id
      name
      loggedin
      kanshinnoaruCategory
      LeavesJitsubutsuShisans {
        nextToken
        startedAt
        __typename
      }
      LeavesKinyuuShisans {
        nextToken
        startedAt
        __typename
      }
      LeavesHokens {
        nextToken
        startedAt
        __typename
      }
      LeavesKaigo {
        id
        handanwoyudanetaihito
        kiboukaigoshurui
        kiboushisetsu
        hiyoukyoshutsu
        zaisankanriwomakaseruhito
        zaisankanriwomakaseruhitonokeiyakujoukyou
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesSougi {
        id
        sougiyouhi
        kibousougikeishiki
        souginosyurui
        sankaninzuukibo
        kibousuruhuniki
        junbishiteiruhiyou
        hiyoukyoshutsu
        hokenkyousainado
        souginiyondehoshiyuujinchijin
        yondehoshiihitonituite
        souginiyobukakazokuniitininsuruyuujinchijin
        itininsuruhitonituite
        souginoannaiwoshinakutemoyoihitonituite
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesLifeFutures {
        nextToken
        startedAt
        __typename
      }
      LeavesShuukans {
        nextToken
        startedAt
        __typename
      }
      LeavesMemos {
        nextToken
        startedAt
        __typename
      }
      LeavesKazokuShinzokus {
        nextToken
        startedAt
        __typename
      }
      LeavesJibunshis {
        nextToken
        startedAt
        __typename
      }
      LeavesHints {
        nextToken
        startedAt
        __typename
      }
      LeavesPets {
        nextToken
        startedAt
        __typename
      }
      LeavesIryousochi {
        id
        kokuchikibounoumu
        enmeisochikibounoumu
        zoukiteikyounoumu
        handanwoyudanetaihito
        ishikiganakunattarashirasetehoshiiyuujinchijin
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesCreditcards {
        nextToken
        startedAt
        __typename
      }
      LeavesKouzas {
        nextToken
        startedAt
        __typename
      }
      LeavesNenkins {
        nextToken
        startedAt
        __typename
      }
      LeavesKankonsousais {
        nextToken
        startedAt
        __typename
      }
      LeavesSumahoPcs {
        nextToken
        startedAt
        __typename
      }
      LeavesLoginIds {
        nextToken
        startedAt
        __typename
      }
      LeavesYuujinChijins {
        nextToken
        startedAt
        __typename
      }
      LeavesKenkoukanris {
        nextToken
        startedAt
        __typename
      }
      LeavesSeikatsunokoto {
        id
        seikatsunokotonitsuite
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesTakaramonos {
        nextToken
        startedAt
        __typename
      }
      LeavesMyEvents {
        nextToken
        startedAt
        __typename
      }
      LeavesHakanitsuite {
        id
        hairuyoteinoohakanoumu
        kibousuruohakanokeishiki
        kibousurubasyo
        hiyoukyoshutsu
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesDaijinishitaikinenbis {
        nextToken
        startedAt
        __typename
      }
      LeavesKakeizus {
        nextToken
        startedAt
        __typename
      }
      LeavesYuigonshonitsuites {
        id
        yuigonshonoumu
        yuigonshonoshurui
        yuigonshonosaisyuusakuseibi
        yuigonshikkousha
        yuigonshikkoushanorenrakusaki
        iraisoudanwoshiteirusenmonka
        iraisoudanwoshiteirusenmonkanorenrakusaki
        sonotasouzokunikansurukibounado
        bikou
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      LeavesTaisetsunishitaihitohenomessages {
        nextToken
        startedAt
        __typename
      }
      LeavesLoankariirekins {
        nextToken
        startedAt
        __typename
      }
      LeavesHosyousaimus {
        nextToken
        startedAt
        __typename
      }
      LeavesShareInfos {
        nextToken
        startedAt
        __typename
      }
      LeavesSharedInfos {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      leavesUsersLeavesKaigoId
      leavesUsersLeavesSougiId
      leavesUsersLeavesIryousochiId
      leavesUsersLeavesSeikatsunokotoId
      leavesUsersLeavesHakanitsuiteId
      leavesUsersLeavesYuigonshonitsuitesId
      owner
      __typename
    }
  }
`;
export const createLeavesJitsubutsuShisan = /* GraphQL */ `
  mutation CreateLeavesJitsubutsuShisan(
    $input: CreateLeavesJitsubutsuShisanInput!
    $condition: ModelLeavesJitsubutsuShisanConditionInput
  ) {
    createLeavesJitsubutsuShisan(input: $input, condition: $condition) {
      id
      shisanmei
      shisanshurui
      shozaibasho
      bikou
      _shisanshurui
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateLeavesJitsubutsuShisan = /* GraphQL */ `
  mutation UpdateLeavesJitsubutsuShisan(
    $input: UpdateLeavesJitsubutsuShisanInput!
    $condition: ModelLeavesJitsubutsuShisanConditionInput
  ) {
    updateLeavesJitsubutsuShisan(input: $input, condition: $condition) {
      id
      shisanmei
      shisanshurui
      shozaibasho
      bikou
      _shisanshurui
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteLeavesJitsubutsuShisan = /* GraphQL */ `
  mutation DeleteLeavesJitsubutsuShisan(
    $input: DeleteLeavesJitsubutsuShisanInput!
    $condition: ModelLeavesJitsubutsuShisanConditionInput
  ) {
    deleteLeavesJitsubutsuShisan(input: $input, condition: $condition) {
      id
      shisanmei
      shisanshurui
      shozaibasho
      bikou
      _shisanshurui
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLeavesKinyuuShisan = /* GraphQL */ `
  mutation CreateLeavesKinyuuShisan(
    $input: CreateLeavesKinyuuShisanInput!
    $condition: ModelLeavesKinyuuShisanConditionInput
  ) {
    createLeavesKinyuuShisan(input: $input, condition: $condition) {
      id
      shisanmei
      shisanshurui
      kinyuukikan
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateLeavesKinyuuShisan = /* GraphQL */ `
  mutation UpdateLeavesKinyuuShisan(
    $input: UpdateLeavesKinyuuShisanInput!
    $condition: ModelLeavesKinyuuShisanConditionInput
  ) {
    updateLeavesKinyuuShisan(input: $input, condition: $condition) {
      id
      shisanmei
      shisanshurui
      kinyuukikan
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteLeavesKinyuuShisan = /* GraphQL */ `
  mutation DeleteLeavesKinyuuShisan(
    $input: DeleteLeavesKinyuuShisanInput!
    $condition: ModelLeavesKinyuuShisanConditionInput
  ) {
    deleteLeavesKinyuuShisan(input: $input, condition: $condition) {
      id
      shisanmei
      shisanshurui
      kinyuukikan
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLeavesHoken = /* GraphQL */ `
  mutation CreateLeavesHoken(
    $input: CreateLeavesHokenInput!
    $condition: ModelLeavesHokenConditionInput
  ) {
    createLeavesHoken(input: $input, condition: $condition) {
      id
      hokenmei
      hokenshurui
      hokengaisha
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateLeavesHoken = /* GraphQL */ `
  mutation UpdateLeavesHoken(
    $input: UpdateLeavesHokenInput!
    $condition: ModelLeavesHokenConditionInput
  ) {
    updateLeavesHoken(input: $input, condition: $condition) {
      id
      hokenmei
      hokenshurui
      hokengaisha
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteLeavesHoken = /* GraphQL */ `
  mutation DeleteLeavesHoken(
    $input: DeleteLeavesHokenInput!
    $condition: ModelLeavesHokenConditionInput
  ) {
    deleteLeavesHoken(input: $input, condition: $condition) {
      id
      hokenmei
      hokenshurui
      hokengaisha
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLeavesKaigo = /* GraphQL */ `
  mutation CreateLeavesKaigo(
    $input: CreateLeavesKaigoInput!
    $condition: ModelLeavesKaigoConditionInput
  ) {
    createLeavesKaigo(input: $input, condition: $condition) {
      id
      handanwoyudanetaihito
      kiboukaigoshurui
      kiboushisetsu
      hiyoukyoshutsu
      zaisankanriwomakaseruhito
      zaisankanriwomakaseruhitonokeiyakujoukyou
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateLeavesKaigo = /* GraphQL */ `
  mutation UpdateLeavesKaigo(
    $input: UpdateLeavesKaigoInput!
    $condition: ModelLeavesKaigoConditionInput
  ) {
    updateLeavesKaigo(input: $input, condition: $condition) {
      id
      handanwoyudanetaihito
      kiboukaigoshurui
      kiboushisetsu
      hiyoukyoshutsu
      zaisankanriwomakaseruhito
      zaisankanriwomakaseruhitonokeiyakujoukyou
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteLeavesKaigo = /* GraphQL */ `
  mutation DeleteLeavesKaigo(
    $input: DeleteLeavesKaigoInput!
    $condition: ModelLeavesKaigoConditionInput
  ) {
    deleteLeavesKaigo(input: $input, condition: $condition) {
      id
      handanwoyudanetaihito
      kiboukaigoshurui
      kiboushisetsu
      hiyoukyoshutsu
      zaisankanriwomakaseruhito
      zaisankanriwomakaseruhitonokeiyakujoukyou
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLeavesSougi = /* GraphQL */ `
  mutation CreateLeavesSougi(
    $input: CreateLeavesSougiInput!
    $condition: ModelLeavesSougiConditionInput
  ) {
    createLeavesSougi(input: $input, condition: $condition) {
      id
      sougiyouhi
      kibousougikeishiki
      souginosyurui
      sankaninzuukibo
      kibousuruhuniki
      junbishiteiruhiyou
      hiyoukyoshutsu
      hokenkyousainado
      souginiyondehoshiyuujinchijin
      yondehoshiihitonituite
      souginiyobukakazokuniitininsuruyuujinchijin
      itininsuruhitonituite
      souginoannaiwoshinakutemoyoihitonituite
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateLeavesSougi = /* GraphQL */ `
  mutation UpdateLeavesSougi(
    $input: UpdateLeavesSougiInput!
    $condition: ModelLeavesSougiConditionInput
  ) {
    updateLeavesSougi(input: $input, condition: $condition) {
      id
      sougiyouhi
      kibousougikeishiki
      souginosyurui
      sankaninzuukibo
      kibousuruhuniki
      junbishiteiruhiyou
      hiyoukyoshutsu
      hokenkyousainado
      souginiyondehoshiyuujinchijin
      yondehoshiihitonituite
      souginiyobukakazokuniitininsuruyuujinchijin
      itininsuruhitonituite
      souginoannaiwoshinakutemoyoihitonituite
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteLeavesSougi = /* GraphQL */ `
  mutation DeleteLeavesSougi(
    $input: DeleteLeavesSougiInput!
    $condition: ModelLeavesSougiConditionInput
  ) {
    deleteLeavesSougi(input: $input, condition: $condition) {
      id
      sougiyouhi
      kibousougikeishiki
      souginosyurui
      sankaninzuukibo
      kibousuruhuniki
      junbishiteiruhiyou
      hiyoukyoshutsu
      hokenkyousainado
      souginiyondehoshiyuujinchijin
      yondehoshiihitonituite
      souginiyobukakazokuniitininsuruyuujinchijin
      itininsuruhitonituite
      souginoannaiwoshinakutemoyoihitonituite
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLeavesLifeFuture = /* GraphQL */ `
  mutation CreateLeavesLifeFuture(
    $input: CreateLeavesLifeFutureInput!
    $condition: ModelLeavesLifeFutureConditionInput
  ) {
    createLeavesLifeFuture(input: $input, condition: $condition) {
      id
      yaritaikoto
      jitsugennenreikigen
      tasseijoukyou
      tasseijoukyoushurui
      category
      yuusendo
      episode
      jitsugenstep {
        jitsugenstep
        shinchoku
        bikou
        __typename
      }
      leavesusersID
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateLeavesLifeFuture = /* GraphQL */ `
  mutation UpdateLeavesLifeFuture(
    $input: UpdateLeavesLifeFutureInput!
    $condition: ModelLeavesLifeFutureConditionInput
  ) {
    updateLeavesLifeFuture(input: $input, condition: $condition) {
      id
      yaritaikoto
      jitsugennenreikigen
      tasseijoukyou
      tasseijoukyoushurui
      category
      yuusendo
      episode
      jitsugenstep {
        jitsugenstep
        shinchoku
        bikou
        __typename
      }
      leavesusersID
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteLeavesLifeFuture = /* GraphQL */ `
  mutation DeleteLeavesLifeFuture(
    $input: DeleteLeavesLifeFutureInput!
    $condition: ModelLeavesLifeFutureConditionInput
  ) {
    deleteLeavesLifeFuture(input: $input, condition: $condition) {
      id
      yaritaikoto
      jitsugennenreikigen
      tasseijoukyou
      tasseijoukyoushurui
      category
      yuusendo
      episode
      jitsugenstep {
        jitsugenstep
        shinchoku
        bikou
        __typename
      }
      leavesusersID
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLeavesShuukan = /* GraphQL */ `
  mutation CreateLeavesShuukan(
    $input: CreateLeavesShuukanInput!
    $condition: ModelLeavesShuukanConditionInput
  ) {
    createLeavesShuukan(input: $input, condition: $condition) {
      id
      yaritaikoto
      yuusendo
      episode
      leavesusersID
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateLeavesShuukan = /* GraphQL */ `
  mutation UpdateLeavesShuukan(
    $input: UpdateLeavesShuukanInput!
    $condition: ModelLeavesShuukanConditionInput
  ) {
    updateLeavesShuukan(input: $input, condition: $condition) {
      id
      yaritaikoto
      yuusendo
      episode
      leavesusersID
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteLeavesShuukan = /* GraphQL */ `
  mutation DeleteLeavesShuukan(
    $input: DeleteLeavesShuukanInput!
    $condition: ModelLeavesShuukanConditionInput
  ) {
    deleteLeavesShuukan(input: $input, condition: $condition) {
      id
      yaritaikoto
      yuusendo
      episode
      leavesusersID
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLeavesMemo = /* GraphQL */ `
  mutation CreateLeavesMemo(
    $input: CreateLeavesMemoInput!
    $condition: ModelLeavesMemoConditionInput
  ) {
    createLeavesMemo(input: $input, condition: $condition) {
      id
      title
      memo
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateLeavesMemo = /* GraphQL */ `
  mutation UpdateLeavesMemo(
    $input: UpdateLeavesMemoInput!
    $condition: ModelLeavesMemoConditionInput
  ) {
    updateLeavesMemo(input: $input, condition: $condition) {
      id
      title
      memo
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteLeavesMemo = /* GraphQL */ `
  mutation DeleteLeavesMemo(
    $input: DeleteLeavesMemoInput!
    $condition: ModelLeavesMemoConditionInput
  ) {
    deleteLeavesMemo(input: $input, condition: $condition) {
      id
      title
      memo
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLeavesKazokuShinzoku = /* GraphQL */ `
  mutation CreateLeavesKazokuShinzoku(
    $input: CreateLeavesKazokuShinzokuInput!
    $condition: ModelLeavesKazokuShinzokuConditionInput
  ) {
    createLeavesKazokuShinzoku(input: $input, condition: $condition) {
      id
      shimei
      zokugara
      seinengappi
      yuubinbangou
      juusho
      denwabangou
      mailaddress
      nyuuinjirenraku
      sougijirenraku
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateLeavesKazokuShinzoku = /* GraphQL */ `
  mutation UpdateLeavesKazokuShinzoku(
    $input: UpdateLeavesKazokuShinzokuInput!
    $condition: ModelLeavesKazokuShinzokuConditionInput
  ) {
    updateLeavesKazokuShinzoku(input: $input, condition: $condition) {
      id
      shimei
      zokugara
      seinengappi
      yuubinbangou
      juusho
      denwabangou
      mailaddress
      nyuuinjirenraku
      sougijirenraku
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteLeavesKazokuShinzoku = /* GraphQL */ `
  mutation DeleteLeavesKazokuShinzoku(
    $input: DeleteLeavesKazokuShinzokuInput!
    $condition: ModelLeavesKazokuShinzokuConditionInput
  ) {
    deleteLeavesKazokuShinzoku(input: $input, condition: $condition) {
      id
      shimei
      zokugara
      seinengappi
      yuubinbangou
      juusho
      denwabangou
      mailaddress
      nyuuinjirenraku
      sougijirenraku
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLeavesJibunshi = /* GraphQL */ `
  mutation CreateLeavesJibunshi(
    $input: CreateLeavesJibunshiInput!
    $condition: ModelLeavesJibunshiConditionInput
  ) {
    createLeavesJibunshi(input: $input, condition: $condition) {
      id
      nengetsu
      eventmei
      episode
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateLeavesJibunshi = /* GraphQL */ `
  mutation UpdateLeavesJibunshi(
    $input: UpdateLeavesJibunshiInput!
    $condition: ModelLeavesJibunshiConditionInput
  ) {
    updateLeavesJibunshi(input: $input, condition: $condition) {
      id
      nengetsu
      eventmei
      episode
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteLeavesJibunshi = /* GraphQL */ `
  mutation DeleteLeavesJibunshi(
    $input: DeleteLeavesJibunshiInput!
    $condition: ModelLeavesJibunshiConditionInput
  ) {
    deleteLeavesJibunshi(input: $input, condition: $condition) {
      id
      nengetsu
      eventmei
      episode
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLeavesHint = /* GraphQL */ `
  mutation CreateLeavesHint(
    $input: CreateLeavesHintInput!
    $condition: ModelLeavesHintConditionInput
  ) {
    createLeavesHint(input: $input, condition: $condition) {
      id
      url
      title
      image
      type
      leavesusersID
      publishdate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateLeavesHint = /* GraphQL */ `
  mutation UpdateLeavesHint(
    $input: UpdateLeavesHintInput!
    $condition: ModelLeavesHintConditionInput
  ) {
    updateLeavesHint(input: $input, condition: $condition) {
      id
      url
      title
      image
      type
      leavesusersID
      publishdate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteLeavesHint = /* GraphQL */ `
  mutation DeleteLeavesHint(
    $input: DeleteLeavesHintInput!
    $condition: ModelLeavesHintConditionInput
  ) {
    deleteLeavesHint(input: $input, condition: $condition) {
      id
      url
      title
      image
      type
      leavesusersID
      publishdate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLeavesPet = /* GraphQL */ `
  mutation CreateLeavesPet(
    $input: CreateLeavesPetInput!
    $condition: ModelLeavesPetConditionInput
  ) {
    createLeavesPet(input: $input, condition: $condition) {
      id
      namae
      shurui
      seinengappi
      seibetsu
      tourokubangou
      hokennoumu
      hokennkaisyamei
      kakaritsukenobyouinmei
      kakaritsukenobyouinnorenrakusaki
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateLeavesPet = /* GraphQL */ `
  mutation UpdateLeavesPet(
    $input: UpdateLeavesPetInput!
    $condition: ModelLeavesPetConditionInput
  ) {
    updateLeavesPet(input: $input, condition: $condition) {
      id
      namae
      shurui
      seinengappi
      seibetsu
      tourokubangou
      hokennoumu
      hokennkaisyamei
      kakaritsukenobyouinmei
      kakaritsukenobyouinnorenrakusaki
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteLeavesPet = /* GraphQL */ `
  mutation DeleteLeavesPet(
    $input: DeleteLeavesPetInput!
    $condition: ModelLeavesPetConditionInput
  ) {
    deleteLeavesPet(input: $input, condition: $condition) {
      id
      namae
      shurui
      seinengappi
      seibetsu
      tourokubangou
      hokennoumu
      hokennkaisyamei
      kakaritsukenobyouinmei
      kakaritsukenobyouinnorenrakusaki
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLeavesIryousochi = /* GraphQL */ `
  mutation CreateLeavesIryousochi(
    $input: CreateLeavesIryousochiInput!
    $condition: ModelLeavesIryousochiConditionInput
  ) {
    createLeavesIryousochi(input: $input, condition: $condition) {
      id
      kokuchikibounoumu
      enmeisochikibounoumu
      zoukiteikyounoumu
      handanwoyudanetaihito
      ishikiganakunattarashirasetehoshiiyuujinchijin
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateLeavesIryousochi = /* GraphQL */ `
  mutation UpdateLeavesIryousochi(
    $input: UpdateLeavesIryousochiInput!
    $condition: ModelLeavesIryousochiConditionInput
  ) {
    updateLeavesIryousochi(input: $input, condition: $condition) {
      id
      kokuchikibounoumu
      enmeisochikibounoumu
      zoukiteikyounoumu
      handanwoyudanetaihito
      ishikiganakunattarashirasetehoshiiyuujinchijin
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteLeavesIryousochi = /* GraphQL */ `
  mutation DeleteLeavesIryousochi(
    $input: DeleteLeavesIryousochiInput!
    $condition: ModelLeavesIryousochiConditionInput
  ) {
    deleteLeavesIryousochi(input: $input, condition: $condition) {
      id
      kokuchikibounoumu
      enmeisochikibounoumu
      zoukiteikyounoumu
      handanwoyudanetaihito
      ishikiganakunattarashirasetehoshiiyuujinchijin
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLeavesCreditcard = /* GraphQL */ `
  mutation CreateLeavesCreditcard(
    $input: CreateLeavesCreditcardInput!
    $condition: ModelLeavesCreditcardConditionInput
  ) {
    createLeavesCreditcard(input: $input, condition: $condition) {
      id
      creditcardmei
      cardbrand
      cardhakkougaisha
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateLeavesCreditcard = /* GraphQL */ `
  mutation UpdateLeavesCreditcard(
    $input: UpdateLeavesCreditcardInput!
    $condition: ModelLeavesCreditcardConditionInput
  ) {
    updateLeavesCreditcard(input: $input, condition: $condition) {
      id
      creditcardmei
      cardbrand
      cardhakkougaisha
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteLeavesCreditcard = /* GraphQL */ `
  mutation DeleteLeavesCreditcard(
    $input: DeleteLeavesCreditcardInput!
    $condition: ModelLeavesCreditcardConditionInput
  ) {
    deleteLeavesCreditcard(input: $input, condition: $condition) {
      id
      creditcardmei
      cardbrand
      cardhakkougaisha
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLeavesKouza = /* GraphQL */ `
  mutation CreateLeavesKouza(
    $input: CreateLeavesKouzaInput!
    $condition: ModelLeavesKouzaConditionInput
  ) {
    createLeavesKouza(input: $input, condition: $condition) {
      id
      hikiotoshikoumoku
      hikiotoshikinyuukikan
      hikiotoshibi
      hikiotoshisakiKinyuuShisanYochokin
      hikiotoshisakiCreditcard
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateLeavesKouza = /* GraphQL */ `
  mutation UpdateLeavesKouza(
    $input: UpdateLeavesKouzaInput!
    $condition: ModelLeavesKouzaConditionInput
  ) {
    updateLeavesKouza(input: $input, condition: $condition) {
      id
      hikiotoshikoumoku
      hikiotoshikinyuukikan
      hikiotoshibi
      hikiotoshisakiKinyuuShisanYochokin
      hikiotoshisakiCreditcard
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteLeavesKouza = /* GraphQL */ `
  mutation DeleteLeavesKouza(
    $input: DeleteLeavesKouzaInput!
    $condition: ModelLeavesKouzaConditionInput
  ) {
    deleteLeavesKouza(input: $input, condition: $condition) {
      id
      hikiotoshikoumoku
      hikiotoshikinyuukikan
      hikiotoshibi
      hikiotoshisakiKinyuuShisanYochokin
      hikiotoshisakiCreditcard
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLeavesNenkin = /* GraphQL */ `
  mutation CreateLeavesNenkin(
    $input: CreateLeavesNenkinInput!
    $condition: ModelLeavesNenkinConditionInput
  ) {
    createLeavesNenkin(input: $input, condition: $condition) {
      id
      nenkinmei
      nenkinshurui
      jyukyuugaku
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateLeavesNenkin = /* GraphQL */ `
  mutation UpdateLeavesNenkin(
    $input: UpdateLeavesNenkinInput!
    $condition: ModelLeavesNenkinConditionInput
  ) {
    updateLeavesNenkin(input: $input, condition: $condition) {
      id
      nenkinmei
      nenkinshurui
      jyukyuugaku
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteLeavesNenkin = /* GraphQL */ `
  mutation DeleteLeavesNenkin(
    $input: DeleteLeavesNenkinInput!
    $condition: ModelLeavesNenkinConditionInput
  ) {
    deleteLeavesNenkin(input: $input, condition: $condition) {
      id
      nenkinmei
      nenkinshurui
      jyukyuugaku
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLeavesKankonsousai = /* GraphQL */ `
  mutation CreateLeavesKankonsousai(
    $input: CreateLeavesKankonsousaiInput!
    $condition: ModelLeavesKankonsousaiConditionInput
  ) {
    createLeavesKankonsousai(input: $input, condition: $condition) {
      id
      eventmei
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateLeavesKankonsousai = /* GraphQL */ `
  mutation UpdateLeavesKankonsousai(
    $input: UpdateLeavesKankonsousaiInput!
    $condition: ModelLeavesKankonsousaiConditionInput
  ) {
    updateLeavesKankonsousai(input: $input, condition: $condition) {
      id
      eventmei
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteLeavesKankonsousai = /* GraphQL */ `
  mutation DeleteLeavesKankonsousai(
    $input: DeleteLeavesKankonsousaiInput!
    $condition: ModelLeavesKankonsousaiConditionInput
  ) {
    deleteLeavesKankonsousai(input: $input, condition: $condition) {
      id
      eventmei
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLeavesSumahoPc = /* GraphQL */ `
  mutation CreateLeavesSumahoPc(
    $input: CreateLeavesSumahoPcInput!
    $condition: ModelLeavesSumahoPcConditionInput
  ) {
    createLeavesSumahoPc(input: $input, condition: $condition) {
      id
      kikishubetsu
      keiyakusaki
      denwabangou
      meiginin
      kishumei
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateLeavesSumahoPc = /* GraphQL */ `
  mutation UpdateLeavesSumahoPc(
    $input: UpdateLeavesSumahoPcInput!
    $condition: ModelLeavesSumahoPcConditionInput
  ) {
    updateLeavesSumahoPc(input: $input, condition: $condition) {
      id
      kikishubetsu
      keiyakusaki
      denwabangou
      meiginin
      kishumei
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteLeavesSumahoPc = /* GraphQL */ `
  mutation DeleteLeavesSumahoPc(
    $input: DeleteLeavesSumahoPcInput!
    $condition: ModelLeavesSumahoPcConditionInput
  ) {
    deleteLeavesSumahoPc(input: $input, condition: $condition) {
      id
      kikishubetsu
      keiyakusaki
      denwabangou
      meiginin
      kishumei
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLeavesLoginId = /* GraphQL */ `
  mutation CreateLeavesLoginId(
    $input: CreateLeavesLoginIdInput!
    $condition: ModelLeavesLoginIdConditionInput
  ) {
    createLeavesLoginId(input: $input, condition: $condition) {
      id
      servicemei
      siteurl
      loginid
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateLeavesLoginId = /* GraphQL */ `
  mutation UpdateLeavesLoginId(
    $input: UpdateLeavesLoginIdInput!
    $condition: ModelLeavesLoginIdConditionInput
  ) {
    updateLeavesLoginId(input: $input, condition: $condition) {
      id
      servicemei
      siteurl
      loginid
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteLeavesLoginId = /* GraphQL */ `
  mutation DeleteLeavesLoginId(
    $input: DeleteLeavesLoginIdInput!
    $condition: ModelLeavesLoginIdConditionInput
  ) {
    deleteLeavesLoginId(input: $input, condition: $condition) {
      id
      servicemei
      siteurl
      loginid
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLeavesYuujinChijin = /* GraphQL */ `
  mutation CreateLeavesYuujinChijin(
    $input: CreateLeavesYuujinChijinInput!
    $condition: ModelLeavesYuujinChijinConditionInput
  ) {
    createLeavesYuujinChijin(input: $input, condition: $condition) {
      id
      shimei
      kankeisei
      seinengappi
      juusho
      denwabangou
      mailaddress
      nyuuinjinorenraku
      sougijinorenraku
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateLeavesYuujinChijin = /* GraphQL */ `
  mutation UpdateLeavesYuujinChijin(
    $input: UpdateLeavesYuujinChijinInput!
    $condition: ModelLeavesYuujinChijinConditionInput
  ) {
    updateLeavesYuujinChijin(input: $input, condition: $condition) {
      id
      shimei
      kankeisei
      seinengappi
      juusho
      denwabangou
      mailaddress
      nyuuinjinorenraku
      sougijinorenraku
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteLeavesYuujinChijin = /* GraphQL */ `
  mutation DeleteLeavesYuujinChijin(
    $input: DeleteLeavesYuujinChijinInput!
    $condition: ModelLeavesYuujinChijinConditionInput
  ) {
    deleteLeavesYuujinChijin(input: $input, condition: $condition) {
      id
      shimei
      kankeisei
      seinengappi
      juusho
      denwabangou
      mailaddress
      nyuuinjinorenraku
      sougijinorenraku
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLeavesKenkoukanri = /* GraphQL */ `
  mutation CreateLeavesKenkoukanri(
    $input: CreateLeavesKenkoukanriInput!
    $condition: ModelLeavesKenkoukanriConditionInput
  ) {
    createLeavesKenkoukanri(input: $input, condition: $condition) {
      id
      byoumei
      hukuyouchuunokusuri
      youto
      youhou
      byouinmei
      chiryoukikan
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateLeavesKenkoukanri = /* GraphQL */ `
  mutation UpdateLeavesKenkoukanri(
    $input: UpdateLeavesKenkoukanriInput!
    $condition: ModelLeavesKenkoukanriConditionInput
  ) {
    updateLeavesKenkoukanri(input: $input, condition: $condition) {
      id
      byoumei
      hukuyouchuunokusuri
      youto
      youhou
      byouinmei
      chiryoukikan
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteLeavesKenkoukanri = /* GraphQL */ `
  mutation DeleteLeavesKenkoukanri(
    $input: DeleteLeavesKenkoukanriInput!
    $condition: ModelLeavesKenkoukanriConditionInput
  ) {
    deleteLeavesKenkoukanri(input: $input, condition: $condition) {
      id
      byoumei
      hukuyouchuunokusuri
      youto
      youhou
      byouinmei
      chiryoukikan
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLeavesSeikatsunokoto = /* GraphQL */ `
  mutation CreateLeavesSeikatsunokoto(
    $input: CreateLeavesSeikatsunokotoInput!
    $condition: ModelLeavesSeikatsunokotoConditionInput
  ) {
    createLeavesSeikatsunokoto(input: $input, condition: $condition) {
      id
      seikatsunokotonitsuite
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateLeavesSeikatsunokoto = /* GraphQL */ `
  mutation UpdateLeavesSeikatsunokoto(
    $input: UpdateLeavesSeikatsunokotoInput!
    $condition: ModelLeavesSeikatsunokotoConditionInput
  ) {
    updateLeavesSeikatsunokoto(input: $input, condition: $condition) {
      id
      seikatsunokotonitsuite
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteLeavesSeikatsunokoto = /* GraphQL */ `
  mutation DeleteLeavesSeikatsunokoto(
    $input: DeleteLeavesSeikatsunokotoInput!
    $condition: ModelLeavesSeikatsunokotoConditionInput
  ) {
    deleteLeavesSeikatsunokoto(input: $input, condition: $condition) {
      id
      seikatsunokotonitsuite
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLeavesTakaramono = /* GraphQL */ `
  mutation CreateLeavesTakaramono(
    $input: CreateLeavesTakaramonoInput!
    $condition: ModelLeavesTakaramonoConditionInput
  ) {
    createLeavesTakaramono(input: $input, condition: $condition) {
      id
      collectionmei
      hokanbasyo
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateLeavesTakaramono = /* GraphQL */ `
  mutation UpdateLeavesTakaramono(
    $input: UpdateLeavesTakaramonoInput!
    $condition: ModelLeavesTakaramonoConditionInput
  ) {
    updateLeavesTakaramono(input: $input, condition: $condition) {
      id
      collectionmei
      hokanbasyo
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteLeavesTakaramono = /* GraphQL */ `
  mutation DeleteLeavesTakaramono(
    $input: DeleteLeavesTakaramonoInput!
    $condition: ModelLeavesTakaramonoConditionInput
  ) {
    deleteLeavesTakaramono(input: $input, condition: $condition) {
      id
      collectionmei
      hokanbasyo
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLeavesEvents = /* GraphQL */ `
  mutation CreateLeavesEvents(
    $input: CreateLeavesEventsInput!
    $condition: ModelLeavesEventsConditionInput
  ) {
    createLeavesEvents(input: $input, condition: $condition) {
      id
      code
      name
      startDate
      endDate
      date
      time
      closingDay
      address
      place
      mapUrl
      price
      confirmationNeeded
      applyEnabled
      imagePath
      eventPageUrl
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateLeavesEvents = /* GraphQL */ `
  mutation UpdateLeavesEvents(
    $input: UpdateLeavesEventsInput!
    $condition: ModelLeavesEventsConditionInput
  ) {
    updateLeavesEvents(input: $input, condition: $condition) {
      id
      code
      name
      startDate
      endDate
      date
      time
      closingDay
      address
      place
      mapUrl
      price
      confirmationNeeded
      applyEnabled
      imagePath
      eventPageUrl
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteLeavesEvents = /* GraphQL */ `
  mutation DeleteLeavesEvents(
    $input: DeleteLeavesEventsInput!
    $condition: ModelLeavesEventsConditionInput
  ) {
    deleteLeavesEvents(input: $input, condition: $condition) {
      id
      code
      name
      startDate
      endDate
      date
      time
      closingDay
      address
      place
      mapUrl
      price
      confirmationNeeded
      applyEnabled
      imagePath
      eventPageUrl
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createLeavesMyEvent = /* GraphQL */ `
  mutation CreateLeavesMyEvent(
    $input: CreateLeavesMyEventInput!
    $condition: ModelLeavesMyEventConditionInput
  ) {
    createLeavesMyEvent(input: $input, condition: $condition) {
      id
      leavesusersID
      leavesEventsID
      confirmed
      applied
      note
      rate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateLeavesMyEvent = /* GraphQL */ `
  mutation UpdateLeavesMyEvent(
    $input: UpdateLeavesMyEventInput!
    $condition: ModelLeavesMyEventConditionInput
  ) {
    updateLeavesMyEvent(input: $input, condition: $condition) {
      id
      leavesusersID
      leavesEventsID
      confirmed
      applied
      note
      rate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteLeavesMyEvent = /* GraphQL */ `
  mutation DeleteLeavesMyEvent(
    $input: DeleteLeavesMyEventInput!
    $condition: ModelLeavesMyEventConditionInput
  ) {
    deleteLeavesMyEvent(input: $input, condition: $condition) {
      id
      leavesusersID
      leavesEventsID
      confirmed
      applied
      note
      rate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLeavesHakanitsuite = /* GraphQL */ `
  mutation CreateLeavesHakanitsuite(
    $input: CreateLeavesHakanitsuiteInput!
    $condition: ModelLeavesHakanitsuiteConditionInput
  ) {
    createLeavesHakanitsuite(input: $input, condition: $condition) {
      id
      hairuyoteinoohakanoumu
      kibousuruohakanokeishiki
      kibousurubasyo
      hiyoukyoshutsu
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateLeavesHakanitsuite = /* GraphQL */ `
  mutation UpdateLeavesHakanitsuite(
    $input: UpdateLeavesHakanitsuiteInput!
    $condition: ModelLeavesHakanitsuiteConditionInput
  ) {
    updateLeavesHakanitsuite(input: $input, condition: $condition) {
      id
      hairuyoteinoohakanoumu
      kibousuruohakanokeishiki
      kibousurubasyo
      hiyoukyoshutsu
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteLeavesHakanitsuite = /* GraphQL */ `
  mutation DeleteLeavesHakanitsuite(
    $input: DeleteLeavesHakanitsuiteInput!
    $condition: ModelLeavesHakanitsuiteConditionInput
  ) {
    deleteLeavesHakanitsuite(input: $input, condition: $condition) {
      id
      hairuyoteinoohakanoumu
      kibousuruohakanokeishiki
      kibousurubasyo
      hiyoukyoshutsu
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLeavesDaijinishitaikinenbi = /* GraphQL */ `
  mutation CreateLeavesDaijinishitaikinenbi(
    $input: CreateLeavesDaijinishitaikinenbiInput!
    $condition: ModelLeavesDaijinishitaikinenbiConditionInput
  ) {
    createLeavesDaijinishitaikinenbi(input: $input, condition: $condition) {
      id
      kinenbimei
      hiduke
      konohigachikazuitaratsuuchi
      tsuuchisakiKazokuShinzoku
      tsuuchisakiYuujinChijin
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateLeavesDaijinishitaikinenbi = /* GraphQL */ `
  mutation UpdateLeavesDaijinishitaikinenbi(
    $input: UpdateLeavesDaijinishitaikinenbiInput!
    $condition: ModelLeavesDaijinishitaikinenbiConditionInput
  ) {
    updateLeavesDaijinishitaikinenbi(input: $input, condition: $condition) {
      id
      kinenbimei
      hiduke
      konohigachikazuitaratsuuchi
      tsuuchisakiKazokuShinzoku
      tsuuchisakiYuujinChijin
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteLeavesDaijinishitaikinenbi = /* GraphQL */ `
  mutation DeleteLeavesDaijinishitaikinenbi(
    $input: DeleteLeavesDaijinishitaikinenbiInput!
    $condition: ModelLeavesDaijinishitaikinenbiConditionInput
  ) {
    deleteLeavesDaijinishitaikinenbi(input: $input, condition: $condition) {
      id
      kinenbimei
      hiduke
      konohigachikazuitaratsuuchi
      tsuuchisakiKazokuShinzoku
      tsuuchisakiYuujinChijin
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLeavesKakeizu = /* GraphQL */ `
  mutation CreateLeavesKakeizu(
    $input: CreateLeavesKakeizuInput!
    $condition: ModelLeavesKakeizuConditionInput
  ) {
    createLeavesKakeizu(input: $input, condition: $condition) {
      id
      filemei
      hokanbasyo
      bikou
      s3key
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateLeavesKakeizu = /* GraphQL */ `
  mutation UpdateLeavesKakeizu(
    $input: UpdateLeavesKakeizuInput!
    $condition: ModelLeavesKakeizuConditionInput
  ) {
    updateLeavesKakeizu(input: $input, condition: $condition) {
      id
      filemei
      hokanbasyo
      bikou
      s3key
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteLeavesKakeizu = /* GraphQL */ `
  mutation DeleteLeavesKakeizu(
    $input: DeleteLeavesKakeizuInput!
    $condition: ModelLeavesKakeizuConditionInput
  ) {
    deleteLeavesKakeizu(input: $input, condition: $condition) {
      id
      filemei
      hokanbasyo
      bikou
      s3key
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLeavesYuigonshonitsuite = /* GraphQL */ `
  mutation CreateLeavesYuigonshonitsuite(
    $input: CreateLeavesYuigonshonitsuiteInput!
    $condition: ModelLeavesYuigonshonitsuiteConditionInput
  ) {
    createLeavesYuigonshonitsuite(input: $input, condition: $condition) {
      id
      yuigonshonoumu
      yuigonshonoshurui
      yuigonshonosaisyuusakuseibi
      yuigonshikkousha
      yuigonshikkoushanorenrakusaki
      iraisoudanwoshiteirusenmonka
      iraisoudanwoshiteirusenmonkanorenrakusaki
      sonotasouzokunikansurukibounado
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateLeavesYuigonshonitsuite = /* GraphQL */ `
  mutation UpdateLeavesYuigonshonitsuite(
    $input: UpdateLeavesYuigonshonitsuiteInput!
    $condition: ModelLeavesYuigonshonitsuiteConditionInput
  ) {
    updateLeavesYuigonshonitsuite(input: $input, condition: $condition) {
      id
      yuigonshonoumu
      yuigonshonoshurui
      yuigonshonosaisyuusakuseibi
      yuigonshikkousha
      yuigonshikkoushanorenrakusaki
      iraisoudanwoshiteirusenmonka
      iraisoudanwoshiteirusenmonkanorenrakusaki
      sonotasouzokunikansurukibounado
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteLeavesYuigonshonitsuite = /* GraphQL */ `
  mutation DeleteLeavesYuigonshonitsuite(
    $input: DeleteLeavesYuigonshonitsuiteInput!
    $condition: ModelLeavesYuigonshonitsuiteConditionInput
  ) {
    deleteLeavesYuigonshonitsuite(input: $input, condition: $condition) {
      id
      yuigonshonoumu
      yuigonshonoshurui
      yuigonshonosaisyuusakuseibi
      yuigonshikkousha
      yuigonshikkoushanorenrakusaki
      iraisoudanwoshiteirusenmonka
      iraisoudanwoshiteirusenmonkanorenrakusaki
      sonotasouzokunikansurukibounado
      bikou
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLeavesTaisetsunishitaihitohenomessage = /* GraphQL */ `
  mutation CreateLeavesTaisetsunishitaihitohenomessage(
    $input: CreateLeavesTaisetsunishitaihitohenomessageInput!
    $condition: ModelLeavesTaisetsunishitaihitohenomessageConditionInput
  ) {
    createLeavesTaisetsunishitaihitohenomessage(
      input: $input
      condition: $condition
    ) {
      id
      messagetitle
      soushinsaki
      soushinsakiYuujinChijin
      messagenaiyou
      soushinyoteibi
      isSentMessage
      soushinbi
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateLeavesTaisetsunishitaihitohenomessage = /* GraphQL */ `
  mutation UpdateLeavesTaisetsunishitaihitohenomessage(
    $input: UpdateLeavesTaisetsunishitaihitohenomessageInput!
    $condition: ModelLeavesTaisetsunishitaihitohenomessageConditionInput
  ) {
    updateLeavesTaisetsunishitaihitohenomessage(
      input: $input
      condition: $condition
    ) {
      id
      messagetitle
      soushinsaki
      soushinsakiYuujinChijin
      messagenaiyou
      soushinyoteibi
      isSentMessage
      soushinbi
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteLeavesTaisetsunishitaihitohenomessage = /* GraphQL */ `
  mutation DeleteLeavesTaisetsunishitaihitohenomessage(
    $input: DeleteLeavesTaisetsunishitaihitohenomessageInput!
    $condition: ModelLeavesTaisetsunishitaihitohenomessageConditionInput
  ) {
    deleteLeavesTaisetsunishitaihitohenomessage(
      input: $input
      condition: $condition
    ) {
      id
      messagetitle
      soushinsaki
      soushinsakiYuujinChijin
      messagenaiyou
      soushinyoteibi
      isSentMessage
      soushinbi
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLeavesLoankariirekin = /* GraphQL */ `
  mutation CreateLeavesLoankariirekin(
    $input: CreateLeavesLoankariirekinInput!
    $condition: ModelLeavesLoankariirekinConditionInput
  ) {
    createLeavesLoankariirekin(input: $input, condition: $condition) {
      id
      kariiresaki
      kariiresakinorenrakusaki
      kariirebi
      kariiregaku
      hensaihouhou
      tanponoumu
      kariirezandaka
      kariirezandakakisaibi
      kariiremokuteki
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateLeavesLoankariirekin = /* GraphQL */ `
  mutation UpdateLeavesLoankariirekin(
    $input: UpdateLeavesLoankariirekinInput!
    $condition: ModelLeavesLoankariirekinConditionInput
  ) {
    updateLeavesLoankariirekin(input: $input, condition: $condition) {
      id
      kariiresaki
      kariiresakinorenrakusaki
      kariirebi
      kariiregaku
      hensaihouhou
      tanponoumu
      kariirezandaka
      kariirezandakakisaibi
      kariiremokuteki
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteLeavesLoankariirekin = /* GraphQL */ `
  mutation DeleteLeavesLoankariirekin(
    $input: DeleteLeavesLoankariirekinInput!
    $condition: ModelLeavesLoankariirekinConditionInput
  ) {
    deleteLeavesLoankariirekin(input: $input, condition: $condition) {
      id
      kariiresaki
      kariiresakinorenrakusaki
      kariirebi
      kariiregaku
      hensaihouhou
      tanponoumu
      kariirezandaka
      kariirezandakakisaibi
      kariiremokuteki
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLeavesHosyousaimu = /* GraphQL */ `
  mutation CreateLeavesHosyousaimu(
    $input: CreateLeavesHosyousaimuInput!
    $condition: ModelLeavesHosyousaimuConditionInput
  ) {
    createLeavesHosyousaimu(input: $input, condition: $condition) {
      id
      syusaimusya
      syusaimusyanorenrakusaki
      hosyoushitahi
      hosyoushitakingaku
      saikensya
      saikensyanorenrakusaki
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateLeavesHosyousaimu = /* GraphQL */ `
  mutation UpdateLeavesHosyousaimu(
    $input: UpdateLeavesHosyousaimuInput!
    $condition: ModelLeavesHosyousaimuConditionInput
  ) {
    updateLeavesHosyousaimu(input: $input, condition: $condition) {
      id
      syusaimusya
      syusaimusyanorenrakusaki
      hosyoushitahi
      hosyoushitakingaku
      saikensya
      saikensyanorenrakusaki
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteLeavesHosyousaimu = /* GraphQL */ `
  mutation DeleteLeavesHosyousaimu(
    $input: DeleteLeavesHosyousaimuInput!
    $condition: ModelLeavesHosyousaimuConditionInput
  ) {
    deleteLeavesHosyousaimu(input: $input, condition: $condition) {
      id
      syusaimusya
      syusaimusyanorenrakusaki
      hosyoushitahi
      hosyoushitakingaku
      saikensya
      saikensyanorenrakusaki
      bikou
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLeavesShareInfo = /* GraphQL */ `
  mutation CreateLeavesShareInfo(
    $input: CreateLeavesShareInfoInput!
    $condition: ModelLeavesShareInfoConditionInput
  ) {
    createLeavesShareInfo(input: $input, condition: $condition) {
      id
      name
      originDate
      target {
        dataName
        shareDate
        __typename
      }
      shareId
      shareIdExpiredDate
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateLeavesShareInfo = /* GraphQL */ `
  mutation UpdateLeavesShareInfo(
    $input: UpdateLeavesShareInfoInput!
    $condition: ModelLeavesShareInfoConditionInput
  ) {
    updateLeavesShareInfo(input: $input, condition: $condition) {
      id
      name
      originDate
      target {
        dataName
        shareDate
        __typename
      }
      shareId
      shareIdExpiredDate
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteLeavesShareInfo = /* GraphQL */ `
  mutation DeleteLeavesShareInfo(
    $input: DeleteLeavesShareInfoInput!
    $condition: ModelLeavesShareInfoConditionInput
  ) {
    deleteLeavesShareInfo(input: $input, condition: $condition) {
      id
      name
      originDate
      target {
        dataName
        shareDate
        __typename
      }
      shareId
      shareIdExpiredDate
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLeavesSharedInfo = /* GraphQL */ `
  mutation CreateLeavesSharedInfo(
    $input: CreateLeavesSharedInfoInput!
    $condition: ModelLeavesSharedInfoConditionInput
  ) {
    createLeavesSharedInfo(input: $input, condition: $condition) {
      id
      name
      shareInfoId
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateLeavesSharedInfo = /* GraphQL */ `
  mutation UpdateLeavesSharedInfo(
    $input: UpdateLeavesSharedInfoInput!
    $condition: ModelLeavesSharedInfoConditionInput
  ) {
    updateLeavesSharedInfo(input: $input, condition: $condition) {
      id
      name
      shareInfoId
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteLeavesSharedInfo = /* GraphQL */ `
  mutation DeleteLeavesSharedInfo(
    $input: DeleteLeavesSharedInfoInput!
    $condition: ModelLeavesSharedInfoConditionInput
  ) {
    deleteLeavesSharedInfo(input: $input, condition: $condition) {
      id
      name
      shareInfoId
      leavesusersID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLeavesSummaryWriting = /* GraphQL */ `
  mutation CreateLeavesSummaryWriting(
    $input: CreateLeavesSummaryWritingInput!
    $condition: ModelLeavesSummaryWritingConditionInput
  ) {
    createLeavesSummaryWriting(input: $input, condition: $condition) {
      id
      firstLoginAt
      lastLoginAt
      writingItems {
        itemModelName
        itemId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateLeavesSummaryWriting = /* GraphQL */ `
  mutation UpdateLeavesSummaryWriting(
    $input: UpdateLeavesSummaryWritingInput!
    $condition: ModelLeavesSummaryWritingConditionInput
  ) {
    updateLeavesSummaryWriting(input: $input, condition: $condition) {
      id
      firstLoginAt
      lastLoginAt
      writingItems {
        itemModelName
        itemId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteLeavesSummaryWriting = /* GraphQL */ `
  mutation DeleteLeavesSummaryWriting(
    $input: DeleteLeavesSummaryWritingInput!
    $condition: ModelLeavesSummaryWritingConditionInput
  ) {
    deleteLeavesSummaryWriting(input: $input, condition: $condition) {
      id
      firstLoginAt
      lastLoginAt
      writingItems {
        itemModelName
        itemId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLeavesMindTypeAnswerId = /* GraphQL */ `
  mutation CreateLeavesMindTypeAnswerId(
    $input: CreateLeavesMindTypeAnswerIdInput!
    $condition: ModelLeavesMindTypeAnswerIdConditionInput
  ) {
    createLeavesMindTypeAnswerId(input: $input, condition: $condition) {
      id
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateLeavesMindTypeAnswerId = /* GraphQL */ `
  mutation UpdateLeavesMindTypeAnswerId(
    $input: UpdateLeavesMindTypeAnswerIdInput!
    $condition: ModelLeavesMindTypeAnswerIdConditionInput
  ) {
    updateLeavesMindTypeAnswerId(input: $input, condition: $condition) {
      id
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteLeavesMindTypeAnswerId = /* GraphQL */ `
  mutation DeleteLeavesMindTypeAnswerId(
    $input: DeleteLeavesMindTypeAnswerIdInput!
    $condition: ModelLeavesMindTypeAnswerIdConditionInput
  ) {
    deleteLeavesMindTypeAnswerId(input: $input, condition: $condition) {
      id
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createLeavesMindTypeAnswer = /* GraphQL */ `
  mutation CreateLeavesMindTypeAnswer(
    $input: CreateLeavesMindTypeAnswerInput!
    $condition: ModelLeavesMindTypeAnswerConditionInput
  ) {
    createLeavesMindTypeAnswer(input: $input, condition: $condition) {
      answerId
      page
      questionNo
      answer
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateLeavesMindTypeAnswer = /* GraphQL */ `
  mutation UpdateLeavesMindTypeAnswer(
    $input: UpdateLeavesMindTypeAnswerInput!
    $condition: ModelLeavesMindTypeAnswerConditionInput
  ) {
    updateLeavesMindTypeAnswer(input: $input, condition: $condition) {
      answerId
      page
      questionNo
      answer
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteLeavesMindTypeAnswer = /* GraphQL */ `
  mutation DeleteLeavesMindTypeAnswer(
    $input: DeleteLeavesMindTypeAnswerInput!
    $condition: ModelLeavesMindTypeAnswerConditionInput
  ) {
    deleteLeavesMindTypeAnswer(input: $input, condition: $condition) {
      answerId
      page
      questionNo
      answer
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
