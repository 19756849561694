/* eslint-disable import/no-anonymous-default-export */
import { DataStore, Storage } from "aws-amplify";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import { DataStore } from "@aws-amplify/datastore";
import { LeavesJibunshi } from "../models";
import { FABDeleteEdit, LabelM, ListTwolineA, StrageImage } from "../page-components";
import { getLabel, useInitializePage } from "../utils/CommonUtils";
import { log } from "../utils/Logger";
import { isShare, listObjects, queryData } from "../utils/ShareUtils";

/*
  nengetsu: AWSDate!
  eventmei: String!
*/
export default function () {
  const navigate = useInitializePage("jibunshi", "title.detail");
  const [data, setData] = useState();
  const [image, setImage] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  let params = useParams();

  useEffect(async () => {
    const dataQuery = await queryData(params, LeavesJibunshi);
    log.debug("data", data);
    if (dataQuery.length > 0) {
      setData(dataQuery[0]);
    }
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (data?.id) {
      listObjects(params, LeavesJibunshi, data).then((res) => {
        const url = res.length > 0 ? res[0].key : null;
        setImage(url);
      });
    }
  }, [data, params, params.id]);

  const deleteData = async () => {
    log.debug("delete data", data);
    await DataStore.delete(data);
    await Storage.remove(image, {
      level: "private",
    });
    navigate(-1, { replace: true });
  };

  return (
    <div>
      <LabelM label={data?.eventmei} />
      {isLoaded && (
        <>
          <ListTwolineA
            label={getLabel("nengetsu")}
            value={data?.nengetsu}
            type="date"
            isEmptyHide
          />
          <ListTwolineA
            label={getLabel("eventmei")}
            value={data?.eventmei}
            isEmptyHide
          />
          <ListTwolineA
            label={getLabel("episode")}
            value={data?.episode}
            isEmptyHide
          />
          {image && <StrageImage src={image} />}
        </>
      )}
      {isShare(params) ? null : (
          <FABDeleteEdit
            onDelete={() => deleteData()}
            onEdit={() => {
              navigate("edit");
              window.gtag("event", "Change", {
                event_category: "Edit",
                event_label: "自分史変更開始",
              });
            }}
          />
        )}
    </div>
  );
}
