import forward from "../assets/image/forward_circle.png";
import PCListSingleLineTwoColumCheck from "../ui-components/PCListSingleLineTwoColumCheck";

export default function ListSingleLineTwoColumnCheck(props) {
  const { onClick, label, checked } = props;

  return (
    <PCListSingleLineTwoColumCheck
      onClick={onClick}
      className="pointer"
      width="100%"
      overrides={{
        "Frame 606": {
          width: "100%",
        },
        Item: {
          children: label,
          className: "text-overflow-ellipsis",
          maxWidth: "calc(100% - 70px)",
          whiteSpace: "pre",
          overflow: "hidden",
          display: "block",
          width: "auto",
        },
        "image 2": {
          left: "auto",
          right: "16px",
          position: "absolute",
          src: forward,
        },
        Icon: {
          paths: [
            {
              d: "M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM14.59 5.58L8 12.17L5.41 9.59L4 11L8 15L16 7L14.59 5.58Z",
              fill: checked ? "var(--my-theme-color)" : "#EFF0F0",
              fillRule: "nonzero",
              style: { transform: "translate(8.33%, 8.33%)" },
            },
          ],
        },
      }}
    />
  );
}
