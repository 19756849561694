import { SmallFAB } from "../ui-components";

export default function FABEditSmall(props) {
  const { ...rest } = props;

  return (
    <SmallFAB
      {...rest}
      marginLeft="auto"
      width="40px"
      height="40px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      padding="8px"
      overrides={{
        Icon: {
          width: "100%",
          height: "100%",
          paths: [
            {
              d: "M14.06 9.02L14.98 9.94L5.92 19H5V18.08L14.06 9.02ZM17.66 3C17.41 3 17.15 3.1 16.96 3.29L15.13 5.12L18.88 8.87L20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63L18.37 3.29C18.17 3.09 17.92 3 17.66 3ZM14.06 6.19L3 17.25V21H6.75L17.81 9.94L14.06 6.19Z",
              fill: "#688649",
            },
          ],
        },
      }}
    />
  );
}
