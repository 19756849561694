/* eslint-disable import/no-anonymous-default-export */
import { DataStore } from "aws-amplify";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LeavesKazokuShinzoku } from "../models";
import { FABDeleteEdit, LabelM, ListTwolineA } from "../page-components";
import { getLabel, useInitializePage } from "../utils/CommonUtils";
import { isShare, queryData } from "../utils/ShareUtils";

/*
  shimei: String!
  zokugara: String!
  seinengappi: AWSDate
  yuubinbangou: String
  juusho: String
  denwabangou: String
  nyuuinjirenraku: Renrakushurui
  sougijirenraku: Renrakushurui
  bikou: String
*/

export default function () {
  const navigate = useInitializePage("kazokushinzokuichiran", "title.detail");
  const [data, setData] = useState();

  let params = useParams();

  useEffect(async () => {
    const dsData = await queryData(params, LeavesKazokuShinzoku);
    console.log("data", dsData);
    if (dsData.length > 0) {
      setData(dsData[0]);
    }
  }, []);

  const deleteData = async () => {
    console.log("delete data", data);
    await DataStore.delete(data);
    navigate(-1, { replace: true });
  };

  return (
    <div>
      <LabelM label={data?.shimei} />
      <ListTwolineA label={getLabel("shimei")} value={data?.shimei} />
      <ListTwolineA isEmptyHide label={getLabel("zokugara")} value={data?.zokugara} />
      <ListTwolineA isEmptyHide label={getLabel("seinengappi")} value={data?.seinengappi} type="date" />
      <ListTwolineA isEmptyHide label={getLabel("yuubinbangou")} value={data?.yuubinbangou} />
      <ListTwolineA isEmptyHide label={getLabel("juusho")} value={data?.juusho} />
      <ListTwolineA isEmptyHide label={getLabel("denwabangou")} value={data?.denwabangou} />
      <ListTwolineA isEmptyHide label={getLabel("mailaddress")} value={data?.mailaddress} />
      <ListTwolineA isEmptyHide label={getLabel("bikou")} value={data?.bikou} />
      {isShare(params) ? null : (
        <FABDeleteEdit
          onDelete={() => deleteData()}
          onEdit={() => {
            navigate("edit");
            window.gtag("event", "Change", {
              event_category: "Edit",
              event_label: "家族・親族変更開始",
            });
          }}
        />
      )}
    </div>
  );
}
