import { PCListSingleLineD } from "../ui-components";

export default function ListSingleLineD(props) {
  return (
    <PCListSingleLineD
      className="pointer"
      margin="0"
      onClick={props.onClick}
      backgroundColor={props.selected && "#DFE2DC"}
      maxWidth="288px"
      width="100%"
      overrides={{
        list: {
          children: props.label,
          className: "text-overflow-ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
          display: "block",
        },
      }}
      style={{
        transition: "0.3s",
      }}
      {...props}
    />
  );
}
