/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Icon, Image, Text, View } from "@aws-amplify/ui-react";
export default function PCAboutHiraqlNote(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="960px"
      height="5153.69px"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "PCAboutHiraqlNote")}
    >
      <Flex
        gap="0"
        position="absolute"
        top="0%"
        bottom="94.49%"
        left="0%"
        right="0%"
        direction="column"
        width="960px"
        height="284px"
        borderRadius="4px"
        padding="0px 32px 32px 32px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "About")}
      >
        <Flex
          gap="0"
          direction="row"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="16px 0px 16px 0px"
          {...getOverrideProps(overrides, "PC_LabelM_About")}
        >
          <Text
            fontFamily="Inter"
            fontSize="21px"
            fontWeight="700"
            color="rgba(0,0,0,1)"
            lineHeight="36px"
            textAlign="left"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            width="896px"
            grow="1"
            basis="896px"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="HIRAQL NOTEについて"
            {...getOverrideProps(
              overrides,
              "HIRAQL NOTE\u306B\u3064\u3044\u3066"
            )}
          ></Text>
        </Flex>
        <Icon
          width="896px"
          height="0px"
          viewBox={{ minX: 0, minY: 0, width: 896, height: 1 }}
          paths={[
            {
              d: "M0 0.5L896 0.5L896 -0.5L0 -0.5L0 0.5Z",
              stroke: "rgba(174,179,183,1)",
              fillRule: "nonzero",
              strokeWidth: 1,
            },
          ]}
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          {...getOverrideProps(overrides, "Vector 1")}
        ></Icon>
        <Flex
          gap="10px"
          direction="row"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="16px 0px 16px 0px"
          {...getOverrideProps(overrides, "PC_Description_About")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color="rgba(28,26,29,1)"
            lineHeight="24px"
            textAlign="left"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            letterSpacing="0.01px"
            width="896px"
            grow="1"
            basis="896px"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="自分のデータや意思を整理し、記していくためのオンラインノートです。人生でやりたいことの具体的なプランを立てたり、ヒントコラムやイベントを通じて感じたことを生活に持ち帰り、記録していくことができます。​&#xA;書いてみて発見することもたくさんあるはず。何度でも書き直し、書き足していきましょう。時間が経つと意外に気持ちが変わり、書き直してみたくなるものです。まずはお好きな項目から、気軽に書き始めてみてください。"
            {...getOverrideProps(
              overrides,
              "\u81EA\u5206\u306E\u30C7\u30FC\u30BF\u3084\u610F\u601D\u3092\u6574\u7406\u3057\u3001\u8A18\u3057\u3066\u3044\u304F\u305F\u3081\u306E\u30AA\u30F3\u30E9\u30A4\u30F3\u30CE\u30FC\u30C8\u3067\u3059\u3002\u4EBA\u751F\u3067\u3084\u308A\u305F\u3044\u3053\u3068\u306E\u5177\u4F53\u7684\u306A\u30D7\u30E9\u30F3\u3092\u7ACB\u3066\u305F\u308A\u3001\u30D2\u30F3\u30C8\u30B3\u30E9\u30E0\u3084\u30A4\u30D9\u30F3\u30C8\u3092\u901A\u3058\u3066\u611F\u3058\u305F\u3053\u3068\u3092\u751F\u6D3B\u306B\u6301\u3061\u5E30\u308A\u3001\u8A18\u9332\u3057\u3066\u3044\u304F\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002\u200B \u66F8\u3044\u3066\u307F\u3066\u767A\u898B\u3059\u308B\u3053\u3068\u3082\u305F\u304F\u3055\u3093\u3042\u308B\u306F\u305A\u3002\u4F55\u5EA6\u3067\u3082\u66F8\u304D\u76F4\u3057\u3001\u66F8\u304D\u8DB3\u3057\u3066\u3044\u304D\u307E\u3057\u3087\u3046\u3002\u6642\u9593\u304C\u7D4C\u3064\u3068\u610F\u5916\u306B\u6C17\u6301\u3061\u304C\u5909\u308F\u308A\u3001\u66F8\u304D\u76F4\u3057\u3066\u307F\u305F\u304F\u306A\u308B\u3082\u306E\u3067\u3059\u3002\u307E\u305A\u306F\u304A\u597D\u304D\u306A\u9805\u76EE\u304B\u3089\u3001\u6C17\u8EFD\u306B\u66F8\u304D\u59CB\u3081\u3066\u307F\u3066\u304F\u3060\u3055\u3044\u3002"
            )}
          ></Text>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="fit-content"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "AboutLink")}
        >
          <Flex
            gap="10px"
            direction="row"
            width="fit-content"
            alignItems="flex-start"
            shrink="0"
            height="56px"
            position="relative"
            padding="16px 0px 16px 0px"
            {...getOverrideProps(overrides, "PC_Description_About2")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(28,26,29,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.01px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="HIRAQL NOTEをスマートフォンでホーム画面に追加する方法は"
              {...getOverrideProps(
                overrides,
                "HIRAQL NOTE\u3092\u30B9\u30DE\u30FC\u30C8\u30D5\u30A9\u30F3\u3067\u30DB\u30FC\u30E0\u753B\u9762\u306B\u8FFD\u52A0\u3059\u308B\u65B9\u6CD5\u306F"
              )}
            ></Text>
          </Flex>
          <Flex
            gap="10px"
            direction="row"
            width="fit-content"
            alignItems="flex-start"
            shrink="0"
            height="36px"
            position="relative"
            padding="8px 8px 8px 8px"
            {...getOverrideProps(overrides, "Link_AddHome")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="700"
              color="rgba(104,134,73,1)"
              lineHeight="20px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.49px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="こちら"
              {...getOverrideProps(overrides, "\u3053\u3061\u3089")}
            ></Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="48px"
        position="absolute"
        top="6.13%"
        bottom="88.28%"
        left="0%"
        right="0%"
        direction="row"
        width="960px"
        height="288px"
        alignItems="flex-start"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Data")}
      >
        <Image
          width="384px"
          height="288px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "DataSample")}
        ></Image>
        <Flex
          gap="0"
          direction="column"
          width="528px"
          grow="1"
          basis="528px"
          height="268px"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "DataText")}
        >
          <Flex
            gap="32px"
            direction="row"
            width="fit-content"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "DataTitle")}
          >
            <Image
              width="60px"
              height="60px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "DataFull")}
            ></Image>
            <Text
              fontFamily="Zen Kaku Gothic New"
              fontSize="28px"
              fontWeight="500"
              color="rgba(0,0,0,1)"
              lineHeight="41px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="私のデータ"
              {...getOverrideProps(overrides, "\u79C1\u306E\u30C7\u30FC\u30BF")}
            ></Text>
          </Flex>
          <Flex
            gap="10px"
            direction="row"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="16px 0px 16px 0px"
            {...getOverrideProps(overrides, "PC_Description_Data")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(28,26,29,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.01px"
              width="528px"
              grow="1"
              basis="528px"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="金融資産、保険、友人の連絡先、スマホやPCの情報、ペットのことなどを整理しておくためのページ。記入しておくと、日常生活の中ではもちろん、”もしもの時”に自分自身や家族がとても助かります。"
              {...getOverrideProps(
                overrides,
                "\u91D1\u878D\u8CC7\u7523\u3001\u4FDD\u967A\u3001\u53CB\u4EBA\u306E\u9023\u7D61\u5148\u3001\u30B9\u30DE\u30DB\u3084PC\u306E\u60C5\u5831\u3001\u30DA\u30C3\u30C8\u306E\u3053\u3068\u306A\u3069\u3092\u6574\u7406\u3057\u3066\u304A\u304F\u305F\u3081\u306E\u30DA\u30FC\u30B8\u3002\u8A18\u5165\u3057\u3066\u304A\u304F\u3068\u3001\u65E5\u5E38\u751F\u6D3B\u306E\u4E2D\u3067\u306F\u3082\u3061\u308D\u3093\u3001\u201D\u3082\u3057\u3082\u306E\u6642\u201D\u306B\u81EA\u5206\u81EA\u8EAB\u3084\u5BB6\u65CF\u304C\u3068\u3066\u3082\u52A9\u304B\u308A\u307E\u3059\u3002"
              )}
            ></Text>
          </Flex>
          <Flex
            gap="8px"
            direction="column"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "DataPoint")}
          >
            <Flex
              gap="16px"
              direction="row"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "DataPoint1")}
            >
              <Icon
                width="24px"
                height="24px"
                shrink="0"
                overflow="hidden"
                position="relative"
                padding="0px 0px 0px 0px"
                type="done"
                fontSize="24px"
                {...getOverrideProps(overrides, "Icon613016052")}
              ></Icon>
              <Flex
                gap="16px"
                direction="row"
                width="488px"
                alignItems="flex-start"
                grow="1"
                basis="488px"
                height="48px"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 556613016056")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(28,26,29,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="flex"
                  direction="column"
                  justifyContent="flex-start"
                  letterSpacing="0.01px"
                  width="488px"
                  grow="1"
                  basis="488px"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="シェア機能を使って、登録したデータを、もしもの時のために、家族など大切な人と共有しておくと良いかもしれません"
                  {...getOverrideProps(
                    overrides,
                    "\u30B7\u30A7\u30A2\u6A5F\u80FD\u3092\u4F7F\u3063\u3066\u3001\u767B\u9332\u3057\u305F\u30C7\u30FC\u30BF\u3092\u3001\u3082\u3057\u3082\u306E\u6642\u306E\u305F\u3081\u306B\u3001\u5BB6\u65CF\u306A\u3069\u5927\u5207\u306A\u4EBA\u3068\u5171\u6709\u3057\u3066\u304A\u304F\u3068\u826F\u3044\u304B\u3082\u3057\u308C\u307E\u305B\u3093"
                  )}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="16px"
              direction="row"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "DataPoint2")}
            >
              <Icon
                width="24px"
                height="24px"
                shrink="0"
                overflow="hidden"
                position="relative"
                padding="0px 0px 0px 0px"
                type="done"
                fontSize="24px"
                {...getOverrideProps(overrides, "Icon613016059")}
              ></Icon>
              <Flex
                gap="16px"
                direction="row"
                width="488px"
                alignItems="flex-start"
                grow="1"
                basis="488px"
                height="48px"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 556613016060")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(28,26,29,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="flex"
                  direction="column"
                  justifyContent="flex-start"
                  letterSpacing="0.01px"
                  width="488px"
                  grow="1"
                  basis="488px"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="大切にしたい記念日をメモしたり、自分が決めたタイミングで、自分が決めた人に、リマインドしてみてはどうでしょう"
                  {...getOverrideProps(
                    overrides,
                    "\u5927\u5207\u306B\u3057\u305F\u3044\u8A18\u5FF5\u65E5\u3092\u30E1\u30E2\u3057\u305F\u308A\u3001\u81EA\u5206\u304C\u6C7A\u3081\u305F\u30BF\u30A4\u30DF\u30F3\u30B0\u3067\u3001\u81EA\u5206\u304C\u6C7A\u3081\u305F\u4EBA\u306B\u3001\u30EA\u30DE\u30A4\u30F3\u30C9\u3057\u3066\u307F\u3066\u306F\u3069\u3046\u3067\u3057\u3087\u3046"
                  )}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="48px"
        position="absolute"
        top="12.96%"
        bottom="79.82%"
        left="0%"
        right="0%"
        direction="row"
        width="960px"
        height="372px"
        alignItems="flex-start"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Will")}
      >
        <Flex
          gap="0"
          direction="column"
          width="528px"
          grow="1"
          basis="528px"
          height="372px"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "WillText")}
        >
          <Flex
            gap="32px"
            direction="row"
            width="fit-content"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "WillTitle")}
          >
            <Image
              width="60px"
              height="60px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "WillFull")}
            ></Image>
            <Text
              fontFamily="Zen Kaku Gothic New"
              fontSize="28px"
              fontWeight="500"
              color="rgba(0,0,0,1)"
              lineHeight="41px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="私の意思"
              {...getOverrideProps(overrides, "\u79C1\u306E\u610F\u601D")}
            ></Text>
          </Flex>
          <Flex
            gap="10px"
            direction="row"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="16px 0px 16px 0px"
            {...getOverrideProps(overrides, "PC_Description_Will")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(28,26,29,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.01px"
              width="528px"
              grow="1"
              basis="528px"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="自分の”意思”を示すためのページ。人生でやりたいこと、行ってみたい場所、夢や野望の実現プラン、介護や医療措置、相続、葬儀、お墓など、次世代に何をどのように遺していくのか。一度きりの人生を自分らしく生きるために考えてみましょう。"
              {...getOverrideProps(
                overrides,
                "\u81EA\u5206\u306E\u201D\u610F\u601D\u201D\u3092\u793A\u3059\u305F\u3081\u306E\u30DA\u30FC\u30B8\u3002\u4EBA\u751F\u3067\u3084\u308A\u305F\u3044\u3053\u3068\u3001\u884C\u3063\u3066\u307F\u305F\u3044\u5834\u6240\u3001\u5922\u3084\u91CE\u671B\u306E\u5B9F\u73FE\u30D7\u30E9\u30F3\u3001\u4ECB\u8B77\u3084\u533B\u7642\u63AA\u7F6E\u3001\u76F8\u7D9A\u3001\u846C\u5100\u3001\u304A\u5893\u306A\u3069\u3001\u6B21\u4E16\u4EE3\u306B\u4F55\u3092\u3069\u306E\u3088\u3046\u306B\u907A\u3057\u3066\u3044\u304F\u306E\u304B\u3002\u4E00\u5EA6\u304D\u308A\u306E\u4EBA\u751F\u3092\u81EA\u5206\u3089\u3057\u304F\u751F\u304D\u308B\u305F\u3081\u306B\u8003\u3048\u3066\u307F\u307E\u3057\u3087\u3046\u3002"
              )}
            ></Text>
          </Flex>
          <Flex
            gap="8px"
            direction="column"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "WillPoint")}
          >
            <Flex
              gap="16px"
              direction="row"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "WillPoint1")}
            >
              <Icon
                width="24px"
                height="24px"
                shrink="0"
                overflow="hidden"
                position="relative"
                padding="0px 0px 0px 0px"
                type="done"
                fontSize="24px"
                {...getOverrideProps(overrides, "Icon613016069")}
              ></Icon>
              <Flex
                gap="16px"
                direction="row"
                width="488px"
                alignItems="flex-start"
                grow="1"
                basis="488px"
                height="48px"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 556613016070")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(28,26,29,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="flex"
                  direction="column"
                  justifyContent="flex-start"
                  letterSpacing="0.01px"
                  width="488px"
                  grow="1"
                  basis="488px"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="やりたいことリストを使って、いつかやろうと思っていたことを実現してみましょう"
                  {...getOverrideProps(
                    overrides,
                    "\u3084\u308A\u305F\u3044\u3053\u3068\u30EA\u30B9\u30C8\u3092\u4F7F\u3063\u3066\u3001\u3044\u3064\u304B\u3084\u308D\u3046\u3068\u601D\u3063\u3066\u3044\u305F\u3053\u3068\u3092\u5B9F\u73FE\u3057\u3066\u307F\u307E\u3057\u3087\u3046"
                  )}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="16px"
              direction="row"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "WillPoint2")}
            >
              <Icon
                width="24px"
                height="24px"
                shrink="0"
                overflow="hidden"
                position="relative"
                padding="0px 0px 0px 0px"
                type="done"
                fontSize="24px"
                {...getOverrideProps(overrides, "Icon613016073")}
              ></Icon>
              <Flex
                gap="16px"
                direction="row"
                width="488px"
                alignItems="flex-start"
                grow="1"
                basis="488px"
                height="72px"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 556613016074")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(28,26,29,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="flex"
                  direction="column"
                  justifyContent="flex-start"
                  letterSpacing="0.01px"
                  width="488px"
                  grow="1"
                  basis="488px"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="大切な人へのビデオメッセージをオンラインに保管して、自分が決めたタイミング（例えばお子様のご結婚など）で、大切な人に届けてみませんか"
                  {...getOverrideProps(
                    overrides,
                    "\u5927\u5207\u306A\u4EBA\u3078\u306E\u30D3\u30C7\u30AA\u30E1\u30C3\u30BB\u30FC\u30B8\u3092\u30AA\u30F3\u30E9\u30A4\u30F3\u306B\u4FDD\u7BA1\u3057\u3066\u3001\u81EA\u5206\u304C\u6C7A\u3081\u305F\u30BF\u30A4\u30DF\u30F3\u30B0\uFF08\u4F8B\u3048\u3070\u304A\u5B50\u69D8\u306E\u3054\u7D50\u5A5A\u306A\u3069\uFF09\u3067\u3001\u5927\u5207\u306A\u4EBA\u306B\u5C4A\u3051\u3066\u307F\u307E\u305B\u3093\u304B"
                  )}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="16px"
              direction="row"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "WillPoint3")}
            >
              <Icon
                width="24px"
                height="24px"
                shrink="0"
                overflow="hidden"
                position="relative"
                padding="0px 0px 0px 0px"
                type="done"
                fontSize="24px"
                {...getOverrideProps(overrides, "Icon613016084")}
              ></Icon>
              <Flex
                gap="16px"
                direction="row"
                width="488px"
                alignItems="flex-start"
                shrink="0"
                height="48px"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 556613016085")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(28,26,29,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="flex"
                  direction="column"
                  justifyContent="flex-start"
                  letterSpacing="0.01px"
                  width="488px"
                  grow="1"
                  basis="488px"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="誰かに相談したり、行動に移したくなった際には、ノートからリアルなサービスをご案内します"
                  {...getOverrideProps(
                    overrides,
                    "\u8AB0\u304B\u306B\u76F8\u8AC7\u3057\u305F\u308A\u3001\u884C\u52D5\u306B\u79FB\u3057\u305F\u304F\u306A\u3063\u305F\u969B\u306B\u306F\u3001\u30CE\u30FC\u30C8\u304B\u3089\u30EA\u30A2\u30EB\u306A\u30B5\u30FC\u30D3\u30B9\u3092\u3054\u6848\u5185\u3057\u307E\u3059"
                  )}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Image
          width="384px"
          height="288px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "WillSample")}
        ></Image>
      </Flex>
      <Flex
        gap="48px"
        position="absolute"
        top="28.33%"
        bottom="66.08%"
        left="0%"
        right="0%"
        direction="row"
        width="960px"
        height="288px"
        alignItems="flex-start"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Hint")}
      >
        <Flex
          gap="0"
          direction="column"
          width="528px"
          grow="1"
          basis="528px"
          height="188px"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "HintText")}
        >
          <Flex
            gap="32px"
            direction="row"
            width="fit-content"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "HintTitle")}
          >
            <Image
              width="60px"
              height="60px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "HintFull")}
            ></Image>
            <Text
              fontFamily="Zen Kaku Gothic New"
              fontSize="28px"
              fontWeight="500"
              color="rgba(0,0,0,1)"
              lineHeight="41px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="ヒント"
              {...getOverrideProps(overrides, "\u30D2\u30F3\u30C8")}
            ></Text>
          </Flex>
          <Flex
            gap="10px"
            direction="row"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="16px 0px 16px 0px"
            {...getOverrideProps(overrides, "PC_Description_Hint")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(28,26,29,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.01px"
              width="528px"
              grow="1"
              basis="528px"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="HIRAQLのヒントコラムを読んで、気に入った記事、読み返したい記事をストックしていくためのページ。HIRAQL NOTEを書き進める時、自分の暮らしや考え方に変化があった時に、読み返すと新しい発見がありそうです。予約済みのイベントもこのページで確認できます。"
              {...getOverrideProps(
                overrides,
                "HIRAQL\u306E\u30D2\u30F3\u30C8\u30B3\u30E9\u30E0\u3092\u8AAD\u3093\u3067\u3001\u6C17\u306B\u5165\u3063\u305F\u8A18\u4E8B\u3001\u8AAD\u307F\u8FD4\u3057\u305F\u3044\u8A18\u4E8B\u3092\u30B9\u30C8\u30C3\u30AF\u3057\u3066\u3044\u304F\u305F\u3081\u306E\u30DA\u30FC\u30B8\u3002HIRAQL NOTE\u3092\u66F8\u304D\u9032\u3081\u308B\u6642\u3001\u81EA\u5206\u306E\u66AE\u3089\u3057\u3084\u8003\u3048\u65B9\u306B\u5909\u5316\u304C\u3042\u3063\u305F\u6642\u306B\u3001\u8AAD\u307F\u8FD4\u3059\u3068\u65B0\u3057\u3044\u767A\u898B\u304C\u3042\u308A\u305D\u3046\u3067\u3059\u3002\u4E88\u7D04\u6E08\u307F\u306E\u30A4\u30D9\u30F3\u30C8\u3082\u3053\u306E\u30DA\u30FC\u30B8\u3067\u78BA\u8A8D\u3067\u304D\u307E\u3059\u3002"
              )}
            ></Text>
          </Flex>
        </Flex>
        <Image
          width="384px"
          height="288px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "HintSample")}
        ></Image>
      </Flex>
      <Flex
        gap="48px"
        position="absolute"
        top="21.42%"
        bottom="72.91%"
        left="0%"
        right="0%"
        direction="row"
        width="960px"
        height="292px"
        alignItems="flex-start"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "History")}
      >
        <Image
          width="384px"
          height="288px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "HistorySample")}
        ></Image>
        <Flex
          gap="0"
          direction="column"
          width="528px"
          grow="1"
          basis="528px"
          height="292px"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "HistoryText")}
        >
          <Flex
            gap="32px"
            direction="row"
            width="fit-content"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "HistoryTitle")}
          >
            <Image
              width="60px"
              height="60px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "HistoryFull")}
            ></Image>
            <Text
              fontFamily="Zen Kaku Gothic New"
              fontSize="28px"
              fontWeight="500"
              color="rgba(0,0,0,1)"
              lineHeight="41px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="大切なもの"
              {...getOverrideProps(overrides, "\u5927\u5207\u306A\u3082\u306E")}
            ></Text>
          </Flex>
          <Flex
            gap="10px"
            direction="row"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="16px 0px 16px 0px"
            {...getOverrideProps(overrides, "PC_Description_History")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(28,26,29,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.01px"
              width="528px"
              grow="1"
              basis="528px"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="自分史や家系図、宝物リストなどを記していくためのページ。これまでの人生を振り返ると、人との繋がりや本当に大切なものが整理されていくはず。そしてこれから何を大切にして生きていきたいかも見えてくるかもしれません。"
              {...getOverrideProps(
                overrides,
                "\u81EA\u5206\u53F2\u3084\u5BB6\u7CFB\u56F3\u3001\u5B9D\u7269\u30EA\u30B9\u30C8\u306A\u3069\u3092\u8A18\u3057\u3066\u3044\u304F\u305F\u3081\u306E\u30DA\u30FC\u30B8\u3002\u3053\u308C\u307E\u3067\u306E\u4EBA\u751F\u3092\u632F\u308A\u8FD4\u308B\u3068\u3001\u4EBA\u3068\u306E\u7E4B\u304C\u308A\u3084\u672C\u5F53\u306B\u5927\u5207\u306A\u3082\u306E\u304C\u6574\u7406\u3055\u308C\u3066\u3044\u304F\u306F\u305A\u3002\u305D\u3057\u3066\u3053\u308C\u304B\u3089\u4F55\u3092\u5927\u5207\u306B\u3057\u3066\u751F\u304D\u3066\u3044\u304D\u305F\u3044\u304B\u3082\u898B\u3048\u3066\u304F\u308B\u304B\u3082\u3057\u308C\u307E\u305B\u3093\u3002"
              )}
            ></Text>
          </Flex>
          <Flex
            gap="8px"
            direction="column"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "HistoryCheck")}
          >
            <Flex
              gap="16px"
              direction="row"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "HistoryCheck1")}
            >
              <Icon
                width="24px"
                height="24px"
                shrink="0"
                overflow="hidden"
                position="relative"
                padding="0px 0px 0px 0px"
                type="done"
                fontSize="24px"
                {...getOverrideProps(overrides, "Icon613016094")}
              ></Icon>
              <Flex
                gap="16px"
                direction="row"
                width="488px"
                alignItems="flex-start"
                grow="1"
                basis="488px"
                height="48px"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 556613016095")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(28,26,29,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="flex"
                  direction="column"
                  justifyContent="flex-start"
                  letterSpacing="0.01px"
                  width="488px"
                  grow="1"
                  basis="488px"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="思い出の写真とともに自分史を振り返り、家族とシェアしながら、これからのことを話す機会を作ってみてはどうでしょう"
                  {...getOverrideProps(
                    overrides,
                    "\u601D\u3044\u51FA\u306E\u5199\u771F\u3068\u3068\u3082\u306B\u81EA\u5206\u53F2\u3092\u632F\u308A\u8FD4\u308A\u3001\u5BB6\u65CF\u3068\u30B7\u30A7\u30A2\u3057\u306A\u304C\u3089\u3001\u3053\u308C\u304B\u3089\u306E\u3053\u3068\u3092\u8A71\u3059\u6A5F\u4F1A\u3092\u4F5C\u3063\u3066\u307F\u3066\u306F\u3069\u3046\u3067\u3057\u3087\u3046"
                  )}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="16px"
              direction="row"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "HistoryCheck2")}
            >
              <Icon
                width="24px"
                height="24px"
                shrink="0"
                overflow="hidden"
                position="relative"
                padding="0px 0px 0px 0px"
                type="done"
                fontSize="24px"
                {...getOverrideProps(overrides, "Icon613016098")}
              ></Icon>
              <Flex
                gap="16px"
                direction="row"
                width="488px"
                alignItems="flex-start"
                grow="1"
                basis="488px"
                height="48px"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 556613016099")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(28,26,29,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="flex"
                  direction="column"
                  justifyContent="flex-start"
                  letterSpacing="0.01px"
                  width="488px"
                  grow="1"
                  basis="488px"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="宝物リストを整理しながら、託したい人と話してみるのも良いかもしれません"
                  {...getOverrideProps(
                    overrides,
                    "\u5B9D\u7269\u30EA\u30B9\u30C8\u3092\u6574\u7406\u3057\u306A\u304C\u3089\u3001\u8A17\u3057\u305F\u3044\u4EBA\u3068\u8A71\u3057\u3066\u307F\u308B\u306E\u3082\u826F\u3044\u304B\u3082\u3057\u308C\u307E\u305B\u3093"
                  )}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="48px"
        position="absolute"
        top="35.16%"
        bottom="60.96%"
        left="0%"
        right="0%"
        direction="row"
        width="960px"
        height="200px"
        alignItems="center"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 581")}
      >
        <Flex
          gap="12px"
          direction="column"
          width="384px"
          height="200px"
          alignItems="center"
          shrink="0"
          position="relative"
          borderRadius="3px"
          padding="32px 0px 22px 0px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "PC_CardCheck")}
        >
          <Flex
            gap="10px"
            direction="row"
            width="fit-content"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Icon614216420")}
          >
            <Image
              width="50px"
              height="50px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Check")}
            ></Image>
          </Flex>
          <Flex
            gap="8px"
            direction="column"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Text614216422")}
          >
            <Text
              fontFamily="Zen Kaku Gothic New"
              fontSize="17px"
              fontWeight="500"
              color="rgba(28,26,29,1)"
              lineHeight="31px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="これだけやっておけば安心"
              {...getOverrideProps(
                overrides,
                "\u3053\u308C\u3060\u3051\u3084\u3063\u3066\u304A\u3051\u3070\u5B89\u5FC3"
              )}
            ></Text>
            <Text
              fontFamily="Zen Kaku Gothic New"
              fontSize="30px"
              fontWeight="500"
              color="rgba(28,26,29,1)"
              lineHeight="33px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="CHECK!"
              {...getOverrideProps(overrides, "CHECK!")}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="10px"
          direction="row"
          width="528px"
          alignItems="flex-start"
          shrink="0"
          height="104px"
          position="relative"
          padding="16px 0px 16px 0px"
          {...getOverrideProps(overrides, "PC_Description_Check")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color="rgba(28,26,29,1)"
            lineHeight="24px"
            textAlign="left"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            letterSpacing="0.01px"
            width="528px"
            grow="1"
            basis="528px"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="「まずはこの項目を記入しておけば、”もしもの時”に安心！助かる！」という項目を厳選しました。何から始めようか...と途方に暮れている方は、まずはここから始めてみてください。"
            {...getOverrideProps(
              overrides,
              "\u300C\u307E\u305A\u306F\u3053\u306E\u9805\u76EE\u3092\u8A18\u5165\u3057\u3066\u304A\u3051\u3070\u3001\u201D\u3082\u3057\u3082\u306E\u6642\u201D\u306B\u5B89\u5FC3\uFF01\u52A9\u304B\u308B\uFF01\u300D\u3068\u3044\u3046\u9805\u76EE\u3092\u53B3\u9078\u3057\u307E\u3057\u305F\u3002\u4F55\u304B\u3089\u59CB\u3081\u3088\u3046\u304B...\u3068\u9014\u65B9\u306B\u66AE\u308C\u3066\u3044\u308B\u65B9\u306F\u3001\u307E\u305A\u306F\u3053\u3053\u304B\u3089\u59CB\u3081\u3066\u307F\u3066\u304F\u3060\u3055\u3044\u3002614216418"
            )}
          ></Text>
        </Flex>
      </Flex>
      <Flex
        gap="48px"
        position="absolute"
        top="40.28%"
        bottom="55.84%"
        left="0%"
        right="0%"
        direction="row"
        width="960px"
        height="200px"
        alignItems="center"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Share777517823")}
      >
        <Flex
          gap="10px"
          direction="row"
          width="528px"
          alignItems="flex-start"
          shrink="0"
          height="200px"
          position="relative"
          padding="16px 0px 16px 0px"
          {...getOverrideProps(overrides, "PC_Description_Share")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color="rgba(28,26,29,1)"
            lineHeight="24px"
            textAlign="left"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            letterSpacing="0.01px"
            width="528px"
            grow="1"
            basis="528px"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="HIRAQL NOTEに記入したデータや意思を家族など大切な人にシェアできます。誰に、どの情報を、いつ共有するのか設定をしましょう。設定をしておくと”もしものとき”に確実にご自身の意思が伝わり、大切な人を助けることができます。&#xA;&#xA;記入ができた項目から気軽に設定をはじめてみましょう。公開日は未来日付に設定できます。また、設定後も編集は可能です。"
            {...getOverrideProps(
              overrides,
              "\u300C\u307E\u305A\u306F\u3053\u306E\u9805\u76EE\u3092\u8A18\u5165\u3057\u3066\u304A\u3051\u3070\u3001\u201D\u3082\u3057\u3082\u306E\u6642\u201D\u306B\u5B89\u5FC3\uFF01\u52A9\u304B\u308B\uFF01\u300D\u3068\u3044\u3046\u9805\u76EE\u3092\u53B3\u9078\u3057\u307E\u3057\u305F\u3002\u4F55\u304B\u3089\u59CB\u3081\u3088\u3046\u304B...\u3068\u9014\u65B9\u306B\u66AE\u308C\u3066\u3044\u308B\u65B9\u306F\u3001\u307E\u305A\u306F\u3053\u3053\u304B\u3089\u59CB\u3081\u3066\u307F\u3066\u304F\u3060\u3055\u3044\u3002777517831"
            )}
          ></Text>
        </Flex>
        <Flex
          gap="12px"
          direction="column"
          width="384px"
          height="200px"
          alignItems="center"
          shrink="0"
          position="relative"
          borderRadius="3px"
          padding="32px 0px 22px 0px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "PC_CardShare")}
        >
          <Flex
            gap="10px"
            direction="row"
            width="fit-content"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Icon777517825")}
          >
            <Image
              width="50px"
              height="51.64px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Share777517832")}
            ></Image>
          </Flex>
          <Flex
            gap="8px"
            direction="column"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Text777517827")}
          >
            <Text
              fontFamily="Zen Kaku Gothic New"
              fontSize="17px"
              fontWeight="500"
              color="rgba(28,26,29,1)"
              lineHeight="31px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="共有しておくと、もしもの時も安心"
              {...getOverrideProps(
                overrides,
                "\u5171\u6709\u3057\u3066\u304A\u304F\u3068\u3001\u3082\u3057\u3082\u306E\u6642\u3082\u5B89\u5FC3"
              )}
            ></Text>
            <Text
              fontFamily="Zen Kaku Gothic New"
              fontSize="30px"
              fontWeight="500"
              color="rgba(28,26,29,1)"
              lineHeight="33px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="ノートを共有"
              {...getOverrideProps(
                overrides,
                "\u30CE\u30FC\u30C8\u3092\u5171\u6709"
              )}
            ></Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        position="absolute"
        top="45.4%"
        bottom="49.47%"
        left="0%"
        right="0%"
        direction="column"
        width="960px"
        height="264px"
        borderRadius="4px"
        padding="0px 32px 32px 32px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "AddHome")}
      >
        <Flex
          gap="12px"
          direction="row"
          width="fit-content"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "AddHomeTitle")}
        >
          <Icon
            width="32px"
            height="32px"
            shrink="0"
            overflow="hidden"
            position="relative"
            padding="0px 0px 0px 0px"
            type="add_to_home_screen"
            fontSize="32px"
            {...getOverrideProps(overrides, "Icon613016132")}
          ></Icon>
          <Flex
            gap="0"
            direction="row"
            width="852px"
            alignItems="flex-start"
            shrink="0"
            height="68px"
            position="relative"
            padding="16px 0px 16px 0px"
            {...getOverrideProps(overrides, "PC_LabelM_AddHome")}
          >
            <Text
              fontFamily="Inter"
              fontSize="21px"
              fontWeight="700"
              color="rgba(0,0,0,1)"
              lineHeight="36px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="852px"
              grow="1"
              basis="852px"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="HIRAQL NOTEをホーム画面に追加"
              {...getOverrideProps(overrides, "Label")}
            ></Text>
          </Flex>
        </Flex>
        <Icon
          width="896px"
          height="0px"
          viewBox={{ minX: 0, minY: 0, width: 896, height: 1 }}
          paths={[
            {
              d: "M0 0.5L896 0.5L896 -0.5L0 -0.5L0 0.5Z",
              stroke: "rgba(174,179,183,1)",
              fillRule: "nonzero",
              strokeWidth: 1,
            },
          ]}
          shrink="0"
          position="relative"
          {...getOverrideProps(overrides, "Vector 2")}
        ></Icon>
        <Flex
          gap="10px"
          direction="row"
          width="896px"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="16px 0px 16px 0px"
          {...getOverrideProps(overrides, "PC_Description_AddHome")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color="rgba(28,26,29,1)"
            lineHeight="24px"
            textAlign="left"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            letterSpacing="0.01px"
            width="896px"
            grow="1"
            basis="896px"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="HIRAQL NOTEをいつでも開けるように、スマートフォンのホーム画面にショートカットを追加しましょう！"
            {...getOverrideProps(
              overrides,
              "HIRAQL NOTE\u3092\u3044\u3064\u3067\u3082\u958B\u3051\u308B\u3088\u3046\u306B\u3001\u30B9\u30DE\u30FC\u30C8\u30D5\u30A9\u30F3\u306E\u30DB\u30FC\u30E0\u753B\u9762\u306B\u30B7\u30E7\u30FC\u30C8\u30AB\u30C3\u30C8\u3092\u8FFD\u52A0\u3057\u307E\u3057\u3087\u3046\uFF01"
            )}
          ></Text>
        </Flex>
        <Flex
          gap="10px"
          direction="row"
          width="fit-content"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="8px 8px 8px 8px"
          {...getOverrideProps(overrides, "Link_AChrome")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            color="rgba(104,134,73,1)"
            lineHeight="20px"
            textAlign="center"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            letterSpacing="0.49px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="・Android端末で、Chromeをご利用の方"
            {...getOverrideProps(
              overrides,
              "\u30FBAndroid\u7AEF\u672B\u3067\u3001Chrome\u3092\u3054\u5229\u7528\u306E\u65B9"
            )}
          ></Text>
        </Flex>
        <Flex
          gap="10px"
          direction="row"
          width="fit-content"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="8px 8px 8px 8px"
          {...getOverrideProps(overrides, "Link_IChrome")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            color="rgba(104,134,73,1)"
            lineHeight="20px"
            textAlign="center"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            letterSpacing="0.49px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="・iOS端末で、Chromeをご利用の方"
            {...getOverrideProps(
              overrides,
              "\u30FBiOS\u7AEF\u672B\u3067\u3001Chrome\u3092\u3054\u5229\u7528\u306E\u65B9"
            )}
          ></Text>
        </Flex>
        <Flex
          gap="10px"
          direction="row"
          width="fit-content"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="8px 8px 8px 8px"
          {...getOverrideProps(overrides, "Link_ISafari")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            color="rgba(104,134,73,1)"
            lineHeight="20px"
            textAlign="center"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            letterSpacing="0.49px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="・iOS端末で、Safariをご利用の方"
            {...getOverrideProps(
              overrides,
              "\u30FBiOS\u7AEF\u672B\u3067\u3001Safari\u3092\u3054\u5229\u7528\u306E\u65B9"
            )}
          ></Text>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        position="absolute"
        top="calc(50% - 804.01px - -1419.17px)"
        left="0%"
        right="0%"
        direction="column"
        width="960px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "IChrome")}
      >
        <Flex
          gap="11px"
          direction="row"
          width="960px"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "IChromeTitle")}
        >
          <Icon
            width="32px"
            height="32px"
            shrink="0"
            overflow="hidden"
            position="relative"
            padding="0px 0px 0px 0px"
            type="done"
            fontSize="32px"
            {...getOverrideProps(overrides, "Icon613016215")}
          ></Icon>
          <Flex
            gap="0"
            direction="row"
            width="901px"
            alignItems="flex-start"
            shrink="0"
            height="68px"
            position="relative"
            padding="16px 0px 16px 0px"
            {...getOverrideProps(overrides, "PC_LabelM_IChrome")}
          >
            <Text
              fontFamily="Inter"
              fontSize="21px"
              fontWeight="700"
              color="rgba(0,0,0,1)"
              lineHeight="36px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="901px"
              grow="1"
              basis="901px"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="iOS端末：ChromeでHIRAQL NOTEをホーム画面に追加する"
              {...getOverrideProps(
                overrides,
                "iOS\u7AEF\u672B\uFF1AChrome\u3067HIRAQL NOTE\u3092\u30DB\u30FC\u30E0\u753B\u9762\u306B\u8FFD\u52A0\u3059\u308B"
              )}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="16px"
          direction="column"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "IChromeContent")}
        >
          <Flex
            gap="16px"
            direction="row"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "IChromeBody1")}
          >
            <Flex
              gap="0"
              direction="column"
              width="228px"
              height="484.69px"
              grow="1"
              basis="228px"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "IChromeBox1")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="228px"
                height="77.02px"
                alignItems="flex-start"
                grow="1"
                basis="77.02420043945312px"
                position="relative"
                padding="16px 0px 16px 0px"
                {...getOverrideProps(overrides, "PC_Description_IChrome1")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(28,26,29,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="flex"
                  direction="column"
                  justifyContent="flex-start"
                  letterSpacing="0.01px"
                  width="228px"
                  grow="1"
                  basis="228px"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="1.HIRAQL NOTEのURLをコピーしておきましょう"
                  {...getOverrideProps(
                    overrides,
                    "1.HIRAQL NOTE\u306EURL\u3092\u30B3\u30D4\u30FC\u3057\u3066\u304A\u304D\u307E\u3057\u3087\u3046"
                  )}
                ></Text>
              </Flex>
              <Image
                width="228px"
                height="407.67px"
                shrink="0"
                position="relative"
                border="1px SOLID rgba(232,232,232,1)"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "IChrome1")}
              ></Image>
            </Flex>
            <Flex
              gap="0"
              direction="column"
              width="228px"
              height="484.69px"
              grow="1"
              basis="228px"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "IChromeBox2")}
            >
              <Flex
                gap="10px"
                direction="row"
                height="77.83px"
                alignItems="flex-start"
                grow="1"
                basis="77.83297729492188px"
                alignSelf="stretch"
                objectFit="cover"
                position="relative"
                padding="16px 0px 16px 0px"
                {...getOverrideProps(overrides, "PC_Description_IChrome2")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(28,26,29,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="flex"
                  direction="column"
                  justifyContent="flex-start"
                  letterSpacing="0.01px"
                  width="228px"
                  grow="1"
                  basis="228px"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="2.ホーム画面に戻り、ショートカットアプリを起動"
                  {...getOverrideProps(
                    overrides,
                    "2.\u30DB\u30FC\u30E0\u753B\u9762\u306B\u623B\u308A\u3001\u30B7\u30E7\u30FC\u30C8\u30AB\u30C3\u30C8\u30A2\u30D7\u30EA\u3092\u8D77\u52D5"
                  )}
                ></Text>
              </Flex>
              <Image
                width="228px"
                height="406.86px"
                shrink="0"
                position="relative"
                border="1px SOLID rgba(232,232,232,1)"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "IChrome2")}
              ></Image>
            </Flex>
            <Flex
              gap="0"
              direction="column"
              width="228px"
              height="484.69px"
              grow="1"
              basis="228px"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "IChromeBox3")}
            >
              <Flex
                gap="10px"
                direction="row"
                height="80px"
                alignItems="flex-start"
                grow="1"
                basis="80px"
                alignSelf="stretch"
                objectFit="cover"
                position="relative"
                padding="16px 0px 16px 0px"
                {...getOverrideProps(overrides, "PC_Description_IChrome3")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(28,26,29,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="flex"
                  direction="column"
                  justifyContent="flex-start"
                  letterSpacing="0.01px"
                  width="228px"
                  height="48px"
                  grow="1"
                  basis="228px"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="3.「＋」をタップ"
                  {...getOverrideProps(
                    overrides,
                    "3.\u300C\uFF0B\u300D\u3092\u30BF\u30C3\u30D7"
                  )}
                ></Text>
              </Flex>
              <Image
                width="228px"
                height="404.69px"
                shrink="0"
                position="relative"
                border="1px SOLID rgba(232,232,232,1)"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "IChrome3")}
              ></Image>
            </Flex>
            <Flex
              gap="0"
              direction="column"
              width="228px"
              height="484.69px"
              grow="1"
              basis="228px"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "IChromeBox4")}
            >
              <Flex
                gap="10px"
                direction="row"
                height="80px"
                alignItems="flex-start"
                grow="1"
                basis="80px"
                alignSelf="stretch"
                objectFit="cover"
                position="relative"
                padding="16px 0px 16px 0px"
                {...getOverrideProps(overrides, "PC_Description_IChrome4")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(28,26,29,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="flex"
                  direction="column"
                  justifyContent="flex-start"
                  letterSpacing="0.01px"
                  width="228px"
                  grow="1"
                  basis="228px"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="4.「アクションを追加」をタップ"
                  {...getOverrideProps(
                    overrides,
                    "4.\u300C\u30A2\u30AF\u30B7\u30E7\u30F3\u3092\u8FFD\u52A0\u300D\u3092\u30BF\u30C3\u30D7"
                  )}
                ></Text>
              </Flex>
              <Image
                width="228px"
                height="404.69px"
                shrink="0"
                position="relative"
                border="1px SOLID rgba(232,232,232,1)"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "IChrome4")}
              ></Image>
            </Flex>
          </Flex>
          <Flex
            gap="16px"
            direction="row"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "IChromeBody2")}
          >
            <Flex
              gap="0"
              direction="column"
              width="228px"
              height="511.67px"
              grow="1"
              basis="228px"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "IChromeBox5")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="228px"
                height="109.13px"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                padding="16px 0px 16px 0px"
                {...getOverrideProps(overrides, "PC_Description_IChrome")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(28,26,29,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="flex"
                  direction="column"
                  justifyContent="flex-start"
                  letterSpacing="0.01px"
                  width="228px"
                  grow="1"
                  basis="228px"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="5.検索窓に「chrome」と入力し、「ChromeでURLを開く」をタップ"
                  {...getOverrideProps(
                    overrides,
                    "5.\u691C\u7D22\u7A93\u306B\u300Cchrome\u300D\u3068\u5165\u529B\u3057\u3001\u300CChrome\u3067URL\u3092\u958B\u304F\u300D\u3092\u30BF\u30C3\u30D7"
                  )}
                ></Text>
              </Flex>
              <Image
                width="228px"
                height="402.53px"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "IChrome5")}
              ></Image>
            </Flex>
            <Flex
              gap="0"
              direction="column"
              width="228px"
              shrink="0"
              height="511.67px"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "IChromeBox6")}
            >
              <Flex
                gap="10px"
                direction="row"
                height="109.13px"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                objectFit="cover"
                position="relative"
                padding="16px 0px 16px 0px"
                {...getOverrideProps(overrides, "PC_Description_IChrome6")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(28,26,29,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="flex"
                  direction="column"
                  justifyContent="flex-start"
                  letterSpacing="0.01px"
                  width="228px"
                  grow="1"
                  basis="228px"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="6.「URL」をタップ"
                  {...getOverrideProps(
                    overrides,
                    "6.\u300CURL\u300D\u3092\u30BF\u30C3\u30D7"
                  )}
                ></Text>
              </Flex>
              <Image
                width="228px"
                height="402.53px"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "IChrome6")}
              ></Image>
            </Flex>
            <Flex
              gap="0"
              direction="column"
              width="228px"
              grow="1"
              basis="228px"
              height="511.67px"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "IChromeBox7")}
            >
              <Flex
                gap="10px"
                direction="row"
                height="111.83px"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                objectFit="cover"
                position="relative"
                padding="16px 0px 16px 0px"
                {...getOverrideProps(overrides, "PC_Description_IChrome7")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(28,26,29,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="flex"
                  direction="column"
                  justifyContent="flex-start"
                  letterSpacing="0.01px"
                  width="228px"
                  height="79.83px"
                  grow="1"
                  basis="228px"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="7.コピーしておいたURLをペーストし、「完了」をタップ"
                  {...getOverrideProps(
                    overrides,
                    "7.\u30B3\u30D4\u30FC\u3057\u3066\u304A\u3044\u305FURL\u3092\u30DA\u30FC\u30B9\u30C8\u3057\u3001\u300C\u5B8C\u4E86\u300D\u3092\u30BF\u30C3\u30D7"
                  )}
                ></Text>
              </Flex>
              <Image
                width="228px"
                height="399.84px"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "IChrome7")}
              ></Image>
            </Flex>
            <Flex
              gap="0"
              direction="column"
              width="228px"
              height="511.67px"
              grow="1"
              basis="228px"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "IChromeBox8")}
            >
              <Flex
                gap="10px"
                direction="row"
                height="109.13px"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                objectFit="cover"
                position="relative"
                padding="16px 0px 16px 0px"
                {...getOverrideProps(overrides, "PC_Description_IChrome8")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(28,26,29,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="flex"
                  direction="column"
                  justifyContent="flex-start"
                  letterSpacing="0.01px"
                  width="228px"
                  grow="1"
                  basis="228px"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="8.ショートカットに任意の名前をつけ、「完了」をタップ"
                  {...getOverrideProps(
                    overrides,
                    "8.\u30B7\u30E7\u30FC\u30C8\u30AB\u30C3\u30C8\u306B\u4EFB\u610F\u306E\u540D\u524D\u3092\u3064\u3051\u3001\u300C\u5B8C\u4E86\u300D\u3092\u30BF\u30C3\u30D7"
                  )}
                ></Text>
              </Flex>
              <Image
                width="228px"
                height="402.53px"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "IChrome8")}
              ></Image>
            </Flex>
          </Flex>
          <Flex
            gap="16px"
            direction="row"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "IChromeBody3")}
          >
            <Flex
              gap="0"
              direction="column"
              width="228px"
              height="511.67px"
              grow="1"
              basis="228px"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "IChromeBox9")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="228px"
                height="105.44px"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                padding="16px 0px 16px 0px"
                {...getOverrideProps(overrides, "PC_Description_IChrome9")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(28,26,29,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="flex"
                  direction="column"
                  justifyContent="flex-start"
                  letterSpacing="0.01px"
                  width="228px"
                  grow="1"
                  basis="228px"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="9.画面右上をタップ"
                  {...getOverrideProps(
                    overrides,
                    "9.\u753B\u9762\u53F3\u4E0A\u3092\u30BF\u30C3\u30D7"
                  )}
                ></Text>
              </Flex>
              <Image
                width="228px"
                height="406.23px"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "IChrome9")}
              ></Image>
            </Flex>
            <Flex
              gap="0"
              direction="column"
              width="228px"
              height="511.67px"
              grow="1"
              basis="228px"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "IChromeBox10")}
            >
              <Flex
                gap="10px"
                direction="row"
                height="107.64px"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                objectFit="cover"
                position="relative"
                padding="16px 0px 16px 0px"
                {...getOverrideProps(overrides, "PC_Description_IChrome10")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(28,26,29,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="flex"
                  direction="column"
                  justifyContent="flex-start"
                  letterSpacing="0.01px"
                  width="228px"
                  grow="1"
                  basis="228px"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="10.「ホーム画面に追加」をタップ"
                  {...getOverrideProps(
                    overrides,
                    "10.\u300C\u30DB\u30FC\u30E0\u753B\u9762\u306B\u8FFD\u52A0\u300D\u3092\u30BF\u30C3\u30D7"
                  )}
                ></Text>
              </Flex>
              <Image
                width="228px"
                height="404.03px"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "IChrome10")}
              ></Image>
            </Flex>
            <Flex
              gap="0"
              direction="column"
              width="228px"
              height="511.67px"
              grow="1"
              basis="228px"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "IChromeBox11")}
            >
              <Flex
                gap="10px"
                direction="row"
                height="107.64px"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                objectFit="cover"
                position="relative"
                padding="16px 0px 16px 0px"
                {...getOverrideProps(overrides, "PC_Description")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(28,26,29,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="flex"
                  direction="column"
                  justifyContent="flex-start"
                  letterSpacing="0.01px"
                  width="228px"
                  height="75.64px"
                  grow="1"
                  basis="228px"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="11.「追加」をタップ&#xA;※適宜任意のアイコンを設定"
                  {...getOverrideProps(
                    overrides,
                    "11.\u300C\u8FFD\u52A0\u300D\u3092\u30BF\u30C3\u30D7 \u203B\u9069\u5B9C\u4EFB\u610F\u306E\u30A2\u30A4\u30B3\u30F3\u3092\u8A2D\u5B9A"
                  )}
                ></Text>
              </Flex>
              <Image
                width="228px"
                height="404.03px"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "IChrome11")}
              ></Image>
            </Flex>
            <Flex
              gap="0"
              direction="column"
              width="228px"
              height="511.67px"
              grow="1"
              basis="228px"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "IChromeBox12")}
            >
              <Flex
                gap="10px"
                direction="row"
                height="107.64px"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                objectFit="cover"
                position="relative"
                padding="16px 0px 16px 0px"
                {...getOverrideProps(overrides, "PC_Description_IChrome12")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(28,26,29,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="flex"
                  direction="column"
                  justifyContent="flex-start"
                  letterSpacing="0.01px"
                  width="228px"
                  grow="1"
                  basis="228px"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="12.HIRAQL NOTEへのショートカットがホーム画面に追加されます"
                  {...getOverrideProps(
                    overrides,
                    "12.HIRAQL NOTE\u3078\u306E\u30B7\u30E7\u30FC\u30C8\u30AB\u30C3\u30C8\u304C\u30DB\u30FC\u30E0\u753B\u9762\u306B\u8FFD\u52A0\u3055\u308C\u307E\u3059"
                  )}
                ></Text>
              </Flex>
              <Image
                width="228px"
                height="404.03px"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "IChrome12")}
              ></Image>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        position="absolute"
        top="51.15%"
        bottom="39%"
        left="0%"
        right="0%"
        direction="column"
        width="960px"
        height="507.62px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "AChrome")}
      >
        <Flex
          gap="11px"
          direction="row"
          width="960px"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "AChromeTitle")}
        >
          <Icon
            width="32px"
            height="32px"
            shrink="0"
            overflow="hidden"
            position="relative"
            padding="0px 0px 0px 0px"
            type="done"
            fontSize="32px"
            {...getOverrideProps(overrides, "Icon613016163")}
          ></Icon>
          <Flex
            gap="0"
            direction="row"
            width="901px"
            alignItems="flex-start"
            shrink="0"
            height="68px"
            position="relative"
            padding="16px 0px 16px 0px"
            {...getOverrideProps(overrides, "PC_LabelM")}
          >
            <Text
              fontFamily="Inter"
              fontSize="21px"
              fontWeight="700"
              color="rgba(0,0,0,1)"
              lineHeight="36px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="901px"
              grow="1"
              basis="901px"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Android端末：ChromeでHIRAQL NOTEをホーム画面に追加する"
              {...getOverrideProps(
                overrides,
                "Android\u7AEF\u672B\uFF1AChrome\u3067HIRAQL NOTE\u3092\u30DB\u30FC\u30E0\u753B\u9762\u306B\u8FFD\u52A0\u3059\u308B"
              )}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="16px"
          direction="row"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "AChromeBody")}
        >
          <Flex
            gap="0"
            direction="column"
            width="228px"
            height="439.62px"
            grow="1"
            basis="228px"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "AChromeBox1")}
          >
            <Flex
              gap="10px"
              direction="row"
              height="79.65px"
              alignItems="flex-start"
              grow="1"
              basis="79.64675903320312px"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="16px 0px 16px 0px"
              {...getOverrideProps(overrides, "PC_Description_AChrome1")}
            >
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(28,26,29,1)"
                lineHeight="24px"
                textAlign="left"
                display="flex"
                direction="column"
                justifyContent="flex-start"
                letterSpacing="0.01px"
                width="228px"
                grow="1"
                basis="228px"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="1.ブラウザの右上のメニューをタップ"
                {...getOverrideProps(
                  overrides,
                  "1.\u30D6\u30E9\u30A6\u30B6\u306E\u53F3\u4E0A\u306E\u30E1\u30CB\u30E5\u30FC\u3092\u30BF\u30C3\u30D7"
                )}
              ></Text>
            </Flex>
            <Flex
              gap="10px"
              direction="column"
              shrink="0"
              position="relative"
              padding="0px 0px 145px 0px"
              {...getOverrideProps(overrides, "Frame 602")}
            >
              <Image
                width="228px"
                height="214.97px"
                shrink="0"
                position="relative"
                border="1px SOLID rgba(232,232,232,1)"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "AChrome1")}
              ></Image>
            </Flex>
          </Flex>
          <Flex
            gap="0"
            direction="column"
            width="228px"
            height="439.62px"
            grow="1"
            basis="228px"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "AChromeBox2")}
          >
            <Flex
              gap="10px"
              direction="row"
              height="80px"
              alignItems="flex-start"
              grow="1"
              basis="80px"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="16px 0px 16px 0px"
              {...getOverrideProps(overrides, "PC_Description_AChrome2")}
            >
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(28,26,29,1)"
                lineHeight="24px"
                textAlign="left"
                display="flex"
                direction="column"
                justifyContent="flex-start"
                letterSpacing="0.01px"
                width="228px"
                grow="1"
                basis="228px"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="2.「ホーム画面に追加」をタップ"
                {...getOverrideProps(
                  overrides,
                  "2.\u300C\u30DB\u30FC\u30E0\u753B\u9762\u306B\u8FFD\u52A0\u300D\u3092\u30BF\u30C3\u30D7"
                )}
              ></Text>
            </Flex>
            <Image
              width="228px"
              height="359.62px"
              shrink="0"
              position="relative"
              border="1px SOLID rgba(232,232,232,1)"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "AChrome2")}
            ></Image>
          </Flex>
          <Flex
            gap="0"
            direction="column"
            width="228px"
            height="439.62px"
            grow="1"
            basis="228px"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "AChromeBox3")}
          >
            <Flex
              gap="10px"
              direction="row"
              height="79.24px"
              alignItems="flex-start"
              grow="1"
              basis="79.24105834960938px"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="16px 0px 16px 0px"
              {...getOverrideProps(overrides, "PC_Description_AChrome3")}
            >
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(28,26,29,1)"
                lineHeight="24px"
                textAlign="left"
                display="flex"
                direction="column"
                justifyContent="flex-start"
                letterSpacing="0.01px"
                width="228px"
                grow="1"
                basis="228px"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="3.「追加」をタップ"
                {...getOverrideProps(
                  overrides,
                  "3.\u300C\u8FFD\u52A0\u300D\u3092\u30BF\u30C3\u30D7"
                )}
              ></Text>
            </Flex>
            <Flex
              gap="10px"
              direction="column"
              shrink="0"
              position="relative"
              padding="0px 0px 234px 0px"
              {...getOverrideProps(overrides, "Frame 600")}
            >
              <Image
                width="228px"
                height="126.38px"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "AChrome3")}
              ></Image>
            </Flex>
          </Flex>
          <Flex
            gap="0"
            direction="column"
            width="228px"
            grow="1"
            basis="228px"
            height="439.62px"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "AChromeBox4")}
          >
            <Flex
              gap="10px"
              direction="row"
              height="79.66px"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="16px 0px 16px 0px"
              {...getOverrideProps(overrides, "PC_Description_AChrome4")}
            >
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(28,26,29,1)"
                lineHeight="24px"
                textAlign="left"
                display="flex"
                direction="column"
                justifyContent="flex-start"
                letterSpacing="0.01px"
                width="228px"
                height="24px"
                grow="1"
                basis="228px"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="4.「自動的に追加」をタップ"
                {...getOverrideProps(
                  overrides,
                  "4.\u300C\u81EA\u52D5\u7684\u306B\u8FFD\u52A0\u300D\u3092\u30BF\u30C3\u30D7"
                )}
              ></Text>
            </Flex>
            <Flex
              gap="10px"
              direction="column"
              shrink="0"
              position="relative"
              padding="0px 0px 176px 0px"
              {...getOverrideProps(overrides, "Frame 601")}
            >
              <Image
                width="228px"
                height="183.95px"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "AChrome4")}
              ></Image>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        position="absolute"
        top="94.09%"
        bottom="-4.81%"
        left="0%"
        right="0%"
        direction="column"
        width="960px"
        height="552.69px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "ISafari")}
      >
        <Flex
          gap="11px"
          direction="row"
          width="960px"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ISafariTitle")}
        >
          <Icon
            width="32px"
            height="32px"
            shrink="0"
            overflow="hidden"
            position="relative"
            padding="0px 0px 0px 0px"
            type="done"
            fontSize="32px"
            {...getOverrideProps(overrides, "Icon614216269")}
          ></Icon>
          <Flex
            gap="0"
            direction="row"
            width="901px"
            alignItems="flex-start"
            shrink="0"
            height="68px"
            position="relative"
            padding="16px 0px 16px 0px"
            {...getOverrideProps(overrides, "PC_LabelM_ISafari")}
          >
            <Text
              fontFamily="Inter"
              fontSize="21px"
              fontWeight="700"
              color="rgba(0,0,0,1)"
              lineHeight="36px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="901px"
              grow="1"
              basis="901px"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="iOS端末：SafariでHIRAQL NOTEをホーム画面に追加する"
              {...getOverrideProps(
                overrides,
                "iOS\u7AEF\u672B\uFF1ASafari\u3067HIRAQL NOTE\u3092\u30DB\u30FC\u30E0\u753B\u9762\u306B\u8FFD\u52A0\u3059\u308B"
              )}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="16px"
          direction="row"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ISafariBody")}
        >
          <Flex
            gap="0"
            direction="column"
            width="228px"
            height="484.69px"
            grow="1"
            basis="228px"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "ISafariBox1")}
          >
            <Flex
              gap="10px"
              direction="row"
              width="228px"
              height="80.55px"
              alignItems="flex-start"
              grow="1"
              basis="80.55123901367188px"
              position="relative"
              padding="16px 0px 16px 0px"
              {...getOverrideProps(overrides, "PC_Description_ISafari1")}
            >
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(28,26,29,1)"
                lineHeight="24px"
                textAlign="left"
                display="flex"
                direction="column"
                justifyContent="flex-start"
                letterSpacing="0.01px"
                width="228px"
                grow="1"
                basis="228px"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="1.ブラウザ下部の共有ボタンをタップ"
                {...getOverrideProps(
                  overrides,
                  "1.\u30D6\u30E9\u30A6\u30B6\u4E0B\u90E8\u306E\u5171\u6709\u30DC\u30BF\u30F3\u3092\u30BF\u30C3\u30D7"
                )}
              ></Text>
            </Flex>
            <Image
              width="228px"
              height="404.14px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "ISafari1")}
            ></Image>
          </Flex>
          <Flex
            gap="0"
            direction="column"
            width="228px"
            height="484.69px"
            grow="1"
            basis="228px"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "ISafariBox2")}
          >
            <Flex
              gap="10px"
              direction="row"
              height="81.74px"
              alignItems="flex-start"
              grow="1"
              basis="81.743408203125px"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="16px 0px 16px 0px"
              {...getOverrideProps(overrides, "PC_Description_ISafari2")}
            >
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(28,26,29,1)"
                lineHeight="24px"
                textAlign="left"
                display="flex"
                direction="column"
                justifyContent="flex-start"
                letterSpacing="0.01px"
                width="228px"
                grow="1"
                basis="228px"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="2.下にスクロール"
                {...getOverrideProps(
                  overrides,
                  "2.\u4E0B\u306B\u30B9\u30AF\u30ED\u30FC\u30EB"
                )}
              ></Text>
            </Flex>
            <Image
              width="228px"
              height="402.95px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "ISafari2")}
            ></Image>
          </Flex>
          <Flex
            gap="0"
            direction="column"
            width="228px"
            height="484.69px"
            grow="1"
            basis="228px"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "ISafariBox3")}
          >
            <Flex
              gap="10px"
              direction="row"
              height="81.74px"
              alignItems="flex-start"
              grow="1"
              basis="81.743408203125px"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="16px 0px 16px 0px"
              {...getOverrideProps(overrides, "PC_Description_ISafari3")}
            >
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(28,26,29,1)"
                lineHeight="24px"
                textAlign="left"
                display="flex"
                direction="column"
                justifyContent="flex-start"
                letterSpacing="0.01px"
                width="228px"
                height="49.74px"
                grow="1"
                basis="228px"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="3.「ホーム画面に追加」をタップ"
                {...getOverrideProps(
                  overrides,
                  "3.\u300C\u30DB\u30FC\u30E0\u753B\u9762\u306B\u8FFD\u52A0\u300D\u3092\u30BF\u30C3\u30D7"
                )}
              ></Text>
            </Flex>
            <Image
              width="228px"
              height="402.95px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "ISafari3")}
            ></Image>
          </Flex>
          <Flex
            gap="0"
            direction="column"
            height="484.69px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "ISafariBox4")}
          >
            <Flex
              gap="10px"
              direction="row"
              height="84.36px"
              alignItems="flex-start"
              grow="1"
              basis="84.35995483398438px"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="16px 0px 16px 0px"
              {...getOverrideProps(overrides, "PC_Description_ISafari4")}
            >
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(28,26,29,1)"
                lineHeight="24px"
                textAlign="left"
                display="flex"
                direction="column"
                justifyContent="flex-start"
                letterSpacing="0.01px"
                width="228px"
                grow="1"
                basis="228px"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="4.「追加」をタップ"
                {...getOverrideProps(
                  overrides,
                  "4.\u300C\u8FFD\u52A0\u300D\u3092\u30BF\u30C3\u30D7"
                )}
              ></Text>
            </Flex>
            <Image
              width="228px"
              height="400.33px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "ISafari4")}
            ></Image>
          </Flex>
        </Flex>
      </Flex>
    </View>
  );
}
