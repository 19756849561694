/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Image, Text, View } from "@aws-amplify/ui-react";
export default function Menu(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="10px"
      direction="column"
      width="375px"
      height="692px"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="50px 0px 0px 0px"
      backgroundColor="rgba(245,244,238,1)"
      {...rest}
      {...getOverrideProps(overrides, "Menu")}
    >
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        grow="1"
        shrink="1"
        basis="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "MenuList")}
      >
        <View
          width="unset"
          height="1px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(239,240,240,1)"
          {...getOverrideProps(overrides, "Rectangle 1143581513341")}
        ></View>
        <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="12px 25px 12px 25px"
          {...getOverrideProps(overrides, "AccountList")}
        >
          <Image
            width="26.67px"
            height="21px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            {...getOverrideProps(overrides, "Account")}
          ></Image>
          <Text
            fontFamily="Zen Kaku Gothic New"
            fontSize="17px"
            fontWeight="500"
            color="rgba(28,26,29,1)"
            lineHeight="31px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="アカウント管理"
            {...getOverrideProps(
              overrides,
              "\u30A2\u30AB\u30A6\u30F3\u30C8\u7BA1\u7406"
            )}
          ></Text>
          <Image
            width="14px"
            height="14px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            {...getOverrideProps(overrides, "NextA")}
          ></Image>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ShareList")}
        >
          <View
            width="unset"
            height="1px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(239,240,240,1)"
            {...getOverrideProps(overrides, "Rectangle 1143581513347")}
          ></View>
          <Flex
            gap="16px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="12px 25px 12px 25px"
            {...getOverrideProps(overrides, "ShareContent")}
          >
            <Image
              width="26.67px"
              height="21px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              {...getOverrideProps(overrides, "Share")}
            ></Image>
            <Text
              fontFamily="Zen Kaku Gothic New"
              fontSize="17px"
              fontWeight="500"
              color="rgba(28,26,29,1)"
              lineHeight="31px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="家族にノートを共有"
              {...getOverrideProps(
                overrides,
                "\u5BB6\u65CF\u306B\u30CE\u30FC\u30C8\u3092\u5171\u6709"
              )}
            ></Text>
            <Image
              width="14px"
              height="14px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              {...getOverrideProps(overrides, "NextS")}
            ></Image>
          </Flex>
        </Flex>
        <View
          width="unset"
          height="1px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(239,240,240,1)"
          {...getOverrideProps(overrides, "Rectangle 1143581513371")}
        ></View>
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "NoteList")}
        >
          <View
            width="unset"
            height="1px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(239,240,240,1)"
            {...getOverrideProps(overrides, "Rectangle 11431")}
          ></View>
          <Flex
            gap="16px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="12px 25px 12px 25px"
            {...getOverrideProps(overrides, "NoteContent")}
          >
            <Image
              width="26.67px"
              height="21px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              {...getOverrideProps(overrides, "Note")}
            ></Image>
            <Text
              fontFamily="Zen Kaku Gothic New"
              fontSize="17px"
              fontWeight="500"
              color="rgba(28,26,29,1)"
              lineHeight="31px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="共有されたノート一覧"
              {...getOverrideProps(
                overrides,
                "\u5171\u6709\u3055\u308C\u305F\u30CE\u30FC\u30C8\u4E00\u89A7"
              )}
            ></Text>
            <Image
              width="14px"
              height="14px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              {...getOverrideProps(overrides, "NextN")}
            ></Image>
          </Flex>
        </Flex>
        <View
          width="unset"
          height="1px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(239,240,240,1)"
          {...getOverrideProps(overrides, "Rectangle 1144")}
        ></View>
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "FileList")}
        >
          <View
            width="unset"
            height="1px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(239,240,240,1)"
            {...getOverrideProps(overrides, "Rectangle 1143595117345")}
          ></View>
          <Flex
            gap="16px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="12px 25px 12px 25px"
            {...getOverrideProps(overrides, "FileContent")}
          >
            <Image
              width="26.67px"
              height="21px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              {...getOverrideProps(overrides, "File643317500")}
            ></Image>
            <Text
              fontFamily="Zen Kaku Gothic New"
              fontSize="17px"
              fontWeight="500"
              color="rgba(28,26,29,1)"
              lineHeight="31px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="添付ファイル一覧"
              {...getOverrideProps(overrides, "File595117348")}
            ></Text>
            <Image
              width="14px"
              height="14px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              {...getOverrideProps(overrides, "NextF")}
            ></Image>
          </Flex>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "LogoutList")}
        >
          <View
            width="unset"
            height="1px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(239,240,240,1)"
            {...getOverrideProps(overrides, "Rectangle 1143581513365")}
          ></View>
          <Flex
            gap="16px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="12px 25px 12px 25px"
            {...getOverrideProps(overrides, "LogoutContent")}
          >
            <Image
              width="26.67px"
              height="21px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              {...getOverrideProps(overrides, "Logout")}
            ></Image>
            <Text
              fontFamily="Zen Kaku Gothic New"
              fontSize="17px"
              fontWeight="500"
              color="rgba(28,26,29,1)"
              lineHeight="31px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="ログアウト"
              {...getOverrideProps(overrides, "\u30ED\u30B0\u30A2\u30A6\u30C8")}
            ></Text>
            <Image
              width="14px"
              height="14px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              {...getOverrideProps(overrides, "NextL")}
            ></Image>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
