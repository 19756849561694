import { DataStore } from "aws-amplify";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { LeavesNenkin } from "../models";
import { ListField } from "../page-components";
import { getLabel, isEmpty, useInitializePage } from "../utils/CommonUtils";
import { log } from "../utils/Logger";
import { usePrompt } from "../utils/Prompt";

function PensionEditPage() {
  const navigate = useInitializePage("nenkin", "title.update");
  const data = useRef();
  const [update, setUpdate] = useState(false);
  const [pensionData, setPensionData] = useState({
    nenkinmei: "",
    jyukyuugaku: "",
    nenkinshurui: "",
    bikou: "",
  });
  const [pensionDataError, setPensionDataError] = useState({
    nenkinmeiError: "",
    jyukyuugakuError: "",
    bikouError: "",
  });
  const [isUpdated, setIsUpdated] = useState(false);

  const params = useParams();
  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const dataQuery = await DataStore.query(LeavesNenkin, (c) =>
        c.id("eq", params.id),
      );
      if (dataQuery?.length > 0) {
        data.current = dataQuery[0];
        setPensionData({
          nenkinmei: dataQuery[0].nenkinmei,
          jyukyuugaku: dataQuery[0].jyukyuugaku,
          nenkinshurui: dataQuery[0].nenkinshurui,
          bikou: dataQuery[0].bikou,
        });
      }
    };
    getData();
  }, [params.id]);

  const listField = [
    {
      type: "textField",
      isRequire: true,
      label: "nenkinmei",
      value: pensionData.nenkinmei,
      errorMessage: pensionDataError.nenkinmeiError,
      placeHolder: "kokuminnenkinnado",
    },
    {
      type: "textField",
      typeInput: "number",
      maxLength: 100,
      label: "jyukyuugaku",
      value: pensionData?.jyukyuugaku,
      errorMessage: pensionDataError.jyukyuugakuError,
      placeHolder: "ikkaiatarinokingaku",
    },
    {
      type: "textField",
      label: "nenkinshurui",
      value: pensionData?.nenkinshurui,
      placeHolder: "nikagetuniikkainado",
    },
    {
      type: "textArea",
      label: "bikou",
      value: pensionData?.bikou,
    },
  ];

  const updateDataPension = useCallback(async () => {
    let valid = true;
    const validNumber = isEmpty(pensionData?.jyukyuugaku)
      ? true
      : !isNaN(parseInt(pensionData?.jyukyuugaku));
    log.debug(pensionData?.jyukyuugaku);
    if (!validNumber || isEmpty(pensionData.nenkinmei)) {
      setPensionDataError({
        ...pensionDataError,
        jyukyuugakuError: validNumber ? "" : getLabel("error.number"),
        nenkinmeiError: isEmpty(pensionData.nenkinmei)
          ? getLabel("error.required")
          : "",
      });
      valid = false;
    }

    if (valid) {
      setIsUpdated(true);
      await DataStore.save(
        LeavesNenkin.copyOf(data.current, (updated) => {
          updated.nenkinmei = pensionData.nenkinmei;
          updated.jyukyuugaku = pensionData.jyukyuugaku;
          updated.nenkinshurui = pensionData.nenkinshurui;
          updated.bikou = pensionData.bikou;
        }),
      );
      window.gtag("event", "ChangeComplete", {
        event_category: "Edit",
        event_label: "年金変更完了",
      });
      navigate(-1, { replace: true });
    }
  }, [
    navigate,
    pensionData.bikou,
    pensionData.jyukyuugaku,
    pensionData.nenkinshurui,
    pensionData.nenkinmei,
    pensionDataError,
  ]);

  return (
    <ListField
      label={getLabel("button.update")}
      list={listField}
      setState={setPensionData}
      setUpdate={setUpdate}
      onSave={updateDataPension}
      onCancel={() => navigate(-1, { replace: true })}
      isDisabled={isUpdated}
    />
  );
}

export default PensionEditPage;
