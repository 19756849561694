import { DataStore } from "aws-amplify";
import { useCallback, useEffect, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useParams } from "react-router-dom";
import { LeavesHosyousaimu, LeavesLoankariirekin } from "../models";
import { ListViewDetail } from "../page-components";
import { formatNumber, useInitializePage } from "../utils/CommonUtils";
import { isShare, queryData } from "../utils/ShareUtils";

function LoanViewPage() {
  const navigate = useInitializePage("loankariirekin", "title.detail");
  const [loanData, setLoanData] = useState({});
  const [hosyousaimuData, setHosyousaimuData] = useState();
  const [isHosyo, setIsHosyo] = useState(false);
  const params = useParams();

  const deleteData = useCallback(async () => {
    if (!isHosyo) {
      await DataStore.delete(loanData);
      navigate(-1, { replace: true });
    } else {
      await DataStore.delete(hosyousaimuData);
      navigate(-1, { replace: true });
    }
  }, [hosyousaimuData, isHosyo, loanData, navigate]);

  useEffect(() => {
    const getData = async () => {
      const dataLoan = await queryData(params, LeavesLoankariirekin);
      if (dataLoan?.length > 0) {
        setLoanData(dataLoan[0]);
        setIsHosyo(false);
        return;
      }
      const dataHosy = await queryData(params, LeavesHosyousaimu);
      if (dataHosy?.length > 0) {
        setHosyousaimuData(dataHosy[0]);
        setIsHosyo(true);
      }
    };
    getData();
    window.scrollTo(0, 0);
  }, []);

  const listLabelLoan = [
    { label: "kariiresaki", value: loanData?.kariiresaki, isRequire: true },
    {
      label: "kariiresakinorenrakusaki",
      value: loanData?.kariiresakinorenrakusaki,
    },
    { label: "kariirebi", value: loanData?.kariirebi, type: "date" },
    {
      label: "kariiregaku",
      value:
        formatNumber(loanData?.kariiregaku) &&
        formatNumber(loanData?.kariiregaku) + "円",
    },
    { label: "hensaihouhou", value: loanData?.hensaihouhou },
    { label: "tanponoumu", value: loanData?.tanponoumu },
    {
      label: "kariirezandaka",
      value:
        formatNumber(loanData?.kariirezandaka) &&
        formatNumber(loanData?.kariirezandaka) + "円",
    },
    {
      label: "kariirezandakakisaibi",
      value: loanData?.kariirezandakakisaibi,
      type: "date",
    },
    { label: "kariiremokuteki", value: loanData?.kariiremokuteki },
    { label: "bikou", value: loanData?.bikou },
  ];
  const listLabelHosy = [
    {
      label: "syusaimusya",
      value: hosyousaimuData?.syusaimusya,
      isRequire: true,
    },
    {
      label: "syusaimusyanorenrakusaki",
      value: hosyousaimuData?.syusaimusyanorenrakusaki,
    },
    {
      label: "hosyoushitahi",
      value: hosyousaimuData?.hosyoushitahi,
      type: "date",
    },
    {
      label: "hosyoushitakingaku",
      value:
        formatNumber(hosyousaimuData?.hosyoushitakingaku) &&
        formatNumber(hosyousaimuData?.hosyoushitakingaku) + "円",
    },
    {
      label: "saikensya",
      value: hosyousaimuData?.saikensya,
    },
    {
      label: "saikensyanorenrakusaki",
      value: hosyousaimuData?.saikensyanorenrakusaki,
    },
    { label: "bikou", value: hosyousaimuData?.bikou },
  ];

  return (
    <ListViewDetail
      label={isHosyo ? hosyousaimuData?.syusaimusya : loanData?.kariiresaki}
      list={isHosyo ? listLabelHosy : listLabelLoan}
      isHideEdit={isShare(params)}
      onDelete={deleteData}
      onEdit={() => navigate("edit")}
    />
  );
}

export default LoanViewPage;
