import { DataStore, Storage } from "aws-amplify";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  LeavesKazokuShinzoku,
  LeavesTaisetsunishitaihitohenomessage,
  LeavesYuujinChijin,
} from "../models";
import {
  ListSelectContent,
  ListTwoLineB,
  StrageImage,
} from "../page-components";
import {
  formatDateLabel,
  getLabel,
  useInitializePage,
} from "../utils/CommonUtils";
import { isShare, listObjects, queryData, queryDatas } from "../utils/ShareUtils";

function MessagePage() {
  const navigate = useInitializePage("taisetsunishitaihitohenomessage");
  const [listMessage, setListMessage] = useState([]);
  const [imageList, setImageList] = useState({});
  const [soushinsakiName, setSoushinsakiName] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [selected, setSelected] = useState(null);
  const locate = useLocation();
  const { state } = locate;
  const params = useParams();

  const handledChangeActive = (item) => {
    setSelected(item);
  };

  useEffect(() => {
    if (listMessage?.length > 0) {
      const item = listMessage.find((ele) => ele?.id === state?.id);
      if (item) {
        setSelected(item);
        window.history.replaceState(
          { ...window.history?.state, usr: null },
          document.title,
        );
      } else setSelected(listMessage[0]);
    } else {
      setSelected(null);
    }
  }, [listMessage, state?.id]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const modelData = await queryDatas(
        params,
        LeavesTaisetsunishitaihitohenomessage,
      );
      if (modelData?.length > 0) {
        setListMessage(modelData);
      }
      setIsLoaded(true);
    };
    getData();
  }, []);

  useEffect(() => {
    if (isLoaded) {
      if (listMessage.length >= 3) setIsDisabled(true);
      // limit item is 3
      else setIsDisabled(false);
    }
  }, [listMessage, isLoaded]);

  const deleteData = async (data) => {
    await DataStore.delete(data);
    await Storage.remove(imageList[data.id], {
      level: "private",
    });
    const newList = await DataStore.query(
      LeavesTaisetsunishitaihitohenomessage,
    );
    setListMessage(newList);
  };

  const getImageUrl = useCallback(
    (item) => {
      if (imageList[item.id]) {
        return;
      } else {
        const getImage = async () => {
          const list = await listObjects(
            params,
            LeavesTaisetsunishitaihitohenomessage,
            item,
          );
          const url = list.length > 0 ? list[0].key : null;
          imageList[item.id] = url;
          setImageList((i) => ({
            ...i,
            [item.id]: url,
          }));
        };
        getImage();
      }
    },
    [selected],
  );

  useEffect(() => {
    if (selected) {
      getImageUrl(selected);
    }
  }, [getImageUrl, selected]);

  useEffect(() => {
    if (selected?.soushinsaki) {
      const getData = async () => {
        const contact = await queryData({...params, id: selected?.soushinsaki}, LeavesKazokuShinzoku);
        if (contact?.length > 0) {
          setSoushinsakiName(contact[0]?.shimei);
          return;
        }
      };
      getData();
    } else if (selected?.soushinsakiYuujinChijin) {
      const getData = async () => {
        const contact = await queryData({...params, id: selected?.soushinsakiYuujinChijin}, LeavesYuujinChijin);
        if (contact?.length > 0) {
          setSoushinsakiName(contact[0]?.shimei);
          return;
        }
      };
      getData();
    }
    setSoushinsakiName(null);
  }, [params, selected]);

  return (
    <ListSelectContent
      label={getLabel("taisetsunishitaihitohenomessage")}
      description={{
        type: "will",
        category: "message",
        subDescription: getLabel("description.taisetsunishitaihitohenomessage"),
      }}
      isShared={isShare(params)}
      mobileList={listMessage.map((data, index) => {
        return (
          <ListTwoLineB
            key={data?.id}
            label={data?.messagetitle}
            value={`${getLabel("soushinbi")}：${
              data?.soushinbi
                ? formatDateLabel(data?.soushinbi)
                : getLabel("nonsetting")
            }`}
            isSent={data.isSentMessage}
            onClick={() => {
              navigate(data?.id);
            }}
          />
        );
      })}
      addButtonLabel={getLabel("メッセージ") + getLabel("button.addsuffix")}
      isDisabled={isDisabled}
      onAddClick={() => {
        navigate("add");
        window.gtag("event", "FirstWrite", {
          event_category: "Edit",
          event_label: "大切にしたい人へのメッセージ書込開始",
        });
      }}
      style={{ maxHeight: "calc(100vh - 455px)", marginTop: "0" }}
      pcListItem={listMessage.map((ele, index) => {
        return (
          <ListTwoLineB
            key={ele?.id}
            label={ele?.messagetitle}
            value={`${getLabel("soushinbi")}：${
              ele?.soushinbi
                ? formatDateLabel(ele?.soushinbi)
                : getLabel("nonsetting")
            }`}
            isSent={ele?.isSentMessage}
            selected={selected?.id === ele?.id}
            onClick={() => {
              handledChangeActive(ele);
            }}
          />
        );
      })}
      selectedItemList={[
        {
          label: getLabel("messagetitle"),
          value: selected?.messagetitle,
        },
        {
          isEmptyHide: true,
          label: getLabel("soushinsaki"),
          value: soushinsakiName,
        },
        {
          isEmptyHide: true,
          label: getLabel("messagenaiyou"),
          value: selected?.messagenaiyou,
        },
        {
          isEmptyHide: true,
          label: getLabel("tenpufile"),
          value: imageList[selected?.id] && (
            <StrageImage key={selected?.id} src={imageList[selected?.id]} />
          ),
        },
        {
          isEmptyHide: true,
          label: getLabel("soushinbi"),
          value: selected?.soushinbi,
          type: isNaN(new Date(selected?.soushinbi)) ? "text" : "date",
        },
      ]}
      selectedItemLabel={selected?.messagetitle}
      onDelete={() => deleteData(selected)}
      onEdit={() => {
        navigate(selected?.id + "/edit");
        window.gtag("event", "Change", {
          event_category: "Edit",
          event_label: "大切にしたい人へのメッセージ変更開始",
        });
      }}
      hideButtonEdit={selected?.isSentMessage}
      isLoaded={isLoaded}
    />
  );
}

export default MessagePage;
