import { DataStore } from "aws-amplify";
import { Fragment, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import { LeavesHoken } from "../models";
import {
  LabelS,
  ListSelectContent,
  ListSingleLineA,
  ListSingleLineD
} from "../page-components";
import { getLabel, useInitializePage } from "../utils/CommonUtils";
import { log } from "../utils/Logger";
import { isShare, queryDatas } from "../utils/ShareUtils";

export default function InsurancePage() {
  const navigate = useInitializePage("hoken");

  const [isLoaded, setIsLoaded] = useState(false);
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState(null);
  const [renderList, setRenderList] = useState();
  const firstItemAdded = useRef(false);
  const params = useParams();

  useEffect(() => {
    let mount = true;
    const getData = async () => {
      const assets = await queryDatas(params, LeavesHoken);
      log.debug("assets", assets);
      if (mount) {
        setIsLoaded(true);
        setList(assets);
      }
    };
    getData();

    return () => {
      mount = false;
    };
  }, []);

  useEffect(() => {
    if (list?.length > 0) {
      const sorted = Array.from(new Set(list.map((data) => data.hokenshurui)))
        .sort()
        .map((type, index) => {
          const jsxList = [];
          const dsList = list.filter(function (item, index, array) {
            return item.hokenshurui === type;
          });
          if (dsList.length > 0) {
            jsxList.push(<LabelS key={type} label={type} margin="0" className={undefined} />);
            dsList.forEach((asset) => {
              if (!firstItemAdded.current && asset) {
                setSelected(asset);
                firstItemAdded.current = true;
              }
              if (isMobile) {
                jsxList.push(
                  <ListSingleLineA
                    key={asset.id}
                    label={asset.hokengaisha}
                    onClick={() => navigate(asset.id)}
                  />,
                );
              } else {
                jsxList.push(
                  <ListSingleLineD
                    key={asset?.id}
                    label={asset?.hokengaisha}
                    selected={selected?.id === asset?.id}
                    onClick={() => {
                      handledChangeActive(asset);
                    }}
                  />,
                );
              }
            });
          }
          return jsxList;
        });
      setRenderList(sorted);
    } else {
      setRenderList([]);
      setSelected(null);
    }
  }, [list, navigate, selected, selected?.id]);

  const deleteData = async (data) => {
    log.debug("delete data", data);
    firstItemAdded.current = false;
    await DataStore.delete(data);
    const newList = await DataStore.query(LeavesHoken);
    setList(newList);
  };

  const handledChangeActive = (item) => {
    setSelected(item);
  };

  return (
    <ListSelectContent
      label={getLabel("hoken")}
      mobileList={
        renderList &&
        renderList.map((ele, index) => {
          return <Fragment key={index}>{ele}</Fragment>;
        })
      }
      description={{
        type: "data",
        category: "hoken",
      }}
      isShared={isShare(params)}
      addButtonLabel={getLabel("hoken") + getLabel("button.addsuffix")}
      onAddClick={() => {
        navigate("add");
        window.gtag("event", "FirstWrite", {
          event_category: "Edit",
          event_label: "保険書込開始",
        });
      }}
      pcListItem={
        renderList &&
        renderList.map((ele, index) => {
          return <Fragment key={index}>{ele}</Fragment>;
        })
      }
      selectedItemList={[
        {
          label: getLabel("hokenshurui"),
          value: selected?.hokenshurui,
        },
        {
          label: getLabel("hokengaisha"),
          value: selected?.hokengaisha,
        },
        {
          label: getLabel("hokenmei"),
          value: selected?.hokenmei,
        },
        {
          label: getLabel("bikou"),
          value: selected?.bikou,
        },
      ]}
      selectedItemLabel={selected?.hokengaisha}
      onDelete={() => deleteData(selected)}
      onEdit={() => {
        navigate(selected?.id + "/edit");
        window.gtag("event", "Change", {
          event_category: "Edit",
          event_label: "保険変更開始",
        });
      }}
      isLoaded={isLoaded}
    />
  );
}
