import { Flex, Radio, RadioGroupField, Text } from "@aws-amplify/ui-react";
import { isMobile } from "react-device-detect";

export default function RadioCustomGroup(props) {
  const { label, isRequired, options, handleChangeOption, active } = props;

  const onChange = (event) => {
    handleChangeOption(event?.target?.value);
  };

  const onUncheckRadio = (value) => () => {
    if (active === value) {
      handleChangeOption(null);
    }
  };

  return (
    <>
      <Flex
        className={isMobile ? "" : "maxwidth800"}
        direction="column"
        justifyContent="center"
        padding={isMobile ? "16px 16px 0 16px" : "0"}
        marginBottom={isMobile ? "0" : "16px"}
        gap="0"
      >
        <Text fontSize={isMobile ? "14px" : "16px"} color="rgb(48, 64, 80)">
          {isRequired ? (
            <span>
              {label}
              <font className="required">※</font>
            </span>
          ) : (
            label
          )}
        </Text>
        <RadioGroupField
          className="radio-custom"
          margin="2px"
          name="radioGroups"
          width="100%"
          value={active}
          onChange={onChange}
        >
          {options?.map((ele, idx) => {
            return (
              <Flex
                key={idx}
                direction="column"
                alignItems="flex-start"
                justifyContent="flex-start"
                width="100%"
                gap="8px"
              >
                <Radio
                  className="pointer"
                  width="100%"
                  value={ele?.value}
                  onClick={onUncheckRadio(ele?.value)}
                >
                  <Text fontSize={isMobile ? "14px" : "16px"}>
                    {ele?.label}
                  </Text>
                </Radio>
                {active === ele?.value && (
                  <Flex width="100%">{ele?.children}</Flex>
                )}
              </Flex>
            );
          })}
        </RadioGroupField>
      </Flex>
    </>
  );
}
