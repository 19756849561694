import { PCListTwoLineA } from "../ui-components";
import { formatDateLabel } from "../utils/CommonUtils";

export default function ListTwoLineAPC(props) {
  let formatedData = props.value;
  let multi = false;
  if (props.value && props.value.indexOf("\n") >= 0) {
    multi = true;
  }
  if (props.type === "date") {
    formatedData = formatDateLabel(formatedData);
  }
  if (props.isEmptyHide && !props.value) {
    return <></>;
  } else {
    return (
      <PCListTwoLineA
        className="pointer"
        backgroundColor={props.selected && "#DFE2DC"}
        overrides={{
          item: {
            children: props.label,
            className: "text-overflow-ellipsis",
            display: "block",
            width: "100%",
            whiteSpace: "pre",
            overflow: "hidden",
          },
          overline: {
            children: formatedData,
            textAlign: "justify",
            className: "word-break",
            whiteSpace: multi ? "break-spaces" : "",
            width: "fit-content",
          },
        }}
        onClick={props.onClick}
      />
    );
  }
}
