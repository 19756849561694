import forward from "../assets/image/forward_circle.png";
import { PCListSingleLineTwoColumItem } from "../ui-components";

const ListSingleLineTwoColumItem = (props) => {
  const { label, value, isPublished, onClick, isEnable } =
    props.isPublished || props.isEnable ? props : { ...props, onClick: null };

  return (
    <PCListSingleLineTwoColumItem
      className={isPublished || isEnable ? "pointer" : ""}
      display="flex"
      justifyContent="space-between"
      onClick={onClick}
      width="100%"
      overrides={{
        "Frame 554": {
          width: "100%",
        },
        list: {
          className: "text-overflow-ellipsis",
          maxWidth: "calc(100% - 20px)",
          whiteSpace: "pre",
          overflow: "hidden",
          display: "block",
          children: label,
          width: "100%",
        },
        ItemContent: {
          width: "100%",
        },
        Item: {
          className: "text-overflow-ellipsis",
          maxWidth:
            isPublished || isEnable ? "calc(100% - 40px)" : "calc(100% - 20px)",
          whiteSpace: "pre",
          overflow: "hidden",
          display: "block",
          children: value,
          width: "auto",
        },
        Icon: {
          display: isPublished ? "block" : "none",
          paths: isPublished && [
            {
              d: "M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM14.59 5.58L8 12.17L5.41 9.59L4 11L8 15L16 7L14.59 5.58Z",
              fill: "var(--my-theme-color)",
              fillRule: "nonzero",
              style: { transform: "translate(8.33%, 8.33%)" },
            },
          ],
        },
        "image 2": {
          left: "auto",
          right: "16px",
          position: "absolute",
          display: isPublished || isEnable ? "block" : "none",
          src: forward,
        },
      }}
    />
  );
};

export default ListSingleLineTwoColumItem;
