import {
  CardMindType,
  PCCardMindType,
  CardCheck,
  CardAsk,
  CardDo,
  CardData,
  CardWill,
  CardMemory,
  Footer,
  CardHint,
  CardHowto,
  PCFooter,
  PCCardCheck,
  PCCardDo,
  PCCardAsk,
  PCCardData,
  PCCardWill,
  PCCardHistory,
  PCCardHint,
  PCCardHowto,
} from "../ui-components";
import { Flex } from "@aws-amplify/ui-react";
// import { default as Footer } from "./Footer";
import { useInitializePage, getLabel, isEnableVideoCall } from "../utils/CommonUtils";
import { isMobile, isTablet } from "react-device-detect";
import forward from "../assets/image/forward_circle.png";
import check from "../assets/image/check_cardIC.png";
import checkicon from "../assets/image/Check.png";
import askicon from "../assets/image/fukidashi.png";
import doicon from "../assets/image/do_cardIC.png";
import mind from "../assets/image/data_cardMIND.png";
import data from "../assets/image/data_cardIC.png";
import will from "../assets/image/will_cardIC.png";
import history from "../assets/image/history_cardIC.png";
import fb from "../assets/image/fbIC.png";
import tw from "../assets/image/twitterIC.png";
import colum from "../assets/image/Colum.png";
import event from "../assets/image/Event.png";
import hint from "../assets/image/hint_cardIC.png";
import kv from "../assets/image/KeyVisual.png";
import pckv from "../assets/image/PC_KeyVisual.png";
import oneBadge from "../assets/image/One_badge.png";
import { AskDialogPopup } from "../page-components";
import Cookies from "js-cookie";
import React, { useState } from "react"; 

const SERVICE_POLICY = process.env.REACT_APP_SERVICE_POLICY;
const CONSULT_EXPERT = process.env.REACT_APP_URL_CONSULT_EXPERT;

export default function () {
  const navigate = useInitializePage("home");
  const [isShowedAskDialogPopup, setIsShowedAskDialogPopup] = useState("none");

  const openPopup = () => {
    window.gtag("event", "AskDialogPopup", {
      event_category: "Share",
      event_label: "開く",
    });
    setIsShowedAskDialogPopup("block");
  };

  const openMindType = () => {
    window.gtag("event", "MindType", {
      event_category: "Share",
      event_label: "ホームページから幸福度診断ページ遷移",
    });
    navigate("/mindtype");
  }

 return (
    <div>
      {isMobile && !isTablet ? (
        <div className="homepage">
          <Flex padding="32px 0" direction="column" alignItems="center">
            <CardMindType
              className="pointer"
              onClick={openMindType}
              overrides={{
                "check_cardIC 1": {
                  src: mind,
                },
              }}
            />
            <CardCheck
              className="pointer"
              onClick={() => navigate("/check")}
              overrides={{
                "check_cardIC 1": {
                  src: check,
                },
              }}
            />
            <CardDo
              className="pointer"
              onClick={() => navigate("/will/bucketlist")}
              overrides={{
                do_cardIC: {
                  src: doicon,
                },
              }}
            />
            <CardAsk
              className="pointer"
              overrides={{
                ask_cardASK: {
                  src: askicon,
                },
                Button: {
                  onClick: openPopup,
                },
              }}
            />
            <CardData
              className="pointer"
              onClick={() => navigate("/data")}
              overrides={{
                "data_cardIC 1": {
                  src: data,
                },
                "foward_circle 1": {
                  src: forward,
                },
              }}
            />
            <CardWill
              className="pointer"
              onClick={() => navigate("/will")}
              overrides={{
                "will_cardIC 1": {
                  src: will,
                },
                "foward_circle 1": {
                  src: forward,
                },
              }}
            />
            <CardMemory
              className="pointer"
              onClick={() => navigate("/history")}
              overrides={{
                "history_cardIC 1": {
                  src: history,
                },
                "foward_circle 1": {
                  src: forward,
                },
              }}
            />
            <CardHint
              overrides={{
                Title: {
                  className: "pointer",
                  onClick: () => navigate("/hint"),
                },
                ColumContent: {
                  className: "pointer",
                  onClick: () => navigate("/hint"),
                },
                EventContent: {
                  className: "pointer",
                  onClick: () => navigate("/event"),
                },
                Hint: {
                  src: hint,
                },
                Colum: {
                  width: "auto",
                  src: colum,
                },
                Event: {
                  width: "auto",
                  src: event,
                },
                Next: {
                  src: forward,
                },
                "One_badge 1": {
                  display: "none",
                  src: oneBadge,
                },
                Next2: {
                  src: forward,
                },
              }}
            />
            <CardHowto
              onClick={() => navigate("/howto")}
              overrides={{
                KeyVisual: {
                  src: kv,
                },
                Next: {
                  width: "25px",
                  height: "25px",
                  src: forward,
                },
              }}
            />
          </Flex>
          <Footer
            width="100vw"
            overrides={{
              Facebook: {
                display: "none",
                className: "pointer",
                src: fb,
              },
              Twitter: {
                display: "none",
                className: "pointer",
                src: tw,
              },
              TextButton579420633: {
                className: "pointer",
                onClick: () => window.open(SERVICE_POLICY, "_blank"),
                overrides: {
                  "Flex.Text[0]": {
                    color: "#ffffff",
                    children: getLabel("servicepolicy"),
                  },
                },
              },
              TextButton579421517: {
                className: "pointer",
                onClick: () => window.open("https://tokyu-laviere.co.jp/", "_blank"),
                overrides: {
                  "Flex.Text[0]": {
                    color: "#ffffff",
                    children: getLabel("company"),
                  },
                },
              },
              TextButton579422086: {
                className: "pointer",
                onClick: () => window.open(CONSULT_EXPERT, "_blank"),
                overrides: {
                  "Flex.Text[0]": {
                    color: "#ffffff",
                    children: getLabel("contact"),
                  },
                },
              },
            }}
          />
          <AskDialogPopup isShowedPopup={isShowedAskDialogPopup} setIsShowedPopup={setIsShowedAskDialogPopup} />
        </div>
      ) : (
        <>
          <Flex
            style={{
              flexDirection: "column",
              transformOrigin: "center 400px",
              transform: isTablet ? "scale(0.7)" : "scale(1)",
              height: isTablet ? "900px" : "auto",
            }}
            alignItems="center"
            justifyContent="center"
          >
            <Flex margin="0">
              <PCCardMindType
                className="pointer"
                onClick={openMindType}
                overrides={{
                  Check: {
                    src: mind,
                  },
                }}
              />
              <PCCardAsk
                className="pointer"
                overrides={{
                  Ask: {
                    src: askicon,
                  },
                  Button: {
                    onClick: openPopup,
                  },
                }}
              />
            </Flex>
            <Flex margin="0">
              <PCCardCheck
                className="pointer"
                onClick={() => navigate("/check")}
                overrides={{
                  Check: {
                    src: check,
                  },
                }}
              />
              <PCCardDo
                className="pointer"
                onClick={() => navigate("/will/bucketlist")}
                overrides={{
                  Do: {
                    src: doicon,
                  },
                }}
              />
            </Flex>
            <Flex>
              <PCCardData
                className="pointer"
                onClick={() => navigate("/data")}
                overrides={{
                  Data: {
                    src: data,
                  },
                  Next: {
                    src: forward,
                  },
                }}
              />
              <PCCardWill
                className="pointer"
                onClick={() => navigate("/will")}
                overrides={{
                  Will: {
                    src: will,
                  },
                  Next: {
                    src: forward,
                  },
                }}
              />
            </Flex>
            <Flex>
              <PCCardHistory
                className="pointer"
                onClick={() => navigate("/history")}
                overrides={{
                  History: {
                    src: history,
                  },
                  Next: {
                    src: forward,
                  },
                }}
              />
              <PCCardHint
                overrides={{
                  Title: {
                    className: "pointer",
                    onClick: () => navigate("/hint"),
                  },
                  CardColum: {
                    className: "pointer",
                    onClick: () => navigate("/hint"),
                  },
                  CardEvent: {
                    className: "pointer",
                    onClick: () => navigate("/event"),
                  },
                  Hint: {
                    width: "auto",
                    src: hint,
                  },
                  Colum: {
                    width: "auto",
                    src: colum,
                  },
                  Event: {
                    src: event,
                  },
                  Next584557548: {
                    src: forward,
                  },
                  Next584557569: {
                    src: forward,
                  },
                  "One_badge 1": {
                    display: "none",
                    height: "auto",
                    src: oneBadge,
                  },
                }}
              />
            </Flex>
            <Flex margin="32px 0">
              <PCCardHowto
                className="pointer"
                onClick={() => navigate("/howto")}
                overrides={{
                  PC_KeyVisual: {
                    src: pckv,
                  },
                  Next: {
                    src: forward,
                  },
                }}
              />
            </Flex>
          </Flex>
          <Flex>
            <PCFooter
              width={"100vw"}
              margin={"32px 0 0 0"}
              overrides={{
                Footer: {
                  width: "100%",
                  overrides: {
                    Facebook: {
                      display: "none",
                      className: "pointer",
                      src: fb,
                    },
                    Twitter: {
                      display: "none",
                      className: "pointer",
                      src: tw,
                    },
                    TextButton579420633: {
                      className: "pointer",
                      onClick: () => window.open(SERVICE_POLICY, "_blank"),
                      overrides: {
                        "Flex.Text[0]": {
                          color: "#ffffff",
                          children: getLabel("servicepolicy"),
                        },
                      },
                    },
                    TextButton579421517: {
                      className: "pointer",
                      onClick: () => window.open("https://tokyu-laviere.co.jp/", "_blank"),
                      overrides: {
                        "Flex.Text[0]": {
                          color: "#ffffff",
                          children: getLabel("company"),
                        },
                      },
                    },
                    TextButton579422086: {
                      className: "pointer",
                      onClick: () => window.open("https://hiraql.tokyu-laviere.co.jp/contact", "_blank"),
                      overrides: {
                        "Flex.Text[0]": {
                          color: "#ffffff",
                          children: getLabel("contact"),
                        },
                      },
                    },
                  },
                },
              }}
            />
          </Flex>
          <AskDialogPopup isShowedPopup={isShowedAskDialogPopup} setIsShowedPopup={setIsShowedAskDialogPopup} />
        </>
      )}
    </div>
  );
}
