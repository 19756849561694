import { DataStore } from "aws-amplify";
import React, { useContext, useState } from "react";
import { isMobile } from "react-device-detect";
import { LeavesHoken } from "../models";
import {
  ButtonCancelSave,
  SwapperContent,
  TextField,
  TextFieldMultiline,
} from "../page-components";
import UserContext from "../UserContext";
import { getLabel, isEmpty, useInitializePage } from "../utils/CommonUtils";
import { HOKENSHURUI, useDatalist } from "../utils/DatalistUtils";
import { log } from "../utils/Logger";
import { usePrompt } from "../utils/Prompt";

/*
  shisanmei: String
  shisanshurui: Shisanshurui
  shozaibasho: String
  bikou: String
  _shisanshurui: String
  leavesusersID: ID! @index(name: "byLeavesUsers")
*/
export default function () {
  const navigate = useInitializePage("hoken", "title.add");

  const context = useContext(UserContext);

  const [hokenmei, setHokenmei] = useState("");
  const [hokengaisha, setHokengaisha] = useState("");
  const [bikou, setBikou] = useState("");

  const [hokenmeiError, setHokenmeiError] = useState(null);
  const [hokengaishaError, setHokengaishaError] = useState(null);
  const [bikouError, setBikouError] = useState(null);
  const [isAdded, setIsAdded] = useState(false);

  const [update, setUpdate] = useState(false);

  usePrompt((location, action) => {
    if (action == "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  const [
    HokenshuruiDataList,
    setHokenshurui,
    getHokenshurui,
    hasErrorHokenshurui,
  ] = useDatalist({
    list: HOKENSHURUI,
    required: true,
    dataUpdateHook: setUpdate,
  });
  const addData = async () => {
    log.debug(
      hokenmei + "," + getHokenshurui() + "," + hokengaisha + "," + bikou,
    );
    let valid = true;
    if (isEmpty(hokengaisha)) {
      setHokengaishaError(getLabel("error.required"));
      valid = false;
    } else {
      setHokengaishaError(null);
    }
    if (hasErrorHokenshurui()) {
      valid = false;
    }
    if (valid) {
      //   console.log(context.leavesUser);
      setIsAdded(true);
      await DataStore.save(
        new LeavesHoken({
          hokenmei: hokenmei,
          hokenshurui: getHokenshurui(),
          hokengaisha: hokengaisha,
          bikou: bikou,
          leavesusersID: context.leavesUser.id,
        }),
      );
      window.gtag("event", "FirstWriteComplete", {
        event_category: "Edit",
        event_label: "保険書込完了",
      });
      navigate(-1, { replace: true });
    }
  };

  return (
    <SwapperContent label={getLabel("shinkitsuika")}>
      <HokenshuruiDataList label="hokenshurui" />
      <TextField
        isRequired
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("hokengaisha")}
        errorMessage={hokengaishaError}
        onChange={(event) => {
          setHokengaisha(event.target.value);
          setUpdate(true);
        }}
      />
      <TextField
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("hokenmei")}
        errorMessage={hokenmeiError}
        placeHolder={getLabel("marumaruhokennado")}
        onChange={(event) => {
          setHokenmei(event.target.value);
          setUpdate(true);
        }}
      />
      <TextFieldMultiline
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("bikou")}
        errorMessage={bikouError}
        onChange={(event) => {
          setBikou(event.target.value);
          setUpdate(true);
        }}
      />
      <ButtonCancelSave
        cancelOnClick={() => navigate(-1, { replace: true })}
        saveOnClick={() => addData()}
        isDisabled={isAdded}
      />
    </SwapperContent>
  );
}
