import { Flex, Text } from "@aws-amplify/ui-react";
import { API, Auth, DataStore } from "aws-amplify";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import { listLeavesShareInfos } from "../graphql/queries";
import { LeavesSharedInfo } from "../models";
import { InviteDialog, LabelM, ListSingleLineCEdit, ListTwolineA, ListTwolineATextButton } from "../page-components";
import { getLabel, useInitializePage } from "../utils/CommonUtils";
import { listGraphqlQuery } from "../utils/ShareUtils";

function InvitedDetailNotePage() {
  const navigate = useInitializePage("invited"); /**TODO: */
  let params = useParams();
  const [noteData, setNoteData] = useState({});
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [data, setData] = useState({});

  const handelDialog = (value) => {
    setIsOpenDialog(value);
  };

  const onComplete = async (value) => {
    const updated = await DataStore.save(
      LeavesSharedInfo.copyOf(data, (updated) => {
        updated.name = value;
      })
    );
    setNoteData((s) => ({
      ...s,
      namae: updated.name,
    }));
    setIsOpenDialog(false);
    if (noteData.namae == null) {
      window.gtag("event", "ReceiveNote", {
        event_category: "Share",
        event_label: "ノート受領",
      });
      navigate("/other/invited", { replace: true });
    }
  };

  useEffect(async () => {
    window.scrollTo(0, 0);
    const sharedInfo = await DataStore.query(LeavesSharedInfo, (c) => c.id("eq", params.id));
    console.log(sharedInfo);
    if (sharedInfo?.length > 0) {
      setData(sharedInfo[0]);
      setNoteData({
        namae: sharedInfo[0].name,
        shotaisaretahi: sharedInfo[0].createdAt,
      });
      if (!sharedInfo[0].name) {
        setIsOpenDialog(true);
      }
      const shareInfoQuery = await listGraphqlQuery("listLeavesShareInfos", { id: { eq: sharedInfo[0].shareInfoId } });
      if (shareInfoQuery.length > 0) {
        // メールアドレスの取得
        const session = await Auth.currentSession();
        let apiName = "AdminQueries";
        let path = "/getUser";
        let params = {
          queryStringParameters: {
            username: shareInfoQuery[0].owner,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: session.getAccessToken().getJwtToken(),
          },
        };
        const response = await API.get(apiName, path, params);

        console.log(response);
        setNoteData({
          namae: sharedInfo[0].name,
          shotaishanomeruadoresu: response.UserAttributes.filter((a) => a.Name === "email")[0].Value,
          shotaisaretahi: sharedInfo[0].createdAt,
        });
      }
    }
  }, []);

  const listLabel = [
    {
      label: getLabel("namae"),
      value: isMobile ? (
        noteData?.namae ? (
          noteData?.namae
        ) : (
          getLabel("notset")
        )
      ) : (
        <Flex gap="24px">
          <Text>{noteData?.namae ? noteData?.namae : getLabel("notset")}</Text>
          <Text
            className="pointer"
            whiteSpace="nowrap"
            color="var(--my-theme-color)"
            fontWeight="700"
            onClick={() => {
              handelDialog(true);
            }}
          >
            {getLabel("update")}
          </Text>
        </Flex>
      ),
      buttonLabel: getLabel("update"),
      onClick: () => {
        handelDialog(true);
      },
      isEdit: true,
    },
    {
      label: getLabel("shotaishanomeruadoresu"),
      value: noteData?.shotaishanomeruadoresu,
    }, //**TODO: */
    { label: getLabel("shotaisaretahi"), value: noteData?.shotaisaretahi, type: "date" }, //**TODO: */
  ];

  return (
    <div className={!isMobile ? "swapper" : ""}>
      <LabelM label={noteData.namae} isDivide={!isMobile} />
      {isMobile ? (
        listLabel.map((ele, index) => {
          return ele.isEdit ? (
            <ListTwolineATextButton key={index} label={getLabel(ele.label)} value={ele.value} buttonLabel={ele.buttonLabel} onClick={ele.onClick} />
          ) : (
            <ListTwolineA key={index} isEmptyHide={ele.isRequire ? false : true} label={getLabel(ele.label)} value={ele.value} type={ele.type} />
          );
        })
      ) : (
        <Flex maxWidth="896px">
          <ListSingleLineCEdit
            list={listLabel}
            overrides={{
              "Flex.PCPrimaryButtonSave[0]": {
                display: "none",
              },
            }}
          />
        </Flex>
      )}
      <InviteDialog
        isOpen={isOpenDialog}
        onOpen={handelDialog}
        onComplete={onComplete}
        title={noteData.namae == null ? getLabel("notononamaeotoroku") : getLabel("notononamaeohenko")}
        description={noteData.namae == null ? getLabel("dialogNote.des") : undefined}
        /**TODO: */
      />
    </div>
  );
}

export default InvitedDetailNotePage;
