import React, { useState, useContext, useRef, useEffect } from "react";
import { Priority, PriorityLabel } from "../constants";
import { Tasseijoukyoushurui } from "../models";
import { ListField } from "../page-components";
import {
  getCurrentUserAge,
  getLabel,
  getListRangeAge,
  isEmpty,
  isNotEmpty,
  useInitializePage,
} from "../utils/CommonUtils";
import { usePrompt } from "../utils/Prompt";
import UserContext from "../UserContext";
import { DataStore, Storage } from "aws-amplify";
import { LeavesLifeFuture } from "../models";
import { useParams } from "react-router-dom";

export default function HabitEditPage() {
  const navigate = useInitializePage("challenge", "title.update");
  const context = useContext(UserContext);
  const currentUserAge = getCurrentUserAge(context.user?.attributes.birthdate);
  const ageRangeOption = getListRangeAge(currentUserAge);
  const params = useParams();
  const data = useRef();
  const [challengeData, setChallengeData] = useState({
    yaritaikoto: "",
    jitsugennenreikigen: "",
    tasseijoukyoushurui: "",
    imagegazou: null,
    yuusendo: "",
    episode: "",
    jitsugenstep: [],
    bikou: "",
  });
  const [challengeDataError, setChallengeDataError] = useState({
    yaritaikoto: "",
  });
  const [isUpdated, setIsUpdated] = useState(false);
  const [update, setUpdate] = useState(false);
  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  useEffect(() => {
    const getData = async () => {
      const dataQuery = await DataStore.query(LeavesLifeFuture, (c) =>
        c.id("eq", params.id)
      );
      if (dataQuery.length > 0) {
        const image = await Storage.list(`LeavesLifeFuture/${params.id}/`, {
          level: "private",
        });
        setChallengeData({
          ...dataQuery[0],
          jitsugennenreikigen: getRangeValue(dataQuery[0]?.jitsugennenreikigen),
          imagegazou: image.length > 0 && image[0]?.key,
          objectSrc: image.length > 0 && image[0]?.key,
        });
        data.current = dataQuery[0];
      }
    };
    getData();
  }, [params]);

  const getRangeValue = (age) => {
    return ageRangeOption.find(
      (ele, idx) =>
        (age >= Number(ele.value) && age <= Number(ele.value) + 4) ||
        (idx === 0 && age <= Number(ele?.value)) ||
        (idx === ageRangeOption?.length - 1 && age >= Number(ele?.value))
    )?.value;
  };

  const listField = [
    {
      type: "textField",
      label: "yaritaikoto",
      placeHolder: "fujisanninoborunado",
      value: challengeData?.yaritaikoto,
      isRequire: true,
      hasError: isNotEmpty(challengeDataError?.yaritaikoto),
      errorMessage: challengeDataError?.yaritaikoto,
    },
    {
      type: "image",
      label: "imagegazou",
      value: challengeData.objectSrc,
    },
    {
      type: "textArea",
      label: "episode",
      subLabel: ".bucketlist",
      value: challengeData?.episode,
      placeHolder: "yaritairiyuunado.challenge",
      isRequire: true,
      hasError: isNotEmpty(challengeDataError?.episode),
      errorMessage: challengeDataError?.episode,
    },
    {
      type: "selectField",
      label: "jitsugennenreikigen",
      value: challengeData?.jitsugennenreikigen,
      isRequire: true,
      options: ageRangeOption?.map((ele) => {
        return (
          <option key={ele?.value} value={ele?.value}>
            {ele?.label}
          </option>
        );
      }),
      isSelect: true,
    },
    {
      type: "selectField",
      label: "yuusendo",
      value: challengeData?.yuusendo,
      isRequire: true,
      options: [
        <option key={Priority.KOU} value={Priority.KOU}>
          {getLabel(PriorityLabel.KOU)}
        </option>,
        <option key={Priority.CHUU} value={Priority.CHUU}>
          {getLabel(PriorityLabel.CHUU)}
        </option>,
        <option key={Priority.TEI} value={Priority.TEI}>
          {getLabel(PriorityLabel.TEI)}
        </option>,
      ],
    },
    {
      type: "selectField",
      label: "tasseijoukyoushurui",
      isRequire: true,
      value: challengeData?.tasseijoukyoushurui,
      options: Object.keys(Tasseijoukyoushurui).map((type) => {
        return (
          <option key={type} value={type}>
            {getLabel("Tasseijoukyoushurui." + type)}
          </option>
        );
      }),
    },
    { type: "textArea", value: challengeData?.bikou, label: "bikou" },
  ];

  const eventTracking = () => {
    window.gtag("event", "ChangeComplete", {
      event_category: "Edit",
      event_label: "やりたいこと変更完了",
    });
  };

  const handleUpdate = async () => {
    let valid = true;
    const error = { ...challengeDataError };
    if (isEmpty(challengeData?.yaritaikoto)) {
      error.yaritaikoto = getLabel("error.required");
      valid = false;
    } else {
      error.yaritaikoto = "";
    }
    if (isEmpty(challengeData?.episode)) {
      error.episode = getLabel("error.required");
      valid = false;
    } else {
      error.episode = "";
    }

    setChallengeDataError(error);

    if (valid) {
      setIsUpdated(true);

      await DataStore.save(
        LeavesLifeFuture.copyOf(data.current, (updated) => {
          updated.yaritaikoto = challengeData.yaritaikoto;
          updated.jitsugennenreikigen = Number(
            challengeData.jitsugennenreikigen
          );
          updated.tasseijoukyoushurui = challengeData.tasseijoukyoushurui;
          updated.yuusendo = challengeData.yuusendo
            ? challengeData.yuusendo
            : Priority.KOU;
          updated.episode = challengeData.episode;
          updated.bikou = challengeData.bikou;
        })
      );
      if (challengeData.imagegazou !== challengeData.objectSrc) {
        const editData = async () => {
          const listFile = await Storage.list(
            `LeavesLifeFuture/${data.current.id}/`,
            {
              level: "private",
            }
          );
          for (let i = 0; i < listFile.length; i++) {
            await Storage.remove(listFile[i].key, { level: "private" });
          }
          if (challengeData.imagegazou) {
            await Storage.put(
              `LeavesLifeFuture/${data.current.id}/${challengeData.imagegazou.name}`,
              challengeData.imagegazou,
              {
                contentType: challengeData.imagegazou.type,
                level: "private",
              }
            );
          }
          eventTracking();
          navigate(-1, { replace: true });
        };
        editData();
      } else {
        eventTracking();
        navigate(-1, { replace: true });
      }
    }
  };

  return (
    <ListField
      label={getLabel("button.update")}
      list={listField}
      setUpdate={setUpdate}
      setState={setChallengeData}
      isDisabled={isUpdated}
      onSave={handleUpdate}
      onCancel={() => navigate(-1, { replace: true })}
    />
  );
}
