import { DataStore } from "aws-amplify";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { LeavesCreditcard } from "../models";
import { ListField } from "../page-components";
import UserContext from "../UserContext";
import {
  getLabel,
  isEmpty,
  isNotEmpty,
  useInitializePage,
} from "../utils/CommonUtils";
import { usePrompt } from "../utils/Prompt";

function CardAddPage() {
  const navigate = useInitializePage("creditcard", "title.add");
  const context = useContext(UserContext);
  const [cardData, setCardData] = useState({
    creditcardmei: "",
    cardbrand: "",
    cardhakkougaisha: "",
    bikou: "",
  });
  const [cardDataError, setCardDataError] = useState({
    creditcardmeiError: "",
    cardbrandError: "",
    cardhakkougaishaError: "",
    bikouError: "",
  });
  const [update, setUpdate] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  const addData = useCallback(async () => {
    let valid = true;
    if (isEmpty(cardData.creditcardmei)) {
      setCardDataError({
        ...cardDataError,
        creditcardmeiError: getLabel("error.required"),
      });
      valid = false;
    }
    if (valid) {
      setIsAdded(true);
      await DataStore.save(
        new LeavesCreditcard({
          creditcardmei: cardData.creditcardmei,
          cardbrand: cardData.cardbrand,
          cardhakkougaisha: cardData.cardhakkougaisha,
          bikou: cardData.bikou,
          leavesusersID: context.leavesUser.id,
        }),
      );
      window.gtag("event", "FirstWriteComplete", {
        event_category: "Edit",
        event_label: "クレジットカード書込完了",
      });
      navigate(-1, { replace: true });
    }
  }, [
    cardData.bikou,
    cardData.cardbrand,
    cardData.cardhakkougaisha,
    cardData.creditcardmei,
    cardDataError,
    context.leavesUser.id,
    navigate,
  ]);

  const listField = [
    {
      type: "textField",
      label: "creditcardmei",
      placeHolder: "toukyucardnado",
      hasError: isNotEmpty(cardDataError.creditcardmeiError),
      errorMessage: cardDataError.creditcardmeiError,
      isRequire: true,
    },
    {
      type: "textField",
      label: "cardbrand",
      placeHolder: "visa",
    },
    {
      type: "textField",
      label: "cardhakkougaisha",
    },
    {
      type: "textArea",
      label: "bikou",
      subLabel: ".creditcard",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ListField
      label={getLabel("shinkitsuika")}
      list={listField}
      setState={setCardData}
      setUpdate={setUpdate}
      onSave={addData}
      onCancel={() => navigate(-1, { replace: true })}
      isDisabled={isAdded}
    />
  );
}

export default CardAddPage;
