import { Flex, Radio, RadioGroupField, Text } from "@aws-amplify/ui-react";
import { isMobile } from "react-device-detect";

export default function RatioGroups(props) {
  const { label, list, isRequired, value, onChange, isDisabled, subLabel } = props;

  return (
    <Flex
      className={isMobile ? "" : "maxwidth800"}
      direction="column"
      justifyContent="center"
      padding={isMobile ? "16px 16px 0 16px" : "0"}
      marginBottom={isMobile ? "0" : "16px"}
      gap="0"
    >
      <Text fontSize={isMobile ? "14px" : "16px"} color="rgb(48, 64, 80)">
        {isRequired ? (
          <span>
            {subLabel ? subLabel : label}
            <font className="required">※</font>
          </span>
        ) : (
          subLabel ? subLabel : label
        )}
      </Text>

      <RadioGroupField
        margin="2px"
        name="ratioGroups"
        onChange={onChange}
        value={value}
        isDisabled={isDisabled}
      >
        {list.map((ele, index) => {
          return (
            <Radio key={index} value={ele.value} className="pointer">
              {ele?.text}
            </Radio>
          );
        })}
      </RadioGroupField>
    </Flex>
  );
}
