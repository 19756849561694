import { EventListChecked } from "../ui-components";
import { isMobile } from "react-device-detect";
import circle_check from "../assets/image/circle_check.png";

export default function (props) {
  if (isMobile) {
    return (
      <EventListChecked
        width="auto"
        margin="0px auto 0px auto"
        className="pointer"
        onClick={props.onClick}
        overrides={{
          Divider: {
            opacity: props.firstRow ? "0" : "1",
          },
          "Group 9": {
            overflow: "hidden",
          },
          Image: {
            objectFit: "contain",
            src: props.src,
          },
          EventTitle: {
            lineHeight: "140%",
            children: props.title,
          },
          "circle_check 2": {
            src: circle_check,
            display: props.confirmed ? "block" : "none",
          },
        }}
      />
    );
  } else {
    return (
      <EventListChecked
        width="auto"
        margin="0px auto 0px auto"
        className="pccomponent pointer"
        onClick={props.onClick}
        overrides={{
          Divider: {
            opacity: props.firstRow ? "0" : "1",
          },
          "Group 9": {
            overflow: "hidden",
          },
          Image: {
            objectFit: "contain",
            src: props.src,
          },
          EventTitle: {
            lineHeight: "140%",
            children: props.title,
          },
          "circle_check 2": {
            src: circle_check,
            display: props.confirmed ? "block" : "none",
          },
        }}
      />
    );
  }
}
