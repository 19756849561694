import { DataStore } from "aws-amplify";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LeavesLoginId } from "../models";
import {
  ListSelectContent,
  ListSingleLineA,
  ListSingleLineD,
} from "../page-components";
import { getLabel, useInitializePage } from "../utils/CommonUtils";
import { isShare, queryDatas } from "../utils/ShareUtils";

function LoginIDPage() {
  const navigate = useInitializePage("kakushuserviceid");
  const [listLoginID, setListLoginID] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selected, setSelected] = useState(null);
  const params = useParams();

  const handledChangeActive = (item) => {
    setSelected(item);
  };

  useEffect(() => {
    if (listLoginID?.length > 0) {
      setSelected(listLoginID[0]);
    } else {
      setSelected(null);
    }
  }, [listLoginID]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const modelData = await queryDatas(params, LeavesLoginId);
      if (modelData?.length > 0) setListLoginID(modelData);
      setIsLoaded(true);
    };
    getData();
  }, []);

  const deleteData = async (data) => {
    await DataStore.delete(data);
    const newList = await DataStore.query(LeavesLoginId);
    setListLoginID(newList);
  };

  return (
    <ListSelectContent
      label={getLabel("kakushuserviceid")}
      mobileList={listLoginID.map((data, index) => {
        return (
          <ListSingleLineA
            key={data?.id}
            label={data?.servicemei}
            onClick={() => navigate(data?.id)}
          />
        );
      })}
      description={{
        type: "data",
        category: "account",
      }}
      isShared={isShare(params)}
      addButtonLabel={getLabel("loginjouhou") + getLabel("button.addsuffix")}
      onAddClick={() => {
        navigate("add");
        window.gtag("event", "FirstWrite", {
          event_category: "Edit",
          event_label: "WEBサービスのログイン情報書込開始",
        });
      }}
      pcListItem={listLoginID.map((ele, index) => {
        return (
          <ListSingleLineD
            key={ele?.id}
            label={ele?.servicemei}
            selected={selected?.id === ele?.id}
            onClick={() => {
              handledChangeActive(ele);
            }}
          />
        );
      })}
      selectedItemList={[
        {
          label: getLabel("servicemei"),
          value: selected?.servicemei,
        },
        {
          isEmptyHide: true,
          label: getLabel("siteurl"),
          value: selected?.siteurl,
        },
        {
          isEmptyHide: true,
          label: getLabel("loginid"),
          value: selected?.loginid,
        },
        {
          isEmptyHide: true,
          label: getLabel("bikou"),
          value: selected?.bikou,
        },
      ]}
      selectedItemLabel={selected?.servicemei}
      onDelete={() => deleteData(selected)}
      onEdit={() => {
        navigate(selected?.id + "/edit");
        window.gtag("event", "Change", {
          event_category: "Edit",
          event_label: "WEBサービスのログイン情報変更開始",
        });
      }}
      isLoaded={isLoaded}
    />
  );
}

export default LoginIDPage;
