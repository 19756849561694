import { PCListSingleLineB, ListSingleLineBIcon } from "../ui-components";
import { isMobile } from "react-device-detect";
import { getLabel } from "../utils/CommonUtils";
export default function (props) {
  console.log(props.checked);
  if (isMobile) {
    return (
      <ListSingleLineBIcon
        width="auto"
        margin="0 16px 0 16px"
        overrides={{
          View: {
            margin: "16px",
            className: "pointer",
            onClick: props.onClick,
          },
          "View.Text[0]": {
            children: props.label,
            className: "text-overflow-ellipsis",
            maxWidth: "calc(100vw - 130px)",
            whiteSpace: "pre",
            overflow: "hidden",
            display: "block",
          },
          "View.IconCheckCircle[0]": props.checked ? {} : { color: "#EFF0F0" },
        }}
      />
    );
  } else {
    return (
      <PCListSingleLineB
        margin="0 auto 16px auto"
        className="pccomponent pointer"
        onClick={props.onClick}
        overrides={{
          "Flex.Text[0]": {
            children: props.label,
            className: "text-overflow-ellipsis",
            maxWidth: "calc(100vw - 130px)",
            whiteSpace: "pre",
            overflow: "hidden",
            display: "block",
          },
          "Flex.Icon[0]": props.checked ? {} : { color: "#EFF0F0" },
        }}
      />
    );
  }
}
