/* eslint-disable import/no-anonymous-default-export */
import { Flex, Text } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import { ListSingleLineCPhoto } from "../ui-components";
import { getObject } from "../utils/ShareUtils";

export default function (props) {
  const [img, setImg] = useState(null);
  const { title, content, onClick } = props;

  useEffect(() => {
    if (props.imageSrc) {
      const getUrl = async () => {
        const { fileName, url } = await getObject(props.imageSrc);
        if (isImage(fileName)) {
          setImg(url);
        }
      };
      getUrl();
    }
  }, [props.imageSrc]);

  const isImage = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "png":
      case "jpg":
      case "jpeg":
      case "gif":
      case "bmp":
        return true;
      default:
        return false;
    }
  };
  const getExtension = (filename) => {
    if (filename) {
      var parts = filename.split(".");
      return parts[parts.length - 1];
    } else {
      return "";
    }
  };
  return (
    <ListSingleLineCPhoto
      margin="16px"
      width="auto"
      onClick={onClick}
      className="pointer"
      overrides={{
        List: {
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: "48px"
        },
        date: {
          width: "max-content",
          children: (
            <Flex width="calc(100vw - 100px)" direction="column" gap="0">
              <Text
                className={`text-overflow-ellipsis ${!content && "vertical-elipsis"}`}
                width="100%"
                fontSize="16px"
                color="#1C1A1D"
                fontWeight="400"
                whiteSpace={content && "nowrap"}
                overflow="hidden"
              >
                {title}
              </Text>
              <Text
                className="text-overflow-ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                color="#5C6670"
              >
                {content}
              </Text>
            </Flex>
          ),
        },
        Label: {
          display: "none",
        },
        Image: {
          display: img ? "block" : "none",
          width: "auto",
          src: img,
        },
      }}
    />
  );
}
