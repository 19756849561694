import { DataStore } from "aws-amplify";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  LeavesDaijinishitaikinenbi,
  LeavesKazokuShinzoku,
  LeavesYuujinChijin,
} from "../models";
import {
  ListSelectContent,
  ListSingleLineA,
  ListSingleLineD,
} from "../page-components";
import { getLabel, isNotEmpty, useInitializePage } from "../utils/CommonUtils";
import { isShare, queryDatas } from "../utils/ShareUtils";

function AnniversaryPage() {
  const navigate = useInitializePage("daijinishitaikinenbi");
  const [listAnniversary, setListAnniversary] = useState([]);
  const [selected, setSelected] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [tsuuchisaki, setTsuuchisaki] = useState(null);
  const params = useParams();

  const handledChangeActive = (item) => {
    setSelected(item);
  };

  useEffect(() => {
    if (listAnniversary?.length > 0) {
      setSelected(listAnniversary[0]);
    } else {
      setSelected(null);
    }
  }, [listAnniversary]);

  const deleteData = async (data) => {
    await DataStore.delete(data);
    const newList = await DataStore.query(LeavesDaijinishitaikinenbi);
    setListAnniversary(newList);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const modelData = await queryDatas(params, LeavesDaijinishitaikinenbi);
      if (modelData?.length > 0) setListAnniversary(modelData);
      setIsLoaded(true);
    };
    getData();
  }, [params]);

  useEffect(() => {
    const getData = async () => {
      const listName = [];
      if (selected?.tsuuchisakiKazokuShinzoku?.length > 0) {
        const familys = await queryDatas(params, LeavesKazokuShinzoku);
        const listFamily = familys
          .filter((f) => selected?.tsuuchisakiKazokuShinzoku.indexOf(f.id) >= 0)
          ?.sort((a, b) => {
            if (a?.id > b?.id) return 1;
            if (a?.id < b?.id) return -1;
            return 0;
          })
          .map((f) => f.shimei)
          .join("、");
        isNotEmpty(listFamily) && listName.push(listFamily);
      }
      if (selected?.tsuuchisakiYuujinChijin?.length > 0) {
        const friends = await queryDatas(params, LeavesYuujinChijin);
        const listFriend = friends
          .filter((f) => selected?.tsuuchisakiYuujinChijin.indexOf(f.id) >= 0)
          ?.sort((a, b) => {
            if (a?.id > b?.id) return 1;
            if (a?.id < b?.id) return -1;
            return 0;
          })
          .map((f) => f.shimei)
          .join("、");
        isNotEmpty(listFriend) && listName.push(listFriend);
      }
      setTsuuchisaki(listName?.join("、"));
    };
    getData();
  }, [params, selected]);

  return (
    <ListSelectContent
      label={getLabel("daijinishitaikinenbi")}
      mobileList={listAnniversary.map((ele) => {
        return (
          <ListSingleLineA
            key={ele?.id}
            label={ele?.kinenbimei}
            onClick={() => navigate(ele?.id)}
          />
        );
      })}
      description={{
        type: "data",
        category: "daijinishitaikinenbi",
      }}
      isShared={isShare(params)}
      addButtonLabel={
        "記念日" + getLabel("button.addsuffix")
        /**TODO:  using getLable when 記念日 is defined*/
      }
      onAddClick={() => {
        navigate("add");
        window.gtag("event", "FirstWrite", {
          event_category: "Edit",
          event_label: "大事にしたい日書込開始",
        });
      }}
      pcListItem={listAnniversary.map((ele, index) => {
        return (
          <ListSingleLineD
            key={ele?.id}
            label={ele?.kinenbimei}
            selected={selected?.id === ele?.id}
            onClick={() => {
              handledChangeActive(ele);
            }}
          />
        );
      })}
      selectedItemList={[
        {
          isEmptyHide: true,
          label: getLabel("kinenbimei"),
          value: selected?.kinenbimei,
        },
        {
          isEmptyHide: true,
          label: getLabel("hiduke"),
          value: selected?.hiduke,
          type: "date",
        },
        {
          isEmptyHide: true,
          label: getLabel("konohigachikazuitaratsuuchi"),
          value: isNaN(parseInt(selected?.konohigachikazuitaratsuuchi))
            ? selected?.konohigachikazuitaratsuuchi
            : `${selected?.konohigachikazuitaratsuuchi}${getLabel("hikuma")}`,
        },
        {
          isEmptyHide: true,
          label: getLabel("tsuuchisaki"),
          value: tsuuchisaki,
        },
        {
          isEmptyHide: true,
          label: getLabel("bikou.anniversary"),
          value: selected?.bikou,
        },
      ]}
      selectedItemLabel={selected?.kinenbimei}
      onDelete={() => deleteData(selected)}
      onEdit={() => {
        navigate(selected?.id + "/edit");
        window.gtag("event", "Change", {
          event_category: "Edit",
          event_label: "大事にしたい日変更開始",
        });
      }}
      isLoaded={isLoaded}
    />
  );
}

export default AnniversaryPage;
