/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useContext } from "react";
import { LabelM, LabelS, ListTwolineATextButton, DefaultButton, ListSingleLineCEdit } from "../page-components";
import { isEmpty, useInitializePage, getLabel } from "../utils/CommonUtils";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Dialog } from "../ui-components";
import { Auth, DataStore, Predicates, Storage } from "aws-amplify";
import UserContext from "../UserContext";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
// import * as Models from "../models";
import { LeavesUsers } from "../models";
import { log } from "../utils/Logger";
import { isMobile } from "react-device-detect";
import { Flex, Text } from "@aws-amplify/ui-react";

export default function () {
  const navigate = useInitializePage("accountjouhou");
  const { user } = useContext(UserContext);

  useEffect(async () => {}, []);

  async function deleteAccount() {
    // ファイルをすべて削除
    const images = await Storage.list("", { level: "private" });
    log.debug("delete images", images);
    const strageresponse = await Promise.all(
      images.map((item) => {
        return Storage.remove(item.key, { level: "private" });
      })
    );
    log.debug("delete images response", strageresponse);

    // データをすべて削除（LeavesUsersに関連づいているファイルは削除される）
    const response = await DataStore.delete(LeavesUsers, Predicates.ALL);
    log.debug("delete datastore response", response);

    Auth.deleteUser();
    navigate("/");
  }
  useEffect(async () => {
    // メール送信
    // let apiName = "AdminQueries";
    // let path = "/sendMail";
    // let myInit = {
    //   body: {
    //     from: "no-reply@connected-design.jp",
    //     to: "yuki.terashima@connected-design.jp",
    //     subject: "件名",
    //     body: "本文",
    //   },
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    //   },
    // };
    // try {
    //   const response = await API.post(apiName, path, myInit);
    //   console.log(response.Users);
    // } catch (e) {
    //   console.log(e);
    // }
    // // Cognitoユーザリスト取得
    // let apiName = "AdminQueries";
    // let path = "/listUsers";
    // let myInit = {
    //   queryStringParameters: {},
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    //   },
    // };
    // try {
    //   const response = await API.get(apiName, path, myInit);
    //   console.log(response.Users);
    // } catch (e) {
    //   console.log(e);
    // }
    // // ほかユーザ情報取得
    // const otherUser = await API.graphql({
    //   query: listLeavesUsers,
    //   variables: { filter: { id: { eq: "20e6a33f-fc54-4af2-878a-da1bbffb3dea" } } },
    //   authMode: "AWS_IAM",
    // });
    // console.log("20e6a33f-fc54-4af2-878a-da1bbffb3dea", otherUser);
    // // メール送信
    // const mail = await API.graphql({
    //   query: sendMail,
    //   variables: { to: "yuki.terashima@connected-design.jp", subject: "件名", body: "本文" },
    // });
    // console.log("mail", mail);
  }, []);

  const onOpenDialog = () => {
    confirmAlert({
      overlayClassName: "react-confirm-alert-overlay-custom",
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <Dialog
              width={isMobile ? "295px" : "512px"}
              overrides={{
                "Flex.Flex[0]": {
                  className: "width100",
                },
                "Flex.Flex[0].Text[0]": {
                  children: getLabel("dialog.delete.title"),
                  className: "width100",
                },
                "Flex.Flex[0].Text[1]": {
                  className: "width100",
                  children: getLabel("dialog.deleteaccount.body"),
                  whiteSpace: "break-spaces",
                },
                "Flex.Flex[1].DefaultButton[0]": {
                  className: "pointer",
                  onClick: () => onClose(),
                  overrides: {
                    "Flex.Button[0]": {
                      children: getLabel("dialog.cancel"),
                    },
                  },
                },
                "Flex.Flex[1]":{
                  className: "width100",
                },
                "Flex.Flex[1].PrimaryButton[0]": {
                  className: "pointer",
                  onClick: async () => {
                    deleteAccount();
                    onClose();
                  },
                  overrides: {
                    "Flex.Text[0]": {
                      children: getLabel("dialog.deleteaccount"),
                    },
                  },
                },
              }}
            />
          </div>
        );
      },
    });
  };

  return (
    <div>
      {isMobile ? (
        <>
          <LabelM label={getLabel("accountjouhou")} />
          <LabelS label={getLabel("kihonjouhou")} />
          <ListTwolineATextButton
            label={getLabel("seibetsu")}
            value={getLabel(user?.attributes.gender)}
          />
          <ListTwolineATextButton
            label={getLabel("seinengappi")}
            value={user?.attributes.birthdate}
            type="date"
          />
          <ListTwolineATextButton
            label={getLabel("yuubinbangou")}
            value={user?.attributes.given_name}
          />
          <LabelS label={getLabel("loginid")} />
          <ListTwolineATextButton
            label={getLabel("mailaddress")}
            value={user?.attributes.email}
            onClick={() => navigate("email")}
            buttonLabel={getLabel("update")}
          />
          <ListTwolineATextButton
            label={getLabel("password")}
            value="********"
            onClick={() => navigate("password")}
            buttonLabel={getLabel("update")}
          />
          <DefaultButton
            label={getLabel("アカウント削除")}
            color={"red"}
            onClick={onOpenDialog}
          />
        </>
      ) : (
        <div className="swapper overflow-hidden">
          <LabelM
            label={getLabel("accountjouhou")}
            isDivide
            isDefault
            buttonAdd={{
              onClick: onOpenDialog,
              label: getLabel("アカウント削除"),
              style: { color: "#BF4040", border: "1px solid #BF4040", boxShadow: "none", padding: "6px 18px 6px 15px" },
            }}
          />
          <LabelS label={getLabel("kihonjouhou")} />
          <ListSingleLineCEdit
            padding="0"
            list={[
              {
                label: getLabel("seibetsu"),
                value: getLabel(user?.attributes.gender),
              },
              {
                label: getLabel("seinengappi"),
                value: user?.attributes.birthdate,
                type: "date",
              },
              {
                label: getLabel("yuubinbangou"),
                value: user?.attributes.given_name,
              },
            ]}
            overrides={{
              Flex:{
                padding: "0",
              },
              "Flex.PCPrimaryButtonSave[0]": {
                display: "none",
              },
            }}
          />

          <LabelS label={getLabel("loginid")} />
          <ListSingleLineCEdit
            list={[
              {
                label: getLabel("mailaddress"),
                value: (
                  <Flex gap="24px">
                    <Text>{user?.attributes.email}</Text>
                    <Text
                      className="pointer"
                      whiteSpace="nowrap"
                      color="var(--my-theme-color)"
                      fontWeight="700"
                      onClick={() => navigate("email")}>
                      {getLabel("update")}
                    </Text>
                  </Flex>
                ),
              },
              {
                label: getLabel("password"),
                value: (
                  <Flex gap="24px">
                    <Text>{"********"}</Text>
                    <Text
                      className="pointer"
                      whiteSpace="nowrap"
                      color="var(--my-theme-color)"
                      fontWeight="700"
                      onClick={() => navigate("password")}>
                      {getLabel("update")}
                    </Text>
                  </Flex>
                ),
              },
            ]}
            overrides={{
              Flex:{
                padding: "0",
              },
              "Flex.PCPrimaryButtonSave[0]": {
                display: "none",
              },
            }}
          />
        </div>
      )}
    </div>
  );
}
