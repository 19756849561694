import { isMobile } from "react-device-detect";
import LabelM from "./LabelM";

export default function SwapperContent(props) {
  return (
    <div className={isMobile ? "" : "swapper"}>
      <LabelM label={props.label} />
      <div>{props.children}</div>
    </div>
  );
}
