import React, { useEffect, useState } from "react";
import { ListViewDetail } from "../page-components";
import { DataStore, Storage } from "aws-amplify";
import { useParams } from "react-router-dom";
import { getLabel, useInitializePage } from "../utils/CommonUtils";
import { isShare, listObjects, queryData } from "../utils/ShareUtils";
import { LeavesShuukan } from "../models";
import { PriorityLabel } from "../constants";

export default function HabitViewPage() {
  const navigate = useInitializePage("shuukan", "title.detail");
  const [imageUrl, setImageUrl] = useState("");
  const [data, setData] = useState({
    yaritaikoto: "",
    yuusendo: "",
    episode: "",
    bikou: "",
  });
  const params = useParams();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const dsData = await queryData(params, LeavesShuukan);
      if (dsData.length > 0) {
        setData(dsData[0]);
      }
      setIsLoaded(true)
    };
    getData();
  }, [params]);

  useEffect(() => {
    if (data.id) {
      listObjects(params, LeavesShuukan, data).then((res) => {
        const url = res.length > 0 ? res[0].key : null;
        setImageUrl(url);
      });
    }
  }, [data, params]);

  const deleteData = async () => {
    await DataStore.delete(data);
    await Storage.remove(imageUrl, {
      level: "private",
    });
    navigate(-1, { replace: true });
  };

  const listLabel = [
    { label: "yaritaikoto", value: data?.yaritaikoto, isRequire: true },
    {
      type: "image",
      value: imageUrl,
      isSmall: true,
    },
    { label: "episode.bucketlist", value: data?.episode, isMulti: true },
    {
      label: "yuusendo",
      value: getLabel(PriorityLabel[data?.yuusendo]),
      isRequire: true,
    },
    { label: "bikou", value: data?.bikou, isMulti: true },
  ];

  return (
    <ListViewDetail
      label={data?.yaritaikoto}
      list={isLoaded ? listLabel : []}
      isHideEdit={isShare(params)}
      onDelete={deleteData}
      onEdit={() => {
        navigate("edit");
        window.gtag("event", "Change", {
          event_category: "Edit",
          event_label: "やりたいこと変更開始",
        });
      }}
    />
  );
}
