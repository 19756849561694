/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Icon, Text, View, Image } from "@aws-amplify/ui-react";
export default function AskDialog(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
        width="375px"
        height="812px"
        overflow="hidden"
        position="relative"
    >
        <Flex
            width="310px"
            height="325px"
            position="absolute"
            top="403px"
            left="calc(50% - 160px - 0.5px)"
        >
            <Flex
                gap="0"
                direction="column"
                width="331px"
                height="373px"
                justifyContent="flex-start"
                alignItems="center"
                position="absolute"
                top="-25.92%"
                bottom="-19.69%"
                left="calc(50% - 165.5px - 0.5px)"
                border="2px SOLID rgba(104,134,73,1)"
                boxShadow="0px 6px 12px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.1599999964237213)"
                borderRadius="4px"
                backgroundColor="rgba(255,255,255,1)"
            >
                <Flex
                    gap="14px"
                    direction="column"
                    width="295px"
                    height="99px"
                    justifyContent="flex-start"
                    alignItems="center"
                    shrink="0"
                    position="relative"
                    padding="28px 23px 222px 23px"
                >
                    <View
                        width="241px"
                        height="57px"
                        display="block"
                        shrink="0"
                        position="relative"
                        borderRadius="100px"
                        backgroundColor="rgba(104,134,73,1)"
                    />
                    <View
                        width="242px"
                        height="20px"
                        display="block"
                        shrink="0"
                        position="relative"
                        backgroundColor="rgba(255,255,255,1)"
                    />
                    <Flex
                        width="200px"
                        height="25.91px"
                        shrink="0"
                        position="relative"
                    >
                        <View
                            width="247px"
                            height="32px"
                            display="block"
                            position="absolute"
                            top="-6px"
                            left="-23.5px"
                            border="1px SOLID rgba(104,134,73,1)"
                            borderRadius="17px"
                        />
                        <Text
                            fontFamily="Inter"
                            fontSize="16px"
                            fontWeight="700"
                            color="rgba(0,0,0,1)"
                            lineHeight="18px"
                            textAlign="left"
                            display="block"
                            width="175px"
                            height="32px"
                            position="absolute"
                            top="0px"
                            left="12.5px"
                            whiteSpace="pre-wrap"
                        >
                            これからの家族のこと
                        </Text>
                    </Flex>
                    <Flex
                        width="247px"
                        height="32px"
                        shrink="0"
                        position="relative"
                    >
                        <View
                            width="247px"
                            height="32px"
                            display="block"
                            position="absolute"
                            top="0.09px"
                            left="0px"
                            border="1px SOLID rgba(104,134,73,1)"
                            borderRadius="17px"
                            backgroundColor="rgba(217,217,217,0.07)"
                        />
                        <Text
                            fontFamily="Inter"
                            fontSize="16px"
                            fontWeight="700"
                            color="rgba(0,0,0,1)"
                            lineHeight="30px"
                            textAlign="left"
                            display="block"
                            width="186px"
                            height="32px"
                            position="absolute"
                            top="0px"
                            left="36px"
                            whiteSpace="pre-wrap"
                        >
                            これからの住まいのこと
                        </Text>
                    </Flex>
                    <Flex
                        width="247px"
                        height="32px"
                        shrink="0"
                        position="relative"
                    >
                        <View
                            width="247px"
                            height="32px"
                            display="block"
                            position="absolute"
                            top="0px"
                            left="1px"
                            border="1px SOLID rgba(104,134,73,1)"
                            borderRadius="17px"
                            backgroundColor="rgba(217,217,217,0.07)"
                        />
                        <Text
                            fontFamily="Inter"
                            fontSize="16px"
                            fontWeight="700"
                            color="rgba(0,0,0,1)"
                            lineHeight="30px"
                            textAlign="left"
                            display="block"
                            width="185px"
                            height="32px"
                            position="absolute"
                            top="0px"
                            left="36px"
                            whiteSpace="pre-wrap"
                        >
                            これからの暮らしのこと
                        </Text>
                    </Flex>
                    <Flex
                        width="247px"
                        height="32px"
                        shrink="0"
                        position="relative"
                    >
                        <View
                            width="247px"
                            height="32px"
                            display="block"
                            position="absolute"
                            top="0px"
                            left="1px"
                            border="1px SOLID rgba(104,134,73,1)"
                            borderRadius="17px"
                            backgroundColor="rgba(217,217,217,0.07)"
                        />
                        <Text
                            fontFamily="Inter"
                            fontSize="16px"
                            fontWeight="700"
                            color="rgba(0,0,0,1)"
                            lineHeight="30px"
                            textAlign="left"
                            display="block"
                            width="173px"
                            height="32px"
                            position="absolute"
                            top="0px"
                            left="37px"
                            whiteSpace="pre-wrap"
                        >
                            これからの資産のこと
                        </Text>
                    </Flex>
                    <Flex
                        width="247px"
                        height="32px"
                        shrink="0"
                        position="relative"
                    >
                        <View
                            width="247px"
                            height="32px"
                            display="block"
                            position="absolute"
                            top="0px"
                            left="1px"
                            border="1px SOLID rgba(104,134,73,1)"
                            borderRadius="17px"
                            backgroundColor="rgba(217,217,217,0.07)"
                        />
                        <Text
                            fontFamily="Inter"
                            fontSize="16px"
                            fontWeight="700"
                            color="rgba(0,0,0,1)"
                            lineHeight="30px"
                            textAlign="left"
                            display="block"
                            width="173px"
                            height="32px"
                            position="absolute"
                            top="0px"
                            left="37px"
                            whiteSpace="pre-wrap"
                        >
                            家族イベントのこと
                        </Text>
                    </Flex>
                    <Text
                        fontFamily="Inter"
                        fontSize="12px"
                        fontWeight="700"
                        color="rgba(255,255,255,1)"
                        lineHeight="24px"
                        textAlign="center"
                        display="block"
                        shrink="0"
                        position="absolute"
                        top="37px"
                        left="205px"
                        whiteSpace="pre-wrap"
                        {...rest}
                        {...getOverrideProps(overrides, "TelButton")}
                    >
                        （無料）
                    </Text>
                    <Text
                        fontFamily="Inter"
                        fontSize="20px"
                        fontWeight="700"
                        color="rgba(255,255,255,1)"
                        lineHeight="24px"
                        textAlign="left"
                        display="block"
                        letterSpacing="0.65px"
                        width="138px"
                        shrink="0"
                        position="absolute"
                        top="35px"
                        left="89px"
                        whiteSpace="pre-wrap"
                        {...rest}
                        {...getOverrideProps(overrides, "TelButton")}
                    >
                        相談してみる
                    </Text>
                    <Text
                        fontFamily="Zen Kaku Gothic New"
                        fontSize="12px"
                        fontWeight="700"
                        color="rgba(255,255,255,1)"
                        lineHeight="20px"
                        textAlign="left"
                        display="block"
                        width="183px"
                        height="27px"
                        shrink="0"
                        position="absolute"
                        top="57px"
                        left="90px"
                        whiteSpace="pre-wrap"
                        {...rest}
                        {...getOverrideProps(overrides, "TelButton")}
                    >
                        話すだけで心が軽くなる?!
                    </Text>
                    <Image
                        width="35px"
                        height="34px"
                        display="block"
                        shrink="0"
                        position="relative"
                        objectFit="cover"
                        alt=""
                        position="absolute"
                        top="38px"  
                        left="45px"
                        {...rest}
                        {...getOverrideProps(overrides, "TelIcon")}
                    />
                </Flex>
                <Text
                    fontFamily="Inter"
                    fontSize="12px"
                    fontWeight="400"
                    color="rgba(28,26,29,1)"
                    lineHeight="17px"
                    textAlign="center"
                    display="block"
                    width="249px"
                    shrink="0"
                    position="absolute"
                    top="90px"
                    whiteSpace="pre-wrap"
                >
                    平日10〜18時
                </Text>
                <Text
                    fontFamily="Inter"
                    fontSize="12px"
                    fontWeight="300"
                    color="rgba(0,0,0,1)"
                    lineHeight="24px"
                    textAlign="center"
                    display="block"
                    width="295px"
                    shrink="0"
                    position="absolute"
                    top="100px"
                    left="20px"
                    margin-left="15px"
                    margin-top="3px"
                    whiteSpace="pre-wrap"
                >
                    提供：東急ラヴィエール（株）
                </Text>
                <Image
                    width="33px"
                    height="100px"
                    display="block"
                    shrink="0"
                    position="absolute"
                    bottom="3px"
                    right="4px"
                    objectFit="cover"
                    alt=""
                    {...getOverrideProps(overrides, "ButlerIcon")}
                />
            </Flex>
        </Flex>
      <View
        padding="0px 0px 0px 0px"
        width="32px"
        height="32px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        bottom="58.7%"
        left="88%"
        right="0%"
        {...getOverrideProps(overrides, "CloseButton")}
      >
        <Icon
          width="32px"
          height="32px"
          viewBox={{ minX: 0, minY: 0, width: 32, height: 32 }}
          paths={[
            {
              d: "M30 16C30 23.732 23.732 30 16 30L16 34C25.9411 34 34 25.9411 34 16L30 16ZM16 30C8.26801 30 2 23.732 2 16L-2 16C-2 25.9411 6.05887 34 16 34L16 30ZM2 16C2 8.26801 8.26801 2 16 2L16 -2C6.05887 -2 -2 6.05887 -2 16L2 16ZM16 2C23.732 2 30 8.26801 30 16L34 16C34 6.05887 25.9411 -2 16 -2L16 2Z",
              stroke: "rgba(174,179,183,1)",
              fillRule: "nonzero",
              strokeWidth: 2,
            },
            {
              d: "M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z",
              fill: "rgba(255,255,255,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0%"
          bottom="0%"
          left="0%"
          right="0%"
          {...getOverrideProps(overrides, "Ellipse")}
        ></Icon>
        <Icon
          width="24px"
          height="24px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          position="absolute"
          top="12.5%"
          bottom="12.5%"
          left="12.5%"
          right="12.5%"
          padding="0px 0px 0px 0px"
          type="close"
          fontSize="24px"
          {...getOverrideProps(overrides, "Icon")}
        ></Icon>
      </View>

    </Flex>
  );
}
