import { Flex } from "@aws-amplify/ui-react";
import { FABEdit, FABShare } from ".";
import { isEnableVideoCall } from "../utils/CommonUtils";

export default function FABEditShare(props) {
  const { onShare, onEdit } = props;

  return (
    <Flex
      display="block"
      position="fixed"
      bottom={isEnableVideoCall ? "160px" : "86px"}
      right="16px"
      zIndex="1"
    >
      <FABShare marginBottom="16px" onClick={onShare} />
      <FABEdit onClick={onEdit} />
    </Flex>
  );
}
