import { DataStore } from "aws-amplify";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { LeavesKenkoukanri } from "../models";
import { ListField } from "../page-components";
import { getLabel, isEmpty, useInitializePage } from "../utils/CommonUtils";
import { usePrompt } from "../utils/Prompt";

function HealthEditPage() {
  const navigate = useInitializePage("kenkoukanrinitsuite", "title.update");
  const data = useRef();
  const [healthData, setHealthData] = useState({
    byoumei: "",
    hukuyouchuunokusuri: "",
    youto: "",
    youhou: "",
    byouinmei: "",
    chiryoukikan: "",
    bikou: "",
  });
  const [healthDataError, setHealthDataError] = useState({
    byoumeiError: "",
    hukuyouchuunokusuriError: "",
    youtoError: "",
    youhouError: "",
    byouinmeiError: "",
    chiryoukikanError: "",
    bikouError: "",
  });
  const [isUpdated, setIsUpdated] = useState(false);
  const params = useParams();
  const [update, setUpdate] = useState(false);
  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const dataQuery = await DataStore.query(LeavesKenkoukanri, (c) =>
        c.id("eq", params.id),
      );
      if (dataQuery?.length > 0) {
        data.current = dataQuery[0];
        setHealthData({
          byoumei: dataQuery[0].byoumei,
          hukuyouchuunokusuri: dataQuery[0].hukuyouchuunokusuri,
          youto: dataQuery[0].youto,
          youhou: dataQuery[0].youhou,
          byouinmei: dataQuery[0].byouinmei,
          chiryoukikan: dataQuery[0].chiryoukikan,
          bikou: dataQuery[0].bikou,
        });
      }
    };
    getData();
  }, [params.id]);

  const listField = [
    {
      type: "textField",
      label: "byoumei",
      value: healthData?.byoumei,
      isRequire: true,
      placeHolder: "tounyoubyounado",
      errorMessage: healthDataError.byoumeiError,
    },
    {
      type: "textField",
      label: "hukuyouchuunokusuri",
      value: healthData?.hukuyouchuunokusuri,
    },
    {
      type: "textField",
      label: "youto",
      value: healthData?.youto,
      placeHolder: "itamidomenado",
    },
    {
      type: "textField",
      label: "youhou",
      value: healthData?.youhou,
      placeHolder: "yoruikkainado",
    },
    { type: "textField", label: "byouinmei", value: healthData?.byouinmei },
    {
      type: "textField",
      label: "chiryoukikan",
      value: healthData?.chiryoukikan,
      placeHolder: "2020nenkarachiryounado",
    },
    { type: "textArea", label: "bikou", value: healthData?.bikou },
  ];

  const updateDataHealth = useCallback(async () => {
    let valid = true;
    if (isEmpty(healthData.byoumei)) {
      setHealthDataError({
        ...healthDataError,
        byoumeiError: getLabel("error.required"),
      });
      valid = false;
    }
    if (valid) {
      setIsUpdated(true);
      await DataStore.save(
        LeavesKenkoukanri.copyOf(data.current, (updated) => {
          updated.byoumei = healthData.byoumei;
          updated.hukuyouchuunokusuri = healthData.hukuyouchuunokusuri;
          updated.youto = healthData.youto;
          updated.youhou = healthData.youhou;
          updated.byouinmei = healthData.byouinmei;
          updated.chiryoukikan = healthData.chiryoukikan;
          updated.bikou = healthData.bikou;
        }),
      );
      window.gtag("event", "ChangeComplete", {
        event_category: "Edit",
        event_label: "健康管理について（常備薬・通院記録）変更完了",
      });
      navigate(-1, { replace: true });
    }
  }, [
    data,
    healthData.bikou,
    healthData.byouinmei,
    healthData.byoumei,
    healthData.chiryoukikan,
    healthData.hukuyouchuunokusuri,
    healthData.youhou,
    healthData.youto,
    healthDataError,
    navigate,
  ]);

  return (
    <ListField
      label={getLabel("button.update")}
      list={listField}
      setState={setHealthData}
      setUpdate={setUpdate}
      onSave={updateDataHealth}
      onCancel={() => navigate(-1, { replace: true })}
      isDisabled={isUpdated}
    />
  );
}

export default HealthEditPage;
