/* eslint-disable react-hooks/exhaustive-deps */
import { API, Auth, DataStore } from "aws-amplify";
import { Flex, Text } from "@aws-amplify/ui-react";
import React ,{ useContext, useEffect, useState, useCallback } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { isMobile } from "react-device-detect";
import { useSearchParams } from "react-router-dom";
import { getLeavesShareInfo, listLeavesShareInfos, listLeavesSharedInfos } from "../graphql/queries";
import { LeavesSharedInfo } from "../models";
import { CategorySwapper, Description, LabelM, ListSingleLineA, ListSingleLineTwoColum, DefaultButton } from "../page-components";
import UserContext from "../UserContext";
import { getLabel, useInitializePage } from "../utils/CommonUtils";
import { listGraphqlQuery, getGraphqlQuery, getServerDate } from "../utils/ShareUtils";
import { log } from "../utils/Logger";

import Hashids from "hashids";
import { LeavesShareInfo } from "../models";
const hashids = new Hashids("hiraql");

let mount = true;

function InvitedPage() {
  const navigate = useInitializePage("invited"); /**TODO: */
  const [searchParams, setSearchParams] = useSearchParams();
  const context = useContext(UserContext);

  const [listItem, setListItem] = useState([]);

  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const process = async () => {
      const encodedShareInfoId = searchParams.get("id");
      if (encodedShareInfoId != null) {
        const shareInfoId = hashids.decodeHex(encodedShareInfoId);
        log.debug("shareInfoUrlId", shareInfoId);
        // urlidからshareinfoを取得
        // 取得出来なかった場合は処理終了で現在のsharedInfoの一覧を表示
        // 取得できた場合
        // 　ownerが自分と同じである場合は該当のshareInfoページへ遷移
        // 　sharedInfoテーブルにshareInfo.idのレコードが存在しないかチェック
        // 　　存在する場合は処理終了で現在のsharedInfoの一覧を表示
        // 　　存在しない場合
        // 　　　sharedInfoテーブルでデータ追加
        const shareInfoQuery = await listGraphqlQuery("listLeavesShareInfos", {
          shareId: { eq: shareInfoId },
        });
        log.debug("shareInfo", shareInfoQuery);
        if (shareInfoQuery.length > 0) {
          const now = await getServerDate();

          const shareInfos = shareInfoQuery.filter(
            (i) =>
              new Date(
                i.shareIdExpiredDate.replaceAll("-", "/") + " 23:59:59"
              ) >= now
          );
          if (shareInfos.length > 0) {
            const shareInfo = shareInfos[0];
            // 自分の招待の場合はshareinfoへ遷移
            if (shareInfo.owner === Auth.user.username) {
              log.debug("self url");
              navigate("/other/invite", { state: { id: shareInfo.id } });
              mount = true;
              return;
            }
            // TODO: 他のシェア済みレコードと同じownerの招待だった場合はスキップが良いか検討

            const sharedInfoQuery = await listGraphqlQuery(
              "listLeavesSharedInfos",
              { shareInfoId: { eq: shareInfo.id } }
            );
            log.debug("sharedInfoQuery", sharedInfoQuery);

            if (sharedInfoQuery.length === 0) {
              const data = await DataStore.save(
                new LeavesSharedInfo({
                  shareInfoId: shareInfo.id,
                  leavesusersID: context.leavesUser.id,
                })
              );
              navigate(data.id + "/detail");
              mount = true;
              return;
            } else {
              if (sharedInfoQuery[0].owner === Auth.user.username) {
                navigate(sharedInfoQuery[0].id);
                mount = true;
                return;
              } else {
                setErrorMessage(getLabel("error.share.other"));
                // searchParams.delete("id");
                // setSearchParams(searchParams);
                // return;
              }
            }
          } else {
            setErrorMessage(getLabel("error.share.expired"));
            // searchParams.delete("id");
            // setSearchParams(searchParams);
            // return;
          }
        } else {
          setErrorMessage(getLabel("error.share.invalid"));
          // searchParams.delete("id");
          // setSearchParams(searchParams);
          // return;
        }
      }
      const sharedInfos = await DataStore.query(LeavesSharedInfo);
      sharedInfos.forEach(async (element) => {
        const infoData = await getGraphqlQuery(
          "getLeavesShareInfo",
          element.shareInfoId
        );
        if (infoData == null) {
          const data = await DataStore.query(LeavesSharedInfo, (c) =>
            c.id("eq", element.id)
          );
          await DataStore.delete(data[0]);
        }
      });
      setListItem(
        sharedInfos.map((s) => {
          return {
            id: s.id,
            label: s.name,
          };
        })
      );
      mount = true;
    };
    if (mount) {
      mount = false;
      process();
    }
  }, []);

  const Dialog = useCallback(
    (props) => {
      return (
        <div className="react-confirm-alert">
          <div className="react-confirm-alert-overlay react-confirm-alert-overlay-custom">
            <div className="custom-ui">
              <Flex
                width="300px"
                display={errorMessage != null ? "block" : "none"}
                boxShadow="0px 15px 12px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.25)"
                padding="8px 23px"
                direction="column"
                backgroundColor="rgba(255,255,255,1)"
              >
                <Text fontWeight="700" fontSize="16px" children={getLabel("error.regist")} marginBottom="8px" marginTop="16px" />
                <Text fontSize="16px" children={errorMessage} marginBottom="8px" marginTop="16px" />
                <DefaultButton
                  label={getLabel("dialog.close")}
                  onClick={() => {
                    searchParams.delete("id");
                    setSearchParams(searchParams);
                    setErrorMessage(null);
                  }}
                />
              </Flex>
            </div>
          </div>
        </div>
      );
    },
    [errorMessage]
  );
  return (
    <div className={isMobile ? "" : "swapper"}>
      <LabelM label={getLabel("kyoyusaretanotoichiran")} isDivide={!isMobile} />
      {listItem?.length === 0 && <Description text={getLabel("invited.des")} />}
      {isMobile ? (
        <>
          {listItem.map((ele) => {
            return (
              <ListSingleLineA
                key={ele.id}
                label={ele.label ? ele.label : getLabel("notset")}
                onClick={() => {
                  navigate(ele.id);
                }}
              />
            );
          })}
        </>
      ) : (
        <CategorySwapper>
          {listItem.map((ele) => {
            return (
              <ListSingleLineTwoColum
                key={ele.id}
                label={ele.label ? ele.label : getLabel("notset")}
                onClick={() => {
                  navigate(ele.id);
                }}
              />
            );
          })}
        </CategorySwapper>
      )}
      {errorMessage != null ? <Dialog /> : null}
    </div>
  );
}

export default React.memo(InvitedPage)