import { DataStore, Storage } from "aws-amplify";
import { useEffect, useRef, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Priority, PriorityLabel } from "../constants";
import { LeavesShuukan } from "../models";
import {
  BucketListGuideDialog,
  ListSelectContent,
  StrageImage,
  TodoListA,
} from "../page-components";
import { getLabel, isImage, useInitializePage } from "../utils/CommonUtils";
import { log } from "../utils/Logger";
import Cookies from "js-cookie";
import {
  getObject,
  isShare,
  listObjects,
  queryDatas,
} from "../utils/ShareUtils";
import { LeavesLifeFuture } from "../models";

export default function HabitPage() {
  const navigate = useInitializePage("shuukan");

  const [isLoaded, setIsLoaded] = useState(false);
  const [list, setList] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [selected, setSelected] = useState(null);
  const [isShow, setIsShow] = useState(false);
  const firstItemAdded = useRef(false);
  const params = useParams();

  const handledChangeActive = (item) => {
    setSelected(item);
  };

  const sortedList = useMemo(() => {
    const highList = list
      .filter((ele) => ele.yuusendo === Priority.KOU)
      ?.sort((a, b) => (a.createdAt > b.createdAt || !a.createdAt ? 1 : -1));
    const duringList = list
      .filter((ele) => ele.yuusendo === Priority.CHUU)
      ?.sort((a, b) => (a.createdAt > b.createdAt || !a.createdAt ? 1 : -1));
    const lowList = list
      .filter((ele) => ele.yuusendo === Priority.TEI)
      ?.sort((a, b) => (a.createdAt > b.createdAt || !a.createdAt ? 1 : -1));

    return highList.concat(duringList.concat(lowList));
  }, [list]);

  useEffect(() => {
    if (sortedList?.length > 0) {
      setSelected(sortedList[0]);
    } else {
      setSelected(null);
    }
  }, [sortedList]);

  useEffect(() => {
    let mount = true;
    if (list?.length > 0) {
      const images = {};
      const getList = async () => {
        const listPromise = [];
        for (let i = 0; i < list.length; i++) {
          listPromise.push(
            new Promise((resolve) => {
              listObjects(params, LeavesShuukan, list[i]).then((res) => {
                if (res.length > 0) {
                  resolve({
                    id: list[i].id,
                    key: res[0].key,
                  });
                } else {
                  resolve({
                    id: list[i].id,
                    key: null,
                  });
                }
              });
            })
          );
        }
        const result = await Promise.all(listPromise);
        result.forEach((ele) => {
          images[ele.id] = ele.key;
        });
        if (mount) setImageList(images);
      };
      getList();
    }
    return () => (mount = false);
  }, [list, params]);

  useEffect(() => {
    const getData = async () => {
      const data = await queryDatas(params, LeavesShuukan);
      const isShowed =  Cookies.get("isShowedBucketListGuide") === "true"
      if (!isShowed) {
        const dataChallenge = await queryDatas(params, LeavesLifeFuture);
        setIsShow(data?.length === 0 && dataChallenge?.length === 0)
      }
      setList(data);
      setIsLoaded(true);
    };
    getData();
  }, [params]);

  const deleteData = async (data) => {
    firstItemAdded.current = false;
    await DataStore.delete(data);
    await Storage.remove(imageList[data.id], {
      level: "private",
    });
    const newList = await DataStore.query(LeavesShuukan);
    setList(newList);
  };

  return (
    <>
      <ListSelectContent
        label={getLabel("yaritaikoto")}
        isHideLabel
        isMemoBtn
        mobileList={sortedList.map((ele) => {
          return (
            <TodoListA
              key={ele.id}
              label={ele.yaritaikoto}
              imageSrc={imageList[ele?.id]}
              onClick={() => navigate(ele?.id)}
            />
          );
        })}
        description={{
          type: "will",
          category: "yaritaikoto",
        }}
        isShared={isShare(params)}
        addButtonLabel={getLabel("yaritaikoto") + getLabel("button.addsuffix")}
        onAddClick={() => {
          navigate("add", { state: "habit" });
          window.gtag("event", "FirstWrite", {
            event_category: "Edit",
            event_label: "やりたいこと書込開始",
          });
        }}
        style={{ marginTop: "16px", maxHeight: "calc(100vh - 435px)" }}
        pcListItem={sortedList.map((ele, index) => {
          return (
            <TodoListA
              key={ele.id}
              label={ele.yaritaikoto}
              selected={selected?.id === ele?.id}
              imageSrc={imageList[ele?.id]}
              onClick={() => handledChangeActive(ele)}
            />
          );
        })}
        selectedItemList={[
          {
            label: getLabel("yaritaikoto"),
            value: selected?.yaritaikoto,
          },
          {
            label: getLabel("imagegazou"),
            value: imageList[selected?.id] && (
              <StrageImage key={selected?.id} src={imageList[selected?.id]} />
            ),
          },
          {
            isEmptyHide: true,
            label: getLabel("episode.bucketlist"),
            value: selected?.episode,
          },
          {
            isEmptyHide: true,
            label: getLabel("yuusendo"),
            value: getLabel(PriorityLabel[selected?.yuusendo]),
          },
          {
            isEmptyHide: true,
            label: getLabel("bikou"),
            value: selected?.bikou,
          },
        ]}
        selectedItemLabel={selected?.yaritaikoto}
        onDelete={() => deleteData(selected)}
        onEdit={() => {
          navigate(selected?.id + "/edit");
          window.gtag("event", "Change", {
            event_category: "Edit",
            event_label: "やりたいこと変更開始",
          });
        }}
        isLoaded={isLoaded}
        desUrlShare="/will/bucketlist/share"
      />
      {isShow && !isShare(params) && <BucketListGuideDialog />}
    </>
  );
}
