/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import {
  Flex,
  Icon,
  IconMoreVert,
  Image,
  Text,
  View,
} from "@aws-amplify/ui-react";
export default function Colum(props) {
  const { overrides: overridesProp, ...rest } = props;
  const overrides = { ...overridesProp };
  return (
    <Flex
      gap="0"
      direction="column"
      width="375px"
      justifyContent="center"
      position="relative"
      padding="0px 16px 8px 16px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "Flex")}
    >
      <Flex
        gap="8px"
        direction="column"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Flex.Flex[0]")}
      >
        <View
          width="343px"
          height="1px"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(239.00000095367432,240.00000089406967,240.00000089406967,1)"
          {...getOverrideProps(overrides, "Flex.Flex[0].View[0]")}
        ></View>
        <Flex
          gap="8px"
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Flex.Flex[0].Flex[0]")}
        >
          <Flex
            gap="16px"
            direction="row"
            width="303px"
            alignItems="flex-start"
            grow="1"
            basis="303px"
            height="56px"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Flex.Flex[0].Flex[0].Flex[0]")}
          >
            <Image
              width="100px"
              height="56px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(
                overrides,
                "Flex.Flex[0].Flex[0].Flex[0].Image[0]"
              )}
            ></Image>
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(13.000000175088644,26.000000350177288,38.0000015348196,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="187px"
              grow="1"
              basis="187px"
              position="relative"
              padding="0px 0px 0px 0px"
              children="Colum title"
              {...getOverrideProps(
                overrides,
                "Flex.Flex[0].Flex[0].Flex[0].Text[0]"
              )}
            ></Text>
          </Flex>
          <Flex
            padding="0px 0px 0px 0px"
            width="32px"
            height="32px"
            shrink="0"
            position="relative"
            {...getOverrideProps(overrides, "Flex.Flex[0].Flex[0].Flex[1]")}
          >
            <Icon
              width="32px"
              height="32px"
              pathData="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z"
              viewBox={{ minX: 0, minY: 0, width: 32, height: 32 }}
              color="rgba(239.00000095367432,240.00000089406967,240.00000089406967,1)"
              position="absolute"
              top="0px"
              left="0px"
              {...getOverrideProps(
                overrides,
                "Flex.Flex[0].Flex[0].Flex[1].Icon[0]"
              )}
            ></Icon>
            <IconMoreVert
              width="24px"
              height="24px"
              position="absolute"
              top="4px"
              left="4px"
              overflow="hidden"
              padding="0px 0px 0px 0px"
              color="rgba(13.000000175088644,26.000000350177288,38.0000015348196,1)"
              type="more_vert"
              fontSize="24px"
              {...getOverrideProps(
                overrides,
                "Flex.Flex[0].Flex[0].Flex[1].IconMoreVert[0]"
              )}
            ></IconMoreVert>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
