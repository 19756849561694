import { DataStore, Storage } from "aws-amplify";
import React, { useCallback, useContext, useState } from "react";
import { LeavesMemo } from "../models";
import { ListField } from "../page-components";
import UserContext from "../UserContext";
import {
  getLabel,
  isEmpty,
  isNotEmpty,
  useInitializePage,
} from "../utils/CommonUtils";
import { usePrompt } from "../utils/Prompt";

export default function MemoAddPage() {
  const navigate = useInitializePage("jiyucho", "title.add");
  const context = useContext(UserContext);
  const [memoData, setMemoData] = useState({
    title: "",
    memoCategory: "",
    tenpufile: "",
  });
  const [memoDataError, setMemoDataError] = useState({
    title: "",
    tenpufile: "",
  });
  const [isAdded, setIsAdded] = useState(false);
  const listField = [
    {
      type: "textField",
      label: "title",
      isRequire: true,
      hasError: isNotEmpty(memoDataError.title),
      errorMessage: memoDataError.title,
    },
    {
      type: "textArea",
      label: "memoCategory",
    },
    {
      type: "file",
      label: "tenpufile",
      isImage: true,
    },
  ];
  const [update, setUpdate] = useState(false);
  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  const addData = useCallback(async () => {
    let valid = true;
    if (isEmpty(memoData.title)) {
      setMemoDataError({
        ...memoDataError,
        title: isEmpty(memoData.title) ? getLabel("error.required") : "",
      });
      valid = false;
    }
    if (valid) {
      setIsAdded(true);
      const saved = await DataStore.save(
        new LeavesMemo({
          title: memoData?.title,
          memo: memoData?.memoCategory,
          leavesusersID: context.leavesUser.id,
        })
      );
      if (memoData?.tenpufile) {
        await Storage.put(
          `LeavesMemo/${saved?.id}/${memoData?.tenpufile?.name}`,
          memoData?.tenpufile,
          {
            contentType: memoData?.tenpufile.type,
            level: "private",
          }
        );
      }
      navigate(-1, { replace: true });
    }
  }, [
    context.leavesUser.id,
    memoData?.memoCategory,
    memoData?.tenpufile,
    memoData.title,
    memoDataError,
    navigate,
  ]);

  return (
    <ListField
      label={getLabel("shinkitsuika")}
      list={listField}
      setState={setMemoData}
      setUpdate={setUpdate}
      onSave={addData}
      onCancel={() => navigate(-1, { replace: true })}
      isDisabled={isAdded}
    />
  );
}
