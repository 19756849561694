/* eslint-disable import/no-anonymous-default-export */
import { Divider } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import React, { useState } from "react";
import { LabelM, PasswordField, PrimaryButton, Text, TextField } from "../page-components";
import { getLabel, isEmpty, useInitializePage } from "../utils/CommonUtils";
import { log } from "../utils/Logger";

export default function () {
  const navigate = useInitializePage("mailaddresswohenkou");

  // メールアドレス変更
  const [email, setEmail] = React.useState("");
  const [emailConfirm, setEmailConfirm] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [newPasswordError, setNewPasswordError] = useState(null);

  const [emailError, setEmailError] = useState(null);
  const [emailConfirmError, setEmailConfirmError] = useState(null);

  // 二重送信防止用コード
  const [processing, setProcessing] = useState(false);

  const changeEmail = async () => {
    // 二重送信防止用コード
    if (processing) return;
    setProcessing(true);

    log.debug("change email " + Auth.user.attributes.email + " -> " + email);
    let valid = true;
    if (isEmpty(email)) {
      setEmailError(getLabel("error.required"));
      valid = false;
    } else {
      const regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!regex.test(email)) {
        setEmailError(getLabel("error.emailformat"));
        valid = false;
      } else {
        setEmailError(null);
      }
    }
    if (email !== emailConfirm) {
      setEmailConfirmError(getLabel("error.differentemail"));
      valid = false;
    } else {
      setEmailConfirmError(null);
    }
    if (isEmpty(newPassword)) {
      setNewPasswordError(getLabel("error.required"));
      valid = false;
    } else {
      setNewPasswordError(null);
    }

    if (valid) {
      try {
        await Auth.signIn(Auth.user.username, newPassword);
        setNewPasswordError(null);
      } catch (e) {
        console.log(e);
        log.debug("change email passworderror", e);
        setNewPasswordError(getLabel("error.password." + e.name));
        // 二重送信防止用コード
        setProcessing(false);
        return;
      }
      try {
        await Auth.signIn(email, "p");
      } catch (e) {
        log.debug("change email emailerror", e);
        if (e.name != "UserNotFoundException") {
          setEmailError(getLabel("An account with the given email already exists."));
          // 二重送信防止用コード
          setProcessing(false);
          return;
        }
      }

      const user = await Auth.currentAuthenticatedUser();
      const curEmail = Auth.user.attributes.email;
      try {
        const response = await Auth.updateUserAttributes(user, { email: email });
        log.debug("changeEmail", response);
        navigate("verify", { state: { oldEmail: curEmail, newEmail: email } });
      } catch (e) {
        log.debug("changeEmail error", e);
        setEmailError(getLabel("error.email." + e.name));
      }
    } else {
      // 二重送信防止用コード
      setProcessing(false);
    }
  };

  return (
    <div>
      <LabelM label={getLabel("mailaddresswohenkou")} />
      <Text margin="16px auto 16px auto" value={getLabel("mailaddress update description")} />
      <PasswordField
        isRequired
        errorMessage={newPasswordError}
        autoComplete="current-password"
        name="current-password"
        label={getLabel("genzainopassword")}
        onChange={(event) => {
          setNewPassword(event.target.value);
        }}
      />
      <Divider margin="0px auto 8px auto" shrink="0" alignSelf="stretch" objectFit="cover" size="small" orientation="horizontal" />

      <TextField
        autoComplete="username"
        name="username"
        isRequired
        type="email"
        errorMessage={emailError}
        label={getLabel("atarashiimailaddress")}
        onChange={(event) => {
          setEmail(event.target.value);
        }}
      />
      <TextField
        isRequired
        type="email"
        errorMessage={emailConfirmError}
        label={getLabel("atarashiimailaddresssainyuuryoku")}
        onChange={(event) => {
          setEmailConfirm(event.target.value);
        }}
      />
      <PrimaryButton label={getLabel("ninshoukodowosoushin")} onClick={() => changeEmail()} isLoading={processing} />
    </div>
  );
}
