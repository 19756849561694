import { Flex, View, Image, Text } from '@aws-amplify/ui-react';
import { isMobile, isTablet } from 'react-device-detect';
import {
    LabelM,
    Description,
    PrimaryButton
} from "../page-components";
import { useNavigate } from 'react-router-dom';
import mobileIcon from '../assets/image/data_cardMIND.png';
import awsExports from '../aws-exports';
import Amplify, { Auth, API } from 'aws-amplify';
import { createLeavesMindTypeAnswerId } from '../graphql/mutations';
import Cookies from 'js-cookie';

export default function MindTypePage() {
  const navigate = useNavigate();

  Amplify.configure(awsExports);

  const checkStart = async() => {
    const user = await Auth.currentAuthenticatedUser();

    const result = await API.graphql({
        query: createLeavesMindTypeAnswerId,
            variables: {
                input: {
                    owner: user.username
                }
            }
        }
    );
    
    Cookies.set('mindtypeAnswerId', result.data.createLeavesMindTypeAnswerId.id, { expires: 30 });

    window.gtag("event", "MindType", {
      event_category: "Share",
      event_label: "幸福度診断開始",
    });

    navigate('/mindtype/check/1');
  };

  if ( isMobile || isTablet ) {
       return (
             <div>
                <LabelM label="幸福度診断テスト" />
                <View
                  width="325px"
                  height="auto"
                  position="relative"
                  boxShadow="2px 2px 6px rgba(0.30416667461395264, 0.3028993010520935, 0.3028993010520935, 0.20000000298023224)"
                  padding="0px 0px 0px 0px"
                  margin="0 auto"
                  marginBottom="-60px"
                >
                     <Flex
                       gap="10px"
                       position="absolute"
                       top="0%"
                       bottom="0%"
                       left="0%"
                       right="0%"
                       direction="row"
                       width="100%"
                       height="auto"
                       alignItems="flex-start"
                       borderRadius="3px"
                       padding="0px 20px 20px 0px"
                       backgroundColor="rgba(255,255,255,1)"
                     >
                           <Image
                             width="70px"
                             height="70px"
                             shrink="0"
                             position="relative"
                             padding="0px 0px 0px 0px"
                             src={mobileIcon}
                           ></Image>
                     </Flex>
                     <Flex
                       position="relative"
                       top="calc(50% - 150px - 0px)"
                       left="0%"
                       right="0%"
                       direction="column"
                       width="346px"
                       height="auto"
                       alignItems="center"
                       padding="74px 20px 0px 0px"
                     >
                         <Flex
                           justifyContent="center"
                           gap="40px"
                           direction="column"
                           alignItems="left"
                           shrink="0"
                           alignSelf="stretch"
                           objectFit="cover"
                           position="relative"
                           padding="0px 0px 0px 0px"
                         >
                             <Description
                                paddingBottom="0px"
                                text="幸福度の診断を通して、自分の特徴を知り、今後の人生のやりたいことを考えたり、見つめ直してみませんか。" />
                             <Description
                                marginTop="-24px"
                                paddingBottom="0px"
                                text="ぜひ、やってみてください。" />
                             <Description
                                marginTop="-24px"
                                paddingBottom="0px"
                                text="ただし、幸福度を診断してみなさんをタイプ分けするものではありません。あくまでみなさんの幸せのバランスを見るための目安だとお考えいただければと思います。" />
                             <Text
                                marginTop="-16px"
                                marginBottom="-24px"
                                paddingBottom="0px"
                                textAlign="center"
                                fontSize="14px">
                                （所要時間：およそ10分）
                             </Text>
                             <Flex
                                justifyContent="center"
                                alignItems="left"
                                fontSize="11px"
                                marginBottom="-15px">
                                 <ul style={{marginLeft:'2px', paddingLeft:'2px'}}>
                                     <li>人生満足度の測定（ディーナーによる人生満足尺度）</li>
                                     <li>現在の感情から幸福度の測定</li>
                                     <ul class="list-dashed">
                                         <li>ポジティブ感情</li>
                                         <li>ネガティブ感情</li>
                                     </ul>
                                     <li>幸せの4因子アンケート</li>
                                     <ul>
                                         <li>「やってみよう！」因子：自己実現と成長</li>
                                         <li>「ありがとう！」因子：つながりと感謝</li>
                                         <li>「なんとかなる！」因子：前向きと楽観</li>
                                         <li>「あなたらしく！」因子：独立とマイペース</li>
                                     </ul>
                                 </ul>
                             </Flex>
                             <Flex
                                justifyContent="right"
                                direction="column"
                                lineHeight="0px"
                                marginRight="15px"
                                marginBottom="20px">
                                <Text fontSize="10px" textAlign="right">慶應義塾大学大学院 システムデザイン・マネジメント研究科 教授</Text>
                                <Text fontSize="10px" textAlign="right">武蔵野大学 ウェルビーイング学部 学部長・教授</Text>
                                <Text fontSize="10px" textAlign="right">前野隆司氏　監修</Text>
                             </Flex>
                             <Flex justifyContent="center" alignItems="left">
                                <PrimaryButton
                                  justifyContent="center"
                                  margin="0"
                                  marginTop="-25px"
                                  marginBottom="30px"
                                  onClick={() => checkStart()}
                                  padding={isMobile ? "8px 12px" : "8px 40px"}
                                  overrides={{
                                    "Flex.Text[0]": {
                                      fontSize: isMobile ? "14px" : "16px",
                                      children: "開始",
                                    },
                                  }}
                                />
                             </Flex>
                       </Flex>
                    </Flex>
               </View>
            </div>
       );
  }
  else {
       return (
             <div className="swapper">
              <Flex justifyContent="center" alignItems="center" marginTop="32px">
                <View
                width="100%"
                display="block"
                position="relative"
                background-color="#ffffff"
                >
                    <LabelM label="幸福度診断テスト" />
                    <hr />
                    <Description text="幸福度の診断を通して、自分の特徴を知り、今後の人生のやりたいことを考えたり、見つめ直してみませんか。"/>
                    <Description text="ぜひ、やってみてください。" />
                    <Description text="ただし、幸福度を診断してみなさんをタイプ分けするものではありません。あくまでみなさんの幸せのバランスを見るための目安だとお考えいただければと思います。" />
                    <Description text="（所要時間：およそ10分）" />
                    <Flex justifyContent="left" alignItems="left" marginBottom="20px">
                        <ul style={{marginLeft:'-20px', paddingLeft:'-20px'}}>
                            <li>人生満足度の測定（ディーナーによる人生満足尺度）</li>
                            <li>現在の感情から幸福度の測定</li>
                            <ul class="list-dashed">
                                <li>ポジティブ感情</li>
                                <li>ネガティブ感情</li>
                            </ul>
                            <li>幸せの4因子アンケート</li>
                            <ul>
                                <li>「やってみよう！」因子：自己実現と成長</li>
                                <li>「ありがとう！」因子：つながりと感謝</li>
                                <li>「なんとかなる！」因子：前向きと楽観</li>
                                <li>「あなたらしく！」因子：独立とマイペース</li>
                            </ul>
                        </ul>
                    </Flex>
                    <Text textAlign="right">慶應義塾大学大学院 システムデザイン・マネジメント研究科 教授</Text>
                    <Text textAlign="right">武蔵野大学 ウェルビーイング学部 学部長・教授</Text>
                    <Text textAlign="right">前野隆司氏　監修</Text>
                    <Flex justifyContent="center" alignItems="left" marginTop="40px" marginBottom="20px">
                       <PrimaryButton
                         justifyContent="center"
                         margin="0"
                         onClick={() => checkStart()}
                         padding={isMobile ? "8px 12px" : "8px 40px"}
                         overrides={{
                           "Flex.Text[0]": {
                             fontSize: isMobile ? "14px" : "16px",
                             children: "開始",
                           },
                         }}
                       />
                    </Flex>
                </View>
              </Flex>
            </div>
       );
    }
}
