/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Text } from "@aws-amplify/ui-react";
import DefaultButton from "./DefaultButton";
import PrimaryButton from "./PrimaryButton";
export default function Dialog(props) {
  const { overrides: overridesProp, ...rest } = props;
  const overrides = { ...overridesProp };
  return (
    <Flex
      gap="0"
      direction="column"
      position="relative"
      boxShadow="0px 15px 12px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.25)"
      borderRadius="4px"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "Flex")}
    >
      <Flex
        gap="14px"
        direction="column"
        width="295px"
        shrink="0"
        position="relative"
        padding="21px 23px 21px 23px"
        {...getOverrideProps(overrides, "Flex.Flex[0]")}
      >
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="700"
          color="rgba(13.000000175088644,26.000000350177288,38.0000015348196,1)"
          lineHeight="24px"
          textAlign="center"
          display="flex"
          direction="column"
          justifyContent="center"
          width="249px"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          children="Title"
          {...getOverrideProps(overrides, "Flex.Flex[0].Text[0]")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="400"
          color="rgba(92.00000211596489,102.00000151991844,112.000000923872,1)"
          lineHeight="24px"
          textAlign="center"
          display="flex"
          direction="column"
          justifyContent="center"
          width="249px"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          children="Body text"
          {...getOverrideProps(overrides, "Flex.Flex[0].Text[1]")}
        ></Text>
      </Flex>
      <Flex
        gap="8px"
        direction="row"
        width="295px"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="8px 8px 8px 8px"
        {...getOverrideProps(overrides, "Flex.Flex[1]")}
      >
        <DefaultButton
          alignItems="center"
          display="flex"
          justifyContent="center"
          shrink="0"
          height="34px"
          {...getOverrideProps(overrides, "Flex.Flex[1].DefaultButton[0]")}
        ></DefaultButton>
        <PrimaryButton
          display="flex"
          shrink="0"
          height="34px"
          {...getOverrideProps(overrides, "Flex.Flex[1].PrimaryButton[0]")}
        ></PrimaryButton>
      </Flex>
    </Flex>
  );
}
