/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function PrimaryButtonDisabled(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="0"
      direction="row"
      justifyContent="center"
      alignItems="center"
      position="relative"
      borderRadius="4px"
      padding="8px 12px 8px 12px"
      backgroundColor="rgba(239,240,240,1)"
      {...rest}
      {...getOverrideProps(overrides, "PrimaryButtonDisabled")}
    >
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="700"
        color="rgba(92,102,112,1)"
        lineHeight="18px"
        textAlign="left"
        display="flex"
        direction="column"
        justifyContent="flex-start"
        letterSpacing="0.41px"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Primary Button"
        {...getOverrideProps(overrides, "\u270F\uFE0F Button text")}
      ></Text>
    </Flex>
  );
}
