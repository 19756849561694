import { Flex, Text } from "@aws-amplify/ui-react";
import { useCallback, useEffect, useRef, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { isMobile } from "react-device-detect";
import { DialogButton } from "../ui-components";
import { getLabel } from "../utils/CommonUtils";
import TextField from "./TextField";

export default function InviteDialog(props) {
  const { onOpen, isOpen, onComplete, description, title } = props;
  const dialogRef = useRef();
  const nameRef = useRef();
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        onOpen && onOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [dialogRef, onOpen]);

  const handleComplete = () => {
    if (nameRef.current) {
      if (nameRef.current.value) {
        onComplete(nameRef.current.value);
        setErrorMessage("");
      } else {
        nameRef.current.errorMessage = getLabel("noteName.error");
        setErrorMessage(getLabel("noteName.error"));
      }
    }
  };

  const Dialog = useCallback(
    (props) => {
      return (
        <div className="react-confirm-alert">
          <div className="react-confirm-alert-overlay react-confirm-alert-overlay-custom">
            <div className="custom-ui" ref={dialogRef}>
              <Flex
                width={isMobile ? "295px" : "512px"}
                display={isOpen ? "block" : "none"}
                boxShadow="0px 15px 12px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.25)"
                padding="21px 23px 8px 23px"
                direction="column"
                backgroundColor="rgba(255,255,255,1)"
              >
                <Text fontWeight="700" marginBottom="14px">
                  {title}
                </Text>
                <Text
                  fontSize={isMobile ? "14px" : "16px"}
                  textAlign="left"
                  marginBottom="14px"
                >
                  {description}
                </Text>
                <TextField
                  ref={nameRef}
                  label={getLabel("notononamae")}
                  placeHolder={getLabel("noteName.placeholder")}
                  position="relative"
                  errorMessage={errorMessage}
                  overrideStyle={{
                    className: undefined,
                    padding: "0",
                    textAlign: "left",
                    marginBottom: "29px",
                    width: "100%",
                  }}
                />
                <DialogButton
                  margin="auto"
                  overrides={{
                    DefaultButton: {
                      className: "pointer",
                    },
                    Button: {
                      children: getLabel("dialog.back"),
                      onClick: () => {
                        onOpen(false);
                      },
                      onFocus: () => {
                        onOpen(false);
                      },
                    },
                    PrimaryButton: {
                      className: "pointer",
                      onClick: handleComplete,
                    },
                  }}
                />
              </Flex>
            </div>
          </div>
        </div>
      );
    },

    [isOpen, errorMessage],
  );

  return isOpen ? <Dialog {...props} /> : null;
}
