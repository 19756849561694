import { Dialog } from "../ui-components";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { getLabel } from "../utils/CommonUtils";

export default function (props) {
  return confirmAlert({
    overlayClassName: "react-confirm-alert-overlay-custom",
    customUI: ({ onClose }) => {
      return (
        <div className="custom-ui">
          <Dialog
            overrides={{
              "Flex.Flex[0].Text[0]": {
                children: props.title,
              },
              "Flex.Flex[0].Text[1]": {
                children: props.body,
                whiteSpace: "break-spaces",
              },
              "Flex.Flex[1].DefaultButton[0]": {
                className: "pointer",
                onClick: () => onClose(),
                overrides: {
                  "Flex.Button[0]": {
                    children: getLabel("dialog.cancel"),
                  },
                },
              },
              "Flex.Flex[1].PrimaryButton[0]": {
                className: "pointer",
                onClick: async () => {
                  await props.okOnClick();
                  onClose();
                },
                overrides: {
                  "Flex.Text[0]": {
                    children: props.okLabel,
                  },
                },
              },
            }}
          />
        </div>
      );
    },
  });
}
