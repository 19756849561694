/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Image, Text } from "@aws-amplify/ui-react";
export default function PCCardHowto(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="0"
      direction="row"
      width="1070px"
      justifyContent="flex-end"
      alignItems="center"
      position="relative"
      boxShadow="3px 3px 6px rgba(0, 0, 0, 0.1599999964237213)"
      padding="0px 0px 0px 105px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "PCCardHowto")}
    >
      <Flex
        gap="47px"
        direction="column"
        width="629.43px"
        grow="1"
        basis="629.4307861328125px"
        height="192px"
        position="relative"
        padding="77px 0px 0px 0px"
        {...getOverrideProps(overrides, "Content")}
      >
        <Text
          fontFamily="Zen Kaku Gothic New"
          fontSize="28px"
          fontWeight="500"
          color="rgba(0,0,0,1)"
          lineHeight="41px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="HIRAQL NOTEの使い方"
          {...getOverrideProps(
            overrides,
            "HIRAQL NOTE\u306E\u4F7F\u3044\u65B9"
          )}
        ></Text>
        <Flex
          gap="10px"
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Line")}
        >
          <Image
            width="27px"
            height="27px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            margin="15px 20px 0px 0px"
            {...getOverrideProps(overrides, "Next")}
          ></Image>
        </Flex>
      </Flex>
      <Image
        width="266px"
        height="266px"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "PC_KeyVisual")}
      ></Image>
    </Flex>
  );
}
