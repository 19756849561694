import React, { useCallback, useState, useMemo } from "react";
import { isMobile } from "react-device-detect";
import { PCSelectFieldOther, SelectFieldAOther } from "../ui-components";
import {
  getLabel,
  inValidDate,
  inValidNumber,
  isEmpty,
  isEmptyDate,
  isNotEmpty,
  numbertoDate,
  restrictFieldDate,
  restrictFieldNumber,
} from "./CommonUtils";

const SONOTA = "SONOTA";
const HINITISHITEI = "HINITISHITEI";
const NANNICHIMAEKASHITEI = "NANNICHIMAEKASHITEI";

export const KIBOUKAIGOSHURUI = ["JITAKUDEKAZOKUNI", "JITAKUDEHELPERNI", "SHISETSUDE", "TOKUNIKIBOUHANAI"];
export const KIBOUSHISETSU = ["KOUTEKISHISETSU", "MINKANSHISETSU", "TOKUNIKIBOUHANAI"];
export const HIYOUKYOSHUTSU = ["CHOKINKARA", "HOKENKARA", "NENKINKARA"];
export const SOUGIYOUHI = ["SHITEHOSHII", "SHINAKUTEYOI", "OMAKASE"];
export const KIBOUSOUGIKEISHIKI = ["FUTSUSHIKI", "CHRISTKYOUSHIKI", "SHINTOUSHIKI", "OMAKASE"];
export const KIBOUSURUHUNIKI = ["SEIDAINI", "FUTSUUNI", "KOJINMARITO"];
export const KOKUCHIKIBOUNOUMU = ["SHITEHOSHII", "SHINAKUTEYOI", "OMAKASE"];
export const ENMEISOCHIKIBOUNOUMU = ["SHITEHOSHII", "SHINAKUTEYOI", "OMAKASE"];
export const ZOUKITEIKYOUKIBOUNOUMU = ["SHITEHOSHII", "SHINAKUTEYOI", "OMAKASE"];
export const HOKENSHURUI = ["SEIMEIHOKEN", "IRYOUHOKEN", "KOJINNENKINHOKEN", "KASAIHOKEN", "JIDOUSHAHOKEN", "KAIGOHOKEN", "GAKUSHIHOKEN"];
export const NENKINSHURUI = ["KOUTEKINENKIN", "SHITEKINENKIN"];
export const KIKISHUBETSU = ["SMARTPHONE", "PASOKON", "TABLET", "KOTEIINTERNET"];
export const NYUUINJINORENRAKU = ["RENRAKUSURU", "RENRAKUSHINAI", "DOCHIRADEMOYOI"];
export const SOUGIJINORENRAKU = ["RENRAKUSURU", "RENRAKUSHINAI", "DOCHIRADEMOYOI"];
export const NYUUINJIRENRAKU = ["RENRAKUSURU", "RENRAKUSHINAI", "DOCHIRADEMOYOI"];
export const SOUGIJIRENRAKU = ["RENRAKUSURU", "RENRAKUSHINAI", "DOCHIRADEMOYOI"];
export const HAIRUYOTEINOOHAKANOUMU = ["ARU", "NAI"];
export const KIBOUSURUOHAKANOKEISHIKI = [
  "DAIDAINOOHAKA",
  "KOUNYUZUMINOKAZOKUNOOHAKA",
  "SHINKIKOUNYUUKIBOU",
  "EITAIKUYOUKIBOU",
  "NOUKOTSUDOUKIBOU",
  "JYUMOKUSOUKIBOU",
  "SANKOTSUKIBOU",
  "KIBOUNASHI",
];
export const YUIGONSHONOUMU = ["SAKUSEISITEIRU", "SAKUSEISHITEINAI"];
export const YUIGONSHONOSHURUI = ["JIHITSUSHOUSHOYUIGON", "KOUSEISHOUSHOYUIGON", "HIMITSUSHOUSHOYUIGON"];
export const SOUGINOSYURUI = ["IPPANSOU", "ICHINICHISOU", "KAZOKUSOU", "KASOUSHIKI"];
export const SANKANINZUUKIBO = ["JYUUMEIIKA", "SANJYUUMEIIKA", "GOJUUMEIIKA", "HYAKUMEIIKA", "NIHYAKUMEIIKA", "SANBYAKUMEIIKA", "SANBYAKUMEIIJOU"];
export const TANPONOUMU = ["NAI"];

export const KONOHIGACHIKAZUITARATSUUCHI = ["TOUJITU", "ZENJITU", "ISSYUUKANMAE"];

export const SOUSHINBI = [];

export const useDatalist = ({ list, required = false, dataUpdateHook, type = SONOTA, custemField }) => {
  const [data, setDataBase] = useState("");
  const [dataError, setDataError] = useState(null);
  const [dataSonota, setDataSonota] = useState("");
  const [dataSonotaError, setDataSonotaError] = useState(null);
  const [defaultValue, setDefaultValue] = useState(false);
  const [dataHinitishitei, setDataHinitishitei] = useState("");
  const [dataHinitishiteiError, setDataHinitishiteiError] = useState(null);
  const [dataNannichimaekashitei, setDataNannichimaekashitei] = useState("");
  const [dataNannichimaekashiteiError, setDataNannichimaekashiteiError] = useState(null);

  const hasError = () => {
    if (isEmpty(data) && required) {
      setDataError(getLabel("error.required"));
      return true;
    } else {
      setDataError(null);
      if (data === SONOTA || data === custemField?.value) {
        if (isEmpty(dataSonota)) {
          setDataSonotaError(getLabel("error.required"));
          return true;
        } else {
          setDataSonotaError(null);
        }
      } else {
        setDataSonotaError(null);
      }
      if (data === HINITISHITEI) {
        const inValidDateFormatCheck = inValidDate(dataHinitishitei);
        if (inValidDateFormatCheck) {
          setDataHinitishiteiError(getLabel("error.dateformat"));
          return true;
        } else if (isEmptyDate(dataHinitishitei)) {
          setDataHinitishiteiError(getLabel("error.required"));
          return true;
        } else {
          setDataHinitishiteiError(null);
        }
      } else {
        setDataHinitishiteiError(null);
      }
      if (data === NANNICHIMAEKASHITEI) {
        const inValidFormat = inValidNumber(dataNannichimaekashitei);
        if (isEmpty(dataNannichimaekashitei)) {
          setDataNannichimaekashiteiError(getLabel("error.required"));
          return true;
        } else if (inValidFormat) {
          setDataNannichimaekashiteiError(getLabel("error.number"));
          return true;
        } else {
          setDataNannichimaekashiteiError(null);
        }
      } else {
        setDataNannichimaekashiteiError(null);
      }
    }
    return false;
  };

  const getData = () => {
    if (data === SONOTA || data === custemField?.value) {
      return dataSonota;
    } else if (data === HINITISHITEI) return dataHinitishitei;
    else if (data === NANNICHIMAEKASHITEI) return dataNannichimaekashitei;
    else return data;
  };

  const setData = (value) => {
    if (isNotEmpty(value)) {
      setDefaultValue(true);
      if (list.map((l) => getLabel("Datalist." + l)).indexOf(value) < 0) {
        if (type === SONOTA || custemField?.value) {
          setDataSonota(value);
          setDataBase(custemField?.value ? custemField?.value : SONOTA);
        } else if (type === HINITISHITEI) {
          setDataHinitishitei(value);
          setDataBase(HINITISHITEI);
        } else {
          setDataNannichimaekashitei(value);
          setDataBase(NANNICHIMAEKASHITEI);
        }
      } else {
        setDataBase(value);
      }
    }
  };

  const handleChangeHinitishitei = (event) => {
    setDataHinitishitei(event.target.value);
  };

  const handleChangeNannichimaekashitei = (event) => {
    setDataNannichimaekashitei(event.target.value);
  };

  const onChangeHinitishitei = (event) => {
    event.target.value = restrictFieldDate(event.target.value);
    if (event.target.value.length !== 8) {
      handleChangeHinitishitei({
        target: { value: event.target.value + "--" },
      }); //バリデーションエラーにさせるため
    } else {
      handleChangeHinitishitei({
        target: {
          value: numbertoDate(event.target.value),
        },
      });
    }
  };

  const onChangeNannichimaekashitei = (event) => {
    event.target.value = restrictFieldNumber(event.target.value, 100);
    handleChangeNannichimaekashitei({
      target: {
        value: event.target.value,
      },
    });
  };

  const isSubInput = data === SONOTA || data === HINITISHITEI || data === NANNICHIMAEKASHITEI || data === custemField?.value;

  const typeSubInput = () => {
    if (type === HINITISHITEI) {
      return isMobile ? "tel" : "date";
    } else if (type === NANNICHIMAEKASHITEI) {
      return "tel";
    } else return "text";
  };

  const placeHolderSubInput = () => {
    if (type === HINITISHITEI) return "";
    else if (type === NANNICHIMAEKASHITEI) return getLabel("nannichimaekashitei.placeholder");
    else return getLabel(custemField?.placeHolder ? custemField?.placeHolder : "sonota.placeholder");
  };

  const hasErrorSubInput = () => {
    if (type === HINITISHITEI) return isNotEmpty(dataHinitishiteiError);
    else if (type === NANNICHIMAEKASHITEI) return isNotEmpty(dataNannichimaekashiteiError);
    else return isNotEmpty(dataSonotaError);
  };

  const defaultValueSubInput = (initValue, isInitSonota) => {
    if (type === HINITISHITEI) return isMobile ? dataHinitishitei.replaceAll("-", "") : dataHinitishitei;
    else if (type === NANNICHIMAEKASHITEI) return dataNannichimaekashitei;
    else return initValue && isInitSonota ? initValue : dataSonota;
  };

  const errorMessageSubInput = () => {
    if (type === HINITISHITEI) return dataHinitishiteiError;
    else if (type === NANNICHIMAEKASHITEI) return dataNannichimaekashiteiError;
    else return dataSonotaError;
  };

  const onChangeSubInput = (event) => {
    if (type === HINITISHITEI) {
      isMobile ? onChangeHinitishitei(event) : setDataHinitishitei(event.target.value);
    } else if (type === NANNICHIMAEKASHITEI) {
      onChangeNannichimaekashitei(event);
    } else {
      setDataSonota(event.target.value);
    }
    if (dataUpdateHook) dataUpdateHook(true);
  };

  const maxLenghtSubInput = () => {
    if (type === HINITISHITEI) return 8;
    else return 100;
  };

  const DataList = useCallback(
    ({ label: label, initValue }) => {
      const dataList = [];
      const isInitSonota = list.map((l) => getLabel("Datalist." + l)).indexOf(initValue) < 0;
      if (!defaultValue || !required) {
        dataList.push(
          <option key="default" value="">
            {getLabel("list.select")}
          </option>
        );
      }
      list.forEach((type) => {
        dataList.push(
          <option key={type} value={getLabel("Datalist." + type)}>
            {getLabel("Datalist." + type)}
          </option>
        );
      });
      if (type === HINITISHITEI) {
        dataList.push(
          <option key={HINITISHITEI} value={HINITISHITEI}>
            {getLabel("Datalist." + HINITISHITEI)}
          </option>
        );
      } else if (type === NANNICHIMAEKASHITEI) {
        dataList.push(
          <option key={NANNICHIMAEKASHITEI} value={NANNICHIMAEKASHITEI}>
            {getLabel("Datalist." + NANNICHIMAEKASHITEI)}
          </option>
        );
      } else {
        dataList.push(
          <option key={custemField?.value ? custemField?.value : SONOTA} value={custemField?.value ? custemField?.value : SONOTA}>
            {getLabel(`Datalist.${custemField?.value ? custemField?.value : SONOTA}`)}
          </option>
        );
      }

      if (isMobile) {
        return (
          <SelectFieldAOther
            width={"auto"}
            overrides={{
              "Flex.SelectFieldA[0]": {
                className: "SelectFieldA",
                overrides: {
                  "Flex.SelectField[0]": {
                    label: required ? (
                      <div>
                        {getLabel(label)}
                        <font className="required">※</font>
                      </div>
                    ) : (
                      getLabel(label)
                    ),
                    className: "SelectFieldA",
                    hasError: isNotEmpty(dataError),
                    value: data,
                    errorMessage: dataError,
                    children: <React.Fragment>{dataList}</React.Fragment>,
                    onChange: (event) => {
                      setDataBase(event.target.value);
                      if (dataUpdateHook) dataUpdateHook(true);
                    },
                  },
                },
              },
              "Flex.TextFieldA[0]": {
                display: isSubInput ? "block" : "none",
                width: "auto",
                className: "TextFieldA",
                overrides: {
                  "Flex.TextField[0]": {
                    width: "auto",
                    label: "",
                    maxLength: maxLenghtSubInput(),
                    type: typeSubInput(),
                    placeholder: placeHolderSubInput(),
                    hasError: hasErrorSubInput(),
                    errorMessage: errorMessageSubInput(),
                    defaultValue: defaultValueSubInput(initValue, isInitSonota),
                    onChange: onChangeSubInput,
                  },
                },
              },
            }}
          />
        );
      } else {
        return (
          <PCSelectFieldOther
            className="maxwidth800"
            margin="0px auto 16px auto"
            overrides={{
              Flex: {
                gap: "0",
              },
              "Flex.PCSelectField[0]": {
                className: "maxwidth800",
                margin: "auto",
                overrides: {
                  "Flex.SelectFieldA[0]": {
                    overrides: {
                      "Flex.SelectField[0]": {
                        height: "auto",
                        width: "auto",
                        style: { fontSize: "16px" },
                        label: (
                          <div style={{ fontSize: "16px" }}>
                            {required ? (
                              <>
                                {getLabel(label)}
                                <font className="required">※</font>
                              </>
                            ) : (
                              getLabel(label)
                            )}
                          </div>
                        ),
                        hasError: isNotEmpty(dataError),
                        value: data,
                        errorMessage: dataError,
                        children: <React.Fragment>{dataList}</React.Fragment>,
                        onChange: (event) => {
                          setDataBase(event.target.value);
                          if (dataUpdateHook) dataUpdateHook(true);
                        },
                      },
                    },
                  },
                },
              },
              "Flex.PCTextField[0]": {
                className: "maxwidth800",
                margin: "auto",
                overrides: {
                  "Flex.TextFieldA[0]": {
                    display: isSubInput ? "block" : "none",
                    overrides: {
                      "Flex.TextField[0]": {
                        maxLength: maxLenghtSubInput(),
                        width: "auto",
                        label: "",
                        fontSize: "16px",
                        type: typeSubInput(),
                        placeholder: placeHolderSubInput(),
                        hasError: hasErrorSubInput(),
                        errorMessage: errorMessageSubInput(),
                        defaultValue: defaultValueSubInput(initValue, isInitSonota),
                        onChange: onChangeSubInput,
                      },
                    },
                  },
                },
              },
            }}
          />
        );
      }
    },
    [data, dataError, dataSonotaError, dataHinitishiteiError, dataNannichimaekashiteiError]
  );
  return [DataList, setData, getData, hasError];
};
// export const getDatalistItems = (datalist, registeredList) => {
//   const list = Array.from(datalist);
//   if (registeredList == null) {
//     return list;
//   } else {
//     Array.from(new Set(registeredList.filter((data) => datalist.indexOf(data) < 0).map((data) => data))).forEach((data) => {
//       list.push(data);
//     });
//   }
//   return list;
// };

// export const getDatalistLabel = (value) => {
//   if (getLabel("Datalist." + value) == "Datalist." + value) {
//     return value;
//   } else {
//     return getLabel("Datalist." + value);
//   }
// };
