import { React, useState } from "react";
import { Flex } from "@aws-amplify/ui-react";
import { AskDialog, PCAskDialog } from "../ui-components";
import { isMobile } from "react-device-detect";

import tel_icon from "../../src/assets/image/ask_tel_white.png";
import butler_icon from "../../src/assets/image/butler.png";

export default function ({isShowedPopup, setIsShowedPopup}) {
  const tel = () => {
    window.gtag("event", "AskDialogPopup", {
      event_category: "Contact",
      event_label: "電話発信（スマホ）",
    });
    window.location.href = "tel:03-3477-6420";
  };

  const handleClose = () => {
    window.gtag("event", "AskDialogPopup", {
      event_category: "Contact",
      event_label: "閉じる",
    });
    setIsShowedPopup("none");
  };

  return (
        (isMobile
            ? (
                <div id="askPopup" className="guide-modal-container most-front" style={{display: isShowedPopup}}>
                  <div className="guide-wrapper">
                    <div className="guide-content-mb guide-content-center-mb right-bottom guide-content-mb-adjust-bucketlistguidancedialog">
                      <Flex marginLeft="1rem">
                        <AskDialog
                           overrides={{
                             CloseButton: {
                               className: "close-button-wrapper",
                             },
                             Icon: {
                               className: "pointer close-icon-modal",
                               paths: [
                                 {
                                   d: "M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z",
                                   fill: "#1C1A1D",
                                   fillRule: "nonzero",
                                 },
                               ],
                               onClick: handleClose,
                             },
                             TelButton: {
                               onClick: tel,
                             },
                             TelIcon: {
                               src: tel_icon,
                             },
                             ButlerIcon: {
                               src: butler_icon,
                             },
                             Ellipse: {
                               className: "display-none",
                             },
                           }}
                        />
                      </Flex>
                    </div>
                  </div>
                </div>
            )
            : (
                <div className="guide-modal-container most-front" style={{display: isShowedPopup}}>
                  <div className="guide-wrapper">
                    <div className="guide-content-pc right-bottom">
                      <Flex>
                        <PCAskDialog
                           overrides={{
                             CloseButton: {
                               className: "close-button-wrapper",
                             },
                             Icon: {
                               className: "pointer close-icon-modal",
                               paths: [
                                 {
                                   d: "M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z",
                                   fill: "#1C1A1D",
                                   fillRule: "nonzero",
                                 },
                               ],
                               onClick: handleClose,
                             },
                             ButlerIcon: {
                               src: butler_icon,
                             },
                             Ellipse: {
                               className: "display-none",
                             },
                           }}
                        />
                      </Flex>
                    </div>
                  </div>
                </div>
            )
        )
  ); 
}
