/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Icon, IconCheckCircle, Text, View } from "@aws-amplify/ui-react";
export default function ListSingleLineBIcon(props) {
  const { overrides: overridesProp, ...rest } = props;
  const overrides = { ...overridesProp };
  return (
    <View
      width="343px"
      height="56px"
      overflow="hidden"
      position="relative"
      borderRadius="4px"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "View")}
    >
      <Icon
        width="40px"
        height="40px"
        pathData=""
        viewBox={{ minX: 0, minY: 0, width: 40, height: 40 }}
        position="absolute"
        top="8px"
        left="287px"
        {...getOverrideProps(overrides, "View.Icon[0]")}
      ></Icon>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="400"
        color="rgba(13.000000175088644,26.000000350177288,38.0000015348196,1)"
        lineHeight="20px"
        textAlign="left"
        display="flex"
        direction="column"
        justifyContent="flex-start"
        position="absolute"
        top="calc(50% - 10px - 0px)"
        left="56px"
        padding="0px 0px 0px 0px"
        children="Label"
        {...getOverrideProps(overrides, "View.Text[0]")}
      ></Text>
      <IconCheckCircle
        width="24px"
        height="24px"
        position="absolute"
        top="calc(50% - 12px - 0px)"
        left="16px"
        overflow="hidden"
        padding="0px 0px 0px 0px"
        color="rgba(4.000000236555934,125.00000014901161,149.00000631809235,1)"
        type="check_circle"
        fontSize="24px"
        {...getOverrideProps(overrides, "View.IconCheckCircle[0]")}
      ></IconCheckCircle>
    </View>
  );
}
