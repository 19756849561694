import { DataStore } from "aws-amplify";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  LeavesDaijinishitaikinenbi,
  LeavesKazokuShinzoku,
  LeavesYuujinChijin,
} from "../models";
import { ListViewDetail } from "../page-components";
import { getLabel, isNotEmpty, useInitializePage } from "../utils/CommonUtils";
import { isShare, queryData, queryDatas } from "../utils/ShareUtils";

function AnniversaryViewPage() {
  const navigate = useInitializePage("daijinishitaikinenbi", "title.detail");
  const [dataAnniversary, setAnniversary] = useState({});
  const [tsuuchisaki, setTsuuchisaki] = useState(null);
  const params = useParams();
  const deleteData = useCallback(async () => {
    await DataStore.delete(dataAnniversary);
    navigate(-1, { replace: true });
  }, [dataAnniversary, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const data = await queryData(params, LeavesDaijinishitaikinenbi);
      if (data?.length > 0) {
        setAnniversary(data[0]);
        const listName = [];
        if (data[0]?.tsuuchisakiKazokuShinzoku?.length > 0) {
          const familys = await queryDatas(params, LeavesKazokuShinzoku);
          const listFamily = familys
            .filter(
              (f) => data[0]?.tsuuchisakiKazokuShinzoku.indexOf(f.id) >= 0,
            )
            ?.sort((a, b) => {
              if (a?.id > b?.id) return 1;
              if (a?.id < b?.id) return -1;
              return 0;
            })
            .map((f) => f.shimei)
            .join("、");
          isNotEmpty(listFamily) && listName.push(listFamily);
        }
        if (data[0]?.tsuuchisakiYuujinChijin?.length > 0) {
          const friends = await queryDatas(params, LeavesYuujinChijin);
          const listFriend = friends
            .filter((f) => data[0]?.tsuuchisakiYuujinChijin.indexOf(f.id) >= 0)
            ?.sort((a, b) => {
              if (a?.id > b?.id) return 1;
              if (a?.id < b?.id) return -1;
              return 0;
            })
            .map((f) => f.shimei)
            .join("、");
          isNotEmpty(listFriend) && listName.push(listFriend);
        }
        setTsuuchisaki(listName?.join("、"));
      }
    };
    getData();
  }, [params, params.id]);

  const listLabel = [
    { label: "kinenbimei", value: dataAnniversary?.kinenbimei },
    {
      label: "hiduke",
      value: dataAnniversary?.hiduke,
      type: "date",
    },
    {
      label: "konohigachikazuitaratsuuchi",
      value: isNaN(parseInt(dataAnniversary?.konohigachikazuitaratsuuchi))
        ? dataAnniversary?.konohigachikazuitaratsuuchi
        : `${dataAnniversary?.konohigachikazuitaratsuuchi}${getLabel(
            "hikuma",
          )}`,
    },
    {
      label: "tsuuchisaki",
      value: tsuuchisaki,
    },
    {
      label: "bikou.anniversary",
      value: dataAnniversary?.bikou,
    },
  ];

  return (
    <ListViewDetail
      label={dataAnniversary?.kinenbimei}
      list={listLabel}
      isHideEdit={isShare(params)}
      onDelete={deleteData}
      onEdit={() => {
        navigate("edit");
        window.gtag("event", "Change", {
          event_category: "Edit",
          event_label: "大事にしたい日変更開始",
        });
      }}
    />
  );
}

export default AnniversaryViewPage;
