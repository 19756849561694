import { DataStore } from "aws-amplify";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { LeavesSeikatsunokoto } from "../models";
import {
  ButtonCancelSave,
  SwapperContent,
  TextFieldMultiline,
} from "../page-components";
import { getLabel, isEmptySeika, useInitializePage } from "../utils/CommonUtils";
import { usePrompt } from "../utils/Prompt";

function LifeEditPage() {
  const navigate = useInitializePage("seikatsunokotonitsuite", "title.update");
  const data = useRef();
  const [isEmptyData, setIsEmptyData] = useState(true);

  const [lifeData, setLifeData] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);

  const [update, setUpdate] = useState(false);

  const eventTracking = () => {
    if (isEmptyData) {
      window.gtag("event", "FirstWriteComplete", {
        event_category: "Edit",
        event_label: "生活のことについて書込完了",
      });
    } else {
      window.gtag("event", "ChangeComplete", {
        event_category: "Edit",
        event_label: "生活のことについて変更完了",
      });
    }
  };

  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const dsData = await DataStore.query(LeavesSeikatsunokoto);
      if (dsData?.length > 0) {
        data.current = dsData[0];
        setIsEmptyData(isEmptySeika(dsData[0]));
        setLifeData(dsData[0].seikatsunokotonitsuite);
      }
    };
    getData();
  }, []);

  const updateData = useCallback(async () => {
    setIsUpdated(true);
    await DataStore.save(
      LeavesSeikatsunokoto.copyOf(data.current, (updated) => {
        updated.seikatsunokotonitsuite = lifeData;
      }),
    );
    eventTracking();
    navigate(-1, { replace: true });
  }, [data, lifeData, navigate]);

  return (
    <SwapperContent label={getLabel("button.update")}>
      <TextFieldMultiline
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("seikatsunokotonitsuite")}
        value={lifeData}
        placeHolder={getLabel("minomawarinokoto")}
        onChange={(event) => {
          setLifeData(event.target.value);
          setUpdate(true);
        }}
      />
      <ButtonCancelSave
        cancelOnClick={() => navigate(-1, { replace: true })}
        saveOnClick={updateData}
        isDisabled={isUpdated}
      />
    </SwapperContent>
  );
}

export default LifeEditPage;
