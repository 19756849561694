/* eslint-disable import/no-anonymous-default-export */
import { isMobile } from "react-device-detect";
import {
  CategorySwapper,
  LabelM,
  ListSingleLineA,
  ListSingleLineTwoColum,
  MessageButton,
} from "../page-components";
import { getLabel, useInitializePage } from "../utils/CommonUtils";

export default function () {
  const navigate = useInitializePage("heart");

  return (
    <div>
      {isMobile ? (
        <div style={{ marginBottom: "94px" }}>
          <LabelM label={getLabel("heart")} />
          <ListSingleLineA
            onClick={() => navigate("bucketlist")}
            label={getLabel("yaritaikoto")}
          />
          <ListSingleLineA
            onClick={() => navigate("message")}
            label={getLabel("taisetsunishitaihitohenomessage")}
          />
          <ListSingleLineA
            onClick={() => navigate("care")}
            label={getLabel("kaigonitsuite")}
          />
          <ListSingleLineA
            onClick={() => navigate("medical")}
            label={getLabel("iryousochinitsuite")}
          />
          <ListSingleLineA
            onClick={() => navigate("will")}
            label={getLabel("yuigonshonitsuite")}
          />
          <ListSingleLineA
            onClick={() => navigate("funeral")}
            label={getLabel("souginitsuite")}
          />
          <ListSingleLineA
            onClick={() => navigate("tomb")}
            label={getLabel("hakanitsuite")}
          />
        </div>
      ) : (
        <>
          <div className="swapper">
            <LabelM label={getLabel("heart")} isDivide />
            <CategorySwapper>
              <ListSingleLineTwoColum
                onClick={() => navigate("bucketlist")}
                label={getLabel("yaritaikoto")}
              />
              <ListSingleLineTwoColum
                onClick={() => navigate("message")}
                label={getLabel("taisetsunishitaihitohenomessage")}
              />
              <ListSingleLineTwoColum
                onClick={() => navigate("care")}
                label={getLabel("kaigonitsuite")}
              />
              <ListSingleLineTwoColum
                onClick={() => navigate("medical")}
                label={getLabel("iryousochinitsuite")}
              />
              <ListSingleLineTwoColum
                onClick={() => navigate("will")}
                label={getLabel("yuigonshonitsuite")}
              />
              <ListSingleLineTwoColum
                onClick={() => navigate("funeral")}
                label={getLabel("souginitsuite")}
              />
              <ListSingleLineTwoColum
                onClick={() => navigate("tomb")}
                label={getLabel("hakanitsuite")}
              />
            </CategorySwapper>
          </div>
        </>
      )}
      <MessageButton
        onClick={() => {
          window.gtag("event", "ContactMyWill", {
            event_category: "Contact",
            event_label: "相談私の意思",
          });
        }}
      />
    </div>
  );
}
