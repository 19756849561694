import { DataStore, Storage } from "aws-amplify";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { LeavesTaisetsunishitaihitohenomessage } from "../models";
import { ListField } from "../page-components";
import { getLabel, isEmpty, isEmptyDate, isInvalidDateFormat, useInitializePage } from "../utils/CommonUtils";
import { log } from "../utils/Logger";
import { usePrompt } from "../utils/Prompt";

function MessageEditPage() {
  const navigate = useInitializePage("taisetsunishitaihitohenomessage", "title.update");
  const data = useRef();
  const [messageData, setMessageData] = useState({
    messagetitle: "",
    messagenaiyou: "",
    soushinbi: null,
    tenpufile: null,
    objectSrc: null,
  });
  const [isLoaded, setIsLoaded] = useState(true);
  const [isFamily, setIsfamily] = useState(false);
  const [soushinsakiId, setSoushinsakiID] = useState(null);
  const [messageTitleError, setMessageTitleError] = useState("");
  const [messageDateError, setMessageDateError] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);
  const params = useParams();

  const [update, setUpdate] = useState(false);

  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  useEffect(() => {
    setSoushinsakiID(messageData?.soushinsaki?.id);
  }, [messageData?.soushinsaki?.id]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const dataQuery = await DataStore.query(LeavesTaisetsunishitaihitohenomessage, (c) => c.id("eq", params.id));
      if (dataQuery?.length > 0) {
        data.current = dataQuery[0];
        if (dataQuery[0]?.isSentMessage) {
          navigate(-1, { replace: true });
        } else {
          setIsLoaded(true);
          setMessageData({
            messagetitle: dataQuery[0].messagetitle,
            messagenaiyou: dataQuery[0].messagenaiyou,
            soushinbi: dataQuery[0].soushinbi,
          });
          if (dataQuery[0]?.soushinsaki) {
            setSoushinsakiID(dataQuery[0]?.soushinsaki);
            setIsfamily(true);
          } else if (dataQuery[0]?.soushinsakiYuujinChijin) {
            setSoushinsakiID(dataQuery[0]?.soushinsakiYuujinChijin);
          }
        }
      }
    };
    getData();
  }, [params.id]);

  useEffect(() => {
    let mount = true;
    if (data.current && data.current.id) {
      Storage.list(`LeavesTaisetsunishitaihitohenomessage/${data.current.id}/`, {
        level: "private",
      }).then((res) => {
        const url = res.length > 0 ? res[0].key : null;
        if (mount) {
          setMessageData({
            ...messageData,
            tenpufile: url,
            objectSrc: url,
          });
        }
      });
    }
    return () => (mount = false);
  }, [data.current]);

  const listField = [
    {
      type: "textField",
      label: "messagetitle",
      isRequire: true,
      placeHolder: "mailnotitleninarimasu",
      errorMessage: messageTitleError,
      value: messageData.messagetitle,
    },
    {
      type: "familyAndFriend",
      label: "soushinsaki",
      value: soushinsakiId,
    },
    {
      type: "textArea",
      label: "messagenaiyou",
      placeHolder: "mailnohonbunninarimasu",
      value: messageData.messagenaiyou,
    },
    {
      type: "file",
      label: "tenpufile",
      value: messageData.objectSrc,
    },
    {
      type: "textField",
      typeInput: "date",
      label: "soushinbi",
      errorMessage: messageDateError,
      value: messageData?.soushinbi,
    },
  ];

  const autoSave = useCallback(() => {
    let valid = true;
    const dateFormatValid = isInvalidDateFormat(messageData?.soushinbi);
    if (isEmpty(messageData.messagetitle)) {
      setMessageTitleError(getLabel("error.required"));
      valid = false;
    } else setMessageTitleError("");
    if (dateFormatValid) {
      setMessageDateError(getLabel("error.dateformat"));
      valid = false;
    } else setMessageDateError("");
    if (valid) {
      console.log("save messageData", messageData);
      const family = messageData?.soushinsaki?.type === "family" ? messageData?.soushinsaki?.id : null;
      const friend = messageData?.soushinsaki?.type === "friend" ? messageData?.soushinsaki?.id : null;
      DataStore.save(
        LeavesTaisetsunishitaihitohenomessage.copyOf(data.current, (updated) => {
          updated.messagetitle = messageData.messagetitle;
          updated.soushinsaki = messageData?.soushinsaki ? family : isFamily ? soushinsakiId : null;
          updated.soushinsakiYuujinChijin = messageData?.soushinsaki ? friend : isFamily ? null : soushinsakiId;
          updated.messagenaiyou = messageData.messagenaiyou;
          updated.soushinbi = isEmptyDate(messageData?.soushinbi) ? null : messageData?.soushinbi;
        })
      );
      if (messageData.tenpufile !== messageData.objectSrc) {
        const editData = async () => {
          const listFile = await Storage.list(`LeavesTaisetsunishitaihitohenomessage/${data.current.id}/`, {
            level: "private",
          });
          for (let i = 0; i < listFile.length; i++) {
            await Storage.remove(listFile[i].key, { level: "private" });
          }
          if (messageData.tenpufile) {
            const result = await Storage.put(
              `LeavesTaisetsunishitaihitohenomessage/${data.current.id}/${messageData.tenpufile.name}`,
              messageData.tenpufile,
              {
                contentType: messageData.tenpufile.type,
                level: "private",
              }
            );
            log.debug("s3 upload result", result);
          }
        };
        editData();
      }
      return true;
    } else {
      return false;
    }
  }, [
    messageData.messagetitle,
    messageData.soushinbi,
    messageData?.soushinsaki,
    messageData.tenpufile,
    messageData.objectSrc,
    messageData.messagenaiyou,
    soushinsakiId,
    navigate,
  ]);

  const eventTracking = () => {
    window.gtag("event", "ChangeComplete", {
      event_category: "Edit",
      event_label: "大切にしたい人へのメッセージ変更完了",
    });
  };

  const updateDataMessage = useCallback(async () => {
    let valid = true;
    const dateFormatValid = isInvalidDateFormat(messageData?.soushinbi);
    if (isEmpty(messageData.messagetitle)) {
      setMessageTitleError(getLabel("error.required"));
      valid = false;
    } else setMessageTitleError("");
    if (dateFormatValid) {
      setMessageDateError(getLabel("error.dateformat"));
      valid = false;
    } else setMessageDateError("");
    if (valid) {
      setIsUpdated(true);
      try {
        const family = messageData?.soushinsaki?.type === "family" ? messageData?.soushinsaki?.id : null;
        const friend = messageData?.soushinsaki?.type === "friend" ? messageData?.soushinsaki?.id : null;
        await DataStore.save(
          LeavesTaisetsunishitaihitohenomessage.copyOf(data.current, (updated) => {
            updated.messagetitle = messageData.messagetitle;
            updated.soushinsaki = messageData?.soushinsaki ? family : isFamily ? soushinsakiId : null;
            updated.soushinsakiYuujinChijin = messageData?.soushinsaki ? friend : isFamily ? null : soushinsakiId;
            updated.messagenaiyou = messageData.messagenaiyou;
            updated.soushinbi = isEmptyDate(messageData?.soushinbi) ? null : messageData?.soushinbi;
          })
        );
        if (messageData.tenpufile !== messageData.objectSrc) {
          const editData = async () => {
            const listFile = await Storage.list(`LeavesTaisetsunishitaihitohenomessage/${data.current.id}/`, {
              level: "private",
            });
            for (let i = 0; i < listFile.length; i++) {
              await Storage.remove(listFile[i].key, { level: "private" });
            }
            if (messageData.tenpufile) {
              const result = await Storage.put(
                `LeavesTaisetsunishitaihitohenomessage/${data.current.id}/${messageData.tenpufile.name}`,
                messageData.tenpufile,
                {
                  contentType: messageData.tenpufile.type,
                  level: "private",
                }
              );
              log.debug("s3 upload result", result);
            }
            eventTracking();
            navigate(-1, { replace: true });
          };
          editData();
        } else {
          eventTracking();
          navigate(-1, { replace: true });
        }
        log.debug("message update success", messageData);
      } catch (e) {
        setIsUpdated(false);
        log.debug("message update error", e);
      }
    }
  }, [
    messageData.messagetitle,
    messageData.soushinbi,
    messageData?.soushinsaki,
    messageData.tenpufile,
    messageData.objectSrc,
    messageData.messagenaiyou,
    soushinsakiId,
    navigate,
  ]);

  return (
    isLoaded && (
      <ListField
        label={getLabel("button.update")}
        list={listField}
        setState={setMessageData}
        setUpdate={setUpdate}
        onSave={updateDataMessage}
        onCancel={() => navigate(-1, { replace: true })}
        isDisabled={isUpdated}
        autoSave={autoSave}
      />
    )
  );
}

export default MessageEditPage;
