import { DataStore, Storage } from "aws-amplify";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { LeavesKakeizu } from "../models";
import {
  ListSelectContent,
  ListSingleLineA,
  ListSingleLineD,
  StrageImage,
} from "../page-components";
import { getLabel, useInitializePage } from "../utils/CommonUtils";
import { isShare, listObjects, queryDatas } from "../utils/ShareUtils";

function FamilyTreePage() {
  const navigate = useInitializePage("kakeizu");
  const [listFamilyTree, setListFamilyTree] = useState([]);
  const [imageList, setImageList] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [selected, setSelected] = useState(null);
  const locate = useLocation();
  const { state } = locate;
  const params = useParams();

  const handledChangeActive = (item) => {
    setSelected(item);
  };

  useEffect(() => {
    if (listFamilyTree?.length > 0) {
      const item = listFamilyTree.find((ele) => ele?.id === state?.id);
      if (item) {
        setSelected(item);
        window.history.replaceState(
          { ...window.history?.state, usr: null },
          document.title,
        );
      } else setSelected(listFamilyTree[0]);
    } else {
      setSelected(null);
    }
  }, [listFamilyTree, state?.id]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const modelData = await queryDatas(params, LeavesKakeizu);
      if (modelData?.length > 0) {
        setListFamilyTree(modelData);
      }
      setIsLoaded(true);
    };
    getData();
  }, []);

  useEffect(() => {
    if (isLoaded) {
      if (listFamilyTree.length >= 3) setIsDisabled(true);
      // limit item is 3
      else setIsDisabled(false);
    }
  }, [listFamilyTree, isLoaded]);

  const deleteData = async (data) => {
    await DataStore.delete(data);
    await Storage.remove(imageList[data.id], {
      level: "private",
    });
    const newList = await DataStore.query(LeavesKakeizu);
    setListFamilyTree(newList);
  };

  const getImageUrl = useCallback(
    (item) => {
      if (imageList[item.id]) {
        return;
      } else {
        const getImage = async () => {
          const list = await listObjects(params, LeavesKakeizu, item);
          const url = list.length > 0 ? list[0].key : null;
          imageList[item.id] = url;
          setImageList((i) => ({
            ...i,
            [item.id]: url,
          }));
        };
        getImage();
      }
    },
    [imageList],
  );

  useEffect(() => {
    if (selected) {
      getImageUrl(selected);
    }
  }, [getImageUrl, selected]);

  return (
    <ListSelectContent
      label={getLabel("kakeizu")}
      mobileList={listFamilyTree.map((data, index) => {
        return (
          <ListSingleLineA
            key={data?.id}
            label={data?.filemei}
            onClick={() => navigate(data?.id)}
          />
        );
      })}
      description={{
        type: "history",
        category: "kakeizu",
        subDescription: getLabel("description.kakeizu"),
      }}
      isShared={isShare(params)}
      addButtonLabel={
        getLabel("file") + getLabel("button.addsuffix") //**TODO: using getLabel when デバイス is defined in vocabulary */
      }
      onAddClick={() => {
        navigate("add");
        window.gtag("event", "FirstWrite", {
          event_category: "Edit",
          event_label: "家系図書込開始",
        });
      }}
      isDisabled={isDisabled}
      pcListItem={listFamilyTree.map((ele, index) => {
        return (
          <ListSingleLineD
            key={ele?.id}
            label={ele?.filemei}
            selected={selected?.id === ele?.id}
            onClick={() => {
              handledChangeActive(ele);
            }}
          />
        );
      })}
      selectedItemList={[
        {
          label: getLabel("filemei"),
          value: selected?.filemei,
        },
        {
          label: getLabel("tenpufile"),
          value: imageList[selected?.id] && (
            <StrageImage key={selected?.id} src={imageList[selected?.id]} />
          ),
        },
        {
          isEmptyHide: true,
          label: getLabel("hokanbasyo"),
          value: selected?.hokanbasyo,
        },
        {
          isEmptyHide: true,
          label: getLabel("bikou"),
          value: selected?.bikou,
        },
      ]}
      selectedItemLabel={selected?.filemei}
      onDelete={() => deleteData(selected)}
      onEdit={() => {
        navigate(selected?.id + "/edit");
        window.gtag("event", "Change", {
          event_category: "Edit",
          event_label: "家系図変更開始",
        });
      }}
      isLoaded={isLoaded}
      style={{ maxHeight: "calc(100vh - 455px)", marginTop: "0" }}
    />
  );
}

export default FamilyTreePage;
