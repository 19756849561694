import { DataStore, Storage } from "aws-amplify";
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Priority, PriorityLabel } from "../constants";
import { LeavesShuukan } from "../models";
import { ListField } from "../page-components";
import {
  getLabel,
  isEmpty,
  isNotEmpty,
  useInitializePage,
} from "../utils/CommonUtils";
import { usePrompt } from "../utils/Prompt";

export default function HabitEditPage() {
  const navigate = useInitializePage("shuukan", "title.update");
  const params = useParams();
  const [habitData, setHabitData] = useState({
    yaritaikoto: "",
    yuusendo: "",
    episode: "",
    bikou: "",
    imagegazou: null,
  });
  const data = useRef();
  const [habitDataError, setHabitDataError] = useState({
    yaritaikoto: "",
  });
  const [isUpdated, setIsUpdated] = useState(false);
  const [update, setUpdate] = useState(false);

  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  useEffect(() => {
    const getData = async () => {
      const dataQuery = await DataStore.query(LeavesShuukan, (c) =>
        c.id("eq", params.id)
      );
      if (dataQuery.length > 0) {
        const image = await Storage.list(`LeavesShuukan/${params.id}/`, {
          level: "private",
        });
        setHabitData({
          ...dataQuery[0],
          imagegazou: image.length > 0 && image[0]?.key,
          objectSrc: image.length > 0 && image[0]?.key,
        });
        data.current = dataQuery[0];
      }
    };
    getData();
  }, [params]);

  const eventTracking = () => {
    window.gtag("event", "ChangeComplete", {
      event_category: "Edit",
      event_label: "やりたいこと変更完了",
    });
  };

  const handleUpdate = async () => {
    let valid = true;
    const error = { ...habitDataError };
    if (isEmpty(habitData?.yaritaikoto)) {
      error.yaritaikoto = getLabel("error.required");
      valid = false;
    } else {
      error.yaritaikoto = "";
    }
    if (isEmpty(habitData?.episode)) {
      error.episode = getLabel("error.required");
      valid = false;
    } else {
      error.episode = "";
    }

    setHabitDataError(error);

    if (valid) {
      setIsUpdated(true);

      await DataStore.save(
        LeavesShuukan.copyOf(data.current, (updated) => {
          updated.yaritaikoto = habitData.yaritaikoto;
          updated.yuusendo = habitData.yuusendo;
          updated.episode = habitData.episode;
          updated.bikou = habitData.bikou;
        })
      );
      if (habitData.imagegazou !== habitData.objectSrc) {
        const editData = async () => {
          const listFile = await Storage.list(
            `LeavesShuukan/${data.current.id}/`,
            {
              level: "private",
            }
          );
          for (let i = 0; i < listFile.length; i++) {
            await Storage.remove(listFile[i].key, { level: "private" });
          }
          if (habitData.imagegazou) {
            await Storage.put(
              `LeavesShuukan/${data.current.id}/${habitData.imagegazou.name}`,
              habitData.imagegazou,
              {
                contentType: habitData.imagegazou.type,
                level: "private",
              }
            );
          }
          eventTracking();
          navigate(-1, { replace: true });
        };
        editData();
      } else {
        eventTracking();
        navigate(-1, { replace: true });
      }
    }
  };

  const listField = [
    {
      type: "textField",
      label: "yaritaikoto",
      placeHolder: "maiasajoggingsurunado",
      isRequire: true,
      value: habitData?.yaritaikoto,
      hasError: isNotEmpty(habitDataError?.yaritaikoto),
      errorMessage: habitDataError?.yaritaikoto,
    },
    {
      type: "image",
      label: "imagegazou",
      value: habitData.objectSrc,
    },
    {
      type: "textArea",
      label: "episode",
      subLabel: ".bucketlist",
      value: habitData?.episode,
      placeHolder: "yaritairiyuunado.habit",
      isRequire: true,
      hasError: isNotEmpty(habitDataError?.episode),
      errorMessage: habitDataError?.episode,
    },
    {
      type: "selectField",
      label: "yuusendo",
      isRequire: true,
      value: habitData?.yuusendo,
      options: [
        <option key={Priority.KOU} value={Priority.KOU}>
          {getLabel(PriorityLabel.KOU)}
        </option>,
        <option key={Priority.CHUU} value={Priority.CHUU}>
          {getLabel(PriorityLabel.CHUU)}
        </option>,
        <option key={Priority.TEI} value={Priority.TEI}>
          {getLabel(PriorityLabel.TEI)}
        </option>,
      ],
    },
    { type: "textArea", value: habitData?.bikou, label: "bikou" },
  ];

  return (
    <ListField
      label={getLabel("button.update")}
      list={listField}
      setUpdate={setUpdate}
      setState={setHabitData}
      isDisabled={isUpdated}
      onSave={handleUpdate}
      onCancel={() => navigate(-1, { replace: true })}
    />
  );
}
