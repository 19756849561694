import { Button, Divider, Flex, Text } from "@aws-amplify/ui-react";
import { label } from "aws-amplify";
import { isMobile } from "react-device-detect";
import { LabelM as MobileLabelM, PCLabelM } from "../ui-components";
import ButtonMemo from "./ButtonMemo";
import ButtonShare from "./ButtonShare";
import PrimaryButtonIcon from "./PrimaryButtonIcon";

export default function LabelM(props) {
  const children =
    props.buttonAdd || props.isShareBtn ? (
      <Flex justifyContent="space-between">
        <Text
          className="text-overflow-ellipsis"
          maxWidth="calc(100% - 300px)"
          whiteSpace="pre"
          overflow="hidden"
          display="block"
        >
          {props.label}
        </Text>
        <Flex marginRight="64px">
          {props.buttonAdd &&
            (props.isDefault ? (
              <Button
                onClick={props.buttonAdd.onClick}
                isDisabled={props.buttonAdd.isDisabled}
                margin="0"
                padding="8px 16px 8px 12px"
                {...props.buttonAdd.style}
              >
                {props.buttonAdd.label}
              </Button>
            ) : (
              <PrimaryButtonIcon
                label={props.buttonAdd.label}
                onClick={props.buttonAdd.onClick}
                isDisabled={props.buttonAdd.isDisabled}
                margin="0"
                padding="8px 16px 8px 12px"
              />
            ))}
          {!props.isInvitePage && props.isShareBtn ? (
            <ButtonShare label={props.isNonList ? props.label :props.buttonAdd.label} desUrl={props.desUrl} />
          ) : null}
          {props?.isMemoBtn ? <ButtonMemo /> : null}
        </Flex>
      </Flex>
    ) : (
      <Text
        maxWidth="calc(100% - 70px)"
        className="text-overflow-ellipsis"
        whiteSpace="pre"
        overflow="hidden"
        display="block"
      >
        {props.label}
      </Text>
    );

  if (isMobile) {
    return (
      <MobileLabelM
        margin={"0 0 16px 0"}
        width={"auto"}
        height="50px"
        overrides={{
          "View.Text[0]": {
            children: props.label,
            width: "fit-content",
            className: "text-overflow-ellipsis",
            maxWidth: "calc(100vw - 50px)",
            whiteSpace: "pre",
            overflow: "hidden",
            display: "block",
          },
        }}
        {...props}
      />
    );
  } else {
    return (
      <>
        <PCLabelM
          margin="auto"
          display="block"
          maxWidth="896px"
          className="pccomponent"
          width="100%"
          overrides={{
            "Flex.Text[0]": {
              children: children,
              width: "100%",
              className: "text-overflow-ellipsis",
              whiteSpace: "pre",
              overflow: "hidden",
              display: "block",
            },
          }}
        />
        <Divider
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          size="small"
          orientation="horizontal"
          color="#AEB3B7"
          display={props.isDivide ? "block" : "none"}
        />
      </>
    );
  }
}
