/* eslint-disable import/no-anonymous-default-export */
import { Flex, IconSave, Loader, Text } from "@aws-amplify/ui-react";
import { isMobile } from "react-device-detect";
import { ButtonCancelSave, PCButtonCancelSave } from "../ui-components";

export default function (props) {
  const { isDisabled } = props;

  const renderButton = () => (
    <Flex direction="row" alignItems="center" gap={isMobile ? "8px" : "6px"}>
      {isDisabled ? (
        <Loader size="large" />
      ) : (
        <IconSave
          width="24px"
          height="24px"
          shrink="0"
          overflow="hidden"
          position="relative"
          padding="0px 0px 0px 0px"
          color="rgba(255,255,255,1)"
          type="save"
          fontSize="24px"
        ></IconSave>
      )}
      <Text
        fontSize= {isMobile ? "14px": "16px"}
        fontWeight="700"
        color={isDisabled ? "#5C6670" : "white"}
        lineHeight="18px"
        textAlign="left"
        display="flex"
        direction="column"
        justifyContent="flex-start"
        letterSpacing="0.4px"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        children={isDisabled ? "保存中" : "保存"}
      />
    </Flex>
  );

  if (isMobile) {
    return (
      <ButtonCancelSave
        width="fit-content"
        margin="32px auto"
        overrides={{
          "Flex.Flex[0]": {
            className: "pointer",
            onClick: props.cancelOnClick,
          },
          "Flex.Flex[1]": {
            className: "pointer",
            onClick: isDisabled ? null : props.saveOnClick,
            backgroundColor: isDisabled ? "#EFF0F0" : "var(--my-theme-color)",
          },
          "Flex.Flex[1].Text[0]": {
            children: renderButton(),
          },
          "Flex.Flex[1].IconSave[0]": {
            display: "none",
          },
        }}
      />
    );
  } else {
    return (
      <PCButtonCancelSave
        margin="32px auto 0 auto"
        width="fit-content"
        overrides={{
          "Flex.Flex[0]": {
            className: "pointer",
            onClick: props.cancelOnClick,
          },
          "Flex.Flex[1]": {
            className: "pointer",
            onClick: isDisabled ? null : props.saveOnClick,
            backgroundColor: isDisabled ? "#EFF0F0" : "var(--my-theme-color)",
          },
          "Flex.Flex[1].Text[0]": {
            children: renderButton(),
          },
          "Flex.Flex[1].IconSave[0]": {
            display: "none",
          },
        }}
      />
    );
  }
}
