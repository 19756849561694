import React, { useEffect, useState, useContext } from "react";
import { LabelM, Text, TextButton, ListTwolineA, PrimaryButton, Dialog } from "../page-components";
import Amplify, { DataStore, Storage } from "aws-amplify";
import { LeavesEvents, LeavesMyEvent } from "../models";
import { useInitializePage, getLabel } from "../utils/CommonUtils";
import { useParams } from "react-router-dom";
import { Image } from "@aws-amplify/ui-react";
import { isMobile } from "react-device-detect";
import { log } from "../utils/Logger";

/*
type LeavesEvents
  @model
  @auth(rules: [{ allow: groups, groups: ["Admin"] }, { allow: private, operations: [read] }, { allow: public, operations: [read] }]) {
  id: ID!
  name: String!
  startDate: AWSDate
  endDate: AWSDate
  date: String
  time: String
  closingDay: String
  address: String
  place: String
  mapUrl: String
  price: String
  confirmationNeeded: Boolean
  applyEnabled: Boolean
}

type LeavesMyEvent
  @model
  @auth(rules: [{ allow: owner }, { allow: private, provider: iam, operations: [read] }, { allow: groups, groups: ["Admins"], operations: [read] }]) {
  id: ID!
  LeavesEvents: LeavesEvents @hasOne
  leavesusersID: ID! @index(name: "byLeavesUsers")
  confirmed: Boolean
  applied: Boolean
  note: String
  rate: Int
}
*/
export default function () {
  const navigate = useInitializePage("event");
  let params = useParams();
  const [event, setEvent] = useState();
  const [myEvent, setMyEvent] = useState();
  const [image, setImage] = useState();

  useEffect(async () => {
    console.log(params.id);
    const data = await DataStore.query(LeavesEvents, (c) => c.id("eq", params.id));
    console.log(data);
    if (data?.length > 0) {
      setEvent(data[0]);
    }
    const myData = await DataStore.query(LeavesMyEvent, (c) => c.leavesEventsID("eq", params.id));
    console.log(myData);
    if (myData?.length > 0) {
      setMyEvent(myData[0]);
    }
    const url = await Storage.get("event/" + data[0].imagePath, { level: "public" });
    setImage(url);

    // 隠しでリセットできるようにする
    if (params.command) {
      if (params.command == "reset") {
        const updated = await DataStore.save(
          LeavesMyEvent.copyOf(myData[0], (updated) => {
            updated.confirmed = false;
          })
        );
        navigate("/event");
      }
      if (params.command == "delete") {
        await DataStore.delete(LeavesMyEvent, (c) => c.leavesEventsID("eq", params.id));
        navigate("/event");
      }
    }
  }, []);

  const confirm = async () => {
    console.log(myEvent);
    const curData = await DataStore.query(LeavesMyEvent, (c) => c.id("eq", myEvent.id));
    const updated = await DataStore.save(
      LeavesMyEvent.copyOf(curData[0], (updated) => {
        updated.confirmed = true;
      })
    );
    console.log(updated);

    setMyEvent(updated);
  };
  return (
    <div style={{ marginBottom: "32px", textAlign: "center", marginTop: isMobile ? "0px" : "32px" }}>
      {isMobile ? (
        <>
          <Image src={image} />
          <Text value={event?.name} margin="8px 0" fontWeight="700" className="ff-min" />
        </>
      ) : (
        <>
          <Image src={image} width="600px" display="flex" margin="auto" />
          <Text value={event?.name} fontSize="16px" fontWeight="700" className="ff-min pccomponent" />
        </>
      )}

      <ListTwolineA label={getLabel("kaiki")} value={event?.date} />
      <ListTwolineA
        label={getLabel("kaijou")}
        value={event?.place + "\n" + event?.address}
        buttonLabel={getLabel("mapwomiru")}
        buttonOnClick={() => window.open(event?.mapUrl, "_blank")}
      />
      <ListTwolineA label={getLabel("kaisaizikan")} value={event?.time} />
      <ListTwolineA label={getLabel("kyuukanbi")} value={event?.closingDay} />
      <ListTwolineA label={getLabel("nyuujouryou")} value={event?.price} />
      {myEvent?.confirmed ? (
        <PrimaryButton
          disabled={true}
          width={isMobile ? "auto" : "fit-content"}
          margin={isMobile ? "0 16px" : "auto"}
          label={getLabel("eventnonyuujoukakuninzumi")}
        />
      ) : (
        <PrimaryButton
          onClick={() =>
            Dialog({
              title: "イベントの入場確認",
              body: "イベント参加当日、スタッフにて操作いたします。それまで、操作しないでください。「入場を確認」を選択した場合、登録し直すことはできません。",
              okLabel: "入場を確認",
              okOnClick: () => {
                confirm();
              },
            })
          }
          width={isMobile ? "auto" : "fit-content"}
          margin={isMobile ? "0 16px" : "auto"}
          label={getLabel("eventnonyuujoukakunin")}
        />
      )}
    </div>
  );
}
