/* eslint-disable react-hooks/exhaustive-deps */
import { Flex, IconSave, Image, Loader, Text } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import PlayIcon from "../assets/image/play_circle.png";
import { ListTwolineA, TextButton } from "../ui-components";
import { getLabel, getVideoBackground } from "../utils/CommonUtils";
import { getObject } from "../utils/ShareUtils";

export default function StrageImage(props) {
  const [previewUrl, setPreviewUrl] = useState();
  const [fileUrl, setFileUrl] = useState();
  const [fileName, setFileName] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoadedThumn, setIsLoadedThum] = useState(false);
  const [isHaveThumn, setIsHaveThumn] = useState(false);
  const isEmpty = props.src ? false : true;

  useEffect(async () => {
    if (props.src) {
      const { fileName, url } = await getObject(props.src);
      if (isImage(fileName)) {
        setPreviewUrl(url);
        setFileUrl(url);
        setFileName(fileName);
        setIsLoadedThum(true);
        setIsHaveThumn(true);
      } else if (isVideo(fileName)) {
        getVideoBackground(url)
          .then((res) => {
            if (res) {
              setPreviewUrl(res);
              setFileUrl(url);
              setFileName(fileName);
              setIsHaveThumn(true);
            }
            setIsLoadedThum(true);
          })
          .catch((err) => {
            setFileUrl(url);
            setFileName(fileName);
            setIsLoadedThum(true);
            setIsHaveThumn(false);
          });
      } else {
        setFileUrl(url);
        setFileName(fileName);
        setIsLoadedThum(true);
      }
    }
    setIsLoaded(true);
  }, [props.src]);

  const onClick = (event) => {
    console.log(event);
    const a = document.createElement("a");
    a.target = "_blank";
    a.href = fileUrl;
    a.download = fileName || "download";
    a.click();
  };

  const isVideo = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "m4v":
      case "avi":
      case "mp4":
      case "mov":
      case "mpg":
      case "mpeg":
        return true;
    }
    return false;
  };
  const isImage = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "png":
      case "jpg":
      case "jpeg":
      case "gif":
      case "bmp":
        return true;
    }
    return false;
  };
  const getExtension = (filename) => {
    if (filename) {
      var parts = filename.split(".");
      return parts[parts.length - 1];
    } else {
      return "";
    }
  };
  const getFileName = (filename) => {
    var parts = filename.split("/");
    return parts[parts.length - 1];
  };
  return (
    isLoaded && (
      <div
        style={{
          textAlign: props.isSmall ? "" : "center",
          width: props.isSmall ? "fit-content" : "",
          position: "relative",
        }}
        className="pointer"
        onClick={isEmpty ? null : onClick}
      >
        {(previewUrl || !isLoadedThumn) && !isEmpty ? (
          <>
            {!isHaveThumn ? (
              <Flex
                minWidth="343px"
                minHeight="229px"
                marginLeft="16px"
                marginRight="16px"
                width="auto"
                height="auto"
                position="relative"
                backgroundColor="#E8E8E8"
              />
            ) : (
              <Image
                margin="auto"
                {...(props.isSmall
                  ? {
                      paddingLeft: "0px",
                      marginLeft: "12px",
                      paddingRight: "0px",
                      width: "56px",
                      height: "56px",
                      overflow: "hidden",
                      objectFit: "cover",
                    }
                  : {
                      paddingLeft:"16px",
                      paddingRight:"16px",
                      width:"auto",
                      height:"auto",
                    })
                }
                shrink="0"
                position="relative"
                src={previewUrl}
              ></Image>
            )}
            {(isVideo(fileName) || !isLoadedThumn) && (
              <Flex
                width="100%"
                height="100%"
                position="absolute"
                top="0"
                justifyContent="center"
                alignItems="center"
              >
                {!isLoadedThumn ? (
                  <Loader width="50px" height="50px" />
                ) : (
                  <Image alt="" src={PlayIcon} width="50px" height="50px" />
                )}
              </Flex>
            )}
          </>
        ) : isMobile ? (
          <ListTwolineA
            width={"auto"}
            overrides={{
              "Flex.Flex[0].Text[0]": {
                children: getLabel("tenpufile"),
              },
              "Flex.Flex[0].Text[1]": {
                children: isEmpty ? (
                  getLabel("list.select")
                ) : (
                  <Flex
                    margin="auto"
                    gap="0px"
                    alignItems="flex-start"
                    paddingTop="8px"
                    width="100%"
                    direction="row"
                  >
                    <IconSave marginTop="3px" />
                    <TextButton
                      className="pointer"
                      width="fit-content"
                      padding="0"
                      marginLeft="8px"
                      onClick={props.onClick}
                      overrides={{
                        "Flex.Text[0]": {
                          className: "word-break",
                          children: fileName,
                          width: "100%",
                          textAlign: "justify",
                        },
                      }}
                    />
                  </Flex>
                ),
                textAlign: "justify",
                className: "word-break",
                width: "fit-content",
                maxWidth: "100vw",
              },
            }}
          />
        ) : (
          <Text
            textAlign="justify"
            className="word-break"
            width="fit-content"
            children={
              isEmpty ? (
                getLabel("list.select")
              ) : (
                <Flex
                  margin="auto"
                  gap="0px"
                  alignItems="flex-start"
                  paddingTop="8px"
                  width="100%"
                  direction="row"
                >
                  <IconSave marginTop="3px" />
                  <TextButton
                    className="pointer"
                    width="fit-content"
                    padding="0"
                    marginLeft="8px"
                    onClick={props.onClick}
                    overrides={{
                      "Flex.Text[0]": {
                        className: "word-break",
                        children: fileName,
                        width: "100%",
                        textAlign: "justify",
                      },
                    }}
                  />
                </Flex>
              )
            }
          />
        )}
      </div>
    )
  );
}
