/* eslint-disable import/no-anonymous-default-export */
import { Flex, Radio, RadioGroupField, Text } from "@aws-amplify/ui-react";
import { DataStore } from "aws-amplify";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { LeavesKazokuShinzoku } from "../models";
import { DialogButton, TextButton } from "../ui-components";
import UserContext from "../UserContext";
import { preventBodyScrollMobile, getLabel } from "../utils/CommonUtils";

export default function (props) {
  const context = useContext(UserContext);
  const navigate = useNavigate();

  const [dialog, setDialog] = useState(false);
  const selected = useRef();
  const listfld = useRef();
  const [friendList, setFriendList] = useState([]);
  // const [selected, setSelected] = useState();
  const [selectedFriend, setSelectedFriend] = useState(getLabel("list.select"));
  const firstRender = useRef(false);

  useEffect(async () => {
    const modelData = await DataStore.query(LeavesKazokuShinzoku);
    setFriendList(modelData.filter((d) => d.mailaddress));
  }, []);
  useEffect(() => {
    if (props.selected) {
      setSelectedFriend(friendList.filter((f) => props.selected == f.id).map((f) => f.shimei));
      // setSelected(props.value);
      selected.current = { value: props.selected };
    } else {
      selected.current = { value: "" };
    }
  }, [props.selected]);

  useEffect(() => {
    if (firstRender.current) {
       preventBodyScrollMobile(dialog);
    } else firstRender.current = true;
  }, [dialog]);

  const reset = (value) => {
    if (value) {
      setSelectedFriend(friendList.filter((f) => value == f.id).map((f) => f.shimei));
      selected.current = { value: value };
    } else {
      setSelectedFriend(getLabel("list.select"));
      selected.current = { value: "" };
    }
  };

  const complete = () => {
    if (selected.current.value == "") {
      setSelectedFriend(getLabel("list.select"));
    } else {
      setSelectedFriend(friendList.filter((f) => selected.current.value == f.id).map((f) => f.shimei));
    }
    if (props.onComplete) {
      props.onComplete(selected.current.value);
    }
    setDialog(false);
  };

  const ContactAddressList = useCallback(
    (props) => {
      return (
        <>
          <Text fontWeight="700" fontSize="16px" children={getLabel("soushinnsakiwosentaku")} marginBottom="8px" />
          <Text fontSize="14px" marginBottom="8px" textAlign="left">
            {getLabel("familiescontactaddressfield.description1")}
            <font
              className="pointer"
              style={{ color: "var(--my-theme-color)" }}
              onClick={() => {
                navigate("/data/contact/family");
              }}
            >
              {getLabel("renrakusakilist")}
            </font>
            {getLabel("familiescontactaddressfield.description2")}
          </Text>
          <div padding="0 8px" className="custom-checkbox-area" ref={listfld}>
            <RadioGroupField
              defaultValue={selected.current?.value}
              paddingBottom="16px"
              margin="2px"
              name="familylist"
              onChange={(e) => {
                selected.current = e.target;
                // change(e.target.value);
              }}
            >
              <Radio value="">{getLabel("list.select")}</Radio>
              {friendList.map((f, i) => {
                return (
                  <Radio key={i} value={f.id}>
                    {f.shimei}
                  </Radio>
                );
              })}
            </RadioGroupField>
          </div>

          <DialogButton
            margin="10px auto"
            overrides={{
              DefaultButton: {
                className: "pointer",
              },
              Button: {
                children: getLabel("dialog.back"),
                onClick: () => {
                  setDialog(false);
                  reset(props.selected);
                },
                onFocus: () => {
                  setDialog(false);
                  reset(props.selected);
                },
              },
              PrimaryButton: {
                className: "pointer",
                onClick: () => complete(),
              },
            }}
          />
        </>
      );
    },
    [selected, friendList]
  );
  const Dialog = useCallback(
    (props) => {
      return (
        <div className="react-confirm-alert">
          <div className="react-confirm-alert-overlay react-confirm-alert-overlay-custom">
            <div className="custom-ui">
              <Flex
                width="280px"
                display={dialog ? "block" : "none"}
                boxShadow="0px 15px 12px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.25)"
                padding="8px 23px"
                direction="column"
                backgroundColor="rgba(255,255,255,1)"
              >
                <ContactAddressList {...props} />
              </Flex>
            </div>
          </div>
        </div>
      );
    },
    [dialog]
  );
  return (
    <Flex
      className={
        isMobile ? "" : props.className ? props.className : "pccomponent"
      }
      direction="column"
      padding={isMobile ? "16px 16px 0px" : "0"}
      gap="0"
    >
      <Text fontSize={isMobile ? "14px" : "16px"}>{props.label}</Text>
      <Text
        color="#5c6670"
        fontSize={isMobile ? "14px" : "16px"}
        children={selectedFriend}
      />
      <TextButton
        className="pointer"
        onClick={() => {
          setDialog(true);
        }}
        overrides={{
          "Flex.Text[0]": {
            fontSize: isMobile ? "14px" : "16px",
            children: getLabel("renrakusakilistkarasentaku"),
          },
        }}
      />
      {dialog ? <Dialog {...props} /> : null}
    </Flex>
  );
}
