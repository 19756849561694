/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Divider, Flex, Image, Text, View } from "@aws-amplify/ui-react";
export default function PCMenu(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="0"
      direction="row"
      width="377px"
      height="791px"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "PCMenu")}
    >
      <Divider
        height="unset"
        shrink="0"
        alignSelf="stretch"
        backgroundColor="rgba(232,232,232,1)"
        size="small"
        orientation="vertical"
        {...getOverrideProps(overrides, "Divider")}
      ></Divider>
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        grow="1"
        shrink="1"
        basis="0"
        alignSelf="stretch"
        position="relative"
        padding="50px 0px 0px 0px"
        backgroundColor="rgba(251,249,246,1)"
        {...getOverrideProps(overrides, "Content")}
      >
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "MenuList")}
        >
          <View
            width="unset"
            height="1px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(232,232,232,1)"
            {...getOverrideProps(overrides, "Rectangle 1143596515753")}
          ></View>
          <Flex
            gap="16px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="12px 25px 12px 25px"
            {...getOverrideProps(overrides, "AccountList")}
          >
            <Image
              width="21px"
              height="21px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              {...getOverrideProps(overrides, "Account")}
            ></Image>
            <Text
              fontFamily="Zen Kaku Gothic New"
              fontSize="17px"
              fontWeight="500"
              color="rgba(28,26,29,1)"
              lineHeight="31px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="アカウント管理"
              {...getOverrideProps(
                overrides,
                "\u30A2\u30AB\u30A6\u30F3\u30C8\u7BA1\u7406"
              )}
            ></Text>
            <Image
              width="14px"
              height="14px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              {...getOverrideProps(overrides, "NextA")}
            ></Image>
          </Flex>
          <Flex
            gap="0"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "ShareList")}
          >
            <View
              width="unset"
              height="1px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(232,232,232,1)"
              {...getOverrideProps(overrides, "Rectangle 1143596515759")}
            ></View>
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="12px 25px 12px 25px"
              {...getOverrideProps(overrides, "ShareContent")}
            >
              <Image
                width="21px"
                height="21px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                {...getOverrideProps(overrides, "Share")}
              ></Image>
              <Text
                fontFamily="Zen Kaku Gothic New"
                fontSize="17px"
                fontWeight="500"
                color="rgba(28,26,29,1)"
                lineHeight="31px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="家族にノートを共有"
                {...getOverrideProps(
                  overrides,
                  "\u5BB6\u65CF\u306B\u30CE\u30FC\u30C8\u3092\u5171\u6709"
                )}
              ></Text>
              <Image
                width="14px"
                height="14px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                {...getOverrideProps(overrides, "NextS")}
              ></Image>
            </Flex>
          </Flex>
          <Flex
            gap="0"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "NoteList")}
          >
            <View
              width="unset"
              height="1px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(232,232,232,1)"
              {...getOverrideProps(overrides, "Rectangle 1143677118091")}
            ></View>
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="12px 25px 12px 25px"
              {...getOverrideProps(overrides, "NoteContent")}
            >
              <Image
                width="21px"
                height="21px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                {...getOverrideProps(overrides, "Note")}
              ></Image>
              <Text
                fontFamily="Zen Kaku Gothic New"
                fontSize="17px"
                fontWeight="500"
                color="rgba(28,26,29,1)"
                lineHeight="31px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="共有されたノート一覧"
                {...getOverrideProps(
                  overrides,
                  "\u5171\u6709\u3055\u308C\u305F\u30CE\u30FC\u30C8\u4E00\u89A7"
                )}
              ></Text>
              <Image
                width="14px"
                height="14px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                {...getOverrideProps(overrides, "NextN")}
              ></Image>
            </Flex>
          </Flex>
          <Flex
            gap="0"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "FileList")}
          >
            <View
              width="unset"
              height="1px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(232,232,232,1)"
              {...getOverrideProps(overrides, "Rectangle 1143596515765")}
            ></View>
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="12px 25px 12px 25px"
              {...getOverrideProps(overrides, "FileContent")}
            >
              <Image
                width="21px"
                height="21px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                {...getOverrideProps(overrides, "File596515767")}
              ></Image>
              <Text
                fontFamily="Zen Kaku Gothic New"
                fontSize="17px"
                fontWeight="500"
                color="rgba(28,26,29,1)"
                lineHeight="31px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="添付ファイル一覧"
                {...getOverrideProps(overrides, "File596515768")}
              ></Text>
              <Image
                width="14px"
                height="14px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                {...getOverrideProps(overrides, "NextF")}
              ></Image>
            </Flex>
          </Flex>
          <Flex
            gap="0"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "DashboardList")}
          >
            <View
              width="unset"
              height="1px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(232,232,232,1)"
              {...getOverrideProps(overrides, "Rectangle 1143849318093")}
            ></View>
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="12px 25px 12px 25px"
              {...getOverrideProps(overrides, "DashboardContent")}
            >
              <Image
                width="21px"
                height="21px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                {...getOverrideProps(overrides, "Dashboard")}
              ></Image>
              <Text
                fontFamily="Zen Kaku Gothic New"
                fontSize="17px"
                fontWeight="500"
                color="rgba(28,26,29,1)"
                lineHeight="31px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="管理者ダッシュボード"
                {...getOverrideProps(
                  overrides,
                  "\u7BA1\u7406\u8005\u30C0\u30C3\u30B7\u30E5\u30DC\u30FC\u30C9"
                )}
              ></Text>
              <Image
                width="14px"
                height="14px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                {...getOverrideProps(overrides, "NextD")}
              ></Image>
            </Flex>
          </Flex>
          <Flex
            gap="0"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "LogoutList")}
          >
            <View
              width="unset"
              height="1px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(232,232,232,1)"
              {...getOverrideProps(overrides, "Rectangle 1143596515771")}
            ></View>
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="12px 25px 12px 25px"
              {...getOverrideProps(overrides, "LogoutContent")}
            >
              <Image
                width="21px"
                height="21px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                {...getOverrideProps(overrides, "Logout")}
              ></Image>
              <Text
                fontFamily="Zen Kaku Gothic New"
                fontSize="17px"
                fontWeight="500"
                color="rgba(28,26,29,1)"
                lineHeight="31px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="ログアウト"
                {...getOverrideProps(
                  overrides,
                  "\u30ED\u30B0\u30A2\u30A6\u30C8"
                )}
              ></Text>
              <Image
                width="14px"
                height="14px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                {...getOverrideProps(overrides, "NextL")}
              ></Image>
            </Flex>
          </Flex>
          <View
            width="unset"
            height="1px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(232,232,232,1)"
            {...getOverrideProps(overrides, "Rectangle 1143596515776")}
          ></View>
        </Flex>
      </Flex>
    </Flex>
  );
}
