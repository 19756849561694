import { DataStore, Storage } from "aws-amplify";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { LeavesKakeizu } from "../models";
import { ListViewDetail } from "../page-components";
import { useInitializePage } from "../utils/CommonUtils";
import { isShare, listObjects, queryData } from "../utils/ShareUtils";

function FamilyTreeViewPage() {
  const navigate = useInitializePage("kakeizu", "title.detail");
  const [dataFamilyTree, setDataFamilyTree] = useState();
  const params = useParams();
  const data = useRef();
  const deleteData = useCallback(async () => {
    await DataStore.delete(data.current);
    await Storage.remove(dataFamilyTree?.imageUrl, {
      level: "private",
    });
    navigate(-1, { replace: true });
  }, [dataFamilyTree?.imageUrl, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      // const dataQuery = await DataStore.query(LeavesKakeizu, (c) => c.id("eq", params.id));
      const dataQuery = await queryData(params, LeavesKakeizu);
      if (dataQuery?.length > 0) {
        data.current = dataQuery[0];
        // const list = await Storage.list("LeavesKakeizu/" + dataQuery[0].id + "/", {
        //   level: "private",
        // });
        const list = await listObjects(params, LeavesKakeizu, dataQuery[0]);
        const url = list.length > 0 ? list[0].key : null;
        setDataFamilyTree({
          ...dataQuery[0],
          imageUrl: url,
        });
      }
    };
    getData();
  }, [params.id]);

  const listLabel = [
    { label: "filemei", value: dataFamilyTree?.filemei },
    {
      type: "image",
      value: dataFamilyTree?.imageUrl,
    },
    { label: "hokanbasyo", value: dataFamilyTree?.hokanbasyo },
    { label: "bikou", value: dataFamilyTree?.bikou, isMulti: true },
  ];

  return (
    <ListViewDetail
      label={dataFamilyTree?.filemei}
      list={listLabel}
      isHideEdit={isShare(params)}
      onDelete={deleteData}
      onEdit={() => {
        navigate("edit");
        window.gtag("event", "Change", {
          event_category: "Edit",
          event_label: "家系図変更開始",
        });
      }}
    />
  );
}

export default FamilyTreeViewPage;
