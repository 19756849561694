import React, { Component, useEffect, useState, useContext } from "react";
import { useViewPort } from "./hooks";
import { useNavigate } from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import {
  withAuthenticator,
  TextField,
  Authenticator,
  useAuthenticator,
  CheckboxField,
  RadioGroupField,
  Radio,
  Heading,
  Text,
  Button,
  View,
  AmplifyProvider,
  Flex,
} from "@aws-amplify/ui-react";
import { isMobile } from "react-device-detect";

import { vocabularies } from "./assets/amplify/vocabularies";

import { LeavesUsers } from "./models";

import Router from "./Router";

import UserContext from "./UserContext";

// import { initializeGA, useTracking } from "./utils/GoogleAnalyticsUtil";

import Amplify, { I18n, Hub, Auth, DataStore, API } from "aws-amplify";
import { log } from "./utils/Logger";

import logo from "./assets/image/loginlogo.png";

import { onUpdateLeavesUsers } from "./graphql/subscriptions";
// import { updateLeavesUsers } from "./graphql/mutations";

import { v4 as uuidv4 } from "uuid";
import Cookies from "js-cookie";

I18n.putVocabularies(vocabularies);
I18n.setLanguage("ja");

const SERVICE_POLICY = process.env.REACT_APP_SERVICE_POLICY;
const isEnableVideoCallLogin =
  process.env.REACT_APP_ENABLE_VIDEO_CALL_LOGIN === "true";

const CUSTOM_IDENTITY_FIELD = "profile";

// Amplify.Logger.LOG_LEVEL = "DEBUG";

// sessionStorage.setItem("datastoreSessionId", uuidv4());

function App() {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState({});
  const [currentUserObject, setCurrentUserObject] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  // initializeGA();

  let startDb = false;

  const updateCurrentUser = async (user) => {
    if (user) {
      // log.debug("updateCurrentUser1", user);
      setCurrentUser(user);
      return;
    }
    try {
      const user = await Auth.currentAuthenticatedUser();
      // console.log("updateCurrentUser2", user);
      const storeKey = user.attributes.website;
      // console.log("key", storeKey);
      log.debug("leaves user relationalkey", storeKey);

      let leavesUser;

      if (storeKey) {
        let leavesUsers = await DataStore.query(LeavesUsers, (c) =>
          c.id("eq", storeKey)
        );

        if (leavesUsers.length > 0) {
          log.info("find object", storeKey);
          leavesUser = leavesUsers[0];
        }
      }

      let needSetLoaded = false;
      if (!leavesUser) {
        log.info("create new", storeKey);
        await DataStore.save(
          new LeavesUsers({
            loggedin: true,
          })
        );
        const leavesUsers = await DataStore.query(LeavesUsers);
        leavesUser = leavesUsers[0];
        await Auth.updateUserAttributes(user, {
          website: leavesUser.id,
        });
        needSetLoaded = true;
      } else {
        if (!leavesUser.loggedin) {
          // loggedin を更新する場合は更新のsubscribeの受信でloadedをtrueにする
          log.info("update loggedin");
          leavesUser = await DataStore.save(
            LeavesUsers.copyOf(leavesUser, (updated) => {
              updated.loggedin = true;
            })
          );
        } else {
          needSetLoaded = true;
        }
      }

      // let leavesUsers = await DataStore.query(LeavesUsers);

      // log.debug("currentUserObject", leavesUser);
      setCurrentUser(user);
      setCurrentUserObject(leavesUser);
      if (needSetLoaded) {
        setIsLoaded(true);
      }

      return { user, leavesUser };

      // this.setState({ currentUser: currentUserObject });
    } catch (err) {
      log.error("updateCurrentUserError", err);
      setCurrentUser({});
      setCurrentUserObject({});
      setIsLoaded(true);
    }
  };

  let dsSubscription;
  const dsEventHandler = async (capsule) => {
    const {
      payload: { event, data },
    } = capsule;

    // console.log("ds event", event);
    // log.debug("ds event", event);

    if (event === "ready") {
      // console.log("DataStore is ready");
      log.info("DataStore is ready and logged in", "app");
      sessionStorage.removeItem("retry");
      startDb = true;
      const { user, leavesUser } = await updateCurrentUser();
      // console.log("leavesUser ready", leavesUser);

      // dsSubscription = DataStore.observeQuery(LeavesUsers, (p) => p.id("eq", leavesUser.id)).subscribe((msg) => {
      //   console.log(msg);
      // });
      // dsSubscription = DataStore.observe(LeavesUsers, leavesUser.id).subscribe((msg) => {
      //   console.log(msg.model, msg.opType, msg.element);
      // });
      // const dsSubscription = DataStore.observe(LeavesKaigo).subscribe((msg) => {
      //   console.log(msg.model, msg.opType, msg.element);
      // });
      dsSubscription = API.graphql({
        query: onUpdateLeavesUsers,
        variables: { owner: user.username },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      }).subscribe({
        next: ({ provider, value }) => {
          let needSetLoaded = false;
          if (value.data.onUpdateLeavesUsers.loggedin != undefined) {
            log.debug(
              "update login status.",
              value.data.onUpdateLeavesUsers.loggedin
            );
            if (!value.data.onUpdateLeavesUsers.loggedin) {
              log.debug("logout");
              Auth.signOut().then(() => {
                navigate("/", { replace: true });
              });
              return;
            } else {
              needSetLoaded = true;
            }
          }
          DataStore.query(LeavesUsers, (c) =>
            c.id("eq", value.data.onUpdateLeavesUsers.id)
          ).then((datas) => {
            log.debug("update currentUserObject.", datas[0]);
            setCurrentUserObject(datas[0]);
            if (needSetLoaded) {
              setIsLoaded(true);
            }
          });
        },
        error: (error) => console.warn(error),
      });
    }
  };

  const authEventHandler = async (capsule) => {
    const { payload } = capsule;
    log.debug("hub auth payload", payload.event);
    if (payload.event == "signIn") {
      log.info("user signIn", payload?.data?.username);
    }
    if (payload.event == "signOut") {
      log.info("user signOut", payload?.data?.username);
      await dsSubscription?.unsubscribe();
      DataStore.clear();
      Hub.remove("auth", authEventHandler);
      Hub.remove("datastore", dsEventHandler);
      // sessionStorage.setItem("datastoreSessionId", uuidv4());
    }
  };

  useEffect(async () => {
    const user = await Auth.currentAuthenticatedUser();
    log.debug("AppVer", "1.1.0");
    log.debug("userAgent", window.navigator.userAgent.toLowerCase());
    log.debug("sessionStorage", sessionStorage);

    if (window.gtag) {
      log.info("set gtag by user.", user.username);
      const now = new Date();
      const today = parseInt(
        new String(now.getFullYear()).concat(
          ("0" + (now.getMonth() + 1)).slice(-2),
          ("0" + now.getDate()).slice(-2)
        )
      );
      const ageBase = user.attributes.birthdate
        ? Math.trunc(
            (today - parseInt(user.attributes.birthdate.replaceAll("-", ""))) /
              10000
          )
        : null;
      window.gtag("set", { user_id: user.username });
      window.gtag("config", process.env.REACT_APP_GA_MEASUREMENT_ID_UA, {
        custom_map: {
          dimension1: "username",
          dimension2: "age",
          dimension3: "gender",
          dimension4: "period",
        },
        username: user.username,
        age: ageBase,
        gender: user.attributes.gender,
        period: Math.trunc(ageBase / 10) * 10,
      });
    }

    // identityidをattributesに保存
    if (user.attributes && !user.attributes[CUSTOM_IDENTITY_FIELD]) {
      await Auth.updateUserAttributes(user, {
        [CUSTOM_IDENTITY_FIELD]: (
          await Auth.currentUserCredentials()
        ).identityId,
      });
      log.info("update user attributes, set identityid. " + user.username);
    }

    // if (window.indexedDB) {
    // const dbnames = await indexedDB.databases();
    // log.debug(indexedDB.databases, dbnames);
    // if (dbnames.indexOf("amplify-datastore") && dbnames.length == 1) {
    //   var DBOpenRequest = window.indexedDB.open("amplify-datastore", 2);
    //   DBOpenRequest.onerror = function (event) {
    //     log.debug("Database connection error", event);
    //   };

    //   DBOpenRequest.onsuccess = function (event) {
    //     log.debug("Database connection success", event);
    //     const db = DBOpenRequest.result;
    //     db.addEventListener("close", () => {
    //       log.debug("Database connection closed");
    //     });
    //   };
    // }
    // }

    DataStore.start()
      .then(() => {
        log.debug("ds start!");
      })
      .catch((e) => {
        log.error("datastore start error", e);
        DataStore.stop().then(() => {
          DataStore.start();
        });
      });
    setTimeout(async () => {
      if (!startDb) {
        log.debug("Datastore is not up and running. retry start ds.");

        if (!sessionStorage.hasOwnProperty("retry")) {
          sessionStorage.setItem("datastoreSessionId", uuidv4());
          sessionStorage.setItem("retry", 1);
          log.debug("restart", sessionStorage);
          setTimeout(() => {
            if (!startDb) {
              log.debug("Datastore not yet up and running. reload.");
              window.location.reload(true);
            }
          }, 20000);
        } else {
          sessionStorage.removeItem("retry");
          setTimeout(() => {
            if (!startDb) {
              log.debug(
                "Datastore not yet up and running. signout and reload."
              );
              Auth.signOut().then(() => {
                window.location.reload(true);
              });
            }
          }, 30000);
        }

        try {
          await DataStore.stop();
        } catch (e) {
          log.debug("ds stop error", e);
        }
        DataStore.start();
      }
    }, 20000);

    Hub.remove("auth", authEventHandler);
    Hub.listen("auth", authEventHandler);

    Hub.remove("datastore", dsEventHandler);
    Hub.listen("datastore", dsEventHandler);

    return () => {
      dsSubscription.unsubscribe();
      Hub.remove("auth", authEventHandler);
      Hub.remove("datastore", dsEventHandler);
    };
  }, []);

  return (
    <UserContext.Provider
      value={{
        user: currentUser,
        leavesUser: currentUserObject,
        updateCurrentUser: updateCurrentUser,
        isLoaded: isLoaded,
      }}
    >
      <div className="App">
        <Router />
      </div>
    </UserContext.Provider>
  );
}

const Checkbox = ({ validationErrors }) => {
  const [touched, setTouched] = useState(false);

  return (
    <CheckboxField
      isRequired
      errorMessage={validationErrors.acknowledgement}
      hasError={touched && !!validationErrors.acknowledgement}
      name="acknowledgement"
      value="yes"
      onChange={() => setTouched(true)}
      label={
        <p>
          <a href={SERVICE_POLICY} target="_blank" rel="noreferrer">
            {I18n.get("termsofservice")}
          </a>
          {I18n.get("agree")}
        </p>
      }
    />
  );
};
// console.log("", withAuthenticator(App)());
function withAuth(Component) {
  let initialState = "signIn";
  if (window.location.search.startsWith("?mode=signUp")) {
    initialState = "signUp";
  }

  return function WrappedWithAuthenticator(props) {
    return (
      <Authenticator
        initialState={initialState}
        variation="modal"
        components={{
          SignUp: {
            FormFields() {
              const { validationErrors, _state } = useAuthenticator();

              const width = useViewPort();
              const breakPointPC = 1418;
              return (
                <>
                  {/* Re-use default `Authenticator.SignUp.FormFields` */}
                  <img
                    style={{ margin: "8px 0px 16px 0px", padding: "0 16px" }}
                    className="auth-logo"
                    src={logo}
                  />
                  <Authenticator.SignUp.FormFields />
                  <div>
                    <Text style={{ marginBottom: "0px" }} fontSize="12px">
                      ※パスワードは8文字以上かつ、大文字、小文字、数字、特殊文字が1文字以上含まれている必要があります。
                    </Text>
                    <Text style={{ marginTop: "0px" }} fontSize="12px">
                      【使用できる特殊文字】^ $ * . [ ] { } ( ) ? " ! @ # % & / \ , &gt; &lt; ' : ; | _ ~ ` = + - 
                    </Text>
                  </div>
                  <Flex className="amplify-field amplify-textfield">
                    <label className="amplify-label">
                      {I18n.get("seibetsu")}
                    </label>
                    <RadioGroupField
                      name="gender"
                      key="gender"
                      direction="row"
                      labelHidden
                      isRequired
                    >
                      <Radio value="man">{I18n.get("man")}</Radio>
                      <Radio value="woman">{I18n.get("woman")}</Radio>
                      <Radio value="unselected">{I18n.get("unselected")}</Radio>
                    </RadioGroupField>
                  </Flex>
                  {isMobile ? (
                    <div>
                      <TextField
                        errorMessage={validationErrors.birthdate}
                        hasError={!!validationErrors.birthdate}
                        isRequired
                        autoComplete="bday"
                        key="birthdate"
                        name="birthdate"
                        placeholder={I18n.get("seinengappi")}
                        type="text"
                        maxlength="8"
                      />
                      <font color="#5C6670" fontSize="0.8rem">
                        【入力例】2022年1月20日 → 20220120
                      </font>
                    </div>
                  ) : (
                    <TextField
                      errorMessage={validationErrors.birthdate}
                      hasError={!!validationErrors.birthdate}
                      isRequired
                      autoComplete="bday"
                      key="birthdate"
                      name="birthdate"
                      label={I18n.get("seinengappi")}
                      type="date"
                      min="1900-01-01"
                      max="9999-12-31"
                      style={{ appearance: "auto" }}
                    />
                  )}
                  <TextField
                    errorMessage={validationErrors.given_name}
                    hasError={!!validationErrors.given_name}
                    key="given_name"
                    name="given_name"
                    type="text"
                    maxlength="7"
                    isRequired
                    label={I18n.get("yuubinbangou") + "（ハイフン無し）"}
                    // placeholder={I18n.get("yuubinbangou") + "（ハイフン無し）"}
                  />
                  {/* isRequired */}
                  {/* Append & require Terms & Conditions field to sign up  */}
                  <Checkbox validationErrors={validationErrors} />
                  {/* <CheckboxField
                      errorMessage={validationErrors.acknowledgement}
                      hasError={!!validationErrors.acknowledgement}
                      name="acknowledgement"
                      value="yes"
                      label="I agree with the Terms & Conditions"
                    /> */}
                </>
              );
            },
          },
          SignIn: {
            Header() {
              return (
                <>
                  <img
                    style={{ marginTop: "32px", padding: "0 50px" }}
                    className="auth-logo"
                    src={logo}
                  />
                </>
              );
            },
            Footer() {
              // const navigate = useNavigate();
              if (
                window.location.pathname == "/hint" &&
                window.location.search.startsWith("?url=")
              ) {
                log.debug("hint page direct access.");
              } else if (
                window.location.pathname == "/event" &&
                window.location.search.startsWith("?code=")
              ) {
                log.debug("event page direct access.");
              } else if (
                window.location.pathname == "/other/invited" &&
                window.location.search.startsWith("?id=")
              ) {
                log.debug("shared page direct access.");
              } else {
                if (window.location.pathname != "/") {
                  log.debug("goto home.", window.location);
                  window.history.replaceState("", "", "/");
                  // window.location.href = "/";
                  window.location.reload();
                }
              }

              const { toResetPassword } = useAuthenticator((context) => [
                context.toResetPassword,
              ]);

              const width = useViewPort();
              const breakPointPC = 1418;
              return (
                <View
                  data-amplify-footer=""
                  style={{ top: "-20px", position: "relative" }}
                >
                  <Button
                    fontWeight="normal"
                    onClick={toResetPassword}
                    size="small"
                    variation="link"
                  >
                    パスワードをお忘れですか？
                  </Button>
                  <br />
                  <br />
                  <Button
                    fontWeight="normal"
                    onClick={() => {
                      window.location.href = "/?mode=signUp";
                    }}
                    size="small"
                    variation="link"
                  >
                    アカウント登録（無料）はこちら
                  </Button>
                </View>
              );
            },
          },
          ConfirmSignUp: {
            Header() {
              return (
                <>
                  <Heading level={4}>{I18n.get("Sending email")}</Heading>
                  <Text className="confirmSignUp">
                    {I18n.get("Code description")}
                    <br />
                    {I18n.get("Code description info")}
                  </Text>
                </>
              );
            },
          },
          VerifyUser: {
            Header() {
              return (
                <>
                  <Heading level={5}>
                    {I18n.get(
                      "Account recovery requires verified contact information"
                    )}
                  </Heading>
                </>
              );
            },
          },
          ResetPassword: {
            Header() {
              return (
                <>
                  <Heading level={4}>{I18n.get("Reset your password")}</Heading>
                </>
              );
            },
          },
          ConfirmResetPassword: {
            Header() {
              return (
                <>
                  <Heading level={4}>{I18n.get("Reset your password")}</Heading>
                </>
              );
            },
          },
        }}
        services={{
          async validateCustomSignUp(formData) {
            if (formData.birthdate) {
              const birthdate = formData.birthdate.replaceAll("-", "");
              if (birthdate.length != 8) {
                return {
                  birthdate: I18n.get("error.dateformat"),
                };
              } else {
                const nengetsuDate = new Date(
                  birthdate.slice(0, 4) +
                    "-" +
                    birthdate.slice(4, 6) +
                    "-" +
                    birthdate.slice(6, 8)
                );
                if (isNaN(nengetsuDate.getFullYear())) {
                  return {
                    birthdate: I18n.get("error.dateformat"),
                  };
                }
              }
            }
            if (formData.given_name) {
              if (formData.given_name.length == 7) {
                if (isNaN(parseInt(formData.given_name))) {
                  return {
                    given_name: I18n.get("error.postcode"),
                  };
                }
              } else {
                const postCode = formData.given_name.split("-");
                if (
                  postCode.length != 2 ||
                  isNaN(parseInt(postCode[0])) ||
                  postCode[0].length != 3 ||
                  isNaN(parseInt(postCode[1])) ||
                  postCode[1].length != 4
                ) {
                  return {
                    given_name: I18n.get("error.postcode"),
                  };
                }
              }
            }
            if (!formData.acknowledgement) {
              return {
                acknowledgement: I18n.get("error.acknowledgement"),
              };
            }
          },
          async handleSignUp(formData) {
            let { username, password, attributes } = formData;
            if (attributes.birthdate) {
              if (attributes.birthdate.length == 8) {
                attributes.birthdate =
                  attributes.birthdate.slice(0, 4) +
                  "-" +
                  attributes.birthdate.slice(4, 6) +
                  "-" +
                  attributes.birthdate.slice(6, 8);
              }
            }
            if (attributes.given_name) {
              if (attributes.given_name.length == 7) {
                attributes.given_name =
                  attributes.given_name.slice(0, 3) +
                  "-" +
                  attributes.given_name.slice(3, 7);
              }
            }

            window.gtag("event", "CompleteCreateAccount", {
              event_category: "Register",
              event_label: "アカウント作成完了",
            });

            // custom username
            username = username.toLowerCase();
            attributes.email = attributes.email.toLowerCase();
            return Auth.signUp({
              username,
              password,
              attributes,
            });
            // return Auth.rejectAuthError("signUpError");
          },
          // async handleConfirmSignUp(formData) {
          //   window.gtag("event", "Autheniticate", { event_category: "Register", event_label: "認証完了" });
          // },
          async handleSignIn(formData) {
            let { username, password } = formData;
            return Auth.signIn({
              username,
              password,
            }).catch((e) => {
              log.debug("SignInError", e);
              if (e.name === "QuotaExceededError") {
                log.debug("QuotaExceededError clear localStrage");
                window.localStorage.clear();
              } else if (e.code === "NetworkError") {
                log.debug("NetworkError clear localStrage");
                window.localStorage.clear();
              }
              throw e;
            });
          },
        }}
      >
        {(authenticator) => <Component {...props} {...authenticator} />}
      </Authenticator>
    );
  };
}
export default withAuth(App);
