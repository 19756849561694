import { DataStore } from "aws-amplify";
import { useCallback, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { LeavesCreditcard } from "../models";
import { LeavesKinyuuShisan } from "../models";
import { LeavesKouza } from "../models";
import { ListViewDetail } from "../page-components";
import { useInitializePage } from "../utils/CommonUtils";
import { isShare, queryData } from "../utils/ShareUtils";

function ClaimViewPage() {
  const navigate = useInitializePage("kouzajidouhikiotoshi", "title.detail");
  const [claimData, setClaimData] = useState({
    hikiotoshikoumoku: "",
    hikiotoshikinyuukikan: "",
    hikiotoshibi: "",
    bikou: "",
  });
  const dataRef = useRef();
  const params = useParams();

  const deleteData = useCallback(async () => {
    await DataStore.delete(dataRef.current);
    navigate(-1, { replace: true });
  }, [navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const data = await queryData(params, LeavesKouza);
      if (data?.length > 0) {
        let result = data[0];
        dataRef.current = data[0];
        if (data[0]?.hikiotoshikinyuukikan)
          result = {
            ...result,
            hikiotoshikinyuukikan: data[0]?.hikiotoshikinyuukikan,
          };
        else if (data[0]?.hikiotoshisakiKinyuuShisanYochokin) {
          const contact = await DataStore.query(LeavesKinyuuShisan, (c) =>
            c.id("eq", data[0]?.hikiotoshisakiKinyuuShisanYochokin),
          );
          if (contact.length > 0) {
            result = {
              ...result,
              hikiotoshikinyuukikan: contact[0]?.kinyuukikan,
            };
          }
        } else if (data[0]?.hikiotoshisakiCreditcard) {
          const contact = await DataStore.query(LeavesCreditcard, (c) =>
            c.id("eq", data[0]?.hikiotoshisakiCreditcard),
          );
          if (contact.length > 0) {
            result = {
              ...result,
              hikiotoshikinyuukikan: contact[0]?.creditcardmei,
            };
          }
        } else
          result = {
            ...result,
            hikiotoshikinyuukikan: null,
          };
        setClaimData(result);
      }
    };
    getData();
  }, [params.id]);


  const listLabel = [
    { label: "hikiotoshikoumoku", value: claimData?.hikiotoshikoumoku },
    { label: "hikiotoshikinyuukikan", value: claimData?.hikiotoshikinyuukikan },
    { label: "hikiotoshibi", value: claimData?.hikiotoshibi },
    {
      label: "bikou.kouzajidouhikiotoshi",
      value: claimData?.bikou,
      isMulti: true,
    },
  ];

  return (
    <ListViewDetail
      label={claimData?.hikiotoshikoumoku}
      list={listLabel}
      isHideEdit={isShare(params)}
      onDelete={deleteData}
      onEdit={() => {
        navigate("edit");
        window.gtag("event", "Change", {
          event_category: "Edit",
          event_label: "定期支払い変更開始",
        });
      }}
    />
  );
}

export default ClaimViewPage;
