import { DataStore, Storage } from "aws-amplify";
import { useCallback, useEffect, useState, useMemo } from "react";
import { LeavesYuigonshonitsuite } from "../models";
import { ListField } from "../page-components";
import {
  getLabel,
  isEmptyYuigon,
  isInvalidDateFormat,
  isNotEmptyDate,
  useInitializePage,
} from "../utils/CommonUtils";
import {
  useDatalist,
  YUIGONSHONOSHURUI,
  YUIGONSHONOUMU,
} from "../utils/DatalistUtils";
import { usePrompt } from "../utils/Prompt";

function WillEditPage() {
  const navigate = useInitializePage("yuigonshonitsuite", "title.update");
  const [data, setData] = useState();
  const [isUpdated, setIsUpdated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isEmptyData, setIsEmptyData] = useState(true);
  const [isEmptyFile, setIsEmptFile] = useState(true);

  const [yuigonshon, setYuigonshon] = useState({
    yuigonshonosaisyuusakuseibi: null,
    yuigonshikkousha: "",
    yuigonshikkoushanorenrakusaki: "",
    iraisoudanwoshiteirusenmonka: "",
    iraisoudanwoshiteirusenmonkanorenrakusaki: "",
    sonotasouzokunikansurukibounado: "",
    bikou: "",
    tenpufile: null,
    objectSrc: null,
  });

  const [seibiError, setSeibiError] = useState("");

  const [update, setUpdate] = useState(false);

  const [
    YuigonshonoumuDataList,
    setYuigonshonoumu,
    getYuigonshonoumu,
    hasErrorYuigonshonoumu,
  ] = useDatalist({
    list: YUIGONSHONOUMU,
    dataUpdateHook: setUpdate,
  });

  const [
    YuigonshonoshuruiDataList,
    setYuigonshonoshurui,
    getYuigonshonoshurui,
    hasErrorYuigonshonoshurui,
  ] = useDatalist({
    list: YUIGONSHONOSHURUI,
    dataUpdateHook: setUpdate,
  });

  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  const isEmptyCategory = useMemo(() => {
    return isEmptyData && isEmptyFile;
  }, [isEmptyData, isEmptyFile]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const dsData = await DataStore.query(LeavesYuigonshonitsuite);

      if (dsData?.length > 0) {
        setData(dsData[0]);
        setIsEmptyData(isEmptyYuigon(dsData[0]));
        setYuigonshonoumu(dsData[0].yuigonshonoumu);
        setYuigonshonoshurui(dsData[0].yuigonshonoshurui);
        setYuigonshon({
          yuigonshonosaisyuusakuseibi: dsData[0].yuigonshonosaisyuusakuseibi,
          yuigonshikkousha: dsData[0].yuigonshikkousha,
          yuigonshikkoushanorenrakusaki:
            dsData[0].yuigonshikkoushanorenrakusaki,
          iraisoudanwoshiteirusenmonka: dsData[0].iraisoudanwoshiteirusenmonka,
          iraisoudanwoshiteirusenmonkanorenrakusaki:
            dsData[0].iraisoudanwoshiteirusenmonkanorenrakusaki,
          sonotasouzokunikansurukibounado:
            dsData[0].sonotasouzokunikansurukibounado,
          bikou: dsData[0].bikou,
        });
      }
      setIsLoaded(true);
    };
    getData();
  }, []);

  useEffect(() => {
    let mount = true;
    if (data && data?.id && isLoaded) {
      Storage.list(`LeavesYuigonshonitsuite/${data?.id}/`, {
        level: "private",
      }).then((res) => {
        const url = res.length > 0 ? res[0].key : null;
        setIsEmptFile(url === null);
        if (mount)
          setYuigonshon({
            ...yuigonshon,
            tenpufile: url,
            objectSrc: url,
          });
      });
    }
    return () => (mount = false);
  }, [data, isLoaded]);

  const eventTracking = () => {
    if (isEmptyCategory) {
      window.gtag("event", "FirstWriteComplete", {
        event_category: "Edit",
        event_label: "遺言書について書込完了",
      });
    } else {
      window.gtag("event", "ChangeComplete", {
        event_category: "Edit",
        event_label: "遺言書について変更完了",
      });
    }
  };

  const updateData = useCallback(async () => {
    let valid = true;
    const dateFormatCheck = isInvalidDateFormat(
      yuigonshon.yuigonshonosaisyuusakuseibi
    );
    if (hasErrorYuigonshonoumu()) valid = false;
    if (hasErrorYuigonshonoshurui()) valid = false;
    if (dateFormatCheck) {
      valid = false;
      setSeibiError(getLabel("error.dateformat"));
    } else setSeibiError("");
    if (valid) {
      setIsUpdated(true);
      await DataStore.save(
        LeavesYuigonshonitsuite.copyOf(data, (updated) => {
          updated.yuigonshonoumu = getYuigonshonoumu();
          updated.yuigonshonoshurui = getYuigonshonoshurui();
          updated.yuigonshonosaisyuusakuseibi = isNotEmptyDate(
            yuigonshon.yuigonshonosaisyuusakuseibi,
          )
            ? yuigonshon.yuigonshonosaisyuusakuseibi
            : null;
          updated.yuigonshikkousha = yuigonshon.yuigonshikkousha;
          updated.yuigonshikkoushanorenrakusaki =
            yuigonshon.yuigonshikkoushanorenrakusaki;
          updated.iraisoudanwoshiteirusenmonka =
            yuigonshon.iraisoudanwoshiteirusenmonka;
          updated.iraisoudanwoshiteirusenmonkanorenrakusaki =
            yuigonshon.iraisoudanwoshiteirusenmonkanorenrakusaki;
          updated.sonotasouzokunikansurukibounado =
            yuigonshon.sonotasouzokunikansurukibounado;
          updated.bikou = yuigonshon.bikou;
        }),
      );
      if (yuigonshon.tenpufile !== yuigonshon.objectSrc) {
        const editData = async () => {
          const listFile = await Storage.list(
            `LeavesYuigonshonitsuite/${data?.id}/`,
            {
              level: "private",
            },
          );
          for (let i = 0; i < listFile.length; i++) {
            await Storage.remove(listFile[i].key, { level: "private" });
          }
          if (yuigonshon.tenpufile) {
            await Storage.put(
              `LeavesYuigonshonitsuite/${data?.id}/${yuigonshon.tenpufile.name}`,
              yuigonshon.tenpufile,
              {
                contentType: yuigonshon.tenpufile.type,
                level: "private",
              },
            );
          }
          eventTracking();
          navigate(-1, { replace: true });
        };
        editData();
      } else {
        eventTracking();
        navigate(-1, { replace: true });
      }
    }
  }, [
    data,
    getYuigonshonoshurui,
    getYuigonshonoumu,
    hasErrorYuigonshonoshurui,
    hasErrorYuigonshonoumu,
    navigate,
    yuigonshon.bikou,
    yuigonshon.iraisoudanwoshiteirusenmonka,
    yuigonshon.iraisoudanwoshiteirusenmonkanorenrakusaki,
    yuigonshon.objectSrc,
    yuigonshon.sonotasouzokunikansurukibounado,
    yuigonshon.tenpufile,
    yuigonshon.yuigonshikkousha,
    yuigonshon.yuigonshikkoushanorenrakusaki,
    yuigonshon.yuigonshonosaisyuusakuseibi,
  ]);

  const listField = [
    {
      type: "dataList",
      child: <YuigonshonoumuDataList label="yuigonshonoumu" />,
    },
    {
      type: "dataList",
      child: <YuigonshonoshuruiDataList label="yuigonshonoshurui" />,
    },
    {
      type: "textField",
      label: "yuigonshonosaisyuusakuseibi",
      typeInput: "date",
      value: yuigonshon.yuigonshonosaisyuusakuseibi,
      errorMessage: seibiError,
    },
    {
      type: "file",
      label: "tenpufile",
      value: yuigonshon.objectSrc,
    },
    {
      type: "textField",
      label: "yuigonshikkousha",
      value: yuigonshon.yuigonshikkousha,
      placeHolder: "jimushoyatantoushameinado",
    },
    {
      type: "textArea",
      label: "yuigonshikkoushanorenrakusaki",
      value: yuigonshon.yuigonshikkoushanorenrakusaki,
      placeHolder: "denwabangouyasiteurlnado",
    },
    {
      type: "textField",
      label: "iraisoudanwoshiteirusenmonka",
      value: yuigonshon.iraisoudanwoshiteirusenmonka,
      placeHolder: "jimushoyatantoushameinado",
    },
    {
      type: "textArea",
      label: "iraisoudanwoshiteirusenmonkanorenrakusaki",
      value: yuigonshon.iraisoudanwoshiteirusenmonkanorenrakusaki,
      placeHolder: "denwabangouyasiteurlnado",
    },
    {
      type: "textArea",
      label: "sonotasouzokunikansurukibounado",
      value: yuigonshon.sonotasouzokunikansurukibounado,
      placeHolder: "souzokunokibou",
    },
    {
      type: "textArea",
      label: "bikou",
      value: yuigonshon.bikou,
    },
  ];

  return (
    <ListField
      label={getLabel("yuigonshonitsuite")}
      list={listField}
      setState={setYuigonshon}
      setUpdate={setUpdate}
      onSave={updateData}
      onCancel={() => navigate(-1, { replace: true })}
      isDisabled={isUpdated}
    />
  );
}

export default WillEditPage;
