/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Image, Text } from "@aws-amplify/ui-react";
export default function PCCardCheck(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="12px"
      direction="column"
      width="528px"
      height="200px"
      alignItems="center"
      position="relative"
      borderRadius="3px"
      padding="32px 0px 22px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "PCCardCheck")}
    >
      <Flex
        gap="10px"
        direction="row"
        width="fit-content"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Icon")}
      >
        <Image
          width="50px"
          height="50px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Check")}
        ></Image>
      </Flex>
      <Flex
        gap="8px"
        direction="column"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Text")}
      >
        <Text
          fontFamily="Zen Kaku Gothic New"
          fontSize="17px"
          fontWeight="500"
          color="rgba(28,26,29,1)"
          lineHeight="31px"
          textAlign="center"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="これだけやっておけば安心"
          {...getOverrideProps(
            overrides,
            "\u3053\u308C\u3060\u3051\u3084\u3063\u3066\u304A\u3051\u3070\u5B89\u5FC3"
          )}
        ></Text>
        <Text
          fontFamily="Zen Kaku Gothic New"
          fontSize="30px"
          fontWeight="500"
          color="rgba(28,26,29,1)"
          lineHeight="33px"
          textAlign="center"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="CHECK!"
          {...getOverrideProps(overrides, "CHECK!")}
        ></Text>
      </Flex>
    </Flex>
  );
}
