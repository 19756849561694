/* eslint-disable import/no-anonymous-default-export */
import { API, Auth } from "aws-amplify";
import React, { useContext, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useLocation } from "react-router-dom";
import { LabelM, PrimaryButton, Text, TextButton, TextField } from "../page-components";
import { Dialog } from "../ui-components";
import UserContext from "../UserContext";
import { getLabel, isEmpty, useInitializePage } from "../utils/CommonUtils";
import { log } from "../utils/Logger";
import { usePrompt } from "../utils/Prompt";

export default function () {
  const navigate = useInitializePage("honninkakunin");
  const { state } = useLocation();
  const { user } = useContext(UserContext);

  const [code, setCode] = React.useState("");

  const [codeError, setCodeError] = useState(null);
  const [processResend, setProcessResend] = useState(false);

  // 二重送信防止用
  const [processing, setProcessing] = useState(false);

  useEffect(async () => {
    if (!state || !state.oldEmail || !state.newEmail) {
      navigate("/", { replace: true });
    }
  }, []);

  usePrompt((location, action) => {
    if (action == "POP" && location.pathname == "/other/account") {
      return true;
    } else {
      const response = window.confirm(getLabel("prompt.updateemail"));
      if (response) {
        // 検証ボタン以外での遷移はメールをもとに戻す
        log.debug("back to email", state.oldEmail);
        user.attributes.email = state.oldEmail;
        Auth.currentSession().then((session) => {
          // メールアドレスをもとに戻す
          let apiName = "AdminQueries";
          let path = "/updateUserAttributes";
          let params = {
            queryStringParameters: {},
            headers: {
              "Content-Type": "application/json",
              Authorization: session.getAccessToken().getJwtToken(),
            },
            body: {
              username: Auth.user.username,
              userAttributes: [
                {
                  Name: "email",
                  Value: state.oldEmail,
                },
                {
                  Name: "email_verified",
                  Value: "true",
                },
              ],
            },
          };
          API.post(apiName, path, params)
            .then((response) => {
              log.debug("adminUpdateAttribute success", response);
              Auth.currentAuthenticatedUser({ bypassCache: true });
            })
            .catch((e) => {
              log.debug("adminUpdateAttribute error", e);
            });
        });
        return true;
      } else {
        return false;
      }
    }
  }, true);

  const vefiryCode = async () => {
    // 二重送信防止用コード
    if (processing) return;
    setProcessing(true);
    log.debug("vefiry code " + code);
    let valid = true;
    if (isEmpty(code)) {
      setCodeError(getLabel("error.required"));
      valid = false;
    } else {
      setCodeError(null);
    }

    if (valid) {
      try {
        const response = await Auth.verifyCurrentUserAttributeSubmit(
          "email",
          code,
        );
        log.debug("verifyEmail", response);
        user.attributes.email = state.newEmail;
        navigate(-2, { replace: true });
      } catch (e) {
        log.debug("verifyEmail error", e);
        setCodeError(getLabel("error.email." + e.name));
        // 二重送信防止解除
        setProcessing(false);
      }
    } else {
      // 二重送信防止解除
      setProcessing(false);
    }
  };
  const resendVefiryCode = async () => {
    setProcessResend(true);
    const user = await Auth.currentAuthenticatedUser();
    //email_verified
    try {
      const response = await Auth.verifyUserAttribute(user, "email");
      log.debug("changeEmail", response);
      setProcessResend(false);
    } catch (e) {
      log.debug("changeEmail error", e);
      setProcessResend(false);
    }
  };

  return (
    <div>
      <LabelM label={getLabel("honninkakunin")} />
      <Text value={getLabel("honninkakunin description")} />
      <TextField
        isRequired
        type="code"
        errorMessage={codeError}
        label={getLabel("ninshoucode")}
        onChange={(event) => {
          setCode(event.target.value);
        }}
      />

      <PrimaryButton
        label={getLabel("codewoninshou")}
        onClick={() => vefiryCode()}
        isLoading={processing}
      />
      <TextButton
        label={getLabel("ninshoucodewosaisousuru")}
        isLoading={processResend}
        onClick={async () => {
          await resendVefiryCode();
          confirmAlert({
            overlayClassName: "react-confirm-alert-overlay-custom",
            customUI: ({ onClose }) => {
              return (
                <div className="custom-ui">
                  <Dialog
                    overrides={{
                      "Flex.Flex[0].Text[0]": {
                        children: getLabel("dialog.resend.title"),
                      },
                      "Flex.Flex[0].Text[1]": {
                        children:
                          state.newEmail + getLabel("dialog.resend.body"),
                      },
                      "Flex.Flex[1].DefaultButton[0]": {
                        display: "none",
                      },
                      "Flex.Flex[1].PrimaryButton[0]": {
                        className: "pointer",
                        onClick: async () => {
                          onClose();
                        },
                        overrides: {
                          "Flex.Text[0]": {
                            children: getLabel("dialog.close"),
                          },
                        },
                      },
                    }}
                  />
                </div>
              );
            },
          });
        }}
      />
      <Text textAlign="center" fontSize="16px" margin="16px auto" value={getLabel("mailgatodokanaibaai")} />
      <Text className="maxwidth600" value={getLabel("mailgatodokanaibaai description")} />
    </div>
  );
}
