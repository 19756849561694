import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, View, Text, Image } from '@aws-amplify/ui-react';
import { isMobile, isTablet } from "react-device-detect";
import {
    LabelM,
    PrimaryButton,
    MindTypeResultChart
} from "../page-components";
import awsExports from '../aws-exports';
import Amplify, { Auth, API } from 'aws-amplify';
import { getLeavesMindTypeAnswerId } from '../graphql/queries';
import Cookies from 'js-cookie';
import mobileIcon from "../assets/image/data_cardMIND.png";
import { ResponsiveContainer } from 'recharts';

export default function MindTypeResult(props) {
  const navigate = useNavigate();
  const answerId = Cookies.get('mindtypeAnswerId');

  /* パラメーター正当性チェック */
  async function checkValid() {
    const page = 5;

    /* answerIdチェック */
    if ( !answerId ) {
        return false;
    }
    else {
        // answerIdが自分のものでなければNG
        const user = await Auth.currentAuthenticatedUser();
        let result = await API.graphql({
            query: getLeavesMindTypeAnswerId,
            variables: { id: answerId }
        });

        if ( !result.data.getLeavesMindTypeAnswerId ) {
            return false;
        }
        else if( result.data.getLeavesMindTypeAnswerId.owner != user.username ) {
            return false;
        }
    }

    /* ページ回答済みチェック */
    let answeredCheckKey = "pageAnswered_" + answerId;
    let pageAnswered = Cookies.get(answeredCheckKey);

    // そもそも回答済みページがないならNG
    if ( !pageAnswered ) {
        return false;
    }

    let pageAnswerList = pageAnswered.split('_');
    let answerPageTable = {};

    pageAnswerList.forEach( (pageNum) => {
        answerPageTable[pageNum] = 1;
    });

    for ( let i=1; i <= page; i++ ) {
        if (!answerPageTable[i]) {
            // 途中回答飛ばしのページがあればNG
            return false;
        }
    }

  };

  // チェックがNGであればクッションページへ戻す
  const checkValidPromise = checkValid();
  checkValidPromise.then( (result) => {
    if (result === false) {
        window.location.href = "/mindtype";
    }
  });

  if ( isMobile || isTablet ) {
       return (
             <div>
                <LabelM label="幸福度診断テスト" />
                <View
                  width="325px"
                  height="auto"
                  position="relative"
                  boxShadow="2px 2px 6px rgba(0.30416667461395264, 0.3028993010520935, 0.3028993010520935, 0.20000000298023224)"
                  padding="0px 0px 0px 0px"
                  margin="0 auto"
                  marginBottom="-60px"
                >
                     <Flex
                       gap="10px"
                       position="absolute"
                       top="0%"
                       bottom="0%"
                       left="0%"
                       right="0%"
                       direction="row"
                       width="100%"
                       height="auto"
                       alignItems="flex-start"
                       borderRadius="3px"
                       padding="0px 20px 20px 0px"
                       backgroundColor="rgba(255,255,255,1)"
                     >
                           <Image
                             width="70px"
                             height="70px"
                             shrink="0"
                             position="relative"
                             padding="0px 0px 0px 0px"
                             src={mobileIcon}
                           ></Image>
                     </Flex>
                     <div>
                         <Text
                           fontFamily="Inter"
                           fontSize="20px"
                           fontWeight="700"
                           color="rgba(0,0,0,1)"
                           lineHeight="21px"
                           textAlign="center"
                           display="block"
                           width="100%"
                           height="78px"
                           position="relative"
                           whiteSpace="pre-wrap"
                           top="50px"
                         >
                           あなたの診断結果 
                         </Text>
                     </div>
                     <Flex
                       position="relative"
                       top="-60px"
                       left="0%"
                       right="0%"
                       direction="column"
                       width="346px"
                       height="auto"
                       alignItems="center"
                       padding="74px 20px 0px 0px"
                     >
                        <Flex
                           justifyContent="center"
                           gap="40px"
                           direction="column"
                           alignItems="center"
                           shrink="0"
                           alignSelf="stretch"
                           objectFit="cover"
                           position="relative"
                           padding="0px 0px 0px 0px"
                         >

                        <Flex
                              width="280px"
                              height="280px"
                              alignItems="center"
                              position="relative"
                              borderRadius="3px"
                              backgroundColor="rgba(255,255,255,1)"
                            >
                            <MindTypeResultChart answerId={answerId} />  
                         </Flex>

                         <Flex
                            justifyContent="center"
                            alignItems="left"
                            direction="column"
                            paddingLeft="10px"
                            paddingRight="10px"
                            position="relative"
                            marginTop="-80px"
                         >
                            <Text fontSize="14px">
                            診断結果を参考に、「人生でやりたいこと」を考えてみませんか？<br />
                            また、お悩みや気がかりなことなど、相談してみませんか？<br />
                            ホーム画面へ戻って、振り返ってみましょう。
                            </Text>
                         </Flex>

                         <PrimaryButton
                            justifyContent="center"
                            margin="0"
                            marginBottom="-20px"
                            onClick={() => navigate("/") }
                            padding="8px 12px"
                            overrides={{
                                "Flex.Text[0]": {
                                fontSize: "14px",
                                children: "ホーム画面"
                                }
                            }}
                         />

                        </Flex>
                    </Flex>
               </View>
            </div>
       );
  }
  else {
          return (
             <div className="swapper">
              <Flex justifyContent="center" alignItems="center" marginTop="32px">
                <View
                width="100%"
                display="block"
                position="relative"
                background-color="#ffffff"
                >
                    <LabelM label="幸福度診断テスト" />
                    <hr />
                    <Flex
                        justifyContent="center"
                        alignItems="center"
                        direction="column"
                    > 
                        <Text
                            margin="15px 0 30px 0"
                            style={{
                                fontSize:24,
                                fontWeight:'bold'
                            }}>あなたの診断結果</Text>

                        <Flex
                              width="700px"
                              height="400px"
                              alignItems="center"
                              position="relative"
                              backgroundColor="rgba(255,255,255,1)"
                            >
                            <MindTypeResultChart answerId={answerId} />
                         </Flex>

                         <Flex
                            justifyContent="center"
                            alignItems="left"
                            direction="column"
                            marginTop="-10px"
                            marginBottom="30px"
                            zIndex="999"
                         >
                            <Text lineHeight="10px">診断結果を参考に、「人生でやりたいこと」を考えてみませんか？</Text>
                            <Text lineHeight="10px">また、お悩みや気がかりなことなど、相談してみませんか？</Text>
                            <Text lineHeight="10px">ホーム画面へ戻って、振り返ってみましょう。</Text>
                         </Flex>

                         <PrimaryButton
                            justifyContent="center"
                            margin="0"
                            marginBottom="20px"
                            onClick={() => navigate("/") }
                            padding="8px 40px"
                            overrides={{
                                "Flex.Text[0]": {
                                fontSize: "16px",
                                children: "ホーム画面"
                                }
                            }}
                         />

                    </Flex>
                </View>
              </Flex>
            </div>
          );
  }

}
