import React, { useContext, useState, useEffect } from "react";
import { NavLink, Routes, useRoutes, Outlet, useParams, useLocation, BrowserRouter as Router } from "react-router-dom";
import UserContext from "./UserContext";
import { log } from "./utils/Logger";
import { isMobile } from "react-device-detect";
import { Flex, Text } from "@aws-amplify/ui-react";
import { Dashboard } from "./pages/admin";
import {
  HomePage,
  HowtoPage,
  NavBar,
  Header,
  MyDataPage,
  HeartPage,
  HintPage,
  EventPage,
  EventViewPage,
  LifeFuturePage,
  LifeFutureAddPage,
  LifeFutureViewPage,
  LifeFutureEditPage,
  LifePastPage,
  AssetsPage,
  RealAssetsPage,
  RealAssetsAddPage,
  RealAssetsViewPage,
  RealAssetsEditPage,
  FinancialAssetsPage,
  FinancialAssetsAddPage,
  FinancialAssetsViewPage,
  FinancialAssetsEditPage,
  InsurancePage,
  InsuranceAddPage,
  InsuranceViewPage,
  InsuranceEditPage,
  FuneralViewPage,
  FuneralEditPage,
  CareViewPage,
  CareEditPage,
  HistoryPage,
  HistoryAddPage,
  HistoryEditPage,
  HistoryViewPage,
  FamilyPage,
  FamilyAddPage,
  FamilyEditPage,
  FamilyViewPage,
  OtherPage,
  AccountInfoPage,
  ChangeEmailPage,
  ChangeEmailVerifyPage,
  ChangePasswordPage,
  CheckPage,
  CardPage,
  CardAddPage,
  CardViewPage,
  CardEditPage,
  PetPage,
  PetViewPage,
  PetEditPage,
  PetAddPage,
  MedicalEditPage,
  MedicalViewPage,
  DigitalPage,
  DevicesPage,
  DevicesAddPage,
  DevicesViewPage,
  DevicesEditPage,
  LoginIDPage,
  LoginIDAddPage,
  LoginIDViewPage,
  LoginIDEditPage,
  PensionPage,
  PensionViewPage,
  PensionEditPage,
  PensionAddPage,
  ClaimPage,
  ClaimViewPage,
  ClaimEditPage,
  ClaimAddPage,
  LifeViewPage,
  LifeEditPage,
  HealthPage,
  HealthAddPage,
  HealthViewPage,
  HealthEditPage,
  MindAndBodyPage,
  ContactPage,
  FriendsPage,
  FriendsAddPage,
  FriendsEditPage,
  FriendsViewPage,
  CollectionsPage,
  CollectionsAddPage,
  CollectionsViewPage,
  CollectionsEditPage,
  TombViewPage,
  TombEditPage,
  // RelationshipPage,
  // RelationshipAddPage,
  // RelationshipViewPage,
  // RelationshipEditPage,
  AnniversaryPage,
  AnniversaryAddPage,
  AnniversaryViewPage,
  AnniversaryEditPage,
  FamilyTreePage,
  FamilyTreeViewPage,
  FamilyTreeAddPage,
  FamilyTreeEditPage,
  WillViewPage,
  WillEditPage,
  MessagePage,
  MessageViewPage,
  MessageAddPage,
  MessageEditPage,
  InvitePage,
  InviteAddPage,
  InvitePreviewPage,
  InviteViewPage,
  InviteEditPage,
  InvitedPage,
  InvitedViewPage,
  AttachmentPage,
  InvitedDetailNotePage,
  LoanPage,
  LoanViewPage,
  LoanAddPage,
  LoanEditPage,
  SettingSharePage,
  SettingShareEditPage,
  HabitPage,
  HabitViewPage,
  HabitEditPage,
  MemoPage,
  MemoViewPage,
  MemoAddPage,
  MemoEditPage,
  MindTypePage,
  MindTypeCheck,
  MindTypeResult
} from "./pages";
import "./assets/css/loading.css"; // Import css
import logoAnime from "./assets/image/loading-common.gif";
import blank from "./assets/image/white.png";
import useBreadcrumbs, { createRoutesFromChildren, Route } from "use-react-router-breadcrumbs";
import { MODEL_NAME, MODEL_TITLE_SHARE } from "./constants";
import { VideoCallButton } from "./page-components";
import { isEnableVideoCall } from "./utils/CommonUtils";

const LeavesRouter = () => {
  const { user, isLoaded } = useContext(UserContext);
  const [isAnimatedEnd, setIsAnimatedEnd] = useState(false);

  const useLocationChange = (action) => {
    const location = useLocation();
    React.useEffect(() => {
      action(location);
    }, [location]);
  };

  useEffect(async () => {
    setTimeout(() => {
      setIsAnimatedEnd(true);
    }, 2000);
  }, []);

  const Loading2 = () => {
    return (
      <div className="loading-position">
        <div className="loadingio-spinner-ellipsis-c2saky3xck">
          <div className="ldio-tsajfowb9ik">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    );
  };

  const NoMatch = () => {
    let params = useParams();
    return (
      <div>
        <h3>
          No match for <code>{params.nomatch}</code>
        </h3>
      </div>
    );
  };

  function PrivateRoute({ component, ...rest }) {
    const breadcrumbs = useBreadcrumbs([
      {
        path: "/data/assets/real/:id",
        breadcrumb: "資産",
      },
    ]);
    //   console.log("isLoaded", isLoaded);
    useLocationChange((location) => {
      log.debug("handle route change here", location);
      if (!window.gtag) return;
      if (!process.env.REACT_APP_GA_MEASUREMENT_ID_GA4) return;
      window.gtag("config", process.env.REACT_APP_GA_MEASUREMENT_ID_GA4, {
        page_path: location.pathname,
      });
      if (!process.env.REACT_APP_GA_MEASUREMENT_ID_UA) return;
      window.gtag("config", process.env.REACT_APP_GA_MEASUREMENT_ID_UA, {
        page_path: location.pathname,
      });
    });
    if (!isLoaded) {
      return null;
    }
    return <Outlet />;
  }

  const GenerateAppRoutes = () => {
    return (
      <Route exact path="/" element={<PrivateRoute />}>
        <Route index element={<HomePage />} breadcrumb="home" />
        <Route path=":nomatch" element={<NoMatch />} />
        <Route path="admin" element={<Dashboard />} breadcrumb="dashboard" />
        <Route path="data" element={<MyDataPage />} breadcrumb="memo" />
        <Route path="data/assets" element={<AssetsPage />} breadcrumb="shisankanren" />
        <Route path="data/assets/real" element={<RealAssetsPage />} breadcrumb="jitsubutsushisan" />
        <Route path="data/assets/real/share" element={<SettingSharePage title={MODEL_TITLE_SHARE.JITSUBUTSUSHISAN} modelName={MODEL_NAME.JITSUBUTSUSHISAN} />} />
        <Route path="data/assets/real/share/:id" element={<SettingShareEditPage title={MODEL_TITLE_SHARE.JITSUBUTSUSHISAN} modelName={MODEL_NAME.JITSUBUTSUSHISAN} />} />
        <Route path="data/assets/real/:id" element={<RealAssetsViewPage />} />
        <Route path="data/assets/real/:id/edit" element={<RealAssetsEditPage />} />
        <Route path="data/assets/real/add" element={<RealAssetsAddPage />} />
        <Route path="data/assets/financial" element={<FinancialAssetsPage />} breadcrumb="kinyuushisan" />
        <Route path="data/assets/financial/share" element={<SettingSharePage title={MODEL_TITLE_SHARE.KINYUUSHISAN} modelName={MODEL_NAME.KINYUUSHISAN} />} />
        <Route path="data/assets/financial/share/:id" element={<SettingShareEditPage title={MODEL_TITLE_SHARE.KINYUUSHISAN} modelName={MODEL_NAME.KINYUUSHISAN} />} />
        <Route path="data/assets/financial/:id" element={<FinancialAssetsViewPage />} />
        <Route path="data/assets/financial/:id/edit" element={<FinancialAssetsEditPage />} />
        <Route path="data/assets/financial/add" element={<FinancialAssetsAddPage />} />
        <Route path="data/assets/insurance" element={<InsurancePage />} breadcrumb="hoken" />
        <Route path="data/assets/insurance/share" element={<SettingSharePage title={MODEL_TITLE_SHARE.HOKEN} modelName={MODEL_NAME.HOKEN} />} />
        <Route path="data/assets/insurance/share/:id" element={<SettingShareEditPage title={MODEL_TITLE_SHARE.HOKEN} modelName={MODEL_NAME.HOKEN} />} />
        <Route path="data/assets/insurance/:id" element={<InsuranceViewPage />} />
        <Route path="data/assets/insurance/:id/edit" element={<InsuranceEditPage />} />
        <Route path="data/assets/insurance/add" element={<InsuranceAddPage />} />
        <Route path="data/assets/pension" element={<PensionPage />} breadcrumb="nenkin" />
        <Route path="data/assets/pension/share" element={<SettingSharePage title={MODEL_TITLE_SHARE.NENKIN} modelName={MODEL_NAME.NENKIN} />} />
        <Route path="data/assets/pension/share/:id" element={<SettingShareEditPage title={MODEL_TITLE_SHARE.NENKIN} modelName={MODEL_NAME.NENKIN} />} />
        <Route path="data/assets/pension/:id" element={<PensionViewPage />} />
        <Route path="data/assets/pension/:id/edit" element={<PensionEditPage />} />
        <Route path="data/assets/pension/add" element={<PensionAddPage />} />
        <Route path="data/assets/claim" element={<ClaimPage />} breadcrumb="kouzajidouhikiotoshi" />
        <Route path="data/assets/claim/share" element={<SettingSharePage title={MODEL_TITLE_SHARE.KOUZA} modelName={MODEL_NAME.KOUZA} />} />
        <Route path="data/assets/claim/share/:id" element={<SettingShareEditPage title={MODEL_TITLE_SHARE.KOUZA} modelName={MODEL_NAME.KOUZA} />} />
        <Route path="data/assets/claim/:id" element={<ClaimViewPage />} />
        <Route path="data/assets/claim/:id/edit" element={<ClaimEditPage />} />
        <Route path="data/assets/claim/add" element={<ClaimAddPage />} />
        <Route path="data/assets/cards" element={<CardPage />} breadcrumb="creditcard" />
        <Route path="data/assets/cards/share" element={<SettingSharePage title={MODEL_TITLE_SHARE.CREDITCARD} modelName={MODEL_NAME.CREDITCARD} />} />
        <Route path="data/assets/cards/share/:id" element={<SettingShareEditPage title={MODEL_TITLE_SHARE.CREDITCARD} modelName={MODEL_NAME.CREDITCARD} />} />
        <Route path="data/assets/cards/:id" element={<CardViewPage />} />
        <Route path="data/assets/cards/:id/edit" element={<CardEditPage />} />
        <Route path="data/assets/cards/add" element={<CardAddPage />} />
        <Route path="data/assets/loan" element={<LoanPage />} breadcrumb="loan" />
        <Route path="data/assets/loan/share" element={<SettingSharePage title={MODEL_TITLE_SHARE.LOANKARIIREKIN} modelName={MODEL_NAME.LOANKARIIREKIN} />} />
        <Route path="data/assets/loan/share/:id" element={<SettingShareEditPage title={MODEL_TITLE_SHARE.LOANKARIIREKIN} modelName={MODEL_NAME.LOANKARIIREKIN} />} />
        <Route path="data/assets/loan/:id" element={<LoanViewPage />} />
        <Route path="data/assets/loan/add" element={<LoanAddPage />} />
        <Route path="data/assets/loan/:id/edit" element={<LoanEditPage />} />
        <Route path="data/pets" element={<PetPage />} breadcrumb="petnitsuite" />
        <Route path="data/pets/share" element={<SettingSharePage title={MODEL_TITLE_SHARE.PET} modelName={MODEL_NAME.PET} />} />
        <Route path="data/pets/share/:id" element={<SettingShareEditPage title={MODEL_TITLE_SHARE.PET} modelName={MODEL_NAME.PET} />} />
        <Route path="data/pets/:id" element={<PetViewPage />} />
        <Route path="data/pets/:id/edit" element={<PetEditPage />} />
        <Route path="data/pets/add" element={<PetAddPage />} />
        <Route path="data/digital" element={<DigitalPage />} breadcrumb="digitalkanren" />
        <Route path="data/digital/devices" element={<DevicesPage />} breadcrumb="sumahoyapcnitsuite" />
        <Route path="data/digital/devices/share" element={<SettingSharePage title={MODEL_TITLE_SHARE.SUMAHOPC} modelName={MODEL_NAME.SUMAHOPC} />} />
        <Route path="data/digital/devices/share/:id" element={<SettingShareEditPage title={MODEL_TITLE_SHARE.SUMAHOPC} modelName={MODEL_NAME.SUMAHOPC} />} />
        <Route path="data/digital/devices/add" element={<DevicesAddPage />} />
        <Route path="data/digital/devices/:id" element={<DevicesViewPage />} />
        <Route path="data/digital/devices/:id/edit" element={<DevicesEditPage />} />
        <Route path="data/digital/loginID" element={<LoginIDPage />} breadcrumb="loginID" />
        <Route path="data/digital/loginID/share" element={<SettingSharePage title={MODEL_TITLE_SHARE.LOGINID} modelName={MODEL_NAME.LOGINID} />} />
        <Route path="data/digital/loginID/share/:id" element={<SettingShareEditPage title={MODEL_TITLE_SHARE.LOGINID} modelName={MODEL_NAME.LOGINID} />} />
        <Route path="data/digital/loginID/add" element={<LoginIDAddPage />} />
        <Route path="data/digital/loginID/:id" element={<LoginIDViewPage />} />
        <Route path="data/digital/loginID/:id/edit" element={<LoginIDEditPage />} />
        <Route path="data/mindandbody" element={<MindAndBodyPage />} breadcrumb="shinshinnokoto" />
        <Route path="data/mindandbody/life" element={<LifeViewPage />} breadcrumb="seikatsunokotonitsuite" />
        <Route path="data/mindandbody/life/share" element={<SettingSharePage title={MODEL_TITLE_SHARE.SEIKATSUNOKOTO} modelName={MODEL_NAME.SEIKATSUNOKOTO} />} />
        <Route path="data/mindandbody/life/share/:id" element={<SettingShareEditPage title={MODEL_TITLE_SHARE.SEIKATSUNOKOTO} modelName={MODEL_NAME.SEIKATSUNOKOTO} />} />
        <Route path="data/mindandbody/life/edit" element={<LifeEditPage />} />
        <Route path="data/mindandbody/health" element={<HealthPage />} breadcrumb="kenkoukanrinitsuite" />
        <Route path="data/mindandbody/health/share" element={<SettingSharePage title={MODEL_TITLE_SHARE.KENKOUKANRI} modelName={MODEL_NAME.KENKOUKANRI} />} />
        <Route path="data/mindandbody/health/share/:id" element={<SettingShareEditPage title={MODEL_TITLE_SHARE.KENKOUKANRI} modelName={MODEL_NAME.KENKOUKANRI} />} />
        <Route path="data/mindandbody/health/add" element={<HealthAddPage />} />
        <Route path="data/mindandbody/health/:id" element={<HealthViewPage />} />
        <Route path="data/mindandbody/health/:id/edit" element={<HealthEditPage />} />
        <Route path="data/contact" element={<ContactPage />} breadcrumb="renrakusakikanren" />
        <Route path="data/contact/friends" element={<FriendsPage />} breadcrumb="yuujinchijin" />
        <Route path="data/contact/friends/share" element={<SettingSharePage title={MODEL_TITLE_SHARE.YUUJINCHIJIN} modelName={MODEL_NAME.YUUJINCHIJIN} />} />
        <Route path="data/contact/friends/share/:id" element={<SettingShareEditPage title={MODEL_TITLE_SHARE.YUUJINCHIJIN} modelName={MODEL_NAME.YUUJINCHIJIN} />} />
        <Route path="data/contact/friends/add" element={<FriendsAddPage />} />
        <Route path="data/contact/friends/:id" element={<FriendsViewPage />} />
        <Route path="data/contact/friends/:id/edit" element={<FriendsEditPage />} />
        <Route path="data/contact/family" element={<FamilyPage />} breadcrumb="kazokushinzokuichiran" />
        <Route path="data/contact/family/share" element={<SettingSharePage title={MODEL_TITLE_SHARE.KAZOKUSHINZOKU} modelName={MODEL_NAME.KAZOKUSHINZOKU} />} />
        <Route path="data/contact/family/share/:id" element={<SettingShareEditPage title={MODEL_TITLE_SHARE.KAZOKUSHINZOKU} modelName={MODEL_NAME.KAZOKUSHINZOKU} />} />
        <Route path="data/contact/family/add" element={<FamilyAddPage />} />
        <Route path="data/contact/family/:id" element={<FamilyViewPage />} />
        <Route path="data/contact/family/:id/edit" element={<FamilyEditPage />} />
        <Route path="data/anniversary" element={<AnniversaryPage />} breadcrumb="daijinishitaikinenbi" />
        <Route path="data/anniversary/share" element={<SettingSharePage title={MODEL_TITLE_SHARE.DAIJINISHITAIKINENBI} modelName={MODEL_NAME.DAIJINISHITAIKINENBI} />} />
        <Route path="data/anniversary/share/:id" element={<SettingShareEditPage title={MODEL_TITLE_SHARE.DAIJINISHITAIKINENBI} modelName={MODEL_NAME.DAIJINISHITAIKINENBI} />} />
        <Route path="data/anniversary/add" element={<AnniversaryAddPage />} />
        <Route path="data/anniversary/:id" element={<AnniversaryViewPage />} />
        <Route path="data/anniversary/:id/edit" element={<AnniversaryEditPage />} />
        <Route path="will" element={<HeartPage />} breadcrumb="heart" />
        <Route path="will/funeral" element={<FuneralViewPage />} breadcrumb="souginitsuite" />
        <Route path="will/funeral/share" element={<SettingSharePage title={MODEL_TITLE_SHARE.SOUGI} modelName={MODEL_NAME.SOUGI} />} />
        <Route path="will/funeral/share/:id" element={<SettingShareEditPage title={MODEL_TITLE_SHARE.SOUGI} modelName={MODEL_NAME.SOUGI} />} />
        <Route path="will/funeral/edit" element={<FuneralEditPage />} />
        <Route path="will/care" element={<CareViewPage />} breadcrumb="kaigonitsuite" />
        <Route path="will/care/share" element={<SettingSharePage title={MODEL_TITLE_SHARE.KAIGO} modelName={MODEL_NAME.KAIGO} />} />
        <Route path="will/care/share/:id" element={<SettingShareEditPage title={MODEL_TITLE_SHARE.KAIGO} modelName={MODEL_NAME.KAIGO} />} />
        <Route path="will/care/edit" element={<CareEditPage />} />
        <Route path="will/medical" element={<MedicalViewPage />} breadcrumb="iryousochinitsuite" />
        <Route path="will/medical/share" element={<SettingSharePage title={MODEL_TITLE_SHARE.IRYOUSOCHI} modelName={MODEL_NAME.IRYOUSOCHI} />} />
        <Route path="will/medical/share/:id" element={<SettingShareEditPage title={MODEL_TITLE_SHARE.IRYOUSOCHI} modelName={MODEL_NAME.IRYOUSOCHI} />} />
        <Route path="will/medical/edit" element={<MedicalEditPage />} />
        <Route path="will/tomb" element={<TombViewPage />} breadcrumb="hakanitsuite" />
        <Route path="will/tomb/share" element={<SettingSharePage title={MODEL_TITLE_SHARE.HAKANITSUITE} modelName={MODEL_NAME.HAKANITSUITE} />} />
        <Route path="will/tomb/share/:id" element={<SettingShareEditPage title={MODEL_TITLE_SHARE.HAKANITSUITE} modelName={MODEL_NAME.HAKANITSUITE} />} />
        <Route path="will/tomb/edit" element={<TombEditPage />} />
        <Route path="will/will" element={<WillViewPage />} breadcrumb="yuigonshonitsuite" />
        <Route path="will/will/share" element={<SettingSharePage title={MODEL_TITLE_SHARE.YUIGONSHONITSUITE} modelName={MODEL_NAME.YUIGONSHONITSUITE} />} />
        <Route path="will/will/share/:id" element={<SettingShareEditPage title={MODEL_TITLE_SHARE.YUIGONSHONITSUITE} modelName={MODEL_NAME.YUIGONSHONITSUITE} />} />
        <Route path="will/will/edit" element={<WillEditPage />} />
        <Route path="will/message" element={<MessagePage />} breadcrumb="taisetsunishitaihitohenomessage" />
        <Route path="will/message/share" element={<SettingSharePage title={MODEL_TITLE_SHARE.MESSAGE} modelName={MODEL_NAME.MESSAGE} />} />
        <Route path="will/message/share/:id" element={<SettingShareEditPage title={MODEL_TITLE_SHARE.MESSAGE} modelName={MODEL_NAME.MESSAGE} />} />
        <Route path="will/message/:id" element={<MessageViewPage />} />
        <Route path="will/message/add" element={<MessageAddPage />} />
        <Route path="will/message/:id/edit" element={<MessageEditPage />} />
        <Route path="will/bucketlist" element={<LifeFuturePage />} breadcrumb="yaritaikoto" />
        <Route path="will/bucketlist/share" element={<SettingSharePage title={MODEL_TITLE_SHARE.LIFEFUTURE} modelName={MODEL_NAME.LIFEFUTURE} />} />
        <Route path="will/bucketlist/share/:id" element={<SettingShareEditPage title={MODEL_TITLE_SHARE.LIFEFUTURE} modelName={MODEL_NAME.LIFEFUTURE} />} />
        <Route path="will/bucketlist/add" element={<LifeFutureAddPage />} />
        <Route path="will/bucketlist/:id" element={<LifeFutureViewPage />} />
        <Route path="will/bucketlist/:id/edit" element={<LifeFutureEditPage />} />
        <Route path="will/tobelist" element={<HabitPage />} breadcrumb="shuukan" />
        <Route path="will/tobelist/share" element={<SettingSharePage title={MODEL_TITLE_SHARE.LIFEFUTURE} modelName={MODEL_NAME.LIFEFUTURE} />} />
        <Route path="will/tobelist/share/:id" element={<SettingShareEditPage title={MODEL_TITLE_SHARE.LIFEFUTURE} modelName={MODEL_NAME.LIFEFUTURE} />} />
        <Route path="will/tobelist/add" element={<LifeFutureAddPage />} breadcrumb="shuukan" />
        <Route path="will/tobelist/:id" element={<HabitViewPage />} breadcrumb="shuukan" />
        <Route path="will/tobelist/:id/edit" element={<HabitEditPage />} breadcrumb="shuukan" />
        <Route path="memo" element={<MemoPage />} breadcrumb="memo" />
        <Route path="memo/:id" element={<MemoViewPage />} breadcrumb="memo" />
        <Route path="memo/add" element={<MemoAddPage />} breadcrumb="memo" />
        <Route path="memo/:id/edit" element={<MemoEditPage />} breadcrumb="memo" />
        <Route path="history" element={<LifePastPage />} breadcrumb="lifepast" />
        <Route path="history/history" element={<HistoryPage />} breadcrumb="jibunshi" />
        <Route path="history/history/share" element={<SettingSharePage title={MODEL_TITLE_SHARE.JIBUNSHI} modelName={MODEL_NAME.JIBUNSHI} />} />
        <Route path="history/history/share/:id" element={<SettingShareEditPage title={MODEL_TITLE_SHARE.JIBUNSHI} modelName={MODEL_NAME.JIBUNSHI} />} />
        <Route path="history/history/add" element={<HistoryAddPage />} />
        <Route path="history/history/:id" element={<HistoryViewPage />} />
        <Route path="history/history/:id/edit" element={<HistoryEditPage />} />
        <Route path="history/collections" element={<CollectionsPage />} breadcrumb="takaramonolistcollection" />
        <Route path="history/collections/share" element={<SettingSharePage title={MODEL_TITLE_SHARE.TAKARAMONO} modelName={MODEL_NAME.TAKARAMONO} />} />
        <Route path="history/collections/share/:id" element={<SettingShareEditPage title={MODEL_TITLE_SHARE.TAKARAMONO} modelName={MODEL_NAME.TAKARAMONO} />} />
        <Route path="history/collections/add" element={<CollectionsAddPage />} />
        <Route path="history/collections/:id" element={<CollectionsViewPage />} />
        <Route path="history/collections/:id/edit" element={<CollectionsEditPage />} />
        <Route path="history/familytree" element={<FamilyTreePage />} breadcrumb="kakeizu" />
        <Route path="history/familytree/share" element={<SettingSharePage title={MODEL_TITLE_SHARE.KAKEIZU} modelName={MODEL_NAME.KAKEIZU} />} />
        <Route path="history/familytree/share/:id" element={<SettingShareEditPage title={MODEL_TITLE_SHARE.KAKEIZU} modelName={MODEL_NAME.KAKEIZU} />} />
        <Route path="history/familytree/:id" element={<FamilyTreeViewPage />} />
        <Route path="history/familytree/add" element={<FamilyTreeAddPage />} />
        <Route path="history/familytree/:id/edit" element={<FamilyTreeEditPage />} />
        <Route path="hint" element={<HintPage />} breadcrumb="hint" />
        <Route path="event" element={<EventPage />} breadcrumb="hint" />
        <Route path="event/:id" element={<EventViewPage />} breadcrumb="event" />
        <Route path="event/:id/:command" element={<EventViewPage />} />
        <Route path="check" element={<CheckPage />} breadcrumb="check" />
        <Route path="howto" element={<HowtoPage />} breadcrumb="howto" />
        <Route path="other" element={<OtherPage />} breadcrumb="other" />
        <Route path="other/account" element={<AccountInfoPage />} breadcrumb="accountjouhou" />
        <Route path="other/account/email" element={<ChangeEmailPage />} breadcrumb="mailaddresswohenkou" />
        <Route path="other/account/email/verify" element={<ChangeEmailVerifyPage />} breadcrumb="ninshoukodowosoushin" />
        <Route path="other/account/password" element={<ChangePasswordPage />} breadcrumb="passwordwohenkou" />
        <Route path="other/invite" element={<InvitePage />} breadcrumb="invite" /> {/**TODO: */}
        <Route path="other/invite/add" element={<InviteAddPage />} breadcrumb="inviteadd" /> {/**TODO: */}
        <Route path="other/invite/add/:id" element={<InvitePreviewPage />} breadcrumb="invitepreview" /> {/**TODO: */}
        <Route path="other/invite/:id" element={<InviteViewPage />} breadcrumb="inviteview" /> {/**TODO: */}
        <Route path="other/invite/:id/edit" element={<InviteEditPage />} breadcrumb="inviteedit" /> {/**TODO: */}
        <Route path="other/invited" element={<InvitedPage />} breadcrumb="inivited" /> {/**TODO: */}
        <Route path="other/invited/:id" element={<InvitedViewPage />} breadcrumb="inivitedview" /> {/**TODO: */}
        <Route path="other/invited/:id/detail" element={<InvitedDetailNotePage />} breadcrumb="inivitedviewnote" /> {/**TODO: */}
        <Route path="other/invited/:shareid/real" element={<RealAssetsPage />} breadcrumb="inivitedviewnote" />
        <Route path="other/invited/:shareid/real/:id" element={<RealAssetsViewPage />} />
        <Route path="other/invited/:shareid/financial" element={<FinancialAssetsPage />} breadcrumb="inivitedviewnote" />
        <Route path="other/invited/:shareid/financial/:id" element={<FinancialAssetsViewPage />} />
        <Route path="other/invited/:shareid/insurance" element={<InsurancePage />} breadcrumb="inivitedviewnote" />
        <Route path="other/invited/:shareid/insurance/:id" element={<InsuranceViewPage />} />
        <Route path="other/invited/:shareid/cards" element={<CardPage />} breadcrumb="inivitedviewnote" />
        <Route path="other/invited/:shareid/cards/:id" element={<CardViewPage />} />
        <Route path="other/invited/:shareid/claim" element={<ClaimPage />} breadcrumb="inivitedviewnote" />
        <Route path="other/invited/:shareid/claim/:id" element={<ClaimViewPage />} />
        <Route path="other/invited/:shareid/pension" element={<PensionPage />} breadcrumb="inivitedviewnote" />
        <Route path="other/invited/:shareid/pension/:id" element={<PensionViewPage />} />
        <Route path="other/invited/:shareid/loginID" element={<LoginIDPage />} breadcrumb="inivitedviewnote" />
        <Route path="other/invited/:shareid/loginID/:id" element={<LoginIDViewPage />} />
        <Route path="other/invited/:shareid/devices" element={<DevicesPage />} breadcrumb="inivitedviewnote" />
        <Route path="other/invited/:shareid/devices/:id" element={<DevicesViewPage />} />
        <Route path="other/invited/:shareid/family" element={<FamilyPage />} breadcrumb="inivitedviewnote" />
        <Route path="other/invited/:shareid/family/:id" element={<FamilyViewPage />} />
        <Route path="other/invited/:shareid/friends" element={<FriendsPage />} breadcrumb="inivitedviewnote" />
        <Route path="other/invited/:shareid/friends/:id" element={<FriendsViewPage />} />
        <Route path="other/invited/:shareid/health" element={<HealthPage />} breadcrumb="inivitedviewnote" />
        <Route path="other/invited/:shareid/health/:id" element={<HealthViewPage />} />
        <Route path="other/invited/:shareid/life" element={<LifeViewPage />} breadcrumb="inivitedviewnote" />
        <Route path="other/invited/:shareid/anniversary" element={<AnniversaryPage />} breadcrumb="inivitedviewnote" />
        <Route path="other/invited/:shareid/anniversary/:id" element={<AnniversaryViewPage />} />
        <Route path="other/invited/:shareid/pets" element={<PetPage />} breadcrumb="inivitedviewnote" />
        <Route path="other/invited/:shareid/pets/:id" element={<PetViewPage />} />
        <Route path="other/invited/:shareid/bucketlist" element={<LifeFuturePage />} breadcrumb="inivitedviewnote" />
        <Route path="other/invited/:shareid/bucketlist/:id" element={<LifeFutureViewPage />} />
        <Route path="other/invited/:shareid/tobelist" element={<HabitPage />} breadcrumb="inivitedviewnote" />
        <Route path="other/invited/:shareid/tobelist/:id" element={<HabitViewPage />} />
        <Route path="other/invited/:shareid/message" element={<MessagePage />} breadcrumb="inivitedviewnote" />
        <Route path="other/invited/:shareid/message/:id" element={<MessageViewPage />} />
        <Route path="other/invited/:shareid/care" element={<CareViewPage />} breadcrumb="inivitedviewnote" />
        <Route path="other/invited/:shareid/medical" element={<MedicalViewPage />} breadcrumb="inivitedviewnote" />
        <Route path="other/invited/:shareid/will" element={<WillViewPage />} breadcrumb="inivitedviewnote" />
        <Route path="other/invited/:shareid/funeral" element={<FuneralViewPage />} breadcrumb="inivitedviewnote" />
        <Route path="other/invited/:shareid/tomb" element={<TombViewPage />} breadcrumb="inivitedviewnote" />
        <Route path="other/invited/:shareid/history" element={<HistoryPage />} breadcrumb="inivitedviewnote" />
        <Route path="other/invited/:shareid/history/:id" element={<HistoryViewPage />} />
        <Route path="other/invited/:shareid/collections" element={<CollectionsPage />} breadcrumb="inivitedviewnote" />
        <Route path="other/invited/:shareid/collections/:id" element={<CollectionsViewPage />} />
        <Route path="other/invited/:shareid/familytree" element={<FamilyTreePage />} breadcrumb="inivitedviewnote" />
        <Route path="other/invited/:shareid/familytree/:id" element={<FamilyTreeViewPage />} />
        <Route path="other/invited/:shareid/loan" element={<LoanPage />} breadcrumb="inivitedviewnote" />
        <Route path="other/invited/:shareid/loan/:id" element={<LoanViewPage />} />
        <Route path="other/files" element={<AttachmentPage />} breadcrumb="files" /> {/**TODO: */}
        <Route path="mindtype" element={<MindTypePage />} breadcrumb="mindtype" />
        <Route path="mindtype/check" element={<MindTypePage />} breadcrumb="mindtype" />
        <Route path="mindtype/check/:page" element={<MindTypeCheck />} breadcrumb="mindtype" />
        <Route path="mindtype/result" element={<MindTypeResult />} breadcrumb="mindtype" />
      </Route>
    );
  };

  const useBreadcrumb = () => {
    const AppRoutes = GenerateAppRoutes();
    const appRouteObjects = createRoutesFromChildren(AppRoutes);
    return useBreadcrumbs(appRouteObjects);
  };
  const AppRoutes = () => {
    const AppRoutes = GenerateAppRoutes();
    const appRouteObjects = createRoutesFromChildren(AppRoutes);
    const GeneratedRoutes = useRoutes(appRouteObjects);
    return GeneratedRoutes;
  };

  return (
    <>
      <div>
        <div>
          <Header breadcrumb={useBreadcrumb} />
          <AppRoutes />
        </div>
      </div>
      <NavBar />
      <div
        className="loading-screen loading-transition"
        style={{ opacity: isLoaded && isAnimatedEnd ? "0" : "1", zIndex: isLoaded && isAnimatedEnd ? "-10" : "100" }}
      >
        <img src={logoAnime} height={isMobile ? "75px" : "75px"} />
      </div>
    </>
  );
};

export default LeavesRouter;
