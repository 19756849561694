import { PCToDoListA, ToDoListA as TodoList } from "../ui-components";
import emptyTodoImage from "../assets/image/todo_empty.png";
import { isMobile } from "react-device-detect";
import { Flex } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import { getUrlImage } from "../utils/CommonUtils";
export default function TodoListA(props) {
  const { label, imageSrc, selected, ...rest } = props;
  const [src, setSrc] = useState("");

  useEffect(() => {
    const getImage = async () => {
      const url = await getUrlImage(imageSrc);
      setSrc(url);
    };
    getImage();
  }, [imageSrc]);

  return isMobile ? (
    <TodoList
      width="auto"
      margin="0 16px 16px 16px"
      {...rest}
      overrides={{
        Title: {
          children: label,
          className: "text-overflow-ellipsis",
          maxWidth: "calc(100vw - 176px)",
          whiteSpace: "pre",
          overflow: "hidden",
          display: "block",
        },
        Image: {
          src: src ? src : emptyTodoImage,
          overflow: "hidden",
          objectFit: "cover",
        },
        Icon: {
          paths: [
            {
              d: "M9.99984 6L8.58984 7.41L13.1698 12L8.58984 16.59L9.99984 18L15.9998 12L9.99984 6Z",
              fill: "#1C1A1D",
              fillRule: "nonzero",
            },
          ],
        },
      }}
    />
  ) : (
    <Flex display="block">
      <PCToDoListA
        className="pointer"
        margin="0"
        backgroundColor={selected && "#DFE2DC"}
        maxWidth="288px"
        width="100%"
        height="72px"
        display="flex"
        {...rest}
        overrides={{
          Image: {
            src: src ? src : emptyTodoImage,
            overflow: "hidden",
          },
          Text: {
            width: "170px",
          },
          Title: {
            children: label,
            className: "text-overflow-ellipsis",
            maxWidth: "100%",
            whiteSpace: "pre",
            overflow: "hidden",
            display: "block",
          },
        }}
      />
    </Flex>
  );
}
