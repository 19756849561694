import { DataStore } from "aws-amplify";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { LeavesKouza } from "../models";
import { ListField, TextField, WithdrawalRadio } from "../page-components";
import UserContext from "../UserContext";
import {
  getLabel,
  isEmpty,
  isNotEmpty,
  useInitializePage,
} from "../utils/CommonUtils";
import { usePrompt } from "../utils/Prompt";

function ClaimAddPage() {
  const navigate = useInitializePage("kouzajidouhikiotoshi", "title.add");
  const context = useContext(UserContext);
  const [claimData, setClaimData] = useState({
    hikiotoshikoumoku: "",
    hikiotoshikinyuukikan: "",
    hikiotoshisakiKinyuuShisanYochokin: "",
    hikiotoshisakiCreditcard: "",
    hikiotoshibi: "",
    bikou: "",
  });
  const [claimDataError, setClaimDataError] = useState({
    hikiotoshikoumokuError: "",
    hikiotoshibiError: "",
    bikouError: "",
  });

  const [activeOption, setActiveOption] = useState(null);
  const [isAdded, setIsAdded] = useState(false);
  const [update, setUpdate] = useState(false);
  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  const handleChangeOption = (value) => {
    if (!value) {
      setClaimData({
        ...claimData,
        hikiotoshisakiKinyuuShisanYochokin: null,
        hikiotoshisakiCreditcard: null,
        hikiotoshikinyuukikan: null,
      });
      setActiveOption(null);
    } else if (value === "1") {
      setClaimData({
        ...claimData,
        hikiotoshisakiKinyuuShisanYochokin: null,
        hikiotoshisakiCreditcard: null,
      });
      setActiveOption(value);
    } else if (value === "2") {
      setClaimData({
        ...claimData,
        hikiotoshikinyuukikan: null,
      });
      setActiveOption(value);
    }
  };

  const handleChangeDataWithdraw = (value) => {
    setClaimData({
      ...claimData,
      hikiotoshisakiKinyuuShisanYochokin:
        value?.type === "financial" ? value?.id : null,
      hikiotoshisakiCreditcard: value?.type === "card" ? value?.id : null,
      hikiotoshikinyuukikan: null,
    });
  };

  const addData = useCallback(async () => {
    let valid = true;
    if (isEmpty(claimData.hikiotoshikoumoku)) {
      setClaimDataError({
        ...claimDataError,
        hikiotoshikoumokuError: getLabel("error.required"),
      });
      valid = false;
    }
    if (valid) {
      setIsAdded(true);
      await DataStore.save(
        new LeavesKouza({
          hikiotoshikoumoku: claimData.hikiotoshikoumoku,
          hikiotoshikinyuukikan: claimData.hikiotoshikinyuukikan,
          hikiotoshisakiKinyuuShisanYochokin:
            claimData.hikiotoshisakiKinyuuShisanYochokin,
          hikiotoshisakiCreditcard: claimData.hikiotoshisakiCreditcard,
          hikiotoshibi: claimData.hikiotoshibi,
          bikou: claimData.bikou,
          leavesusersID: context.leavesUser.id,
        }),
      );
      window.gtag("event", "FirstWriteComplete", {
        event_category: "Edit",
        event_label: "定期支払い書込完了",
      });
      navigate(-1, { replace: true });
    }
  }, [claimData, claimDataError, context.leavesUser.id, navigate]);

  const listField = [
    {
      type: "textField",
      label: "hikiotoshikoumoku",
      isRequire: true,
      placeHolder: "internetnado",
      hasError: isNotEmpty(claimDataError.hikiotoshikoumokuError),
      errorMessage: claimDataError.hikiotoshikoumokuError,
    },
    {
      type: "radioCustomGroup",
      label: "hikiotoshikinyuukikan",
      active: activeOption,
      handleChangeOption: handleChangeOption,
      options: [
        {
          value: "1",
          label: getLabel("nyuryokusuru"),
          children: (
            <TextField
              placeholder={getLabel("kinyuukikancardmeinado")}
              overrideStyle={{
                padding: 0,
                width: "100%",
                margin: 0,
              }}
              onChange={(e) => {
                setClaimData({
                  ...claimData,
                  hikiotoshikinyuukikan: e.target.value,
                  hikiotoshisakiKinyuuShisanYochokin: null,
                  hikiotoshisakiCreditcard: null,
                });
              }}
            />
          ),
        },
        {
          value: "2",
          label: getLabel("torokuzuminojohokaraerabu"),
          children: (
            <WithdrawalRadio
              selected={
                claimData?.hikiotoshisakiKinyuuShisanYochokin
                  ? claimData?.hikiotoshisakiKinyuuShisanYochokin
                  : claimData?.hikiotoshisakiCreditcard
              }
              overrideStyle={{
                padding: 0,
                width: "100%",
                margin: 0,
              }}
              onChangeWithdraw={handleChangeDataWithdraw}
            />
          ),
        },
      ],
    },
    {
      type: "textField",
      label: "hikiotoshibi",
    },
    {
      type: "textArea",
      label: "bikou",
      subLabel: ".kouzajidouhikiotoshi",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ListField
      label={getLabel("shinkitsuika")}
      list={listField}
      setState={setClaimData}
      setUpdate={setUpdate}
      onSave={addData}
      onCancel={() => navigate(-1, { replace: true })}
      isDisabled={isAdded}
    />
  );
}

export default ClaimAddPage;
