import { IconContentCopy, IconShare } from "@aws-amplify/ui-react";
import { API, DataStore } from "aws-amplify";
import { useCallback, useEffect, useState,useMemo } from "react";
import { useLocation } from "react-router-dom";
import { listLeavesSharedInfos } from "../graphql/queries";
import { LeavesShareInfo } from "../models";
import { LabelS, ListSelectContent, ListSingleLineA, ListSingleLineD, ListTwoLineButton } from "../page-components";
import { getLabel, useInitializePage } from "../utils/CommonUtils";
import { getShareMessage, generateUrl, isShareAccepted } from "../utils/ShareUtils";
import { log } from "../utils/Logger";
import { isMobile, isTablet } from "react-device-detect";
import { MODEL_NAME } from "../constants";

export default function InviteListPage() {
  const locate = useLocation();
  const { state } = locate;
  const [isLoaded, setIsLoaded] = useState(true);
  const navigate = useInitializePage("invite"); /**TODO: */
  const [listInvite, setListInvite] = useState([]);

  const [selected, setSelected] = useState(null);
  const [urlid, setUrlid] = useState({});
  const [isCopyEnable, setIsCopyEnable] = useState(false);

  const handledChangeActive = (item) => {
    setUrlid({});
    setSelected(item);
  };

  useEffect(() => {
    if (listInvite?.length > 0) {
      const item = listInvite.find((ele) => ele?.id === state?.id);
      if (item) {
        setSelected(item);
      } else {
        setSelected(listInvite[0]);
      }
    } else {
      setSelected(null);
    }
  }, [listInvite, state?.id]);


  useEffect(() => {
    if (listInvite?.length > 0) {
      const item = listInvite.find((ele) => ele?.id === state?.id);
      if (item) {
        setSelected(item);
      } else {
        setSelected(listInvite[0]);
      }
    } else {
      setSelected(null);
    }
  }, [listInvite, state?.id]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (navigator.clipboard) {
      setIsCopyEnable(true);
    }

    const getData = async () => {
      const data = await DataStore.query(LeavesShareInfo);

      if (data?.length > 0) {
        setListInvite(
          await Promise.all(
            data.map(async (d) => {
              // 招待情報取得
              // const sharedInfo = await API.graphql({
              //   query: listLeavesSharedInfos,
              //   variables: { filter: { shareInfoId: { eq: d.id } } },
              //   authMode: "AWS_IAM",
              // });
              // log.debug("get sharedInfo;" + d.id, sharedInfo.data.listLeavesSharedInfos.items);
              const isAccested = await isShareAccepted(d.id);
              const targetShare = d.target
                .filter((ele) => ele.dataName !== MODEL_NAME.HOSYOUSAIMU && ele.dataName !== MODEL_NAME.SHUUKAN)
                .sort(function (a, b) {
                  return new Date(a?.shareDate) - new Date(b?.shareDate);
                });
              const shareDateList = new Set(
                targetShare.map((ele) => ele?.shareDate)
              );
              const dateSplit = [];
              shareDateList?.forEach((date) => {
                dateSplit.push({
                  label: String(date).replaceAll("-", "/") + getLabel("nikokaisurupeji"),
                  value: targetShare
                    .filter((ele) => ele?.shareDate === date)
                    .map((t) => getLabel("Datastore." + t.dataName))
                    .join("、"),
                });
              });

              const inviteData = {
                id: d.id,
                namae: d.name,
                sutetasu: isAccested
                  ? getLabel("shotaishoninzumi")
                  : getLabel("shotaichu"),
                splitList: dateSplit,
              };

              return inviteData;
            })
          )
        );
      }
    };
    getData();
  }, []);

  const deleteData = async (selected) => {
    log.debug("delete data", selected);
    const data = await DataStore.query(LeavesShareInfo, (c) => c.id("eq", selected.id));
    await DataStore.delete(data[0]);
    const newData = await DataStore.query(LeavesShareInfo);

    if (newData?.length > 0) {
      setListInvite(
        await Promise.all(
          newData.map(async (d) => {
            // 招待情報取得
            // const sharedInfo = await API.graphql({
            //   query: listLeavesSharedInfos,
            //   variables: { filter: { shareInfoId: { eq: d.id } } },
            //   authMode: "AWS_IAM",
            // });
            // log.debug("get sharedInfo;" + d.id, sharedInfo.data.listLeavesSharedInfos.items);
            const isAccested = await isShareAccepted(d.id);

            const targetShare = d.target
                .filter((ele) => ele.dataName !== MODEL_NAME.HOSYOUSAIMU && ele.dataName !== MODEL_NAME.SHUUKAN)
                .sort(function (a, b) {
                  return new Date(a?.shareDate) - new Date(b?.shareDate);
                });
              const shareDateList = new Set(
                targetShare.map((ele) => ele?.shareDate)
              );
              const dateSplit = [];
              shareDateList?.forEach((date) => {
                dateSplit.push({
                  label: String(date).replaceAll("-", "/") + getLabel("nikokaisurupeji"),
                  value: targetShare
                    .filter((ele) => ele?.shareDate === date)
                    .map((t) => getLabel("Datastore." + t.dataName))
                    .join("、"),
                });
              });

              const inviteData = {
                id: d.id,
                namae: d.name,
                sutetasu: isAccested
                  ? getLabel("shotaishoninzumi")
                  : getLabel("shotaichu"),
                splitList: dateSplit,
              };

              return inviteData;
          })
        )
      );
    } else {
      setListInvite([]);
    }
  };

  useEffect(() => {
    if (listInvite?.length > 0) {
      setSelected(listInvite[0]);
    } else {
      setSelected(null);
    }
  }, [listInvite]);

  const generateShareUrl = useCallback(
    async (selected) => {
      if (!urlid.url) {
        const urlObj = await generateUrl(selected.id);
        setUrlid(urlObj);

        return urlObj;
      } else {
        return urlid;
      }
    },
    [selected, urlid]
  );

  const handleCopy = async (e, selected) => {
    window.gtag("event", "CopyInviteInfo", {
      event_category: "Share",
      event_label: "招待情報のコピーボタン",
    });
    e.preventDefault();
    const { url, expiredDate } = await generateShareUrl(selected);
    const text = getShareMessage(url, expiredDate, selected.namae);
    navigator.clipboard.writeText(text).then(
      function () {
        log.debug("Success copy url");
        alert(getLabel("copied"));
      },
      function (err) {
        log.debug("Could not copy text: ", err);
        alert(getLabel("copyFail"));
      }
    );
  };
  const handleShare = async (e, selected) => {
    window.gtag("event", "ShareInviteInfo", {
      event_category: "Share",
      event_label: "招待情報の共有ボタン",
    });
    e.preventDefault();
    const title = getLabel("notonokyoyu");
    const { url, expiredDate } = await generateShareUrl(selected);
    const text = getShareMessage(url, expiredDate, selected.namae);
    if (navigator.canShare) {
      log.debug("share:navigator.share");
      navigator.share({
        title: title,
        text: text,
      });
    } else {
      log.debug("share:mailto");
      window.open(`mailto:?subject=${title}&body=${text.replaceAll("\n", "%0D%0A")}`);
    }
  };

  const dataSample = useMemo(() => {
    if(selected?.splitList?.length > 0)
    return selected?.splitList?.map((ele) => ({
      label: ele?.label,
      value: ele?.value,
    }));
    else return [];
  }, [selected]); 

  return (
    <ListSelectContent
      label={getLabel("kazokuninotookyoyu")}
      description={{
        subDescription: getLabel("invite.des"),
      }}
      descriptionStyle={{ width: "100%", paddingBottom: "0" }}
      isTopButton
      mobileList={listInvite.map((ele) => {
        return (
          <ListSingleLineA
            key={ele?.id}
            label={
              ele?.sutetasu === getLabel("shotaishoninzumi") ? (
                ele?.namae
              ) : (
                <>
                  <span style={{ color: "#688649", display: "inline" }}>[{getLabel("shotaichu")}]</span>
                  {ele?.namae}
                </>
              )
            }
            onClick={() => {
              navigate(ele?.id);
            }}
          />
        );
      })}
      labelS={<LabelS label={getLabel("shotaishitakazokuichiran")} />}
      addButtonLabel={getLabel("kazokuoshotaisuru")}
      onAddClick={() => {
        navigate("add");
        window.gtag("event", "StartInvite", {
          event_category: "Share",
          event_label: "招待開始",
        });
      }}
      style={{ maxHeight: "calc(100vh - 570px)", marginTop: "20px" }}
      pcListItem={listInvite.map((ele, index) => {
        return (
          <ListSingleLineD
            key={ele?.id}
            label={
              ele?.sutetasu === getLabel("shotaishoninzumi") ? (
                ele?.namae
              ) : (
                <>
                  <span style={{ color: "#688649", display: "inline" }}>[{getLabel("shotaichu")}]</span>
                  {ele?.namae}
                </>
              )
            }
            selected={selected?.id === ele?.id}
            onClick={() => {
              handledChangeActive(ele);
            }}
          />
        );
      })}
      selectedItemList={[
        {
          isEmptyHide: true,
          label: getLabel("namae"),
          value: selected?.namae,
        },
        ...dataSample,
        {
          isEmptyHide: true,
          label: getLabel("shareStatus"),
          value: selected?.sutetasu,
        },
        selected?.sutetasu === getLabel("shotaichu") && {
          label: getLabel("inviteURL"),
          value: (
            <ListTwoLineButton
              buttons={
                isCopyEnable
                  ? [
                      {
                        label: getLabel("copy"),
                        icon: <IconContentCopy color="var(--my-theme-color)" fontSize="23px" />,
                        onClick: (e) => {
                          handleCopy(e, selected);
                        },
                      },
                      {
                        label: getLabel("share"),
                        icon: <IconShare color="var(--my-theme-color)" fontSize="23px" />,
                        onClick: (e) => {
                          handleShare(e, selected);
                        },
                      },
                    ]
                  : [
                      {
                        label: getLabel("share"),
                        icon: <IconShare color="var(--my-theme-color)" fontSize="23px" />,
                        onClick: (e) => {
                          handleShare(e, selected);
                        },
                      },
                    ]
              }
            />
          ),
        },
      ]}
      selectedItemLabel={selected?.namae}
      onDelete={() => deleteData(selected)}
      onEdit={() => {
        navigate(selected?.id + "/edit");
        window.gtag("event", "EditInviteInfo", {
          event_category: "Share",
          event_label: "招待情報編集 (Edit)",
        });
      }}
      isLoaded={isLoaded}
      isInvitePage
    />
  );
}
