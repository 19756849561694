import { DataStore } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import { KinyuuShisanshurui, LeavesKinyuuShisan } from "../models";
import {
  ButtonCancelSave,
  SelectField,
  SwapperContent,
  TextField,
  TextFieldMultiline,
} from "../page-components";
import {
  getLabel,
  isEmpty,
  isNotEmpty,
  useInitializePage,
} from "../utils/CommonUtils";
import { log } from "../utils/Logger";
import { usePrompt } from "../utils/Prompt";

/*
  shisanmei: String
  shisanshurui: Shisanshurui
  shozaibasho: String
  bikou: String
  _shisanshurui: String
  leavesusersID: ID! @index(name: "byLeavesUsers")
*/
export default function () {
  const navigate = useInitializePage("kinyuushisan", "title.update");
  const [shisan, setShisan] = useState();

  const [shisanshurui, setShisanshurui] = useState("");
  const [kinyuukikan, setKinyuukikan] = useState("");
  const [bikou, setBikou] = useState("");

  const [shisanshuruiError, setShisanshuruiError] = useState(null);
  const [kinyuukikanError, setKinyuukikanError] = useState(null);
  const [bikouError, setBikouError] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);

  const [update, setUpdate] = useState(false);
  usePrompt((location, action) => {
    if (action == "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  let params = useParams();

  useEffect(async () => {
    const dsShisan = await DataStore.query(LeavesKinyuuShisan, (c) =>
      c.id("eq", params.id),
    );
    log.debug("shisan", dsShisan);
    if (dsShisan.length > 0) {
      setShisan(dsShisan[0]);
      setShisanshurui(dsShisan[0].shisanshurui);
      setKinyuukikan(dsShisan[0].kinyuukikan);
      setBikou(dsShisan[0].bikou);
    }
  }, []);

  const updateData = async () => {
    log.debug(shisanshurui + "," + kinyuukikan + "," + bikou);
    let valid = true;
    if (isEmpty(kinyuukikan)) {
      setKinyuukikanError(getLabel("error.required"));
      valid = false;
    } else {
      setKinyuukikanError(null);
    }
    if (isEmpty(shisanshurui)) {
      setShisanshuruiError(getLabel("error.required"));
      valid = false;
    } else {
      setShisanshuruiError(null);
    }
    if (valid) {
      setIsUpdated(true);
      DataStore.save(
        LeavesKinyuuShisan.copyOf(shisan, (updated) => {
          updated.shisanshurui = shisanshurui;
          updated.kinyuukikan = kinyuukikan;
          updated.bikou = bikou;
        }),
      );
      window.gtag("event", "ChangeComplete", {
        event_category: "Edit",
        event_label: "金融資産変更完了",
      });
      navigate(-1, { replace: true });
    }
  };

  const shisanshuruiList = [];
  Object.keys(KinyuuShisanshurui).forEach((type) => {
    shisanshuruiList.push(
      <option key={type} value={type}>
        {getLabel("KinyuuShisanshurui." + type)}
      </option>,
    );
  });

  return (
    <SwapperContent label={getLabel("button.update")}>
      <SelectField
        isRequired
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("shisanshurui")}
        value={shisanshurui}
        hasError={isNotEmpty(shisanshuruiError)}
        errorMessage={shisanshuruiError}
        children={<React.Fragment>{shisanshuruiList}</React.Fragment>}
        onChange={(event) => {
          setShisanshurui(event.target.value);
          setUpdate(true);
        }}
      />
      <TextField
        isRequired
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("kinyuukikan")}
        placeHolder={getLabel("ginkousyoukengaisyanado")}
        value={kinyuukikan}
        errorMessage={kinyuukikanError}
        onChange={(event) => {
          setKinyuukikan(event.target.value);
          setUpdate(true);
        }}
      />
      <TextFieldMultiline
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("bikou")}
        value={bikou}
        errorMessage={bikouError}
        onChange={(event) => {
          setBikou(event.target.value);
          setUpdate(true);
        }}
      />
      <ButtonCancelSave
        cancelOnClick={() => navigate(-1, { replace: true })}
        saveOnClick={() => updateData()}
        isDisabled={isUpdated}
      />
    </SwapperContent>
  );
}
