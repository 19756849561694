import { CheckboxField, Flex, Grid, Image, Text } from "@aws-amplify/ui-react";
import { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { getLabel } from "../utils/CommonUtils";
import TextField from "./TextField";
import forward from "../assets/image/forward.png";

export default function CheckListShare(props) {
  const [checkedAll, setCheckedAll] = useState(false);
  const {
    list,
    onChange,
    listItemChecked,
    errorList,
    commonShareDate,
    isAddPage,
    onUpdate,
  } = props;
  const [listRender, setListRender] = useState(list);
  const listTextBoxRef = useRef([]);
  const mountRef = useRef(true);

  const [listSelected, setListSelected] = useState([]);
  const preSelect = useRef([]);

  useEffect(() => {
    if (listItemChecked.length > 0 && mountRef.current) {
      setListSelected(listItemChecked);
      if (listItemChecked?.length === list?.length) setCheckedAll(true);
      preSelect.current = listItemChecked;
      mountRef.current = false;
      const copyList = [...listRender];
      listItemChecked.forEach((ele, idx) => {
        const itemRender = listRender.find((item) => item.value === ele.value);
        if (
          itemRender &&
          commonShareDate !== ele.shareDate &&
          !isAddPage &&
          ele.shareDate
        ) {
          itemRender.shareDate = isMobile
            ? String(ele.shareDate).replaceAll("-", "")
            : ele.shareDate;
          itemRender.isExpand = true;
        } else copyList.shareDate = null;
      });
      setListRender(copyList);
    }
  }, [listItemChecked, mountRef.current]);

  const onChecked = (value, checked, idxRef) => {
    let tempList = [...listSelected];
    onUpdate();
    if (listSelected.find((ele) => ele.value === value)) {
      if (!checked) {
        tempList = tempList.filter((ele) => ele.value !== value);
      }
    } else {
      let shareDate = "";
      const valueTextBox = listTextBoxRef.current[idxRef].value;
      if (valueTextBox.length !== 8) {
        shareDate = valueTextBox + "--";
      } else {
        shareDate =
          valueTextBox.slice(0, 4) +
          "-" +
          valueTextBox.slice(4, 6) +
          "-" +
          valueTextBox.slice(6, 8);
      }
      tempList.push({
        value: value,
        shareDate: isMobile ? shareDate : valueTextBox,
      });
    }
    setListSelected(tempList);
    preSelect.current = tempList;
    if (tempList.length < list.length) {
      setCheckedAll(false);
    } else {
      setCheckedAll(true);
    }
  };

  const onChangeTextBox = (category) => (event) => {
    const copyList = [...listSelected];
    const cate = copyList.find((ele) => ele.value === category);
    if (cate) {
      cate.shareDate = event?.target.value;
      setListSelected(copyList);
    }
  };

  const onCheckedAll = (e) => {
    onUpdate();
    setCheckedAll(e.target.checked);
  };

  const isCheckedItem = (value) => {
    return listSelected.findIndex((ele) => ele.value === value) >= 0;
  };

  const onToggle = (ele) => {
    const copyList = [...listRender];
    const element = copyList.find((item) => item === ele);
    if (element) element.isExpand = !element.isExpand;
    setListRender(copyList);
  };

  useEffect(() => {
    if (checkedAll && preSelect.current.length < list.length)
      setListSelected(
        listRender.map((ele, idx) => {
          let shareDate = "";
          const valueTextBox = listTextBoxRef.current[idx].value;
          if (valueTextBox.length !== 8) {
            shareDate = valueTextBox + "--";
          } else {
            shareDate =
              valueTextBox.slice(0, 4) +
              "-" +
              valueTextBox.slice(4, 6) +
              "-" +
              valueTextBox.slice(6, 8);
          }
          return {
            value: ele.value,
            shareDate: isMobile ? shareDate : valueTextBox,
          };
        })
      );
  }, [checkedAll]);

  useEffect(() => {
    onChange && onChange(listSelected);
  }, [listSelected]);

  useEffect(() => {
    if (Object.keys(errorList).length > 0) {
      const copyList = [...listRender];
      Object.keys(errorList).forEach((ele) => {
        const element = copyList.find((item) => item.value === ele);
        if (element && errorList[ele]) element.isExpand = true;
      });
      setListRender(copyList);
    }
  }, [errorList]);

  return (
    <div
      style={
        isMobile
          ? { padding: "16px 16px 0 16px" }
          : {
              maxWidth: "800px",
              margin: "auto",
              marginBottom: "16px",
            }
      }
    >
      <Text fontSize={isMobile ? "14px" : "16px"} color="rgb(48, 64, 80)">
        {props.isRequired ? (
          <span>
            {props.label}
            <font className="required">※</font>
          </span>
        ) : (
          props.label
        )}
        {props.errorMessage && (
          <Text
            color="var(--amplify-components-fieldmessages-error-color)"
            fontSize="14px"
          >
            {props.errorMessage}
          </Text>
        )}
      </Text>
      {isMobile ? (
        <Flex margin="12px 16px 0 16px" overflow="hidden" display="block">
          <CheckboxField
            className="share-checkbox"
            label={getLabel("subetesentaku")}
            checked={checkedAll}
            onChange={onCheckedAll}
          />
          {listRender.map((ele, idx) => {
            return (
              <Flex
                key={idx}
                display="block"
                marginTop="21px"
                position="relative"
                overflow="hidden"
                style={{ zIndex: 0 }}
              >
                <Flex position="relative" height="24px" justifyContent="space-between" style={{ zIndex: 1 }}>
                  <CheckboxField
                    className="share-checkbox"
                    checked={isCheckedItem(ele.value)}
                    position="relative"
                    onChange={(e) => {
                      onChecked(ele.value, e.target.checked, idx);
                    }}
                  />
                  <Flex
                    position="relative"
                    width={"calc(100% - 32px)"}
                    height="100%"
                    justifyContent="flex-start"
                    alignItems="center"
                    onClick={() => onToggle(ele)}
                  >
                    {ele.option}
                    <Image
                      src={forward}
                      position="absolute"
                      right="7px"
                      width="7px"
                      height="11px"
                      transform={
                        ele.isExpand ? "rotate(270deg)" : "rotate(90deg)"
                      }
                    />
                  </Flex>
                </Flex>
                <TextField
                  type="date"
                  ref={(el) => (listTextBoxRef.current[idx] = el)}
                  onChange={onChangeTextBox(ele.value)}
                  errorMessage={errorList[ele.value]}
                  defaultValue={ele.shareDate}
                  placeholder={getLabel("pejikokaibi")}
                  overrideStyle={{
                    display: ele.isExpand ? "block" : "none",
                    padding: "1px",
                    paddingTop: "12px",
                    marginTop: "-23px",
                  }}
                />
              </Flex>
            );
          })}
        </Flex>
      ) : (
        <>
          <CheckboxField
            className="share-checkbox"
            label={getLabel("subetesentakusuru")}
            paddingTop="16px"
            checked={checkedAll}
            onChange={onCheckedAll}
          />
          <Grid
            width="100%"
            templateColumns="auto auto auto"
            columnGap="16px"
            rowGap="16px"
            marginTop="16px"
          >
            {listRender.map((ele, idx) => {
              return (
                <Flex
                  key={idx}
                  display="block"
                  position="relative"
                  width="100%"
                  overflow="hidden"
                  style={{ zIndex: 0 }}
                >
                <Flex position="relative" height="24px" justifyContent="space-between" style={{ zIndex: 1 }}>
                  <CheckboxField
                    className="share-checkbox"
                    checked={isCheckedItem(ele.value)}
                    position="relative"
                    onChange={(e) => {
                      onChecked(ele.value, e.target.checked, idx);
                    }}
                  />
                  <Flex
                    position="relative"
                    width={"calc(100% - 32px)"}
                    height="100%"
                    justifyContent="flex-start"
                    alignItems="center"
                    onClick={() => onToggle(ele)}
                    style={{cursor: "pointer"}}
                  >
                    {ele.option}
                    <Image
                      src={forward}
                      position="absolute"
                      right="5px"
                      width="7px"
                      height="11px"
                      transform={
                        ele.isExpand ? "rotate(270deg)" : "rotate(90deg)"
                      }
                    />
                  </Flex>
                </Flex>
                  <TextField
                    type="date"
                    ref={(el) => (listTextBoxRef.current[idx] = el)}
                    onChange={onChangeTextBox(ele.value)}
                    errorMessage={errorList[ele.value]}
                    defaultValue={ele.shareDate}
                    placeholder={getLabel("pejikokaibi")}
                    overrideStyle={{
                      display: ele.isExpand ? "block" : "none",
                      padding: "1px",
                      paddingTop: "12px",
                      className: "width100",
                      marginBottom: "0",
                    }}
                  />
                </Flex>
              );
            })}
          </Grid>
        </>
      )}
    </div>
  );
}
