/* eslint-disable import/no-anonymous-default-export */
import { DataStore } from "aws-amplify";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LeavesKazokuShinzoku } from "../models";
import {
  ListSelectContent,
  ListSingleLineA,
  ListSingleLineD,
} from "../page-components";
import { getLabel, useInitializePage } from "../utils/CommonUtils";
import { log } from "../utils/Logger";
import { isShare, queryDatas } from "../utils/ShareUtils";

/*
  shimei: String!
  zokugara: String!
  seinengappi: AWSDate
  yuubinbangou: String
  juusho: String
  denwabangou: String
  nyuuinjirenraku: Renrakushurui
  sougijirenraku: Renrakushurui
  bikou: String
*/
export default function () {
  const navigate = useInitializePage("kazokushinzokuichiran");

  const [isLoaded, setIsLoaded] = useState(false);
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState(null);
  const params = useParams();

  const handledChangeActive = (item) => {
    setSelected(item);
  };

  useEffect(() => {
    if (list?.length > 0) {
      setSelected(list[0]);
    } else {
      setSelected(null);
    }
  }, [list]);

  useEffect(() => {
    const getData = async () => {
      const dsData = await queryDatas(params, LeavesKazokuShinzoku);
      log.debug("dsData", dsData);

      setIsLoaded(true);
      setList(dsData);
    };
    getData();
  }, []);

  const deleteData = async (data) => {
    log.debug("delete data", data);
    await DataStore.delete(data);
    const newList = await DataStore.query(LeavesKazokuShinzoku);
    setList(newList);
  };
  return (
    <ListSelectContent
      label={getLabel("kazokushinzokuichiran")}
      mobileList={list.map((data, index) => {
        return (
          <ListSingleLineA
            key={data?.id}
            label={data?.shimei + "（" + data?.zokugara + "）"}
            onClick={() => navigate(data?.id)}
          />
        );
      })}
      description={{
        type: "data",
        category: "kazokushinzoku",
      }}
      isShared={isShare(params)}
      addButtonLabel={
        getLabel("kazokushinzoku") + getLabel("button.addsuffix") //**TODO: using getLabel when デバイス is defined in vocabulary */
      }
      onAddClick={() => {
        navigate("add");
        window.gtag("event", "FirstWrite", {
          event_category: "Edit",
          event_label: "家族・親族書込開始",
        });
      }}
      pcListItem={list.map((ele, index) => {
        return (
          <ListSingleLineD
            key={ele?.id}
            label={ele?.shimei + "（" + ele?.zokugara + "）"}
            selected={selected?.id === ele?.id}
            onClick={() => {
              handledChangeActive(ele);
            }}
          />
        );
      })}
      selectedItemList={[
        {
          label: getLabel("shimei"),
          value: selected?.shimei,
        },
        {
          isEmptyHide: true,
          label: getLabel("zokugara"),
          value: selected?.zokugara,
        },
        {
          isEmptyHide: true,
          type: "date",
          label: getLabel("seinengappi"),
          value: selected?.seinengappi,
        },
        {
          isEmptyHide: true,
          label: getLabel("yuubinbangou"),
          value: selected?.yuubinbangou,
        },
        {
          isEmptyHide: true,
          label: getLabel("juusho"),
          value: selected?.juusho,
        },
        {
          isEmptyHide: true,
          label: getLabel("denwabangou"),
          value: selected?.denwabangou,
        },
        {
          isEmptyHide: true,
          label: getLabel("mailaddress"),
          value: selected?.mailaddress,
        },
        {
          isEmptyHide: true,
          label: getLabel("bikou"),
          value: selected?.bikou,
        },
      ]}
      selectedItemLabel={selected?.shimei + "（" + selected?.zokugara + "）"}
      onDelete={() => deleteData(selected)}
      onEdit={() => {
        navigate(selected?.id + "/edit");
        window.gtag("event", "Change", {
          event_category: "Edit",
          event_label: "家族・親族変更開始",
        });
      }}
      isLoaded={isLoaded}
    />
  );
}
