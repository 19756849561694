/* eslint-disable import/no-anonymous-default-export */
import { isMobile } from "react-device-detect";
import {
  CategorySwapper,
  LabelM,
  ListSingleLineA,
  ListSingleLineTwoColum,
  MessageButton
} from "../page-components";
import { getLabel, useInitializePage } from "../utils/CommonUtils";

export default function () {
  const navigate = useInitializePage("memo");
  return (
    <div>
      {isMobile ? (
        <div style={{ marginBottom: "94px" }}>
          <LabelM label={getLabel("memo")} />
          <ListSingleLineA
            onClick={() => navigate("assets")}
            label={getLabel("shisankanren")}
          />
          <ListSingleLineA
            onClick={() => navigate("digital")}
            label={getLabel("digitalkanren")}
          />
          <ListSingleLineA
            onClick={() => navigate("contact")}
            label={getLabel("renrakusakikanren")}
          />
          <ListSingleLineA
            onClick={() => navigate("mindandbody")}
            label={getLabel("shinshinnokoto")}
          />
          <ListSingleLineA
            onClick={() => navigate("pets")}
            label={getLabel("petnitsuite")}
          />
          <ListSingleLineA
            onClick={() => navigate("anniversary")}
            label={getLabel("daijinishitaikinenbi")}
          />
        </div>
      ) : (
        <div className="swapper">
          <LabelM label={getLabel("memo")} isDivide />
          <div>
            <CategorySwapper label={getLabel("shisankanren")}>
              <ListSingleLineTwoColum
                onClick={() => navigate("assets/real")}
                label={getLabel("jitsubutsushisan")}
              />
              <ListSingleLineTwoColum
                onClick={() => navigate("assets/financial")}
                label={getLabel("kinyuushisan")}
              />
              <ListSingleLineTwoColum
                onClick={() => navigate("assets/insurance")}
                label={getLabel("hoken")}
              />              
              <ListSingleLineTwoColum
                onClick={() => navigate("assets/cards")}
                label={getLabel("creditcard")}
              />
              <ListSingleLineTwoColum
                onClick={() => navigate("assets/claim")}
                label={getLabel("kouzajidouhikiotoshi")}
              />
              <ListSingleLineTwoColum
                onClick={() => navigate("assets/pension")}
                label={getLabel("nenkin")}
              />
              <ListSingleLineTwoColum
                onClick={() => navigate("assets/loan")}
                label={getLabel("loankariirekin")}
              />
            </CategorySwapper>
            <CategorySwapper label={getLabel("digitalkanren")}>
              <ListSingleLineTwoColum
                onClick={() => navigate("digital/devices")}
                label={getLabel("sumahoyapcnitsuite")}
              />              
              <ListSingleLineTwoColum
                onClick={() => navigate("digital/loginID")}
                label={getLabel("kakushuserviceid")}
              />
            </CategorySwapper>

            <CategorySwapper label={getLabel("renrakusakikanren")}>
              <ListSingleLineTwoColum
                onClick={() => navigate("contact/family")}
                label={getLabel("kazokushinzokuichiran")}
              />
              <ListSingleLineTwoColum
                onClick={() => navigate("contact/friends")}
                label={getLabel("yuujinchijin")}
              />
            </CategorySwapper>

            <CategorySwapper label={getLabel("shinshinnokoto")}>
              <ListSingleLineTwoColum
                onClick={() => navigate("mindandbody/health")}
                label={getLabel("kenkoukanrinitsuite")}
              />
              <ListSingleLineTwoColum
                onClick={() => navigate("mindandbody/life")}
                label={getLabel("seikatsunokotonitsuite")}
              />
            </CategorySwapper>

            <CategorySwapper label={getLabel("sonota")}>
              <ListSingleLineTwoColum
                onClick={() => navigate("pets")}
                label={getLabel("petnitsuite")}
              />
              <ListSingleLineTwoColum
                onClick={() => navigate("anniversary")}
                label={getLabel("daijinishitaikinenbi")}
              />              
            </CategorySwapper>
          </div>
        </div>
      )}
      <MessageButton
        onClick={() => {
          window.gtag("event", "ContactMyData", {
            event_category: "Contact",
            event_label: "相談私のデータ",
          });
        }}
      />
    </div>
  );
}
