import { Button, CheckboxField, Flex, Text } from "@aws-amplify/ui-react";
import { DataStore } from "aws-amplify";
import { useCallback, useEffect, useRef, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { LeavesKazokuShinzoku, LeavesYuujinChijin } from "../models";
import { DialogButton, TextButton } from "../ui-components";
import { preventBodyScrollMobile, getLabel } from "../utils/CommonUtils";
import Description from "./Description";

const Type = {
  SELECT: "select",
  FAMILY: "family",
  FRIEND: "friend",
  EMPTY: "empty",
};

export default function FamilyAndFriendContactList(props) {
  const navigate = useNavigate();
  const [dialog, setDialog] = useState(false);
  const dialogRef = useRef();
  const [listFriend, setListFriend] = useState([]);
  const [listFamily, setListFamily] = useState([]);
  const [listData, setListData] = useState([]);
  const [selectedContact, setSelectedContact] = useState(getLabel("list.select"));
  const [dialogType, setDialogType] = useState(Type.SELECT);
  const [isLoadItem, setIsLoadItem] = useState(false);
  const firstRender = useRef(false);

  const { selectedFamily, selectedFriend, defaultValueShow, onComplete } = props;

  const handlSelect = (type) => {
    setDialogType(type);
  };

  const sortByIdWithEmail = (list) => {
    if (list) {
      return list?.filter((d) => d?.mailaddress);
    }
    return [];
  };

  useEffect(() => {
    if (dialogType === Type.FAMILY) {
      setListData(listFamily);
    } else if (dialogType === Type.FRIEND) {
      setListData(listFriend);
    } else if (dialogType === Type.SELECT) {
      setListData([]);
    }
  }, [dialogType, listFamily, listFriend]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        setDialog(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [dialogRef]);

  useEffect(() => {
    const getData = async () => {
      setIsLoadItem(true);
      const familyData = await DataStore.query(LeavesKazokuShinzoku);
      const friendData = await DataStore.query(LeavesYuujinChijin);

      setListFamily(
        sortByIdWithEmail(familyData).map((f) => {
          return {
            checked: selectedFamily?.indexOf(f?.id) >= 0,
            data: f,
          };
        })
      );
      setListFriend(
        sortByIdWithEmail(friendData).map((f) => {
          return {
            checked: selectedFriend?.indexOf(f?.id) >= 0,
            data: f,
          };
        })
      );
      setIsLoadItem(false);
    };
    getData();
  }, [selectedFamily, selectedFriend]);

  useEffect(() => {
    let listName = [];
    if (defaultValueShow) listName.push(defaultValueShow);
    if (listFamily?.length > 0) {
      listFamily?.forEach((ele) => {
        if (ele?.checked) listName?.push(ele?.data?.shimei);
      });
    }
    if (listFriend?.length > 0) {
      listFriend?.forEach((ele) => {
        if (ele?.checked) listName?.push(ele?.data?.shimei);
      });
    }
    setSelectedContact(listName.length > 0 ? listName?.join("、") : getLabel("list.select"));
  }, [defaultValueShow, listFamily, listFriend]);

  const reset = useCallback(() => {
    for (let i = 0; i < listFamily?.length; i++) {
      listFamily[i].checked = selectedFamily?.indexOf(listFamily[i]?.data?.id) >= 0;
    }
    for (let i = 0; i < listFriend?.length; i++) {
      listFriend[i].checked = selectedFriend?.indexOf(listFriend[i]?.data?.id) >= 0;
    }
  }, [listFamily, listFriend, selectedFamily, selectedFriend]);

  useEffect(() => {
    if (!dialog) {
      setDialogType(Type.SELECT);
      reset();
    }
  }, [dialog, reset]);

  useEffect(() => {
    if (firstRender.current) {
      preventBodyScrollMobile(dialog);
    } else firstRender.current = true;
  }, [dialog]);

  const change = useCallback(
    (i, checked) => {
      if (dialogType === Type.FAMILY) {
        listFamily[i].checked = checked;
      }
      if (dialogType === Type.FRIEND) {
        listFriend[i].checked = checked;
      }
    },
    [dialogType, listFamily, listFriend]
  );

  const autoSave = useCallback(() => {
    if (props.autoSave) {
      return props.autoSave();
    }
    return true;
  }, [dialogType, props]);

  const complete = useCallback(() => {
    const family = listFamily?.filter((f) => f?.checked).map((f) => f?.data?.id);
    const friend = listFriend?.filter((f) => f?.checked).map((f) => f?.data?.id);
    if (onComplete) {
      onComplete({
        family: family,
        friend: friend,
      });
    }
    setDialog(false);
  }, [listFamily, listFriend, onComplete]);

  const DialogSelect = useCallback((props) => {
    return (
      <Flex display="block" paddingBottom="19px">
        <Text fontWeight="700" marginBottom="14px">
          {getLabel("renrakusenrisutoosentaku")}
        </Text>
        <Description
          text={getLabel("renrakusenrisutoosentaku.des")}
          fontSize="14px"
          padding="0 16px 0 16px"
          width="fit-content"
          margin="auto"
        />
        <Button width="100%" maxWidth="250px" fontWeight="700" marginTop="16px" padding="6px 16px" fontSize="14px" onClick={() => handlSelect(Type.FAMILY)}>
          {getLabel("kazokushinzoku")}
        </Button>
        <Button width="100%" maxWidth="250px" fontWeight="700" marginTop="16px" padding="6px 16px" fontSize="14px" onClick={() => handlSelect(Type.FRIEND)}>
          {getLabel("yuujinchijin")}
        </Button>
      </Flex>
    );
  }, []);

  const EmptyDialog = useCallback(
    (props) => {
      return (
        <Flex display="block" paddingBottom="8px">
          <Text fontWeight="700" fontSize="16px" children={getLabel("renrakusengatorokusareteimasen")} marginBottom="8px" />
          <Text fontSize="14px" marginBottom="30px" textAlign="left" color="#5C6670">
            {getLabel("contact.detail")}
          </Text>
          <DialogButton
            margin="auto"
            overrides={{
              DefaultButton: {
                className: "pointer",
              },
              Button: {
                children: getLabel("atodesuru"),
                onClick: () => {
                  setDialogType(Type.SELECT);
                },
                onFocus: () => {},
              },
              "\u270F\uFE0F Button text": {
                children: getLabel("torokuosuru"),
                className: "pointer", 
                onClick: async () => {
                  const valid = autoSave();
                  if (valid) {
                    navigate(`/data/contact/${dialogType === Type.FAMILY ? "family" : "friends"}`, { state: { dialog: false } });
                  } else {
                    setDialog(false);
                  }
                },
              },
            }}
          />
        </Flex>
      );
    },
    [dialogType, navigate]
  );

  const ContactList = useCallback(
    (props) => {
      return (
        <Flex display="block" paddingBottom="8px">
          <Text fontWeight="700" fontSize="16px" children={getLabel("soushinnsakiwosentaku")} marginBottom="8px" />
          <Text fontSize="14px" padding="0 16px" marginBottom="8px" textAlign="left">
            {getLabel("familiescontactaddressfield.description1")}
            <font
              className="pointer"
              style={{ color: "var(--my-theme-color)" }}
              onClick={() => {
                const valid = autoSave();
                if (valid) {
                  navigate(`/data/contact/${dialogType === Type.FAMILY ? "family" : "friends"}`, { state: { dialog: false } });
                } else {
                  setDialog(false);
                }
              }}
            >
              {getLabel("renrakusakilist")}
            </font>
            {getLabel("familiescontactaddressfield.description2")}
          </Text>
          <Flex
            className="checkbox-list-swapper"
            maxHeight="300px"
            direction="column"
            margin="2px"
            name="familylist"
            width="100%"
            gap="10px"
            padding="0 16px"
            paddingBottom="16px"
          >
            {props?.list?.map((f, i) => {
              return (
                <CheckboxField
                  key={i}
                  className="checkbox-item"
                  label={f?.data?.shimei}
                  fontSize="16px"
                  onChange={(e) => {
                    change(i, e.target.checked);
                  }}
                  defaultChecked={f?.checked}
                />
              );
            })}
          </Flex>

          <DialogButton
            margin="auto"
            overrides={{
              DefaultButton: {
                className: "pointer",
              },
              Button: {
                children: getLabel("dialog.back"),
                onClick: () => {
                  setDialogType(Type.SELECT);
                  reset();
                },
              },
              PrimaryButton: {
                className: "pointer",
                onClick: () => {
                  complete();
                },
              },
            }}
          />
        </Flex>
      );
    },
    [change, complete, dialogType, navigate, reset]
  );
  const Dialog = useCallback(
    (props) => {
      return (
        <div className="react-confirm-alert">
          <div className="react-confirm-alert-overlay react-confirm-alert-overlay-custom">
            <div className="custom-ui" ref={dialogRef}>
              <Flex
                width={isMobile ? "295px" : "512px"}
                display={dialog ? "block" : "none"}
                boxShadow="0px 15px 12px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.25)"
                padding="21px 23px 0px 23px"
                direction="column"
                backgroundColor="rgba(255,255,255,1)"
              >
                {props.type === Type.SELECT && <DialogSelect {...props} />}
                {(props.type === Type.FAMILY || props.type === Type.FRIEND) &&
                  (listData?.length > 0 ? <ContactList list={listData} /> : !isLoadItem && <EmptyDialog />)}
              </Flex>
            </div>
          </div>
        </div>
      );
    },
    [dialog, isLoadItem, listData]
  );

  return (
    <Flex
      className={isMobile ? "" : props.className ? props.className : "pccomponent"}
      direction="column"
      padding={isMobile ? "16px 16px 0px" : "0"}
      gap="0"
    >
      <Text fontSize={isMobile ? "14px" : "16px"}>{props.label}</Text>
      <Text
        color="#5c6670"
        fontSize={isMobile ? "14px" : "16px"}
        textAlign="justify"
        className="word-break"
        width="fit-content"
        maxWidth="100vw"
        children={selectedContact}
      />
      <TextButton
        className="pointer"
        onClick={() => {
          setDialog(true);
        }}
        overrides={{
          "Flex.Text[0]": {
            fontSize: isMobile ? "14px" : "16px",
            children: getLabel("renrakusakilistkarasentaku"),
          },
        }}
      />
      {dialog ? <Dialog type={dialogType} {...props} /> : null}
    </Flex>
  );
}
