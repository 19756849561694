/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Divider, Flex, Icon, Image, Text, View } from "@aws-amplify/ui-react";
export default function ButtonNavigationHover(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="375px"
      height="71px"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "ButtonNavigationHover")}
    >
      <View
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        overflow="hidden"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(251,249,246,1)"
        {...getOverrideProps(overrides, "Background")}
      >
        <Flex
          gap="0"
          position="absolute"
          top="-1px"
          left="0%"
          right="0%"
          direction="row"
          width="375px"
          justifyContent="center"
          alignItems="flex-start"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Hover")}
        >
          <Flex
            gap="10px"
            direction="row"
            width="75px"
            justifyContent="center"
            alignItems="flex-start"
            grow="1"
            basis="75px"
            height="75px"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "HData")}
          >
            <Icon
              width="75px"
              height="75px"
              viewBox={{ minX: 0, minY: 0, width: 75, height: 75 }}
              paths={[
                {
                  d: "M75 37.5C75 58.2107 58.2107 75 37.5 75C16.7893 75 0 58.2107 0 37.5C0 16.7893 16.7893 0 37.5 0C58.2107 0 75 16.7893 75 37.5Z",
                  fill: "rgba(232,232,232,0.7)",
                  fillRule: "nonzero",
                },
              ]}
              shrink="0"
              position="relative"
              {...getOverrideProps(overrides, "HoverData")}
            ></Icon>
          </Flex>
          <Flex
            gap="10px"
            direction="row"
            width="75px"
            justifyContent="center"
            alignItems="flex-start"
            grow="1"
            basis="75px"
            height="75px"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "HWill")}
          >
            <Icon
              width="75px"
              height="75px"
              viewBox={{ minX: 0, minY: 0, width: 75, height: 75 }}
              paths={[
                {
                  d: "M75 37.5C75 58.2107 58.2107 75 37.5 75C16.7893 75 0 58.2107 0 37.5C0 16.7893 16.7893 0 37.5 0C58.2107 0 75 16.7893 75 37.5Z",
                  fill: "rgba(232,232,232,0.7)",
                  fillRule: "nonzero",
                },
              ]}
              shrink="0"
              position="relative"
              {...getOverrideProps(overrides, "HoverWill")}
            ></Icon>
          </Flex>
          <Flex
            gap="10px"
            direction="row"
            width="75px"
            justifyContent="center"
            alignItems="flex-start"
            grow="1"
            basis="75px"
            height="75px"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "HHeart")}
          >
            <Icon
              width="75px"
              height="75px"
              viewBox={{ minX: 0, minY: 0, width: 75, height: 75 }}
              paths={[
                {
                  d: "M75 37.5C75 58.2107 58.2107 75 37.5 75C16.7893 75 0 58.2107 0 37.5C0 16.7893 16.7893 0 37.5 0C58.2107 0 75 16.7893 75 37.5Z",
                  fill: "rgba(232,232,232,0.7)",
                  fillRule: "nonzero",
                },
              ]}
              shrink="0"
              position="relative"
              {...getOverrideProps(overrides, "HoverHeart")}
            ></Icon>
          </Flex>
          <Flex
            gap="10px"
            direction="row"
            width="75px"
            justifyContent="center"
            alignItems="flex-start"
            grow="1"
            basis="75px"
            height="75px"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "HHint")}
          >
            <Icon
              width="75px"
              height="75px"
              viewBox={{ minX: 0, minY: 0, width: 75, height: 75 }}
              paths={[
                {
                  d: "M75 37.5C75 58.2107 58.2107 75 37.5 75C16.7893 75 0 58.2107 0 37.5C0 16.7893 16.7893 0 37.5 0C58.2107 0 75 16.7893 75 37.5Z",
                  fill: "rgba(232,232,232,0.7)",
                  fillRule: "nonzero",
                },
              ]}
              shrink="0"
              position="relative"
              {...getOverrideProps(overrides, "HoverHint")}
            ></Icon>
          </Flex>
          <Flex
            gap="10px"
            direction="row"
            width="75px"
            justifyContent="center"
            alignItems="flex-start"
            grow="1"
            basis="75px"
            height="75px"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "HHowto")}
          >
            <Icon
              width="75px"
              height="75px"
              viewBox={{ minX: 0, minY: 0, width: 75, height: 75 }}
              paths={[
                {
                  d: "M75 37.5C75 58.2107 58.2107 75 37.5 75C16.7893 75 0 58.2107 0 37.5C0 16.7893 16.7893 0 37.5 0C58.2107 0 75 16.7893 75 37.5Z",
                  fill: "rgba(232,232,232,0.7)",
                  fillRule: "nonzero",
                },
              ]}
              shrink="0"
              position="relative"
              {...getOverrideProps(overrides, "HoverHowto")}
            ></Icon>
          </Flex>
        </Flex>
      </View>
      <Flex
        gap="0"
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        direction="column"
        width="375px"
        height="71px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Content")}
      >
        <Divider
          height="1px"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          size="small"
          orientation="horizontal"
          {...getOverrideProps(overrides, "Divider")}
        ></Divider>
        <Flex
          gap="0"
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "NavList")}
        >
          <Flex
            gap="0"
            direction="column"
            width="74.2px"
            justifyContent="center"
            alignItems="center"
            grow="1"
            basis="74.19999694824219px"
            height="70px"
            position="relative"
            padding="10px 0px 5px 0px"
            {...getOverrideProps(overrides, "NvData")}
          >
            <Image
              width="32px"
              height="32px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Data")}
            ></Image>
            <Text
              fontFamily="Zen Kaku Gothic New"
              fontSize="11px"
              fontWeight="700"
              color="rgba(28,26,29,1)"
              lineHeight="23px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="私のデータ"
              {...getOverrideProps(overrides, "\u79C1\u306E\u30C7\u30FC\u30BF")}
            ></Text>
          </Flex>
          <Divider
            height="70px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            size="small"
            orientation="vertical"
            {...getOverrideProps(overrides, "DividerD")}
          ></Divider>
          <Flex
            gap="0"
            direction="column"
            width="74.2px"
            justifyContent="center"
            alignItems="center"
            grow="1"
            basis="74.19999694824219px"
            height="70px"
            position="relative"
            padding="10px 0px 5px 0px"
            {...getOverrideProps(overrides, "NvWill")}
          >
            <Image
              width="16px"
              height="32px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Will")}
            ></Image>
            <Text
              fontFamily="Zen Kaku Gothic New"
              fontSize="11px"
              fontWeight="700"
              color="rgba(28,26,29,1)"
              lineHeight="23px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="私の意思"
              {...getOverrideProps(overrides, "\u79C1\u306E\u610F\u601D")}
            ></Text>
          </Flex>
          <Divider
            height="70px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            size="small"
            orientation="vertical"
            {...getOverrideProps(overrides, "DividerW")}
          ></Divider>
          <Flex
            gap="0"
            direction="column"
            width="74.2px"
            justifyContent="center"
            alignItems="center"
            grow="1"
            basis="74.19999694824219px"
            height="70px"
            position="relative"
            padding="10px 0px 5px 0px"
            {...getOverrideProps(overrides, "NvHeart")}
          >
            <Image
              width="28.33px"
              height="32px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Heart")}
            ></Image>
            <Text
              fontFamily="Zen Kaku Gothic New"
              fontSize="11px"
              fontWeight="700"
              color="rgba(28,26,29,1)"
              lineHeight="23px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="大切なもの"
              {...getOverrideProps(overrides, "\u5927\u5207\u306A\u3082\u306E")}
            ></Text>
          </Flex>
          <Divider
            height="70px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            size="small"
            orientation="vertical"
            {...getOverrideProps(overrides, "DividerHe")}
          ></Divider>
          <Flex
            gap="0"
            direction="column"
            width="74.2px"
            justifyContent="center"
            alignItems="center"
            grow="1"
            basis="74.19999694824219px"
            height="70px"
            position="relative"
            padding="10px 0px 5px 0px"
            {...getOverrideProps(overrides, "NvHint")}
          >
            <Image
              width="28.33px"
              height="32px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Hint")}
            ></Image>
            <Text
              fontFamily="Zen Kaku Gothic New"
              fontSize="11px"
              fontWeight="700"
              color="rgba(28,26,29,1)"
              lineHeight="23px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="ヒント"
              {...getOverrideProps(overrides, "\u30D2\u30F3\u30C8")}
            ></Text>
          </Flex>
          <Divider
            height="70px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            size="small"
            orientation="vertical"
            {...getOverrideProps(overrides, "DividerHi")}
          ></Divider>
          <Flex
            gap="0"
            direction="column"
            width="74.2px"
            justifyContent="center"
            alignItems="center"
            grow="1"
            basis="74.19999694824219px"
            height="70px"
            position="relative"
            padding="10px 0px 5px 0px"
            {...getOverrideProps(overrides, "NvHowto")}
          >
            <Image
              width="28.33px"
              height="32px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Howto")}
            ></Image>
            <Text
              fontFamily="Zen Kaku Gothic New"
              fontSize="11px"
              fontWeight="700"
              color="rgba(28,26,29,1)"
              lineHeight="23px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="使い方"
              {...getOverrideProps(overrides, "\u4F7F\u3044\u65B9")}
            ></Text>
          </Flex>
        </Flex>
      </Flex>
      <Image
        position="absolute"
        top="12.68%"
        bottom="69.01%"
        height="18.31%"
        left="73.07%"
        right="23.47%"
        width="3.47%"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "One_badge")}
      ></Image>
    </View>
  );
}
