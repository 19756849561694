import { Flex } from "@aws-amplify/ui-react";
import { DataStore } from "aws-amplify";
import { useCallback, useContext, useEffect, useState } from "react";
import { LeavesDaijinishitaikinenbi } from "../models";
import { ListField, SubText } from "../page-components";
import UserContext from "../UserContext";
import { getLabel, isEmpty, isEmptyDate, isInvalidDateFormat, isNotEmptyDate, useInitializePage } from "../utils/CommonUtils";
import { KONOHIGACHIKAZUITARATSUUCHI, useDatalist } from "../utils/DatalistUtils";
import { usePrompt } from "../utils/Prompt";
import { log } from "../utils/Logger";

function AnniversaryAddPage() {
  const navigate = useInitializePage("daijinishitaikinenbi", "title.add");
  const context = useContext(UserContext);
  const [dataAnniversary, setDataAnniversary] = useState({
    kinenbimei: "",
    hiduke: "",
    bikou: "",
  });
  const [dataAnniversaryError, setDataAnniversaryError] = useState({
    kinenbimeiError: "",
    hidukeError: "",
  });
  const [update, setUpdate] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const [KonohigachikazuitaratsuuchiDataList, setKonohigachikazuitaratsuuchi, getKonohigachikazuitaratsuuchi, hasErrorKonohigachikazuitaratsuuchi] =
    useDatalist({
      list: KONOHIGACHIKAZUITARATSUUCHI,
      dataUpdateHook: setUpdate,
      type: "NANNICHIMAEKASHITEI",
    });
  const listField = [
    {
      type: "textField",
      label: "kinenbimei",
      isRequire: true,
      placeHolder: "kekkonkinenbinado",
      errorMessage: dataAnniversaryError.kinenbimeiError,
    },
    {
      type: "textField",
      label: "hiduke",
      isRequire: true,
      typeInput: "date",
      errorMessage: dataAnniversaryError.hidukeError,
    },
    {
      type: "dataList",
      child: (
        <Flex display="block">
          <KonohigachikazuitaratsuuchiDataList label="konohigachikazuitaratsuuchi" />
          {/* <SubText text={getLabel("konohigachikazuitaratsuuchi.subtitle")} /> */}
          <SubText text="※未選択の場合は通知がされません。それ以外を選択した場合は、自分と設定した通知先に毎年通知されます" />
        </Flex>
      ),
    },
    {
      type: "familyAndFriendList",
      label: "tsuuchisaki",
      listFriend: dataAnniversary?.tsuuchisaki?.friend,
      listFamily: dataAnniversary?.tsuuchisaki?.family,
    },
    {
      type: "textArea",
      label: "bikou",
      subLabel: ".anniversary",
      placeHolder: "kasaburankawoyouisurukotonado",
    },
  ];

  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  const autoSave = useCallback(() => {
    if (!dataAnniversary.kinenbimei) {
      dataAnniversary.kinenbimei = "未設定";
    }
    let valid = true;
    const dateFormatCheck = isInvalidDateFormat(dataAnniversary.hiduke);
    if (hasErrorKonohigachikazuitaratsuuchi()) valid = false;
    if (isEmpty(dataAnniversary.kinenbimei) || dateFormatCheck) {
      setDataAnniversaryError({
        ...dataAnniversaryError,
        kinenbimeiError: isEmpty(dataAnniversary.kinenbimei) ? getLabel("error.required") : "",
        hidukeError: isEmptyDate(dataAnniversary.hiduke) ? getLabel("error.required") : dateFormatCheck ? getLabel("error.dateformat") : "",
      });
      valid = false;
    }
    if (valid) {
      console.log("save dataAnniversary", dataAnniversary);
      DataStore.save(
        new LeavesDaijinishitaikinenbi({
          kinenbimei: dataAnniversary.kinenbimei,
          hiduke: dataAnniversary.hiduke ? dataAnniversary.hiduke : null,
          konohigachikazuitaratsuuchi: getKonohigachikazuitaratsuuchi(),
          tsuuchisakiKazokuShinzoku: dataAnniversary?.tsuuchisaki?.family,
          tsuuchisakiYuujinChijin: dataAnniversary?.tsuuchisaki?.friend,
          bikou: dataAnniversary.bikou,
          leavesusersID: context.leavesUser.id,
        })
      );
      return true;
    } else {
      return false;
    }
  }, [
    context.leavesUser.id,
    dataAnniversary.bikou,
    dataAnniversary.hiduke,
    dataAnniversary.kinenbimei,
    dataAnniversary?.tsuuchisaki,
    dataAnniversaryError,
    getKonohigachikazuitaratsuuchi,
    hasErrorKonohigachikazuitaratsuuchi,
    navigate,
  ]);

  const addData = useCallback(async () => {
    let valid = true;
    const dateFormatCheck = isInvalidDateFormat(dataAnniversary.hiduke);
    if (hasErrorKonohigachikazuitaratsuuchi()) valid = false;
    if (isEmpty(dataAnniversary.kinenbimei) || isEmptyDate(dataAnniversary.hiduke) || dateFormatCheck) {
      setDataAnniversaryError({
        ...dataAnniversaryError,
        kinenbimeiError: isEmpty(dataAnniversary.kinenbimei) ? getLabel("error.required") : "",
        hidukeError: isEmptyDate(dataAnniversary.hiduke) ? getLabel("error.required") : dateFormatCheck ? getLabel("error.dateformat") : "",
      });
      valid = false;
    }
    if (valid) {
      setIsAdded(true);
      try {
        await DataStore.save(
          new LeavesDaijinishitaikinenbi({
            kinenbimei: dataAnniversary.kinenbimei,
            hiduke: dataAnniversary.hiduke,
            konohigachikazuitaratsuuchi: getKonohigachikazuitaratsuuchi(),
            tsuuchisakiKazokuShinzoku: dataAnniversary?.tsuuchisaki?.family,
            tsuuchisakiYuujinChijin: dataAnniversary?.tsuuchisaki?.friend,
            bikou: dataAnniversary.bikou,
            leavesusersID: context.leavesUser.id,
          })
        );
        window.gtag("event", "FirstWriteComplete", {
          event_category: "Edit",
          event_label: "大事にしたい記念日書込完了",
        });
        log.debug("anniversary add success", dataAnniversary);
        navigate("/data/anniversary", { replace: true, state: { dialog: false } });
      } catch (e) {
        setIsAdded(false);
        log.debug("anniversary add error", e);
      }
    }
  }, [
    context.leavesUser.id,
    dataAnniversary.bikou,
    dataAnniversary.hiduke,
    dataAnniversary.kinenbimei,
    dataAnniversary?.tsuuchisaki,
    dataAnniversaryError,
    getKonohigachikazuitaratsuuchi,
    hasErrorKonohigachikazuitaratsuuchi,
    navigate,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ListField
      label={getLabel("shinkitsuika")}
      list={listField}
      setState={setDataAnniversary}
      setUpdate={setUpdate}
      onSave={addData}
      onCancel={() => navigate("/data/anniversary", { replace: true })}
      isDisabled={isAdded}
      autoSave={autoSave}
    />
  );
}

export default AnniversaryAddPage;
