/* eslint-disable import/no-anonymous-default-export */
import { DataStore, Storage } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import { LeavesJibunshi } from "../models";
import {
  ButtonCancelSave,
  SelectImageField,
  SwapperContent,
  TextField,
  TextFieldMultiline,
} from "../page-components";
import {
  getLabel,
  isEmpty,
  isEmptyDate,
  isInvalidDateFormat,
  isNotEmptyDate,
  useInitializePage,
} from "../utils/CommonUtils";
import { log } from "../utils/Logger";
import { usePrompt } from "../utils/Prompt";

/*
  nengetsu: AWSDate!
  eventmei: String!
*/
export default function () {
  const navigate = useInitializePage("jibunshi", "title.update");
  const [data, setData] = useState();
  // let shisan;

  const [nengetsu, setNengetsu] = useState("");
  const [eventmei, setEventmei] = useState("");
  const [episode, setEpisode] = useState("");
  const [tenpufile, setTenpufile] = useState(null);
  const [objectSrc, setObjectSrc] = useState(null);

  const [nengetsuError, setNengetsuError] = useState(null);
  const [eventmeiError, setEventmeiError] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);

  const [update, setUpdate] = useState(false);
  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  let params = useParams();

  const eventTracking = () => {
    window.gtag("event", "ChangeComplete", {
      event_category: "Edit",
      event_label: "自分史変更完了",
    });
  };

  useEffect(async () => {
    const dsData = await DataStore.query(LeavesJibunshi, (c) =>
      c.id("eq", params.id),
    );
    log.debug("dsData", dsData);
    if (dsData.length > 0) {
      setData(dsData[0]);
      setNengetsu(dsData[0].nengetsu);
      setEventmei(dsData[0].eventmei);
      setEpisode(dsData[0].episode);
    }
  }, []);

  useEffect(() => {
    Storage.list("LeavesJibunshi/" + params.id + "/", {
      level: "private",
    }).then((res) => {
      const url = res.length > 0 ? res[0].key : null;
      setObjectSrc(url);
      setTenpufile(url);
    });
  }, [params.id]);

  const updateData = async () => {
    log.debug(nengetsu + "," + eventmei + "," + episode);
    let valid = true;
    const dateFormatCheck = isInvalidDateFormat(nengetsu);
    if (dateFormatCheck) {
      setNengetsuError(getLabel("error.dateformat"));
      valid = false;
    } else if (isEmptyDate(nengetsu)) {
      setNengetsuError(getLabel("error.required"));
      valid = false;
    } else {
      setNengetsuError(null);
    }
    if (isEmpty(eventmei)) {
      setEventmeiError(getLabel("error.required"));
      valid = false;
    } else {
      setEventmeiError(null);
    }
    if (valid) {
      setIsUpdated(true);
      DataStore.save(
        LeavesJibunshi.copyOf(data, (updated) => {
          updated.nengetsu = isNotEmptyDate(nengetsu) ? nengetsu : null;
          updated.eventmei = eventmei;
          updated.episode = episode;
        }),
      );

      if (objectSrc !== tenpufile) {
        const editData = async () => {
          const listFile = await Storage.list(`LeavesJibunshi/${data.id}/`, {
            level: "private",
          });
          for (let i = 0; i < listFile.length; i++) {
            await Storage.remove(listFile[i].key, { level: "private" });
          }
          if (tenpufile) {
            const result = await Storage.put(
              "LeavesJibunshi/" + data.id + "/" + tenpufile.name,
              tenpufile,
              {
                contentType: tenpufile.type,
                level: "private",
              },
            );
            log.debug("s3 upload result", result);
          }
          eventTracking();
          navigate(-1, { replace: true });
        };
        editData();
      } else {
        eventTracking();
        navigate(-1, { replace: true });
      }
    }
  };

  return (
    <SwapperContent label={getLabel("button.update")}>
      <TextField
        isRequired
        className={isMobile ? "" : "maxwidth800"}
        type="date"
        label={getLabel("nengetsu")}
        value={nengetsu}
        errorMessage={nengetsuError}
        onChange={(event) => {
          setNengetsu(event.target.value);
          setUpdate(true);
        }}
      />
      <TextField
        isRequired
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("eventmei")}
        value={eventmei}
        errorMessage={eventmeiError}
        placeHolder={getLabel("marumarusyanyuusyanado")}
        onChange={(event) => {
          setEventmei(event.target.value);
          setUpdate(true);
        }}
      />
      <TextFieldMultiline
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("episode")}
        value={episode}
        placeHolder={getLabel(
          "oyakaranopresentnonecktiewoshimetehatsusyukkinshitanado",
        )}
        onChange={(event) => {
          setEpisode(event.target.value);
          setUpdate(true);
        }}
      />
      <SelectImageField
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("tenpufile")}
        objectSrc={objectSrc}
        onChange={(event) => {
          setTenpufile(event.target.value);
          setUpdate(true);
        }}
        buttonLabel={getLabel("filewosentaku")}
      />
      <ButtonCancelSave
        cancelOnClick={() => navigate(-1, { replace: true })}
        saveOnClick={() => updateData()}
        isDisabled={isUpdated}
      />
    </SwapperContent>
  );
}
