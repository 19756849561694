import { DataStore, Storage } from "aws-amplify";
import { useCallback, useContext, useEffect, useState } from "react";
import { LeavesTaisetsunishitaihitohenomessage } from "../models";
import { ListField } from "../page-components";
import UserContext from "../UserContext";
import { getLabel, isEmpty, isEmptyDate, isInvalidDateFormat, useInitializePage } from "../utils/CommonUtils";
import { log } from "../utils/Logger";
import { usePrompt } from "../utils/Prompt";

function MessageAddPage() {
  const navigate = useInitializePage("taisetsunishitaihitohenomessage", "title.add");
  const context = useContext(UserContext);
  const [isLoaded, setIsLoaded] = useState(false);
  const [messageData, setMessageData] = useState({
    messagetitle: "",
    messagenaiyou: "",
    soushinbi: null,
    tenpufile: null,
  });
  const [soushinsakiId, setSoushinsakiID] = useState(null);
  const [messageTitleError, setMessageTitleError] = useState("");
  const [messageDateError, setMessageDateError] = useState("");
  const [isAdded, setIsAdded] = useState(false);
  const [update, setUpdate] = useState(false);
  const listField = [
    {
      type: "textField",
      label: "messagetitle",
      isRequire: true,
      placeHolder: "mailnotitleninarimasu",
      errorMessage: messageTitleError,
    },
    {
      type: "familyAndFriend",
      label: "soushinsaki",
      value: soushinsakiId,
    },
    {
      type: "textArea",
      label: "messagenaiyou",
      placeHolder: "mailnohonbunninarimasu",
    },
    {
      type: "file",
      label: "tenpufile",
    },
    {
      type: "textField",
      typeInput: "date",
      label: "soushinbi",
      errorMessage: messageDateError,
    },
  ];

  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  const autoSave = useCallback(() => {
    if (!messageData.messagetitle) {
      messageData.messagetitle = "未設定";
    }
    let valid = true;
    const dateFormatValid = isInvalidDateFormat(messageData?.soushinbi);
    if (isEmpty(messageData.messagetitle)) {
      setMessageTitleError(getLabel("error.required"));
      valid = false;
    } else setMessageTitleError("");
    if (dateFormatValid) {
      setMessageDateError(getLabel("error.dateformat"));
      valid = false;
    } else setMessageDateError("");
    if (valid) {
      console.log("save messageData", messageData);
      const update = async () => {
        const saved = await DataStore.save(
          new LeavesTaisetsunishitaihitohenomessage({
            messagetitle: messageData.messagetitle,
            soushinsaki: messageData?.soushinsaki?.type === "family" ? messageData?.soushinsaki?.id : null,
            soushinsakiYuujinChijin: messageData?.soushinsaki?.type === "friend" ? messageData?.soushinsaki?.id : null,
            messagenaiyou: messageData?.messagenaiyou,
            soushinbi: isEmptyDate(messageData?.soushinbi) ? null : messageData?.soushinbi,
            leavesusersID: context.leavesUser.id,
          })
        );
        if (messageData.tenpufile) {
          const result = await Storage.put(`LeavesTaisetsunishitaihitohenomessage/${saved.id}/${messageData.tenpufile.name}`, messageData.tenpufile, {
            contentType: messageData.tenpufile.type,
            level: "private",
          });
          log.debug("s3 upload result", result);
        }
      };
      update();
      return true;
    } else {
      return false;
    }
  }, [
    context.leavesUser.id,
    messageData?.messagenaiyou,
    messageData.messagetitle,
    messageData?.soushinbi,
    messageData?.soushinsaki?.id,
    messageData?.soushinsaki?.type,
    messageData.tenpufile,
    navigate,
  ]);

  useEffect(() => {
    setSoushinsakiID(messageData?.soushinsaki?.id);
  }, [messageData?.soushinsaki?.id]);

  const addData = useCallback(async () => {
    let valid = true;
    const dateFormatValid = isInvalidDateFormat(messageData?.soushinbi);
    if (isEmpty(messageData.messagetitle)) {
      setMessageTitleError(getLabel("error.required"));
      valid = false;
    } else setMessageTitleError("");
    if (dateFormatValid) {
      setMessageDateError(getLabel("error.dateformat"));
      valid = false;
    } else setMessageDateError("");
    if (valid) {
      const addData = async () => {
        setIsAdded(true);
        try {
          const saved = await DataStore.save(
            new LeavesTaisetsunishitaihitohenomessage({
              messagetitle: messageData.messagetitle,
              soushinsaki: messageData?.soushinsaki?.type === "family" ? messageData?.soushinsaki?.id : null,
              soushinsakiYuujinChijin: messageData?.soushinsaki?.type === "friend" ? messageData?.soushinsaki?.id : null,
              messagenaiyou: messageData?.messagenaiyou,
              soushinbi: isEmptyDate(messageData?.soushinbi) ? null : messageData?.soushinbi,
              leavesusersID: context.leavesUser.id,
            })
          );
          if (messageData.tenpufile) {
            const result = await Storage.put(
              `LeavesTaisetsunishitaihitohenomessage/${saved.id}/${messageData.tenpufile.name}`,
              messageData.tenpufile,
              {
                contentType: messageData.tenpufile.type,
                level: "private",
              }
            );
            log.debug("s3 upload result", result);
          }
          window.gtag("event", "FirstWriteComplete", {
            event_category: "Edit",
            event_label: "大切にしたい人へのメッセージ書込完了",
          });
          navigate(-1, { replace: true });
          log.debug("message add success", messageData);
        } catch (e) {
          setIsAdded(false);
          log.debug("message add error", e);
        }
      };
      addData();
    }
  }, [
    context.leavesUser.id,
    messageData?.messagenaiyou,
    messageData.messagetitle,
    messageData?.soushinbi,
    messageData?.soushinsaki?.id,
    messageData?.soushinsaki?.type,
    messageData.tenpufile,
    navigate,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const modelData = await DataStore.query(LeavesTaisetsunishitaihitohenomessage);
      if (modelData?.length >= 3) {
        navigate(-1, { replace: true });
      } else {
        setIsLoaded(true);
      }
    };
    getData();
  }, [navigate]);

  return (
    isLoaded && (
      <ListField
        label={getLabel("shinkitsuika")}
        list={listField}
        setState={setMessageData}
        setUpdate={setUpdate}
        onSave={addData}
        onCancel={() => navigate(-1, { replace: true })}
        isDisabled={isAdded}
        autoSave={autoSave}
      />
    )
  );
}

export default MessageAddPage;
