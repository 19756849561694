/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Text, View } from "@aws-amplify/ui-react";
export default function TabColum(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="0"
      direction="row"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(232,232,232,1)"
      {...rest}
      {...getOverrideProps(overrides, "TabColum")}
    >
      <Flex
        gap="0"
        direction="column"
        width="187.5px"
        height="40px"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Selected")}
      >
        <Flex
          gap="10px"
          direction="row"
          height="38px"
          justifyContent="center"
          alignItems="center"
          grow="1"
          basis="38px"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Text")}
        >
          <Text
            fontFamily="Zen Kaku Gothic New"
            fontSize="12px"
            fontWeight="700"
            color="rgba(104,134,73,1)"
            lineHeight="23px"
            textAlign="center"
            display="flex"
            direction="column"
            justifyContent="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="お気に入りコラム"
            {...getOverrideProps(
              overrides,
              "\u304A\u6C17\u306B\u5165\u308A\u30B3\u30E9\u30E0"
            )}
          ></Text>
        </Flex>
        <View
          height="2px"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(104,134,73,1)"
          {...getOverrideProps(overrides, "Line")}
        ></View>
      </Flex>
      <Flex
        gap="10px"
        direction="row"
        width="187.5px"
        height="40px"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="14px 16px 14px 16px"
        {...getOverrideProps(overrides, "Enabled")}
      >
        <Text
          fontFamily="Zen Kaku Gothic New"
          fontSize="12px"
          fontWeight="700"
          color="rgba(160,160,160,1)"
          lineHeight="23px"
          textAlign="center"
          display="flex"
          direction="column"
          justifyContent="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="予約済みイベント"
          {...getOverrideProps(
            overrides,
            "\u4E88\u7D04\u6E08\u307F\u30A4\u30D9\u30F3\u30C8"
          )}
        ></Text>
      </Flex>
    </Flex>
  );
}
