import { SmallFAB } from "../ui-components";

export default function FABMemo(props) {
  const { ...rest } = props;

  return (
    <SmallFAB
      {...rest}
      marginLeft="auto"
      width="40px"
      height="40px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      padding="8px"
      overrides={{
        Icon: {
          width: "100%",
          height: "100%",
          paths: [
            {
              d: "M16 4H4C2.9 4 2 4.9 2 6V18.01C2 19.11 2.9 20 4 20H20C21.1 20 22 19.1 22 18V10L16 4V4ZM4 18.01V6H15V11H20V18.01H4V18.01Z",
              fill: "#688649",
            },
          ],
        },
      }}
    />
  );
}
