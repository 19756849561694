import React, { useEffect, useState, useContext } from "react";
import { EventList, LabelS, Banner, EventAnnouncement, Description } from "../page-components";
import Amplify, { DataStore, Storage } from "aws-amplify";
import { LeavesEvents, LeavesMyEvent } from "../models";
import { useInitializePage, getLabel } from "../utils/CommonUtils";
import { useSearchParams } from "react-router-dom";
import UserContext from "../UserContext";
import colum from "../assets/image/colum.jpg";
import { Flex, Text } from "@aws-amplify/ui-react";
import { log } from "../utils/Logger";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { isMobile } from "react-device-detect";

/*
type LeavesEvents
  @model
  @auth(rules: [{ allow: groups, groups: ["Admin"] }, { allow: private, operations: [read] }, { allow: public, operations: [read] }]) {
  id: ID!
  name: String!
  startDate: AWSDate
  endDate: AWSDate
  date: String
  time: String
  closingDay: String
  address: String
  place: String
  mapUrl: String
  price: String
  confirmationNeeded: Boolean
  applyEnabled: Boolean
}

type LeavesMyEvent
  @model
  @auth(rules: [{ allow: owner }, { allow: private, provider: iam, operations: [read] }, { allow: groups, groups: ["Admins"], operations: [read] }]) {
  id: ID!
  LeavesEvents: LeavesEvents @hasOne
  leavesusersID: ID! @index(name: "byLeavesUsers")
  confirmed: Boolean
  applied: Boolean
  note: String
  rate: Int
}
*/
export default function () {
  const navigate = useInitializePage("event");
  const context = useContext(UserContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoaded, setIsLoaded] = useState(false);
  const [myList, setMyList] = useState([]);
  const [futureList, setFutureList] = useState([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    autoplaySpeed: 7000,
    autoplay: true,
    pauseOnHover: false,
    // slidesToShow: isMobile ? 1 : 4,
    // slidesToScroll: isMobile ? 1 : 4,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: isMobile ? "" : "pccomponent",
  };

  useEffect(async () => {
    const linkEventCode = searchParams.get("code");

    const allEvents = await DataStore.query(LeavesEvents);
    let myEvents = await DataStore.query(LeavesMyEvent);
    // log.debug("allEvents", allEvents);
    // log.debug("myEvents", myEvents);
    console.log("allEvents", allEvents);
    console.log("myEvents", myEvents);
    const list = [];
    if (linkEventCode != null) {
      const linkEvent = await DataStore.query(LeavesEvents, (c) => c.code("eq", linkEventCode));
      console.log("linkEvent", linkEvent);
      if (linkEvent.length > 0) {
        const alreadyData = myEvents.filter((data) => data.leavesEventsID == linkEvent[0].id);
        if (alreadyData.length == 0) {
          const newData = await DataStore.save(
            new LeavesMyEvent({
              leavesEventsID: linkEvent[0].id,
              confirmed: false,
              applied: false,
              leavesusersID: context.leavesUser.id,
            })
          );
          // myEvents = await DataStore.query(LeavesMyEvent);
          searchParams.delete("code");
          setSearchParams(searchParams);
          navigate(linkEvent[0].id);
          return;
        }
        searchParams.delete("code");
        setSearchParams(searchParams);
      }
    }
    const futureList = await Promise.all(
      allEvents
        .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
        .map(async (e) => {
          const eventStartDate = new Date(e.startDate + "T00:00:00Z");
          const url = await Storage.get("event/" + e.imagePath, { level: "public" });
          return { src: url, event: e, open: new Date() > eventStartDate };
        })
    );

    setFutureList(futureList);

    const myList = futureList.filter((e) => {
      const linkEvent = myEvents.filter((data) => {
        const exists = data.leavesEventsID == e.event.id;
        e["myEvent"] = data;
        return exists;
      });
      return linkEvent.length > 0;
    });

    setMyList(myList);
    setIsLoaded(true);

    // await DataStore.delete(LeavesHint, Predicates.ALL);
  }, []);

  return (
    <div style={{ marginTop: isMobile ? "0px" : "32px" }}>
      {/* <Slider {...settings}>
        {futureList
          .filter((e) => {
            const eventEndDate = new Date(e.endDate + "T23:59:59Z");
            return new Date() <= eventEndDate;
          })
          .map((ele, index) => {
          return (
            <EventAnnouncement
              key={index}
              eventSrc={ele.event.eventPageUrl}
              tagLabel={ele.open ? getLabel("kaisaichuu") : getLabel("kinjitsukaisai")}
              src={ele.src}
              title={ele.event.name}
              date={ele.event.date}
            />
          );
        })}
      </Slider> */}
      {isLoaded && myList.length == 0 ? (
        <Description margin="auto" paddingTop="32px" width="fit-content" className="" text={getLabel("moushikomizumieventnashi")} />
      ) : null}
      {/* <div style={{ marginTop: myList.length > 1 ? "0px" : "32px" }}> */}
      <div>
        {myList.map((ele, index) => {
          return (
            <EventList
              firstRow={index == 0}
              onClick={() => navigate(ele.event.id)}
              key={index}
              src={ele.src}
              title={ele.event.name}
              confirmed={ele.myEvent.confirmed}
            />
          );
        })}
      </div>
    </div>
  );
}
