import { CheckboxField, Flex, Text, TextField } from "@aws-amplify/ui-react";
import { DataStore } from "aws-amplify";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { isMobile } from "react-device-detect";
import { LeavesYuujinChijin } from "../models";
import { DialogButton, TextButton, TextButtonIcon } from "../ui-components";
import UserContext from "../UserContext";
import { preventBodyScrollMobile, getLabel } from "../utils/CommonUtils";

export default function (props) {
  const context = useContext(UserContext);

  const [add, setAdd] = useState(false);
  const [dialog, setDialog] = useState(false);
  const addfld = useRef();
  const listfld = useRef();
  const [friendList, setFriendList] = useState([]);
  const firstRender = useRef(false);

  useEffect(() => {
    if (add) {
      listfld.current.scrollTop = listfld.current.scrollHeight;
      addfld.current.focus();
    }
  }, [add]);

  useEffect(() => {
    if (props.selected) {
      setFriendList(
        friendList.map((f) => {
          return { checked: props.selected.indexOf(f.data.id) >= 0, data: f.data };
        })
      );
    }
  }, [props.selected]);

  useEffect(() => {
    if (firstRender.current) {
      preventBodyScrollMobile(dialog);
    } else firstRender.current = true;
  }, [dialog]);

  useEffect(async () => {
    const modelData = await DataStore.query(LeavesYuujinChijin);
    setFriendList(
      modelData.map((f) => {
        return {
          checked: false,
          data: f,
        };
      })
    );
  }, []);

  const reset = () => {
    if (props.selected) {
      setFriendList(
        friendList.map((f) => {
          return { checked: props.selected.indexOf(f.data.id) >= 0, data: f.data };
        })
      );
    }
  };

  const change = (i, checked) => {
    friendList[i].checked = checked;
  };

  const complete = () => {
    const newSelected = friendList.filter((f) => f.checked).map((f) => f.data.id);
    if (props.onComplete) {
      props.onComplete(newSelected);
    }
    setDialog(false);
  };

  const addFriend = async (name) => {
    const newFriends = await DataStore.save(
      new LeavesYuujinChijin({
        shimei: name,
        leavesusersID: context.leavesUser.id,
      })
    );
    friendList.push({ checked: true, data: newFriends });
    setFriendList(friendList.map((f) => f));
  };

  const ContactAddressList = useCallback(() => {
    return (
      <>
        <Text fontWeight="700" fontSize="16px" children={props.dialogLabel ? props.dialogLabel :getLabel("yujinchijinwosentaku")} marginBottom="8px" />
        <div padding="0 8px" className="custom-checkbox-area" ref={listfld}>
          {friendList.map((f, i) => {
            return (
              <CheckboxField
                key={i}
                className="custom-checkbox"
                label={f.data.shimei}
                size="small"
                onChange={(e) => {
                  change(i, e.target.checked);
                }}
                defaultChecked={f.checked}
              />
            );
          })}

          <TextField
            ref={addfld}
            width="auto"
            margin="0 0 10px 0"
            placeholder={getLabel("shimei")}
            display={add ? "block" : "none"}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                if (e.target.value != "") {
                  addFriend(e.target.value);
                  e.target.value = "";
                }
                e.target.style.display = "none";
              }
            }}
            onBlur={async (e) => {
              if (e.target.value != "") {
                await addFriend(e.target.value);
                e.target.value = "";
              }
              e.target.style.display = "none";
            }}
          />
          <TextButtonIcon
            margin="0 0 32px 0px"
            padding="0"
            className="pointer"
            onFocus={(e) => {
              e.target.blur();
            }}
            overrides={{
              Icon: {
                onClick: () => {
                  setAdd(!add);
                  addfld.current.style.display = "block";
                  addfld.current.focus();
                  listfld.current.scrollTop = listfld.current.scrollHeight;
                },
              },
              "Text Button": {
                children: getLabel("tsuika"),
                onClick: () => {
                  setAdd(!add);
                  addfld.current.style.display = "block";
                  addfld.current.focus();
                  listfld.current.scrollTop = listfld.current.scrollHeight;
                },
              },
            }}
          />
        </div>

        <DialogButton
          margin="10px auto"
          overrides={{
            DefaultButton: {
              className: "pointer",
            },
            Button: {
              children: getLabel("dialog.back"),
              onClick: () => {
                setDialog(false);
                reset();
              },
              onFocus: () => {
                setDialog(false);
                reset();
              },
            },
            PrimaryButton: {
              className: "pointer",
              onClick: complete,
            },
          }}
        />
      </>
    );
  }, [add, friendList]);
  const Dialog = useCallback(() => {
    return (
      <div className="react-confirm-alert">
        <div className="react-confirm-alert-overlay react-confirm-alert-overlay-custom">
          <div className="custom-ui">
            <Flex
              width={isMobile ? "295px" : "512px"}
              display={dialog ? "block" : "none"}
              boxShadow="0px 15px 12px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.25)"
              padding="8px 23px"
              direction="column"
              backgroundColor="rgba(255,255,255,1)"
            >
              <ContactAddressList />
            </Flex>
          </div>
        </div>
      </div>
    );
  }, [dialog]);
  return (
    <Flex
      className={
        isMobile ? "" : props.className ? props.className : "pccomponent"
      }
      direction="column"
      padding={isMobile ? "16px 16px 0px" : "0"}
      gap="0"
    >
      <Text fontSize={isMobile ? "14px" : "16px"}>{props.label}</Text>
      <Text
        color="#5c6670"
        fontSize={isMobile ? "14px" : "16px"}
        children={
          friendList.filter((f) => f.checked).length === 0
            ? getLabel("list.select")
            : friendList
                .filter((f) => f.checked)
                .map((f) => f.data.shimei)
                .join("、")
        }
      />
      <TextButton
        className="pointer"
        onClick={() => {
          setAdd(false);
          setDialog(true);
        }}
        overrides={{
          "Flex.Text[0]": {
            fontSize: isMobile ? "14px" : "16px",
            children: getLabel("renrakusakilistkarasentaku"),
          },
        }}
      />
      {dialog ? <Dialog /> : null}
    </Flex>
  );
}
