import { DataStore } from "aws-amplify";
import React, { useCallback, useEffect, useState } from "react";
import { isIOS } from "react-device-detect";
import { useParams } from "react-router-dom";
import { LeavesKouza } from "../models";
import { ListField, TextField, WithdrawalRadio } from "../page-components";
import {
  getLabel,
  isEmpty,
  isNotEmpty,
  useInitializePage,
} from "../utils/CommonUtils";
import { usePrompt } from "../utils/Prompt";

function CardEditPage() {
  const navigate = useInitializePage("kouzajidouhikiotoshi", "title.update");
  const [data, setData] = useState();
  const [claimData, setClaimData] = useState({
    hikiotoshikoumoku: "",
    hikiotoshikinyuukikan: "",
    hikiotoshisakiKinyuuShisanYochokin: "",
    hikiotoshisakiCreditcard: "",
    hikiotoshibi: "",
    bikou: "",
  });
  const [claimDataError, setClaimDataError] = useState({
    hikiotoshikoumokuError: "",
    hikiotoshikinyuukikanError: "",
    hikiotoshibiError: "",
    bikouError: "",
  });
  const [isUpdated, setIsUpdated] = useState(false);
  let params = useParams();
  const [update, setUpdate] = useState(false);
  const [activeOption, setActiveOption] = useState(null);
  const [loading, setLoading] = useState(true);
  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  const handleChangeOption = (value) => {
    if (!value) {
      setClaimData({
        ...claimData,
        hikiotoshisakiKinyuuShisanYochokin: null,
        hikiotoshisakiCreditcard: null,
        hikiotoshikinyuukikan: null,
      });
      setActiveOption(null);
    } else if (value === "1") {
      setClaimData({
        ...claimData,
        hikiotoshisakiKinyuuShisanYochokin: null,
        hikiotoshisakiCreditcard: null,
      });
      setActiveOption(value);
    } else if (value === "2") {
      setClaimData({
        ...claimData,
        hikiotoshikinyuukikan: null,
      });
      setActiveOption(value);
    }
  };

  const handleChangeDataWithdraw = (value) => {
    setClaimData({
      ...claimData,
      hikiotoshisakiKinyuuShisanYochokin:
        value?.type === "financial" ? value?.id : null,
      hikiotoshisakiCreditcard: value?.type === "card" ? value?.id : null,
      hikiotoshikinyuukikan: null
    });
  }; 

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const data = await DataStore.query(LeavesKouza, (c) =>
        c.id("eq", params.id),
      );
      if (data?.length > 0) {
        setData(data[0]);
        setClaimData({
          hikiotoshikoumoku: data[0].hikiotoshikoumoku,
          hikiotoshikinyuukikan: data[0].hikiotoshikinyuukikan,
          hikiotoshisakiKinyuuShisanYochokin: data[0].hikiotoshisakiKinyuuShisanYochokin,
          hikiotoshisakiCreditcard: data[0].hikiotoshisakiCreditcard,
          hikiotoshibi: data[0].hikiotoshibi,
          bikou: data[0].bikou,
        });
        if(data[0].hikiotoshikinyuukikan) setActiveOption("1");
        else if(data[0].hikiotoshisakiKinyuuShisanYochokin) setActiveOption("2");
        else if(data[0].hikiotoshisakiCreditcard) setActiveOption("2");
        else setActiveOption(null);
        setLoading(false);
      }
    };
    getData();
  }, [params.id]);

  const updateDataClaim = useCallback(async () => {
    let valid = true;
    if (isEmpty(claimData.hikiotoshikoumoku)) {
      setClaimDataError({
        ...claimDataError,
        hikiotoshikoumokuError: getLabel("error.required"),
      });
      valid = false;
    }
    if (valid) {
      setIsUpdated(true);
      await DataStore.save(
        LeavesKouza.copyOf(data, (updated) => {
          updated.hikiotoshikoumoku = claimData.hikiotoshikoumoku;
          updated.hikiotoshikinyuukikan = claimData.hikiotoshikinyuukikan;
          updated.hikiotoshisakiKinyuuShisanYochokin =
            claimData.hikiotoshisakiKinyuuShisanYochokin;
          updated.hikiotoshisakiCreditcard = claimData.hikiotoshisakiCreditcard;
          updated.hikiotoshibi = claimData.hikiotoshibi;
          updated.bikou = claimData.bikou;
        }),
      );
      window.gtag("event", "ChangeComplete", {
        event_category: "Edit",
        event_label: "定期支払い変更完了",
      });
      navigate(-1, { replace: true });
    }
  }, [
    claimData.bikou,
    claimData.hikiotoshibi,
    claimData.hikiotoshikinyuukikan,
    claimData.hikiotoshikoumoku,
    claimData.hikiotoshisakiCreditcard,
    claimData.hikiotoshisakiKinyuuShisanYochokin,
    claimDataError,
    data,
    navigate,
  ]);

  const listField = [
    {
      type: "textField",
      label: "hikiotoshikoumoku",
      isRequire: true,
      value: claimData.hikiotoshikoumoku,
      placeHolder: "internetnado",
      hasError: isNotEmpty(claimDataError.hikiotoshikoumokuError),
      errorMessage: claimDataError.hikiotoshikoumokuError,
    },
    {
      type: "radioCustomGroup",
      label: "hikiotoshikinyuukikan",
      active: activeOption,
      handleChangeOption: handleChangeOption,
      options: [
        {
          value: "1",
          label: getLabel("nyuryokusuru"),
          children: (
            <TextField
              placeholder={getLabel("kinyuukikancardmeinado")}
              overrideStyle={{
                padding: 0,
                width: "100%",
                margin: 0,
              }}
              value={claimData?.hikiotoshikinyuukikan}
              onChange={(e) => {
                setClaimData({
                  ...claimData,
                  hikiotoshikinyuukikan: e.target.value,
                  hikiotoshisakiKinyuuShisanYochokin: null,
                  hikiotoshisakiCreditcard: null,
                });
              }}
            />
          ),
        },
        {
          value: "2",
          label: getLabel("torokuzuminojohokaraerabu"),
          children: (
            <WithdrawalRadio
              selected={
                claimData?.hikiotoshisakiKinyuuShisanYochokin
                  ? claimData?.hikiotoshisakiKinyuuShisanYochokin
                  : claimData?.hikiotoshisakiCreditcard
              }
              overrideStyle={{
                padding: 0,
                width: "100%",
                margin: 0,
              }}
              onChangeWithdraw={handleChangeDataWithdraw}
            />
          ),
        },
      ],
    },
    {
      type: "textField",
      label: "hikiotoshibi",
      value: claimData.hikiotoshibi,
    },
    {
      type: "textArea",
      label: "bikou",
      subLabel: ".kouzajidouhikiotoshi",
      value: claimData.bikou,
    },
  ];

  return (
    (!loading || !isIOS) && 
    <ListField
      label={getLabel("button.update")}
      list={listField}
      setState={setClaimData}
      setUpdate={setUpdate}
      onSave={updateDataClaim}
      onCancel={() => navigate(-1, { replace: true })}
      isDisabled={isUpdated}
    />
  );
}

export default CardEditPage;
