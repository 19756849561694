import { DataStore } from "aws-amplify";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { LeavesKenkoukanri } from "../models";
import { ListField } from "../page-components";
import UserContext from "../UserContext";
import { getLabel, isEmpty, useInitializePage } from "../utils/CommonUtils";
import { usePrompt } from "../utils/Prompt";

function HealthAddPage() {
  const navigate = useInitializePage("kenkoukanrinitsuite", "title.add");
  const context = useContext(UserContext);
  const [healthData, setHealthData] = useState({
    byoumei: "",
    hukuyouchuunokusuri: "",
    youto: "",
    youhou: "",
    byouinmei: "",
    chiryoukikan: "",
    bikou: "",
  });
  const [healthDataError, setHealthDataError] = useState({
    byoumeiError: "",
    hukuyouchuunokusuriError: "",
    youtoError: "",
    youhouError: "",
    byouinmeiError: "",
    chiryoukikanError: "",
    bikouError: "",
  });
  const [isAdded, setIsAdded] = useState(false);
  const listField = [
    {
      type: "textField",
      label: "byoumei",
      isRequire: true,
      placeHolder: "tounyoubyounado",
      errorMessage: healthDataError.byoumeiError,
    },
    {
      type: "textField",
      label: "hukuyouchuunokusuri",
    },
    {
      type: "textField",
      label: "youto",
      placeHolder: "itamidomenado",
    },
    {
      type: "textField",
      label: "youhou",
      placeHolder: "yoruikkainado",
    },
    { type: "textField", label: "byouinmei" },
    {
      type: "textField",
      label: "chiryoukikan",
      placeHolder: "2020nenkarachiryounado",
    },
    { type: "textArea", label: "bikou" },
  ];
  const [update, setUpdate] = useState(false);
  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  const addData = useCallback(async () => {
    let valid = true;
    if (isEmpty(healthData.byoumei)) {
      setHealthDataError({
        ...healthDataError,
        byoumeiError: getLabel("error.required"),
      });
      valid = false;
    }
    if (valid) {
      setIsAdded(true);
      await DataStore.save(
        new LeavesKenkoukanri({
          byoumei: healthData.byoumei,
          hukuyouchuunokusuri: healthData.hukuyouchuunokusuri,
          youto: healthData.youto,
          youhou: healthData.youhou,
          byouinmei: healthData.byouinmei,
          chiryoukikan: healthData.chiryoukikan,
          bikou: healthData.bikou,
          leavesusersID: context.leavesUser.id,
        }),
      );
      window.gtag("event", "FirstWriteComplete", {
        event_category: "Edit",
        event_label: "健康管理について（常備薬・通院記録）書込完了",
      });
      navigate(-1, { replace: true });
    }
  }, [
    context.leavesUser.id,
    healthData.bikou,
    healthData.byouinmei,
    healthData.byoumei,
    healthData.chiryoukikan,
    healthData.hukuyouchuunokusuri,
    healthData.youhou,
    healthData.youto,
    healthDataError,
    navigate,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ListField
      label={getLabel("shinkitsuika")}
      list={listField}
      setState={setHealthData}
      setUpdate={setUpdate}
      onSave={addData}
      onCancel={() => navigate(-1, { replace: true })}
      isDisabled={isAdded}
    />
  );
}

export default HealthAddPage;
