/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Flex,
  Radio,
  RadioGroupField,
  Text,
  TextField,
} from "@aws-amplify/ui-react";
import { DataStore } from "aws-amplify";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { isMobile } from "react-device-detect";
import {
  KinyuuShisanshurui,
  LeavesCreditcard,
  LeavesKinyuuShisan,
} from "../models";
import { DialogButton, TextButton, TextButtonIcon } from "../ui-components";
import UserContext from "../UserContext";
import { preventBodyScrollMobile, getLabel } from "../utils/CommonUtils";
import Description from "./Description";

const Type = {
  SELECT: "select",
  FINANCIAL: "financial",
  CARD: "card",
  EMPTY: "empty",
};

const saveType = {
  OPEN: "open",
  SAVE: "save",
  CLOSE: "close",
};

export default function WithdrawalRadio(props) {
  const context = useContext(UserContext);
  const [dialog, setDialog] = useState(false);
  const dialogRef = useRef();
  const textBoxRef = useRef();
  const listfld = useRef();
  const selected = useRef(null);
  const [listData, setListData] = useState();
  const [selectedContact, setSelectedContact] = useState(
    getLabel("list.select"),
  );
  const [dialogType, setDialogType] = useState(Type.SELECT);
  const [isLoadding, setIsLoadding] = useState(false);
  const [save, setSave] = useState(saveType.CLOSE);
  const firstRender = useRef(false);

  const handlSelect = (type) => {
    setDialogType(type);
  };

  const onComplete = () => {
    props.onChangeWithdraw({
      id: selected.current.value,
      type: dialogType,
    });
  };

  useEffect(() => {
    if (save === saveType.OPEN) {
      listfld.current.children[1].scrollTop =
        listfld.current.children[1].scrollHeight;
      textBoxRef.current.focus();
    }
  }, [save]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        setDialog(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [dialogRef]);

  useEffect(() => {
    if (firstRender.current) {
      preventBodyScrollMobile(dialog);
    } else firstRender.current = true;
  }, [dialog]);

  useEffect(() => {
    if (dialogType === Type.SELECT) {
      setSave(saveType.CLOSE);
    }
    const getData = async () => {
      let data = [];
      setIsLoadding(true);
      if (dialogType === Type.FINANCIAL) {
        data = await DataStore.query(LeavesKinyuuShisan);
        const item = data?.filter((ele) => ele.id === props.selected);
        if (item?.length === 0) selected.current = { value: "" };
        else {
          selected.current = { value: item[0]?.id };
        }
        setListData(
          data?.filter((d) => d.shisanshurui === KinyuuShisanshurui.YOCHOKIN)
        );
      } else if (dialogType === Type.CARD) {
        data = await DataStore.query(LeavesCreditcard);
        const item = data?.filter((ele) => ele.id === props.selected);
        if (item?.length === 0) selected.current = { value: "" };
        else {
          selected.current = { value: item[0]?.id };
        }
        setListData(data);
      }
      setIsLoadding(false);
    };
    getData();
  }, [dialogType]);

  useEffect(() => {
    if (props?.selected) {
      const getData = async () => {
        const contact = await DataStore.query(LeavesKinyuuShisan, (c) =>
          c.id("eq", props.selected),
        );
        if (contact?.length > 0) {
          setSelectedContact(contact[0]?.kinyuukikan);
        } else {
          const contact = await DataStore.query(LeavesCreditcard, (c) =>
            c.id("eq", props.selected),
          );
          if (contact?.length > 0) {
            setSelectedContact(contact[0]?.creditcardmei);
          } else {
            selected.current = { value: "" };
          }
        }
      };
      getData();
      selected.current = { value: props.selected };
    } else {
      selected.current = { value: "" };
      setSelectedContact(getLabel("list.select"));
    }
  }, [props.selected]);

  useEffect(() => {
    if (!dialog) {
      setDialogType(Type.SELECT);
    }
  }, [dialog]);

  const complete = useCallback(() => {
    if (selected.current.value === "") {
      setSelectedContact(getLabel("list.select"));
    } else {
      const item = listData.filter((f) => selected.current.value === f.id)[0];
      setSelectedContact(
        dialogType === Type.FINANCIAL ? item?.kinyuukikan : item?.creditcardmei,
      );
    }
    onComplete();
    setDialog(false);
  }, [dialogType, listData]);

  const handleAddItem = async (value) => {
    if (value) {
      if (dialogType === Type.CARD) await handleSaveCard(value);
      else await handleSaveYochokin(value);
      
    }
  };

  const openTextBox = () => {
    if (save === saveType.CLOSE) {
      setSave(saveType.OPEN);
    }
  };

  const handleSaveYochokin = async (value) => {
    if (value) {
      const newFinancial = new LeavesKinyuuShisan({
        shisanshurui: KinyuuShisanshurui.YOCHOKIN,
        kinyuukikan: value,
        leavesusersID: context.leavesUser.id,
      });
      selected.current = { value: newFinancial?.id };
      setListData([...listData, newFinancial]);
      await DataStore.save(newFinancial);
    }
  };

  const handleSaveCard = async (value) => {
    if (value) {
      const newCard = new LeavesCreditcard({
        creditcardmei: value,
        leavesusersID: context.leavesUser.id,
      });
      selected.current = { value: newCard?.id };
      setListData([...listData, newCard]);
      await DataStore.save(newCard);
    }
  };

  const DialogSelect = useCallback((props) => {
    return (
      <Flex display="block" paddingBottom="19px">
        <Text fontWeight="700" marginBottom="14px" textAlign="center">
          {getLabel("hikiotoshisakiosentaku")}
        </Text>
        <Description
          text={getLabel("sanshosurukomokuosentakushitekudasai")}
          fontSize="14px"
          padding="0 16px 0 16px"
          width="fit-content"
          margin="auto"
        />
        <Button
          width="100%"
          maxWidth="250px"
          fontWeight="700"
          marginTop="16px"
          padding="6px 16px"
          fontSize="14px"
          onClick={() => handlSelect(Type.FINANCIAL)}
        >
          {getLabel("yochokinkinyushisan")}
        </Button>
        <Button
          width="100%"
          maxWidth="250px"
          fontWeight="700"
          marginTop="16px"
          padding="6px 16px"
          fontSize="14px"
          onClick={() => handlSelect(Type.CARD)}
        >
          {getLabel("creditcard")}
        </Button>
      </Flex>
    );
  }, []);

  const ContactList = useCallback(
    (props) => {
      return (
        <Flex display="block" paddingBottom="8px">
          <Text
            fontWeight="700"
            fontSize="16px"
            children={
              dialogType === Type.FINANCIAL
                ? getLabel("yochokinkinyushisan")
                : getLabel("creditcard")
            }
            marginBottom="8px"
          />
          <Flex maxHeight="300px">
            <RadioGroupField
              ref={listfld}
              defaultValue={selected?.current?.value}
              className="radio-swapper"
              paddingBottom="16px"
              margin="2px"
              name="familylist"
              width="100%"
              onChange={(e) => {
                selected.current = e.target;
              }}
            >
              <Radio value="">{getLabel("list.select")}</Radio>
              {props?.list?.map((f, i) => {
                return (
                  <Radio key={i} value={f.id}>
                    {dialogType === Type.FINANCIAL
                      ? f.kinyuukikan
                      : f.creditcardmei}
                  </Radio>
                );
              })}
              <Flex width="100%" textAlign="center" display="block">
                <TextField
                  ref={textBoxRef}
                  width="auto"
                  margin="0 0 10px 0"
                  maxLength={100}
                  display={props?.openAdd ? "block" : "none"}
                  placeHolder={
                    dialogType === Type.FINANCIAL
                      ? getLabel("ginkousyoukengaisyanado")
                      : getLabel("toukyucardnado")
                  }
                  autoComplete={false}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      if (e.target.value !== "") {
                        props.handleAdd(e.target.value);
                        e.target.value = "";
                      }
                      setSave(saveType.CLOSE);
                    }
                  }}
                  onBlur={async (e) => {
                    if (e.target.value !== "") {
                      await props.handleAdd(e.target.value);
                      e.target.value = "";
                    }
                    setSave(saveType.CLOSE);
                  }}
                />
                <TextButtonIcon
                  margin="0 0 16px 0px"
                  padding="0"
                  className="pointer"
                  display="inline-flex"
                  onClick={props.openTextBox}
                  onFocus={(e) => {
                    e.target.blur();
                  }}
                  overrides={{
                    Icon: {
                      onClick: () => {
                        listfld.current.scrollTop = listfld.current.scrollHeight;
                      },
                    },
                    "Text Button": {
                      children: getLabel("tsuika"),
                      onClick: () => {
                        listfld.current.scrollTop = listfld.current.scrollHeight;
                      },
                    },
                  }}
                />
              </Flex>
            </RadioGroupField>
          </Flex>
          <DialogButton
            margin="auto"
            overrides={{
              DefaultButton: {
                className: "pointer",
              },
              Button: {
                children: getLabel("dialog.back"),
                onClick: () => {
                  setDialogType(Type.SELECT);
                },
              },
              PrimaryButton: {
                className: "pointer",
                onClick: complete,
              },
            }}
          />
        </Flex>
      );
    },
    [dialogType, handleAddItem, complete],
  );
  const Dialog = useCallback(
    (props) => {
      return (
        <div className="react-confirm-alert">
          <div className="react-confirm-alert-overlay react-confirm-alert-overlay-custom">
            <div className="custom-ui" ref={dialogRef}>
              <Flex
                width={isMobile ? "295px" : "512px"}
                display="block"
                boxShadow="0px 15px 12px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.25)"
                padding="21px 23px 0px 23px"
                direction="column"
                backgroundColor="rgba(255,255,255,1)"
              >
                {props.type === Type.SELECT && <DialogSelect {...props} />}
                {(props.type === Type.FINANCIAL || props.type === Type.CARD) &&
                  !isLoadding && (
                    <ContactList
                      list={props.listData}
                      openAdd={props.isAdd}
                      handleAdd={props.handleAdd}
                      openTextBox={props.openTextBox}
                    />
                  )}
              </Flex>
            </div>
          </div>
        </div>
      );
    },
    [dialog, isLoadding],
  );

  return (
    <Flex
      className={
        isMobile ? "" : props.className ? props.className : "pccomponent"
      }
      direction="column"
      padding={isMobile ? "16px 16px 0px" : "0"}
      gap="0"
      {...props.overrideStyle}
    >
      <Text fontSize={isMobile ? "14px" : "16px"}>{props.label}</Text>
      <Text
        color="#5c6670"
        fontSize={isMobile ? "14px" : "16px"}
        textAlign="justify"
        className="word-break"
        width="fit-content"
        maxWidth="100vw"
        marginLeft="25px"
        children={selectedContact}
      />
      <TextButton
        className="pointer"
        onClick={() => {
          setDialog(true);
        }}
        overrides={{
          "Flex.Text[0]": {
            fontSize: isMobile ? "14px" : "16px",
            children: getLabel("kinyushisanCard"),
          },
        }}
      />
      {dialog ? (
        <Dialog
          type={dialogType}
          isAdd={save === saveType.OPEN}
          handleAdd={handleAddItem}
          openTextBox={openTextBox}
          listData={listData}
          {...props}
        />
      ) : null}
    </Flex>
  );
}
