import { DataStore } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { KaigoJoukyou, LeavesKaigo } from "../models";
import {
  ButtonCancelSave,
  SelectField,
  SwapperContent,
  TextField,
  TextFieldMultiline,
} from "../page-components";
import {
  defaultString,
  getLabel,
  isEmptyKaigon,
  useInitializePage,
} from "../utils/CommonUtils";
import {
  HIYOUKYOSHUTSU,
  KIBOUKAIGOSHURUI,
  KIBOUSHISETSU,
  useDatalist,
} from "../utils/DatalistUtils";
import { log } from "../utils/Logger";
import { usePrompt } from "../utils/Prompt";

/*
    handanwoyudanetaihito: "判断を委ねたい人",
    kiboukaigoshurui: "希望介護種類",
    kiboushisetsu: "希望施設",
    hiyoukyoshutsu: "費用拠出",
    zaisankanriwomakaseruhito: "財産管理を任せる人",
    zaisankanriwomakaseruhitonokeiyakujoukyou: "財産管理を任せる人の契約状況",
    bikou
*/
export default function () {
  const navigate = useInitializePage("kaigonitsuite", "title.update");
  const [data, setData] = useState();

  const [handanwoyudanetaihito, setHandanwoyudanetaihito] = useState("");
  const [zaisankanriwomakaseruhito, setZaisankanriwomakaseruhito] =
    useState("");
  const [
    zaisankanriwomakaseruhitonokeiyakujoukyou,
    setZaisankanriwomakaseruhitonokeiyakujoukyou,
  ] = useState("");
  const [bikou, setBikou] = useState("");

  const [handanwoyudanetaihitoError, setHandanwoyudanetaihitoError] =
    useState(null);
  const [kiboushisetsuError, setKiboushisetsuError] = useState(null);
  const [hiyoukyoshutsuError, setHiyoukyoshutsuError] = useState(null);
  const [zaisankanriwomakaseruhitoError, setZaisankanriwomakaseruhitoError] =
    useState(null);
  const [
    zaisankanriwomakaseruhitonokeiyakujoukyouError,
    setZaisankanriwomakaseruhitonokeiyakujoukyouError,
  ] = useState(null);
  const [bikouError, setBikouError] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);

  const [update, setUpdate] = useState(false);
  const [isEmptyData, setIsEmptyData] = useState(true);

  const [
    KiboukaigoshuruiDataList,
    setKiboukaigoshurui,
    getKiboukaigoshurui,
    hasErrorKiboukaigoshurui,
  ] = useDatalist({
    list: KIBOUKAIGOSHURUI,
    required: false,
    dataUpdateHook: setUpdate,
  });
  const [
    KiboushisetsuDataList,
    setKiboushisetsu,
    getKiboushisetsu,
    hasErrorKiboushisetsu,
  ] = useDatalist({
    list: KIBOUSHISETSU,
    required: false,
    dataUpdateHook: setUpdate,
  });
  const [
    HiyoukyoshutsuDataList,
    setHiyoukyoshutsu,
    getHiyoukyoshutsu,
    hasErrorHiyoukyoshutsu,
  ] = useDatalist({
    list: HIYOUKYOSHUTSU,
    required: false,
    dataUpdateHook: setUpdate,
  });
  

  usePrompt((location, action) => {
    if (action == "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  useEffect(async () => {
    const dsData = await DataStore.query(LeavesKaigo);
    log.debug("kaigo", dsData);
    if (dsData.length > 0) {
      setData(dsData[0]);
      setIsEmptyData(isEmptyKaigon(dsData[0]));
      setHandanwoyudanetaihito(dsData[0].handanwoyudanetaihito);
      setKiboukaigoshurui(dsData[0].kiboukaigoshurui);
      setKiboushisetsu(dsData[0].kiboushisetsu);
      setHiyoukyoshutsu(dsData[0].hiyoukyoshutsu);
      setZaisankanriwomakaseruhito(dsData[0].zaisankanriwomakaseruhito);
      setZaisankanriwomakaseruhitonokeiyakujoukyou(
        dsData[0].zaisankanriwomakaseruhitonokeiyakujoukyou,
      );
      setBikou(dsData[0].bikou);
    }
  }, []);
  
  const eventTracking = () => {
    if (isEmptyData) {
      window.gtag("event", "FirstWriteComplete", {
        event_category: "Edit",
        event_label: "介護について書込完了",
      });
    } else {
      window.gtag("event", "ChangeComplete", {
        event_category: "Edit",
        event_label: "介護について変更完了",
      });
    }
  };

  const updateData = async () => {
    log.debug(
      handanwoyudanetaihito +
        "," +
        getKiboukaigoshurui() +
        "," +
        getKiboushisetsu() +
        "," +
        getHiyoukyoshutsu() +
        "," +
        zaisankanriwomakaseruhito +
        "," +
        zaisankanriwomakaseruhitonokeiyakujoukyou +
        "," +
        bikou,
    );
    let valid = true;

    if (hasErrorKiboukaigoshurui()) {
      valid = false;
    }
    if (hasErrorKiboushisetsu()) {
      valid = false;
    }
    if (hasErrorHiyoukyoshutsu()) {
      valid = false;
    }
    if (valid) {
      log.debug("kaigo");
      setIsUpdated(true);
      DataStore.save(
        LeavesKaigo.copyOf(data, (updated) => {
          updated.handanwoyudanetaihito = handanwoyudanetaihito;
          updated.kiboukaigoshurui = getKiboukaigoshurui();
          updated.kiboushisetsu = getKiboushisetsu();
          updated.hiyoukyoshutsu = getHiyoukyoshutsu();
          updated.zaisankanriwomakaseruhito = zaisankanriwomakaseruhito;
          updated.zaisankanriwomakaseruhitonokeiyakujoukyou =
            zaisankanriwomakaseruhitonokeiyakujoukyou;
          updated.bikou = bikou;
        }),
      );
      eventTracking();
      navigate(-1, { replace: true });
    }
  };

  const kaigoJoukyouList = [];
  kaigoJoukyouList.push(
    <option key="default" value="">
      {getLabel("list.select")}
    </option>,
  );
  Object.keys(KaigoJoukyou).forEach((type) => {
    kaigoJoukyouList.push(
      <option key={type} value={type}>
        {getLabel("KaigoJoukyou." + type)}
      </option>,
    );
  });
  return (
    <SwapperContent label={getLabel("kaigonitsuite")}>
      <TextField
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("handanwoyudanetaihito")}
        value={handanwoyudanetaihito}
        errorMessage={handanwoyudanetaihitoError}
        placeHolder={getLabel("namae")}
        onChange={(event) => {
          setHandanwoyudanetaihito(event.target.value);
          setUpdate(true);
        }}
      />

      <KiboukaigoshuruiDataList label="kiboukaigoshurui" />
      <KiboushisetsuDataList label="kiboushisetsu" />
      <HiyoukyoshutsuDataList label="hiyoukyoshutsu" />

      <TextField
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("zaisankanriwomakaseruhito")}
        value={zaisankanriwomakaseruhito}
        errorMessage={zaisankanriwomakaseruhitoError}
        placeHolder={getLabel("namae")}
        onChange={(event) => {
          setZaisankanriwomakaseruhito(event.target.value);
          setUpdate(true);
        }}
      />
      <SelectField
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("zaisankanriwomakaseruhitonokeiyakujoukyou")}
        value={defaultString(zaisankanriwomakaseruhitonokeiyakujoukyou)}
        errorMessage={zaisankanriwomakaseruhitonokeiyakujoukyouError}
        options={kaigoJoukyouList}
        onChange={(event) => {
          setZaisankanriwomakaseruhitonokeiyakujoukyou(event.target.value);
          setUpdate(true);
        }}
      />
      <TextFieldMultiline
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("bikou")}
        value={bikou}
        errorMessage={bikouError}
        onChange={(event) => {
          setBikou(event.target.value);
          setUpdate(true);
        }}
      />
      <ButtonCancelSave
        cancelOnClick={() => navigate(-1, { replace: true })}
        saveOnClick={() => updateData()}
        isDisabled={isUpdated}
      />
    </SwapperContent>
  );
}
