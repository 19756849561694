/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Button, Flex, Text, View } from "@aws-amplify/ui-react";
export default function DialogButton(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="231px"
      height="34px"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "DialogButton")}
    >
      <Flex
        gap="0"
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="51.73%"
        direction="row"
        width="111.5px"
        height="34px"
        justifyContent="center"
        alignItems="center"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "DefaultButton")}
      >
        <Button
          display="flex"
          gap="0"
          direction="row"
          width="111.5px"
          justifyContent="center"
          alignItems="center"
          grow="1"
          basis="111.5px"
          height="34px"
          position="relative"
          border="1px SOLID rgba(174,179,183,1)"
          borderRadius="4px"
          padding="7px 11px 7px 11px"
          size="small"
          isDisabled={false}
          variation="default"
          {...getOverrideProps(overrides, "Button")}
        ></Button>
      </Flex>
      <Flex
        gap="0"
        position="absolute"
        top="0%"
        bottom="0%"
        left="51.73%"
        right="0%"
        direction="row"
        width="111.5px"
        height="34px"
        justifyContent="center"
        alignItems="center"
        borderRadius="4px"
        padding="8px 12px 8px 12px"
        backgroundColor="rgba(4,125,149,1)"
        {...getOverrideProps(overrides, "PrimaryButton")}
      >
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="700"
          color="rgba(255,255,255,1)"
          lineHeight="18px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          letterSpacing="0.41px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="完了"
          {...getOverrideProps(overrides, "\u270F\uFE0F Button text")}
        ></Text>
      </Flex>
    </View>
  );
}
