import { Image, Text } from "@aws-amplify/ui-react";
import React, { useMemo } from "react";
import DefaultButton from "./DefaultButton";
import share from "../assets/image/Share.png";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { getLabel } from "../utils/CommonUtils";

export default function ButtonShare(props) {
  const { label, desUrl, ...rest } = props;
  const navigate = useNavigate();

  const processLabel = useMemo(() => {
    if (label?.includes(getLabel("nitsuite"))) {
      return label?.split(getLabel("nitsuite"))[0];
    } else if (label?.includes(getLabel("button.addsuffix"))) {
      return label?.split(getLabel("button.addsuffix"))[0];
    } else return label;
  }, [label]);

  return isMobile ? (
    <DefaultButton
      marginBottom="32px"
      height="auto"
      padding="0 16px"
      overrides={{
        "Flex.Button[0]": {
          width: "fit-content",
          height: "auto",
          margin: "auto",
          children: (
            <>
              <Image src={share} width="22px" />
              <Text padding="0 0 0 5px">
                {processLabel + getLabel("button.sharesuffix")}
              </Text>
            </>
          ),
        },
      }}
      onClick={() => {
        navigate(desUrl ? desUrl : `share`);
      }}
      {...rest}
    />
  ) : (
    <DefaultButton
      height="auto"
      margin="0"
      onClick={() => {
        navigate(desUrl ? desUrl : `share`);
      }}
      overrides={{
        "Flex.Button[0]": {
          width: "fit-content",
          height: "auto",
          margin: "0",
          padding: "8px 16px 8px 12px",
          boxShadow: "none",
          children: (
            <>
              <Image src={share} width="22px" />
              <Text
                padding="0 0 0 5px"
                fontSize="16px"
                maxWidth="250px"
                width="100%"
                display="block"
                overflow="hidden"
                style={{
                  textOverflow: "ellipsis",
                }}
              >
                {processLabel + getLabel("button.sharesuffix")}
              </Text>
            </>
          ),
        },
      }}
      {...rest}
    />
  );
}
