import { Flex } from "@aws-amplify/ui-react";
import { FABDelete, FABEdit } from ".";
import { isEnableVideoCall } from "../utils/CommonUtils";

export default function FABDeleteEdit(props) {
  const { onDelete, onEdit } = props;

  return (
    <Flex
      display="block"
      position="fixed"
      bottom={isEnableVideoCall ? "160px" : "86px"}
      right="16px"
      style={{ zIndex: "1" }}
    >
      <FABDelete marginBottom="16px" deleteOnClick={onDelete} />
      <FABEdit onClick={onEdit} />
    </Flex>
  );
}
