import React, { useEffect } from "react";
import { LabelM, ListSingleLineA, DefaultButton } from "../page-components";
import { useInitializePage, getLabel } from "../utils/CommonUtils";
import { Auth } from "aws-amplify";

export default function () {
  const navigate = useInitializePage("other");
  function signOut() {
    Auth.signOut().then(() => {
      navigate("/");
    });
  }

  return (
    <div>
      <LabelM label={getLabel("other")} />
      <ListSingleLineA onClick={() => navigate("account")} label={getLabel("accountjouhou")} />

      <DefaultButton label={getLabel("logout")} onClick={signOut} />
    </div>
  );
}
