import { LabelM, ListSingleLineA } from "../page-components";
import { getLabel, useInitializePage } from "../utils/CommonUtils";

function MindAndBodyPage() {
  const navigate = useInitializePage("shinshinnokoto");
  return (
    <div>
      <LabelM label={getLabel("shinshinnokoto")} />
      <ListSingleLineA
        onClick={() => navigate("health")}
        label={getLabel("kenkoukanrinitsuite")}
      />
      <ListSingleLineA
        onClick={() => navigate("life")}
        label={getLabel("seikatsunokotonitsuite")}
      />
    </div>
  );
}

export default MindAndBodyPage;
