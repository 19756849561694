/* eslint-disable import/no-anonymous-default-export */
import { Flex, Loader, Text } from "@aws-amplify/ui-react";
import { isMobile } from "react-device-detect";
import { TextButton } from "../ui-components";
export default function (props) {
  const isLoading = props?.isLoading

  return (
    <TextButton
      className="pointer"
      width="fit-content"
      margin="32px auto"
      onClick={isLoading ? null : props.onClick}
      overrides={{
        "Flex.Text[0]": {
          children: (
            <Flex
              direction="row"
              alignItems="center"
              gap={isMobile ? "8px" : "6px"}
            >
              {isLoading && <Loader size="large" />}
              <Text color={isLoading ? "#5C6670" : "var(--my-theme-color)"}>
                {props.label}
              </Text>
            </Flex>
          ),
        },
      }}
    />
  );
}
