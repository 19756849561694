import { DataStore, Storage } from "aws-amplify";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { LeavesMemo } from "../models";
import {
  ListSelectContent,
  ListSingleLineVertical,
  ListTwoLineAPCVertical,
  StrageImage,
} from "../page-components";
import { getLabel, useInitializePage } from "../utils/CommonUtils";
import { log } from "../utils/Logger";
import { isShare, listObjects, queryDatas } from "../utils/ShareUtils";

export default function MemoPage() {
  const navigate = useInitializePage("jiyucho");
  const locate = useLocation();
  const { state } = locate;

  const [isLoaded, setIsLoaded] = useState(false);
  const [imageList, setImageList] = useState({});
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState(null);
  const params = useParams();

  const handledChangeActive = (item) => {
    setSelected(item);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const dsData = await queryDatas(params, LeavesMemo);
      log.debug("dsData", dsData);

      setIsLoaded(true);
      setList(dsData);
    };
    getData();
  }, []);

  const deleteData = async (data) => {
    log.debug("delete data", data);
    await DataStore.delete(data);
    await Storage.remove(imageList[data.id], {
      level: "private",
    });
    const newList = await DataStore.query(LeavesMemo);
    setList(newList);
  };

  useEffect(() => {
    let mount = true;
    if (list?.length > 0) {
      const images = {};
      const getList = async () => {
        const listPromise = [];
        for (let i = 0; i < list.length; i++) {
          listPromise.push(
            new Promise((resolve) => {
              listObjects(params, LeavesMemo, list[i]).then((res) => {
                if (res.length > 0) {
                  resolve({
                    id: list[i].id,
                    key: res[0].key,
                  });
                } else {
                  resolve({
                    id: list[i].id,
                    key: null,
                  });
                }
              });
            })
          );
        }
        const result = await Promise.all(listPromise);
        result.forEach((ele) => {
          images[ele.id] = ele.key;
        });
        if (mount) setImageList(images);
      };
      getList();
    }
    return () => (mount = false);
  }, [list, params]);

  useEffect(() => {
    if (list?.length > 0) {
      const item = list.find((ele) => ele?.id === state?.id);
      if (item) {
        setSelected(item);
      } else setSelected(list[0]);
    } else {
      setSelected(null);
    }
  }, [list, state?.id]);

  return (
    <ListSelectContent
      label={getLabel("jiyucho")}
      isInvitePage
      isHideLabel
      mobileList={list.map((data, index) => {
        return (
          <ListSingleLineVertical
            key={data?.id}
            title={data?.title}
            imageSrc={imageList[data?.id]}
            content={data?.memo}
            onClick={() => navigate(data?.id)}
          />
        );
      })}
      isShared={isShare(params)}
      addButtonLabel={getLabel("jiyucho") + getLabel("button.addsuffix")}
      onAddClick={() => {
        navigate("add");
      }}
      style={{ marginTop: "16px" }}
      pcListItem={list.map((ele, index) => {
        return (
          <ListTwoLineAPCVertical
            key={ele?.id}
            label={ele?.title}
            value={ele?.memo}
            selected={selected?.id === ele?.id}
            onClick={() => {
              handledChangeActive(ele);
            }}
          />
        );
      })}
      selectedItemList={[
        {
          label: getLabel("title"),
          value: selected?.title,
        },
        {
          label: getLabel("memoCategory"),
          value: selected?.memo,
        },
        {
          label: getLabel("tenpufile"),
          value: imageList[selected?.id] && (
            <StrageImage key={selected?.id} src={imageList[selected?.id]} />
          ),
        },
      ]}
      selectedItemLabel={selected?.title}
      onDelete={() => deleteData(selected)}
      onEdit={() => {
        navigate(selected?.id + "/edit");
      }}
      isLoaded={isLoaded}
    />
  );
}
