// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const Hokennoumu = {
  "KANYUU": "KANYUU",
  "MIKANYUU": "MIKANYUU"
};

const Renrakushurui = {
  "SURU": "SURU",
  "SHINAI": "SHINAI",
  "DOCHIRADEMO": "DOCHIRADEMO"
};

const KaigoJoukyou = {
  "ININKOUKEN": "ININKOUKEN",
  "ININ": "ININ",
  "NASHI": "NASHI"
};

const KinyuuShisanshurui = {
  "YOCHOKIN": "YOCHOKIN",
  "KABUSHKI": "KABUSHKI",
  "SAIKEN": "SAIKEN",
  "TOSHI_SHINTAKU": "TOSHI_SHINTAKU",
  "SHOHINKEN_KOGITTE": "SHOHINKEN_KOGITTE",
  "SONOTA": "SONOTA"
};

const JitsubutsuShisanshurui = {
  "FUDOUSAN": "FUDOUSAN",
  "KIKINZOKU": "KIKINZOKU",
  "COLLECTION": "COLLECTION",
  "SONOTA": "SONOTA"
};

const Tasseijoukyoushurui = {
  "JYUNBICHUU": "JYUNBICHUU",
  "TRYCHUU": "TRYCHUU",
  "TASSEI": "TASSEI"
};

const KanshinnoaruCategory = {
  "SHISANKANRIYASOUZOKU": "SHISANKANRIYASOUZOKU",
  "ROUGONOIKIGAI": "ROUGONOIKIGAI",
  "KAIGOYAIRYOU": "KAIGOYAIRYOU",
  "SOUGIYAOHAKA": "SOUGIYAOHAKA",
  "ZIBUNYAKAZOKUNOKIROKU": "ZIBUNYAKAZOKUNOKIROKU",
  "NINGENKANKEINOSEIRI": "NINGENKANKEINOSEIRI",
  "DIGITALNOSEIRI": "DIGITALNOSEIRI"
};

const { LeavesUsers, LeavesJitsubutsuShisan, LeavesKinyuuShisan, LeavesHoken, LeavesKaigo, LeavesSougi, LeavesLifeFuture, LeavesShuukan, LeavesMemo, LeavesKazokuShinzoku, LeavesJibunshi, LeavesHint, LeavesPet, LeavesIryousochi, LeavesCreditcard, LeavesKouza, LeavesNenkin, LeavesKankonsousai, LeavesSumahoPc, LeavesLoginId, LeavesYuujinChijin, LeavesKenkoukanri, LeavesSeikatsunokoto, LeavesTakaramono, LeavesEvents, LeavesMyEvent, LeavesHakanitsuite, LeavesDaijinishitaikinenbi, LeavesKakeizu, LeavesYuigonshonitsuite, LeavesTaisetsunishitaihitohenomessage, LeavesLoankariirekin, LeavesHosyousaimu, LeavesShareInfo, LeavesSharedInfo, LeavesSummaryWriting, LeavesMindTypeAnswerId, LeavesMindTypeAnswer, JitsugenStep, ShareTarget, WritingItem } = initSchema(schema);

export {
  LeavesUsers,
  LeavesJitsubutsuShisan,
  LeavesKinyuuShisan,
  LeavesHoken,
  LeavesKaigo,
  LeavesSougi,
  LeavesLifeFuture,
  LeavesShuukan,
  LeavesMemo,
  LeavesKazokuShinzoku,
  LeavesJibunshi,
  LeavesHint,
  LeavesPet,
  LeavesIryousochi,
  LeavesCreditcard,
  LeavesKouza,
  LeavesNenkin,
  LeavesKankonsousai,
  LeavesSumahoPc,
  LeavesLoginId,
  LeavesYuujinChijin,
  LeavesKenkoukanri,
  LeavesSeikatsunokoto,
  LeavesTakaramono,
  LeavesEvents,
  LeavesMyEvent,
  LeavesHakanitsuite,
  LeavesDaijinishitaikinenbi,
  LeavesKakeizu,
  LeavesYuigonshonitsuite,
  LeavesTaisetsunishitaihitohenomessage,
  LeavesLoankariirekin,
  LeavesHosyousaimu,
  LeavesShareInfo,
  LeavesSharedInfo,
  LeavesSummaryWriting,
  LeavesMindTypeAnswerId,
  LeavesMindTypeAnswer,
  Hokennoumu,
  Renrakushurui,
  KaigoJoukyou,
  KinyuuShisanshurui,
  JitsubutsuShisanshurui,
  Tasseijoukyoushurui,
  KanshinnoaruCategory,
  JitsugenStep,
  ShareTarget,
  WritingItem
};