/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Icon, Text, View, Image } from "@aws-amplify/ui-react";
export default function PCAskDialog(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
        width="373px"
        height="380px"
        overflow="hidden"
        position="relative"
        bottom="-40px"
        right="-25px"
    >
        <Flex
            gap="0"
            direction="column"
            width="359px"
            height="367px"
            justifyContent="flex-start"
            alignItems="flex-start"
            position="absolute"
            top="16px"
            left="0px"
        >
            <Flex
                gap="0"
                direction="column"
                width="359px"
                height="352px"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                border="2px SOLID rgba(104,134,73,1)"
                boxShadow="0px 6px 12px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.1599999964237213)"
                borderRadius="4px"
                backgroundColor="rgba(255,255,255,1)"
            >
                <Flex
                    gap="10px"
                    direction="column"
                    width="359px"
                    height="356px"
                    justifyContent="flex-start"
                    alignItems="center"
                    shrink="0"
                    position="relative"
                    padding="21px 23px 21px 23px"
                >
                    <Flex
                        width="247px"
                        height="49px"
                        shrink="0"
                        position="relative"
                        borderRadius="100px"
                    >
                        <View
                            width="247px"
                            height="49px"
                            display="block"
                            position="absolute"
                            top="0px"
                            left="0px"
                            border="1px SOLID rgba(104,134,73,1)"
                            borderRadius="100px"
                            backgroundColor="rgba(104,134,73,1)"
                        />
                        <Text
                            fontFamily="Zen Kaku Gothic New"
                            fontSize="15px"
                            fontWeight="500"
                            color="rgba(255,255,255,1)"
                            lineHeight="33px"
                            textAlign="center"
                            display="block"
                            position="absolute"
                            top="0px"
                            left="161px"
                            whiteSpace="pre-wrap"
                        >
                            （無料）
                        </Text>
                        <Text
                            fontFamily="Zen Kaku Gothic New"
                            fontSize="22px"
                            fontWeight="500"
                            color="rgba(255,255,255,1)"
                            lineHeight="34px"
                            textAlign="center"
                            display="block"
                            position="absolute"
                            top="-2px"
                            left="39px"
                            whiteSpace="pre-wrap"
                        >
                            相談してみる
                        </Text>
                        <Text
                            fontFamily="Zen Kaku Gothic New"
                            fontSize="15px"
                            fontWeight="500"
                            color="rgba(255,255,255,1)"
                            lineHeight="33px"
                            textAlign="center"
                            display="block"
                            position="absolute"
                            top="19px"
                            left="39px"
                            whiteSpace="pre-wrap"
                        >
                            話すだけで心が軽くなる?!
                        </Text>
                    </Flex>
                    <View
                        width="301px"
                        height="45px"
                        display="block"
                        shrink="0"
                        position="relative"
                        backgroundColor="rgba(255,255,255,1)"
                    />
                    <Flex
                        width="247px"
                        height="32px"
                        shrink="0"
                        position="relative"
                    >
                        <View
                            width="247px"
                            height="32px"
                            display="block"
                            position="absolute"
                            top="0px"
                            left="0px"
                            border="1px SOLID rgba(104,134,73,1)"
                            borderRadius="17px"
                            backgroundColor="rgba(217,217,217,0.07)"
                        />
                        <Text
                            fontFamily="Inter"
                            fontSize="16px"
                            fontWeight="700"
                            color="rgba(0,0,0,1)"
                            lineHeight="30px"
                            textAlign="left"
                            display="block"
                            width="163px"
                            height="32px"
                            position="absolute"
                            top="0px"
                            left="28px"
                            whiteSpace="pre-wrap"
                        >
                            これからの家族のこと
                        </Text>
                    </Flex>
                    <Flex
                        width="247px"
                        height="32px"
                        shrink="0"
                        position="relative"
                    >
                        <View
                            width="247px"
                            height="32px"
                            display="block"
                            position="absolute"
                            top="0px"
                            left="0px"
                            border="1px SOLID rgba(104,134,73,1)"
                            borderRadius="17px"
                            backgroundColor="rgba(217,217,217,0.07)"
                        />
                        <Text
                            fontFamily="Inter"
                            fontSize="16px"
                            fontWeight="700"
                            color="rgba(0,0,0,1)"
                            lineHeight="30px"
                            textAlign="left"
                            display="block"
                            width="181px"
                            height="32px"
                            position="absolute"
                            top="0px"
                            left="28px"
                            whiteSpace="pre-wrap"
                        >
                            これからの住まいのこと
                        </Text>
                    </Flex>
                    <Flex
                        width="247px"
                        height="32px"
                        shrink="0"
                        position="relative"
                    >
                        <View
                            width="247px"
                            height="32px"
                            display="block"
                            position="absolute"
                            top="0px"
                            left="0px"
                            border="1px SOLID rgba(104,134,73,1)"
                            borderRadius="17px"
                            backgroundColor="rgba(217,217,217,0.07)"
                        />
                        <Text
                            fontFamily="Inter"
                            fontSize="16px"
                            fontWeight="700"
                            color="rgba(0,0,0,1)"
                            lineHeight="30px"
                            textAlign="left"
                            display="block"
                            width="184px"
                            height="32px"
                            position="absolute"
                            top="0px"
                            left="28px"
                            whiteSpace="pre-wrap"
                        >
                            これからの暮らしのこと
                        </Text>
                    </Flex>
                    <Flex
                        width="247px"
                        height="32px"
                        shrink="0"
                        position="relative"
                    >
                        <View
                            width="247px"
                            height="32px"
                            display="block"
                            position="absolute"
                            top="0px"
                            left="0px"
                            border="1px SOLID rgba(104,134,73,1)"
                            borderRadius="17px"
                            backgroundColor="rgba(217,217,217,0.07)"
                        />
                        <Text
                            fontFamily="Inter"
                            fontSize="16px"
                            fontWeight="700"
                            color="rgba(0,0,0,1)"
                            lineHeight="30px"
                            textAlign="left"
                            display="block"
                            width="161px"
                            height="32px"
                            position="absolute"
                            top="0px"
                            left="28px"
                            whiteSpace="pre-wrap"
                        >
                            これからの資産のこと
                        </Text>
                    </Flex>
                    <Flex
                        width="247px"
                        height="32px"
                        shrink="0"
                        position="relative"
                    >
                        <View
                            width="247px"
                            height="32px"
                            display="block"
                            position="absolute"
                            top="0px"
                            left="0px"
                            border="1px SOLID rgba(104,134,73,1)"
                            borderRadius="17px"
                            backgroundColor="rgba(217,217,217,0.07)"
                        />
                        <Text
                            fontFamily="Inter"
                            fontSize="16px"
                            fontWeight="700"
                            color="rgba(0,0,0,1)"
                            lineHeight="30px"
                            textAlign="center"
                            display="block"
                            width="144px"
                            height="32px"
                            position="absolute"
                            top="0px"
                            left="28px"
                            whiteSpace="pre-wrap"
                        >
                            家族イベントのこと
                        </Text>
                    </Flex>
                    <Text
                        fontFamily="Inter"
                        fontSize="18px"
                        fontWeight="700"
                        color="rgba(28,26,29,1)"
                        lineHeight="24px"
                        textAlign="center"
                        display="block"
                        width="313px"
                        shrink="0"
                        position="absolute"
                        top="72px"
                        whiteSpace="pre-wrap"
                    >
                        電話番号：03-3477-6420
                    </Text>
                    <Text
                        fontFamily="Inter"
                        fontSize="14px"
                        fontWeight="400"
                        color="rgba(28,26,29,1)"
                        lineHeight="24px"
                        textAlign="center"
                        display="block"
                        width="85px"
                        height="19px"
                        shrink="0"
                        position="absolute"
                        top="93px"
                        whiteSpace="pre-wrap"
                    >
                        平日10〜18時
                    </Text>
                    <Text
                        fontFamily="Inter"
                        fontSize="12px"
                        fontWeight="400"
                        color="rgba(28,26,29,1)"
                        lineHeight="24px"
                        textAlign="center"
                        display="block"
                        width="171px"
                        height="18px"
                        shrink="0"
                        position="absolute"
                        top="112px"
                        left="102px"
                        whiteSpace="pre-wrap"
                    >
                        提供：東急ラヴィエール（株）
                    </Text>
                    <Image
                        width="40px"
                        height="127px"
                        display="block"
                        shrink="0"
                        position="absolute"
                        bottom="10px"
                        right="11px"
                        objectFit="cover"
                        alt=""
                        {...getOverrideProps(overrides, "ButlerIcon")}
                    />
                </Flex>
            </Flex>
        </Flex>
      <View
        padding="0px 0px 0px 0px"
        width="32px"
        height="32px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        bottom="91.5%"
        left="91.5%"
        right="0%"
        {...getOverrideProps(overrides, "CloseButton")}
      >
        <Icon
          width="32px"
          height="32px"
          viewBox={{ minX: 0, minY: 0, width: 32, height: 32 }}
          paths={[
            {
              d: "M30 16C30 23.732 23.732 30 16 30L16 34C25.9411 34 34 25.9411 34 16L30 16ZM16 30C8.26801 30 2 23.732 2 16L-2 16C-2 25.9411 6.05887 34 16 34L16 30ZM2 16C2 8.26801 8.26801 2 16 2L16 -2C6.05887 -2 -2 6.05887 -2 16L2 16ZM16 2C23.732 2 30 8.26801 30 16L34 16C34 6.05887 25.9411 -2 16 -2L16 2Z",
              stroke: "rgba(174,179,183,1)",
              fillRule: "nonzero",
              strokeWidth: 2,
            },
            {
              d: "M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z",
              fill: "rgba(255,255,255,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0%"
          bottom="0%"
          left="0%"
          right="0%"
          {...getOverrideProps(overrides, "Ellipse")}
        ></Icon>
        <Icon
          width="24px"
          height="24px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          position="absolute"
          top="12.5%"
          bottom="12.5%"
          left="12.5%"
          right="12.5%"
          padding="0px 0px 0px 0px"
          type="close"
          fontSize="24px"
          {...getOverrideProps(overrides, "Icon")}
        ></Icon>
      </View>
    </Flex>
  );
}
