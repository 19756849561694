export const defaultTexts = {
  BACK_SIGN_IN: 'Back to Sign In',
  BIRTHDATE: 'Birthdate',
  CHANGE_PASSWORD: 'Change Password',
  CHANGING_PASSWORD: 'Changing',
  CODE: 'Code',
  CONFIRM_PASSWORD: 'Confirm Password',
  CONFIRM_RESET_PASSWORD_HEADING: 'Reset your Password',
  CONFIRM_SIGNUP_HEADING: 'Confirm Sign Up',
  CONFIRM_SMS: 'Confirm SMS Code',
  CONFIRM_TOTP: 'Confirm TOTP Code',
  CONFIRM: 'Confirm',
  CONFIRMATION_CODE: 'Confirmation Code',
  CONFIRMING: 'Confirming',
  CREATE_ACCOUNT: 'Create Account',
  CREATING_ACCOUNT: 'Creating Account',
  EMAIL_ADDRESS: 'Email',
  ENTER_CODE: 'Enter your code',
  ENTER_USERNAME: 'Enter your username',
  FAMILY_NAME: 'Family Name',
  GIVEN_NAME: 'Given Name',
  FORGOT_YOUR_PASSWORD: 'Forgot your password?',
  FORGOT_YOUR_PASSWORD_LEGACY: 'Forgot your password? ',
  HIDE_PASSWORD: 'Hide password',
  LOADING: 'Loading',
  LOGIN_NAME: 'Username',
  MIDDLE_NAME: 'Middle Name',
  NAME: 'Name',
  NICKNAME: 'Nickname',
  NEW_PASSWORD: 'New password',
  PASSWORD: 'Password',
  PHONE_NUMBER: 'Phone Number',
  PREFERRED_USERNAME: 'Preferred Username',
  PROFILE: 'Profile',
  RESEND_CODE: 'Resend Code',
  RESET_PASSWORD_HEADING: 'Reset your password',
  RESET_PASSWORD: 'Reset Password',
  SEND_CODE: 'Send code',
  SENDING: 'Sending',
  SETUP_TOTP: 'Setup TOTP',
  SHOW_PASSWORD: 'Show password',
  SIGN_IN_BUTTON: 'Sign in',
  SIGN_IN_TAB: 'Sign In',
  SIGN_IN_WITH_AMAZON: 'Sign In with Amazon',
  SIGN_IN_WITH_APPLE: 'Sign In with Apple',
  SIGN_IN_WITH_FACEBOOK: 'Sign In with Facebook',
  SIGN_IN_WITH_GOOGLE: 'Sign In with Google',
  SIGN_IN: 'Sign in to your account',
  SIGN_UP_BUTTON: 'Create a new account',
  SIGNING_IN_BUTTON: 'Signing in',
  SKIP: 'Skip',
  SUBMIT: 'Submit',
  SUBMITTING: 'Submitting',
  VERIFY_CONTACT: 'Verify Contact',
  VERIFY_HEADING: 'Account recovery requires verified contact information',
  VERIFY: 'Verify',
  WEBSITE: 'Website',
};
