import { Fragment } from "react";
import { isMobile } from "react-device-detect";
import { getLabel } from "../utils/CommonUtils";
import ButtonCancelSave from "./ButtonCancelSave";
import CheckList from "./CheckList";
import CheckListShare from "./CheckListShare";
import DropdownDescription from "./DropdownDescription";
import FamiliesContactAddressField from "./FamiliesContactAddressField";
import FamilyAndFriendContact from "./FamilyAndFriendContact";
import FamilyAndFriendContactList from "./FamilyAndFriendContactList";
import FriendsContactAddressField from "./FriendsContactAddressField";
import ImageSelect from "./ImageSelect";
import LabelM from "./LabelM";
import RadioCustomGroup from "./RadioCustomGroup";
import RatioGroups from "./RatioGroups";
import SelectField from "./SelectField";
import SelectImageField from "./SelectImageField";
import TextField from "./TextField";
import TextFieldMultiline from "./TextFieldMultiline";

export default function ListField(props) {
  const { list, setState, setUpdate, onSave, onCancel, label, isDisabled, isDivide, autoSave } = props;

  return (
    <div className={isMobile ? "" : "swapper"}>
      <LabelM label={label} marginBottom={"0"} isDivide={isDivide} />
      <div
        style={
          isMobile
            ? {}
            : {
                marginTop: isDivide ? "24px" : "0",
              }
        }
      >
        {list.map(
          (
            {
              type,
              isRequire,
              label,
              subLabel,
              typeInput,
              value,
              placeHolder,
              errorMessage,
              child,
              options,
              isEdit,
              isDisabled,
              maxLength = 100,
              setStateRatio,
              defaultValueShow,
              listFriend = [],
              listFamily = [],
              active,
              handleChangeOption,
              description,
              errorList,
              commonShareDate,
              descriptionPC,
              isAddPage,
              defaultFile,
              key,
              isImage = false
            },
            index
          ) => {
            if (type === "textField") {
              return (
                <TextField
                  key={index}
                  className={isMobile ? "" : "maxwidth800"}
                  isRequired={isRequire}
                  label={getLabel(label)}
                  type={typeInput}
                  value={value}
                  maxLength={maxLength}
                  placeholder={getLabel(placeHolder)}
                  errorMessage={errorMessage}
                  disabled={isDisabled}
                  onChange={(event) => {
                    setState((s) => ({
                      ...s,
                      [label]: event.target.value,
                    }));
                    setUpdate(true);
                  }}
                />
              );
            } else if (type === "textArea") {
              return (
                <TextFieldMultiline
                  key={index}
                  className={isMobile ? "" : "maxwidth800"}
                  isRequired={isRequire}
                  label={getLabel(label + (subLabel ? subLabel : ""))}
                  value={value}
                  placeholder={getLabel(placeHolder)}
                  errorMessage={errorMessage}
                  onChange={(event) => {
                    setState((s) => ({
                      ...s,
                      [label]: event.target.value,
                    }));
                    setUpdate(true);
                  }}
                />
              );
            } else if (type === "dataList") {
              return <Fragment key={index}>{child}</Fragment>;
            } else if (type === "file") {
              return (
                <SelectImageField
                  key={index}
                  className={isMobile ? "" : "maxwidth800"}
                  isRequired={isRequire}
                  label={getLabel(label)}
                  objectSrc={value}
                  onlyImage={isImage}
                  errorMessage={errorMessage}
                  onChange={(event) => {
                    setState((s) => ({
                      ...s,
                      [label]: event.target.value,
                    }));
                    setUpdate(true);
                  }}
                />
              );
            } else if (type === "contactFamily") {
              return (
                <FamiliesContactAddressField
                  key={index}
                  className={isMobile ? "" : "maxwidth800"}
                  label={getLabel(label)}
                  selected={value}
                  onComplete={(value) => {
                    setState((s) => ({
                      ...s,
                      [label]: value,
                    }));
                    setUpdate(true);
                  }}
                />
              );
            } else if (type === "contactFriend") {
              return (
                <FriendsContactAddressField
                  key={index}
                  className={isMobile ? "" : "maxwidth800"}
                  label={getLabel(label)}
                  selected={value}
                  onComplete={(list) => {
                    setState((s) => ({
                      ...s,
                      [label]: list,
                    }));
                    setUpdate(true);
                  }}
                />
              );
            } else if (type === "selectField") {
              return (
                <SelectField
                  key={index}
                  className={isMobile ? "" : "maxwidth800"}
                  isRequired={isRequire}
                  label={getLabel(label)}
                  value={value}
                  errorMessage={errorMessage}
                  children={options}
                  onChange={(event) => {
                    setState((s) => ({
                      ...s,
                      [label]: event.target.value,
                    }));
                    setUpdate(true);
                  }}
                />
              );
            } else if (type === "checkList") {
              return (
                <CheckList
                  key={index}
                  label={getLabel(label)}
                  errorMessage={errorMessage}
                  isRequired
                  list={options}
                  listItemChecked={value}
                  isShareCheckList
                  onChange={(list) => {
                    setState((s) => {
                      return {
                        ...s,
                        [label]: list,
                      };
                    });
                    setUpdate(true);
                  }}
                />
              );
            } else if (type === "familyAndFriend") {
              return (
                <FamilyAndFriendContact
                  key={index}
                  className={isMobile ? "" : "maxwidth800"}
                  label={getLabel(label)}
                  selected={value}
                  defaultValueShow={defaultValueShow}
                  autoSave={autoSave}
                  onComplete={(value) => {
                    setState((s) => ({
                      ...s,
                      [label]: value,
                    }));
                    setUpdate(true);
                  }}
                />
              );
            } else if (type === "familyAndFriendList") {
              return (
                <FamilyAndFriendContactList
                  key={index}
                  className={isMobile ? "" : "maxwidth800"}
                  label={getLabel(label)}
                  selectedFamily={listFamily}
                  selectedFriend={listFriend}
                  defaultValueShow={defaultValueShow}
                  autoSave={autoSave}
                  onComplete={(value) => {
                    setState((s) => ({
                      ...s,
                      [label]: value,
                    }));
                    setUpdate(true);
                  }}
                />
              );
            } else if (type === "ratioGroup") {
              return (
                <RatioGroups
                  key={index}
                  label={getLabel(label)}
                  subLabel={subLabel}
                  list={options}
                  isRequired
                  value={value}
                  isDisabled={isDisabled}
                  onChange={(event) => {
                    setStateRatio((s) => ({
                      ...s,
                      [label]: event.target.value,
                    }));
                    setUpdate(true);
                  }}
                />
              );
            } else if (type === "radioCustomGroup") {
              return (
                <RadioCustomGroup
                    key={index}
                    label={getLabel(label)}
                    isRequired={isRequire}
                    options={options}
                    active={active}
                    handleChangeOption={handleChangeOption}
                />
              );
            } else if (type === "description") {
              return (
                <DropdownDescription title={label} description={description} />
              );
            } else if (type === "checkListShare") {
              return (
                <CheckListShare
                  key={index}
                  label={getLabel(label)}
                  errorMessage={errorMessage}
                  isRequired
                  list={options}
                  listItemChecked={value}
                  isShareCheckList
                  errorList={errorList}
                  commonShareDate={commonShareDate}
                  isAddPage={isAddPage}
                  onUpdate={() => setUpdate(true)}
                  onChange={(list) => {
                    setState((s) => {
                      return {
                        ...s,
                        [label]: list,
                      };
                    });
                  }}
                />
              );
            } else if (type === "image") {
              return (
                <ImageSelect
                  key={key ? key : index}
                  className={isMobile ? "" : "maxwidth800"}
                  isRequired={isRequire}
                  label={getLabel(label)}
                  objectSrc={value}
                  defaultValue={defaultFile}
                  errorMessage={errorMessage}
                  onChange={(event) => {
                    setState((s) => ({
                      ...s,
                      [label]: event.target.value,
                    }));
                    setUpdate(true);
                  }}
                />
              );
            } else return null;
          }
        )}
        <ButtonCancelSave cancelOnClick={onCancel} saveOnClick={onSave} isDisabled={isDisabled} />
      </div>
    </div>
  );
}
