import { DataStore } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import { LeavesHoken } from "../models";
import {
  ButtonCancelSave,
  SwapperContent,
  TextField,
  TextFieldMultiline,
} from "../page-components";
import { getLabel, isEmpty, useInitializePage } from "../utils/CommonUtils";
import { HOKENSHURUI, useDatalist } from "../utils/DatalistUtils";
import { log } from "../utils/Logger";
import { usePrompt } from "../utils/Prompt";

/*
  shisanmei: String
  shisanshurui: Shisanshurui
  shozaibasho: String
  bikou: String
  _shisanshurui: String
  leavesusersID: ID! @index(name: "byLeavesUsers")
*/

export default function () {
  const navigate = useInitializePage("hoken", "title.update");
  const [shisan, setShisan] = useState();

  const [hokenmei, setHokenmei] = useState("");
  const [hokengaisha, setHokengaisha] = useState("");
  const [bikou, setBikou] = useState("");

  const [hokenmeiError, setHokenmeiError] = useState(null);
  const [hokengaishaError, setHokengaishaError] = useState(null);
  const [bikouError, setBikouError] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);

  const [update, setUpdate] = useState(false);

  const [
    HokenshuruiDataList,
    setHokenshurui,
    getHokenshurui,
    hasErrorHokenshurui,
  ] = useDatalist({
    list: HOKENSHURUI,
    required: true,
    dataUpdateHook: setUpdate,
  });

  usePrompt((location, action) => {
    if (action == "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  let params = useParams();

  useEffect(async () => {
    const dsShisan = await DataStore.query(LeavesHoken, (c) =>
      c.id("eq", params.id),
    );
    log.debug("shisan", dsShisan);
    if (dsShisan.length > 0) {
      setShisan(dsShisan[0]);
      setHokenmei(dsShisan[0].hokenmei);
      setHokenshurui(dsShisan[0].hokenshurui);
      setHokengaisha(dsShisan[0].hokengaisha);
      setBikou(dsShisan[0].bikou);
    }
  }, []);

  const updateData = async () => {
    log.debug(
      hokenmei + "," + getHokenshurui() + "," + hokengaisha + "," + bikou,
    );
    let valid = true;
    if (isEmpty(hokengaisha)) {
      setHokengaishaError(getLabel("error.required"));
      valid = false;
    } else {
      setHokengaishaError(null);
    }
    if (hasErrorHokenshurui()) {
      valid = false;
    }

    if (valid) {
      setIsUpdated(true);
      DataStore.save(
        LeavesHoken.copyOf(shisan, (updated) => {
          updated.hokenmei = hokenmei;
          updated.hokenshurui = getHokenshurui();
          updated.hokengaisha = hokengaisha;
          updated.bikou = bikou;
        }),
      );
      window.gtag("event", "ChangeComplete", {
        event_category: "Edit",
        event_label: "保険変更完了",
      });
      navigate(-1, { replace: true });
    }
  };

  return (
    <SwapperContent label={getLabel("button.update")}>
      <HokenshuruiDataList label="hokenshurui" />
      <TextField
        isRequired
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("hokengaisha")}
        value={hokengaisha}
        errorMessage={hokengaishaError}
        onChange={(event) => {
          setHokengaisha(event.target.value);
          setUpdate(true);
        }}
      />
      <TextField
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("hokenmei")}
        value={hokenmei}
        errorMessage={hokenmeiError}
        placeHolder={getLabel("marumaruhokennado")}
        onChange={(event) => {
          setHokenmei(event.target.value);
          setUpdate(true);
        }}
      />
      <TextFieldMultiline
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("bikou")}
        value={bikou}
        errorMessage={bikouError}
        onChange={(event) => {
          setBikou(event.target.value);
          setUpdate(true);
        }}
      />
      <ButtonCancelSave
        cancelOnClick={() => navigate(-1, { replace: true })}
        saveOnClick={() => updateData()}
        isDisabled={isUpdated}
      />
    </SwapperContent>
  );
}
