export const i18n = {
  PaginationItem: {
    PreviousItem: {
      ariaLabel: 'Go to previous page',
    },
    NextItem: {
      ariaLabel: 'Go to next page',
    },
  },
};
