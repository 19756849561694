import { DataStore } from "aws-amplify";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useParams } from "react-router-dom";
import { LeavesUsers, LeavesYuigonshonitsuite } from "../models";
import { ListTwolineA, ListView, StrageImage } from "../page-components";
import UserContext from "../UserContext";
import {
  getLabel,
  isEmptyYuigon,
  useInitializePage,
} from "../utils/CommonUtils";
import { isShare, listObjects, queryData } from "../utils/ShareUtils";

function WillViewPage() {
  const navigate = useInitializePage("yuigonshonitsuite", "title.detail");
  const [data, setData] = useState();
  const [imageUrl, setImageUrl] = useState(null);
  const [isEmptyData, setIsEmptyData] = useState(true);
  const [isEmptyFile, setIsEmptFile] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const params = useParams();

  const context = useContext(UserContext);

  const isEmptyCategory = useMemo(() => {
    return isEmptyData && isEmptyFile;
  }, [isEmptyData, isEmptyFile]);

  const eventTracking = () => {
    if (isEmptyCategory) {
      window.gtag("event", "FirstWrite", {
        event_category: "Edit",
        event_label: "遺言書について書込開始",
      });
    } else {
      window.gtag("event", "Change", {
        event_category: "Edit",
        event_label: "遺言書について変更開始",
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const dataQuery = await queryData(params, LeavesYuigonshonitsuite);
      if (dataQuery?.length > 0) {
        setData(dataQuery[0]);
        setIsEmptyData(isEmptyYuigon(dataQuery[0]));
        if (!context.leavesUser.leavesUsersLeavesYuigonshonitsuitesId) {
          DataStore.save(
            LeavesUsers.copyOf(context.leavesUser, (updated) => {
              updated.leavesUsersLeavesYuigonshonitsuitesId = dataQuery[0].id;
            }),
          );
        }
      } else {
        const newData = await DataStore.save(
          new LeavesYuigonshonitsuite({
            yuigonshonoumu: "",
            yuigonshonoshurui: "",
            yuigonshonosaisyuusakuseibi: null,
            yuigonshikkousha: "",
            yuigonshikkoushanorenrakusaki: "",
            iraisoudanwoshiteirusenmonka: "",
            iraisoudanwoshiteirusenmonkanorenrakusaki: "",
            sonotasouzokunikansurukibounado: "",
            bikou: "",
          }),
        );
        DataStore.save(
          LeavesUsers.copyOf(context.leavesUser, (updated) => {
            updated.leavesUsersLeavesYuigonshonitsuitesId = newData.id;
          }),
        );
      }
    };
    getData();
  }, [context.leavesUser]);

  useEffect(() => {
    let mount = true;
    window.history.replaceState(
      { ...window.history?.state, usr: null },
      document.title,
    );
    if (data && data.id) {
      listObjects(params, LeavesYuigonshonitsuite, data).then((res) => {
        const url = res.length > 0 ? res[0].key : null;
        setIsEmptFile(url === null);
        setIsLoaded(true);
        if (mount) setImageUrl(url);
      });
    }
    return () => (mount = false);
  }, [data, isLoaded, params]);

  const listData = [
    { label: getLabel("yuigonshonoumu"), value: data?.yuigonshonoumu },
    {
      label: getLabel("yuigonshonoshurui"),
      value: data?.yuigonshonoshurui,
    },
    {
      type: "date",
      label: getLabel("yuigonshonosaisyuusakuseibi"),
      value: data?.yuigonshonosaisyuusakuseibi,
    },
    {
      type: "file",
      label: getLabel("tenpufile"),
      value: <StrageImage src={imageUrl} />,
    },
    {
      label: getLabel("yuigonshikkousha"),
      value: data?.yuigonshikkousha,
    },
    {
      label: getLabel("yuigonshikkoushanorenrakusaki"),
      value: data?.yuigonshikkoushanorenrakusaki,
    },
    {
      label: getLabel("iraisoudanwoshiteirusenmonka"),
      value: data?.iraisoudanwoshiteirusenmonka,
    },
    {
      label: getLabel("iraisoudanwoshiteirusenmonkanorenrakusaki"),
      value: data?.iraisoudanwoshiteirusenmonkanorenrakusaki,
    },
    {
      label: getLabel("sonotasouzokunikansurukibounado"),
      value: data?.sonotasouzokunikansurukibounado,
    },
    { label: getLabel("bikou"), value: data?.bikou },
  ];

  return (
    <ListView
      label={getLabel("yuigonshonitsuite")}
      description={{
        type: "will",
        category: "yuigonshonoumu",
      }}
      isLoaded={isLoaded}
      isShared={isShare(params)}
      isEmptyCategory={isEmptyCategory}
      onEdit={() => {
        navigate("edit");
        eventTracking();
      }}
      listMobile={listData.map((ele, index) => {
        return ele.type === "file" ? (
          <Fragment key={index}>{ele.value}</Fragment>
        ) : (
          <ListTwolineA
            key={index}
            label={ele.label}
            value={ele.value}
            type={ele.type}
          />
        );
      })}
      listPC={listData}
    />
  );
}

export default WillViewPage;
