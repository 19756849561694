/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Image, Text } from "@aws-amplify/ui-react";
export default function CardAsk(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
        gap="22px"
        direction="row"
        width="325px"
        height="120px"
        justifyContent="center"
        alignItems="center"
        position="relative"
        borderRadius="3px"
        padding="0px 0px 0px 26px"
        backgroundColor="rgba(255,255,255,1)"
        {...rest}
        {...getOverrideProps(overrides, "Button")}
    >
        <Flex
            gap="10px"
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="9px 9px 9px 9px"
            {...getOverrideProps(overrides, "Icon")}
        >
            <Image
                width="42px"
                height="42px"
                display="block"
                shrink="0"
                position="relative"
                objectFit="cover"
                alt=""
                {...getOverrideProps(overrides, "ask_cardASK")}
            />
        </Flex>
        <Flex
            gap="2px"
            direction="column"
            width="217px"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="25px 0px 30px 0px"
        >
            <Text
                fontFamily="Zen Kaku Gothic New"
                fontSize="14px"
                fontWeight="500"
                color="rgba(0,0,0,1)"
                lineHeight="25px"
                textAlign="left"
                display="block"
                shrink="0"
                position="absolute"
                top="30px"
                left="126px"
                whiteSpace="pre-wrap"
            >
                （無料）
            </Text>
            <Text
                fontFamily="Zen Kaku Gothic New"
                fontSize="22px"
                fontWeight="500"
                color="rgba(28,26,29,1)"
                lineHeight="34px"
                textAlign="left"
                display="block"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                whiteSpace="pre-wrap"
            >
                相談してみる
            </Text>
            <Text
                fontFamily="Zen Kaku Gothic New"
                fontSize="14px"
                fontWeight="500"
                color="rgba(28,26,29,1)"
                lineHeight="25px"
                textAlign="left"
                display="block"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                whiteSpace="pre-wrap"
            >
                話すだけで心が軽くなる!?
            </Text>
        </Flex>
    </Flex>
  );
}
