import { IconCheckCircle, Text } from "@aws-amplify/ui-react";
import React, { useMemo } from "react";
import { isMobile } from "react-device-detect";
import { ListTwoLineB as ListTwoLine } from "../ui-components";

export default function ListTwoLineBShare(props) {
  const { label, value, isPublished, onClick, isEnable } = props;

  const itemRender = useMemo(() => {
    return (
      <Text
        display="flex"
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        gap="5px"
      >
        {isPublished && (
          <IconCheckCircle
            width="18px"
            height="18px"
            fontSize="18px"
            color="#688649"
          />
        )}
        <Text
          fontSize="14px"
          color="#5C6670"
          className="text-overflow-ellipsis"
          maxWidth="calc(100vw - 100px)"
          whiteSpace="pre"
          overflow="hidden"
        >
          {value}
        </Text>
      </Text>
    );
  }, [isPublished, value]);

  const override = useMemo(() => {
    return {
      Title: {
        color: "#1C1A1D",
        children: label,
        display: "block",
        className: "text-overflow-ellipsis",
        maxWidth: "calc(100vw - 100px)",
        whiteSpace: "pre",
        overflow: "hidden",
        fontSize: "14px",
      },
      Item: {
        children: itemRender,
      },
      Icon: {
        paths: (isPublished || isEnable) && [
          {
            d: "M9.99984 6L8.58984 7.41L13.1698 12L8.58984 16.59L9.99984 18L15.9998 12L9.99984 6Z",
            fill: "#1C1A1D",
            fillRule: "nonzero",
          },
        ],
      },
    };
  }, [isEnable, isPublished, itemRender, label]);

  return (
    <ListTwoLine
      margin={isMobile ? "16px" : "16px auto"}
      width="auto"
      maxWidth={isMobile ? "100%" : "960px"}
      onClick={(isPublished || isEnable) ? onClick : undefined}
      className={isPublished ? "pointer" : ""}
      overrides={override}
    />
  );
}
