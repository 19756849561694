import { DataStore } from "aws-amplify";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { LeavesNenkin } from "../models";
import { ListField } from "../page-components";
import UserContext from "../UserContext";
import { getLabel, isEmpty, useInitializePage } from "../utils/CommonUtils";
import { log } from "../utils/Logger";
import { usePrompt } from "../utils/Prompt";

function PensionAddPage() {
  const navigate = useInitializePage("nenkin", "title.add");
  const context = useContext(UserContext);
  const [update, setUpdate] = useState(false);
  const [pensionData, setPensionData] = useState({
    nenkinmei: "",
    jyukyuugaku: "",
    nenkinshurui: "",
    bikou: "",
  });
  const [pensionDataError, setPensionDataError] = useState({
    nenkinmeiError: "",
    jyukyuugakuError: "",
    bikouError: "",
  });
  const [isAdded, setIsAdded] = useState(false);

  const listField = [
    {
      type: "textField",
      isRequire: true,
      label: "nenkinmei",
      errorMessage: pensionDataError.nenkinmeiError,
      placeHolder: "kokuminnenkinnado",
    },
    {
      type: "textField",
      typeInput: "number",
      maxLength: 100,
      label: "jyukyuugaku",
      errorMessage: pensionDataError.jyukyuugakuError,
      placeHolder: "ikkaiatarinokingaku",
    },
    {
      type: "textField",
      label: "nenkinshurui",
      value: pensionData?.nenkinshurui,
      placeHolder: "nikagetuniikkainado",
    },
    { type: "textArea", label: "bikou" },
  ];
  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  const addData = useCallback(async () => {
    let valid = true;
    const validNumber = isEmpty(pensionData?.jyukyuugaku)
      ? true
      : !isNaN(parseInt(pensionData?.jyukyuugaku));
    log.debug(pensionData?.jyukyuugaku);
    if (!validNumber || isEmpty(pensionData.nenkinmei)) {
      setPensionDataError({
        ...pensionDataError,
        jyukyuugakuError: validNumber ? "" : getLabel("error.number"),
        nenkinmeiError: isEmpty(pensionData.nenkinmei)
          ? getLabel("error.required")
          : "",
      });
      valid = false;
    }
    if (valid) {
      setIsAdded(true);
      await DataStore.save(
        new LeavesNenkin({
          nenkinmei: pensionData.nenkinmei,
          jyukyuugaku: pensionData.jyukyuugaku,
          nenkinshurui: pensionData.nenkinshurui,
          bikou: pensionData.bikou,
          leavesusersID: context.leavesUser.id,
        }),
      );
      window.gtag("event", "FirstWriteComplete", {
        event_category: "Edit",
        event_label: "年金書込完了",
      });
      navigate(-1, { replace: true });
    }
  }, [
    context.leavesUser.id,
    navigate,
    pensionData.bikou,
    pensionData.jyukyuugaku,
    pensionData.nenkinshurui,
    pensionData.nenkinmei,
    pensionDataError,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ListField
      label={getLabel("shinkitsuika")}
      list={listField}
      setState={setPensionData}
      setUpdate={setUpdate}
      onSave={addData}
      onCancel={() => navigate(-1, { replace: true })}
      isDisabled={isAdded}
    />
  );
}

export default PensionAddPage;
