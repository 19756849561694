import { IconContentCopy, IconShare } from "@aws-amplify/ui-react";
import { DataStore } from "aws-amplify";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { MODEL_NAME } from "../constants";
import { LeavesShareInfo } from "../models";
import { FABDeleteEdit, LabelM, ListTwoLineButton, ListTwolineA } from "../page-components";
import { getLabel, useInitializePage } from "../utils/CommonUtils";
import { log } from "../utils/Logger";
import { generateUrl, getShareMessage, isShareAccepted } from "../utils/ShareUtils";

export default function InviteViewPage() {
  const navigate = useInitializePage("invite", "title.detail"); /**TODO: */
  const [urlid, setUrlid] = useState({});
  const [invite, setInvite] = useState();
  const param = useParams();
  const [isCopyEnable, setIsCopyEnable] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (navigator.clipboard) {
      setIsCopyEnable(true);
    }

    const getData = async () => {
      const data = await DataStore.query(LeavesShareInfo, (c) => c.id("eq", param?.id));

      if (data?.length > 0) {
        // const sharedInfo = await API.graphql({
        //   query: listLeavesSharedInfos,
        //   variables: { filter: { shareInfoId: { eq: data[0].id } } },
        //   authMode: "AWS_IAM",
        // });
        // log.debug("get sharedInfo;" + data[0].id, sharedInfo.data.listLeavesSharedInfos.items);
        const isAccested = await isShareAccepted(data[0].id);
        const targetShare = data[0].target
          .filter((ele) => ele.dataName !== MODEL_NAME.HOSYOUSAIMU && ele.dataName !==  MODEL_NAME.SHUUKAN)
          .sort(function (a, b) {
            return new Date(a?.shareDate) - new Date(b?.shareDate);
          });
        const shareDateList = new Set(targetShare.map((ele) => ele?.shareDate));
        const dateSplit = [];
        shareDateList?.forEach((date) => {
          dateSplit.push({
            label: String(date).replaceAll("-","/") + getLabel("nikokaisurupeji"),
            value: targetShare
              .filter((ele) => ele?.shareDate === date)
              .map((t) => getLabel("Datastore." + t.dataName))
              .join("、"),
          });
        });
        const inviteData = {
          id: data[0].id,
          namae: data[0].name,
          sutetasu: isAccested
            ? getLabel("shotaishoninzumi")
            : getLabel("shotaichu"),
          splitList: dateSplit,
        };
        setInvite(inviteData);

        // return {
        //   id: d.id,
        //   namae: d.name,
        //   kokaibi: d.originDate,
        //   kyoyusurupeji: d.target
        //     .map((t) => getLabel("Datastore." + t.dataName))
        //     .join(","),
        //   sutetasu:
        //     sharedInfo.data.listLeavesSharedInfos.items.filter(
        //       (i) => !i._deleted,
        //     ).length > 0
        //       ? "招待承認済み"
        //       : "招待中",
        // };
      }
    };
    getData();
  }, []);

  const deleteData = async () => {
    const data = await DataStore.query(LeavesShareInfo, (c) => c.id("eq", invite.id));
    await DataStore.delete(data[0]);
    navigate(-1, { replace: true });
  };

  const generateShareUrl = useCallback(async () => {
    if (!urlid.url) {
      const urlObj = await generateUrl(invite.id);
      setUrlid(urlObj);

      return urlObj;
    } else {
      return urlid;
    }
  }, [invite?.id, urlid]);

  const handleCopy = async (e) => {
    window.gtag("event", "CopyInviteInfo", {
      event_category: "Share",
      event_label: "招待情報のコピーボタン",
    });
    e.preventDefault();
    const { url, expiredDate } = await generateShareUrl();
    const text = getShareMessage(url, expiredDate, invite.namae);
    navigator.clipboard.writeText(text).then(
      function () {
        log.debug("Success copy url");
        alert(getLabel("copied"));
      },
      function (err) {
        log.debug("Could not copy text: ", err);
        alert(getLabel("copyFail"));
      }
    );
  };
  const handleShare = async (e) => {
    window.gtag("event", "ShareInviteInfo", {
      event_category: "Share",
      event_label: "招待情報の共有ボタン",
    });
    e.preventDefault();
    const title = getLabel("notonokyoyu");
    const { url, expiredDate } = await generateShareUrl();
    const text = getShareMessage(url, expiredDate, invite.namae);
    if (navigator.canShare) {
      log.debug("share:navigator.share");
      navigator.share({
        title: title,
        text: text,
      });
    } else {
      log.debug("share:mailto");
      window.open(`mailto:?subject=${title}&body=${text.replaceAll("\n", "%0D%0A")}`);
    }
  };

  const dataSample = useMemo(() => {
    let renderList = [
      { label: "namae", value: invite?.namae },
    ];
    renderList = renderList.concat(
      invite?.splitList?.map((ele) => ({
        label: ele?.label,
        value: ele?.value,
      }))
    );
    renderList.push({
      label: "shareStatus",
      value: invite?.sutetasu,
    });
    return renderList;
  }, [invite]); 

  return (
    <div>
      <LabelM label={invite?.namae} />
      {dataSample.map((ele, idx) => {
        return <ListTwolineA key={idx} isEmptyHide={true} label={getLabel(ele?.label)} value={ele?.value} type={ele?.type} />;
      })}
      {invite?.sutetasu === getLabel("shotaichu") && (
        <ListTwoLineButton
          label=""
          value={getLabel("inviteURL")}
          buttons={
            isCopyEnable
              ? [
                  {
                    label: getLabel("copy"),
                    icon: <IconContentCopy color="var(--my-theme-color)" fontSize="18px" />,
                    onClick: handleCopy,
                  },
                  {
                    label: getLabel("share"),
                    icon: <IconShare color="var(--my-theme-color)" fontSize="18px" />,
                    onClick: handleShare,
                  },
                ]
              : [
                  {
                    label: getLabel("share"),
                    icon: <IconShare color="var(--my-theme-color)" fontSize="18px" />,
                    onClick: handleShare,
                  },
                ]
          }
        />
      )}
      <FABDeleteEdit
        onDelete={() => deleteData()}
        onEdit={() => {
          navigate("edit");
          window.gtag("event", "EditInviteInfo", {
            event_category: "Share",
            event_label: "招待情報編集 (Edit)",
          });
        }}
      />
    </div>
  );
}
