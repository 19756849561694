/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Image, Text } from "@aws-amplify/ui-react";
export default function PCCardAsk(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
        gap="12px"
        direction="column"
        width="528px"
        height="200px"
        justifyContent="flex-start"
        alignItems="center"
        position="relative"
        borderRadius="3px"
        padding="32px 0px 22px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...rest}
        {...getOverrideProps(overrides, "Button")}
    >
        <Flex
            gap="10px"
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
        >
            <Image
                width="50px"
                height="50px"
                display="block"
                shrink="0"
                position="relative"
                objectFit="cover"
                alt=""
                {...getOverrideProps(overrides, "Ask")}
            />
        </Flex>
        <Flex justifyContent="center" alignItem="center">
        <Text
            fontFamily="Zen Kaku Gothic New"
            fontSize="15px"
            fontWeight="500"
            color="rgba(28,26,29,1)"
            lineHeight="33px"
            textAlign="center"
            display="block"
            shrink="0"
            position="absolute"
            left="314px"
            top="96px"
            whiteSpace="pre-wrap"
        >
            （無料）
        </Text>
        <Text
            fontFamily="Zen Kaku Gothic New"
            fontSize="27px"
            fontWeight="500"
            color="rgba(28,26,29,1)"
            lineHeight="33px"
            textAlign="center"
            display="block"
            shrink="0"
            position="absolute"
            left="158px"
            top="95px"
            whiteSpace="pre-wrap"
        >
            相談してみる
        </Text>
        </Flex>
        <Text
            fontFamily="Zen Kaku Gothic New"
            fontSize="17px"
            fontWeight="500"
            color="rgba(28,26,29,1)"
            lineHeight="33px"
            textAlign="center"
            display="block"
            width="213px"
            height="31px"
            shrink="0"
            position="absolute"
            top="135px"
            whiteSpace="pre-wrap"
        >
            話すだけで心が軽くなる?!
        </Text>
    </Flex>
  );
}
