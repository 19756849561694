import React from "react";
import forward from "../assets/image/forward_circle.png";
import { PCListSingleLineTwoColum } from "../ui-components";

export default function ListSingleLineTwoColum(props) {
  return (
    <PCListSingleLineTwoColum
      className="pointer"
      width="100%"
      onClick={props.onClick}
      overrides={{
        list: {
          className: "text-overflow-ellipsis",
          maxWidth: "calc(100% - 55px)",
          whiteSpace: "pre",
          overflow: "hidden",
          display: "block",
          children: props.label,
        },
        "image 2": {
          left: "auto",
          right: "16px",
          src: forward,
        },
      }}
    />
  );
}
