import { DataStore, Storage } from "aws-amplify";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { LeavesKakeizu } from "../models";
import { ListField } from "../page-components";
import UserContext from "../UserContext";
import { getLabel, isEmpty, useInitializePage } from "../utils/CommonUtils";
import { log } from "../utils/Logger";
import { usePrompt } from "../utils/Prompt";

function FamilyTreeAddPage() {
  const navigate = useInitializePage("kakeizu", "title.add");
  const context = useContext(UserContext);
  const [isLoaded, setIsLoaded] = useState(false);

  const [familyTreeData, setFamilyTreeData] = useState({
    filemei: "",
    tenpufile: null,
    hokanbasyo: "",
    bikou: "",
    file: null,
  });
  const [isAdded, setIsAdded] = useState(false);
  const [familyTreeDataError, setFamilyTreeDataError] = useState({
    filemeiError: "",
    tenpufileError: "",
  });
  const listField = [
    {
      type: "textField",
      label: "filemei",
      isRequire: true,
      placeHolder: "yamadakenokakeizunado",
      errorMessage: familyTreeDataError.filemeiError,
    },
    {
      type: "file",
      label: "tenpufile",
      isRequire: true,
      value: familyTreeData.objectSrc,
      errorMessage: familyTreeDataError.tenpufileError,
    },
    {
      type: "textField",
      label: "hokanbasyo",
      placeHolder: "chounannojitakunado",
    },
    {
      type: "textArea",
      label: "bikou",
    },
  ];
  const [update, setUpdate] = useState(false);
  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  const addData = useCallback(async () => {
    console.log(familyTreeData);
    let valid = true;
    const error = {};
    if (isEmpty(familyTreeData.filemei)) {
      error.filemeiError = isEmpty(familyTreeData.filemei)
        ? getLabel("error.required")
        : "";
      valid = false;
    } else {
      error.filemeiError = null;
    }
    if (familyTreeData.tenpufile == null) {
      error.tenpufileError = getLabel("error.required");
      valid = false;
    } else {
      error.tenpufileError = null;
    }
    setFamilyTreeDataError(error);

    if (valid) {
      const saved = await DataStore.save(
        new LeavesKakeizu({
          filemei: familyTreeData.filemei,
          s3key: familyTreeData.s3key,
          hokanbasyo: familyTreeData.hokanbasyo,
          bikou: familyTreeData.bikou,
          leavesusersID: context.leavesUser.id,
        }),
      );
      const addData = async () => {
        setIsAdded(true);
        const result = await Storage.put(
          `LeavesKakeizu/${saved.id}/${familyTreeData.tenpufile.name}`,
          familyTreeData.tenpufile,
          {
            contentType: familyTreeData.tenpufile.type,
            level: "private",
          },
        );

        log.debug("s3 upload result", result);
        window.gtag("event", "FirstWriteComplete", {
          event_category: "Edit",
          event_label: "家系図書込完了",
        });
        navigate(-1, { replace: true });
      };
      addData();
    }
  }, [familyTreeData, context.leavesUser.id, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const modelData = await DataStore.query(LeavesKakeizu);
      if (modelData?.length >= 3) {
        navigate(-1, { replace: true });
      } else {
        setIsLoaded(true);
      }
    };
    getData();
  }, [navigate]);

  return (
    isLoaded && (
      <ListField
        label={getLabel("shinkitsuika")}
        list={listField}
        setState={setFamilyTreeData}
        setUpdate={setUpdate}
        onSave={addData}
        isDisabled={isAdded}
        onCancel={() => navigate(-1, { replace: true })}
      />
    )
  );
}

export default FamilyTreeAddPage;
