/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Icon, Image, Text, View } from "@aws-amplify/ui-react";
export default function AboutHiraqlNote(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="375px"
      height="13158.08px"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "AboutHiraqlNote")}
    >
      <Flex
        gap="10px"
        position="absolute"
        top="0%"
        bottom="99.64%"
        left="0%"
        right="0%"
        direction="row"
        width="375px"
        height="48px"
        alignItems="flex-start"
        padding="12px 16px 12px 16px"
        backgroundColor="rgba(251,249,246,1)"
        {...getOverrideProps(overrides, "HiraqlNoteHowto")}
      >
        <Text
          fontFamily="Inter"
          fontSize="20px"
          fontWeight="700"
          color="rgba(28,26,29,1)"
          lineHeight="24px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          letterSpacing="0.65px"
          width="343px"
          grow="1"
          basis="343px"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="HIRAQL NOTEの使い方"
          {...getOverrideProps(
            overrides,
            "HIRAQL NOTE\u306E\u4F7F\u3044\u65B9"
          )}
        ></Text>
      </Flex>
      <View
        position="absolute"
        top="3.16%"
        bottom="93.61%"
        left="4.27%"
        right="4.27%"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "CardData")}
      >
        <Flex
          gap="10px"
          position="absolute"
          top="0%"
          bottom="0%"
          left="0%"
          right="0%"
          direction="row"
          width="343px"
          height="424.99px"
          alignItems="flex-start"
          borderRadius="3px"
          padding="0px 20px 20px 0px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "BackImageData")}
        >
          <Image
            width="70px"
            height="70px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "data_cardIC")}
          ></Image>
        </Flex>
        <Flex
          gap="25px"
          position="absolute"
          top="0px"
          left="calc(50% - 171.5px - 1px)"
          direction="column"
          width="343px"
          alignItems="center"
          padding="24px 20px 20px 20px"
          {...getOverrideProps(overrides, "ContentData")}
        >
          <Flex
            gap="21px"
            direction="column"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "TextData")}
          >
            <Flex
              gap="0"
              direction="column"
              width="285px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "TitleData")}
            >
              <Text
                fontFamily="Zen Kaku Gothic New"
                fontSize="25px"
                fontWeight="500"
                color="rgba(28,26,29,1)"
                lineHeight="33px"
                textAlign="center"
                display="flex"
                direction="column"
                justifyContent="flex-start"
                shrink="0"
                alignSelf="stretch"
                objectFit="cover"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="私のデータ"
                {...getOverrideProps(
                  overrides,
                  "\u79C1\u306E\u30C7\u30FC\u30BF"
                )}
              ></Text>
              <Text
                fontFamily="Zen Kaku Gothic New"
                fontSize="15px"
                fontWeight="500"
                color="rgba(28,26,29,1)"
                lineHeight="30px"
                textAlign="center"
                display="flex"
                direction="column"
                justifyContent="flex-start"
                shrink="0"
                alignSelf="stretch"
                objectFit="cover"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="MY DATA"
                {...getOverrideProps(overrides, "MY DATA")}
              ></Text>
            </Flex>
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(28,26,29,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              shrink="0"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="金融資産、保険、友人の連絡先、スマホやPCの情報、ペットのことなどを整理しておくことができます。“もしもの時”に自分自身や家族がとても助かります。"
              {...getOverrideProps(
                overrides,
                "\u91D1\u878D\u8CC7\u7523\u3001\u4FDD\u967A\u3001\u53CB\u4EBA\u306E\u9023\u7D61\u5148\u3001\u30B9\u30DE\u30DB\u3084PC\u306E\u60C5\u5831\u3001\u30DA\u30C3\u30C8\u306E\u3053\u3068\u306A\u3069\u3092\u6574\u7406\u3057\u3066\u304A\u304F\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002\u201C\u3082\u3057\u3082\u306E\u6642\u201D\u306B\u81EA\u5206\u81EA\u8EAB\u3084\u5BB6\u65CF\u304C\u3068\u3066\u3082\u52A9\u304B\u308A\u307E\u3059\u3002"
              )}
            ></Text>
          </Flex>
          <Flex
            gap="8px"
            direction="column"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "DataPoint")}
          >
            <Flex
              gap="16px"
              direction="row"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "DataPoint1")}
            >
              <Icon
                width="24px"
                height="24px"
                shrink="0"
                overflow="hidden"
                position="relative"
                padding="0px 0px 0px 0px"
                type="done"
                fontSize="24px"
                {...getOverrideProps(overrides, "Icon790918118")}
              ></Icon>
              <Flex
                gap="16px"
                direction="row"
                width="263px"
                alignItems="flex-start"
                grow="1"
                basis="263px"
                height="96px"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 556790918119")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="14px"
                  fontWeight="400"
                  color="rgba(28,26,29,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="flex"
                  direction="column"
                  justifyContent="flex-start"
                  width="263px"
                  grow="1"
                  basis="263px"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="シェア機能を使って、登録したデータを、もしもの時のために、家族など大切な人と共有しておくと良いかもしれません"
                  {...getOverrideProps(
                    overrides,
                    "\u30B7\u30A7\u30A2\u6A5F\u80FD\u3092\u4F7F\u3063\u3066\u3001\u767B\u9332\u3057\u305F\u30C7\u30FC\u30BF\u3092\u3001\u3082\u3057\u3082\u306E\u6642\u306E\u305F\u3081\u306B\u3001\u5BB6\u65CF\u306A\u3069\u5927\u5207\u306A\u4EBA\u3068\u5171\u6709\u3057\u3066\u304A\u304F\u3068\u826F\u3044\u304B\u3082\u3057\u308C\u307E\u305B\u3093"
                  )}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="16px"
              direction="row"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "DataPoint2")}
            >
              <Icon
                width="24px"
                height="24px"
                shrink="0"
                overflow="hidden"
                position="relative"
                padding="0px 0px 0px 0px"
                type="done"
                fontSize="24px"
                {...getOverrideProps(overrides, "Icon790918122")}
              ></Icon>
              <Flex
                gap="16px"
                direction="row"
                width="263px"
                alignItems="flex-start"
                grow="1"
                basis="263px"
                height="72px"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 556790918123")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="14px"
                  fontWeight="400"
                  color="rgba(28,26,29,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="flex"
                  direction="column"
                  justifyContent="flex-start"
                  width="263px"
                  grow="1"
                  basis="263px"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="大切にしたい記念日をメモしたり、自分が決めたタイミングで、自分が決めた人に、リマインドしてみてはどうでしょう"
                  {...getOverrideProps(
                    overrides,
                    "\u5927\u5207\u306B\u3057\u305F\u3044\u8A18\u5FF5\u65E5\u3092\u30E1\u30E2\u3057\u305F\u308A\u3001\u81EA\u5206\u304C\u6C7A\u3081\u305F\u30BF\u30A4\u30DF\u30F3\u30B0\u3067\u3001\u81EA\u5206\u304C\u6C7A\u3081\u305F\u4EBA\u306B\u3001\u30EA\u30DE\u30A4\u30F3\u30C9\u3057\u3066\u307F\u3066\u306F\u3069\u3046\u3067\u3057\u3087\u3046"
                  )}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </View>
      <Image
        position="absolute"
        top="6.51%"
        bottom="91.53%"
        height="1.95%"
        left="4.27%"
        right="4.27%"
        width="91.47%"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "DataSample")}
      ></Image>
      <View
        position="absolute"
        top="8.59%"
        bottom="87.23%"
        left="4.27%"
        right="4.27%"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "CardWill")}
      >
        <Flex
          gap="10px"
          position="absolute"
          top="0%"
          bottom="0%"
          left="0%"
          right="0%"
          direction="row"
          width="343px"
          height="549.99px"
          alignItems="flex-start"
          borderRadius="3px"
          padding="0px 20px 20px 0px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "BackImageWill")}
        >
          <Image
            width="70px"
            height="70px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "will_cardIC")}
          ></Image>
        </Flex>
        <Flex
          gap="25px"
          position="absolute"
          top="0px"
          left="0%"
          right="0%"
          direction="column"
          width="343px"
          height="550px"
          alignItems="center"
          padding="24px 20px 20px 20px"
          {...getOverrideProps(overrides, "ContentWill")}
        >
          <Flex
            gap="21px"
            direction="column"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "TextWill")}
          >
            <Flex
              gap="0"
              direction="column"
              width="285px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "TitleWill")}
            >
              <Text
                fontFamily="Zen Kaku Gothic New"
                fontSize="25px"
                fontWeight="500"
                color="rgba(28,26,29,1)"
                lineHeight="33px"
                textAlign="center"
                display="flex"
                direction="column"
                justifyContent="flex-start"
                shrink="0"
                alignSelf="stretch"
                objectFit="cover"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="私の意思"
                {...getOverrideProps(overrides, "\u79C1\u306E\u610F\u601D")}
              ></Text>
              <Text
                fontFamily="Zen Kaku Gothic New"
                fontSize="15px"
                fontWeight="500"
                color="rgba(28,26,29,1)"
                lineHeight="30px"
                textAlign="center"
                display="flex"
                direction="column"
                justifyContent="flex-start"
                shrink="0"
                alignSelf="stretch"
                objectFit="cover"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="WILL"
                {...getOverrideProps(overrides, "WILL")}
              ></Text>
            </Flex>
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(28,26,29,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              shrink="0"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="自分の”意思”を示すためのページ。人生でやりたいこと、行ってみたい場所、夢や野望の実現プラン、介護や医療措置、相続、葬儀、お墓など、次世代に何をどのように遺していくのか。一度きりの人生を自分らしく生きるために考えてみましょう。"
              {...getOverrideProps(
                overrides,
                "\u81EA\u5206\u306E\u201D\u610F\u601D\u201D\u3092\u793A\u3059\u305F\u3081\u306E\u30DA\u30FC\u30B8\u3002\u4EBA\u751F\u3067\u3084\u308A\u305F\u3044\u3053\u3068\u3001\u884C\u3063\u3066\u307F\u305F\u3044\u5834\u6240\u3001\u5922\u3084\u91CE\u671B\u306E\u5B9F\u73FE\u30D7\u30E9\u30F3\u3001\u4ECB\u8B77\u3084\u533B\u7642\u63AA\u7F6E\u3001\u76F8\u7D9A\u3001\u846C\u5100\u3001\u304A\u5893\u306A\u3069\u3001\u6B21\u4E16\u4EE3\u306B\u4F55\u3092\u3069\u306E\u3088\u3046\u306B\u907A\u3057\u3066\u3044\u304F\u306E\u304B\u3002\u4E00\u5EA6\u304D\u308A\u306E\u4EBA\u751F\u3092\u81EA\u5206\u3089\u3057\u304F\u751F\u304D\u308B\u305F\u3081\u306B\u8003\u3048\u3066\u307F\u307E\u3057\u3087\u3046\u3002"
              )}
            ></Text>
            <Flex
              gap="8px"
              direction="column"
              shrink="0"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "WillPoint")}
            >
              <Flex
                gap="16px"
                direction="row"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                objectFit="cover"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "WillPoint1")}
              >
                <Icon
                  width="24px"
                  height="24px"
                  shrink="0"
                  overflow="hidden"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  type="done"
                  fontSize="24px"
                  {...getOverrideProps(overrides, "Icon790918137")}
                ></Icon>
                <Flex
                  gap="16px"
                  direction="row"
                  width="263px"
                  alignItems="flex-start"
                  grow="1"
                  basis="262.99993896484375px"
                  height="72px"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Frame 556790918138")}
                >
                  <Text
                    fontFamily="Inter"
                    fontSize="14px"
                    fontWeight="400"
                    color="rgba(28,26,29,1)"
                    lineHeight="24px"
                    textAlign="left"
                    display="flex"
                    direction="column"
                    justifyContent="flex-start"
                    width="263px"
                    grow="1"
                    basis="262.99993896484375px"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="やりたいことリストを使って、いつかやろうと思っていたことを実現してみましょう"
                    {...getOverrideProps(
                      overrides,
                      "\u3084\u308A\u305F\u3044\u3053\u3068\u30EA\u30B9\u30C8\u3092\u4F7F\u3063\u3066\u3001\u3044\u3064\u304B\u3084\u308D\u3046\u3068\u601D\u3063\u3066\u3044\u305F\u3053\u3068\u3092\u5B9F\u73FE\u3057\u3066\u307F\u307E\u3057\u3087\u3046"
                    )}
                  ></Text>
                </Flex>
              </Flex>
              <Flex
                gap="16px"
                direction="row"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                objectFit="cover"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "WillPoint2")}
              >
                <Icon
                  width="24px"
                  height="24px"
                  shrink="0"
                  overflow="hidden"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  type="done"
                  fontSize="24px"
                  {...getOverrideProps(overrides, "Icon790918141")}
                ></Icon>
                <Flex
                  gap="16px"
                  direction="row"
                  width="263px"
                  alignItems="flex-start"
                  grow="1"
                  basis="262.99993896484375px"
                  height="96px"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Frame 556790918142")}
                >
                  <Text
                    fontFamily="Inter"
                    fontSize="14px"
                    fontWeight="400"
                    color="rgba(28,26,29,1)"
                    lineHeight="24px"
                    textAlign="left"
                    display="flex"
                    direction="column"
                    justifyContent="flex-start"
                    width="263px"
                    grow="1"
                    basis="262.99993896484375px"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="大切な人へのビデオメッセージをオンラインに保管して、自分が決めたタイミング（例えばお子様のご結婚など）で、大切な人に届けてみませんか"
                    {...getOverrideProps(
                      overrides,
                      "\u5927\u5207\u306A\u4EBA\u3078\u306E\u30D3\u30C7\u30AA\u30E1\u30C3\u30BB\u30FC\u30B8\u3092\u30AA\u30F3\u30E9\u30A4\u30F3\u306B\u4FDD\u7BA1\u3057\u3066\u3001\u81EA\u5206\u304C\u6C7A\u3081\u305F\u30BF\u30A4\u30DF\u30F3\u30B0\uFF08\u4F8B\u3048\u3070\u304A\u5B50\u69D8\u306E\u3054\u7D50\u5A5A\u306A\u3069\uFF09\u3067\u3001\u5927\u5207\u306A\u4EBA\u306B\u5C4A\u3051\u3066\u307F\u307E\u305B\u3093\u304B"
                    )}
                  ></Text>
                </Flex>
              </Flex>
              <Flex
                gap="16px"
                direction="row"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                objectFit="cover"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "WillPoint3")}
              >
                <Icon
                  width="24px"
                  height="24px"
                  shrink="0"
                  overflow="hidden"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  type="done"
                  fontSize="24px"
                  {...getOverrideProps(overrides, "Icon790918145")}
                ></Icon>
                <Flex
                  gap="16px"
                  direction="row"
                  width="263px"
                  alignItems="flex-start"
                  grow="1"
                  basis="262.99993896484375px"
                  height="72px"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Frame 556790918146")}
                >
                  <Text
                    fontFamily="Inter"
                    fontSize="14px"
                    fontWeight="400"
                    color="rgba(28,26,29,1)"
                    lineHeight="24px"
                    textAlign="left"
                    display="flex"
                    direction="column"
                    justifyContent="flex-start"
                    width="263px"
                    grow="1"
                    basis="262.99993896484375px"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="誰かに相談したり、行動に移したくなった際には、ノートからリアルなサービスをご案内します"
                    {...getOverrideProps(
                      overrides,
                      "\u8AB0\u304B\u306B\u76F8\u8AC7\u3057\u305F\u308A\u3001\u884C\u52D5\u306B\u79FB\u3057\u305F\u304F\u306A\u3063\u305F\u969B\u306B\u306F\u3001\u30CE\u30FC\u30C8\u304B\u3089\u30EA\u30A2\u30EB\u306A\u30B5\u30FC\u30D3\u30B9\u3092\u3054\u6848\u5185\u3057\u307E\u3059"
                    )}
                  ></Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </View>
      <Image
        position="absolute"
        top="12.89%"
        bottom="85.16%"
        height="1.96%"
        left="4.27%"
        right="4.27%"
        width="91.47%"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "WillSample")}
      ></Image>
      <View
        position="absolute"
        top="14.96%"
        bottom="82.02%"
        left="4.27%"
        right="4.27%"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "CardHistory")}
      >
        <Flex
          gap="10px"
          position="absolute"
          top="0%"
          bottom="0%"
          left="0%"
          right="0%"
          direction="row"
          width="343px"
          height="396.99px"
          alignItems="flex-start"
          borderRadius="3px"
          padding="0px 20px 20px 0px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "BackImageHistory")}
        >
          <Image
            width="70px"
            height="70px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "history_cardIC")}
          ></Image>
        </Flex>
        <Flex
          gap="25px"
          position="absolute"
          top="0px"
          left="calc(50% - 171.5px - 1px)"
          direction="column"
          width="343px"
          height="397px"
          alignItems="center"
          padding="24px 20px 20px 20px"
          {...getOverrideProps(overrides, "ContentHistory")}
        >
          <Flex
            gap="21px"
            direction="column"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "TextHistory")}
          >
            <Flex
              gap="0"
              direction="column"
              width="285px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "TitleHint790918154")}
            >
              <Text
                fontFamily="Zen Kaku Gothic New"
                fontSize="25px"
                fontWeight="500"
                color="rgba(28,26,29,1)"
                lineHeight="33px"
                textAlign="center"
                display="flex"
                direction="column"
                justifyContent="flex-start"
                shrink="0"
                alignSelf="stretch"
                objectFit="cover"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="大切なもの"
                {...getOverrideProps(
                  overrides,
                  "\u5927\u5207\u306A\u3082\u306E"
                )}
              ></Text>
              <Text
                fontFamily="Zen Kaku Gothic New"
                fontSize="15px"
                fontWeight="500"
                color="rgba(28,26,29,1)"
                lineHeight="30px"
                textAlign="center"
                display="flex"
                direction="column"
                justifyContent="flex-start"
                shrink="0"
                alignSelf="stretch"
                objectFit="cover"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="HISTORY"
                {...getOverrideProps(overrides, "HISTORY")}
              ></Text>
            </Flex>
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(28,26,29,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              shrink="0"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="自分史や親族、宝物リストなど、大切なものごとを記録することができます。これまでの人生を振り返り、これから何を大切にして生きていきたいか考えるきっかけに。"
              {...getOverrideProps(
                overrides,
                "\u81EA\u5206\u53F2\u3084\u89AA\u65CF\u3001\u5B9D\u7269\u30EA\u30B9\u30C8\u306A\u3069\u3001\u5927\u5207\u306A\u3082\u306E\u3054\u3068\u3092\u8A18\u9332\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002\u3053\u308C\u307E\u3067\u306E\u4EBA\u751F\u3092\u632F\u308A\u8FD4\u308A\u3001\u3053\u308C\u304B\u3089\u4F55\u3092\u5927\u5207\u306B\u3057\u3066\u751F\u304D\u3066\u3044\u304D\u305F\u3044\u304B\u8003\u3048\u308B\u304D\u3063\u304B\u3051\u306B\u3002"
              )}
            ></Text>
            <Flex
              gap="8px"
              direction="column"
              shrink="0"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "HistoryCheck")}
            >
              <Flex
                gap="16px"
                direction="row"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                objectFit="cover"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "HistoryCheck1")}
              >
                <Icon
                  width="24px"
                  height="24px"
                  shrink="0"
                  overflow="hidden"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  type="done"
                  fontSize="24px"
                  {...getOverrideProps(overrides, "Icon790918160")}
                ></Icon>
                <Flex
                  gap="16px"
                  direction="row"
                  width="263px"
                  alignItems="flex-start"
                  grow="1"
                  basis="263px"
                  height="96px"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Frame 556790918161")}
                >
                  <Text
                    fontFamily="Inter"
                    fontSize="14px"
                    fontWeight="400"
                    color="rgba(28,26,29,1)"
                    lineHeight="24px"
                    textAlign="left"
                    display="flex"
                    direction="column"
                    justifyContent="flex-start"
                    width="263px"
                    grow="1"
                    basis="263px"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="思い出の写真とともに自分史を振り返り、家族とシェアしながら、これからのことを話す機会を作ってみてはどうでしょう"
                    {...getOverrideProps(
                      overrides,
                      "\u601D\u3044\u51FA\u306E\u5199\u771F\u3068\u3068\u3082\u306B\u81EA\u5206\u53F2\u3092\u632F\u308A\u8FD4\u308A\u3001\u5BB6\u65CF\u3068\u30B7\u30A7\u30A2\u3057\u306A\u304C\u3089\u3001\u3053\u308C\u304B\u3089\u306E\u3053\u3068\u3092\u8A71\u3059\u6A5F\u4F1A\u3092\u4F5C\u3063\u3066\u307F\u3066\u306F\u3069\u3046\u3067\u3057\u3087\u3046"
                    )}
                  ></Text>
                </Flex>
              </Flex>
              <Flex
                gap="16px"
                direction="row"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                objectFit="cover"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "HistoryCheck2")}
              >
                <Icon
                  width="24px"
                  height="24px"
                  shrink="0"
                  overflow="hidden"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  type="done"
                  fontSize="24px"
                  {...getOverrideProps(overrides, "Icon790918164")}
                ></Icon>
                <Flex
                  gap="16px"
                  direction="row"
                  width="263px"
                  alignItems="flex-start"
                  grow="1"
                  basis="263px"
                  height="48px"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Frame 556790918165")}
                >
                  <Text
                    fontFamily="Inter"
                    fontSize="14px"
                    fontWeight="400"
                    color="rgba(28,26,29,1)"
                    lineHeight="24px"
                    textAlign="left"
                    display="flex"
                    direction="column"
                    justifyContent="flex-start"
                    width="263px"
                    grow="1"
                    basis="263px"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="宝物リストを整理しながら、託したい人と話してみるのも良いかもしれません"
                    {...getOverrideProps(
                      overrides,
                      "\u5B9D\u7269\u30EA\u30B9\u30C8\u3092\u6574\u7406\u3057\u306A\u304C\u3089\u3001\u8A17\u3057\u305F\u3044\u4EBA\u3068\u8A71\u3057\u3066\u307F\u308B\u306E\u3082\u826F\u3044\u304B\u3082\u3057\u308C\u307E\u305B\u3093"
                    )}
                  ></Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </View>
      <View
        position="absolute"
        top="20.18%"
        bottom="77.76%"
        left="4.27%"
        right="4.27%"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "CardHint")}
      >
        <Flex
          gap="10px"
          position="absolute"
          top="0%"
          bottom="0%"
          left="0%"
          right="0%"
          direction="row"
          width="343px"
          height="271.99px"
          alignItems="flex-start"
          borderRadius="3px"
          padding="0px 20px 20px 0px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "BackImageHint")}
        >
          <Image
            width="70px"
            height="70px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "hint_cardIC")}
          ></Image>
        </Flex>
        <Flex
          gap="25px"
          position="absolute"
          top="0px"
          left="calc(50% - 171.5px - 1px)"
          direction="column"
          width="343px"
          height="272px"
          alignItems="center"
          padding="24px 20px 20px 20px"
          {...getOverrideProps(overrides, "ContentHint")}
        >
          <Flex
            gap="21px"
            direction="column"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "TextHint")}
          >
            <Flex
              gap="0"
              direction="column"
              width="285px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "TitleHint790918172")}
            >
              <Text
                fontFamily="Zen Kaku Gothic New"
                fontSize="25px"
                fontWeight="500"
                color="rgba(28,26,29,1)"
                lineHeight="33px"
                textAlign="center"
                display="flex"
                direction="column"
                justifyContent="flex-start"
                shrink="0"
                alignSelf="stretch"
                objectFit="cover"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="ヒント"
                {...getOverrideProps(overrides, "\u30D2\u30F3\u30C8")}
              ></Text>
              <Text
                fontFamily="Zen Kaku Gothic New"
                fontSize="15px"
                fontWeight="500"
                color="rgba(28,26,29,1)"
                lineHeight="30px"
                textAlign="center"
                display="flex"
                direction="column"
                justifyContent="flex-start"
                shrink="0"
                alignSelf="stretch"
                objectFit="cover"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="HINT"
                {...getOverrideProps(overrides, "HINT")}
              ></Text>
            </Flex>
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(28,26,29,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              shrink="0"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="HIRAQLのヒントコラムを読んで、気に入った記事、読み返したい記事をストックしていくためのページ。HIRAQL NOTEを書き進める時、自分の暮らしや考え方に変化があった時に、読み返すと新しい発見がありそうです。予約済みのイベントもこのページで確認できます。"
              {...getOverrideProps(
                overrides,
                "HIRAQL\u306E\u30D2\u30F3\u30C8\u30B3\u30E9\u30E0\u3092\u8AAD\u3093\u3067\u3001\u6C17\u306B\u5165\u3063\u305F\u8A18\u4E8B\u3001\u8AAD\u307F\u8FD4\u3057\u305F\u3044\u8A18\u4E8B\u3092\u30B9\u30C8\u30C3\u30AF\u3057\u3066\u3044\u304F\u305F\u3081\u306E\u30DA\u30FC\u30B8\u3002HIRAQL NOTE\u3092\u66F8\u304D\u9032\u3081\u308B\u6642\u3001\u81EA\u5206\u306E\u66AE\u3089\u3057\u3084\u8003\u3048\u65B9\u306B\u5909\u5316\u304C\u3042\u3063\u305F\u6642\u306B\u3001\u8AAD\u307F\u8FD4\u3059\u3068\u65B0\u3057\u3044\u767A\u898B\u304C\u3042\u308A\u305D\u3046\u3067\u3059\u3002\u4E88\u7D04\u6E08\u307F\u306E\u30A4\u30D9\u30F3\u30C8\u3082\u3053\u306E\u30DA\u30FC\u30B8\u3067\u78BA\u8A8D\u3067\u304D\u307E\u3059\u3002"
              )}
            ></Text>
          </Flex>
        </Flex>
      </View>
      <Image
        position="absolute"
        top="18.1%"
        bottom="79.94%"
        height="1.96%"
        left="4.27%"
        right="4.27%"
        width="91.47%"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "HistorySample")}
      ></Image>
      <Image
        position="absolute"
        top="22.37%"
        bottom="75.68%"
        height="1.96%"
        left="4.27%"
        right="4.27%"
        width="91.47%"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "HintSample")}
      ></Image>
      <Flex
        gap="16px"
        position="absolute"
        top="24.44%"
        bottom="73.29%"
        left="4.27%"
        right="4.27%"
        direction="column"
        width="343px"
        height="298px"
        alignItems="center"
        borderRadius="3px"
        padding="32px 0px 22px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "CardCheck")}
      >
        <Flex
          gap="12px"
          direction="column"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "CheckHeader")}
        >
          <Flex
            gap="10px"
            direction="row"
            width="fit-content"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Icon790918180")}
          >
            <Image
              width="50px"
              height="50px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Check")}
            ></Image>
          </Flex>
          <Flex
            gap="8px"
            direction="column"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "CheckTitle")}
          >
            <Text
              fontFamily="Zen Kaku Gothic New"
              fontSize="17px"
              fontWeight="500"
              color="rgba(28,26,29,1)"
              lineHeight="31px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="これだけやっておけば安心"
              {...getOverrideProps(
                overrides,
                "\u3053\u308C\u3060\u3051\u3084\u3063\u3066\u304A\u3051\u3070\u5B89\u5FC3"
              )}
            ></Text>
            <Text
              fontFamily="Zen Kaku Gothic New"
              fontSize="30px"
              fontWeight="500"
              color="rgba(28,26,29,1)"
              lineHeight="33px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="CHECK!"
              {...getOverrideProps(overrides, "CHECK!")}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="10px"
          direction="row"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 20px 0px 20px"
          {...getOverrideProps(overrides, "CheckText")}
        >
          <Text
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="400"
            color="rgba(28,26,29,1)"
            lineHeight="24px"
            textAlign="left"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            width="303px"
            grow="1"
            basis="302.99993896484375px"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="「まずはこの項目を記入しておけば、”もしもの時”に安心！助かる！」という項目を厳選しました。何から始めようか...と途方に暮れている方は、まずはここから始めてみてください。"
            {...getOverrideProps(
              overrides,
              "\u300C\u307E\u305A\u306F\u3053\u306E\u9805\u76EE\u3092\u8A18\u5165\u3057\u3066\u304A\u3051\u3070\u3001\u201D\u3082\u3057\u3082\u306E\u6642\u201D\u306B\u5B89\u5FC3\uFF01\u52A9\u304B\u308B\uFF01\u300D\u3068\u3044\u3046\u9805\u76EE\u3092\u53B3\u9078\u3057\u307E\u3057\u305F\u3002\u4F55\u304B\u3089\u59CB\u3081\u3088\u3046\u304B...\u3068\u9014\u65B9\u306B\u66AE\u308C\u3066\u3044\u308B\u65B9\u306F\u3001\u307E\u305A\u306F\u3053\u3053\u304B\u3089\u59CB\u3081\u3066\u307F\u3066\u304F\u3060\u3055\u3044\u3002790918186"
            )}
          ></Text>
        </Flex>
      </Flex>
      <Flex
        gap="10px"
        position="absolute"
        top="31.54%"
        bottom="68.2%"
        left="4.27%"
        right="17.87%"
        direction="row"
        width="292px"
        height="34px"
        alignItems="flex-start"
        padding="8px 8px 8px 8px"
        {...getOverrideProps(overrides, "Link_AChrome")}
      >
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="700"
          color="rgba(104,134,73,1)"
          lineHeight="18px"
          textAlign="center"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          letterSpacing="0.41px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="・Android端末で、Chromeをご利用の方"
          {...getOverrideProps(
            overrides,
            "\u30FBAndroid\u7AEF\u672B\u3067\u3001Chrome\u3092\u3054\u5229\u7528\u306E\u65B9"
          )}
        ></Text>
      </Flex>
      <Flex
        gap="10px"
        position="absolute"
        top="32.06%"
        bottom="67.69%"
        left="4.27%"
        right="30.4%"
        direction="row"
        width="245px"
        height="34px"
        alignItems="flex-start"
        padding="8px 8px 8px 8px"
        {...getOverrideProps(overrides, "Link_ISafari")}
      >
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="700"
          color="rgba(104,134,73,1)"
          lineHeight="18px"
          textAlign="center"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          letterSpacing="0.41px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="・iOS端末で、Safariをご利用の方"
          {...getOverrideProps(
            overrides,
            "\u30FBiOS\u7AEF\u672B\u3067\u3001Safari\u3092\u3054\u5229\u7528\u306E\u65B9"
          )}
        ></Text>
      </Flex>
      <Flex
        gap="10px"
        position="absolute"
        top="31.8%"
        bottom="67.94%"
        left="4.27%"
        right="26.67%"
        direction="row"
        width="259px"
        height="34px"
        alignItems="flex-start"
        padding="8px 8px 8px 8px"
        {...getOverrideProps(overrides, "Link_IChrome")}
      >
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="700"
          color="rgba(104,134,73,1)"
          lineHeight="18px"
          textAlign="center"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          letterSpacing="0.41px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="・iOS端末で、Chromeをご利用の方"
          {...getOverrideProps(
            overrides,
            "\u30FBiOS\u7AEF\u672B\u3067\u3001Chrome\u3092\u3054\u5229\u7528\u306E\u65B9"
          )}
        ></Text>
      </Flex>
      <Flex
        gap="0"
        position="absolute"
        top="32.82%"
        bottom="58.75%"
        left="4.27%"
        right="4.27%"
        direction="column"
        width="343px"
        height="1109.31px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "AChromeBody")}
      >
        <Flex
          gap="0"
          direction="column"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "AChromeBox1")}
        >
          <Flex
            gap="10px"
            direction="row"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="16px 0px 16px 0px"
            {...getOverrideProps(overrides, "PC_Description_AChrome1")}
          >
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(28,26,29,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="343px"
              grow="1"
              basis="342.99993896484375px"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="1.ブラウザの右上のメニューをタップ"
              {...getOverrideProps(
                overrides,
                "1.\u30D6\u30E9\u30A6\u30B6\u306E\u53F3\u4E0A\u306E\u30E1\u30CB\u30E5\u30FC\u3092\u30BF\u30C3\u30D7"
              )}
            ></Text>
          </Flex>
          <Image
            width="228px"
            height="214.97px"
            shrink="0"
            position="relative"
            border="1px SOLID rgba(232,232,232,1)"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "AChrome1")}
          ></Image>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "AChromeBox2")}
        >
          <Flex
            gap="10px"
            direction="row"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="16px 0px 16px 0px"
            {...getOverrideProps(overrides, "PC_Description_AChrome2")}
          >
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(28,26,29,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="343px"
              grow="1"
              basis="342.99993896484375px"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="2.「ホーム画面に追加」をタップ"
              {...getOverrideProps(
                overrides,
                "2.\u300C\u30DB\u30FC\u30E0\u753B\u9762\u306B\u8FFD\u52A0\u300D\u3092\u30BF\u30C3\u30D7"
              )}
            ></Text>
          </Flex>
          <Image
            width="228px"
            height="359.62px"
            shrink="0"
            position="relative"
            border="1px SOLID rgba(232,232,232,1)"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "AChrome2")}
          ></Image>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "AChromeBox3")}
        >
          <Flex
            gap="10px"
            direction="row"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="16px 0px 16px 0px"
            {...getOverrideProps(overrides, "PC_Description_AChrome3")}
          >
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(28,26,29,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="343px"
              grow="1"
              basis="342.99993896484375px"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="3.「追加」をタップ"
              {...getOverrideProps(
                overrides,
                "3.\u300C\u8FFD\u52A0\u300D\u3092\u30BF\u30C3\u30D7"
              )}
            ></Text>
          </Flex>
          <Image
            width="228px"
            height="126.38px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "AChrome3")}
          ></Image>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "AChromeBox4")}
        >
          <Flex
            gap="10px"
            direction="row"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="16px 0px 16px 0px"
            {...getOverrideProps(overrides, "PC_Description_AChrome4")}
          >
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(28,26,29,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="228px"
              height="24px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="4.「自動的に追加」をタップ"
              {...getOverrideProps(
                overrides,
                "4.\u300C\u81EA\u52D5\u7684\u306B\u8FFD\u52A0\u300D\u3092\u30BF\u30C3\u30D7"
              )}
            ></Text>
          </Flex>
          <Image
            width="228px"
            height="183.95px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "AChrome4")}
          ></Image>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        position="absolute"
        top="42.12%"
        bottom="14.81%"
        left="4.27%"
        right="4.27%"
        direction="column"
        width="343px"
        height="5667.51px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "IChromeBody")}
      >
        <Flex
          gap="0"
          direction="column"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "IChromeBox1")}
        >
          <Flex
            gap="10px"
            direction="row"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="16px 0px 16px 0px"
            {...getOverrideProps(overrides, "PC_Description_IChrome1")}
          >
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(28,26,29,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="343px"
              grow="1"
              basis="342.99993896484375px"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="1.HIRAQL NOTEのURLをコピーしておきましょう"
              {...getOverrideProps(
                overrides,
                "1.HIRAQL NOTE\u306EURL\u3092\u30B3\u30D4\u30FC\u3057\u3066\u304A\u304D\u307E\u3057\u3087\u3046"
              )}
            ></Text>
          </Flex>
          <Image
            width="228px"
            height="407.67px"
            shrink="0"
            position="relative"
            border="1px SOLID rgba(232,232,232,1)"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "IChrome1")}
          ></Image>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "IChromeBox2")}
        >
          <Flex
            gap="10px"
            direction="row"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="16px 0px 16px 0px"
            {...getOverrideProps(overrides, "PC_Description_IChrome2")}
          >
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(28,26,29,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="343px"
              grow="1"
              basis="342.99993896484375px"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="2.ホーム画面に戻り、ショートカットアプリを起動"
              {...getOverrideProps(
                overrides,
                "2.\u30DB\u30FC\u30E0\u753B\u9762\u306B\u623B\u308A\u3001\u30B7\u30E7\u30FC\u30C8\u30AB\u30C3\u30C8\u30A2\u30D7\u30EA\u3092\u8D77\u52D5"
              )}
            ></Text>
          </Flex>
          <Image
            width="228px"
            height="406.86px"
            shrink="0"
            position="relative"
            border="1px SOLID rgba(232,232,232,1)"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "IChrome2")}
          ></Image>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "IChromeBox3")}
        >
          <Flex
            gap="10px"
            direction="row"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="16px 0px 16px 0px"
            {...getOverrideProps(overrides, "PC_Description_IChrome3")}
          >
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(28,26,29,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="343px"
              grow="1"
              basis="342.99993896484375px"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="3.「＋」をタップ"
              {...getOverrideProps(
                overrides,
                "3.\u300C\uFF0B\u300D\u3092\u30BF\u30C3\u30D7"
              )}
            ></Text>
          </Flex>
          <Image
            width="228px"
            height="404.69px"
            shrink="0"
            position="relative"
            border="1px SOLID rgba(232,232,232,1)"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "IChrome3")}
          ></Image>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "IChromeBox4")}
        >
          <Flex
            gap="10px"
            direction="row"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="16px 0px 16px 0px"
            {...getOverrideProps(overrides, "PC_Description_IChrome4")}
          >
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(28,26,29,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="343px"
              grow="1"
              basis="342.99993896484375px"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="4.「アクションを追加」をタップ"
              {...getOverrideProps(
                overrides,
                "4.\u300C\u30A2\u30AF\u30B7\u30E7\u30F3\u3092\u8FFD\u52A0\u300D\u3092\u30BF\u30C3\u30D7"
              )}
            ></Text>
          </Flex>
          <Image
            width="228px"
            height="404.69px"
            shrink="0"
            position="relative"
            border="1px SOLID rgba(232,232,232,1)"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "IChrome4")}
          ></Image>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "IChromeBox5")}
        >
          <Flex
            gap="10px"
            direction="row"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="16px 0px 16px 0px"
            {...getOverrideProps(overrides, "PC_Description_IChrome")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(28,26,29,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.01px"
              width="343px"
              grow="1"
              basis="342.99993896484375px"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="5.検索窓に「chrome」と入力し、「ChromeでURLを開く」をタップ"
              {...getOverrideProps(
                overrides,
                "5.\u691C\u7D22\u7A93\u306B\u300Cchrome\u300D\u3068\u5165\u529B\u3057\u3001\u300CChrome\u3067URL\u3092\u958B\u304F\u300D\u3092\u30BF\u30C3\u30D7"
              )}
            ></Text>
          </Flex>
          <Image
            width="228px"
            height="402.53px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "IChrome5")}
          ></Image>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "IChromeBox6")}
        >
          <Flex
            gap="10px"
            direction="row"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="16px 0px 16px 0px"
            {...getOverrideProps(overrides, "PC_Description_IChrome6")}
          >
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(28,26,29,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="343px"
              grow="1"
              basis="342.99993896484375px"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="6.「URL」をタップ"
              {...getOverrideProps(
                overrides,
                "6.\u300CURL\u300D\u3092\u30BF\u30C3\u30D7"
              )}
            ></Text>
          </Flex>
          <Image
            width="228px"
            height="402.53px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "IChrome6")}
          ></Image>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "IChromeBox7")}
        >
          <Flex
            gap="10px"
            direction="row"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="16px 0px 16px 0px"
            {...getOverrideProps(overrides, "PC_Description_IChrome7")}
          >
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(28,26,29,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="343px"
              grow="1"
              basis="342.99993896484375px"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="7.コピーしておいたURLをペーストし、「完了」をタップ"
              {...getOverrideProps(
                overrides,
                "7.\u30B3\u30D4\u30FC\u3057\u3066\u304A\u3044\u305FURL\u3092\u30DA\u30FC\u30B9\u30C8\u3057\u3001\u300C\u5B8C\u4E86\u300D\u3092\u30BF\u30C3\u30D7"
              )}
            ></Text>
          </Flex>
          <Image
            width="228px"
            height="399.84px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "IChrome7")}
          ></Image>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "IChromeBox8")}
        >
          <Flex
            gap="10px"
            direction="row"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="16px 0px 16px 0px"
            {...getOverrideProps(overrides, "PC_Description_IChrome8")}
          >
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(28,26,29,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="343px"
              grow="1"
              basis="342.99993896484375px"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="8.ショートカットに任意の名前をつけ、「完了」をタップ"
              {...getOverrideProps(
                overrides,
                "8.\u30B7\u30E7\u30FC\u30C8\u30AB\u30C3\u30C8\u306B\u4EFB\u610F\u306E\u540D\u524D\u3092\u3064\u3051\u3001\u300C\u5B8C\u4E86\u300D\u3092\u30BF\u30C3\u30D7"
              )}
            ></Text>
          </Flex>
          <Image
            width="228px"
            height="402.53px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "IChrome8")}
          ></Image>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "IChromeBox9")}
        >
          <Flex
            gap="10px"
            direction="row"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="16px 0px 16px 0px"
            {...getOverrideProps(overrides, "PC_Description_IChrome9")}
          >
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(28,26,29,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="228px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="9.画面右上をタップ"
              {...getOverrideProps(
                overrides,
                "9.\u753B\u9762\u53F3\u4E0A\u3092\u30BF\u30C3\u30D7"
              )}
            ></Text>
          </Flex>
          <Image
            width="228px"
            height="406.23px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "IChrome9")}
          ></Image>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "IChromeBox10")}
        >
          <Flex
            gap="10px"
            direction="row"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="16px 0px 16px 0px"
            {...getOverrideProps(overrides, "PC_Description_IChrome10")}
          >
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(28,26,29,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="343px"
              grow="1"
              basis="342.99993896484375px"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="10.「ホーム画面に追加」をタップ"
              {...getOverrideProps(
                overrides,
                "10.\u300C\u30DB\u30FC\u30E0\u753B\u9762\u306B\u8FFD\u52A0\u300D\u3092\u30BF\u30C3\u30D7"
              )}
            ></Text>
          </Flex>
          <Image
            width="228px"
            height="404.03px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "IChrome10")}
          ></Image>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "IChromeBox11")}
        >
          <Flex
            gap="10px"
            direction="row"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="16px 0px 16px 0px"
            {...getOverrideProps(overrides, "PC_Description")}
          >
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(28,26,29,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="343px"
              grow="1"
              basis="342.99993896484375px"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="11.「追加」をタップ&#xA;※適宜任意のアイコンを設定"
              {...getOverrideProps(
                overrides,
                "11.\u300C\u8FFD\u52A0\u300D\u3092\u30BF\u30C3\u30D7 \u203B\u9069\u5B9C\u4EFB\u610F\u306E\u30A2\u30A4\u30B3\u30F3\u3092\u8A2D\u5B9A"
              )}
            ></Text>
          </Flex>
          <Image
            width="228px"
            height="404.03px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "IChrome11")}
          ></Image>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "IChromeBox12")}
        >
          <Flex
            gap="10px"
            direction="row"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="16px 0px 16px 0px"
            {...getOverrideProps(overrides, "PC_Description_IChrome12")}
          >
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(28,26,29,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="343px"
              grow="1"
              basis="342.99993896484375px"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="12.HIRAQL NOTEへのショートカットがホーム画面に追加されます"
              {...getOverrideProps(
                overrides,
                "12.HIRAQL NOTE\u3078\u306E\u30B7\u30E7\u30FC\u30C8\u30AB\u30C3\u30C8\u304C\u30DB\u30FC\u30E0\u753B\u9762\u306B\u8FFD\u52A0\u3055\u308C\u307E\u3059"
              )}
            ></Text>
          </Flex>
          <Image
            width="228px"
            height="404.03px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "IChrome12")}
          ></Image>
        </Flex>
      </Flex>
      <Flex
        gap="2px"
        position="absolute"
        top="41.62%"
        bottom="57.88%"
        left="0%"
        right="0%"
        direction="column"
        width="375px"
        height="66px"
        padding="16px 16px 16px 16px"
        backgroundColor="rgba(251,249,246,1)"
        {...getOverrideProps(overrides, "IChromeTitle")}
      >
        <Text
          fontFamily="Inter"
          fontSize="12px"
          fontWeight="500"
          color="rgba(112,112,112,1)"
          lineHeight="14px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          letterSpacing="0.33px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="iOS端末"
          {...getOverrideProps(overrides, "iOS\u7AEF\u672B790918260")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="700"
          color="rgba(28,26,29,1)"
          lineHeight="18px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          letterSpacing="0.41px"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="ChromeでHIRAQL NOTEをホーム画面に追加する"
          {...getOverrideProps(
            overrides,
            "Chrome\u3067HIRAQL NOTE\u3092\u30DB\u30FC\u30E0\u753B\u9762\u306B\u8FFD\u52A0\u3059\u308B790918261"
          )}
        ></Text>
      </Flex>
      <Flex
        gap="2px"
        position="absolute"
        top="32.31%"
        bottom="67.18%"
        left="0%"
        right="0%"
        direction="column"
        width="375px"
        height="66px"
        padding="16px 16px 16px 16px"
        backgroundColor="rgba(251,249,246,1)"
        {...getOverrideProps(overrides, "AChromeTitle")}
      >
        <Text
          fontFamily="Inter"
          fontSize="12px"
          fontWeight="500"
          color="rgba(112,112,112,1)"
          lineHeight="14px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          letterSpacing="0.33px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Android端末"
          {...getOverrideProps(overrides, "Android\u7AEF\u672B")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="700"
          color="rgba(28,26,29,1)"
          lineHeight="18px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          letterSpacing="0.41px"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="ChromeでHIRAQL NOTEをホーム画面に追加する"
          {...getOverrideProps(
            overrides,
            "Chrome\u3067HIRAQL NOTE\u3092\u30DB\u30FC\u30E0\u753B\u9762\u306B\u8FFD\u52A0\u3059\u308B790918264"
          )}
        ></Text>
      </Flex>
      <Flex
        gap="2px"
        position="absolute"
        top="85.56%"
        bottom="13.94%"
        left="0%"
        right="0%"
        direction="column"
        width="375px"
        height="66px"
        padding="16px 16px 16px 16px"
        backgroundColor="rgba(251,249,246,1)"
        {...getOverrideProps(overrides, "ISafariTitle")}
      >
        <Text
          fontFamily="Inter"
          fontSize="12px"
          fontWeight="500"
          color="rgba(112,112,112,1)"
          lineHeight="14px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          letterSpacing="0.33px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="iOS端末"
          {...getOverrideProps(overrides, "iOS\u7AEF\u672B790918266")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="700"
          color="rgba(28,26,29,1)"
          lineHeight="18px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          letterSpacing="0.41px"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="SafariでHIRAQL NOTEをホーム画面に追加する"
          {...getOverrideProps(
            overrides,
            "Safari\u3067HIRAQL NOTE\u3092\u30DB\u30FC\u30E0\u753B\u9762\u306B\u8FFD\u52A0\u3059\u308B"
          )}
        ></Text>
      </Flex>
      <Flex
        gap="8px"
        position="absolute"
        top="30.57%"
        bottom="69.07%"
        left="0%"
        right="0%"
        direction="row"
        width="375px"
        height="48px"
        alignItems="center"
        padding="8px 0px 8px 16px"
        backgroundColor="rgba(251,249,246,1)"
        {...getOverrideProps(overrides, "AddHomeText")}
      >
        <Icon
          width="32px"
          height="32px"
          shrink="0"
          overflow="hidden"
          position="relative"
          padding="0px 0px 0px 0px"
          type="add_to_home_screen"
          fontSize="32px"
          {...getOverrideProps(overrides, "Icon790918269")}
        ></Icon>
        <Text
          fontFamily="Inter"
          fontSize="20px"
          fontWeight="700"
          color="rgba(28,26,29,1)"
          lineHeight="24px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          letterSpacing="0.65px"
          width="278px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="ホーム画面に追加"
          {...getOverrideProps(
            overrides,
            "\u30DB\u30FC\u30E0\u753B\u9762\u306B\u8FFD\u52A0"
          )}
        ></Text>
      </Flex>
      <Flex
        gap="10px"
        position="absolute"
        top="30.93%"
        bottom="68.46%"
        left="0%"
        right="0%"
        direction="row"
        width="375px"
        height="80px"
        alignItems="flex-start"
        padding="16px 16px 16px 16px"
        {...getOverrideProps(overrides, "AddHomeDescription")}
      >
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="400"
          color="rgba(0,0,0,1)"
          lineHeight="24px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          width="343px"
          grow="1"
          basis="343px"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="HIRAQL NOTEをいつでも開けるように、スマートフォンのホーム画面にショートカットを追加しましょう。"
          {...getOverrideProps(
            overrides,
            "\u81EA\u5206\u306E\u30C7\u30FC\u30BF\u3084\u610F\u601D\u3092\u6574\u7406\u3057\u3001\u8A18\u3057\u3066\u3044\u304F\u305F\u3081\u306E\u30AA\u30F3\u30E9\u30A4\u30F3\u30CE\u30FC\u30C8\u3067\u3059\u3002\u4EBA\u751F\u3067\u3084\u308A\u305F\u3044\u3053\u3068\u306E\u5177\u4F53\u7684\u306A\u30D7\u30E9\u30F3\u3092\u7ACB\u3066\u305F\u308A\u3001\u30D2\u30F3\u30C8\u30B3\u30E9\u30E0\u3084\u30A4\u30D9\u30F3\u30C8\u3092\u901A\u3058\u3066\u611F\u3058\u305F\u3053\u3068\u3092\u751F\u6D3B\u306B\u6301\u3061\u5E30\u308A\u3001\u8A18\u9332\u3057\u3066\u3044\u304F\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002 \u66F8\u3044\u3066\u307F\u3066\u767A\u898B\u3059\u308B\u3053\u3068\u3082\u305F\u304F\u3055\u3093\u3042\u308B\u306F\u305A\u3002\u4F55\u5EA6\u3067\u3082\u66F8\u304D\u76F4\u3057\u3001\u66F8\u304D\u8DB3\u3057\u3066\u3044\u304D\u307E\u3057\u3087\u3046\u3002\u6642\u9593\u304C\u7D4C\u3064\u3068\u610F\u5916\u306B\u6C17\u6301\u3061\u304C\u5909\u308F\u308A\u3001\u66F8\u304D\u76F4\u3057\u3066\u307F\u305F\u304F\u306A\u308B\u3082\u306E\u3067\u3059\u3002\u307E\u305A\u306F\u304A\u597D\u304D\u306A\u9805\u76EE\u304B\u3089\u3001\u6C17\u8EFD\u306B\u66F8\u304D\u59CB\u3081\u3066\u307F\u3066\u304F\u3060\u3055\u3044\u3002790918272"
          )}
        ></Text>
      </Flex>
      <Flex
        gap="0"
        position="absolute"
        top="86.06%"
        bottom="0%"
        left="4.27%"
        right="4.27%"
        direction="column"
        width="343px"
        height="1834.24px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "ISafari")}
      >
        <Flex
          gap="0"
          direction="column"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ISafariBox1")}
        >
          <Flex
            gap="10px"
            direction="row"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="16px 0px 16px 0px"
            {...getOverrideProps(overrides, "PC_Description_ISafari1")}
          >
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(28,26,29,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="343px"
              grow="1"
              basis="342.99993896484375px"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="1.ブラウザ下部の共有ボタンをタップ"
              {...getOverrideProps(
                overrides,
                "1.\u30D6\u30E9\u30A6\u30B6\u4E0B\u90E8\u306E\u5171\u6709\u30DC\u30BF\u30F3\u3092\u30BF\u30C3\u30D7"
              )}
            ></Text>
          </Flex>
          <Image
            width="228px"
            height="404.14px"
            shrink="0"
            position="relative"
            border="1px SOLID rgba(232,232,232,1)"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "ISafari1")}
          ></Image>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ISafariBox2")}
        >
          <Flex
            gap="10px"
            direction="row"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="16px 0px 16px 0px"
            {...getOverrideProps(overrides, "PC_Description_ISafari2")}
          >
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(28,26,29,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="343px"
              grow="1"
              basis="342.99993896484375px"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="2.下にスクロール"
              {...getOverrideProps(
                overrides,
                "2.\u4E0B\u306B\u30B9\u30AF\u30ED\u30FC\u30EB"
              )}
            ></Text>
          </Flex>
          <Image
            width="228px"
            height="402.95px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "ISafari2")}
          ></Image>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ISafariBox3")}
        >
          <Flex
            gap="10px"
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="16px 0px 16px 0px"
            {...getOverrideProps(overrides, "PC_Description_ISafari3")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(28,26,29,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.01px"
              width="343px"
              grow="1"
              basis="342.99993896484375px"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="3.「ホーム画面に追加」をタップ"
              {...getOverrideProps(
                overrides,
                "3.\u300C\u30DB\u30FC\u30E0\u753B\u9762\u306B\u8FFD\u52A0\u300D\u3092\u30BF\u30C3\u30D7"
              )}
            ></Text>
          </Flex>
          <Image
            width="228px"
            height="402.95px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "ISafari3")}
          ></Image>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ISafariBox4")}
        >
          <Flex
            gap="10px"
            direction="row"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="16px 0px 16px 0px"
            {...getOverrideProps(overrides, "PC_Description_ISafari4")}
          >
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(28,26,29,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="343px"
              grow="1"
              basis="342.99993896484375px"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="4.「追加」をタップ"
              {...getOverrideProps(
                overrides,
                "4.\u300C\u8FFD\u52A0\u300D\u3092\u30BF\u30C3\u30D7"
              )}
            ></Text>
          </Flex>
          <Image
            width="228px"
            height="400.33px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "ISafari4")}
          ></Image>
        </Flex>
      </Flex>
      <Flex
        gap="10px"
        position="absolute"
        top="0.36%"
        bottom="97.57%"
        left="0%"
        right="0%"
        direction="row"
        width="375px"
        height="271.99px"
        alignItems="flex-start"
        padding="16px 16px 16px 16px"
        {...getOverrideProps(overrides, "DescriptionAbout")}
      >
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="400"
          color="rgba(0,0,0,1)"
          lineHeight="24px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          width="343px"
          grow="1"
          basis="343px"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="自分のデータや意思を整理し、記していくためのオンラインノートです。人生でやりたいことの具体的なプランを立てたり、ヒントコラムやイベントを通じて感じたことを生活に持ち帰り、記録していくことができます。&#xD;&#xA;書いてみて発見することもたくさんあるはず。何度でも書き直し、書き足していきましょう。時間が経つと意外に気持ちが変わり、書き直してみたくなるものです。まずはお好きな項目から、気軽に書き始めてみてください。"
          {...getOverrideProps(
            overrides,
            "\u81EA\u5206\u306E\u30C7\u30FC\u30BF\u3084\u610F\u601D\u3092\u6574\u7406\u3057\u3001\u8A18\u3057\u3066\u3044\u304F\u305F\u3081\u306E\u30AA\u30F3\u30E9\u30A4\u30F3\u30CE\u30FC\u30C8\u3067\u3059\u3002\u4EBA\u751F\u3067\u3084\u308A\u305F\u3044\u3053\u3068\u306E\u5177\u4F53\u7684\u306A\u30D7\u30E9\u30F3\u3092\u7ACB\u3066\u305F\u308A\u3001\u30D2\u30F3\u30C8\u30B3\u30E9\u30E0\u3084\u30A4\u30D9\u30F3\u30C8\u3092\u901A\u3058\u3066\u611F\u3058\u305F\u3053\u3068\u3092\u751F\u6D3B\u306B\u6301\u3061\u5E30\u308A\u3001\u8A18\u9332\u3057\u3066\u3044\u304F\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002 \u66F8\u3044\u3066\u307F\u3066\u767A\u898B\u3059\u308B\u3053\u3068\u3082\u305F\u304F\u3055\u3093\u3042\u308B\u306F\u305A\u3002\u4F55\u5EA6\u3067\u3082\u66F8\u304D\u76F4\u3057\u3001\u66F8\u304D\u8DB3\u3057\u3066\u3044\u304D\u307E\u3057\u3087\u3046\u3002\u6642\u9593\u304C\u7D4C\u3064\u3068\u610F\u5916\u306B\u6C17\u6301\u3061\u304C\u5909\u308F\u308A\u3001\u66F8\u304D\u76F4\u3057\u3066\u307F\u305F\u304F\u306A\u308B\u3082\u306E\u3067\u3059\u3002\u307E\u305A\u306F\u304A\u597D\u304D\u306A\u9805\u76EE\u304B\u3089\u3001\u6C17\u8EFD\u306B\u66F8\u304D\u59CB\u3081\u3066\u307F\u3066\u304F\u3060\u3055\u3044\u3002790918291"
          )}
        ></Text>
      </Flex>
      <View
        padding="0px 0px 0px 0px"
        position="absolute"
        top="2.43%"
        bottom="96.96%"
        left="4.27%"
        right="4.27%"
        {...getOverrideProps(overrides, "AddHomeGuide")}
      >
        <Flex
          gap="10px"
          position="absolute"
          top="0%"
          bottom="0%"
          left="0%"
          right="0%"
          direction="row"
          width="343px"
          height="80px"
          alignItems="flex-start"
          border="1px SOLID rgba(232,232,232,1)"
          borderRadius="4px"
          padding="15px 15px 15px 15px"
          {...getOverrideProps(overrides, "DescriptionAddHome")}
        >
          <Text
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="left"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="HIRAQL NOTEをスマートフォンでホーム画面に&#xA;追加する方法は"
            {...getOverrideProps(
              overrides,
              "HIRAQL NOTE\u3092\u30B9\u30DE\u30FC\u30C8\u30D5\u30A9\u30F3\u3067\u30DB\u30FC\u30E0\u753B\u9762\u306B\u8FFD\u52A0\u3059\u308B\u65B9\u6CD5\u306F"
            )}
          ></Text>
        </Flex>
        <Flex
          gap="0"
          position="absolute"
          top="43.76%"
          bottom="13.74%"
          left="32.36%"
          right="50.15%"
          direction="row"
          width="60px"
          height="34px"
          justifyContent="center"
          alignItems="center"
          borderRadius="4px"
          padding="8px 8px 8px 8px"
          {...getOverrideProps(overrides, "Link_AddHome")}
        >
          <Text
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="700"
            color="rgba(104,134,73,1)"
            lineHeight="18px"
            textAlign="center"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            letterSpacing="0.41px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="こちら"
            {...getOverrideProps(overrides, "\u3053\u3061\u3089")}
          ></Text>
        </Flex>
      </View>
      <Flex
        gap="16px"
        position="absolute"
        top="26.83%"
        bottom="69.8%"
        left="4.27%"
        right="4.27%"
        direction="column"
        width="343px"
        height="444px"
        alignItems="center"
        borderRadius="3px"
        padding="32px 0px 22px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "CardShare")}
      >
        <Flex
          gap="12px"
          direction="column"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ShareHeader")}
        >
          <Image
            width="50px"
            height="51.64px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Share")}
          ></Image>
          <Flex
            gap="8px"
            direction="column"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "ShareTitle")}
          >
            <Text
              fontFamily="Zen Kaku Gothic New"
              fontSize="17px"
              fontWeight="500"
              color="rgba(28,26,29,1)"
              lineHeight="31px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="共有しておくと、もしもの時も安心"
              {...getOverrideProps(
                overrides,
                "\u5171\u6709\u3057\u3066\u304A\u304F\u3068\u3001\u3082\u3057\u3082\u306E\u6642\u3082\u5B89\u5FC3"
              )}
            ></Text>
            <Text
              fontFamily="Zen Kaku Gothic New"
              fontSize="30px"
              fontWeight="500"
              color="rgba(28,26,29,1)"
              lineHeight="33px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="ノートを共有"
              {...getOverrideProps(
                overrides,
                "\u30CE\u30FC\u30C8\u3092\u5171\u6709"
              )}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="10px"
          direction="row"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 20px 0px 20px"
          {...getOverrideProps(overrides, "ShareText")}
        >
          <Text
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="400"
            color="rgba(28,26,29,1)"
            lineHeight="24px"
            textAlign="left"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            width="303px"
            grow="1"
            basis="302.99993896484375px"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="HIRAQL NOTEに記入したデータや意思を家族など大切な人にシェアできます。誰に、どの情報を、いつ共有するのか設定をしましょう。設定をしておくと”もしものとき”に確実にご自身の意思が伝わり、大切な人を助けることができます。&#xA;&#xA;記入ができた項目から気軽に設定をはじめてみましょう。公開日は未来日付に設定できます。また、設定後も編集は可能です。"
            {...getOverrideProps(
              overrides,
              "\u300C\u307E\u305A\u306F\u3053\u306E\u9805\u76EE\u3092\u8A18\u5165\u3057\u3066\u304A\u3051\u3070\u3001\u201D\u3082\u3057\u3082\u306E\u6642\u201D\u306B\u5B89\u5FC3\uFF01\u52A9\u304B\u308B\uFF01\u300D\u3068\u3044\u3046\u9805\u76EE\u3092\u53B3\u9078\u3057\u307E\u3057\u305F\u3002\u4F55\u304B\u3089\u59CB\u3081\u3088\u3046\u304B...\u3068\u9014\u65B9\u306B\u66AE\u308C\u3066\u3044\u308B\u65B9\u306F\u3001\u307E\u305A\u306F\u3053\u3053\u304B\u3089\u59CB\u3081\u3066\u307F\u3066\u304F\u3060\u3055\u3044\u3002790918304"
            )}
          ></Text>
        </Flex>
      </Flex>
    </View>
  );
}
