import { PCListSingleLineA, ListSingleLineA } from "../ui-components";
import { isMobile } from "react-device-detect";
export default function (props) {
  if (isMobile) {
    return (
      <ListSingleLineA
        width="auto"
        margin="0 16px 0 16px"
        overrides={{
          View: {
            margin: "16px",
            className: "pointer",
            onClick: props.onClick,
          },
          "View.View[0].Text[0]": {
            className: "text-overflow-ellipsis",
            maxWidth: "calc(100vw - 100px)",
            whiteSpace: "pre",
            overflow: "hidden",
            display: "block",
            children: props.label,
          },
        }}
      />
    );
  } else {
    return (
      <PCListSingleLineA
        overrides={{
          View: {
            className: "pccomponent pointer",
            margin: "0 auto 16px auto",
            onClick: props.onClick,
          },
          "View.Text[0]": {
            className: "text-overflow-ellipsis",
            maxWidth: "calc(100vw - 100px)",
            whiteSpace: "pre",
            overflow: "hidden",
            display: "block",
            children: props.label,
          },
        }}
      />
    );
  }
}
