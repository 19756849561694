import { DataStore } from "aws-amplify";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MODEL_NAME } from "../constants";
import { LeavesShareInfo } from "../models";
import { ListField } from "../page-components";
import {
  getLabel,
  isEmptyDate,
  isInvalidDateFormat,
  isNotEmpty,
  isNotEmptyDate,
} from "../utils/CommonUtils";
import { usePrompt } from "../utils/Prompt";
import { queryData } from "../utils/ShareUtils";

export default function SettingShareEditPage(props) {
  const { modelName, title } = props;
  const navigate = useNavigate();
  const params = useParams();
  const dataRef = useRef();
  const [ratio, setRatio] = useState({
    radioValue: null,
  });
  const [dataShare, setDateShare] = useState({
    name: "",
    pejikokaibi: "",
  });
  const [isUpdated, setIsUpdated] = useState(false);
  const [error, setError] = useState("");
  const [update, setUpdate] = useState(false);

  usePrompt((location, action) => {
    if (action == "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  useEffect(() => {
    const getdata = async () => {
      const data = await queryData(params, LeavesShareInfo);
      if (data.length > 0) {
        dataRef.current = data[0];
        const targetShare = data[0]?.target.find(
          (item) => item.dataName === modelName,
        );
        if (targetShare) {
          setDateShare({
            name: data[0]?.name,
            pejikokaibi: targetShare?.shareDate,
          });
          setRatio({ radioValue: "enable" });
        } else {
          setDateShare({
            name: data[0]?.name,
          });
          setRatio({ radioValue: "disable" });
        }
      }
    };
    getdata();
  }, [modelName, params]);

  const listField = [
    {
      type: "ratioGroup",
      label: "radioValue",
      subLabel: getLabel(title) + getLabel("active.sharesuffix"),
      isRequire: true,
      value: ratio?.radioValue,
      setStateRatio: setRatio,
      options: [
        {
          id: 0,
          text: getLabel("kyoyusuru"),
          value: "enable",
        },
        {
          id: 1,
          text: getLabel("kyoyushinai"),
          value: "disable",
        },
      ],
    },
  ];

  const handleUpdate = async () => {
    let valid = true;
    const dateFormatCheck = isInvalidDateFormat(dataShare?.pejikokaibi);
    const isDisableShare =
      ratio?.radioValue === "disable";
    if (dateFormatCheck && !isDisableShare) {
      setError(getLabel("error.dateformat"));
      valid = false;
    } else if (isEmptyDate(dataShare?.pejikokaibi) && !isDisableShare) {
      setError(getLabel("error.required"));
      valid = false;
    } else {
      setError(null);
    }

    if (valid) {
      setIsUpdated(true);
      let targets = JSON.parse(JSON.stringify(dataRef.current?.target));
      if (isDisableShare) {
        targets = targets.filter((ele) => ele.dataName !== modelName);
        if (modelName === MODEL_NAME.LOANKARIIREKIN) {
          targets = targets.filter(
            (ele) => ele.dataName !== MODEL_NAME.HOSYOUSAIMU,
          );
        }
        if (modelName === MODEL_NAME.LIFEFUTURE) {
          targets = targets.filter(
            (ele) => ele.dataName !== MODEL_NAME.SHUUKAN,
          );
        }
      } else {
        const currentCategory = targets.find(
          (ele) => ele.dataName === modelName,
        );
        const hosyousaimuItem = targets.find(
          (ele) => ele.dataName === MODEL_NAME.HOSYOUSAIMU,
        );
        const shuukanItem = targets.find(
          (ele) => ele.dataName === MODEL_NAME.SHUUKAN,
        );
        if (currentCategory) {
          currentCategory.shareDate = dataShare?.pejikokaibi;
          if (modelName === MODEL_NAME.LOANKARIIREKIN && hosyousaimuItem)
            hosyousaimuItem.shareDate = dataShare?.pejikokaibi;
          if (modelName === MODEL_NAME.LIFEFUTURE && shuukanItem)
            shuukanItem.shareDate = dataShare?.pejikokaibi;
        } else {
          targets.push({
            dataName: modelName,
            shareDate: dataShare?.pejikokaibi,
          });
          if (!hosyousaimuItem && modelName === MODEL_NAME.LOANKARIIREKIN) {
            targets.push({
              dataName: MODEL_NAME.HOSYOUSAIMU,
              shareDate: dataShare?.pejikokaibi,
            });
          }
          if (!shuukanItem && modelName === MODEL_NAME.LIFEFUTURE) {
            targets.push({
              dataName: MODEL_NAME.SHUUKAN,
              shareDate: dataShare?.pejikokaibi,
            });
          }
        }
      }
      await DataStore.save(
        LeavesShareInfo.copyOf(dataRef.current, (updated) => {
          updated.target = targets;
        }),
      );

      navigate(-1, { replace: true });
    }
  };

  return (
    <ListField
      label={dataShare?.name}
      list={
        ratio?.radioValue === "enable"
          ? [
              ...listField,
              {
                type: "textField",
                typeInput: "date",
                label: "pejikokaibi",
                isRequire: true,
                value: dataShare?.pejikokaibi,
                hasError: isNotEmpty(error),
                errorMessage: error,
              },
            ]
          : listField
      }
      setUpdate={setUpdate}
      setState={setDateShare}
      onCancel={() => navigate(-1, { replace: true })}
      onSave={handleUpdate}
      isDisabled={isUpdated}
    />
  );
}
