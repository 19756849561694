import React, { useEffect, useState, useRef, useCallback } from "react";
import { ButtonNavigationHover } from "../ui-components";
import { useNavigate, useLocation } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";
import data from "../assets/image/Data.png";
import will from "../assets/image/Will.png";
import heart from "../assets/image/Heart.png";
import hint from "../assets/image/Hint.png";
import howto from "../assets/image/Howto.png";
import data_active from "../assets/image/Data_active.png";
import will_active from "../assets/image/Will_active.png";
import heart_active from "../assets/image/Heart_active.png";
import hint_active from "../assets/image/Hint_active.png";
import howto_active from "../assets/image/Howto_active.png";
import onebadge from "../assets/image/One_badge.png";

export default function () {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState({});
  const dataRef = useRef();
  const willRef = useRef();
  const historyRef = useRef();
  const hintRef = useRef();
  const howtoRef = useRef();

  useEffect(() => {
    if (location.pathname.startsWith("/data")) {
      setActiveTab("data");
    } else if (location.pathname.startsWith("/will")) {
      setActiveTab("will");
    } else if (location.pathname.startsWith("/history")) {
      setActiveTab("heart");
    } else if (location.pathname.startsWith("/hint")) {
      setActiveTab("hint");
    } else if (location.pathname.startsWith("/event")) {
      setActiveTab("hint");
    } else if (location.pathname.startsWith("/howto")) {
      setActiveTab("howto");
    } else {
      setActiveTab(null);
    }
  }, [location]);

  const showCircle = useCallback((ref) => {
    ref.current.style.transitionDuration = "0s";
    ref.current.style.opacity = 1;
    setTimeout(() => {
      ref.current.style.transitionDuration = "1s";
      ref.current.style.opacity = 0;
    }, 100);
  });

  return (
    <>
      {isMobile && !isTablet ? (
        <>
          <div className="push"></div>

          <div className="NavBar">
            <ButtonNavigationHover
              width={"100vw"}
              overrides={{
                Hover: {
                  width: "100vw",
                },
                Content: {
                  width: "100vw",
                },
                Data: {
                  width: "auto",
                  src: activeTab == "data" ? data_active : data,
                },
                NvData: {
                  onClick: () => {
                    showCircle(dataRef);
                    navigate("/data");
                  },
                },
                "\u79C1\u306E\u30C7\u30FC\u30BF": {
                  color: activeTab == "data" ? "var(--my-theme-color)" : "#A0A0A0",
                },
                HoverData: {
                  ref: dataRef,
                  height: "auto",
                  width: "85px",
                  top: "-5px",
                  opacity: "0",
                },
                Will: {
                  width: "auto",
                  src: activeTab == "will" ? will_active : will,
                },
                NvWill: {
                  onClick: () => {
                    showCircle(willRef);
                    navigate("/will");
                  },
                },
                "\u79C1\u306E\u610F\u601D": {
                  color: activeTab == "will" ? "var(--my-theme-color)" : "#A0A0A0",
                },
                HoverWill: {
                  ref: willRef,
                  height: "auto",
                  width: "85px",
                  top: "-5px",
                  opacity: "0",
                },
                Heart: {
                  width: "auto",
                  src: activeTab == "heart" ? heart_active : heart,
                },
                NvHeart: {
                  onClick: () => {
                    showCircle(historyRef);

                    navigate("/history");
                  },
                },
                "\u5927\u5207\u306A\u3082\u306E": {
                  color: activeTab == "heart" ? "var(--my-theme-color)" : "#A0A0A0",
                },
                HoverHeart: {
                  ref: historyRef,
                  height: "auto",
                  width: "85px",
                  top: "-5px",
                  opacity: "0",
                },
                Hint: {
                  width: "auto",
                  src: activeTab == "hint" ? hint_active : hint,
                },
                NvHint: {
                  onClick: () => {
                    showCircle(hintRef);
                    navigate("/hint");
                  },
                },
                "\u30D2\u30F3\u30C8": {
                  color: activeTab == "hint" ? "var(--my-theme-color)" : "#A0A0A0",
                },
                One_badge: {
                  display: "none",
                  width: "auto",
                  src: onebadge,
                },
                HoverHint: {
                  ref: hintRef,
                  height: "auto",
                  width: "85px",
                  top: "-5px",
                  opacity: "0",
                },
                Howto: {
                  width: "auto",
                  src: activeTab == "howto" ? howto_active : howto,
                },
                NvHowto: {
                  onClick: () => {
                    showCircle(howtoRef);
                    navigate("/howto");
                  },
                },
                "\u4F7F\u3044\u65B9": {
                  color: activeTab == "howto" ? "var(--my-theme-color)" : "#A0A0A0",
                },
                HoverHowto: {
                  ref: howtoRef,
                  height: "auto",
                  width: "85px",
                  top: "-5px",
                  opacity: "0",
                },
              }}
            />
          </div>
        </>
      ) : null}
    </>
  );
}
