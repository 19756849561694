import { DataStore } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import { JitsubutsuShisanshurui, LeavesJitsubutsuShisan } from "../models";
import {
  ButtonCancelSave,
  SelectField,
  SwapperContent,
  TextField,
  TextFieldMultiline,
} from "../page-components";
import { getLabel, isEmpty, useInitializePage } from "../utils/CommonUtils";
import { log } from "../utils/Logger";
import { usePrompt } from "../utils/Prompt";

/*
  shisanmei: String
  shisanshurui: Shisanshurui
  shozaibasho: String
  bikou: String
  _shisanshurui: String
  leavesusersID: ID! @index(name: "byLeavesUsers")
*/
export default function () {
  const navigate = useInitializePage("jitsubutsushisan", "title.update");
  const [shisan, setShisan] = useState();

  const [shisanmei, setShisanmei] = useState("");
  const [shisanshurui, setShisanshurui] = useState("");
  const [shozaibasho, setShozaibasho] = useState("");
  const [bikou, setBikou] = useState("");

  const [shisanmeiError, setShisanmeiError] = useState(null);
  const [shisanshuruiError, setShisanshuruiError] = useState(null);
  const [shozaibashoError, setShozaibashoError] = useState(null);
  const [bikouError, setBikouError] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);

  const [update, setUpdate] = useState(false);
  usePrompt((location, action) => {
    if (action == "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  let params = useParams();

  useEffect(async () => {
    const dsShisan = await DataStore.query(LeavesJitsubutsuShisan, (c) =>
      c.id("eq", params.id),
    );
    log.debug("shisan", dsShisan);
    if (dsShisan.length > 0) {
      setShisan(dsShisan[0]);
      setShisanmei(dsShisan[0].shisanmei);
      setShisanshurui(dsShisan[0].shisanshurui);
      setShozaibasho(dsShisan[0].shozaibasho);
      setBikou(dsShisan[0].bikou);
    }
  }, []);

  const updateData = async () => {
    log.debug(shisanmei + "," + shisanshurui + "," + shozaibasho + "," + bikou);
    let valid = true;
    if (isEmpty(shisanmei)) {
      setShisanmeiError(getLabel("error.required"));
      valid = false;
    } else {
      setShisanmeiError(null);
    }
    if (isEmpty(shisanshurui)) {
      setShisanshuruiError(getLabel("error.required"));
      valid = false;
    } else {
      setShisanshuruiError(null);
    }
    if (valid) {
      setIsUpdated(true);
      DataStore.save(
        LeavesJitsubutsuShisan.copyOf(shisan, (updated) => {
          updated.shisanmei = shisanmei;
          updated.shisanshurui = shisanshurui;
          updated._shisanshurui = shisanshurui;
          updated.shozaibasho = shozaibasho;
          updated.bikou = bikou;
        }),
      );
      window.gtag("event", "ChangeComplete", {
        event_category: "Edit",
        event_label: "実物資産変更完了",
      });
      navigate(-1, { replace: true });
    }
  };

  const shisanshuruiList = [];
  Object.keys(JitsubutsuShisanshurui).forEach((type) => {
    shisanshuruiList.push(
      <option key={type} value={type}>
        {getLabel("JitsubutsuShisanshurui." + type)}
      </option>,
    );
  });

  return (
    <SwapperContent label={getLabel("button.update")}>
      <SelectField
        isRequired
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("shisanshurui")}
        value={shisanshurui}
        errorMessage={shisanshuruiError}
        children={<React.Fragment>{shisanshuruiList}</React.Fragment>}
        onChange={(event) => {
          setShisanshurui(event.target.value);
          setUpdate(true);
        }}
      />
      <TextField
        isRequired
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("shisanmei")}
        value={shisanmei}
        errorMessage={shisanmeiError}
        onChange={(event) => {
          setShisanmei(event.target.value);
          setUpdate(true);
        }}
      />
      <TextField
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("shozaibasho")}
        value={shozaibasho}
        errorMessage={shozaibashoError}
        onChange={(event) => {
          setShozaibasho(event.target.value);
          setUpdate(true);
        }}
      />
      <TextFieldMultiline
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("bikou")}
        value={bikou}
        errorMessage={bikouError}
        onChange={(event) => {
          setBikou(event.target.value);
          setUpdate(true);
        }}
      />
      <ButtonCancelSave
        cancelOnClick={() => navigate(-1, { replace: true })}
        saveOnClick={() => updateData()}
        isDisabled={isUpdated}
      />
    </SwapperContent>
  );
}
