import { Flex, IconContentCopy, IconShare } from "@aws-amplify/ui-react";
import { DataStore } from "aws-amplify";
import React, { useCallback, useEffect, useState,useMemo } from "react";
import { useParams } from "react-router-dom";
import { LeavesShareInfo } from "../models";
import { log } from "../utils/Logger";
import { getShareMessage, generateUrl } from "../utils/ShareUtils";
import { isMobile, isTablet } from "react-device-detect";

import { DefaultButton, Description, LabelM, ListSingleLineCEdit, ListTwolineA, ListTwoLineButton, PrimaryButton } from "../page-components";
import { getLabel, useInitializePage } from "../utils/CommonUtils";
import { MODEL_NAME } from "../constants";

export default function InvitePreview() {
  const navigate = useInitializePage("invite", "preview"); /**TODO: */
  let params = useParams();
  const [data, setData] = useState({
    name: "",
    target: [],
  });
  const [urlid, setUrlid] = useState({});
  const [isCopyEnable, setIsCopyEnable] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (navigator.clipboard) {
      setIsCopyEnable(true);
    }

    const getData = async () => {
      const data = await DataStore.query(LeavesShareInfo, (c) => c.id("eq", params.id));

      if (data?.length > 0) {
        const targetShare = data[0].target
          .filter((ele) => ele.dataName !== MODEL_NAME.HOSYOUSAIMU && ele.dataName !== MODEL_NAME.SHUUKAN)
          .sort(function (a, b) {
            return new Date(a?.shareDate) - new Date(b?.shareDate);
          });
        const shareDateList = new Set(targetShare.map((ele) => ele?.shareDate));
        const dateSplit = [];
        shareDateList?.forEach((date) => {
          dateSplit.push({
            label: String(date).replaceAll("-", "/") + getLabel("nikokaisurupeji"),
            value: targetShare
              .filter((ele) => ele?.shareDate === date)
              .map((t) => getLabel("Datastore." + t.dataName))
              .join("、"),
          });
        });
        const inviteData = {
          id: data[0].id,
          name: data[0].name,
          splitList: dateSplit,
        };
        setData(inviteData);

      }
    };

    getData();
  }, [params.id]);

  const generateShareUrl = useCallback(
    async (selected) => {
      if (!urlid.url) {
        const urlObj = await generateUrl(selected.id);
        setUrlid(urlObj);

        return urlObj;
      } else {
        return urlid;
      }
    },
    [data, urlid]
  );

  const handleCopy = async (e) => {
    window.gtag("event", "CopyInviteInfo", {
      event_category: "Share",
      event_label: "招待情報のコピーボタン",
    });
    e.preventDefault();
    const { url, expiredDate } = await generateShareUrl(data);
    const text = getShareMessage(url, expiredDate, data.name);
    navigator.clipboard.writeText(text).then(
      function () {
        log.debug("Success copy url");
        alert(getLabel("copied"));
      },
      function (err) {
        log.debug("Could not copy text: ", err);
        alert(getLabel("copyFail"));
      }
    );
  };
  const handleShare = async (e) => {
    window.gtag("event", "ShareInviteInfo", {
      event_category: "Share",
      event_label: "招待情報の共有ボタン",
    });
    e.preventDefault();
    const title = getLabel("notonokyoyu");
    const { url, expiredDate } = await generateShareUrl(data);
    const text = getShareMessage(url, expiredDate, data.name);
    if (navigator.canShare) {
      log.debug("share:navigator.share");
      navigator.share({
        title: title,
        text: text,
      });
    } else {
      log.debug("share:mailto");
      window.open(`mailto:?subject=${title}&body=${text.replaceAll("\n", "%0D%0A")}`);
    }
  };

  const dataSample = useMemo(() => {
    let renderList = [
      { label: getLabel("namae"), value: data.name },
    ];
    renderList = renderList.concat(
      data?.splitList?.map((ele) => ({
        label: ele?.label,
        value: ele?.value,
      }))
    );
    renderList.push({
      type: "url",
      label: getLabel("inviteURL"),
      value: (
        <ListTwoLineButton
          marginLeft={isMobile ? "0" : "10px"}
          label=""
          value={isMobile ? getLabel("inviteURL") : ""}
          buttons={
            isCopyEnable
              ? [
                  {
                    label: getLabel("copy"),
                    icon: (
                      <IconContentCopy
                        color="var(--my-theme-color)"
                        fontSize="22px"
                      />
                    ),
                    onClick: (e) => {
                      handleCopy(e);
                    },
                  },
                  {
                    label: getLabel("share"),
                    icon: (
                      <IconShare
                        color="var(--my-theme-color)"
                        fontSize="22px"
                      />
                    ),
                    onClick: (e) => {
                      handleShare(e);
                    },
                  },
                ]
              : [
                  {
                    label: getLabel("share"),
                    icon: (
                      <IconShare
                        color="var(--my-theme-color)"
                        fontSize="18px"
                      />
                    ),
                    onClick: handleShare,
                  },
                ]
          }
        />
      ),
    });
    return renderList;
  }, [data.name, data.originDate, data?.splitList, handleCopy, handleShare, isCopyEnable]); 

  return (
    <div className={!isMobile ? "swapper" : ""}>
      <LabelM label={getLabel("shotaiurlokyoyu")} isDivide={!isMobile} /> {/**TODO: */}
      <Description width="100%" text={isMobile ? getLabel("invitePreview.des1") : getLabel("invitePreview.des2")} /**TODO: */ />
      <div style={{ marginTop: "7px" }}>
        {isMobile ? (
          dataSample.map((ele, idx) => {
            return ele?.type === "url" ? (
              <React.Fragment key={idx}>{ele?.value}</React.Fragment>
            ) : (
              <ListTwolineA key={idx} isEmptyHide={true} label={getLabel(ele?.label)} value={ele?.value} />
            );
          })
        ) : (
          <Flex maxWidth="896px">
            <ListSingleLineCEdit
              list={dataSample}
              overrides={{
                "Flex.PCPrimaryButtonSave[0]": {
                  display: "none",
                },
              }}
            />
          </Flex>
        )}
      </div>
      <Flex justifyContent="center" alignItems="center" marginTop="32px">
        <DefaultButton
          margin="0"
          marginBottom="0"
          alignItems="flex-end"
          onClick={() => navigate(-2, { replace: true })}
          overrides={{
            "Flex.Button[0]": {
              fontSize: isMobile ? "14px" : "16px",
              padding: isMobile ? "8px 12px" : "8px 16px",
              children: getLabel("shotaioshuryosuru"),
              color: "rgba(13,26,38,1)",
            },
          }}
        />
        <PrimaryButton
          margin="0"
          onClick={() => navigate(-1, { replace: true })}
          padding={isMobile ? "8px 12px" : "8px 16px"}
          overrides={{
            "Flex.Text[0]": {
              fontSize: isMobile ? "14px" : "16px",
              children: getLabel("shotaiotsudzukeru"),
            },
          }}
        />
      </Flex>
    </div>
  );
}
