/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import Footer from "./Footer";
import { Flex } from "@aws-amplify/ui-react";
export default function PCFooter(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="0"
      direction="column"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "PCFooter")}
    >
      <Footer
        display="flex"
        gap="0"
        direction="column"
        width="375px"
        height="207px"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="32px 32px 32px 32px"
        backgroundColor="var(--my-theme-header-footer-color)"
        {...getOverrideProps(overrides, "Footer")}
      ></Footer>
    </Flex>
  );
}
