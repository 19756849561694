import { DataStore, Storage } from "aws-amplify";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { LeavesKakeizu } from "../models";
import { ListField } from "../page-components";
import { getLabel, isEmpty, useInitializePage } from "../utils/CommonUtils";
import { log } from "../utils/Logger";
import { usePrompt } from "../utils/Prompt";

function FamilyTreeEditPage() {
  const navigate = useInitializePage("kakeizu", "title.update");
  const data = useRef();
  const [update, setUpdate] = useState(false);
  const [familyTreeData, setFamilyTreeData] = useState({
    filemei: "",
    s3key: "",
    hokanbasyo: "",
    bikou: "",
  });
  const [familyTreeDataError, setFamilyTreeDataError] = useState({
    filemeiError: "",
    tenpufileError: "",
  });
  const [isUpdated, setIsUpdated] = useState(false);
  const params = useParams();
  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const dataQuery = await DataStore.query(LeavesKakeizu, (c) =>
        c.id("eq", params.id),
      );
      if (dataQuery?.length > 0) {
        data.current = dataQuery[0];

        const list = await Storage.list(`LeavesKakeizu/${dataQuery[0].id}/`, {
          level: "private",
        });
        const url = list.length > 0 ? list[0].key : null;

        setFamilyTreeData({
          filemei: dataQuery[0].filemei,
          s3key: dataQuery[0].s3key,
          hokanbasyo: dataQuery[0].hokanbasyo,
          bikou: dataQuery[0].bikou,
          objectSrc: url,
          tenpufile: url,
        });
      }
    };
    getData();
  }, [params.id]);

  const listField = [
    {
      type: "textField",
      label: "filemei",
      isRequire: true,
      placeHolder: "yamadakenokakeizunado",
      value: familyTreeData.filemei,
      errorMessage: familyTreeDataError.filemeiError,
    },
    {
      type: "file",
      label: "tenpufile",
      isRequire: true,
      value: familyTreeData.objectSrc,
      errorMessage: familyTreeDataError.tenpufileError,
    },
    {
      type: "textField",
      label: "hokanbasyo",
      value: familyTreeData.hokanbasyo,
      placeHolder: "chounannojitakunado",
    },
    {
      type: "textArea",
      label: "bikou",
      value: familyTreeData.bikou,
    },
  ];

  const eventTracking = () => {
    window.gtag("event", "ChangeComplete", {
      event_category: "Edit",
      event_label: "家系図変更完了",
    });
  };

  const updateDataFamilyTree = useCallback(async () => {
    let valid = true;
    const error = {};

    if (isEmpty(familyTreeData.filemei)) {
      error.filemeiError = isEmpty(familyTreeData.filemei)
        ? getLabel("error.required")
        : "";
      valid = false;
    } else {
      error.filemeiError = null;
    }
    if (familyTreeData.tenpufile == null) {
      error.tenpufileError = getLabel("error.required");
      valid = false;
    } else {
      error.tenpufileError = null;
    }

    setFamilyTreeDataError(error);

    if (valid) {
      setIsUpdated(true);
      await DataStore.save(
        LeavesKakeizu.copyOf(data.current, (updated) => {
          updated.filemei = familyTreeData.filemei;
          updated.hokanbasyo = familyTreeData.hokanbasyo;
          updated.bikou = familyTreeData.bikou;
        }),
      );
      if (familyTreeData.objectSrc !== familyTreeData.tenpufile) {
        const editData = async () => {
          const listFile = await Storage.list(
            `LeavesKakeizu/${data.current.id}/`,
            {
              level: "private",
            },
          );
          for (let i = 0; i < listFile.length; i++) {
            await Storage.remove(listFile[i].key, { level: "private" });
          }
          if (familyTreeData.tenpufile != null) {
            const result = await Storage.put(
              `LeavesKakeizu/${data.current.id}/${familyTreeData.tenpufile.name}`,
              familyTreeData.tenpufile,
              {
                contentType: familyTreeData.tenpufile.type,
                level: "private",
              },
            );

            log.debug("s3 upload result", result);
          }
          eventTracking();
          navigate(-1, { replace: true });
        };
        editData();
      } else {
        eventTracking();
        navigate(-1, { replace: true });
      }
    }
  }, [
    familyTreeData.filemei,
    familyTreeData.tenpufile,
    familyTreeData.objectSrc,
    familyTreeData.hokanbasyo,
    familyTreeData.bikou,
    navigate,
  ]);

  return (
    <ListField
      label={getLabel("button.update")}
      list={listField}
      setState={setFamilyTreeData}
      setUpdate={setUpdate}
      onSave={updateDataFamilyTree}
      isDisabled={isUpdated}
      onCancel={() => navigate(-1, { replace: true })}
    />
  );
}

export default FamilyTreeEditPage;
