import { MindTypeQuestion } from "../page-components";

export default function MindTypeCheckPages(props) {

    if ( props.page == 1 ) {
        /* ディーナーの人生満足尺度 */
        return(
           <div>
             <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={1}
               questionNo={1}
               text="1. ほとんどの面で、私の人生は私の理想に近い" />
             <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={1}
               questionNo={2}
               text="2. 私の人生は、とてもすばらしい状態だ" />
             <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={1}
               questionNo={3}
               text="3. 私は自分の人生に満足している" />
             <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={1}
               questionNo={4}
               text="4. 私はこれまで、自分の人生に求める大切なものを得てきた" />
             <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={1}
               questionNo={5}
               text="5. もう一度人生をやり直せるとしても、ほとんど何も変えないだろう" />
           </div>
        );
    }
    else if ( props.page == 2 ) {
        /* ポジティブ感情 */
        return(
            <div>
              <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={2}
               questionNo={1}
               text="1. 活気のある" />
              <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={2}
               questionNo={2}
               text="2. わくわくした" />
              <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={2}
               questionNo={3}
               text="3. 気合の入った" />
              <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={2}
               questionNo={4}
               text="4. きっぱりとした" />
              <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={2}
               questionNo={5}
               text="5. 機敏な" />
              <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={2}
               questionNo={6}
               text="6. 誇らしい" />
              <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={2}
               questionNo={7}
               text="7. 強気な" />
              <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={2}
               questionNo={8}
               text="8. 熱狂した" />
            </div>
        );
    }
    else if ( props.page == 3 ) {
        /* ネガティブ感情 */
        return(
            <div>
              <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={3}
               questionNo={1}
               text="1. いらだった" />
              <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={3}
               questionNo={2}
               text="2. 苦悩した" />
              <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={3}
               questionNo={3}
               text="3. ぴりぴりした" />
              <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={3}
               questionNo={4}
               text="4. びくびくした" />
              <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={3}
               questionNo={5}
               text="5. 恥じた" />
              <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={3}
               questionNo={6}
               text="6. うろたえた" />
              <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={3}
               questionNo={7}
               text="7. 心配した" />
              <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={3}
               questionNo={8}
               text="8. おびえた" />
            </div>
        );
    }
    else if ( props.page == 4 ) {
        /* 幸せの4因子(1) */
        return(
            <div>
              <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={4}
               questionNo={1}
               text="1. 私は有能である" />
              <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={4}
               questionNo={2}
               text="2. 私は社会・組織の要請に応えている" />
              <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={4}
               questionNo={3}
               text="3. 私のこれまでの人生は、変化、学習、成長に満ちていた" />
              <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={4}
               questionNo={4}
               text="4. 今の自分は「本当になりたかった自分」である" />
              <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={4}
               questionNo={5}
               text="5. 人の喜ぶ顔が見たい" />
              <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={4}
               questionNo={6}
               text="6. 私を大切に思ってくれる人たちがいる" />
              <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={4}
               questionNo={7}
               text="7. 私は、人生において感謝することがたくさんある" />
              <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={4}
               questionNo={8}
               text="8. 私は日々の生活において、他者に親切にし、手助けしたいと思っている" />
            </div>
        );
    }
    else if ( props.page == 5 ) {
        /* 幸せの4因子(2) */
        return(
            <div>
              <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={5}
               questionNo={9}
               text="9. 私はものごとが思い通りにいくと思う" />
              <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={5}
               questionNo={10}
               text="10. 私は学校や仕事での失敗や不安な感情をあまり引きずらない" />
              <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={5}
               questionNo={11}
               text="11. 私は他者との近しい関係を維持することができる" />
              <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={5}
               questionNo={12}
               text="12. 自分は人生で多くのことを達成してきた" />
              <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={5}
               questionNo={13}
               text="13. 私は自分と他者がすることをあまり比較しない" />
              <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={5}
               questionNo={14}
               text="14. 私に何ができて何ができないかは外部の制約のせいではない" />
              <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={5}
               questionNo={15}
               text="15. 自分自身についての信念はあまり変化しない" />
              <MindTypeQuestion
               answerTable={props.answerTable}
               setAnswerTable={props.setAnswerTable}
               page={5}
               questionNo={16}
               text="16. テレビを見るとき、チャンネルをあまり頻繁に切り替え過ぎない" />
            </div>
        );
    }
}
