import { Flex, Image, Text } from "@aws-amplify/ui-react";
import { Storage } from "aws-amplify";
import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { ListTwolineA, PCListSingleLineCTextButton, TextButton } from "../ui-components";
import { getLabel } from "../utils/CommonUtils";
import { log } from "../utils/Logger";

export default function SelectImageField(props) {
  const [filename, setFilename] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  const hiddenFileInput = useRef(null);

  useEffect(async () => {
    if (props.objectSrc != null) {
      const inFilename = getFileName(props.objectSrc);
      const url = await Storage.get(props.objectSrc, {
        level: "private",
      });
      if (isImage(inFilename)) {
        setPreviewUrl(url);
      }
      setFilename(inFilename);
    }
  }, [props.objectSrc]);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const removeSelectedFile = (event) => {
    setPreviewUrl(null);
    setFilename(null);

    if (props.onChange) {
      props.onChange({
        target: {
          value: null,
        },
      });
    }
  };
  const uploadFile = async (file) => {
    log.debug("select file", file);
    let isError = false;
    if(props?.onlyImage && !isImage(file.name)){
      isError = true;
      setFilename(
        <span>
          <font className="amplify-field__error-message">{getLabel("error.onlyImage")}</font>
        </span>
      );
    }
    if (ignoreExtensions(file.name)) {
      isError = true;
      setFilename(
        <span>
          <font className="amplify-field__error-message">{getLabel("error.fileextentions")}</font>
        </span>
      );
    }
    //動画 150M(157286400 byte), それ以外 10M(10485760 byte)
    if (isVideo(file.name)) {
      if (file.size > 157286400) {
        isError = true;
        setFilename(
          <span>
            <font className="amplify-field__error-message">{getLabel("error.filesize")}</font>
          </span>
        );
      }
    } else if (file.size > 10485760) {
      isError = true;
      setFilename(
        <span>
          <font className="amplify-field__error-message">{getLabel("error.filesize")}</font>
        </span>
      );
    }

    if(isError){
      if (props.onChange) {
        props.onChange({
          target: {
            value: null,
          },
        });
      }
      setPreviewUrl(null);
    } else {
      if (props.onChange) {
        props.onChange({
          target: {
            value: file,
          },
        });
      }
      if (isImage(file.name)) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewUrl(reader.result);
          // console.log(reader.result);
        };
        reader.readAsDataURL(file);
      }
      setFilename(file.name);
    }
  };

  const isVideo = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "m4v":
      case "avi":
      case "mp4":
      case "mov":
      case "mpg":
      case "mpeg":
        return true;
    }
    return false;
  };
  const isImage = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "png":
      case "jpg":
      case "jpeg":
      case "gif":
      case "bmp":
        return true;
    }
    return false;
  };
  const ignoreExtensions = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "html":
      case "htm":
      case "js":
      case "exe":
      case "bat":
      case "cmd":
      case "com":
      case "dll":
      case "vbs":
      case "wsf":
      case "wsh":
      case "msc":
      case "msi":
      case "sh":
      case "pl":
      case "php":
      case "cgi":
        return true;
    }
    return false;
  };

  const getExtension = (filename) => {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  };
  const getFileName = (filename) => {
    var parts = filename.split("/");
    return parts[parts.length - 1];
  };

  if (props.isEmptyHide && !props.value) {
    return <></>;
  } else {
    if (isMobile) {
      return (
        <Flex gap="0" direction="column" padding="16px 16px 0px">
          <ListTwolineA
            width={"auto"}
            padding="0px"
            overrides={{
              "Flex.Flex[0].Text[0]": {
                children: props.isRequired ? (
                  <span>
                    {props.label}
                    <font className="required">※</font>
                  </span>
                ) : (
                  props.label
                ),
                fontSize: "14px",
                color: "rgb(48, 64, 80)",
              },
              "Flex.Flex[0].Text[1]": {
                children: !!filename ? filename : getLabel("list.select"),
                color: "#5c6670",
                fontSize: "14px",
                textAlign: "justify",
                className: "word-break",
                whiteSpace: "break-spaces",
                width: "fit-content",
                maxWidth: "100vw",
              },
            }}
          />
          {previewUrl ? <Image width="100%" height="auto" shrink="0" position="relative" padding="0px 0px 0px 0px" src={previewUrl}></Image> : null}
          <Flex>
            {filename && typeof filename === "string" ? (
              <TextButton
                className="pointer"
                width="fit-content"
                margin="auto"
                padding="0px"
                onClick={removeSelectedFile}
                overrides={{
                  "Flex.Text[0]": {
                    color: "red",
                    children: getLabel("filewosakujo"),
                  },
                }}
              />
            ) : null}
            <TextButton
              className="pointer"
              width="fit-content"
              margin="auto"
              padding="0px"
              onClick={handleClick}
              overrides={{
                "Flex.Text[0]": {
                  children: getLabel("filewosentaku"),
                },
              }}
            />
          </Flex>
          {!!props.errorMessage ? <Text paddingTop="4px" className="amplify-field__error-message" children={props.errorMessage} /> : null}
          <input
            ref={hiddenFileInput}
            hidden
            type="file"
            onChange={(e) => {
              uploadFile(e.target.files[0]);
            }}
          />
        </Flex>
      );
    } else {
      return (
        <Flex className={props.className ? props.className : "pccomponent"} gap="0" direction="column" margin="0px auto 16px">
          <PCListSingleLineCTextButton
            width={"auto"}
            className={props.className ? props.className : "pccomponent"} 
            margin="0 0 16px"
            overrides={{
              Content: {
                shrink:"",
                height: "auto",
                maxWidth: "670px",
              },
              Category: {
                children: props.isRequired ? (
                  <span>
                    {props.label}
                    <font className="required">※</font>
                  </span>
                ) : (
                  props.label
                ),
              },
              Item: {
                children: !!filename ? (
                  <>
                    {filename}
                    {previewUrl ? (
                      <Image width="100%" height="auto" shrink="0" position="relative" padding="0px 0px 0px 0px" src={previewUrl}></Image>
                    ) : null}
                  </>
                ) : (
                  getLabel("list.select")
                ),
                textAlign: "justify",
                className: "word-break",
                whiteSpace: "break-spaces",
                width: "fit-content",
                maxWidth: "100vw",
              },
              "Frame 549": {
                margin: "auto",
              },
              PC_TextButton531329624: {
                display: filename && typeof filename === "string" ? "block" : "none",
                className: "pointer",
                onClick: removeSelectedFile,
                overrides: {
                  "Text Button": {
                    color: "red",
                    children: getLabel("filewosakujo"),
                  },
                },
              },
              PC_TextButton574415494: {
                className: "pointer",
                onClick: handleClick,
                overrides: {
                  "Text Button": {
                    children: getLabel("filewosentaku"),
                  },
                },
              },
            }}
          />

          {!!props.errorMessage ? <Text paddingTop="4px" className="amplify-field__error-message" children={props.errorMessage} /> : null}
          <input
            ref={hiddenFileInput}
            hidden
            type="file"
            id="f"
            onChange={(e) => {
              uploadFile(e.target.files[0]);
            }}
          />
        </Flex>
      );
    }
  }
}
