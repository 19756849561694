import { DataStore } from "aws-amplify";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { phoneRegex } from "../constants";
import { LeavesSumahoPc } from "../models";
import { ListField } from "../page-components";
import UserContext from "../UserContext";
import { getLabel, isNotEmpty, useInitializePage } from "../utils/CommonUtils";
import { KIKISHUBETSU, useDatalist } from "../utils/DatalistUtils";
import { usePrompt } from "../utils/Prompt";

function DevicesAddPage() {
  const navigate = useInitializePage("sumahoyapcnitsuite", "title.add");
  const context = useContext(UserContext);
  const [update, setUpdate] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const [deviceData, setDeviceData] = useState({
    kikishubetsu: "",
    keiyakusaki: "",
    denwabangou: "",
    meiginin: "",
    kishumei: "",
    bikou: "",
  });
  const [denwabangouError, setdenwabangouError] = useState(null);
  const [
    KikishubetsuDataList,
    // eslint-disable-next-line no-unused-vars
    setKikishubetsu,
    getKikishubetsu,
    hasErrorKikishubetsu,
  ] = useDatalist({
    list: KIKISHUBETSU,
    required: true,
    dataUpdateHook: setUpdate,
  });

  const listField = [
    {
      type: "dataList",
      child: <KikishubetsuDataList label="kikishubetsu" />,
    },
    {
      type: "textField",
      label: "keiyakusaki",
      placeHolder: getLabel("carrieryaprovidernado"),
    },
    {
      type: "textField",
      typeInput: "tel",
      label: "denwabangou",
      errorMessage: denwabangouError,
    },
    {
      type: "textField",
      label: "meiginin",
    },
    {
      type: "textField",
      label: "kishumei",
      placeHolder: getLabel("iphone12nado"),
    },
    {
      type: "textArea",
      label: "bikou",
    },
  ];
  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  const addData = useCallback(async () => {
    let valid = true;
    const validPhone =
      !phoneRegex.test(deviceData.denwabangou) &&
      isNotEmpty(deviceData.denwabangou);
    if (hasErrorKikishubetsu() || validPhone) {
      setdenwabangouError(validPhone ? getLabel("error.telno") : null);
      valid = false;
    }
    if (valid) {
      setIsAdded(true);
      await DataStore.save(
        new LeavesSumahoPc({
          kikishubetsu: getKikishubetsu(),
          keiyakusaki: deviceData.keiyakusaki,
          denwabangou: deviceData.denwabangou,
          meiginin: deviceData.meiginin,
          kishumei: deviceData.kishumei,
          bikou: deviceData.bikou,
          leavesusersID: context.leavesUser.id,
        }),
      );
      window.gtag("event", "FirstWriteComplete", {
        event_category: "Edit",
        event_label: "スマホやPCについて書込完了",
      });
      navigate(-1, { replace: true });
    }
  }, [
    context.leavesUser.id,
    deviceData.bikou,
    deviceData.denwabangou,
    deviceData.keiyakusaki,
    deviceData.kishumei,
    deviceData.meiginin,
    getKikishubetsu,
    hasErrorKikishubetsu,
    navigate,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ListField
      label={getLabel("shinkitsuika")}
      list={listField}
      setState={setDeviceData}
      setUpdate={setUpdate}
      onSave={addData}
      onCancel={() => navigate(-1, { replace: true })}
      isDisabled={isAdded}
    />
  );
}

export default DevicesAddPage;
