import { DataStore, Storage } from "aws-amplify";
import { Fragment, useEffect, useRef, useState, useContext } from "react";
import { isMobile, isTablet } from "react-device-detect";
import { useParams } from "react-router-dom";
import { LeavesLifeFuture } from "../models";
import {
  BucketListGuideDialog,
  LabelS,
  ListSelectContent,
  StrageImage,
  TodoListACheck,
} from "../page-components";
import {
  getCurrentUserAge,
  getLabel,
  getListRangeAge,
  useInitializePage,
} from "../utils/CommonUtils";
import { isShare, listObjects, queryDatas } from "../utils/ShareUtils";
import UserContext from "../UserContext";
import { PriorityLabel, Priority } from "../constants";
import { Tasseijoukyoushurui } from "../models";
import Cookies from "js-cookie";

export default function LifeFuturePage() {
  const navigate = useInitializePage("challenge");
  const context = useContext(UserContext);
  const currentUserAge = getCurrentUserAge(context.user?.attributes.birthdate);
  const ageRangeOption = getListRangeAge(currentUserAge);
  const [isLoaded, setIsLoaded] = useState(false);
  const [list, setList] = useState([]);
  const [renderList, setRenderList] = useState();
  const [selected, setSelected] = useState(null);
  const [imageList, setImageList] = useState([]);
  const firstItemAdded = useRef(false);
  const [isShow, setIsShow] = useState(false);
  const params = useParams();

  const handledChangeActive = (item) => {
    setSelected(item);
  };

  const quickAchieve = async () => {
    const refData = selected;
    await DataStore.save(
      LeavesLifeFuture.copyOf(selected, (updated) => {
        updated.tasseijoukyoushurui = Tasseijoukyoushurui.TASSEI;
      })
    );
    const data = await queryDatas(params, LeavesLifeFuture);
    if (data?.length > 0) {
      setList(data);
      setSelected(data?.find((ele) => ele?.id === refData.id));
    }
  };

  useEffect(() => {
    if (list?.length > 0) {
      let isFisrtGroup = true;
      const newsortedList = ageRangeOption.map((ele, idx) => {
        const listItem = list.filter(
          (item) =>
            (item?.jitsugennenreikigen >= Number(ele.value) &&
              item?.jitsugennenreikigen <= Number(ele.value) + 4) ||
            (idx === 0 && item?.jitsugennenreikigen <= Number(ele?.value)) ||
            (idx === ageRangeOption?.length - 1 &&
              item?.jitsugennenreikigen >= Number(ele?.value))
        );
        const jsxList = [];

        if (listItem.length > 0) {
          jsxList.push(
            <LabelS
              key={ele.value}
              label={ele.label}
              margin="0"
              marginTop={isFisrtGroup && !isTablet ? "-16px" : "0"}
            />
          );
          isFisrtGroup = false;
          const highList = listItem
            .filter((ele) => ele.yuusendo === Priority.KOU || !ele.yuusendo)
            ?.sort((a, b) =>
              a.createdAt > b.createdAt || !a.createdAt ? 1 : -1
            );
          const duringList = listItem
            .filter((ele) => ele.yuusendo === Priority.CHUU)
            ?.sort((a, b) =>
              a.createdAt > b.createdAt || !a.createdAt ? 1 : -1
            );
          const lowList = listItem
            .filter((ele) => ele.yuusendo === Priority.TEI)
            ?.sort((a, b) =>
              a.createdAt > b.createdAt || !a.createdAt ? 1 : -1
            );

          const sortedList = highList.concat(duringList.concat(lowList));

          sortedList.forEach((data) => {
            if (!firstItemAdded.current && data) {
              setSelected(data);
              firstItemAdded.current = true;
            }
            if (isMobile) {
              jsxList.push(
                <TodoListACheck
                  key={data.id}
                  label={data.yaritaikoto}
                  imageSrc={imageList[data?.id]}
                  onClick={() => navigate(data?.id)}
                  isAchieve={
                    data?.tasseijoukyoushurui === Tasseijoukyoushurui.TASSEI
                  }
                  marginTop="16px"
                />
              );
            } else {
              jsxList.push(
                <TodoListACheck
                  key={data.id}
                  label={data.yaritaikoto}
                  imageSrc={imageList[data?.id]}
                  selected={selected?.id === data?.id}
                  isAchieve={
                    data?.tasseijoukyoushurui === Tasseijoukyoushurui.TASSEI
                  }
                  onClick={() => handledChangeActive(data)}
                />
              );
            }
          });
        }
        return jsxList;
      });
      setRenderList(newsortedList);
    } else {
      setRenderList([]);
      setSelected(null);
    }
  }, [list, navigate, selected, selected?.id, imageList]);

  useEffect(() => {
    const getData = async () => {
      const data = await queryDatas(params, LeavesLifeFuture);
      const isShowed = Cookies.get("isShowedBucketListGuide") === "true";
      if (!isShowed) {
        const dataChallenge = await queryDatas(params, LeavesLifeFuture);
        setIsShow(data?.length === 0 && dataChallenge?.length === 0);
      }
      setList(data);
      setIsLoaded(true);
    };
    getData();
  }, []);

  useEffect(() => {
    let mount = true;
    if (list?.length > 0) {
      const images = {};
      const getList = async () => {
        const listPromise = [];
        for (let i = 0; i < list.length; i++) {
          listPromise.push(
            new Promise((resolve) => {
              listObjects(params, LeavesLifeFuture, list[i]).then((res) => {
                if (res.length > 0) {
                  resolve({
                    id: list[i].id,
                    key: res[0].key,
                  });
                } else {
                  resolve({
                    id: list[i].id,
                    key: null,
                  });
                }
              });
            })
          );
        }
        const result = await Promise.all(listPromise);
        result.forEach((ele) => {
          images[ele.id] = ele.key;
        });
        if (mount) setImageList(images);
      };
      getList();
    }
    return () => (mount = false);
  }, [list, params]);

  const deleteData = async (data) => {
    firstItemAdded.current = false;
    await DataStore.delete(data);
    await Storage.remove(imageList[data.id], {
      level: "private",
    });
    const newList = await DataStore.query(LeavesLifeFuture);
    setList(newList);
  };

  return (
    <>
      <ListSelectContent
        label={getLabel("yaritaikoto")}
        isHideLabel
        isMemoBtn
        isChallengeCate
        isAchieved={selected?.tasseijoukyoushurui ===  Tasseijoukyoushurui.TASSEI}
        onAchieve={quickAchieve}
        mobileList={
          renderList &&
          renderList.map((ele, index) => {
            return <Fragment key={index}>{ele}</Fragment>;
          })
        }
        description={{
          type: "will",
          category: "yaritaikoto",
        }}
        isShared={isShare(params)}
        addButtonLabel={getLabel("yaritaikoto") + getLabel("button.addsuffix")}
        onAddClick={() => {
          navigate("add", { state: "challenge" });
          window.gtag("event", "FirstWrite", {
            event_category: "Edit",
            event_label: "やりたいこと書込開始",
          });
        }}
        style={{ marginTop: "16px", maxHeight: "calc(100vh - 435px)" }}
        pcListItem={
          renderList &&
          renderList.map((ele, index) => {
            return <Fragment key={index}>{ele}</Fragment>;
          })
        }
        selectedItemList={[
          {
            label: getLabel("yaritaikoto"),
            value: selected?.yaritaikoto,
          },
          {
            label: getLabel("imagegazou"),
            value: imageList[selected?.id] && (
              <StrageImage key={selected?.id} src={imageList[selected?.id]} />
            ),
          },
          {
            label: getLabel("episode.bucketlist"),
            value: selected?.episode,
          },
          {
            label: getLabel("jitsugennenreikigen"),
            value: ageRangeOption.find(
              (ele, idx) =>
                (selected?.jitsugennenreikigen >= Number(ele.value) &&
                  selected?.jitsugennenreikigen <= Number(ele.value) + 4) ||
                (idx === 0 &&
                  selected?.jitsugennenreikigen <= Number(ele?.value)) ||
                (idx === ageRangeOption?.length - 1 &&
                  selected?.jitsugennenreikigen >= Number(ele?.value))
            )?.label,
          },
          {
            label: getLabel("yuusendo"),
            value: getLabel(PriorityLabel[selected?.yuusendo]),
          },
          {
            label: getLabel("tasseijoukyoushurui"),
            value:
              selected?.tasseijoukyoushurui &&
              getLabel("Tasseijoukyoushurui." + selected?.tasseijoukyoushurui),
          },
          {
            label: getLabel("bikou"),
            value: selected?.bikou,
          },
        ]}
        selectedItemLabel={selected?.yaritaikoto}
        onDelete={() => deleteData(selected)}
        onEdit={() => {
          navigate(selected?.id + "/edit");
          window.gtag("event", "Change", {
            event_category: "Edit",
            event_label: "やりたいこと変更開始",
          });
        }}
        isLoaded={isLoaded}
      />
      {isShow && !isShare(params) && <BucketListGuideDialog />}
    </>
  );
}
