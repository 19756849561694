import { DataStore } from "aws-amplify";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Hokennoumu, LeavesPet } from "../models";
import { ListField } from "../page-components";
import UserContext from "../UserContext";
import {
  getLabel,
  isEmpty,
  isInvalidDateFormat,
  isNotEmpty,
  isNotEmptyDate,
  useInitializePage,
} from "../utils/CommonUtils";
import { usePrompt } from "../utils/Prompt";

function PetAddPage() {
  const navigate = useInitializePage("petnitsuite", "title.add");
  const context = useContext(UserContext);
  const [petData, setPetData] = useState({
    namae: "",
    shurui: "",
    seinengappi: "",
    seibetsu: "",
    tourokubangou: "",
    hokennoumu: "",
    hokennkaisyamei: "",
    kakaritsukenobyouinmei: "",
    kakaritsukenobyouinnorenrakusaki: "",
    bikou: "",
  });
  const [petDataError, setPetDataError] = useState({
    namaeError: "",
    shuruiError: "",
    seinengappiError: "",
    seibetsuError: "",
    tourokubangouError: "",
    hokennoumuError: "",
    hokennkaisyameiError: "",
    kakaritsukenobyouinmeiError: "",
    kakaritsukenobyouinnorenrakusakiError: "",
    bikouError: "",
  });
  const [isAdded, setIsAdded] = useState(false);
  const [hokennoumuList, setHokennoumuList] = useState();
  const listField = [
    {
      type: "textField",
      label: "namae",
      isRequire: true,
      hasError: isNotEmpty(petDataError.namaeError),
      errorMessage: petDataError.namaeError,
    },
    {
      type: "textField",
      label: "shurui",
      placeHolder: "sibakennado",
      hasError: isNotEmpty(petDataError.shuruiError),
      errorMessage: petDataError.shuruiError,
    },
    {
      type: "textField",
      typeInput: "date",
      label: "seinengappi",
      hasError: isNotEmpty(petDataError.seinengappiError),
      errorMessage: petDataError.seinengappiError,
    },
    {
      type: "textField",
      label: "seibetsu",
      hasError: isNotEmpty(petDataError.seibetsuError),
      errorMessage: petDataError.seibetsuError,
    },
    {
      type: "textField",
      label: "tourokubangou",
      hasError: isNotEmpty(petDataError.tourokubangouError),
      errorMessage: petDataError.tourokubangouError,
    },
    {
      type: "selectField",
      label: "hokennoumu",
      options: hokennoumuList,
      isSelect: true,
    },
    {
      type: "textField",
      label: "hokennkaisyamei",
      hasError: isNotEmpty(petDataError.hokennkaisyameiError),
      errorMessage: petDataError.hokennkaisyameiError,
    },
    {
      type: "textField",
      label: "kakaritsukenobyouinmei",
      hasError: isNotEmpty(petDataError.kakaritsukenobyouinmeiError),
      errorMessage: petDataError.kakaritsukenobyouinmeiError,
    },
    {
      type: "textArea",
      label: "kakaritsukenobyouinnorenrakusaki",
      placeHolder: "denwabangouyasiteurlnado",
      hasError: isNotEmpty(petDataError.kakaritsukenobyouinnorenrakusakiError),
      errorMessage: petDataError.kakaritsukenobyouinnorenrakusakiError,
    },
    {
      type: "textArea",
      label: "bikou",
      hasError: isNotEmpty(petDataError.bikouError),
      errorMessage: petDataError.bikouError,
    },
  ];
  const [update, setUpdate] = useState(false);
  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  const addData = useCallback(async () => {
    let valid = true;
    const dateFormatCheck = isInvalidDateFormat(petData.seinengappi);
    if (isEmpty(petData.namae) || dateFormatCheck) {
      setPetDataError({
        ...petDataError,
        namaeError: isEmpty(petData.namae) ? getLabel("error.required") : "",
        seinengappiError: dateFormatCheck ? getLabel("error.dateformat") : "",
      });
      valid = false;
    }
    if (valid) {
      setIsAdded(true);
      await DataStore.save(
        new LeavesPet({
          namae: petData.namae,
          shurui: petData.shurui,
          seinengappi: isNotEmptyDate(petData.seinengappi)
            ? petData.seinengappi
            : null,
          seibetsu: petData.seibetsu,
          tourokubangou: petData.tourokubangou,
          hokennoumu: isNotEmpty(petData.hokennoumu)
            ? petData.hokennoumu
            : null,
          hokennkaisyamei: petData.hokennkaisyamei,
          kakaritsukenobyouinmei: petData.kakaritsukenobyouinmei,
          kakaritsukenobyouinnorenrakusaki:
            petData.kakaritsukenobyouinnorenrakusaki,
          bikou: petData.bikou,
          leavesusersID: context.leavesUser.id,
        }),
      );
      window.gtag("event", "FirstWriteComplete", {
        event_category: "Edit",
        event_label: "ペットについて書込完了",
      });
      navigate(-1, { replace: true });
    }
  }, [
    context.leavesUser.id,
    navigate,
    petData.bikou,
    petData.hokennkaisyamei,
    petData.hokennoumu,
    petData.kakaritsukenobyouinmei,
    petData.kakaritsukenobyouinnorenrakusaki,
    petData.namae,
    petData.seibetsu,
    petData.seinengappi,
    petData.shurui,
    petData.tourokubangou,
    petDataError,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const listOption = [];
    listOption.push(
      <option key="default" value="">
        {getLabel("list.select")}
      </option>,
    );
    Object.keys(Hokennoumu).forEach((type) => {
      listOption.push(
        <option key={type} value={type}>
          {getLabel("Hokennoumu." + type)}
        </option>,
      );
    });
    setHokennoumuList(listOption);
  }, []);

  return (
    <ListField
      label={getLabel("shinkitsuika")}
      list={listField}
      setState={setPetData}
      setUpdate={setUpdate}
      onSave={addData}
      onCancel={() => navigate(-1, { replace: true })}
      isDisabled={isAdded}
    />
  );
}

export default PetAddPage;
