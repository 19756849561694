import { DataStore } from "aws-amplify";
import { useEffect, useState } from "react";
import { FABDeleteEdit, LabelM, ListTwolineA } from "../page-components";
// import { DataStore } from "@aws-amplify/datastore";
import { useParams } from "react-router-dom";
import { LeavesJitsubutsuShisan } from "../models";
import { getLabel, useInitializePage } from "../utils/CommonUtils";
import { log } from "../utils/Logger";
import { isShare, queryData } from "../utils/ShareUtils";

/*
  shisanmei: String
  shisanshurui: Shisanshurui
  shozaibasho: String
  bikou: String
  _shisanshurui: String
  leavesusersID: ID! @index(name: "byLeavesUsers")
*/
export default function () {
  const navigate = useInitializePage("jitsubutsushisan", "title.detail");
  const [shisan, setShisan] = useState();

  let params = useParams();

  useEffect(async () => {
    // const shisan = await DataStore.query(LeavesJitsubutsuShisan, (c) => c.id("eq", params.id));
    const shisan = await queryData(params, LeavesJitsubutsuShisan);
    log.debug("shisan", shisan);
    if (shisan.length > 0) {
      setShisan(shisan[0]);
    }
  }, []);

  const deleteData = async () => {
    log.debug("delete data", shisan);
    await DataStore.delete(shisan);
    navigate(-1, { replace: true });
  };

  return (
    <div>
      <LabelM label={shisan?.shisanmei} />
      <ListTwolineA label={getLabel("shisanshurui")} value={shisan ? getLabel("JitsubutsuShisanshurui." + shisan?.shisanshurui) : ""} />
      <ListTwolineA label={getLabel("shisanmei")} value={shisan?.shisanmei} />
      <ListTwolineA isEmptyHide label={getLabel("shozaibasho")} value={shisan?.shozaibasho} />
      <ListTwolineA isEmptyHide label={getLabel("bikou")} value={shisan?.bikou} />
      {isShare(params) ? null : (
        <FABDeleteEdit
          onDelete={() => deleteData()}
          onEdit={() => {
            navigate("edit");
            window.gtag("event", "Change", {
              event_category: "Edit",
              event_label: "実物資産変更開始",
            });
          }}
        />
      )}
    </div>
  );
}
