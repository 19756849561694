import { DataStore } from "aws-amplify";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { emailRegex, phoneRegex } from "../constants";
import { LeavesYuujinChijin } from "../models";
import { ListField } from "../page-components";
import {
  getLabel,
  isEmpty,
  isInvalidDateFormat,
  isNotEmpty,
  isNotEmptyDate,
  useInitializePage,
} from "../utils/CommonUtils";
import { usePrompt } from "../utils/Prompt";

function FriendsEditPage() {
  const navigate = useInitializePage("yuujinchijin", "title.update");
  const data = useRef();
  const [friendData, setFriendData] = useState({
    shimei: "",
    kankeisei: "",
    seinengappi: "",
    juusho: "",
    denwabangou: "",
    mailaddress: "",
    bikou: "",
  });
  const [friendDataError, setFriendDataError] = useState({
    shimeiError: "",
    kankeiseiError: "",
    seinengappiError: "",
    juushoError: "",
    denwabangouError: "",
    mailaddressError: "",
    bikouError: "",
  });
  const [isUpdated, setIsUpdated] = useState(false);
  const params = useParams();
  const [update, setUpdate] = useState(false);

  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const dataQuery = await DataStore.query(LeavesYuujinChijin, (c) =>
        c.id("eq", params.id),
      );
      if (dataQuery?.length > 0) {
        data.current = dataQuery[0];
        setFriendData({
          shimei: dataQuery[0].shimei,
          kankeisei: dataQuery[0].kankeisei,
          seinengappi: dataQuery[0].seinengappi,
          juusho: dataQuery[0].juusho,
          denwabangou: dataQuery[0].denwabangou,
          mailaddress: dataQuery[0].mailaddress,
          bikou: dataQuery[0].bikou,
        });
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const listField = [
    {
      type: "textField",
      label: "shimei",
      value: friendData?.shimei,
      isRequire: true,
      errorMessage: friendDataError.shimeiError,
    },
    {
      type: "textField",
      label: "kankeisei",
      placeHolder: "koukounodoukyuuseinado",
      value: friendData?.kankeisei,
    },
    {
      type: "textField",
      label: "seinengappi",
      value: friendData?.seinengappi,
      typeInput: "date",
      errorMessage: friendDataError.seinengappiError,
    },
    {
      type: "textField",
      label: "juusho",
      value: friendData?.juusho,
    },
    {
      type: "textField",
      label: "denwabangou",
      typeInput: "tel",
      value: friendData?.denwabangou,
      errorMessage: friendDataError.denwabangouError,
    },
    {
      type: "textField",
      label: "mailaddress",
      typeInput: "email",
      placeHolder: "nyuuryokumiss",
      value: friendData?.mailaddress,
      errorMessage: friendDataError.mailaddressError,
    },
    {
      type: "textArea",
      label: "bikou",
      value: friendData?.bikou,
      placeHolder: "kazokugashiranaiepisode",
    },
  ];

  const updateDataFriend = useCallback(async () => {
    let valid = true;
    const inValidPhone =
      !phoneRegex.test(friendData.denwabangou) &&
      isNotEmpty(friendData.denwabangou);
    const dateFormatCheck = isInvalidDateFormat(friendData.seinengappi);
    const checkEmail =
      isNotEmpty(friendData.mailaddress) &&
      !emailRegex.test(friendData.mailaddress);
    if (
      isEmpty(friendData.shimei) ||
      dateFormatCheck ||
      inValidPhone ||
      checkEmail
    ) {
      setFriendDataError({
        ...friendDataError,
        shimeiError: isEmpty(friendData.shimei)
          ? getLabel("error.required")
          : "",
        seinengappiError: dateFormatCheck ? getLabel("error.dateformat") : "",
        denwabangouError: inValidPhone ? getLabel("error.telno") : "",
        mailaddressError: checkEmail ? getLabel("error.emailformat") : "",
      });
      valid = false;
    }
    if (valid) {
      setIsUpdated(true);
      await DataStore.save(
        LeavesYuujinChijin.copyOf(data.current, (updated) => {
          updated.shimei = friendData.shimei;
          updated.kankeisei = friendData.kankeisei;
          updated.seinengappi = isNotEmptyDate(friendData.seinengappi)
            ? friendData.seinengappi
            : null;
          updated.juusho = friendData.juusho;
          updated.denwabangou = friendData.denwabangou;
          updated.mailaddress = friendData.mailaddress;
          updated.bikou = friendData.bikou;
        }),
      );
      window.gtag("event", "ChangeComplete", {
        event_category: "Edit",
        event_label: "友人・知人変更完了",
      });
      navigate(-1, { replace: true });
    }
  }, [
    friendData.bikou,
    friendData.denwabangou,
    friendData.juusho,
    friendData.kankeisei,
    friendData.mailaddress,
    friendData.seinengappi,
    friendData.shimei,
    friendDataError,
    navigate,
  ]);

  return (
    <ListField
      label={getLabel("button.update")}
      list={listField}
      setState={setFriendData}
      setUpdate={setUpdate}
      onSave={updateDataFriend}
      onCancel={() => navigate(-1, { replace: true })}
      isDisabled={isUpdated}
    />
  );
}

export default FriendsEditPage;
