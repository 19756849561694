import React, { useEffect, useState, useRef } from "react";
import { LabelM, PasswordField, PrimaryButton } from "../page-components";
import { isEmpty, isNotEmpty, useInitializePage, getLabel } from "../utils/CommonUtils";
import { Text } from "@aws-amplify/ui-react";
import { Auth, API } from "aws-amplify";
import { log } from "../utils/Logger";

export default function () {
  const navigate = useInitializePage("passwordwohenkou");

  const [curPassword, setCurPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = React.useState("");

  const [curPasswordError, setCurPasswordError] = useState(null);
  const [newPasswordError, setNewPasswordError] = useState(null);
  const [newPasswordConfirmError, setNewPasswordConfirmError] = useState(null);

  // 二重送信防止用
  const processing = useRef(false);

  useEffect(async () => {}, []);

  const changePassword = async () => {
    // 二重送信防止用コード
    if (processing.current) return;
    processing.current = true;

    log.debug("change password ");
    let valid = true;
    if (isEmpty(curPassword)) {
      setCurPasswordError(getLabel("error.required"));
      // setCurPasswordError(<Text whiteSpace="pre-wrap">{getLabel("error.required") + "\n" + getLabel("error.required")}</Text>);
      valid = false;
    } else {
      setCurPasswordError(null);
    }
    if (isEmpty(newPassword)) {
      setNewPasswordError(getLabel("error.required"));
      valid = false;
    } else {
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`\\=+ ]).{8,99}$/;
      if (!regex.test(newPassword)) {
        setNewPasswordError(getLabel("error.passwordformat"));
        valid = false;
      } else {
        if (newPassword === curPassword) {
          setNewPasswordError(getLabel("error.samepassword"));
          valid = false;
        } else {
          setNewPasswordError(null);
        }
      }
    }
    if (isEmpty(newPasswordConfirm)) {
      setNewPasswordConfirmError(getLabel("error.required"));
      valid = false;
    } else {
      setNewPasswordConfirmError(null);
    }

    if (isNotEmpty(newPassword) && isNotEmpty(newPasswordConfirm)) {
      if (newPassword !== newPasswordConfirm) {
        setNewPasswordConfirmError(getLabel("error.differentpassword"));
        valid = false;
      } else {
        setNewPasswordConfirmError(null);
      }
    }
    if (valid) {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          return Auth.changePassword(user, curPassword, newPassword);
        })
        .then((data) => {
          log.debug("changePassword", data);
          navigate(-1, { replace: true });
        })
        .catch((err) => {
          log.debug("changePassword error", err);
          setCurPasswordError(getLabel("error.password." + err.name));
        });
    } else {
      // 二重送信防止用コード
      processing.current = false;
    }
  };
  return (
    <div>
      <LabelM label={getLabel("passwordwohenkou")} />
      <PasswordField
        isRequired={true}
        errorMessage={curPasswordError}
        autoComplete="current-password"
        name="current-password"
        label={getLabel("genzainopassword")}
        onChange={(event) => {
          setCurPassword(event.target.value);
        }}
      />
      <PasswordField
        isRequired
        errorMessage={newPasswordError}
        autoComplete="new-password"
        name="new-password"
        label={getLabel("atarashiipassword")}
        onChange={(event) => {
          setNewPassword(event.target.value);
        }}
        descriptiveText={
          <Text as="span" color="#5C6670" fontStyle="italic" fontSize="0.9rem">
            {getLabel("passwordformat")}
          </Text>
        }
      />
      <PasswordField
        isRequired
        errorMessage={newPasswordConfirmError}
        label={getLabel("atarashiipasswordsainyuuryoku")}
        onChange={(event) => {
          setNewPasswordConfirm(event.target.value);
        }}
      />
      <PrimaryButton label={getLabel("passwordwohenkou")} onClick={() => changePassword()} />
    </div>
  );
}
