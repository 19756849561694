import { DataStore } from "aws-amplify";
import { Fragment, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import { KinyuuShisanshurui, LeavesKinyuuShisan } from "../models";
import {
  LabelS,
  ListSelectContent,
  ListSingleLineA,
  ListSingleLineD,
} from "../page-components";
import { getLabel, useInitializePage } from "../utils/CommonUtils";
import { log } from "../utils/Logger";
import { isShare, queryDatas } from "../utils/ShareUtils";

export default function FinancialAssetsPage() {
  const navigate = useInitializePage("kinyuushisan");

  const [isLoaded, setIsLoaded] = useState(false);
  // const [shisanList, setShisanList] = useState([]);
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState(null);
  const [renderList, setRenderList] = useState();
  const firstItemAdded = useRef(false);
  const params = useParams();

  useEffect(() => {
    let mount = true;
    const getData = async () => {
      if (!mount) return;
      const assets = await queryDatas(params, LeavesKinyuuShisan);
      log.debug("assets", assets);
      // const list = [];
      // リスト作成
      // Object.keys(KinyuuShisanshurui).forEach((type) => {
      //   pushJsxList(list, assets, type);
      // });
      // setShisanList(list);
      setIsLoaded(true);
      setList(assets);
    };
    getData();
    return () => {
      mount = false;
    };
  }, []);

  // function pushJsxList(list, assets, shisanshurui) {
  //   const dsList = assets.filter(function (item, index, array) {
  //     return item.shisanshurui == shisanshurui;
  //   });
  //   if (dsList.length > 0) {
  //     list.push(
  //       <LabelS
  //         key={shisanshurui}
  //         width={"auto"}
  //         margin={"16px 0 0 0"}
  //         overrides={{
  //           "View.Text[0]": {
  //             children: getLabel("KinyuuShisanshurui." + shisanshurui),
  //             width: "fit-content",
  //           },
  //         }}
  //       />
  //     );
  //     dsList.forEach((asset) => {
  //       list.push(
  //         <ListSingleLineA
  //           key={asset.id}
  //           width="auto"
  //           margin="0 16px 0 16px"
  //           overrides={{
  //             View: {
  //               margin: "16px",
  //               className: "pointer",
  //               onClick: () => navigate(asset.id),
  //             },
  //             "View.View[0].Text[0]": {
  //               children: asset.shisanmei,
  //             },
  //           }}
  //         />
  //       );
  //     });
  //   }
  //   // return list;
  // }

  useEffect(() => {
    if (list?.length > 0) {
      const sorted = Object.keys(KinyuuShisanshurui).map((type, index) => {
        const jsxList = [];
        const dsList = list.filter(function (item, index, array) {
          return item.shisanshurui === type;
        });
        if (dsList.length > 0) {
          jsxList.push(
            <LabelS
              key={type}
              label={getLabel("KinyuuShisanshurui." + type)}
              margin="0"
            />,
          );
          dsList.forEach((asset, index) => {
            if (!firstItemAdded.current && asset) {
              setSelected(asset);
              firstItemAdded.current = true;
            }
            if (isMobile) {
              jsxList.push(
                <ListSingleLineA
                  key={asset.id}
                  label={asset.kinyuukikan}
                  onClick={() => navigate(asset.id)}
                />,
              );
            } else {
              jsxList.push(
                <ListSingleLineD
                  key={asset?.id}
                  label={asset?.kinyuukikan}
                  selected={selected?.id === asset?.id}
                  onClick={() => {
                    handledChangeActive(asset);
                  }}
                />,
              );
            }
          });
        }
        return jsxList;
      });
      setRenderList(sorted);
    } else {
      setRenderList([]);
      setSelected(null);
    }
  }, [list, navigate, selected, selected?.id]);

  const deleteData = async (data) => {
    log.debug("delete data", data);
    firstItemAdded.current = false;
    await DataStore.delete(data);
    const newList = await DataStore.query(LeavesKinyuuShisan);
    setList(newList);
  };

  const handledChangeActive = (item) => {
    setSelected(item);
  };

  return (
    <ListSelectContent
      label={getLabel("kinyuushisan")}
      mobileList={
        renderList &&
        renderList.map((ele, index) => {
          return <Fragment key={index}>{ele}</Fragment>;
        })
      }
      description={{
        type: "data",
        category: "kinyuushisan",
      }}
      isShared={isShare(params)}
      addButtonLabel={getLabel("kinyuushisan") + getLabel("button.addsuffix")}
      onAddClick={() => {
        navigate("add");
        window.gtag("event", "FirstWrite", {
          event_category: "Edit",
          event_label: "金融資産書込開始",
        });
      }}
      pcListItem={
        renderList &&
        renderList.map((ele, index) => {
          return <Fragment key={index}>{ele}</Fragment>;
        })
      }
      selectedItemList={[
        {
          label: getLabel("shisanshurui"),
          value: getLabel("KinyuuShisanshurui." + selected?.shisanshurui),
        },
        {
          label: getLabel("kinyuukikan"),
          value: selected?.kinyuukikan,
        },
        {
          label: getLabel("bikou"),
          value: selected?.bikou,
        },
      ]}
      selectedItemLabel={selected?.kinyuukikan}
      onDelete={() => deleteData(selected)}
      onEdit={() => {
        navigate(selected?.id + "/edit");
        window.gtag("event", "Change", {
          event_category: "Edit",
          event_label: "金融資産変更開始",
        });
      }}
      isLoaded={isLoaded}
    />
  );
}
