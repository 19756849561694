import { DataStore } from "aws-amplify";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { LeavesTakaramono } from "../models";
import { ListField } from "../page-components";
import UserContext from "../UserContext";
import { getLabel, isEmpty, useInitializePage } from "../utils/CommonUtils";
import { usePrompt } from "../utils/Prompt";

function CollectionsAddPage() {
  const navigate = useInitializePage("takaramonolistcollection", "title.add");
  const context = useContext(UserContext);
  const [collectionData, setCollectionData] = useState({
    collectionmei: "",
    hokanbasyo: "",
    bikou: "",
  });
  const [collectionDataError, setCollectionDataError] = useState({
    collectionmeiError: "",
    hokanbasyoError: "",
    bikouError: "",
  });
  const [isAdded, setIsAdded] = useState(false);
  const listField = [
    {
      type: "textField",
      label: "collectionmei",
      isRequire: true,
      placeHolder:
        "切手コレクションなど" /**TODO: using getLabel when 切手コレクションなど is defined in vocabulary*/,
      errorMessage: collectionDataError.collectionmeiError,
    },
    {
      type: "textField",
      label: "hokanbasyo",
      placeHolder:
        "本棚の赤いファイルなど" /**TODO: using getLabel when 本棚の赤いファイルなど is defined in vocabulary*/,
    },
    {
      type: "textArea",
      label: "bikou",
    },
  ];
  const [update, setUpdate] = useState(false);
  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  const addData = useCallback(async () => {
    let valid = true;
    if (isEmpty(collectionData.collectionmei)) {
      setCollectionDataError({
        ...collectionDataError,
        collectionmeiError: getLabel("error.required"),
      });
      valid = false;
    }
    if (valid) {
      setIsAdded(true);
      await DataStore.save(
        new LeavesTakaramono({
          collectionmei: collectionData.collectionmei,
          hokanbasyo: collectionData.hokanbasyo,
          bikou: collectionData.bikou,
          leavesusersID: context.leavesUser.id,
        }),
      );
      window.gtag("event", "FirstWriteComplete", {
        event_category: "Edit",
        event_label: "宝物リスト・コレクション書込完了",
      });
      navigate(-1, { replace: true });
    }
  }, [
    collectionData.bikou,
    collectionData.collectionmei,
    collectionData.hokanbasyo,
    collectionDataError,
    context.leavesUser.id,
    navigate,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ListField
      label={getLabel("shinkitsuika")}
      list={listField}
      setState={setCollectionData}
      setUpdate={setUpdate}
      onSave={addData}
      onCancel={() => navigate(-1, { replace: true })}
      isDisabled={isAdded}
    />
  );
}

export default CollectionsAddPage;
