import { API, DataStore } from "aws-amplify";
import { useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import { MODEL_NAME } from "../constants";
import { getLeavesShareInfo } from "../graphql/queries";
import { LeavesSharedInfo } from "../models";
import { CategorySwapper, DefaultButton, LabelM, ListSingleLineTwoColumItem, ListTwoLineBShare } from "../page-components";
import { getLabel, useInitializePage } from "../utils/CommonUtils";
import { getGraphqlQuery, getServerDate } from "../utils/ShareUtils";

function InviteViewPage() {
  const navigate = useInitializePage("invited"); /**TODO: */
  const params = useParams();

  const [noteName, setNoteName] = useState(null);
  const [list, setList] = useState([]);

  const pathMapping = useMemo(() => {
    return {
      LeavesJitsubutsuShisan: "real",
      LeavesKinyuuShisan: "financial",
      LeavesHoken: "insurance",
      LeavesCreditcard: "cards",
      LeavesKouza: "claim",
      LeavesNenkin: "pension",
      LeavesLoankariirekin: "loan",
      LeavesSumahoPc: "devices",
      LeavesLoginId: "loginID",
      LeavesKazokuShinzoku: "family",
      LeavesYuujinChijin: "friends",
      LeavesKenkoukanri: "health",
      LeavesSeikatsunokoto: "life",
      LeavesPet: "pets",
      LeavesDaijinishitaikinenbi: "anniversary",
      LeavesLifeFuture: "bucketlist",
      LeavesTaisetsunishitaihitohenomessage: "message",
      LeavesKaigo: "care",
      LeavesIryousochi: "medical",
      LeavesYuigonshonitsuite: "will",
      LeavesSougi: "funeral",
      LeavesHakanitsuite: "tomb",
      LeavesJibunshi: "history",
      LeavesTakaramono: "collections",
      LeavesKakeizu: "familytree",
    };
  }, []);

  const parentLabel = ["memo", "heart", "lifepast"];

  const getParentID = (category) => {
    const will = [
      pathMapping.LeavesLifeFuture,
      pathMapping.LeavesTaisetsunishitaihitohenomessage,
      pathMapping.LeavesKaigo,
      pathMapping.LeavesIryousochi,
      pathMapping.LeavesYuigonshonitsuite,
      pathMapping.LeavesSougi,
      pathMapping.LeavesHakanitsuite,
    ];

    const history = [pathMapping.LeavesJibunshi, pathMapping.LeavesTakaramono, pathMapping.LeavesKakeizu];
    if (will.includes(pathMapping[category]) > 0) return 2;
    else if (history.includes(pathMapping[category]) > 0) return 3;
    else return 1;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    let mouted = true;
    const getData = async () => {
      const sharedInfo = await DataStore.query(LeavesSharedInfo, (c) => c.id("eq", params.id));
      if (sharedInfo.length > 0) {
        sharedInfo[0]?.name && setNoteName(sharedInfo[0]?.name);
        const shareInfo = await getGraphqlQuery("getLeavesShareInfo", sharedInfo[0].shareInfoId);
        if (shareInfo) {
          const shareTarget = [];
          const now = await getServerDate();
          shareInfo.target.filter(ele=> ele.dataName !== MODEL_NAME.HOSYOUSAIMU && ele.dataName !==  MODEL_NAME.SHUUKAN).map((t) => {
            shareTarget.push({
              id: pathMapping[t.dataName],
              label: getLabel("Datastore." + t.dataName),
              value: `${getLabel("shareDate.suffix")}：` + t.shareDate.replaceAll("-", "/"),
              isPublished: new Date(t.shareDate.replaceAll("-", "/") + " 00:00:00") < now,
              parentId: getParentID(t.dataName),
            });
          });
          const orderForIndexVals = Object.values(pathMapping).slice(0).reverse();
          shareTarget.sort((a, b) => {
            const idxa = -orderForIndexVals.indexOf(a?.id);
            const idxb = -orderForIndexVals.indexOf(b?.id);
            return idxa - idxb;
          });
          if (mouted) setList(shareTarget);
        }
      }
    };
    getData();

    return () => {
      mouted = false;
    };
  }, [params.id, pathMapping]);

  return (
    <div className={isMobile ? "" : "swapper"}>
      <LabelM
        label={noteName}
        isDivide={!isMobile}
        isDefault
        isInvitePage
        buttonAdd={{
          onClick: () => {
            navigate("detail");
          },
          label: getLabel("notonokihonjohokakuninhenko"),
        }}
      />
      {isMobile ? (
        <>
          {list.map((ele) => {
            return (
              <ListTwoLineBShare
                key={ele?.id}
                label={getLabel(ele?.label)}
                value={ele?.value}
                isPublished={ele?.isPublished}
                onClick={() => {
                  navigate(ele?.id);
                }}
              />
            );
          })}
          <DefaultButton
            label={getLabel("notonokihonjohokakuninhenko")}
            onClick={() => {
              navigate("detail");
            }}
          />
        </>
      ) : (
        parentLabel.map((label, idx) => {
          const listItem = list.filter((ele) => ele.parentId === idx + 1);

          return (
            listItem.length > 0 && (
              <CategorySwapper label={getLabel(label)} key={idx}>
                {listItem.map((ele, index) => {
                  return (
                    <ListSingleLineTwoColumItem
                      key={index}
                      label={getLabel(ele?.label)}
                      value={ele?.value}
                      isPublished={ele?.isPublished}
                      onClick={() => {
                        navigate(ele?.id);
                      }}
                    />
                  );
                })}
              </CategorySwapper>
            )
          );
        })
      )}
    </div>
  );
}
export default InviteViewPage;
