import * as React from "react";
import { PrimaryFABtext } from "../ui-components";
import { getLabel } from "../utils/CommonUtils";

export default function FABAchieve(props) {
  const { isDisabled, onClick, ...rest } = props;
  const onClickAdd = isDisabled ? () => {} : onClick;

  return (
    <PrimaryFABtext
      {...rest}
      onClick={onClickAdd}
      width="133px"
      backgroundColor={isDisabled ? "#EFF0F0" : "#688649"}
      overrides={{
        "\u270F\uFE0F Button text": {
          children: getLabel("bucketlist.archive.status"),
          color: isDisabled ? "#5C6670" : "white",
        },
        Icon: {
          width: 20,
          height: 20,
          marginTop: 5,
          paths: [
            {
              d: "M17.1 17.5H19.5V19.5H13.5V13.5H15.5V16.23C17.33 14.76 18.5 12.52 18.5 10C18.5 5.93005 15.44 2.56005 11.5 2.07005V0.0500488C16.55 0.550049 20.5 4.81005 20.5 10C20.5 12.99 19.18 15.67 17.1 17.5ZM2.5 10C2.5 7.48005 3.67 5.23005 5.5 3.77005V6.50005H7.5V0.500049H1.5V2.50005H3.9C1.82 4.33005 0.5 7.01005 0.5 10C0.5 15.19 4.45 19.4501 9.5 19.9501V17.93C5.56 17.4401 2.5 14.07 2.5 10ZM14.74 6.11005L9.08 11.77L6.25 8.94005L4.84 10.35L9.08 14.59L16.15 7.52005L14.74 6.11005Z",
              fill: isDisabled ? "#5C6670" : "white",
              fillRule: "nonzero",
            },
          ],
        },
      }}
    />
  );
}
