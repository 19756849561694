/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Image, Text } from "@aws-amplify/ui-react";
export default function CardMindType(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="22px"
      direction="row"
      width="325px"
      height="120px"
      justifyContent="center"
      alignItems="center"
      position="relative"
      borderRadius="3px"
      padding="0px 0px 0px 26px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "CardMindType")}
    >
      <Flex
        gap="10px"
        direction="row"
        width="fit-content"
        alignItems="flex-start"
        shrink="0"
        height="60px"
        position="relative"
        padding="9px 9px 9px 9px"
        {...getOverrideProps(overrides, "Icon")}
      >
        <Image
          width="42px"
          height="42px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "check_cardIC 1")}
        ></Image>
      </Flex>
      <Flex
        gap="2px"
        direction="column"
        width="217px"
        shrink="0"
        height="120px"
        position="relative"
        padding="25px 0px 30px 0px"
        {...getOverrideProps(overrides, "Text")}
      >
        <Text
          fontFamily="Zen Kaku Gothic New"
          fontSize="24px"
          top="14px"
          fontWeight="500"
          color="rgba(28,26,29,1)"
          lineHeight="33px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="幸福度診断テスト"
          {...getOverrideProps(overrides,"\xe5\xb9\xb8\xe7\xa6\x8f\xe5\xba\xa6\xe8\xa8\xba\xe6\x96\xad\xe3\x83\x86\xe3\x82\xb9")}
        ></Text>
      </Flex>
    </Flex>
  );
}
