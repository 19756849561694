import { Text } from "@aws-amplify/ui-react";
import { isMobile } from "react-device-detect";
export default function (props) {
  let multi = false;
  if (props.value && props.value.indexOf("\n") >= 0) {
    multi = true;
  }
  if (isMobile) {
    return (
      <Text padding="0 16px 0 16px" fontSize="14px" whiteSpace="pre-wrap" {...props} whiteSpace={multi ? "break-spaces" : ""}>
        {props.value}
      </Text>
    );
  } else {
    return (
      <Text
        className="pccomponent"
        fontSize="14px"
        whiteSpace="pre-wrap"
        margin="16px auto 16px auto"
        {...props}
        whiteSpace={multi ? "break-spaces" : ""}
      >
        {props.value}
      </Text>
    );
  }
}
