import { DataStore } from "aws-amplify";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Hokennoumu, LeavesPet } from "../models";
import { ListField } from "../page-components";
import {
  getLabel,
  isEmpty,
  isInvalidDateFormat,
  isNotEmpty,
  isNotEmptyDate,
  useInitializePage,
} from "../utils/CommonUtils";
import { usePrompt } from "../utils/Prompt";

function PetEditPage() {
  const navigate = useInitializePage("petnitsuite", "title.update");
  const [hokennoumuList, setHokennoumuList] = useState();
  const [data, setData] = useState();
  const [dataPet, setDataPet] = useState({
    namae: "",
    shurui: "",
    seinengappi: null,
    seibetsu: "",
    tourokubangou: "",
    hokennoumu: "",
    hokennkaisyamei: "",
    kakaritsukenobyouinmei: "",
    kakaritsukenobyouinnorenrakusaki: "",
    bikou: "",
  });
  const [dataPetError, setDataPetError] = useState({
    namaeError: "",
    shuruiError: "",
    seinengappiError: "",
    seibetsuError: "",
    tourokubangouError: "",
    hokennoumuError: "",
    hokennkaisyameiError: "",
    kakaritsukenobyouinmeiError: "",
    kakaritsukenobyouinnorenrakusakiError: "",
    bikouError: "",
  });
  const [isUpdated, setIsUpdated] = useState(false);
  let params = useParams();
  const [update, setUpdate] = useState(false);
  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  useEffect(() => {
    const getData = async () => {
      const data = await DataStore.query(LeavesPet, (c) =>
        c.id("eq", params.id),
      );
      if (data?.length > 0) {
        setData(data[0]);
        setDataPet({
          namae: data[0].namae,
          shurui: data[0].shurui,
          seinengappi: data[0].seinengappi,
          seibetsu: data[0].seibetsu,
          tourokubangou: data[0].tourokubangou,
          hokennoumu: data[0].hokennoumu,
          hokennkaisyamei: data[0].hokennkaisyamei,
          kakaritsukenobyouinmei: data[0].kakaritsukenobyouinmei,
          kakaritsukenobyouinnorenrakusaki:
            data[0].kakaritsukenobyouinnorenrakusaki,
          bikou: data[0].bikou,
        });
      }
    };
    getData();
  }, [params.id]);

  const listField = [
    {
      type: "textField",
      label: "namae",
      value: dataPet?.namae,
      isRequire: true,
      hasError: isNotEmpty(dataPetError.namaeError),
      errorMessage: dataPetError.namaeError,
    },
    {
      type: "textField",
      label: "shurui",
      value: dataPet?.shurui,
      placeHolder: "sibakennado",
      hasError: isNotEmpty(dataPetError.shuruiError),
      errorMessage: dataPetError.shuruiError,
    },
    {
      type: "textField",
      typeInput: "date",
      label: "seinengappi",
      value: dataPet?.seinengappi,
      hasError: isNotEmpty(dataPetError.seinengappiError),
      errorMessage: dataPetError.seinengappiError,
    },
    { type: "textField", label: "seibetsu", value: dataPet?.seibetsu },
    {
      type: "textField",
      label: "tourokubangou",
      value: dataPet?.tourokubangou,
    },
    {
      type: "selectField",
      label: "hokennoumu",
      options: hokennoumuList,
      value: dataPet?.hokennoumu,
      isSelect: true,
    },
    {
      type: "textField",
      label: "hokennkaisyamei",
      value: dataPet?.hokennkaisyamei,
    },
    {
      type: "textField",
      label: "kakaritsukenobyouinmei",
      value: dataPet?.kakaritsukenobyouinmei,
    },
    {
      type: "textArea",
      label: "kakaritsukenobyouinnorenrakusaki",
      placeHolder: "denwabangouyasiteurlnado",
      value: dataPet?.kakaritsukenobyouinnorenrakusaki,
    },
    {
      type: "textArea",
      label: "bikou",
      value: dataPet?.bikou,
    },
  ];

  const updateDataPet = useCallback(async () => {
    let valid = true;
    const dateFormatCheck = isInvalidDateFormat(dataPet.seinengappi);
    if (isEmpty(dataPet.namae) || dateFormatCheck) {
      setDataPetError({
        ...dataPetError,
        namaeError: isEmpty(dataPet.namae) ? getLabel("error.required") : "",
        seinengappiError: dateFormatCheck ? getLabel("error.dateformat") : "",
      });
      valid = false;
    }
    if (valid) {
      setIsUpdated(true);
      await DataStore.save(
        LeavesPet.copyOf(data, (updated) => {
          updated.namae = dataPet.namae;
          updated.shurui = dataPet.shurui;
          updated.seinengappi = isNotEmptyDate(dataPet.seinengappi)
            ? dataPet.seinengappi
            : null;
          updated.seibetsu = dataPet.seibetsu;
          updated.tourokubangou = dataPet.tourokubangou;
          updated.hokennoumu = isNotEmpty(dataPet.hokennoumu)
            ? dataPet.hokennoumu
            : null;
          updated.hokennkaisyamei = dataPet.hokennkaisyamei;
          updated.kakaritsukenobyouinmei = dataPet.kakaritsukenobyouinmei;
          updated.kakaritsukenobyouinnorenrakusaki =
            dataPet.kakaritsukenobyouinnorenrakusaki;
          updated.bikou = dataPet.bikou;
        }),
      );
      window.gtag("event", "ChangeComplete", {
        event_category: "Edit",
        event_label: "ペットについて変更完了",
      });
      navigate(-1, { replace: true });
    }
  }, [
    data,
    dataPet.bikou,
    dataPet.hokennkaisyamei,
    dataPet.hokennoumu,
    dataPet.kakaritsukenobyouinmei,
    dataPet.kakaritsukenobyouinnorenrakusaki,
    dataPet.namae,
    dataPet.seibetsu,
    dataPet.seinengappi,
    dataPet.shurui,
    dataPet.tourokubangou,
    dataPetError,
    navigate,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const listOption = [];
    listOption.push(
      <option key="default" value="">
        {getLabel("list.select")}
      </option>,
    );
    Object.keys(Hokennoumu).forEach((type) => {
      listOption.push(
        <option key={type} value={type}>
          {getLabel("Hokennoumu." + type)}
        </option>,
      );
    });
    setHokennoumuList(listOption);
  }, []);

  return (
    <ListField
      label={getLabel("button.update")}
      list={listField}
      setState={setDataPet}
      setUpdate={setUpdate}
      onSave={updateDataPet}
      onCancel={() => navigate(-1, { replace: true })}
      isDisabled={isUpdated}
    />
  );
}

export default PetEditPage;
