import data from "../assets/image/data_cardIC.png";
import history from "../assets/image/history_cardIC.png";
import will from "../assets/image/will_cardIC.png";
import { CardData } from "../ui-components";

export default function Card(props) {
  const {
    type = "",
    title = "",
    titleEn = "",
    mainDescription = "",
    subDescription = "",
  } = props;

  const generateIcon = () => {
    if (type === "data") return data;
    else if (type === "will") return will;
    else return history;
  };

  return (
    <div style={{ padding: "0 16px", width: "100%" }}>
      <CardData
        boxShadow="none"
        height="auto"
        width="100%"
        overrides={{
          BackImage: {
            width: "100%",
            height: "auto",
          },
          Content: {
            width: "100%",
            height: "auto",
            position: "relative",
            padding: "24px 20px",
          },
          Line: {
            height: "auto",
          },
          "data_cardIC 1": {
            src: generateIcon(),
          },
          "foward_circle 1": {
            display: "none",
          },
          "\u79C1\u306E\u30C7\u30FC\u30BF": {
            children: title,
          },
          "MY DATA": {
            children: titleEn,
          },
          "\u91D1\u878D\u8CC7\u7523\u3001\u4FDD\u967A\u3001\u53CB\u4EBA\u306E\u9023\u7D61\u5148\u3001\u30B9\u30DE\u30DB\u3084PC\u306E\u60C5\u5831\u3001\u30DA\u30C3\u30C8\u306E\u3053\u3068\u306A\u3069\u3092\u6574\u7406\u3057\u3066\u304A\u304F\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002\u201C\u3082\u3057\u3082\u306E\u6642\u201D\u306B\u81EA\u5206\u81EA\u8EAB\u3084\u5BB6\u65CF\u304C\u3068\u3066\u3082\u52A9\u304B\u308A\u307E\u3059\u3002":
            {
              children: mainDescription + "\n" + subDescription,
              textAlign: "justify",
            },
        }}
      />
    </div>
  );
}
