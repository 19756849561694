import { DefaultButton } from "../ui-components";
export default function (props) {
  return (
    <DefaultButton
      className="pointer"
      width="fit-content"
      margin="16px auto"
      onClick={props.onClick}
      overrides={{
        "Flex.Button[0]": {
          ...props,
          children: props.label,
          disabled: props.disabled ? props.disabled : false,
        },
      }}
      {...props}
    />
  );
}
