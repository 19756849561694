import { DataStore } from "aws-amplify";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { emailRegex, phoneRegex } from "../constants";
import { LeavesYuujinChijin } from "../models";
import { ListField } from "../page-components";
import UserContext from "../UserContext";
import {
  getLabel,
  isEmpty,
  isInvalidDateFormat,
  isNotEmpty,
  isNotEmptyDate,
  useInitializePage,
} from "../utils/CommonUtils";
import { usePrompt } from "../utils/Prompt";

function FriendsAddPage() {
  const navigate = useInitializePage("yuujinchijin", "title.add");
  const context = useContext(UserContext);
  const [update, setUpdate] = useState(false);
  const [friendData, setFriendData] = useState({
    shimei: "",
    kankeisei: "",
    seinengappi: "",
    juusho: "",
    denwabangou: "",
    mailaddress: "",
    bikou: "",
  });
  const [friendDataError, setFriendDataError] = useState({
    shimeiError: "",
    kankeiseiError: "",
    seinengappiError: "",
    juushoError: "",
    denwabangouError: "",
    mailaddressError: "",
    bikouError: "",
  });
  const [isAdded, setIsAdded] = useState(false);
  const listField = [
    {
      type: "textField",
      label: "shimei",
      isRequire: true,
      errorMessage: friendDataError.shimeiError,
    },
    {
      type: "textField",
      placeHolder: "koukounodoukyuuseinado",
      label: "kankeisei",
    },
    {
      type: "textField",
      label: "seinengappi",
      typeInput: "date",
      errorMessage: friendDataError.seinengappiError,
    },
    {
      type: "textField",
      label: "juusho",
    },
    {
      type: "textField",
      label: "denwabangou",
      typeInput: "tel",
      errorMessage: friendDataError.denwabangouError,
    },
    {
      type: "textField",
      label: "mailaddress",
      typeInput: "email",
      placeHolder: "nyuuryokumiss",
      errorMessage: friendDataError.mailaddressError,
    },
    {
      type: "textArea",
      label: "bikou",
      placeHolder: "kazokugashiranaiepisode",
    },
  ];
  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  const addData = useCallback(async () => {
    let valid = true;
    const inValidPhone =
      !phoneRegex.test(friendData.denwabangou) &&
      isNotEmpty(friendData.denwabangou);
    const dateFormatCheck = isInvalidDateFormat(friendData.seinengappi);
    const checkEmail =
      isNotEmpty(friendData.mailaddress) &&
      !emailRegex.test(friendData.mailaddress);
    if (
      isEmpty(friendData.shimei) ||
      dateFormatCheck ||
      inValidPhone ||
      checkEmail
    ) {
      setFriendDataError({
        ...friendDataError,
        shimeiError: isEmpty(friendData.shimei)
          ? getLabel("error.required")
          : "",
        seinengappiError: dateFormatCheck ? getLabel("error.dateformat") : "",
        denwabangouError: inValidPhone ? getLabel("error.telno") : "",
        mailaddressError: checkEmail ? getLabel("error.emailformat") : "",
      });
      valid = false;
    }
    if (valid) {
      setIsAdded(true);
      await DataStore.save(
        new LeavesYuujinChijin({
          shimei: friendData.shimei,
          kankeisei: friendData.kankeisei,
          seinengappi: isNotEmptyDate(friendData.seinengappi)
            ? friendData.seinengappi
            : null,
          juusho: friendData.juusho,
          denwabangou: friendData.denwabangou,
          mailaddress: friendData.mailaddress,
          bikou: friendData.bikou,
          leavesusersID: context.leavesUser.id,
        }),
      );
      window.gtag("event", "FirstWriteComplete", {
        event_category: "Edit",
        event_label: "友人・知人書込完了",
      });
      navigate(-1, { replace: true });
    }
  }, [
    context.leavesUser.id,
    friendData.bikou,
    friendData.denwabangou,
    friendData.juusho,
    friendData.kankeisei,
    friendData.mailaddress,
    friendData.seinengappi,
    friendData.shimei,
    friendDataError,
    navigate,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ListField
      label={getLabel("shinkitsuika")}
      list={listField}
      setState={setFriendData}
      setUpdate={setUpdate}
      onSave={addData}
      onCancel={() => navigate(-1, { replace: true })}
      isDisabled={isAdded}
    />
  );
}

export default FriendsAddPage;
