import { Flex } from "@aws-amplify/ui-react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { FABEditShare } from ".";
import { withNonScrollBody } from "../hooks";
import { getLabel } from "../utils/CommonUtils";
import Card from "./Card";
import Description from "./Description";
import LabelM from "./LabelM";
import ListSingleLineCEdit from "./ListSingleLineCEdit";

function ListView(props) {
  const {
    isEmptyCategory,
    isLoaded,
    description,
    listPC,
    listMobile,
    onEdit,
    label,
    overrideStylePC,
    isHideButtonEdit,
    isShared,
  } = props;
  const { category, subDescription, type } = description;
  const navigate = useNavigate();

  return isMobile ? (
    <>
      <LabelM label={label} />
      {isEmptyCategory
        ? isLoaded &&
          (isShared ? (
            <Description text={getLabel("shared.des")} />
          ) : (
            <Flex marginBottom="32px" w="100%">
              <Card
                type={type}
                title={getLabel(`${category}`)}
                titleEn={getLabel(`${category}.en`)}
                mainDescription={
                  isShared
                    ? getLabel(`shared.des`)
                    : getLabel(`${category}.des`)
                }
                subDescription={subDescription}
              />
            </Flex>
          ))
        : isLoaded && listMobile}
      {!isHideButtonEdit && !isShared && (
        <FABEditShare
          onShare={() => {
            navigate(`share`);
          }}
          onEdit={onEdit}
        />
      )}
    </>
  ) : (
    <div className="swapper">
      <LabelM label={label} isDivide isShareBtn={!isShared && !isHideButtonEdit} isNonList/>
      {isEmptyCategory && isLoaded && (
        <Description
          text={isShared ? getLabel("shared.des") : getLabel(`${category}.des`)}
          paddingBottom="0"
        />
      )}
      <div
        className={`hide-scroll content-swapper ${
          isEmptyCategory && "min-height-0"
        }`}
        style={{
          overflow: "auto",
          maxHeight: "calc(100vh - 411px)",
          ...overrideStylePC,
        }}
      >
        <ListSingleLineCEdit
          list={!isEmptyCategory && isLoaded && listPC}
          editOnClick={onEdit}
          isHideEdit={isHideButtonEdit || isShared}
        />
      </div>
    </div>
  );
}

export default withNonScrollBody(ListView);
