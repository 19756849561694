import { DataStore } from "aws-amplify";
import { useCallback, useEffect, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useParams } from "react-router-dom";
import { LeavesNenkin } from "../models";
import { ListViewDetail } from "../page-components";
import { formatNumber, useInitializePage } from "../utils/CommonUtils";
import { isShare, queryData } from "../utils/ShareUtils";

function PensionViewPage() {
  const navigate = useInitializePage("nenkin", "title.detail");
  const [pensionData, setPensionData] = useState({
    nenkinmei: "",
    jyukyuugaku: "",
    nenkinshurui: "",
    bikou: "",
  });
  const params = useParams();
  const deleteData = useCallback(async () => {
    await DataStore.delete(pensionData);
    navigate(-1, { replace: true });
  }, [pensionData, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const data = await queryData(params, LeavesNenkin);
      if (data?.length > 0) {
        setPensionData(data[0]);
      }
    };
    getData();
  }, [params.id]);

  const listLabel = [
    {
      label: "nenkinmei",
      value: pensionData?.nenkinmei,
      isRequire: true,
    },
    {
      label: "jyukyuugaku",
      value:
        formatNumber(pensionData?.jyukyuugaku) &&
        formatNumber(pensionData?.jyukyuugaku) + "円", //** TODO: Using getLable when 円 is defined in src\assets\amplify\vocabularies\index.js*/
    },
    {
      label: "nenkinshurui",
      value: pensionData?.nenkinshurui,
    },
    { label: "bikou", value: pensionData?.bikou, isMulti: true },
  ];

  return (
    <ListViewDetail
      label={pensionData?.nenkinmei}
      list={listLabel}
      isHideEdit={isShare(params)}
      onDelete={deleteData}
      onEdit={() => {
        navigate("edit");
        window.gtag("event", "Change", {
          event_category: "Edit",
          event_label: "年金変更開始",
        });
      }}
    />
  );
}

export default PensionViewPage;
