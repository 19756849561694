import { Image, Text } from "@aws-amplify/ui-react";
import React from "react";
import DefaultButton from "./DefaultButton";
import memo from "../assets/image/memo.png";
import { useNavigate } from "react-router-dom";
import { getLabel } from "../utils/CommonUtils";

export default function ButtonMemo(props) {
  const { ...rest } = props;
  const navigate = useNavigate();

  return (
    <DefaultButton
      height="auto"
      margin="0"
      onClick={() => {
        navigate("/memo");
      }}
      overrides={{
        "Flex.Button[0]": {
          width: "fit-content",
          height: "auto",
          margin: "0",
          padding: "8px 16px 8px 12px",
          boxShadow: "none",
          children: (
            <>
              <Image src={memo} width="22px" />
              <Text
                padding="0 0 0 5px"
                fontSize="16px"
                maxWidth="250px"
                width="100%"
                display="block"
                overflow="hidden"
                style={{
                  textOverflow: "ellipsis",
                }}
              >
                {getLabel("jiyucho")}
              </Text>
            </>
          ),
        },
      }}
      {...rest}
    />
  );
}
