/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Image, Text } from "@aws-amplify/ui-react";
export default function CardDo(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="22px"
      direction="row"
      width="325px"
      height="120px"
      justifyContent="center"
      alignItems="center"
      position="relative"
      borderRadius="3px"
      padding="0px 0px 0px 26px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "CardDo")}
    >
      <Flex
        gap="10px"
        direction="row"
        width="fit-content"
        alignItems="flex-start"
        shrink="0"
        height="60px"
        position="relative"
        padding="9px 9px 9px 9px"
        {...getOverrideProps(overrides, "Icon")}
      >
        <Image
          width="42px"
          height="42px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "do_cardIC")}
        ></Image>
      </Flex>
      <Flex
        gap="0"
        direction="column"
        width="217px"
        grow="1"
        basis="217px"
        height="120px"
        position="relative"
        padding="22px 0px 28px 0px"
        {...getOverrideProps(overrides, "Text")}
      >
        <Text
          fontFamily="Zen Kaku Gothic New"
          fontSize="15px"
          fontWeight="500"
          color="rgba(28,26,29,1)"
          lineHeight="30px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="人生でやりたい"
          {...getOverrideProps(
            overrides,
            "\u6B7B\u306C\u307E\u3067\u306B\u3084\u308A\u305F\u3044"
          )}
        ></Text>
        <Flex
          gap="4px"
          direction="row"
          height="40px"
          alignItems="flex-end"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "TextBold")}
        >
          <Text
            fontFamily="Zen Kaku Gothic New"
            fontSize="43px"
            fontWeight="500"
            color="rgba(28,26,29,1)"
            lineHeight="55px"
            textAlign="left"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            width="39px"
            height="51px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="10"
            {...getOverrideProps(overrides, "10")}
          ></Text>
          <Text
            fontFamily="Zen Kaku Gothic New"
            fontSize="25px"
            fontWeight="500"
            color="rgba(28,26,29,1)"
            lineHeight="33px"
            textAlign="left"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="のこと"
            {...getOverrideProps(overrides, "\u306E\u3053\u3068")}
          ></Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
