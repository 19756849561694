/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Image, Text, View } from "@aws-amplify/ui-react";
export default function CardMemory(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="325px"
      height="300px"
      position="relative"
      boxShadow="2px 2px 6px rgba(0.30416667461395264, 0.3028993010520935, 0.3028993010520935, 0.20000000298023224)"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "CardMemory")}
    >
      <Flex
        gap="10px"
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        direction="row"
        width="325px"
        height="300px"
        alignItems="flex-start"
        borderRadius="3px"
        padding="0px 20px 20px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "BackImage")}
      >
        <Image
          width="70px"
          height="70px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "history_cardIC 1")}
        ></Image>
        <Flex
          gap="10px"
          direction="row"
          height="280px"
          justifyContent="flex-end"
          alignItems="flex-end"
          grow="1"
          basis="225px"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Line")}
        >
          <Image
            width="25px"
            height="25px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "foward_circle 1")}
          ></Image>
        </Flex>
      </Flex>
      <Flex
        gap="25px"
        position="absolute"
        top="calc(50% - 150px - 0px)"
        left="calc(50% - 162.5px - 0px)"
        direction="column"
        width="325px"
        height="300px"
        alignItems="center"
        padding="24px 20px 20px 20px"
        {...getOverrideProps(overrides, "Content")}
      >
        <Flex
          gap="21px"
          direction="column"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Text")}
        >
          <Flex
            gap="0"
            direction="column"
            width="285px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Title")}
          >
            <Text
              fontFamily="Zen Kaku Gothic New"
              fontSize="25px"
              fontWeight="500"
              color="rgba(28,26,29,1)"
              lineHeight="33px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              shrink="0"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="大切なもの"
              {...getOverrideProps(overrides, "\u5927\u5207\u306A\u3082\u306E")}
            ></Text>
            <Text
              fontFamily="Zen Kaku Gothic New"
              fontSize="15px"
              fontWeight="500"
              color="rgba(28,26,29,1)"
              lineHeight="30px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              shrink="0"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="HISTORY"
              {...getOverrideProps(overrides, "HISTORY")}
            ></Text>
          </Flex>
          <Text
            fontFamily="Zen Kaku Gothic New"
            fontSize="15px"
            fontWeight="500"
            color="rgba(28,26,29,1)"
            lineHeight="30px"
            textAlign="left"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="自分史や親族、宝物リストなど、大切なものごとを記録することができます。これまでの人生を振り返り、これから何を大切にして生きていきたいか考えるきっかけに。"
            {...getOverrideProps(
              overrides,
              "\u81EA\u5206\u53F2\u3084\u89AA\u65CF\u3001\u5B9D\u7269\u30EA\u30B9\u30C8\u306A\u3069\u3001\u5927\u5207\u306A\u3082\u306E\u3054\u3068\u3092\u8A18\u9332\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002\u3053\u308C\u307E\u3067\u306E\u4EBA\u751F\u3092\u632F\u308A\u8FD4\u308A\u3001\u3053\u308C\u304B\u3089\u4F55\u3092\u5927\u5207\u306B\u3057\u3066\u751F\u304D\u3066\u3044\u304D\u305F\u3044\u304B\u8003\u3048\u308B\u304D\u3063\u304B\u3051\u306B\u3002"
            )}
          ></Text>
        </Flex>
      </Flex>
    </View>
  );
}
