import React from "react";
import { isMobile } from "react-device-detect";
import { useViewPort } from "../hooks";
import { FABMail, PCFABMail } from "../ui-components";
import { isEnableVideoCall } from "../utils/CommonUtils";

export default function MessageButton(props) {
  const width = useViewPort();
  const breakPointPC = 1418;

  const handleOpenWindow = () => {
    props?.onClick();
    window.open(process.env.REACT_APP_URL_CONSULT_EXPERT);
  };

  return (
    <>
      {isMobile ? (
        <FABMail
          className="pointer"
          position="fixed"
          justifyContent="flex-end"
          bottom="210px"
          onClick={handleOpenWindow}
          right="16px"
          style={{ zIndex: 10, transition: " 0.4s" }}
          overrides={{
            Icon: {
              paths: [
                {
                  d: "M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6ZM20 6L12 10.99L4 6H20ZM20 18H4V8L12 13L20 8V18Z",
                  fill: "#1C1A1D",
                  fillRule: "nonzero",
                },
              ],
            },
          }}
        />
      ) : (
        <PCFABMail
          className="pointer"
          position="fixed"
          width="fit-content"
          bottom={isEnableVideoCall ? "180px" : "96px"}
          right={width < breakPointPC ? "0" : "calc(100vw/15)"}
          style={{ zIndex: 10, transition: " 0.4s" }}
          onClick={handleOpenWindow}
          overrides={{
            Icon: {
              paths: [
                {
                  d: "M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6ZM20 6L12 10.99L4 6H20ZM20 18H4V8L12 13L20 8V18Z",
                  fill: "#1C1A1D",
                  fillRule: "nonzero",
                },
              ],
            },
          }}
        />
      )}
    </>
  );
}
