/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import TextFieldA from "./TextFieldA";
import { Flex } from "@aws-amplify/ui-react";
export default function PCTextField(props) {
  const { overrides: overridesProp, ...rest } = props;
  const overrides = { ...overridesProp };
  return (
    <Flex
      gap="4px"
      direction="column"
      width="1040px"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "Flex")}
    >
      <TextFieldA
        padding="0px 0px 0px 0px"
        display="flex"
        shrink="0"
        alignSelf="stretch"
        objectFit="cover"
        {...getOverrideProps(overrides, "Flex.TextFieldA[0]")}
      ></TextFieldA>
    </Flex>
  );
}
