/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Divider, Flex, Image, Text } from "@aws-amplify/ui-react";
export default function PCNav(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="0"
      direction="column"
      width="1440px"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "PCNav")}
    >
      <Flex
        gap="10px"
        direction="column"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        objectFit="cover"
        position="relative"
        padding="14px 48px 14px 48px"
        backgroundColor="rgba(251,249,246,1)"
        {...getOverrideProps(overrides, "Nav")}
      >
        <Flex
          gap="0"
          direction="row"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Content")}
        >
          <Flex
            gap="35px"
            direction="row"
            width="1309px"
            justifyContent="center"
            alignItems="center"
            grow="1"
            basis="1309px"
            height="31px"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Body")}
          >
            <Flex
              gap="7px"
              direction="row"
              width="fit-content"
              alignItems="center"
              shrink="0"
              height="31px"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "NvData")}
            >
              <Image
                width="28px"
                height="28px"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Data")}
              ></Image>
              <Text
                fontFamily="Zen Kaku Gothic New"
                fontSize="17px"
                fontWeight="500"
                color="rgba(0,0,0,1)"
                lineHeight="31px"
                textAlign="center"
                display="flex"
                direction="column"
                justifyContent="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="私のデータ"
                {...getOverrideProps(
                  overrides,
                  "\u79C1\u306E\u30C7\u30FC\u30BF"
                )}
              ></Text>
            </Flex>
            <Flex
              gap="7px"
              direction="row"
              width="fit-content"
              alignItems="center"
              shrink="0"
              height="31px"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "NvWill")}
            >
              <Image
                width="28px"
                height="28px"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Will")}
              ></Image>
              <Text
                fontFamily="Zen Kaku Gothic New"
                fontSize="17px"
                fontWeight="500"
                color="rgba(0,0,0,1)"
                lineHeight="31px"
                textAlign="center"
                display="flex"
                direction="column"
                justifyContent="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="私の意思"
                {...getOverrideProps(overrides, "\u79C1\u306E\u610F\u601D")}
              ></Text>
            </Flex>
            <Flex
              gap="7px"
              direction="row"
              width="fit-content"
              alignItems="center"
              shrink="0"
              height="31px"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "NvHistory")}
            >
              <Image
                width="28px"
                height="28px"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "History")}
              ></Image>
              <Text
                fontFamily="Zen Kaku Gothic New"
                fontSize="17px"
                fontWeight="500"
                color="rgba(0,0,0,1)"
                lineHeight="31px"
                textAlign="center"
                display="flex"
                direction="column"
                justifyContent="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="大切なもの"
                {...getOverrideProps(
                  overrides,
                  "\u5927\u5207\u306A\u3082\u306E"
                )}
              ></Text>
            </Flex>
            <Flex
              gap="0"
              direction="row"
              width="fit-content"
              alignItems="flex-start"
              shrink="0"
              height="31px"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "NvHintBadge")}
            >
              <Flex
                gap="7px"
                direction="row"
                width="fit-content"
                alignItems="center"
                shrink="0"
                height="31px"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "NvHint")}
              >
                <Image
                  width="28px"
                  height="28px"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Hint")}
                ></Image>
                <Text
                  fontFamily="Zen Kaku Gothic New"
                  fontSize="17px"
                  fontWeight="500"
                  color="rgba(0,0,0,1)"
                  lineHeight="31px"
                  textAlign="center"
                  display="flex"
                  direction="column"
                  justifyContent="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="ヒント"
                  {...getOverrideProps(overrides, "\u30D2\u30F3\u30C8")}
                ></Text>
              </Flex>
              <Image
                width="13px"
                height="13px"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "One_badge")}
              ></Image>
            </Flex>
            <Flex
              gap="7px"
              direction="row"
              width="fit-content"
              alignItems="center"
              shrink="0"
              height="31px"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "NvHowto")}
            >
              <Image
                width="28px"
                height="28px"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Howto")}
              ></Image>
              <Text
                fontFamily="Zen Kaku Gothic New"
                fontSize="17px"
                fontWeight="500"
                color="rgba(0,0,0,1)"
                lineHeight="31px"
                textAlign="center"
                display="flex"
                direction="column"
                justifyContent="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="使い方"
                {...getOverrideProps(overrides, "\u4F7F\u3044\u65B9")}
              ></Text>
            </Flex>
          </Flex>
          <Image
            width="35px"
            height="35px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "HamburgerMenu")}
          ></Image>
        </Flex>
      </Flex>
      <Divider
        height="1px"
        shrink="0"
        alignSelf="stretch"
        objectFit="cover"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(232,232,232,1)"
        size="small"
        orientation="horizontal"
        {...getOverrideProps(overrides, "Divider")}
      ></Divider>
    </Flex>
  );
}
