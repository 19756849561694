import { Button, Loader } from "@aws-amplify/ui-react";
import React, { useState } from "react";
import { listGraphqlQuery } from "../utils/ShareUtils";
import ExcelJS from "exceljs";
import { schema } from "../models/schema";
import { formatDate, getLabel } from "../utils/CommonUtils";

const categoryMap = {
  jitsubutsushisan: {
    label: "LeavesJitsubutsuShisan",
    restrictFeild: ["shozaibasho"],
  },
  kinyuushisan: { label: "LeavesKinyuuShisan" },
  hoken: { label: "LeavesHoken" },
  creditcard: { label: "LeavesCreditcard" },
  kouzajidouhikiotoshi: { label: "LeavesKouza" },
  nenkin: { label: "LeavesNenkin" },
  loankariirekin: {
    label: "LeavesLoankariirekin",
    restrictFeild: [
      "kariiresaki",
      "kariiresakinorenrakusaki",
      "kariirebi",
      "kariiregaku",
      "hensaihouhou",
      "tanponoumu",
      "kariirezandaka",
      "kariirezandakakisaibi",
      "kariiremokuteki",
      "bikou",
    ],
  },
  hosyousaimu: {
    label: "LeavesHosyousaimu",
    restrictFeild: [
      "syusaimusya",
      "syusaimusyanorenrakusaki",
      "hosyoushitahi",
      "hosyoushitakingaku",
      "saikensya",
      "saikensyanorenrakusaki",
      "bikou",
    ],
  },
  sumahoyapcnitsuite: {
    label: "LeavesSumahoPc",
    restrictFeild: ["denwabangou", "meiginin"],
  },
  kakushuserviceid: { label: "LeavesLoginId", restrictFeild: ["loginid"] },
  kazokushinzoku: {
    label: "LeavesKazokuShinzoku",
    restrictFeild: [
      "shimei",
      "juusho",
      "denwabangou",
      "mailaddress",
      "yuubinbangou",
    ],
  },
  yuujinchijin: {
    label: "LeavesYuujinChijin",
    restrictFeild: ["shimei", "juusho", "denwabangou", "mailaddress"],
  },
  kenkoukanrinitsuite: {
    label: "LeavesKenkoukanri",
    restrictFeild: [
      "byoumei",
      "hukuyouchuunokusuri",
      "youto",
      "youhou",
      "byouinmei",
      "chiryoukikan",
      "bikou",
    ],
  },
  seikatsunokotonitsuite: {
    label: "LeavesSeikatsunokoto",
    restrictFeild: ["seikatsunokotonitsuite"],
  },
  petnitsuite: {
    label: "LeavesPet",
    restrictFeild: [
      "tourokubangou",
      "kakaritsukenobyouinmei",
      "kakaritsukenobyouinnorenrakusaki",
    ],
  },
  daijinishitaikinenbi: {
    label: "LeavesDaijinishitaikinenbi",
    restrictFeild: ["hiduke"],
  },
  yaritaikoto: { label: "LeavesLifeFuture" },
  taisetsunishitaihitohenomessage: {
    label: "LeavesTaisetsunishitaihitohenomessage",
    restrictFeild: ["messagetitle", "messagenaiyou"],
  },
  kaigonitsuite: {
    label: "LeavesKaigo",
    restrictFeild: ["handanwoyudanetaihito", "zaisankanriwomakaseruhito"],
  },
  iryousochinitsuite: {
    label: "LeavesIryousochi",
    restrictFeild: ["handanwoyudanetaihito"],
  },
  yuigonshonitsuite: {
    label: "LeavesYuigonshonitsuite",
    restrictFeild: [
      "yuigonshikkousha",
      "yuigonshikkoushanorenrakusaki",
      "iraisoudanwoshiteirusenmonka",
      "iraisoudanwoshiteirusenmonkanorenrakusaki",
    ],
  },
  souginitsuite: {
    label: "LeavesSougi",
    restrictFeild: [
      "yondehoshiihitonituite",
      "itininsuruhitonituite",
      "souginoannaiwoshinakutemoyoihitonituite",
    ],
  },
  hakanitsuite: { label: "LeavesHakanitsuite" },
  jibunshi: { label: "LeavesJibunshi", restrictFeild: ["nengetsu", "episode"] },
  takaramonolistcollection: { label: "LeavesTakaramono" },
  kakeizu: { label: "LeavesKakeizu" },
};

export default function RawDataButton(props) {
  const [isLoading, setIsLoading] = useState(false);

  const handleDowload = async () => {
    setIsLoading(true);
    const listLabelValue = Object.values(categoryMap).map((ele) => ele.label);
    const listKey = Object.keys(categoryMap);
    const workbook = new ExcelJS.Workbook();
    const listPromise = [];
    for (let i = 0; i < listLabelValue.length; i++) {
      const promise = new Promise((resolve) => {
        listGraphqlQuery("list" + listLabelValue[i] + "s").then((res) => {
          if (res.length > 0) resolve(res);
        });
      });
      listPromise.push(promise);
    }
    const listDataRequest = await Promise.all(listPromise);

    for (let i = 0; i < listDataRequest?.length; i++) {
      const data = listDataRequest[i];
      const listRestrict = categoryMap[listKey[i]]?.restrictFeild;
      const modelName = categoryMap[listKey[i]]?.label;
      if (data.length > 0) {
        const listFieldModel = Object.keys(
          schema?.models[listLabelValue[i]].fields,
        );
        let column = listFieldModel.filter(
          (ele) => ele !== "leavesusersID" && ele !== "id",
        );
        /**Swap leavesusersID vs ID column */
        column.push("id");
        if (listFieldModel.includes("leavesusersID")) {
          column = ["leavesusersID"].concat(column);
        }
        if (modelName === "LeavesKazokuShinzoku") {
          column = column.filter(
            (ele) => ele !== "nyuuinjirenraku" && ele !== "sougijirenraku",
          );
        }

        if (modelName === "LeavesYuujinChijin") {
          column = column.filter(
            (ele) => ele !== "nyuuinjinorenraku" && ele !== "sougijinorenraku",
          );
        }

        if (modelName === "LeavesLifeFuture") {
          column = column.filter((ele) => ele !== "category");
        }

        if (modelName === "LeavesKakeizu") {
          column = column.filter((ele) => ele !== "s3key");
        }

        workbook.addWorksheet(getLabel(listKey[i]));
        const worksheet = workbook.getWorksheet(getLabel(listKey[i]));

        worksheet.columns = column?.map((ele) => ({
          header: getLabel(ele),
          key: ele,
          width: 40,
        }));

        data.forEach((u, idx) => {
          const row = {};
          const rowNum = idx + 1;
          for (let k = 0; k < column.length; k++) {
            const isSecret =
              listRestrict &&
              u[column[k]] &&
              listRestrict.findIndex((item) => item === column[k]) >= 0;
            if (isSecret) row[column[k]] = "***";
            else if (
              (column[k] === "shisanshurui" || column[k] === "_shisanshurui") &&
              modelName === "LeavesJitsubutsuShisan"
            ) {
              row[column[k]] = u[column[k]]
                ? getLabel("JitsubutsuShisanshurui." + u[column[k]])
                : "";
            } else if (
              column[k] === "shisanshurui" &&
              modelName === "LeavesKinyuuShisan"
            ) {
              row[column[k]] = u[column[k]]
                ? getLabel("KinyuuShisanshurui." + u[column[k]])
                : "";
            } else if (
              column[k] === "hokennoumu" &&
              modelName === "LeavesPet"
            ) {
              row[column[k]] = u[column[k]]
                ? getLabel("Hokennoumu." + u[column[k]])
                : "";
            } else if (
              column[k] === "tasseijoukyoushurui" &&
              modelName === "LeavesLifeFuture"
            ) {
              row[column[k]] = u[column[k]]
                ? getLabel("Tasseijoukyoushurui." + u[column[k]])
                : "";
            } else if (
              column[k] === "zaisankanriwomakaseruhitonokeiyakujoukyou" &&
              modelName === "LeavesKaigo"
            ) {
              row[column[k]] = u[column[k]]
                ? getLabel("KaigoJoukyou." + u[column[k]])
                : "";
            } else {
              row[column[k]] = u[column[k]];
            }
          }
          worksheet.addRow(row);

          if (rowNum % 2 !== 0) {
            worksheet.getRow(rowNum + 1).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "DDDDDD" },
            };
          }

          worksheet.getRow(rowNum + 1).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });
        // style for header
        worksheet.getRow(1).font = { bold: true };
        worksheet.getRow(1).alignment = { horizontal: "center" };
        worksheet.getRow(1).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "CCECFF" },
        };
        worksheet.getRow(1).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
    }

    setIsLoading(false);
    const uint8Array = await workbook.xlsx.writeBuffer(); //xlsxの場合
    const blob = new Blob([uint8Array], { type: "application/octet-binary" });
    const url = window.URL.createObjectURL(blob);
    const currentDate = new Date();
    const filename = `${formatDate(currentDate)}_${String(
      currentDate.getHours(),
    ).padStart(2, "0")}h${String(currentDate.getMinutes()).padStart(
      2,
      "0",
    )}m${String(currentDate.getSeconds()).padStart(2, "0")}s`;
    const a = document.createElement("a");
    a.href = url;
    a.download = filename + ".xlsx";
    a.click();
    a.remove();
  };

  return (
    <Button
      style={{ backgroundColor: isLoading ? "#EFF0F0" : "#ffffff" }}
      padding="6px 14px"
      isDisabled={isLoading}
      onClick={handleDowload}
    >
      {isLoading && <Loader size="large" />} {getLabel("ekuserukeishiki")}
    </Button>
  );
}
