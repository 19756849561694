import { PrimaryFABtext } from "../ui-components";
import { getLabel } from "../utils/CommonUtils";

export default function FABAdd(props) {
  const { isDisabled, onClick, ...rest } = props;
  const onClickAdd = isDisabled ? () => {} : onClick;

  return (
    <PrimaryFABtext
      {...rest}
      onClick={onClickAdd}
      width="89px"
      backgroundColor={isDisabled ? "#EFF0F0" : "#688649"}
      overrides={{
        "\u270F\uFE0F Button text": {
          children: getLabel("tsuika"),
          color: isDisabled ? "#5C6670" : "white",
        },
        Icon: {
          paths: [
            {
              d: "M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z",
              fill: isDisabled ? "#5C6670" : "white",
              fillRule: "nonzero",
            },
          ],
        },
      }}
    />
  );
}
