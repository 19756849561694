import { Flex } from "@aws-amplify/ui-react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { withNonScrollBody } from "../hooks";
import { getLabel, isEnableVideoCall } from "../utils/CommonUtils";
import Card from "./Card";
import Description from "./Description";
import FABAdd from "./FABAdd";
import FABMemo from "./FABMemo";
import FABShare from "./FABShare";
import LabelM from "./LabelM";
import ListSingleLineDExpand from "./ListSingleLineDExpand";

function ListSelectContent(props) {
  const {
    label,
    mobileList,
    addButtonLabel,
    onAddClick,
    pcListItem,
    selectedItemList,
    selectedItemLabel,
    onDelete,
    onEdit,
    style,
    isLoaded = true,
    isDisabled,
    description = {},
    hideButtonAdd,
    hideButtonEdit,
    labelS,
    descriptionStyle,
    isTopButton,
    isDefaultButton,
    isShared,
    isInvitePage = false,
    isHideBtnDelete = false,
    isHideLabel = false,
    desUrlShare = "",
    isMemoBtn = false,
    isChallengeCate = false,
    onAchieve = ()=>{},
    isAchieved = false
  } = props;

  const navigate = useNavigate();
  const { type, category, subDescription } = description;

  const goToMemo = () => {
    navigate("/memo");
  };

  const mobileDescription = () => {
    return description && mobileList && mobileList.length === 0 && category ? (
      isShared ? (
        <Description text={getLabel("shared.des")} style={descriptionStyle} />
      ) : (
        <Card
          type={type}
          title={getLabel(`${category}`)}
          titleEn={getLabel(`${category}.en`)}
          mainDescription={getLabel(`${category}.des`)}
          subDescription={subDescription}
        />
      )
    ) : (
      subDescription && !isShared && (
        <Description text={subDescription} style={descriptionStyle} />
      )
    );
  };

  const pcDescription = () => {
    return description && pcListItem && pcListItem.length === 0 && category ? (
      <Description
        text={
          isShared
            ? getLabel("shared.des")
            : `${getLabel(`${category}.des`)}\n${
                subDescription ? subDescription : ""
              }`
        }
      />
    ) : subDescription ? (
      <Description text={isShared ? "" : subDescription} />
    ) : null;
  };

  const renderButtonAddMobile = () => {
    const isRender = !hideButtonAdd;

    return (
      isRender && (
        <Flex display="block" position="fixed" bottom={isEnableVideoCall ? "160px" : "86px"} right="16px" style={{zIndex :"1"}}>
          {isMemoBtn && <FABMemo marginBottom="16px" onClick={goToMemo} />}
          {!isInvitePage && (
            <FABShare
              marginBottom="16px"
              onClick={() => {
                navigate(desUrlShare ? desUrlShare : `share`);
              }}
            />
          )}
          <FABAdd onClick={onAddClick} isDisabled={isDisabled} />
        </Flex>
      )
    );
  };

  return isMobile ? (
    <>
      {!isHideLabel && <LabelM label={label} />}
      {isLoaded && mobileDescription()}
      {isTopButton && !isShared && renderButtonAddMobile()}
      {labelS}
      {mobileList}
      {!isTopButton && !isShared && renderButtonAddMobile()}
    </>
  ) : (
    <div className="swapper">
      <LabelM
        label={label}
        isDivide
        buttonAdd={
          !hideButtonAdd &&
          !isShared && {
            label: addButtonLabel,
            onClick: onAddClick,
            isDisabled: isDisabled,
          }
        }
        isShareBtn={!isShared && !hideButtonAdd}
        isMemoBtn={isMemoBtn}
        isInvitePage={isInvitePage}
        isDefault={isDefaultButton}
        desUrl={desUrlShare}
      />
      {isLoaded && pcDescription()}
      {pcListItem?.length > 0 && (
        <div className="content-swapper" style={style}>
          <div className="list-lineD-swapper hide-scroll text-overflow-ellipsis">
            {pcListItem}
          </div>
          {selectedItemList && (
            <ListSingleLineDExpand
              label={selectedItemLabel}
              list={selectedItemList}
              deleteOnClick={onDelete}
              editOnClick={onEdit}
              hideButton={hideButtonEdit || isShared}
              isHideBtnDelete={isHideBtnDelete}
              isChallengeCate={isChallengeCate}
              onAchieve={onAchieve}
              isAchieved={isAchieved}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default withNonScrollBody(ListSelectContent);
