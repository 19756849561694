import { DataStore } from "aws-amplify";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MODEL_NAME } from "../constants";
import { LeavesShareInfo } from "../models";
import { ListField } from "../page-components";
import { getLabel, isEmpty, isEmptyDate, isInvalidDateFormat, isNotEmptyDate, useInitializePage } from "../utils/CommonUtils";
import { log } from "../utils/Logger";
import { usePrompt } from "../utils/Prompt";
import { isShareAccepted } from "../utils/ShareUtils";

export default function InviteEditPage() {
  const navigate = useInitializePage("invite", "title.update"); /**TODO: */
  let params = useParams();
  const [data, setData] = useState({
    name: "",
    target: [],
  });

  const [shareData, setShareData] = useState({
    shareName: "",
    shareDate: null,
    shareTarget: [],
  });
  const [shareNameError, setShareNameError] = useState("");
  const [shareDateError, setShareDateError] = useState("");
  const [shareTargetError, setShareTargetError] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);
  const [targetDateError, setTargetDateError] = useState({});
  const [update, setUpdate] = useState(false);
  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);
  const [isAccepted, setIsAccepted] = useState(false);
  const checkOptions = [
    "LeavesJitsubutsuShisan",
    "LeavesKinyuuShisan",
    "LeavesHoken",
    "LeavesCreditcard",
    "LeavesKouza",
    "LeavesNenkin",
    "LeavesLoankariirekin",
    "LeavesSumahoPc",
    "LeavesLoginId",
    "LeavesKazokuShinzoku",
    "LeavesYuujinChijin",
    "LeavesKenkoukanri",
    "LeavesSeikatsunokoto",
    "LeavesPet",
    "LeavesDaijinishitaikinenbi",
    "LeavesLifeFuture",
    "LeavesTaisetsunishitaihitohenomessage",
    "LeavesKaigo",
    "LeavesIryousochi",
    "LeavesYuigonshonitsuite",
    "LeavesSougi",
    "LeavesHakanitsuite",
    "LeavesJibunshi",
    "LeavesTakaramono",
    "LeavesKakeizu",
  ];

  const listField = [
    { type: "textField", label: "shareName", value: shareData.shareName, isRequire: true, errorMessage: shareNameError },
    {
      type: "textField",
      label: "shareDate",
      value: shareData.shareDate,
      isRequire: true,
      typeInput: "date",
      errorMessage: shareDateError,
    },
    {
      type: "description",
      label: getLabel("shareDate.desTitle"),
      description: getLabel("shareDate.des"),
    },
    {
      type: "checkListShare",
      label: "shareTarget",
      isRequire: true,
      errorList: targetDateError,
      commonShareDate: shareData?.shareDate,
      value: shareData?.shareTarget.filter(ele => ele.value !==  MODEL_NAME.HOSYOUSAIMU && ele.value !==  MODEL_NAME.SHUUKAN),
      options: checkOptions.map((o) => {
        return { option: getLabel("Datastore." + o), value: o };
      }),
      errorMessage: shareTargetError,
    },
    {
      type: "textField",
      label: "shareStatus",
      value: isAccepted ? getLabel("shotaishoninzumi") : getLabel("shotaichu"),
      isDisabled: true,
    },
  ]; /**TODO: */

  const addData = async () => {
    let valid = true;
    if (isEmpty(shareData.shareName)) {
      setShareNameError(getLabel("error.required"));
      valid = false;
    } else setShareNameError("");

    const dateFormatCheck = isInvalidDateFormat(shareData.shareDate);
    if (dateFormatCheck) {
      setShareDateError(getLabel("error.dateformat"));
      valid = false;
    } else if (isEmptyDate(shareData.shareDate)) {
      setShareDateError(getLabel("error.required"));
      valid = false;
    } else {
      setShareDateError(null);
    }
    if (
      shareData.shareTarget.filter((ele) => ele !== MODEL_NAME.HOSYOUSAIMU && ele !== MODEL_NAME.SHUUKAN)
        ?.length === 0
    ) {
      setShareTargetError(getLabel("error.required"));
      valid = false;
    } else setShareTargetError("");

    const errorTargetDate = {};
    shareData.shareTarget.forEach((ele) => {
      const checkDate = isInvalidDateFormat(ele.shareDate);
      if (checkDate) {
        errorTargetDate[ele.value] = getLabel("error.dateformat");
        valid = false;
      } else {
        errorTargetDate[ele.value] = null;
      }
    });
    setTargetDateError(errorTargetDate);

    if (valid) {
      setIsUpdated(true);
      let targetList = shareData.shareTarget.map((t) => {
        return {
          dataName: t.value,
          shareDate: isEmptyDate(t.shareDate)
            ? shareData.shareDate
            : t.shareDate,
        };
      });
      const loanItem = targetList.find(
        (ele) => ele.dataName === MODEL_NAME.LOANKARIIREKIN
      );
      const hosyoItem = targetList.find(
        (ele) => ele.dataName === MODEL_NAME.HOSYOUSAIMU
      );
      if (loanItem && !hosyoItem) {
        targetList.push({
          dataName: MODEL_NAME.HOSYOUSAIMU,
          shareDate: loanItem.shareDate,
        });
      } else if (!loanItem) {
        targetList = targetList.filter(
          (ele) => ele.dataName !== MODEL_NAME.HOSYOUSAIMU
        );
      }

      const bucketItem = targetList.find(
        (ele) => ele.dataName === MODEL_NAME.LIFEFUTURE
      );
      const shuukanItem = targetList.find(
        (ele) => ele.dataName === MODEL_NAME.SHUUKAN
      );
      if (bucketItem && !shuukanItem) {
        targetList.push({
          dataName: MODEL_NAME.SHUUKAN,
          shareDate: bucketItem.shareDate,
        });
      } else if (!bucketItem) {
        targetList = targetList.filter(
          (ele) => ele.dataName !== MODEL_NAME.SHUUKAN
        );
      }

      const updated = DataStore.save(
        LeavesShareInfo.copyOf(data, (updated) => {
          updated.name = shareData.shareName;
          updated.originDate = shareData.shareDate;
          updated.target = targetList;
        })
      );
      log.debug("updated", updated);
      navigate(-1, { replace: true, state: { id: shareData.id } });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const data = await DataStore.query(LeavesShareInfo, (c) => c.id("eq", params.id));
      if (data?.length > 0) {
        setShareData({
          shareName: data[0].name,
          shareDate: data[0].originDate,
          shareTarget: data[0].target?.map((ele) => ({
            value: ele?.dataName,
            shareDate:
              ele?.shareDate === data[0].originDate ? "" : ele?.shareDate,
          })),
        });
        setData(data[0]);

        // const sharedInfo = await API.graphql({
        //   query: listLeavesSharedInfos,
        //   variables: { filter: { shareInfoId: { eq: data[0].id } } },
        //   authMode: "AWS_IAM",
        // });
        // log.debug("get sharedInfo;" + data[0].id, sharedInfo.data.listLeavesSharedInfos.items);
        // setIsAccepted(sharedInfo.data.listLeavesSharedInfos.items.filter((i) => !i._deleted).length > 0);
        setIsAccepted(await isShareAccepted(data[0].id));
      }
    };
    getData();
  }, []);

  return (
    <div>
      <ListField
        label={getLabel("button.update")}
        isDivide
        list={listField}
        setState={setShareData}
        setUpdate={setUpdate}
        onSave={addData}
        onCancel={() => {
          navigate(-1, { replace: true, state: { id: shareData.id } });
        }}
        isDisabled={isUpdated}
      />
      {/**TODO: */}
    </div>
  );
}
