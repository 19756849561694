/* eslint-disable import/no-anonymous-default-export */
import { DataStore, Storage } from "aws-amplify";
import React, { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { LeavesJibunshi } from "../models";
import {
  ButtonCancelSave,
  SelectImageField,
  SwapperContent,
  TextField,
  TextFieldMultiline,
} from "../page-components";
import UserContext from "../UserContext";
import {
  getLabel,
  isEmpty,
  isEmptyDate,
  isInvalidDateFormat,
  isNotEmptyDate,
  useInitializePage,
} from "../utils/CommonUtils";
import { log } from "../utils/Logger";
import { usePrompt } from "../utils/Prompt";

/*
  nengetsu: AWSDate!
  eventmei: String!
*/
export default function () {
  const navigate = useInitializePage("jibunshi", "title.add");

  const context = useContext(UserContext);
  const [isLoaded, setIsLoaded] = useState(false);

  const [nengetsu, setNengetsu] = useState("");
  const [eventmei, setEventmei] = useState("");
  const [episode, setEpisode] = useState("");
  const [tenpufile, setTenpufile] = useState(null);

  const [nengetsuError, setNengetsuError] = useState(null);
  const [eventmeiError, setEventmeiError] = useState(null);
  const [isAdded, setIsAdded] = useState(false);

  const [update, setUpdate] = useState(false);
  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  const eventTracking = () => {
    window.gtag("event", "FirstWriteComplete", {
      event_category: "Edit",
      event_label: "自分史書込完了",
    });
  };

  const addData = async () => {
    log.debug(nengetsu + "," + eventmei + "," + episode);
    let valid = true;
    const dateFormatCheck = isInvalidDateFormat(nengetsu);
    if (dateFormatCheck) {
      setNengetsuError(getLabel("error.dateformat"));
      valid = false;
    } else if (isEmptyDate(nengetsu)) {
      setNengetsuError(getLabel("error.required"));
      valid = false;
    } else {
      setNengetsuError(null);
    }
    if (isEmpty(eventmei)) {
      setEventmeiError(getLabel("error.required"));
      valid = false;
    } else {
      setEventmeiError(null);
    }
    if (valid) {
      setIsAdded(true);
      //   console.log(context.leavesUser);
      const saved = await DataStore.save(
        new LeavesJibunshi({
          nengetsu: isNotEmptyDate(nengetsu) ? nengetsu : null,
          eventmei: eventmei,
          episode: episode,
          leavesusersID: context.leavesUser.id,
        }),
      );

      if (tenpufile) {
        const addData = async () => {
          const result = await Storage.put(
            "LeavesJibunshi/" + saved.id + "/" + tenpufile.name,
            tenpufile,
            {
              contentType: tenpufile.type,
              level: "private",
            },
          );
          eventTracking();
          navigate(-1, { replace: true });
          log.debug("s3 upload result", result);
        };
        addData();
      } else {
        eventTracking();
        navigate(-1, { replace: true });
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const modelData = await DataStore.query(LeavesJibunshi);
      if (modelData?.length >= 10) {
        navigate(-1, { replace: true });
      } else {
        setIsLoaded(true);
      }
    };
    getData();
  }, [navigate]);

  return (
    isLoaded && (
      <SwapperContent label={getLabel("shinkitsuika")}>
        <TextField
          isRequired
          className={isMobile ? "" : "maxwidth800"}
          type="date"
          label={getLabel("nengetsu")}
          errorMessage={nengetsuError}
          onChange={(event) => {
            setNengetsu(event.target.value);
            setUpdate(true);
          }}
        />
        <TextField
          isRequired
          className={isMobile ? "" : "maxwidth800"}
          label={getLabel("eventmei")}
          errorMessage={eventmeiError}
          placeHolder={getLabel("marumarusyanyuusyanado")}
          onChange={(event) => {
            setEventmei(event.target.value);
            setUpdate(true);
          }}
        />
        <TextFieldMultiline
          className={isMobile ? "" : "maxwidth800"}
          label={getLabel("episode")}
          placeHolder={getLabel(
            "oyakaranopresentnonecktiewoshimetehatsusyukkinshitanado",
          )}
          onChange={(event) => {
            setEpisode(event.target.value);
            setUpdate(true);
          }}
        />
        <SelectImageField
          className={isMobile ? "" : "maxwidth800"}
          label={getLabel("tenpufile")}
          onChange={(event) => {
            setTenpufile(event.target.value);
            setUpdate(true);
          }}
          buttonLabel={getLabel("filewosentaku")}
        />
        <ButtonCancelSave
          cancelOnClick={() => navigate(-1, { replace: true })}
          saveOnClick={() => addData()}
          isDisabled={isAdded}
        />
      </SwapperContent>
    )
  );
}
