import { DataStore } from "aws-amplify";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { LeavesLoginId } from "../models";
import { ListField } from "../page-components";
import UserContext from "../UserContext";
import {
  getLabel,
  isEmpty,
  isNotEmpty,
  useInitializePage,
} from "../utils/CommonUtils";
import { usePrompt } from "../utils/Prompt";

function LoginIDAddPage() {
  const navigate = useInitializePage("kakushuserviceid", "title.add");
  const context = useContext(UserContext);
  const [loginIDData, setLoginIDData] = useState({
    servicemei: "",
    siteurl: "",
    loginid: "",
    bikou: "",
  });
  const [loginIDDataError, setLoginIDDataError] = useState({
    servicemeiError: "",
    siteurlError: "",
    loginidError: "",
    bikouError: "",
  });
  const [isAdded, setIsAdded] = useState(false);
  const listField = [
    {
      type: "textField",
      label: "servicemei",
      isRequire: true,
      placeHolder: "facebooknado",
      hasError: isNotEmpty(loginIDDataError.servicemeiError),
      errorMessage: loginIDDataError.servicemeiError,
    },
    { type: "textArea", label: "siteurl" },
    { type: "textField", label: "loginid" },
    { type: "textArea", label: "bikou" },
  ];
  const [update, setUpdate] = useState(false);
  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  const addData = useCallback(async () => {
    let valid = true;
    if (isEmpty(loginIDData.servicemei)) {
      setLoginIDDataError({
        ...loginIDDataError,
        servicemeiError: getLabel("error.required"),
      });
      valid = false;
    }
    if (valid) {
      setIsAdded(true);
      await DataStore.save(
        new LeavesLoginId({
          servicemei: loginIDData.servicemei,
          siteurl: loginIDData.siteurl,
          loginid: loginIDData.loginid,
          bikou: loginIDData.bikou,
          leavesusersID: context.leavesUser.id,
        }),
      );
      window.gtag("event", "FirstWriteComplete", {
        event_category: "Edit",
        event_label: "WEBサービスのログイン情報書込完了",
      });
      navigate(-1, { replace: true });
    }
  }, [
    context.leavesUser.id,
    loginIDData.bikou,
    loginIDData.loginid,
    loginIDData.servicemei,
    loginIDData.siteurl,
    loginIDDataError,
    navigate,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ListField
      label={getLabel("shinkitsuika")}
      list={listField}
      setState={setLoginIDData}
      setUpdate={setUpdate}
      onSave={addData}
      onCancel={() => navigate(-1, { replace: true })}
      isDisabled={isAdded}
    />
  );
}

export default LoginIDAddPage;
