import { View, Text } from '@aws-amplify/ui-react';
import { AnswerButton } from "../ui-components";
import { isMobile, isTablet } from "react-device-detect";

export default function MindTypeQuestion(props) {

    return (
        <View
            width={(isMobile || isTablet) ? '300px' : '438px'}
            height="91px"
            display="flex"
            position="relative"
        >
            <AnswerButton   
                answerTable={props.answerTable}
                setAnswerTable={props.setAnswerTable}
                page={props.page}
                questionNo={props.questionNo}
                answer={1} />
            <AnswerButton
                answerTable={props.answerTable}
                setAnswerTable={props.setAnswerTable}
                page={props.page}
                questionNo={props.questionNo}
                answer={2} />
            <AnswerButton
                answerTable={props.answerTable}
                setAnswerTable={props.setAnswerTable}
                page={props.page}
                questionNo={props.questionNo}
                answer={3} />
            <AnswerButton
                answerTable={props.answerTable}
                setAnswerTable={props.setAnswerTable}
                page={props.page}
                questionNo={props.questionNo}
                answer={4} />
            <AnswerButton
                answerTable={props.answerTable}
                setAnswerTable={props.setAnswerTable}
                page={props.page}
                questionNo={props.questionNo}
                answer={5} />
            <AnswerButton
                answerTable={props.answerTable}
                setAnswerTable={props.setAnswerTable}
                page={props.page}
                questionNo={props.questionNo}
                answer={6} />

            {(props.page != 2 && props.page != 3) ? 
            <AnswerButton
                answerTable={props.answerTable}
                setAnswerTable={props.setAnswerTable}
                page={props.page}
                questionNo={props.questionNo}
                answer={7} />
            : ''}

            <Text
                fontFamily="Inter"
                fontSize={(isMobile || isTablet) ? '10px' : '14px'}
                fontWeight="700"
                color="rgba(28,26,29,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                letterSpacing="-0.97px"
                width={(isMobile || isTablet) ? '300px' : '438px'}
                position="absolute"
                top="0%"
                bottom="73.63%"
                left="0%"
                right="0%"
                whiteSpace="pre-wrap"
            >
                {props.text}
            </Text>
        </View>
    );

}
