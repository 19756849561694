import { I18n } from "aws-amplify";
import { isAndroid, isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { getObject } from "./ShareUtils";

export const defaultString = (value, returnValue) => {
  if (value == null || value == undefined || value == "") {
    return "";
  } else {
    return returnValue ? returnValue : value;
  }
};
export const isEmpty = (value) => {
  return value == null || value == "" || value == undefined;
};

export const isNotEmpty = (value) => {
  return value != null && value != "" && value != undefined;
};

export const useInitializePage = (value, suffix) => {
  document.title = I18n.get(value) + (suffix ? I18n.get(suffix) : "");
  const navigate = useNavigate();
  return navigate;
};

export const getLabel = (value) => {
  const label = I18n.get(value);
  return label ? label : value;
};

export const formatDate = (date) => {
  //format to YYYY-MM-DD
  return date.getFullYear() + "-" + String(date.getMonth() + 1).padStart(2, "0") + "-" + String(date.getDate()).padStart(2, "0");
};

export const formatDateTextField = (awsDateStr) => {
  const splitedDate = awsDateStr?.split("-");
  if (splitedDate && splitedDate.length == 3) {
    return splitedDate[0] + "/" + parseInt(splitedDate[1]) + "/" + parseInt(splitedDate[2]);
  }
  return awsDateStr;
};

export const formatDateLabel = (awsDateStr) => {
  const splitedDate = awsDateStr?.split("-");
  if (splitedDate && splitedDate.length == 3) {
    return splitedDate[0] + "年" + parseInt(splitedDate[1]) + "月" + parseInt(splitedDate[2]) + "日";
  }
  return awsDateStr;
};

export const isNotEmptyDate = (value) => {
  return value === "" || value === null || value === "--" ? false : true;
};

export const isEmptyDate = (value) => {
  return value === "" || value === null || value === "--" ? true : false;
};

export const formatNumber = (value) => {
  if (value) {
    const numbers = value.toString().split(".");
    numbers[0] = numbers[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return numbers.join(".");
  } else {
    return null;
  }
};

export const restrictFieldNumber = (value, maxLength) => {
  let result = value;
  if (result.length > maxLength) {
    result = result.slice(0, maxLength);
  }
  if (!/^\d+$/.test(result) || result === "0") {
    //prevent type 0 first input number
    result = result.slice(0, result.length - 1);
  }
  result = result.replace(/^0+/, "");
  return result;
};

export const restrictFieldDate = (value) => {
  let result = value;
  if (!/^\d+$/.test(result)) {
    result = result.slice(0, result.length - 1);
  }
  if (result.length > 8) {
    result = result.slice(0, 8);
  }
  return result
};

export const numbertoDate = (value) => {
  return `${value.slice(0, 4)}-${value.slice(4, 6)}-${value.slice(6, 8)}`;
};

export const inValidNumber = (value) => {
  return isNaN(parseInt(value));
};

export const inValidDate = (date) => { //not require date
  return isNotEmptyDate(date) && isNaN(new Date(date));
};

export const isEmptySougi = (dataQuery) => {
  return (
    isEmpty(dataQuery?.sougiyouhi) &&
    isEmpty(dataQuery?.kibousougikeishiki) &&
    isEmpty(dataQuery?.souginosyurui) &&
    isEmpty(dataQuery?.sankaninzuukibo) &&
    isEmpty(dataQuery?.kibousuruhuniki) &&
    isEmpty(dataQuery?.junbishiteiruhiyou) &&
    isEmpty(dataQuery?.hiyoukyoshutsu) &&
    isEmpty(dataQuery?.hokenkyousainado) &&
    isEmpty(dataQuery?.souginiyondehoshiyuujinchijin) &&
    isEmpty(dataQuery?.yondehoshiihitonituite) &&
    isEmpty(dataQuery?.souginiyobukakazokuniitininsuruyuujinchijin) &&
    isEmpty(dataQuery?.itininsuruhitonituite) &&
    isEmpty(dataQuery?.souginoannaiwoshinakutemoyoihitonituite) &&
    isEmpty(dataQuery?.bikou)
  );
};

export const isEmptyYuigon = (dataQuery) => {
  return (
    isEmpty(dataQuery?.yuigonshonoumu) &&
    isEmpty(dataQuery?.yuigonshonoshurui) &&
    isEmpty(dataQuery?.yuigonshonosaisyuusakuseibi) &&
    isEmpty(dataQuery?.yuigonshikkousha) &&
    isEmpty(dataQuery?.yuigonshikkoushanorenrakusaki) &&
    isEmpty(dataQuery?.iraisoudanwoshiteirusenmonka) &&
    isEmpty(dataQuery?.iraisoudanwoshiteirusenmonkanorenrakusaki) &&
    isEmpty(dataQuery?.sonotasouzokunikansurukibounado) &&
    isEmpty(dataQuery?.bikou)
  );
};

export const isEmptyKaigon = (dataQuery) => {
  return (
    isEmpty(dataQuery?.handanwoyudanetaihito) &&
    isEmpty(dataQuery?.kiboukaigoshurui) &&
    isEmpty(dataQuery?.kiboushisetsu) &&
    isEmpty(dataQuery?.hiyoukyoshutsu) &&
    isEmpty(dataQuery?.zaisankanriwomakaseruhito) &&
    isEmpty(dataQuery?.zaisankanriwomakaseruhitonokeiyakujoukyou) &&
    isEmpty(dataQuery?.bikou)
  );
};

export const isEmptyHakani = (dataQuery) => {
  return (
    isEmpty(dataQuery?.hairuyoteinoohakanoumu) &&
    isEmpty(dataQuery?.kibousuruohakanokeishiki) &&
    isEmpty(dataQuery?.kibousurubasyo) &&
    isEmpty(dataQuery?.hiyoukyoshutsu) &&
    isEmpty(dataQuery?.bikou)
  );
};

export const isEmptyIryou = (dataQuery) => {
  return (
    isEmpty(dataQuery?.kokuchikibounoumu) &&
    isEmpty(dataQuery?.enmeisochikibounoumu) &&
    isEmpty(dataQuery?.zoukiteikyounoumu) &&
    isEmpty(dataQuery?.handanwoyudanetaihito) &&
    isEmpty(dataQuery?.ishikiganakunattarashirasetehoshiiyuujinchijin) &&
    isEmpty(dataQuery?.bikou)
  );
};

export const isEmptySeika = (dataQuery) => {
  return isEmpty(dataQuery.seikatsunokotonitsuite);
};
export const getExtension = (filename) => {
  const parts = filename && filename?.split(".");
  return parts ? parts[parts.length - 1] : "";
};

export const isVideo = (filename) => {
  const ext = getExtension(filename);
  // eslint-disable-next-line default-case
  switch (ext.toLowerCase()) {
    case "m4v":
    case "avi":
    case "mp4":
    case "mov":
    case "mpg":
    case "mpeg":
      return true;
  }
  return false;
};
export const isImage = (filename) => {
  const ext = getExtension(filename);
  // eslint-disable-next-line default-case
  switch (ext.toLowerCase()) {
    case "png":
    case "jpg":
    case "jpeg":
    case "gif":
    case "bmp":
      return true;
  }
  return false;
};

export const getVideoBackground = (videoPath) => {
  return new Promise((resolve, reject) => {
    const videoBackgroundItem = document.createElement("video");
    videoBackgroundItem.src = videoPath;
    videoBackgroundItem.loop = true;
    videoBackgroundItem.autoplay = true;
    videoBackgroundItem.muted = true;
    videoBackgroundItem.crossOrigin = "anonymous";
    videoBackgroundItem.onerror = () => {
      return reject();
    };
    videoBackgroundItem.onloadeddata = () => {
      const canvasVideo = document.createElement("canvas");
      videoBackgroundItem.currentTime = 0;
      videoBackgroundItem.addEventListener("canplaythrough", () => {
        const context = canvasVideo.getContext("2d");
        canvasVideo.width = videoBackgroundItem?.videoWidth;
        canvasVideo.height = videoBackgroundItem?.videoHeight;
        context?.drawImage(
          videoBackgroundItem,
          0,
          0,
          canvasVideo.width,
          canvasVideo.height,
        );
        videoBackgroundItem.pause();
        videoBackgroundItem.remove();
        if (canvasVideo.toDataURL("image/jpeg"))
          return resolve(canvasVideo.toDataURL("image/jpeg"));
        else return reject();
      });
    };
  });
};

export const downloadFile = (
  fileSrc,
  fileName,
  onComplete = () => {},
  onError = () => {},
) => {
  fetch(fileSrc, {
    method: "GET",
    cache: "no-cache",
    mode: "cors",
    credentials: "omit",
  })
    .then((response) => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        onComplete();
      });
    })
    .catch((err) => {
      onError(err);
    });
};

export const isValidNumberFormat = (value) => {
  return isEmpty(value) ? true : !isNaN(parseInt(value));
};

export const isInvalidDateFormat = (value) => {
  return isNotEmptyDate(value) && (isNaN(new Date(value)) || value.length !== 10)
};

export const preventBodyScrollMobile = (isOpen) => {
  if(!isMobile) return;
  const body = document.body;
  const element = document.getElementsByClassName(
    "react-confirm-alert-overlay",
  )[0];

  if (isAndroid) {
    if (isOpen) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
  } else {
    if (isOpen) {
      body.classList.add("disable-touch");
      body.ontouchmove = (e) => {
        if (e.target !== element) return;
        else e.preventDefault();
      };
    } else {
      body.classList.remove("disable-touch");
      body.ontouchmove = null;
    }
  }
};

export const isEnableVideoCall = process.env.REACT_APP_ENABLE_VIDEO_CALL === "true";

export const getCurrentUserAge = (birthdate) => {
  const now = new Date();
  const today = parseInt(
    new String(now.getFullYear()).concat(
      ("0" + (now.getMonth() + 1)).slice(-2),
      ("0" + now.getDate()).slice(-2)
    )
  );
  const ageBase = Math.trunc(
    (today - parseInt(birthdate.replaceAll("-", ""))) / 10000
  );
  return ageBase;
};

export const getListRangeAge = (currentUserAge) => {
  const listOption = [
    {
      value: "100",
      label: `100${getLabel("sai")}〜`,
    },
  ];
  const numberRange = Math.ceil((100 - currentUserAge) / 5);
  for (let i = 1; i <= numberRange; i++) {
    const item = {
      value: String(100 - 5 * i),
      label: `${100 - 5 * i}${getLabel("sai")}〜${100 - 5 * i + 4}${getLabel(
        "sai"
      )}`,
    };
    listOption.push(item);
  }
  return listOption.reverse();
};

export const getUrlImage = async (imageSrc) => {
  const { fileName, url } = await getObject(imageSrc);
  if (isImage(fileName)) {
    return url;
  }
  else return null
};

export const getFileName = (filename) => {
  const parts = filename && filename?.split("/");
  return parts?.length > 0 && parts[parts.length - 1];
};
