/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Icon, Text } from "@aws-amplify/ui-react";
export default function TextButtonIcon(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="8px"
      direction="row"
      justifyContent="center"
      alignItems="center"
      position="relative"
      borderRadius="4px"
      padding="8px 8px 8px 8px"
      {...rest}
      {...getOverrideProps(overrides, "TextButtonIcon")}
    >
      <Icon
        width="18px"
        height="18px"
        shrink="0"
        overflow="hidden"
        position="relative"
        padding="0px 0px 0px 0px"
        color="rgba(4,125,149,1)"
        viewBox={{ minX: 0, minY: 0, width: 24, height: 24 }}
        paths={[
          {
            d: "M11 5L9 5L9 9L5 9L5 11L9 11L9 15L11 15L11 11L15 11L15 9L11 9L11 5ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z",
            fillRule: "nonzero",
            style: { transform: "translate(8.33%, 8.33%)" },
          },
        ]}
        type="add_circle_outline"
        fontSize="18px"
        {...getOverrideProps(overrides, "Icon")}
      ></Icon>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="700"
        color="rgba(4,125,149,1)"
        lineHeight="18px"
        textAlign="center"
        display="flex"
        direction="column"
        justifyContent="flex-start"
        letterSpacing="0.41px"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Text Button"
        {...getOverrideProps(overrides, "Text Button")}
      ></Text>
    </Flex>
  );
}
