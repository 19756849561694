import { DataStore, Storage } from "aws-amplify";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { LeavesMemo } from "../models";
import { ListField } from "../page-components";
import {
  getLabel,
  isEmpty,
  useInitializePage,
} from "../utils/CommonUtils";
import { usePrompt } from "../utils/Prompt";

export default function MemoEditPage() {
  const navigate = useInitializePage("jiyucho", "title.update");
  const data = useRef();
  const [memoData, setMemoData] = useState({
    title: "",
    memo: "",
    tenpufile: null,
    objectSrc: null,
  });
  const [isLoaded, setIsLoaded] = useState(true);
  const [memoDataError, setMemoDataError] = useState({
    title: "",
    tenpufile: "",
  });
  const [isUpdated, setIsUpdated] = useState(false);
  const params = useParams();

  const [update, setUpdate] = useState(false);

  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const dataQuery = await DataStore.query(LeavesMemo, (c) =>
        c.id("eq", params.id)
      );
      if (dataQuery?.length > 0) {
        data.current = dataQuery[0];
        setIsLoaded(true);
        setMemoData({
          title: dataQuery[0].title,
          memoCategory: dataQuery[0].memo,
        });
      }
    };
    getData();
  }, [params.id]);

  useEffect(() => {
    let mount = true;
    if (data.current && data.current.id) {
      Storage.list(`LeavesMemo/${data.current.id}/`, {
        level: "private",
      }).then((res) => {
        const url = res.length > 0 ? res[0].key : null;
        if (mount) {
          setMemoData({
            ...memoData,
            tenpufile: url,
            objectSrc: url,
          });
        }
      });
    }
    return () => (mount = false);
  }, [data.current]);

  const listField = [
    {
      type: "textField",
      label: "title",
      isRequire: true,
      errorMessage: memoDataError?.title,
      value: memoData?.title,
    },
    {
      type: "textArea",
      label: "memoCategory",
      value: memoData?.memoCategory,
    },
    {
      type: "file",
      label: "tenpufile",
      value: memoData.objectSrc,
      isImage: true,
    },
  ];

  const updateDataMessage = useCallback(async () => {
    let valid = true;
    if (isEmpty(memoData.title)) {
      setMemoDataError({ ...memoDataError, title: getLabel("error.required") });
      valid = false;
    } else setMemoDataError({ ...memoDataError, title: "" });
    if (valid) {
      setIsUpdated(true);
      await DataStore.save(
        LeavesMemo.copyOf(data.current, (updated) => {
          updated.title = memoData.title;
          updated.memo = memoData.memoCategory;
        })
      );
      if (memoData?.tenpufile !== memoData.objectSrc) {
        const editData = async () => {
          const listFile = await Storage.list(
            `LeavesMemo/${data.current.id}/`,
            {
              level: "private",
            }
          );
          for (let i = 0; i < listFile.length; i++) {
            await Storage.remove(listFile[i].key, { level: "private" });
          }
          if (memoData.tenpufile) {
            await Storage.put(
              `LeavesMemo/${data.current.id}/${memoData.tenpufile.name}`,
              memoData.tenpufile,
              {
                contentType: memoData.tenpufile.type,
                level: "private",
              }
            );
          }
          navigate(-1, { replace: true });
        };
        editData();
      } else {
        navigate(-1, { replace: true });
      }
    }
  }, [
    memoData.title,
    memoData.tenpufile,
    memoData.objectSrc,
    memoData.memoCategory,
    navigate,
  ]);

  return (
    isLoaded && (
      <ListField
        label={getLabel("button.update")}
        list={listField}
        setState={setMemoData}
        setUpdate={setUpdate}
        onSave={updateDataMessage}
        onCancel={() => navigate(-1, { replace: true })}
        isDisabled={isUpdated}
      />
    )
  );
}
