import { Flex, Loader, Text } from "@aws-amplify/ui-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import DocumentIcon from "../assets/image/document.png";
import PlayIcon from "../assets/image/play_circle.png";
import { Colum as PageColumn, SelectMenu } from "../ui-components";
import { downloadFile, getVideoBackground } from "../utils/CommonUtils";

export default function Colum(props) {
  const { type, fileSrc, fileName, mobilePath, pcPath } = props;
  const [openMenu, setOpenMenu] = useState(false);
  const [imgPreview, setImgPreview] = useState("");
  const [isDoc, setIsDoc] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const idItem = mobilePath?.split("/")[mobilePath?.split("/").length - 1];
  const [isDownloading, setIsDownloading] = useState(false);
  const [haveThumnail, setHaveThumnail] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const menuRef = useRef();
  const navigate = useNavigate();

  const onDownload = useCallback(() => {
    setIsDownloading(true);
    setOpenMenu(true);
    downloadFile(fileSrc, fileName, () => {
      setIsDownloading(false);
      setOpenMenu(false);
    });
  }, [fileName, fileSrc]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenMenu(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    let mount = true;
    if (type === "image") {
      mount && setImgPreview(fileSrc);
      setHaveThumnail(true);
      setIsLoaded(true);
    } else if (type === "video") {
      setIsVideo(true);
      getVideoBackground(fileSrc)
        .then((res) => {
          setHaveThumnail(true);
          if (res && mount) {
            setImgPreview(res);
          }
          setIsLoaded(true);
        })
        .catch(() => {
          setHaveThumnail(false);
          setIsLoaded(true);
        });
    } else {
      mount && setIsDoc(true);
      setIsLoaded(true);
    }

    return () => {
      mount = false;
    };
  }, [fileSrc, type]);

  const onMenu = useCallback(() => {
    setOpenMenu(!openMenu);
  }, [openMenu]);

  const renderChild = useMemo(() => {
    if (imgPreview !== "" || isVideo || !isLoaded) {
      return (
        <div className="column-child">
          {haveThumnail ? (
            <img alt="" src={imgPreview} className="column-child__img" />
          ) : (
            <Flex
              width="100%"
              height="100%"
              position="absolute"
              backgroundColor="#E8E8E8"
            />
          )}
          {(isVideo || !isLoaded) &&
            (isLoaded ? (
              <img alt="" src={PlayIcon} className="column-child__icon " />
            ) : (
              <Loader width="25px" height="25px" position="absolute" />
            ))}
        </div>
      );
    } else if (isDoc) {
      return (
        <div className="column-child column-child--orange">
          <img alt="" src={DocumentIcon} className="column-child__icon " />
        </div>
      );
    } else {
      return null;
    }
  }, [haveThumnail, imgPreview, isDoc, isLoaded, isVideo]);

  const overridesMenu = useMemo(() => {
    return {
      "Flex.Flex[0].Text[0]": {
        children: (
          <Flex justifyContent="center" alignItems="center" gap="5px">
            {isDownloading && <Loader />}
            <Text>ファイルをダウンロード</Text>
          </Flex>
        ), //**TODO: */
        color: "#1C1A1D",
        style: { wordBreak: "keep-all" },
      },
      "Flex.Flex[0]": {
        className: "pointer",
        display: openMenu ? "block" : "none",
        onClick: onDownload,
      },
    };
  }, [isDownloading, onDownload, openMenu]);

  const overridesColumnMobile = useMemo(() => {
    return {
      "Flex.Flex[0].Flex[0].Flex[0]": {
        onClick: () => navigate(mobilePath),
      },
      "Flex.Flex[0].Flex[0].Flex[0].Image[0]": {
        as: "div",
        children: renderChild,
      },
      "Flex.Flex[0].Flex[0].Flex[0].Text[0]": {
        height: "100%",
        children: fileName,
        className: "vertical-elipsis",
      },
      "Flex.Flex[0].Flex[0].Flex[1]": {
        className: "pointer",
        ref: menuRef,
        onClick: onMenu,
      },
      "Flex.Flex[0].Flex[0].Flex[1].Icon[0]": openMenu
        ? {}
        : {
            color: "#fff",
          },
      "Flex.Flex[0].View[0]": {
        width: "100%",
        className: "item-border-top",
      },
    };
  }, [fileName, mobilePath, navigate, onMenu, openMenu, renderChild]);

  const overridesColumnPC = useMemo(() => {
    return {
      "Flex.Flex[0]": { gap: "16px" },
      "Flex.Flex[0].Flex[0].Flex[0]": {
        onClick: () => navigate(pcPath, { state: { id: idItem } }),
        width: "150px",
        height: "84px",
      },
      "Flex.Flex[0].Flex[0].Flex[0].Image[0]": {
        height: "100%",
        objectFit: "contain",
        width: "150px",
        as: "div",
        children: renderChild,
      },
      "Flex.Flex[0].Flex[0].Flex[0].Text[0]": {
        children: fileName,
        fontSize: "16px",
        style: { wordBreak: "break-word" },
      },
      "Flex.Flex[0].Flex[0].Flex[1]": {
        className: "pointer",
        ref: menuRef,
        onClick: onMenu,
      },
      "Flex.Flex[0].Flex[0].Flex[1].Icon[0]": openMenu
        ? {}
        : {
            color: "#fff",
          },
      "Flex.Flex[0].View[0]": {
        width: "100%",
        className: "item-border-top",
      },
    };
  }, [fileName, idItem, navigate, onMenu, openMenu, pcPath, renderChild]);

  return (
    <Flex position="relative" display="block" className="attachment-item">
      <PageColumn
        className="pointer"
        width="100%"
        padding={isMobile ? "0px 16px 8px 16px" : "0 0 16px 0"}
        overrides={isMobile ? overridesColumnMobile : overridesColumnPC}
      />

      <Flex
        justifyContent="flex-end"
        position="absolute"
        right={isMobile ? "10px" : "0"}
        top={isMobile ? "100%" : "70%"}
        width="fit-content"
        marginTop="4px"
      >
        <SelectMenu
          className={openMenu ? "colummenu fadein" : "colummenu"}
          width="auto"
          opacity="0"
          overrides={overridesMenu}
        />
      </Flex>
    </Flex>
  );
}
