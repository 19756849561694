import { DataStore } from "aws-amplify";
import { Fragment, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import { LeavesHosyousaimu, LeavesLoankariirekin } from "../models";
import {
  LabelS,
  ListSelectContent,
  ListSingleLineA,
  ListSingleLineD,
} from "../page-components";
import {
  formatNumber,
  getLabel,
  useInitializePage,
} from "../utils/CommonUtils";
import { isShare, queryDatas } from "../utils/ShareUtils";

function LoanPage() {
  const navigate = useInitializePage("loankariirekin");
  const [listLoan, setListLoan] = useState([]);
  const [listHosyo, setListHosyo] = useState([]);
  const [isHosyo, setIsHosyo] = useState(false);
  const [renderList, setRenderList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selected, setSelected] = useState(null);
  const params = useParams();

  const handledChangeActive = (item) => {
    setSelected(item);
  };

  const pcDatalist = () => {
    if (isHosyo) {
      return [
        {
          label: getLabel("syusaimusya"),
          value: selected?.syusaimusya,
        },
        {
          isEmptyHide: true,
          label: getLabel("syusaimusyanorenrakusaki"),
          value: selected?.syusaimusyanorenrakusaki,
        },
        {
          isEmptyHide: true,
          label: getLabel("hosyoushitahi"),
          value: selected?.hosyoushitahi,
          type: "date",
        },
        {
          isEmptyHide: true,
          label: getLabel("hosyoushitakingaku"),
          value:
            formatNumber(selected?.hosyoushitakingaku) &&
            formatNumber(selected?.hosyoushitakingaku) + "円",
        },
        {
          isEmptyHide: true,
          label: getLabel("saikensya"),
          value: selected?.saikensya,
        },
        {
          isEmptyHide: true,
          label: getLabel("saikensyanorenrakusaki"),
          value: selected?.saikensyanorenrakusaki,
        },
        {
          isEmptyHide: true,
          label: getLabel("bikou"),
          value: selected?.bikou,
        },
      ];
    } else {
      return [
        {
          label: getLabel("kariiresaki"),
          value: selected?.kariiresaki,
        },
        {
          isEmptyHide: true,
          label: getLabel("kariiresakinorenrakusaki"),
          value: selected?.kariiresakinorenrakusaki,
        },
        {
          isEmptyHide: true,
          label: getLabel("kariirebi"),
          value: selected?.kariirebi,
          type: "date",
        },
        {
          isEmptyHide: true,
          label: getLabel("kariiregaku"),
          value:
            formatNumber(selected?.kariiregaku) &&
            formatNumber(selected?.kariiregaku) + "円",
        },
        {
          isEmptyHide: true,
          label: getLabel("hensaihouhou"),
          value: selected?.hensaihouhou,
        },
        {
          isEmptyHide: true,
          label: getLabel("tanponoumu"),
          value: selected?.tanponoumu,
        },
        {
          isEmptyHide: true,
          label: getLabel("kariirezandaka"),
          value:
            formatNumber(selected?.kariirezandaka) &&
            formatNumber(selected?.kariirezandaka) + "円",
        },
        {
          isEmptyHide: true,
          label: getLabel("kariirezandakakisaibi"),
          value: selected?.kariirezandakakisaibi,
          type: "date",
        },
        {
          isEmptyHide: true,
          label: getLabel("kariiremokuteki"),
          value: selected?.kariiremokuteki,
        },
        {
          isEmptyHide: true,
          label: getLabel("bikou"),
          value: selected?.bikou,
        },
      ];
    }
  };

  useEffect(() => {
    if (listLoan?.length > 0) {
      setSelected(listLoan[0]);
    } else if (listHosyo?.length > 0) {
      setSelected(listHosyo[0]);
      setIsHosyo(true);
    } else {
      setSelected(null);
    }
  }, [listHosyo, listLoan]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const hosyousaimu = await queryDatas(params, LeavesHosyousaimu);
      const loan = await queryDatas(params, LeavesLoankariirekin);
      if (loan?.length > 0) {
        setListLoan(loan);
      }
      if (hosyousaimu?.length > 0) {
        setListHosyo(hosyousaimu);
      }
      setIsLoaded(true);
    };
    getData();
  }, [params]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const concatList = [];

    if (listLoan?.length > 0) {
      listLoan.forEach((ele) => {
        concatList.push(
          isMobile ? (
            <ListSingleLineA
              key={ele?.id}
              label={ele?.kariiresaki}
              onClick={() => navigate(ele?.id)}
            />
          ) : (
            <ListSingleLineD
              key={ele?.id}
              label={ele?.kariiresaki}
              selected={selected?.id === ele?.id}
              onClick={() => {
                handledChangeActive(ele);
                setIsHosyo(false);
              }}
            />
          ),
        );
      });
    }
    if (listHosyo?.length > 0) {
      concatList.push(
        <LabelS
          key={"hosyousaimu"}
          label={getLabel("hosyousaimu")}
          margin="0"
        />,
      );
      listHosyo.forEach((ele) => {
        concatList.push(
          isMobile ? (
            <ListSingleLineA
              key={ele?.id}
              label={ele?.syusaimusya}
              onClick={() => navigate(ele?.id)}
            />
          ) : (
            <ListSingleLineD
              key={ele?.id}
              label={ele?.syusaimusya}
              selected={selected?.id === ele?.id}
              onClick={() => {
                handledChangeActive(ele);
                setIsHosyo(true);
              }}
            />
          ),
        );
      });
    }
    setRenderList(concatList);
  }, [listHosyo, listLoan, navigate, selected?.id]);

  const deleteData = async (data) => {
    if (isHosyo) {
      await DataStore.delete(data);
      const newList = await DataStore.query(LeavesHosyousaimu);
      setListHosyo(newList);
      if (listLoan?.length > 0) {
        setIsHosyo(false);
      } else if (newList?.length > 0) {
        setIsHosyo(true);
      }
    } else {
      await DataStore.delete(data);
      const newList = await DataStore.query(LeavesLoankariirekin);
      setListLoan(newList);
      if (newList?.length > 0) {
        setIsHosyo(false);
      } else if (listHosyo?.length > 0) {
        setIsHosyo(true);
      }
    }
  };

  return (
    <>
      <ListSelectContent
        label={getLabel("loankariirekin")}
        mobileList={renderList.map((data, index) => {
          return <Fragment key={index}>{data}</Fragment>;
        })}
        description={{
          type: "data",
          category: "loankariirekin",
        }}
        isShared={isShare(params)}
        addButtonLabel={
          getLabel("loankariiresaki") + getLabel("button.addsuffix")
        }
        onAddClick={() => navigate("add")}
        pcListItem={renderList.map((data, index) => {
          return <Fragment key={index}>{data}</Fragment>;
        })}
        selectedItemList={pcDatalist()}
        selectedItemLabel={
          isHosyo ? selected?.syusaimusya : selected?.kariiresaki
        }
        onDelete={() => deleteData(selected)}
        onEdit={() => navigate(selected?.id + "/edit")}
        isLoaded={isLoaded}
      />
    </>
  );
}

export default LoanPage;
