/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable react-hooks/exhaustive-deps */
import { DataStore, Storage } from "aws-amplify";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import {
  LeavesHoken,
  LeavesIryousochi,
  LeavesJitsubutsuShisan,
  LeavesKaigo, LeavesKakeizu, LeavesKazokuShinzoku,
  LeavesKinyuuShisan,
  LeavesKouza,
  LeavesNenkin,
  LeavesSougi,
  LeavesYuigonshonitsuite
} from "../models";
import { CategorySwapper, LabelM, LabelS, ListSingleLineB, ListSingleLineTwoColumnCheck } from "../page-components";
import { getLabel, isNotEmpty, useInitializePage } from "../utils/CommonUtils";

export default function () {
  const navigate = useInitializePage("check");

  const [kinyuushisan, setKinyuushisan] = useState(false);
  const [jitsubutsushisan, setJitsubutsushisan] = useState(false);
  const [nenkin, setNenkin] = useState(false);
  const [hoken, setHoken] = useState(false);
  const [kazoku, setKazoku] = useState(false);
  const [souginitsuite, setSouginitsuite] = useState(false);
  const [kaigonitsuite, setKaigonitsuite] = useState(false);
  const [iryousochinitsuite, setIryousochinitsuite] = useState(false);
  const [kouzajidouhikiotoshi, setKouzajidouhikiotoshi] = useState(false);
  const [yuigonshonitsuite, setYuigonshonitsuite] = useState(false);
  const [kakeizu, setKakeizu] = useState(false);

  useEffect(async () => {
    const kinyuu = await DataStore.query(LeavesKinyuuShisan);
    console.log("data", kinyuu);
    if (kinyuu?.length > 0) {
      setKinyuushisan(true);
    }
    const jitsubutsu = await DataStore.query(LeavesJitsubutsuShisan);
    console.log("data", jitsubutsu);
    if (jitsubutsu?.length > 0) {
      setJitsubutsushisan(true);
    }
    const nenkinList = await DataStore.query(LeavesNenkin);
    if (nenkinList?.length > 0) {
      setNenkin(true);
    }
    const hokenList = await DataStore.query(LeavesHoken);
    if (hokenList?.length > 0) {
      setHoken(true);
    }
    const kouzajidouhikiotoshiList = await DataStore.query(LeavesKouza);
    if (kouzajidouhikiotoshiList?.length > 0) {
      setKouzajidouhikiotoshi(true);
    }
    const kakeizuList = await DataStore.query(LeavesKakeizu);
    if (kakeizuList?.length > 0) {
      setKakeizu(true);
    }
    const kazokuList = await DataStore.query(LeavesKazokuShinzoku);
    if (kazokuList?.length > 0) {
      setKazoku(true);
    }
    const sougi = await DataStore.query(LeavesSougi);
    if (sougi?.length > 0) {
      // sougiyouhi: String
      // kibousougikeishiki: String
      // kibousuruhuniki: String
      // hiyoukyoshutsu: String
      // hokenkyousainado: String
      // bikou: String
      if (
        isNotEmpty(sougi[0].sougiyouhi) ||
        isNotEmpty(sougi[0].kibousougikeishiki) ||
        isNotEmpty(sougi[0].souginosyurui) ||
        isNotEmpty(sougi[0].sankaninzuukibo) ||
        isNotEmpty(sougi[0].kibousuruhuniki) ||
        isNotEmpty(sougi[0].junbishiteiruhiyou) ||
        isNotEmpty(sougi[0].hiyoukyoshutsu) ||
        isNotEmpty(sougi[0].hokenkyousainado) ||
        isNotEmpty(sougi[0].souginiyondehoshiyuujinchijin) ||
        isNotEmpty(sougi[0].yondehoshiihitonituite) ||
        isNotEmpty(sougi[0].souginiyobukakazokuniitininsuruyuujinchijin) ||
        isNotEmpty(sougi[0].itininsuruhitonituite) ||
        isNotEmpty(sougi[0].souginoannaiwoshinakutemoyoihitonituite) ||
        isNotEmpty(sougi[0].bikou)
      ) {
        setSouginitsuite(true);
      }
    }
    const kaigo = await DataStore.query(LeavesKaigo);
    if (kaigo?.length > 0) {
      if (
        isNotEmpty(kaigo[0].handanwoyudanetaihito) ||
        isNotEmpty(kaigo[0].kiboukaigoshurui) ||
        isNotEmpty(kaigo[0].kiboushisetsu) ||
        isNotEmpty(kaigo[0].hiyoukyoshutsu) ||
        isNotEmpty(kaigo[0].zaisankanriwomakaseruhito) ||
        isNotEmpty(kaigo[0].zaisankanriwomakaseruhitonokeiyakujoukyou) ||
        isNotEmpty(kaigo[0].bikou)
      ) {
        setKaigonitsuite(true);
      }

      // handanwoyudanetaihito: "判断を委ねたい人",
      // kiboukaigoshurui: "希望介護種類",
      // kiboushisetsu: "希望施設",
      // hiyoukyoshutsu: "費用拠出",
      // zaisankanriwomakaseruhito: "財産管理を任せる人",
      // zaisankanriwomakaseruhitonokeiyakujoukyou: "財産管理を任せる人の契約状況",
      // bikou
    }
    const yuigon = await DataStore.query(LeavesYuigonshonitsuite);
    if (yuigon?.length > 0) {
      if (
        isNotEmpty(yuigon[0].yuigonshonoumu) ||
        isNotEmpty(yuigon[0].yuigonshonoshurui) ||
        isNotEmpty(yuigon[0].yuigonshonosaisyuusakuseibi) ||
        isNotEmpty(yuigon[0].yuigonshikkousha) ||
        isNotEmpty(yuigon[0].yuigonshikkoushanorenrakusaki) ||
        isNotEmpty(yuigon[0].iraisoudanwoshiteirusenmonka) ||
        isNotEmpty(yuigon[0].iraisoudanwoshiteirusenmonkanorenrakusaki) ||
        isNotEmpty(yuigon[0].sonotasouzokunikansurukibounado) ||
        isNotEmpty(yuigon[0].bikou)
      ) {
        setYuigonshonitsuite(true);
      }
      if (yuigon[0] && yuigon[0].id) {
        Storage.list(`LeavesYuigonshonitsuite/${yuigon[0].id}/`, {
          level: "private",
        }).then((res) => {
          if (res.length > 0) {
            setYuigonshonitsuite(true);
          }
        });
      }
    }

    const iryou = await DataStore.query(LeavesIryousochi);
    if (iryou?.length > 0) {
      if (
        isNotEmpty(iryou[0].kokuchikibounoumu) ||
        isNotEmpty(iryou[0].enmeisochikibounoumu) ||
        isNotEmpty(iryou[0].zoukiteikyounoumu) ||
        isNotEmpty(iryou[0].handanwoyudanetaihito) ||
        isNotEmpty(iryou[0].ishikiganakunattarashirasetehoshiiyuujinchijin) ||
        isNotEmpty(iryou[0].bikou)
      ) {
        setIryousochinitsuite(true);
      }
    }
  }, []);

  return (
    <div>
      {isMobile ? (
        <>
          <LabelM label={getLabel("check")} />
          <LabelS label={getLabel("memo")} />
          <ListSingleLineB
            onClick={() => navigate("/data/assets/financial")}
            label={getLabel("kinyuushisan")}
            checked={kinyuushisan}
          />
          <ListSingleLineB
            onClick={() => navigate("/data/assets/real")}
            label={getLabel("jitsubutsushisan")}
            checked={jitsubutsushisan}
          />
          <ListSingleLineB
            onClick={() => navigate("/data/assets/pension")}
            label={getLabel("nenkin")}
            checked={nenkin}
          />
          <ListSingleLineB
            onClick={() => navigate("/data/assets/insurance")}
            label={getLabel("hoken")}
            checked={hoken}
          />
          <ListSingleLineB
            onClick={() => navigate("/data/assets/claim")}
            label={getLabel("kouzajidouhikiotoshi")}
            checked={kouzajidouhikiotoshi}
          />
          <ListSingleLineB
            onClick={() => navigate("/data/contact/family")}
            label={getLabel("kazokushinzokuichiran")}
            checked={kazoku}
          />

          <LabelS label={getLabel("heart")} />
          <ListSingleLineB
            onClick={() => navigate("/will/funeral")}
            label={getLabel("souginitsuite")}
            checked={souginitsuite}
          />
          <ListSingleLineB
            onClick={() => navigate("/will/care")}
            label={getLabel("kaigonitsuite")}
            checked={kaigonitsuite}
          />
          <ListSingleLineB
            onClick={() => navigate("/will/medical")}
            label={getLabel("iryousochinitsuite")}
            checked={iryousochinitsuite}
          />
          <ListSingleLineB
            onClick={() => navigate("/will/will")}
            label={getLabel("yuigonshonitsuite")}
            checked={yuigonshonitsuite}
          />

          <LabelS label={getLabel("lifepast")} />
          <ListSingleLineB
            onClick={() => navigate("/history/familytree")}
            label={getLabel("kakeizu")}
            checked={kakeizu}
          />
        </>
      ) : (
        <div className="swapper">
          <LabelM label={getLabel("check")} isDivide />
          <div>
            <CategorySwapper label={getLabel("memo")}>
              <ListSingleLineTwoColumnCheck
                onClick={() => navigate("/data/assets/real")}
                label={getLabel("jitsubutsushisan")}
                checked={jitsubutsushisan}
              />
              <ListSingleLineTwoColumnCheck
                onClick={() => navigate("/data/assets/financial")}
                label={getLabel("kinyuushisan")}
                checked={kinyuushisan}
              />
              <ListSingleLineTwoColumnCheck
                onClick={() => navigate("/data/assets/insurance")}
                label={getLabel("hoken")}
                checked={hoken}
              />
              <ListSingleLineTwoColumnCheck
                onClick={() => navigate("/data/assets/claim")}
                label={getLabel("kouzajidouhikiotoshi")}
                checked={kouzajidouhikiotoshi}
              />             
              <ListSingleLineTwoColumnCheck
                onClick={() => navigate("/data/assets/pension")}
                label={getLabel("nenkin")}
                checked={nenkin}
              />
              <ListSingleLineTwoColumnCheck
                onClick={() => navigate("/data/contact/family")}
                label={getLabel("kazokushinzokuichiran")}
                checked={kazoku}
              />
            </CategorySwapper>
            <CategorySwapper label={getLabel("heart")}>
              <ListSingleLineTwoColumnCheck
                onClick={() => navigate("/will/care")}
                label={getLabel("kaigonitsuite")}
                checked={kaigonitsuite}
              />
              <ListSingleLineTwoColumnCheck
                onClick={() => navigate("/will/medical")}
                label={getLabel("iryousochinitsuite")}
                checked={iryousochinitsuite}
              />
              <ListSingleLineTwoColumnCheck
                onClick={() => navigate("/will/will")}
                label={getLabel("yuigonshonitsuite")}
                checked={yuigonshonitsuite}
              />
              <ListSingleLineTwoColumnCheck
                onClick={() => navigate("/will/funeral")}
                label={getLabel("souginitsuite")}
                checked={souginitsuite}
              />
            </CategorySwapper>
            <CategorySwapper label={getLabel("lifepast")}>
              <ListSingleLineTwoColumnCheck
                onClick={() => navigate("/history/familytree")}
                label={getLabel("kakeizu")}
                checked={kakeizu}
              />
            </CategorySwapper>
          </div>
        </div>
      )}
    </div>
  );
}
