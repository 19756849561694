import { View } from '@aws-amplify/ui-react';
import { isMobile, isTablet } from "react-device-detect";

export default function MindTypeCheckStatus(props) {

   return (
       <View
           width="270px"
           height="37px"
           display="flex"
           position="relative"
           top={(isMobile || isTablet) ? '-132px' : '-46px'}
           left={(isMobile || isTablet) ? '190px' : '591px'}
       >
            <View
                width="25px"
                height="25px"
                margin="0 5px 0 0"
                position="relative"
                backgroundColor="rgba(104,134,73,1)"
            />
            <View
                width="25px"
                height="25px"
                margin="0 5px 0 0"
                position="relative"
                backgroundColor={ props.page >= 2 ? "rgba(104,134,73,1)" : "rgba(217,217,217,1)" }
            />
            <View
                width="25px"
                height="25px"
                margin="0 5px 0 0"
                position="relative"
                backgroundColor={ props.page >= 3 ? "rgba(104,134,73,1)" : "rgba(217,217,217,1)" }
            />
            <View
                width="25px"
                height="25px"
                margin="0 5px 0 0"
                position="relative"
                backgroundColor={ props.page >= 4 ? "rgba(104,134,73,1)" : "rgba(217,217,217,1)" }
            />
            <View
                width="25px"
                height="25px"
                margin="0 5px 0 0"
                position="relative"
                backgroundColor={ props.page >= 5 ? "rgba(104,134,73,1)" : "rgba(217,217,217,1)" }
            />
        </View>
    );
}
