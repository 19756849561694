import { DataStore } from "aws-amplify";
import { useContext, useEffect, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useParams } from "react-router-dom";
import { LeavesIryousochi, LeavesUsers, LeavesYuujinChijin } from "../models";
import { ListTwolineA, ListView } from "../page-components";
import UserContext from "../UserContext";
import {
  getLabel,
  isEmptyIryou,
  useInitializePage,
} from "../utils/CommonUtils";
import { isShare, queryData, queryDatas } from "../utils/ShareUtils";

function MedicalViewPage() {
  const navigate = useInitializePage("iryousochinitsuite", "title.detail");
  const [data, setData] = useState();
  const [
    ishikiganakunattarashirasetehoshiiyuujinchijin,
    setIshikiganakunattarashirasetehoshiiyuujinchijin,
  ] = useState();
  const [isEmptyData, setIsEmptyData] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const context = useContext(UserContext);
  const params = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const data = await queryData(params, LeavesIryousochi);
      if (data?.length > 0) {
        setData(data[0]);
        setIsEmptyData(isEmptyIryou(data[0]));
        if (!context.leavesUser.leavesUsersLeavesIryousochiId) {
          DataStore.save(
            LeavesUsers.copyOf(context.leavesUser, (updated) => {
              updated.leavesUsersLeavesIryousochiId = data[0].id;
            }),
          );
        }
      } else {
        const newData = await DataStore.save(
          new LeavesIryousochi({
            kokuchikibounoumu: "",
            enmeisochikibounoumu: "",
            zoukiteikyounoumu: "",
            handanwoyudanetaihito: "",
            bikou: "",
          }),
        );
        DataStore.save(
          LeavesUsers.copyOf(context.leavesUser, (updated) => {
            updated.leavesUsersLeavesIryousochiId = newData.id;
          }),
        );
      }
      if (data[0].ishikiganakunattarashirasetehoshiiyuujinchijin) {
        const friends = await queryDatas(params, LeavesYuujinChijin);
        setIshikiganakunattarashirasetehoshiiyuujinchijin(
          friends
            .filter(
              (f) =>
                data[0].ishikiganakunattarashirasetehoshiiyuujinchijin.indexOf(
                  f.id,
                ) >= 0,
            )
            .map((f) => f.shimei)
            .join("、"),
        );
      }
      setIsLoaded(true);
    };
    getData();
  }, [context.leavesUser, params]);

  const eventTracking = () => {
    if (isEmptyData) {
      window.gtag("event", "FirstWrite", {
        event_category: "Edit",
        event_label: "医療措置について書込開始",
      });
    } else {
      window.gtag("event", "Change", {
        event_category: "Edit",
        event_label: "医療措置について変更開始",
      });
    }
  };

  const listData = [
    { label: getLabel("kokuchikibounoumu"), value: data?.kokuchikibounoumu },
    {
      label: getLabel("enmeisochikibounoumu"),
      value: data?.enmeisochikibounoumu,
    },
    { label: getLabel("zoukiteikyounoumu"), value: data?.zoukiteikyounoumu },
    {
      label: getLabel("handanwoyudanetaihito"),
      value: data?.handanwoyudanetaihito,
    },
    {
      label: getLabel("ishikiganakunattarashirasetehoshiiyuujinchijin"),
      value: ishikiganakunattarashirasetehoshiiyuujinchijin,
    },
    { label: getLabel("bikou"), value: data?.bikou },
  ];

  return (
    <ListView
      label={getLabel("iryousochinitsuite")}
      description={{
        type: "will",
        category: "iryousochinitsuite",
      }}
      isLoaded={isLoaded}
      isShared={isShare(params)}
      isEmptyCategory={isEmptyData}
      onEdit={() => {
        navigate("edit");
        eventTracking();
      }}
      listMobile={listData.map((ele, index) => {
        return <ListTwolineA key={index} label={ele.label} value={ele.value} />;
      })}
      listPC={listData}
    />
  );
}

export default MedicalViewPage;
