import React, { useEffect, useState, useMemo } from "react";
import { confirmAlert } from "react-confirm-alert";
import { isMobile } from "react-device-detect";
import { useNavigate, useParams } from "react-router-dom";
import { LeavesShareInfo } from "../models";
import {
  LabelS,
  ListSelectContent,
  ListSingleLineA,
  ListSingleLineD,
  ListTwoLineB,
  ListTwoLineBShare,
  Text,
} from "../page-components";
import { Dialog } from "../ui-components";
import { getLabel } from "../utils/CommonUtils";
import { getServerDate, queryDatas } from "../utils/ShareUtils";

export default function SettingSharePage(props) {
  const navigate = useNavigate();
  const { title, modelName } = props;
  const [listInvite, setListInvite] = useState([]);
  const [selected, setSelected] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const params = useParams();

  const handledChangeActive = (item) => {
    setSelected(item);
  };

  useEffect(() => {
    if (listInvite?.length > 0) {
      setSelected(listInvite[0]);
    } else {
      setSelected(null);
    }
  }, [listInvite]);

  useEffect(() => {
    const getdata = async () => {
      const data = await queryDatas(params, LeavesShareInfo);
      const filterByCategory = [];
      const now = await getServerDate();
      data.forEach((element) => {
        const targetShare = element?.target.find(
          (item) => item.dataName === modelName,
        );
        filterByCategory.push({
          id: element.id,
          name: element?.name,
          publishDate: targetShare && targetShare?.shareDate,
          isPublished:
            targetShare &&
            new Date(
              targetShare?.shareDate.replaceAll("-", "/") + " 00:00:00",
            ) < now,
          isShare: targetShare ? true : false,
        });
      });
      setListInvite(
        filterByCategory.sort((x, y) => {
          return Number(y?.isShare) - Number(x?.isShare);
        }),
      );
      setIsLoaded(true);
    };
    getdata();
  }, [modelName, params]);

  const renderListInvite = useMemo(() => {
    let renderList = [];
    const listShare = listInvite
      ?.filter((ele) => ele?.isShare)
      ?.map((ele) =>
        isMobile ? (
          <ListTwoLineBShare
            key={ele?.id}
            label={ele?.name}
            value={
              `${getLabel("shareDate.suffix")}：` +
              ele.publishDate.replaceAll("-", "/")
            }
            isPublished={ele?.isPublished}
            isEnable
            onClick={() => {
              navigate(ele?.id);
            }}
          />
        ) : (
          <ListTwoLineB
            key={ele?.id}
            label={ele?.name}
            value={
              `${getLabel("shareDate.suffix")}：` +
              ele.publishDate.replaceAll("-", "/")
            }
            isSent={ele?.isPublished}
            selected={selected?.id === ele?.id}
            onClick={() => {
              handledChangeActive(ele);
            }}
          />
        ),
      );
    const listNotShare = listInvite
      ?.filter((ele) => !ele?.isShare)
      ?.map((ele) =>
        isMobile ? (
          <ListSingleLineA
            key={ele?.id}
            onClick={() => navigate(ele?.id)}
            label={ele?.name}
          />
        ) : (
          <ListSingleLineD
            key={ele?.id}
            label={ele?.name}
            selected={selected?.id === ele?.id}
            onClick={() => {
              handledChangeActive(ele);
            }}
          />
        ),
      );
    if(!listShare?.length && !listNotShare?.length) return renderList;
    renderList.push(
      <LabelS
        label={getLabel(isMobile ? "share.enableLabel" : "share.enableLabelPC")}
        marginTop="0"
        marginBottom="0"
      />,
    );
    if (listShare?.length === 0) {
      renderList.push(
        <Text value={getLabel("shareEnable.des")} margin={isMobile && "16px 0"} fontSize={isMobile ? "14px" : "16px"} />,
      );
    }
    renderList = renderList.concat(listShare);
    renderList.push(
      <LabelS
        label={getLabel("share.disableLabel")}
        marginTop="0"
        marginBottom="0"
      />,
    );
    if (listNotShare?.length === 0) {
      renderList.push(<Text value={getLabel("shareDisable.des")} margin={isMobile && "16px 0"} fontSize={isMobile ? "14px" : "16px"} />);
    }
    renderList = renderList.concat(listNotShare);
    return renderList;
  }, [listInvite, navigate, selected?.id]);

  return (
    <ListSelectContent
      label={getLabel(title) + getLabel("button.sharesuffix")}
      description={{
        subDescription: getLabel("share.categoryDes"),
      }}
      style={{ maxHeight: "calc(100vh - 455px)", marginTop: "0" }}
      descriptionStyle={{ width: "100%", paddingBottom: "0" }}
      mobileList={renderListInvite}
      addButtonLabel={getLabel("kazokuoshotaisuru")}
      onAddClick={() => {
        confirmAlert({
          overlayClassName: "react-confirm-alert-overlay-custom",
          customUI: ({ onClose }) => {
            return (
              <div className="custom-ui">
                <Dialog
                  width={isMobile ? "295px" : "512px"}
                  overrides={{
                    "Flex.Flex[0]": {
                      className: "width100",
                    },
                    "Flex.Flex[0].Text[0]": {
                      children: getLabel("share.dialogTitle"),
                      className: "width100",
                    },
                    "Flex.Flex[0].Text[1]": {
                      color: "black",
                      padding: "0 15px",
                      children: getLabel("share.dialogConfirm"),
                      className: "width100",
                    },
                    "Flex.Flex[1]":{
                      className: "width100",
                    },
                    "Flex.Flex[1].DefaultButton[0]": {
                      className: "pointer",
                      onClick: () => onClose(),
                      overrides: {
                        "Flex.Button[0]": {
                          padding: "7px 25px",
                          children: getLabel("atodesuru"),
                        },
                      },
                    },
                    "Flex.Flex[1].PrimaryButton[0]": {
                      className: "pointer",
                      padding: "7px 25px",
                      onClick: () => {
                        navigate("/other/invite/add", { state: modelName });
                        onClose();
                      },
                      overrides: {
                        "Flex.Text[0]": {
                          children: getLabel("shotaisuru"),
                        },
                      },
                    },
                  }}
                />
              </div>
            );
          },
        });
      }}
      selectedItemLabel={selected?.name}
      pcListItem={renderListInvite}
      selectedItemList={[
        {
          label: getLabel(title) + getLabel("active.sharesuffix"),
          value: selected?.isShare
            ? getLabel("kyoyusuru")
            : getLabel("kyoyushinai"),
        },
        {
          isEmptyHide: true,
          label: getLabel("pejikokaibi"),
          value: selected?.publishDate,
          type: "date",
        },
      ]}
      isHideBtnDelete
      onEdit={() => {
        navigate(selected?.id);
      }}
      isLoaded={isLoaded}
      isInvitePage
    />
  );
}
