import { DataStore } from "aws-amplify";
import { useCallback, useEffect, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useParams } from "react-router-dom";
import { LeavesSumahoPc } from "../models";
import { ListViewDetail } from "../page-components";
import { useInitializePage } from "../utils/CommonUtils";
import { isShare, queryData } from "../utils/ShareUtils";

function DevivesViewPage() {
  const navigate = useInitializePage("sumahoyapcnitsuite", "title.detail");
  const [deviceData, setDeviceData] = useState({
    kikishubetsu: "",
    keiyakusaki: "",
    denwabangou: "",
    meiginin: "",
    kishumei: "",
    bikou: "",
  });
  const params = useParams();
  const deleteData = useCallback(async () => {
    await DataStore.delete(deviceData);
    navigate(-1, { replace: true });
  }, [deviceData, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const data = await queryData(params, LeavesSumahoPc);
      if (data?.length > 0) {
        setDeviceData(data[0]);
      }
    };
    getData();
  }, [params.id]);

  const listLabel = [
    { label: "kikishubetsu", value: deviceData?.kikishubetsu, isRequire: true },
    { label: "keiyakusaki", value: deviceData?.keiyakusaki },
    { label: "denwabangou", value: deviceData?.denwabangou },
    { label: "meiginin", value: deviceData?.meiginin },
    { label: "kishumei", value: deviceData?.kishumei },
    { label: "bikou", value: deviceData?.bikou, isMulti: true },
  ];

  return (
    <ListViewDetail
      label={deviceData?.kikishubetsu}
      list={listLabel}
      isHideEdit={isShare(params)}
      onDelete={deleteData}
      onEdit={() => {
        navigate("edit");
        window.gtag("event", "Change", {
          event_category: "Edit",
          event_label: "スマホやPCについて変更開始",
        });
      }}
    />
  );
}

export default DevivesViewPage;
