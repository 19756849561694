import { Flex, Icon, Image, Text } from "@aws-amplify/ui-react";
import React, { useMemo } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Dialog, PCListSingleLineAExpandLess, PrimaryButtonIcon } from "../ui-components";
import { formatDateLabel, getLabel, isEmpty } from "../utils/CommonUtils";
import { isMobile } from "react-device-detect";

export default function ListSingleLineDExpand(props) {
  const overridesProp = useMemo(() => {
    const propsList = {
      "Flex.Divider[0]": {
        display: props.isChallengeCate && !props.hideButton ? "block" : "none",
        as: "div",
        border: "none",
        opacity: 1,
        children: (
          <PrimaryButtonIcon
            className={props?.isAchieved ? "" : "pointer"}
            margin="auto"
            width="204px"
            padding="0"
            onClick={props?.onAchieve}
            backgroundColor={props?.isAchieved ? "#EFF0F0" : "#688649"}
            overrides={{
              "Flex.Flex[0].Text[0]": {
                children: (
                  <Flex gap="0" padding="10px">
                    <Icon width="20px" height="20px" marginTop="2px">
                      <path fill={props?.isAchieved ? "#5C6670" : "white"} d="M17.1 17.5H19.5V19.5H13.5V13.5H15.5V16.23C17.33 14.76 18.5 12.52 18.5 10C18.5 5.93005 15.44 2.56005 11.5 2.07005V0.0500488C16.55 0.550049 20.5 4.81005 20.5 10C20.5 12.99 19.18 15.67 17.1 17.5ZM2.5 10C2.5 7.48005 3.67 5.23005 5.5 3.77005V6.50005H7.5V0.500049H1.5V2.50005H3.9C1.82 4.33005 0.5 7.01005 0.5 10C0.5 15.19 4.45 19.4501 9.5 19.9501V17.93C5.56 17.4401 2.5 14.07 2.5 10ZM14.74 6.11005L9.08 11.77L6.25 8.94005L4.84 10.35L9.08 14.59L16.15 7.52005L14.74 6.11005Z" />
                    </Icon>
                    <Text marginLeft="8px" fontSize="16px" color={props?.isAchieved  ? "#5C6670" : "white"}>
                      {getLabel("bucketlist.archive.status")}
                    </Text>
                  </Flex>
                ),
              },
              "Flex.Flex[0].IconAdd[0]": {
                display: "none",
              },
            }}
          />
        ),
      },
      "Flex.Flex[0].Flex[0].Icon[0]": {
        display: "none",
      },
      "Flex.Flex[0].Flex[0]": {
        justifyContent: "initial",
      },
      "Flex.Flex[0].Flex[0].Text[0]": {
        children: props?.label,
        className: "text-overflow-ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
        display: "block",
      },
      "Flex.PCButtonDeleteEdit[0]": {
        display: props.hideButton ? "none" : "flex",
        overrides: {
          "Flex.Flex[0]": {
            className: "pointer",
            display: props?.isHideBtnDelete ? "none" : "flex",
            onClick: props.isCustomDelete ? props?.deleteOnClick : () => {
              confirmAlert({
                overlayClassName: "react-confirm-alert-overlay-custom",
                customUI: ({ onClose }) => {
                  return (
                    <div className="custom-ui">
                      <Dialog
                        width={isMobile ? "295px" : "512px"}
                        overrides={{
                          "Flex.Flex[0]": {
                            className: "width100",
                          },
                          "Flex.Flex[0].Text[0]": {
                            children: getLabel("dialog.delete.title"),
                            className: "width100",
                          },
                          "Flex.Flex[0].Text[1]": {
                            children: getLabel("dialog.delete.body"),
                            className: "width100",
                          },
                          "Flex.Flex[1]":{
                            className: "width100",
                          },
                          "Flex.Flex[1].DefaultButton[0]": {
                            className: "pointer",
                            onClick: () => onClose(),
                            overrides: {
                              "Flex.Button[0]": {
                                children: getLabel("dialog.cancel"),
                              },
                            },
                          },
                          "Flex.Flex[1].PrimaryButton[0]": {
                            className: "pointer",
                            onClick: async () => {
                              await props?.deleteOnClick();
                              onClose();
                            },
                            overrides: {
                              "Flex.Text[0]": {
                                children: getLabel("dialog.delete"),
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  );
                },
              });
            },
          },
          "Flex.PCPrimaryButtonSave[0]": {
            display: props.hideButton ? "none" : "flex",
            className: props.isChallengeCate
              ? "pointer challenge-edit"
              : "pointer",
            backgroundColor: props.isChallengeCate ? "transparent" : "#688649",
            border: props.isChallengeCate ? "1px solid rgb(174, 179, 183)" : "",
            onClick: props?.editOnClick,
          },
        },
      },
    };
    for (let i = 0; i < 15; i++) {
      const key = "Flex.Flex[0].Flex[" + (i + 1) + "]";
      const isExistedItem = props?.list && props?.list[i];
      const isEmptyValue = !isExistedItem || isEmpty(props?.list[i]?.value);

      if (isEmptyValue) {
        propsList[key] = {
          display: "none",
        };
      } else {
        let formatedData = props?.list[i]?.value;
        if (props.list[i]?.type === "date") {
          formatedData = formatDateLabel(formatedData);
        }

        propsList[key + ".Text[0]"] = {
          children: props?.list[i]?.label,
        };
        propsList[key + ".Text[1]"] = {
          children: formatedData,
          textAlign: "justify",
          className: "word-break",
          maxWidth: "100%",
          width: "fit-content",
        };
      }
    }
    return propsList;
  }, [props]);

  return (
    <Flex maxWidth="576px" width="100%" overflow="auto">
      <PCListSingleLineAExpandLess
        width="100%"
        justifyContent="flex-start"
        padding="0px 16px"
        overrides={overridesProp}
      />
    </Flex>
  );
}
