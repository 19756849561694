/* eslint-disable import/no-anonymous-default-export */
import { DataStore } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import { emailRegex } from "../constants";
import { LeavesKazokuShinzoku } from "../models";
import {
  ButtonCancelSave,
  SwapperContent,
  TextField,
  TextFieldMultiline,
} from "../page-components";
import {
  getLabel,
  isEmpty,
  isInvalidDateFormat,
  isNotEmpty,
  isNotEmptyDate,
  useInitializePage,
} from "../utils/CommonUtils";
import { log } from "../utils/Logger";
import { usePrompt } from "../utils/Prompt";

/*
  nengetsu: AWSDate!
  eventmei: String!
*/
export default function () {
  const navigate = useInitializePage("kazokushinzokuichiran", "title.update");
  const [data, setData] = useState();
  // let shisan;

  const [shimei, setShimei] = useState("");
  const [zokugara, setZokugara] = useState("");
  const [seinengappi, setSeinengappi] = useState("");
  const [yuubinbangou, setYuubinbangou] = useState("");
  const [juusho, setJuusho] = useState("");
  const [denwabangou, setDenwabangou] = useState("");
  const [mailaddress, setMailaddress] = useState("");
  const [bikou, setBikou] = useState("");

  const [shimeiError, setShimeiError] = useState(null);
  const [zokugaraError, setZokugaraError] = useState(null);
  const [seinengappiError, setSeinengappiError] = useState(null);
  const [yuubinbangouError, setYuubinbangouError] = useState(null);
  const [juushoError, setJuushoError] = useState(null);
  const [denwabangouError, setDenwabangouError] = useState(null);
  const [mailaddressError, setMailaddressError] = useState(null);
  const [bikouError, setBikouError] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);

  const [update, setUpdate] = useState(false);
  usePrompt((location, action) => {
    if (action == "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  let params = useParams();

  useEffect(async () => {
    const dsData = await DataStore.query(LeavesKazokuShinzoku, (c) =>
      c.id("eq", params.id),
    );
    log.debug("dsData", dsData);
    if (dsData.length > 0) {
      setData(dsData[0]);
      setShimei(dsData[0].shimei);
      setZokugara(dsData[0].zokugara);
      setSeinengappi(dsData[0].seinengappi);
      setYuubinbangou(dsData[0].yuubinbangou);
      setJuusho(dsData[0].juusho);
      setDenwabangou(dsData[0].denwabangou);
      setMailaddress(dsData[0].mailaddress);
      setBikou(dsData[0].bikou);
    }
  }, []);

  const updateData = async () => {
    log.debug(
      shimei +
        "," +
        zokugara +
        "," +
        seinengappi +
        "," +
        yuubinbangou +
        "," +
        juusho +
        "," +
        denwabangou +
        "," +
        mailaddress +
        "," +
        bikou,
    );
    let valid = true;
    if (isEmpty(shimei)) {
      setShimeiError(getLabel("error.required"));
      valid = false;
    } else {
      setShimeiError(null);
    }
    if (isEmpty(zokugara)) {
      setZokugaraError(getLabel("error.required"));
      valid = false;
    } else {
      setZokugaraError(null);
    }
    const dateFormatCheck = isInvalidDateFormat(seinengappi);
    if (dateFormatCheck) {
      setSeinengappiError(getLabel("error.dateformat"));
      valid = false;
    } else {
      setSeinengappiError(null);
    }
    if (isNotEmpty(yuubinbangou)) {
      if (!/^\d{3}[-]{0,1}\d{4}$/.test(yuubinbangou)) {
        setYuubinbangouError(getLabel("error.postcode"));
        valid = false;
      } else {
        setYuubinbangouError(null);
      }
    }
    if (isNotEmpty(denwabangou)) {
      if (!/^[0-9-]{4,13}$/.test(denwabangou)) {
        setDenwabangouError(getLabel("error.telno"));
        valid = false;
      } else {
        setDenwabangouError(null);
      }
    }
    const checkEmail = isNotEmpty(mailaddress) && !emailRegex.test(mailaddress);
    if (checkEmail) {
      setMailaddressError(getLabel("error.emailformat"));
      valid = false;
    } else {
      setMailaddressError(null);
    }

    if (valid) {
      setIsUpdated(true);
      DataStore.save(
        LeavesKazokuShinzoku.copyOf(data, (updated) => {
          updated.shimei = shimei;
          updated.zokugara = zokugara;
          updated.seinengappi = isNotEmptyDate(seinengappi)
            ? seinengappi
            : null;
          updated.yuubinbangou = yuubinbangou;
          updated.juusho = juusho;
          updated.denwabangou = denwabangou;
          updated.mailaddress = mailaddress;
          updated.bikou = bikou;
        }),
      );
      window.gtag("event", "ChangeComplete", {
        event_category: "Edit",
        event_label: "家族・親族変更完了",
      });
      navigate(-1, { replace: true });
    }
  };

  return (
    <SwapperContent label={getLabel("button.update")}>
      <TextField
        isRequired
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("shimei")}
        value={shimei}
        errorMessage={shimeiError}
        onChange={(event) => {
          setShimei(event.target.value);
          setUpdate(true);
        }}
      />
      <TextField
        isRequired
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("zokugara")}
        value={zokugara}
        errorMessage={zokugaraError}
        onChange={(event) => {
          setZokugara(event.target.value);
          setUpdate(true);
        }}
      />
      <TextField
        className={isMobile ? "" : "maxwidth800"}
        type="date"
        label={getLabel("seinengappi")}
        value={seinengappi}
        errorMessage={seinengappiError}
        onChange={(event) => {
          setSeinengappi(event.target.value);
          setUpdate(true);
        }}
      />
      <TextField
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("yuubinbangou")}
        value={yuubinbangou}
        errorMessage={yuubinbangouError}
        onChange={(event) => {
          setYuubinbangou(event.target.value);
          setUpdate(true);
        }}
      />
      <TextField
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("juusho")}
        value={juusho}
        errorMessage={juushoError}
        onChange={(event) => {
          setJuusho(event.target.value);
          setUpdate(true);
        }}
      />
      <TextField
        className={isMobile ? "" : "maxwidth800"}
        type="tel"
        label={getLabel("denwabangou")}
        value={denwabangou}
        errorMessage={denwabangouError}
        onChange={(event) => {
          setDenwabangou(event.target.value);
          setUpdate(true);
        }}
      />
      <TextField
        className={isMobile ? "" : "maxwidth800"}
        type="email"
        label={getLabel("mailaddress")}
        value={mailaddress}
        errorMessage={mailaddressError}
        placeholder={getLabel("nyuuryokumiss")}
        onChange={(event) => {
          setMailaddress(event.target.value);
          setUpdate(true);
        }}
      />
      <TextFieldMultiline
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("bikou")}
        value={bikou}
        errorMessage={bikouError}
        placeholder={getLabel("kazokugashiranaiepisode")}
        onChange={(event) => {
          setBikou(event.target.value);
          setUpdate(true);
        }}
      />

      <ButtonCancelSave
        cancelOnClick={() => navigate(-1, { replace: true })}
        saveOnClick={() => updateData()}
        isDisabled={isUpdated}
      />
    </SwapperContent>
  );
}
