import { LabelM, ListSingleLineA } from "../page-components";
import { getLabel, useInitializePage } from "../utils/CommonUtils";

function ContactPage() {
  const navigate = useInitializePage("renrakusakikanren");

  return (
    <div>
      <LabelM label={getLabel("renrakusakikanren")} />
      <ListSingleLineA onClick={() => navigate("family")} label={getLabel("kazokushinzokuichiran")} />
      <ListSingleLineA onClick={() => navigate("friends")} label={getLabel("yuujinchijin")} />
    </div>
  );
}

export default ContactPage;
