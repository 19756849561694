import { Flex, Image, Text } from "@aws-amplify/ui-react";
import { TextButton } from "../ui-components";
import emptyTodoImage from "../assets/image/image_holder.png";
import { useRef, useState, useEffect } from "react";
import { getFileName, getLabel, isImage } from "../utils/CommonUtils";
import { isMobile } from "react-device-detect";
import { Storage } from "aws-amplify";

export default function ImageSelect(props) {
  const [filename, setFilename] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const hiddenFileInput = useRef(null);

  useEffect(() => {
    const getImageInit = async () => {
      if (props.objectSrc != null) {
        const inFilename = getFileName(props.objectSrc);
        const url = await Storage.get(props.objectSrc, {
          level: "private",
        });
        if (isImage(inFilename)) {
          setPreviewUrl(url);
        }
        setFilename(inFilename);
      }
    };
    getImageInit();
  }, [props.objectSrc]);

  useEffect(() => {
    if (props.defaultValue) {
      uploadFile(props.defaultValue);
    }
  }, [props.defaultValue]);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const uploadFile = async (file) => {
    let isError = false;
    if (isImage(file.name)) {
      if (file.size > 10485760) {
        setErrorMessage(getLabel("error.filesize"));
        isError = true;
      } else {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewUrl(reader.result);
        };
        reader.readAsDataURL(file);
        setErrorMessage("");
      }
    } else {
      setErrorMessage(getLabel("error.onlyImage"));
      isError = true;
    }

    if (!isError) {
      if (props.onChange) {
        props.onChange({
          target: {
            value: file,
          },
        });
      }
      setFilename(file.name);
    } else {
      if (props.onChange) {
        props.onChange({
          target: {
            value: null,
          },
        });
      }
      setPreviewUrl(null);
      setFilename(null);
    }
  };

  const removeSelectedFile = (event) => {
    setPreviewUrl(null);
    setFilename(null);
    setErrorMessage("");

    hiddenFileInput.current.value = "";

    if (props.onChange) {
      props.onChange({
        target: {
          value: null,
        },
      });
    }
  };

  return (
    <>
      {isMobile ? (
        <Flex gap="0" direction="column" padding="16px 16px 0px">
          <Text
            fontSize="14px"
            color="var(--amplify-components-field-label-color)"
          >
            {props.label}
          </Text>
        </Flex>
      ) : (
        <Flex
          className={props.className ? props.className : "pccomponent"}
          gap="0"
          direction="column"
          margin="0px auto 16px"
        >
          <Text
            fontSize="16px"
            color="var(--amplify-components-field-label-color)"
          >
            {props.label}
          </Text>
        </Flex>
      )}
      <Flex
        marginTop="12px"
        textAlign="center"
        direction="column"
        alignItems="center"
        gap="0"
      >
        <Image
          src={previewUrl ? previewUrl : emptyTodoImage}
          width={isMobile ? "72px" : "100px"}
          height={isMobile ? "72px" : "100px"}
          overflow="hidden"
          objectFit="cover"
        />
        {errorMessage && (
          <Text className="amplify-text amplify-field__error-message">
            {errorMessage}
          </Text>
        )}
        <Flex>
          <TextButton
            className="pointer"
            onClick={removeSelectedFile}
            display={previewUrl ? "block" : "none"}
            overrides={{
              "Flex.Text[0]": {
                color: "red",
                children: getLabel("filewosakujo"),
              },
            }}
          />
          <TextButton
            className="pointer"
            onClick={handleClick}
            overrides={{
              "Flex.Text[0]": {
                children: getLabel("filewosentaku"),
              },
            }}
          />
        </Flex>
      </Flex>
      <input
        ref={hiddenFileInput}
        hidden
        type="file"
        onChange={(e) => {
          uploadFile(e.target.files[0]);
        }}
      />
    </>
  );
}
