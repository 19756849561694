/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Image, Text } from "@aws-amplify/ui-react";
export default function CardCheck(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="22px"
      direction="row"
      width="325px"
      height="120px"
      justifyContent="center"
      alignItems="center"
      position="relative"
      borderRadius="3px"
      padding="0px 0px 0px 26px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "CardCheck")}
    >
      <Flex
        gap="10px"
        direction="row"
        width="fit-content"
        alignItems="flex-start"
        shrink="0"
        height="60px"
        position="relative"
        padding="9px 9px 9px 9px"
        {...getOverrideProps(overrides, "Icon")}
      >
        <Image
          width="42px"
          height="42px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "check_cardIC 1")}
        ></Image>
      </Flex>
      <Flex
        gap="2px"
        direction="column"
        width="217px"
        shrink="0"
        height="120px"
        position="relative"
        padding="25px 0px 30px 0px"
        {...getOverrideProps(overrides, "Text")}
      >
        <Text
          fontFamily="Zen Kaku Gothic New"
          fontSize="15px"
          fontWeight="500"
          color="rgba(28,26,29,1)"
          lineHeight="30px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="これだけやっておけば安心"
          {...getOverrideProps(
            overrides,
            "\u3053\u308C\u3060\u3051\u3084\u3063\u3066\u304A\u3051\u3070\u5B89\u5FC3"
          )}
        ></Text>
        <Text
          fontFamily="Zen Kaku Gothic New"
          fontSize="27px"
          fontWeight="500"
          color="rgba(28,26,29,1)"
          lineHeight="33px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="CHECK!"
          {...getOverrideProps(overrides, "CHECK!")}
        ></Text>
      </Flex>
    </Flex>
  );
}
