import { LabelM, ListSingleLineA } from "../page-components";
import { getLabel, useInitializePage } from "../utils/CommonUtils";

function DigitalPage() {
  const navigate = useInitializePage("digitalkanren");

  return (
    <div>
      <LabelM label={getLabel("digitalkanren")} />
      <ListSingleLineA
        onClick={() => navigate("devices")}
        label={getLabel("sumahoyapcnitsuite")}
      />
      <ListSingleLineA
        onClick={() => navigate("loginID")}
        label={getLabel("kakushuserviceid")}
      />
    </div>
  );
}

export default DigitalPage;
