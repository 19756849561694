import { DataStore } from "aws-amplify";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LeavesKinyuuShisan } from "../models";
import { FABDeleteEdit, LabelM, ListTwolineA } from "../page-components";
import { getLabel, useInitializePage } from "../utils/CommonUtils";
import { log } from "../utils/Logger";
import { isShare, queryData } from "../utils/ShareUtils";

/*
  shisanmei: String
  shisanshurui: KinyuuShisanshurui
  kinyuukikan: String
  bikou: String
*/
export default function () {
  const navigate = useInitializePage("kinyuushisan", "title.detail");
  const [shisan, setShisan] = useState();

  let params = useParams();

  useEffect(async () => {
    const shisan = await queryData(params, LeavesKinyuuShisan);
    log.debug("shisan", shisan);
    if (shisan.length > 0) {
      setShisan(shisan[0]);
    }
  }, []);

  const deleteData = async () => {
    log.debug("delete data", shisan);
    await DataStore.delete(shisan);
    navigate(-1, { replace: true });
  };
  return (
    <div>
      <LabelM label={shisan?.kinyuukikan} />
      <ListTwolineA label={getLabel("shisanshurui")} value={shisan ? getLabel("KinyuuShisanshurui." + shisan?.shisanshurui) : ""} />
      <ListTwolineA isEmptyHide label={getLabel("kinyuukikan")} value={shisan?.kinyuukikan} />
      <ListTwolineA isEmptyHide label={getLabel("bikou")} value={shisan?.bikou} />
      {isShare(params) ? null : (
        <FABDeleteEdit
          onDelete={() => deleteData()}
          onEdit={() => {
            navigate("edit");
            window.gtag("event", "Change", {
              event_category: "Edit",
              event_label: "金融資産変更開始",
            });
          }}
        />
      )}
    </div>
  );
}
