import { Flex } from "@aws-amplify/ui-react";
import { DataStore } from "aws-amplify";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { LeavesDaijinishitaikinenbi } from "../models";
import { ListField, SubText } from "../page-components";
import { getLabel, isEmpty, isEmptyDate, isInvalidDateFormat, isNotEmptyDate, useInitializePage } from "../utils/CommonUtils";
import { KONOHIGACHIKAZUITARATSUUCHI, useDatalist } from "../utils/DatalistUtils";
import { usePrompt } from "../utils/Prompt";
import { log } from "../utils/Logger";

function AnniversaryEditPage() {
  const navigate = useInitializePage("daijinishitaikinenbi", "title.update");
  const data = useRef();
  const [tsuuchisakiId, setTsuuchisakiID] = useState(null);
  const [dataAnniversary, setDataAnniversary] = useState({
    kinenbimei: "",
    hiduke: "",
    tsuuchisaki: {},
    bikou: "",
  });
  const [dataAnniversaryError, setDataAnniversaryError] = useState({
    kinenbimeiError: "",
    hidukeError: "",
  });
  const [isUpdated, setIsUpdated] = useState(false);
  const params = useParams();
  const [update, setUpdate] = useState(false);
  const [KonohigachikazuitaratsuuchiDataList, setKonohigachikazuitaratsuuchi, getKonohigachikazuitaratsuuchi, hasErrorKonohigachikazuitaratsuuchi] =
    useDatalist({
      list: KONOHIGACHIKAZUITARATSUUCHI,
      dataUpdateHook: setUpdate,
      type: "NANNICHIMAEKASHITEI",
    });

  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const dataQuery = await DataStore.query(LeavesDaijinishitaikinenbi, (c) => c.id("eq", params.id));
      if (dataQuery?.length > 0) {
        data.current = dataQuery[0];
        setKonohigachikazuitaratsuuchi(dataQuery[0].konohigachikazuitaratsuuchi);
        setDataAnniversary({
          ...dataQuery[0],
          tsuuchisaki: {
            family: dataQuery[0]?.tsuuchisakiKazokuShinzoku,
            friend: dataQuery[0]?.tsuuchisakiYuujinChijin,
          },
        });
      }
    };
    getData();
  }, [params.id]);

  const listField = [
    {
      type: "textField",
      label: "kinenbimei",
      isRequire: true,
      placeHolder: "kekkonkinenbinado",
      value: dataAnniversary.kinenbimei,
      errorMessage: dataAnniversaryError.kinenbimeiError,
    },
    {
      type: "textField",
      label: "hiduke",
      isRequire: true,
      typeInput: "date",
      value: dataAnniversary.hiduke,
      errorMessage: dataAnniversaryError.hidukeError,
    },
    {
      type: "dataList",
      child: (
        <Flex display="block">
          <KonohigachikazuitaratsuuchiDataList label="konohigachikazuitaratsuuchi" />
          {/* <SubText text={getLabel("konohigachikazuitaratsuuchi.subtitle")} /> */}
          <SubText text="※未選択の場合は通知がされません。それ以外を選択した場合は、自分と設定した通知先に毎年通知されます" />
        </Flex>
      ),
    },
    {
      type: "familyAndFriendList",
      label: "tsuuchisaki",
      listFriend: dataAnniversary?.tsuuchisaki?.friend,
      listFamily: dataAnniversary?.tsuuchisaki?.family,
    },
    {
      type: "textArea",
      label: "bikou",
      subLabel: ".anniversary",
      placeHolder: "kasaburankawoyouisurukotonado",
      value: dataAnniversary.bikou,
    },
  ];

  const autoSave = useCallback(() => {
    let valid = true;
    const dateFormatCheck = isInvalidDateFormat(dataAnniversary.hiduke);
    if (hasErrorKonohigachikazuitaratsuuchi()) valid = false;
    if (isEmpty(dataAnniversary.kinenbimei) || dateFormatCheck) {
      setDataAnniversaryError({
        ...dataAnniversaryError,
        kinenbimeiError: isEmpty(dataAnniversary.kinenbimei) ? getLabel("error.required") : "",
        hidukeError: isEmptyDate(dataAnniversary.hiduke) ? getLabel("error.required") : dateFormatCheck ? getLabel("error.dateformat") : "",
      });
      valid = false;
    }
    if (valid) {
      console.log("save dataAnniversary", dataAnniversary);
      DataStore.save(
        LeavesDaijinishitaikinenbi.copyOf(data.current, (updated) => {
          updated.kinenbimei = dataAnniversary.kinenbimei;
          updated.hiduke = dataAnniversary.hiduke ? dataAnniversary.hiduke : null;
          updated.konohigachikazuitaratsuuchi = getKonohigachikazuitaratsuuchi();
          updated.tsuuchisakiKazokuShinzoku = dataAnniversary?.tsuuchisaki?.family;

          updated.tsuuchisakiYuujinChijin = dataAnniversary?.tsuuchisaki?.friend;

          updated.bikou = dataAnniversary.bikou;
        })
      );
      return true;
    } else {
      return false;
    }
  }, [
    dataAnniversary.bikou,
    dataAnniversary.hiduke,
    dataAnniversary.kinenbimei,
    dataAnniversary?.tsuuchisaki?.family,
    dataAnniversary?.tsuuchisaki?.friend,
    dataAnniversaryError,
    getKonohigachikazuitaratsuuchi,
    hasErrorKonohigachikazuitaratsuuchi,
    navigate,
  ]);

  const updateDataAnniversary = useCallback(async () => {
    let valid = true;
    const dateFormatCheck = isInvalidDateFormat(dataAnniversary.hiduke);
    if (hasErrorKonohigachikazuitaratsuuchi()) valid = false;
    if (isEmpty(dataAnniversary.kinenbimei) || isEmptyDate(dataAnniversary.hiduke) || dateFormatCheck) {
      setDataAnniversaryError({
        ...dataAnniversaryError,
        kinenbimeiError: isEmpty(dataAnniversary.kinenbimei) ? getLabel("error.required") : "",
        hidukeError: isEmptyDate(dataAnniversary.hiduke) ? getLabel("error.required") : dateFormatCheck ? getLabel("error.dateformat") : "",
      });
      valid = false;
    }
    if (valid) {
      setIsUpdated(true);
      try {
        await DataStore.save(
          LeavesDaijinishitaikinenbi.copyOf(data.current, (updated) => {
            updated.kinenbimei = dataAnniversary.kinenbimei;
            updated.hiduke = dataAnniversary.hiduke;
            updated.konohigachikazuitaratsuuchi = getKonohigachikazuitaratsuuchi();
            updated.tsuuchisakiKazokuShinzoku = dataAnniversary?.tsuuchisaki?.family;

            updated.tsuuchisakiYuujinChijin = dataAnniversary?.tsuuchisaki?.friend;

            updated.bikou = dataAnniversary.bikou;
          })
        );
        window.gtag("event", "ChangeComplete", {
          event_category: "Edit",
          event_label: "大事にしたい日変更完了",
        });
        log.debug("anniversary update success", dataAnniversary);
        navigate(-1, { replace: true });
      } catch (e) {
        setIsUpdated(false);
        log.debug("anniversary update error", e);
      }
    }
  }, [
    dataAnniversary.bikou,
    dataAnniversary.hiduke,
    dataAnniversary.kinenbimei,
    dataAnniversary?.tsuuchisaki?.family,
    dataAnniversary?.tsuuchisaki?.friend,
    dataAnniversaryError,
    getKonohigachikazuitaratsuuchi,
    hasErrorKonohigachikazuitaratsuuchi,
    navigate,
  ]);

  return (
    <ListField
      label={getLabel("button.update")}
      list={listField}
      setState={setDataAnniversary}
      setUpdate={setUpdate}
      onSave={updateDataAnniversary}
      onCancel={() => navigate(-1, { replace: true })}
      isDisabled={isUpdated}
      autoSave={autoSave}
    />
  );
}

export default AnniversaryEditPage;
