import { PCListSingleLineCEdit } from "../ui-components";
import { formatDateLabel } from "../utils/CommonUtils";

export default function ListSingleLineCEdit(props) {
  const overridesProp = {
    "Flex.Divider[0]": {
      display: "none",
    },
    "Flex.PCPrimaryButtonSave[0]": {
      className: "pointer",
      onClick: props.editOnClick,
      display: props.isHideEdit ? "none" : "flex",
    },
    ...props.overrides,
  };
  for (let i = 0; i < 15; i++) {
    const key = "Flex.Flex[0].Flex[" + i + "]";
    const isExistedItem = props.list && props.list[i];

    if (isExistedItem) {
      let formatedData = props.list[i].value;
      if (props.list[i].type === "date") {
        formatedData = formatDateLabel(formatedData);
      }

      overridesProp[key + ".Text[0]"] = {
        children: props.list[i].label,
      };
      overridesProp[key + ".Text[1]"] = {
        children: formatedData,
        textAlign: "justify",
        className: "word-break",
        width: "fit-content",
        maxWidth: "100vw",
      };
    } else {
      overridesProp[key] = {
        display: "none",
      };
    }
  }
  return (
    <PCListSingleLineCEdit
      className="pccomponent"
      paddingTop="0px"
      justifyContent="flex-start"
      overrides={overridesProp}
    />
  );
}
