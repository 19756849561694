import { DataStore } from "aws-amplify";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LeavesNenkin } from "../models";
import {
  ListSelectContent,
  ListSingleLineA,
  ListSingleLineD,
} from "../page-components";
import {
  formatNumber,
  getLabel,
  useInitializePage,
} from "../utils/CommonUtils";
import { isShare, queryDatas } from "../utils/ShareUtils";

function PensionPage() {
  const navigate = useInitializePage("nenkin");
  const [listPension, setListPension] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selected, setSelected] = useState(null);
  const params = useParams();

  const handledChangeActive = (item) => {
    setSelected(item);
  };

  useEffect(() => {
    if (listPension?.length > 0) {
      setSelected(listPension[0]);
    } else {
      setSelected(null);
    }
  }, [listPension]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const modelData = await queryDatas(params, LeavesNenkin);
      if (modelData?.length > 0) {
        setListPension(modelData);
      }
      setIsLoaded(true);
    };

    getData();
  }, []);

  const deleteData = async (data) => {
    await DataStore.delete(data);
    const newList = await DataStore.query(LeavesNenkin);
    setListPension(newList);
  };

  return (
    <ListSelectContent
      label={getLabel("nenkin")}
      mobileList={listPension.map((data, index) => {
        return (
          <ListSingleLineA
            key={data?.id}
            label={data?.nenkinmei}
            onClick={() => navigate(data?.id)}
          />
        );
      })}
      description={{
        type: "data",
        category: "nenkin",
      }}
      isShared={isShare(params)}
      addButtonLabel={getLabel("nenkin") + getLabel("button.addsuffix")}
      onAddClick={() => {
        navigate("add");
        window.gtag("event", "FirstWrite", {
          event_category: "Edit",
          event_label: "年金書込開始",
        });
      }}
      pcListItem={listPension.map((ele, index) => {
        return (
          <ListSingleLineD
            key={ele?.id}
            label={ele?.nenkinmei}
            selected={selected?.id === ele?.id}
            onClick={() => {
              handledChangeActive(ele);
            }}
          />
        );
      })}
      selectedItemList={[
        {
          isEmptyHide: true,
          label: getLabel("nenkinmei"),
          value: selected?.nenkinmei,
        },
        {
          isEmptyHide: true,
          label: getLabel("jyukyuugaku"),
          value:
            formatNumber(selected?.jyukyuugaku) &&
            formatNumber(selected?.jyukyuugaku) + "円", //** TODO: Using getLable when 円 is defined in src\assets\amplify\vocabularies\index.js*/
        },
        {
          isEmptyHide: true,
          label: getLabel("nenkinshurui"),
          value: selected?.nenkinshurui,
        },
        {
          isEmptyHide: true,
          label: getLabel("bikou"),
          value: selected?.bikou,
        },
      ]}
      selectedItemLabel={selected?.nenkinmei}
      onDelete={() => deleteData(selected)}
      onEdit={() => {
        navigate(selected?.id + "/edit");
        window.gtag("event", "Change", {
          event_category: "Edit",
          event_label: "年金変更開始",
        });
      }}
      isLoaded={isLoaded}
    />
  );
}

export default PensionPage;
