import { DataStore } from "aws-amplify";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LeavesCreditcard } from "../models";
import { ListViewDetail } from "../page-components";
import { useInitializePage } from "../utils/CommonUtils";
import { isShare, queryData } from "../utils/ShareUtils";

function CardViewPage() {
  const navigate = useInitializePage("creditcard", "title.detail");
  const [cardData, setCardData] = useState({
    creditcardmei: "",
    cardbrand: "",
    cardhakkougaisha: "",
    bikou: "",
  });
  const params = useParams();

  const deleteData = useCallback(async () => {
    await DataStore.delete(cardData);

    navigate(-1, { replace: true });
  }, [cardData, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const data = await queryData(params, LeavesCreditcard);

      if (data?.length > 0) {
        setCardData(data[0]);
      }
    };

    getData();
  }, [params.id]);

  const listLabel = [
    { label: "creditcardmei", value: cardData?.creditcardmei },
    { label: "cardbrand", value: cardData?.cardbrand },
    { label: "cardhakkougaisha", value: cardData?.cardhakkougaisha },
    { label: "bikou", value: cardData?.bikou, isMulti: true },
  ];

  return (
    <ListViewDetail
      label={cardData?.creditcardmei}
      list={listLabel}
      isHideEdit={isShare(params)}
      onDelete={deleteData}
      onEdit={() => {
        navigate("edit");
        window.gtag("event", "Change", {
          event_category: "Edit",
          event_label: "クレジットカード変更開始",
        });
      }}
    />
  );
}

export default CardViewPage;
