/* eslint-disable import/no-anonymous-default-export */
import { Flex, Loader, Text } from "@aws-amplify/ui-react";
import { isMobile } from "react-device-detect";
import { PrimaryButton, PrimaryButtonDisabled } from "../ui-components";
export default function (props) {
  const disableProps = { ...props };
  const isLoading = props?.isLoading;
  const isDisabled = props?.disabled;
  disableProps.onClick = null;

  return isDisabled || isLoading ? (
    <PrimaryButtonDisabled
      className="pointer"
      width="fit-content"
      margin="32px auto"
      overrides={{
        "\u270F\uFE0F Button text": {
          children: (
            <Flex
              direction="row"
              alignItems="center"
              gap={isMobile ? "8px" : "6px"}
            >
              {isLoading && <Loader size="large" />}
              <Text color={isDisabled || isLoading ? "#5C6670" : "white"}>
                {props.label}
              </Text>
            </Flex>
          ),
        },
      }}
      {...disableProps}
    />
  ) : (
    <PrimaryButton
      className="pointer"
      width="fit-content"
      margin="32px auto"
      onClick={props.onClick}
      overrides={{
        "Flex.Text[0]": {
          children: props.label,
        },
      }}
      {...props}
    />
  );
}
