/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState, useCallback, useContext, useMemo } from "react";
import { Header, PCHeader, PCNav, HeaderButton, TabColum, TabEvent, PCTabColum, PCTabEvent, Menu, PCMenu } from "../ui-components";
import { useNavigate } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";
import { useLocation, NavLink } from "react-router-dom";
import logo from "../assets/image/logo.png";
import data from "../assets/image/Data.png";
import will from "../assets/image/Will.png";
import heart from "../assets/image/Heart.png";
import hint from "../assets/image/Hint.png";
import howto from "../assets/image/Howto.png";
import data_active from "../assets/image/Data_active.png";
import will_active from "../assets/image/Will_active.png";
import heart_active from "../assets/image/Heart_active.png";
import hint_active from "../assets/image/Hint_active.png";
import howto_active from "../assets/image/Howto_active.png";

import hamburgerMenu from "../assets/image/HamburgerMenu.png";
import pcHamburgerMenu from "../assets/image/PC_HamburgerMenu.png";
import pcHamburgerMenu_active from "../assets/image/PC_HamburgerMenu_active.png";
import pcClose from "../assets/image/PC_Close.png";
import pcClose_active from "../assets/image/PC_Close_active.png";
import close from "../assets/image/Close.png";
import forward from "../assets/image/forward.png";
import account from "../assets/image/Account.png";
import note from "../assets/image/Note.png";
import share from "../assets/image/Share.png";
import file from "../assets/image/File.png";
import logout from "../assets/image/Logout.png";
import onebadge from "../assets/image/One_badge.png";
import dashboard from "../assets/image/Dashboard.png";
import { Flex, IconArrowBackIos, Text } from "@aws-amplify/ui-react";
import { getLabel } from "../utils/CommonUtils";
import { Auth, API, DataStore } from "aws-amplify";
import { updateLeavesUsers } from "../graphql/mutations";
import { LeavesUsers } from "../models";
import UserContext from "../UserContext";
import { log } from "../utils/Logger";

export default function (props) {
  const [isMin, setIsMin] = useState(false);
  const [isHint, setIsHint] = useState(null);
  const [isBucket, setIsBucket] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isShare, setIsShare] = useState(false);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [hideBack, setHideBack] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const [activeTab, setActiveTab] = useState({});

  const context = useContext(UserContext);

  useEffect(() => {
    setHideBack(location.pathname === "/");
  }, [location.pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsMin(false);
    setIsMenuOpen(false);

    if (location.pathname.startsWith("/data")) {
      setActiveTab("data");
      setIsHint(null);
      setIsBucket(null);
      setIsShare(false);
    } else if (location.pathname.startsWith("/will")) {
      setActiveTab("will");
      setIsHint(null);
      setIsShare(false);
      if (location.pathname === "/will/tobelist") setIsBucket("habit");
      else if (location.pathname === "/will/bucketlist")
        setIsBucket("challenge");
      else setIsBucket(null);
    } else if (location.pathname.startsWith("/history")) {
      setActiveTab("heart");
      setIsHint(null);
      setIsBucket(null);
      setIsShare(false);
    } else if (location.pathname.startsWith("/hint")) {
      setActiveTab("hint");
      setIsHint("colum");
      setIsBucket(null);
      setIsShare(false);
    } else if (location.pathname.startsWith("/event")) {
      setActiveTab("hint");
      setIsHint("event");
      setIsBucket(null);
      setIsShare(false);
    } else if (location.pathname.startsWith("/memo")) {
      setActiveTab("hint");
      setIsHint("memo");
      setIsBucket(null);
      setIsShare(false);
    } else if (location.pathname.startsWith("/howto")) {
      setActiveTab("howto");
      setIsHint(null);
      setIsBucket(null);
      setIsShare(false);
    } else if(location.pathname.startsWith("/other")) {
      if(/(?:^\/other\/invited\/).*?(?=\/tobelist$)/gs.test(location.pathname))
      {
        setIsBucket("habit");
        setIsShare(true);
      }
      else if(/(?:^\/other\/invited\/).*?(?=\/bucketlist$)/gs.test(location.pathname))
      {
        setIsBucket("challenge");
        setIsShare(true);
      }
      else 
      {
        setIsBucket(null);
        setIsShare(false);
      };
    } else {  
      setActiveTab(null);
      setIsHint(null);
      setIsBucket(null);
      setIsShare(false);
    }
  }, [location]);

  // useEffect(() => {

  //   // if (!isMobile) {
  //   //   if (location.pathname !== "/") {
  //   //     setIsMin(true);
  //   //     setIsHome(false);
  //   //   } else {
  //   //     if (window.scrollY <= 135) {
  //   //       setIsMin(false);
  //   //       setIsHome(true);
  //   //     }
  //   //   }
  //   // } else {
  //   // }
  // }, [location]);

  const toggleVisibility = () => {
    if (isMobile && !isTablet) {
      window.scrollY > 550 ? setIsMin(true) : setIsMin(false);
    } else {
      setIsMenuOpen(false);
      window.scrollY > 550 ? setIsMin(true) : setIsMin(false);
    }
  };

  useEffect(() => {
    const getRole = async () => {
      const user = await Auth.currentAuthenticatedUser();
      const groups =
        user.signInUserSession.accessToken.payload["cognito:groups"];
      if (groups && groups.indexOf("Admin") >= 0) {
        setIsAdmin(true);
      }
    };
    getRole();
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const handleTouchMove = useCallback((event) => {
    event.preventDefault();
  }, []);
  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("touchmove", handleTouchMove, { passive: false });
      // document.body.style.overflow = "hidden";
    } else {
      document.removeEventListener("touchmove", handleTouchMove);
      // document.body.style.overflow = "initial";
    }
  }, [isMenuOpen]);

  const SubTabSpace = useCallback(
    (props) => {
      if (location.pathname == "/hint" || location.pathname == "/event") {
        return <div style={{ height: "40px" }}></div>;
      } else {
        return null;
      }
    },
    [location]
  );
  const Breadcrumb = useCallback(() => {
    const breadCrumbs = props.breadcrumb();
    return null;
    return (
      <Flex direction="row" gap="0px">
        {breadCrumbs.map(({ match, breadcrumb }, index) => {
          if (!isMobile && !isTablet && match.pattern.path.endsWith(":id") && !breadcrumb.props.children) {
            return null;
          }
          const breadcrumbName = getLabel(
            match.pattern.path.endsWith("/:id")
              ? "shousai"
              : match.pattern.path.endsWith("/edit")
              ? "henshu"
              : match.pattern.path.endsWith("/add")
              ? "shinkitsuika"
              : breadcrumb.props.children
          );
          return (
            <span key={match.pathname}>
              {breadCrumbs.length == index + 1 ? <Text>{breadcrumbName}</Text> : <NavLink to={match.pathname}>{breadcrumbName}</NavLink>}
            </span>
          );
        })}
      </Flex>
    );
  }, [location]);

  const signOut = async () => {
    document.removeEventListener("touchmove", handleTouchMove);
    setTimeout(() => {
      log.debug("push logout button");
      Auth.signOut().then(() => {
        // window.history.replaceState("", "", "/");
        // window.location.href = "/";
        navigate("/", { replace: true });
      });
    }, 1000);
    DataStore.save(
      LeavesUsers.copyOf(context.leavesUser, (updated) => {
        updated.loggedin = false;
      })
    );
  };

  const handleBack = () => {
    const listPath = location.pathname.split("/");
    const parentUrl = listPath.slice(0, listPath.length - 1).join("/");
    if (parentUrl === "/other") navigate("/", { replace: true });
    else if (listPath?.includes("memo")) navigate(-1, { replace: true });
    else navigate(parentUrl, { replace: true });
  };

  const willRoute = (cateName = "")=>{
    const splitUrl = location.pathname?.split("/");
    return isShare
      ? `/other/invited/${splitUrl[3]}/${cateName}`
      : `/will/${cateName}`;
  }

  return (
    <>
      {isMobile && !isTablet ? (
        <>
          <div className="header-position">
            <HeaderButton
              className={"header-transition"}
              height={isMin ? "0px" : "60px"}
              paddingTop={isMin ? "0px" : "10px"}
              width={"100vw"}
              overrides={{
                Colum: {
                  onClick: () => {
                    setIsMenuOpen(false);
                    window.open("https://hiraql.tokyu-laviere.co.jp/column", "_blank");
                  },
                  gap: "3px",
                },
                "\u30B3\u30E9\u30E0": {
                  className: "ff-min",
                  lineHeight: "25px",
                },
                "\u3092\u8AAD\u3080": {
                  className: "ff-min",
                  letterSpacing: "3px",
                },
                Note: {
                  onClick: () => {
                    setIsMenuOpen(false);
                    navigate("/");
                  },
                  gap: "3px",
                },
                NOTE: {
                  className: "ff-min",
                  lineHeight: "25px",
                },
                "\u3092\u4F7F\u3046": {
                  className: "ff-min",
                  letterSpacing: "3px",
                },
              }}
            />
            <div
              style={{
                position: "relative",
                width: "100vw",
                height: "fit-content",
              }}
            >
              <IconArrowBackIos
                position="absolute"
                fontSize="25px"
                top="17px"
                left="12px"
                style={{ zIndex: 1 }}
                onClick={handleBack}
                display={hideBack ? "none" : "block"}
              />
              <Header
                width={"100vw"}
                backgroundColor="var(--my-theme-header-footer-color)"
                overrides={{
                  Logo: {
                    height: "50px",
                    width: "auto",
                    top: "3px",
                    left: "calc(100vw/2 - 95px)",
                    src: logo,
                    onClick: () => {
                      setIsMenuOpen(false);
                      navigate("/");
                    },
                  },
                  Menu: {
                    src: isMenuOpen ? close : hamburgerMenu,
                    height: "auto",
                    right: "30px",
                    left: "auto",
                    top: "18px",
                    onClick: () => setIsMenuOpen(!isMenuOpen),
                  },
                }}
              />
            </div>
            <Menu
              className="menu-transition"
              top={isMin ? "60px" : "120px"}
              right={isMenuOpen ? "0px" : "-100vw"}
              width="100vw"
              position="absolute"
              style={{ zIndex: "100" }}
              overrides={{
                ShareList: {
                  onClick: () => {
                    setIsMenuOpen(false);
                    navigate("/other/invite");
                  },
                },
                "\u5BB6\u65CF\u306B\u30CE\u30FC\u30C8\u3092\u5171\u6709": {
                  children: getLabel("invite"),
                },
                ManagementList: {
                  display: "none",
                },
                ContactList: {
                  display: "none",
                },
                FileList: {
                  onClick: () => {
                    setIsMenuOpen(false);
                    navigate("/other/files");
                  },
                },
                File595117348: {
                  children: getLabel("notonotenpufairuichiran"),
                },
                NoteList: {
                  onClick: () => {
                    setIsMenuOpen(false);
                    navigate("/other/invited");
                  },
                },
                AccountList: {
                  onClick: () => {
                    setIsMenuOpen(false);
                    navigate("/other/account");
                  },
                  alignItems: "center",
                },
                Account: {
                  src: account,
                  width: "auto",
                },
                NextA: {
                  src: forward,
                  width: "10px",
                  height: "auto",
                },
                Note: {
                  src: note,
                  width: "auto",
                },
                Share: {
                  src: share,
                  width: "auto",
                },
                File643317500: {
                  src: file,
                  width: "auto",
                },
                NextN: {
                  src: forward,
                  width: "10px",
                  height: "auto",
                },
                NextS: {
                  src: forward,
                  width: "10px",
                  height: "auto",
                },
                NextF: {
                  src: forward,
                  width: "10px",
                  height: "auto",
                },
                LogoutList: {
                  onClick: signOut,
                  style: { userSelect: "none" },
                },
                Logout: {
                  src: logout,
                  width: "auto",
                },
                NextL: {
                  src: forward,
                  width: "10px",
                  height: "auto",
                },
              }}
            />
            {isHint != null ? (
              <TabEvent
                margin="auto"
                width="100%"
                overrides={{
                  TabEvent: {
                    justifyContent: "center",
                  },
                  Enabled: {
                    maxWidth: "125px",
                    position: "initial",
                    onClick: () => navigate("/hint"),
                  },
                  "\u304A\u6C17\u306B\u5165\u308A\u30B3\u30E9\u30E0": {
                    color: isHint === "colum" ? "var(--my-theme-color)" : "rgba(160,160,160,1)",
                  },
                  Selected: {
                    maxWidth: "125px",
                    position: "initial",
                    onClick: () => navigate("/event"),
                  },
                  "\u4E88\u7D04\u6E08\u307F\u30A4\u30D9\u30F3\u30C8": {
                    color: isHint === "event" ? "var(--my-theme-color)" : "rgba(160,160,160,1)",
                  },
                  Enabled2: {
                    maxWidth: "125px",
                    position: "initial",
                    onClick: () => navigate("/memo"),
                  },
                  "\u30E1\u30E2": {
                    color: isHint === "memo" ? "var(--my-theme-color)" : "rgba(160,160,160,1)",
                    children: getLabel("jiyucho"),
                  },
                  Line: {
                    className: "header-transition",
                    position: "absolute",
                    width: "125px",
                    top: "38px",
                    left:
                      isHint === "colum"
                        ? "calc((100vw - 375px) / 2)"
                        : isHint === "event"
                        ? "calc((100vw - 375px) / 2 + 125px)"
                        : "calc((100vw - 375px) / 2 + 250px)",
                  },
                }}
              />
            ) : null}
            {isBucket != null ? (
              <TabColum
                overrides={{
                  marginBottom: "16px",
                  TabColum: {
                    justifyContent: "center",
                  },
                  Enabled: {
                    position: "initial",
                    onClick: () => navigate(willRoute("tobelist")),
                  },
                  "\u304A\u6C17\u306B\u5165\u308A\u30B3\u30E9\u30E0": {
                    children: getLabel("challenge"),
                    color: isBucket === "challenge" ? "var(--my-theme-color)" : "rgba(160,160,160,1)",
                  },
                  Selected: {
                    position: "initial",
                    onClick: () => navigate(willRoute("bucketlist")),
                  },
                  "\u4E88\u7D04\u6E08\u307F\u30A4\u30D9\u30F3\u30C8": {
                    children: getLabel("shuukan"),
                    color: isBucket === "habit" ? "var(--my-theme-color)" : "rgba(160,160,160,1)",
                  },
                  Line: {
                    className: "header-transition",
                    position: "absolute",
                    width: "187px",
                    top: "38px",
                    left: isBucket === "habit" ? "calc(50vw)" : "calc(50vw - 187px)",
                  },
                }}
              />
            ) : null}
          </div>
          <div style={{ height: isHint != null ? "160px" : isBucket != null ? "176px" : "120px" }}></div>
          <Breadcrumb />
        </>
      ) : (
        <>
          <div className="header-position">
            <div width="100vw" style={{ backgroundColor: "var(--my-theme-header-footer-color)" }}>
              <PCHeader
                className={"header-transition"}
                backgroundColor="var(--my-theme-header-footer-color)"
                width={"100vw"}
                maxWidth="1366px"
                margin="auto"
                height={isMin ? "90px" : "150px"}
                top={"0px"}
                overflow="hidden"
                overrides={{
                  Logo: {
                    className: "pointer",
                    src: logo,
                    height: isMin ? "55px" : "70px",
                    width: "auto",
                    top: isMin ? "13px" : "40px",
                    left: "45px",
                    onClick: () => {
                      setIsMenuOpen(false);
                      navigate("/");
                    },
                  },
                  Tab: {
                    left: "auto",
                    top: "auto",
                    bottom: "0px",
                    right: "0px",
                    padding: "0px",
                  },
                  Colum: {
                    className: "pointer tab header-transition",
                    onClick: () => {
                      setIsMenuOpen(false);
                      window.open("https://hiraql.tokyu-laviere.co.jp/column", "_blank");
                    },
                  },
                  "\u30B3\u30E9\u30E0": {
                    className: "ff-min",
                  },
                  "\u3092\u8AAD\u3080": {
                    className: "ff-min",
                  },
                  Note: {
                    className: "pointer tab",
                    onClick: () => {
                      setIsMenuOpen(false);
                      navigate("/");
                    },
                  },
                  NOTE: {
                    className: "ff-min",
                  },
                  "\u3092\u4F7F\u3046": {
                    className: "ff-min",
                  },
                  NoteBody: {
                    gap: "7px",
                    height: "33px",
                  },
                }}
              />
            </div>
            <PCNav
              margin="auto"
              top={"0px"}
              width={"100vw"}
              overrides={{
                Nav: {
                  width: "100vw",
                },
                NvData: {
                  className: "pointer nav" + (activeTab == "data" ? "-active" : ""),
                  onClick: () => {
                    setIsMenuOpen(false);
                    navigate("/data");
                  },
                },
                Data: {
                  width: "auto",
                  src: data_active,
                },
                NvWill: {
                  className: "pointer nav" + (activeTab == "will" ? "-active" : ""),
                  onClick: () => {
                    setIsMenuOpen(false);
                    navigate("/will");
                  },
                },
                Will: {
                  width: "auto",
                  src: will_active,
                },
                NvHistory: {
                  className: "pointer nav" + (activeTab == "heart" ? "-active" : ""),
                  onClick: () => {
                    setIsMenuOpen(false);
                    navigate("/history");
                  },
                },
                History: {
                  width: "auto",
                  src: heart_active,
                },
                NvHintBadge: {
                  className: "pointer nav" + (activeTab == "hint" ? "-active" : ""),
                  onClick: () => {
                    setIsMenuOpen(false);
                    navigate("/hint");
                  },
                },
                Hint: {
                  width: "auto",
                  src: hint_active,
                },
                One_badge: {
                  display: "none",
                  className: "nav-badge",
                  src: onebadge,
                },
                NvHowto: {
                  className: "pointer nav" + (activeTab == "howto" ? "-active" : ""),
                  onClick: () => {
                    setIsMenuOpen(false);
                    navigate("/howto");
                  },
                },
                Howto: {
                  width: "auto",
                  src: howto_active,
                },
                HamburgerMenu: {
                  className: "pointer hamburger",
                  src: isMenuOpen ? pcClose : pcHamburgerMenu,
                  onClick: () => setIsMenuOpen(!isMenuOpen),
                },
                Divider: {
                  style: { borderColor: "#e8e8e8" },
                  opacity: "1",
                },
              }}
            />
            <PCMenu
              className="header-transition"
              top={isMin ? "154px" : "214px"}
              right={isMenuOpen ? "0px" : "-380px"}
              position="absolute"
              style={{ zIndex: "100" }}
              overrides={{
                ShareList: {
                  className: "pointer menu",
                  onClick: () => {
                    setIsMenuOpen(false);
                    navigate("/other/invite");
                  },
                },
                "\u5BB6\u65CF\u306B\u30CE\u30FC\u30C8\u3092\u5171\u6709": {
                  children: getLabel("invite"),
                },
                ManagementList: {
                  display: "none",
                },
                ContactList: {
                  display: "none",
                },
                DashboardList: {
                  display: isAdmin ? "block" : "none",
                },
                FileList: {
                  className: "pointer menu",
                  onClick: () => {
                    setIsMenuOpen(false);
                    navigate("/other/files");
                  },
                },
                File596515768: {
                  children: getLabel("notonotenpufairuichiran"),
                },
                NoteList: {
                  className: "pointer menu",
                  onClick: () => {
                    setIsMenuOpen(false);
                    navigate("/other/invited");
                  },
                },
                AccountList: {
                  className: "pointer menu",
                  onClick: () => {
                    setIsMenuOpen(false);
                    navigate("/other/account");
                  },
                  alignItems: "center",
                },
                DashboardContent: {
                  className: "pointer menu",
                  onClick: () => {
                    setIsMenuOpen(false);
                    navigate("/admin");
                  },
                },
                Account: {
                  src: account,
                  width: "auto",
                },
                NextA: {
                  src: forward,
                  width: "10px",
                  height: "auto",
                },
                Note: {
                  src: note,
                  width: "auto",
                },
                Share: {
                  src: share,
                  width: "auto",
                },
                File596515767: {
                  src: file,
                  width: "auto",
                },
                Dashboard: {
                  src: dashboard,
                  width: "auto",
                },
                NextN: {
                  src: forward,
                  width: "10px",
                  height: "auto",
                },
                NextS: {
                  src: forward,
                  width: "10px",
                  height: "auto",
                },
                NextF: {
                  src: forward,
                  width: "10px",
                  height: "auto",
                },
                NextD: {
                  src: forward,
                  width: "10px",
                  height: "auto",
                },
                LogoutList: {
                  className: "pointer menu",
                  onClick: signOut,
                },
                Logout: {
                  src: logout,
                  width: "auto",
                },
                NextL: {
                  src: forward,
                  width: "10px",
                  height: "auto",
                },
                Divider: {
                  style: { borderColor: "#e8e8e8" },
                },
              }}
            />
            {isHint != null ? (
              <TabEvent
                margin="auto"
                width="100%"
                overrides={{
                  TabEvent: {
                    justifyContent: "center",
                  },
                  Enabled: {
                    className: "pointer",
                    maxWidth: "188px",
                    position: "initial",
                    onClick: () => navigate("/hint"),
                  },
                  "\u304A\u6C17\u306B\u5165\u308A\u30B3\u30E9\u30E0": {
                    color: isHint === "colum" ? "var(--my-theme-color)" : "rgba(160,160,160,1)",
                    fontSize: "15px"
                  },
                  Selected: {
                    className: "pointer",
                    maxWidth: "188px",
                    position: "initial",
                    onClick: () => navigate("/event"),
                  },
                  "\u4E88\u7D04\u6E08\u307F\u30A4\u30D9\u30F3\u30C8": {
                    color: isHint === "event" ? "var(--my-theme-color)" : "rgba(160,160,160,1)",
                    fontSize: "15px"
                  },
                  Enabled2: {
                    className: "pointer",
                    maxWidth: "188px",
                    position: "initial",
                    onClick: () => navigate("/memo"),
                  },
                  "\u30E1\u30E2": {
                    color: isHint === "memo" ? "var(--my-theme-color)" : "rgba(160,160,160,1)",
                    children: getLabel("jiyucho"),
                    fontSize: "15px"
                  },
                  Line: {
                    className: "header-transition",
                    position: "absolute",
                    width: "188px",
                    top: "38px",
                    height: "3px",
                    left:
                      isHint === "colum"
                        ? "calc((100vw - 564px) / 2)"
                        : isHint === "event"
                        ? "calc((100vw - 564px) / 2 + 188px)"
                        : "calc((100vw - 564px) / 2 + 376px)",
                  },
                }}
              />
            ) : null}
            {isBucket != null ? (
              <PCTabColum
                overrides={{
                  TabColum: {
                    justifyContent: "center",
                  },
                  Enabled: {
                    position: "initial",
                    className: "pointer subtab",
                    maxWidth: "188px",
                    onClick: () => navigate(willRoute("tobelist")),
                  },
                  "\u304A\u6C17\u306B\u5165\u308A\u30B3\u30E9\u30E0": {
                    children: getLabel("challenge"),
                    color: isBucket === "challenge" ? "var(--my-theme-color)" : "rgba(160,160,160,1)",
                    fontSize: "15px"
                  },
                  Selected: {
                    position: "initial",
                    className: "pointer subtab",
                    maxWidth: "188px",
                    onClick: () => navigate(willRoute("bucketlist")),
                  },
                  "\u4E88\u7D04\u6E08\u307F\u30A4\u30D9\u30F3\u30C8": {
                    children: getLabel("shuukan"),
                    color: isBucket === "habit" ? "var(--my-theme-color)" : "rgba(160,160,160,1)",
                    fontSize: "15px"
                  },
                  Line: {
                    className: "header-transition",
                    position: "absolute",
                    width: "188px",
                    top: "38px",
                    height: "3px",
                    left: isBucket === "habit" ? "calc(50vw)" : "calc(50vw - 188px)",
                    fontSize: "15px"
                  },
                }}
                width="100vw"
              />
            ) : null}
            <Breadcrumb />
          </div>
          <div style={{ height: isHint != null || (isBucket != null && !isTablet) ? "255px" : isBucket != null && isTablet ? "270px" : "230px" }}></div>
        </>
      )}
    </>
  );
}
