/* eslint-disable import/no-anonymous-default-export */
import { DataStore } from "aws-amplify";
import React, { useContext, useState } from "react";
import { isMobile } from "react-device-detect";
import { JitsubutsuShisanshurui, LeavesJitsubutsuShisan } from "../models";
import {
  ButtonCancelSave,
  SelectField,
  SwapperContent,
  TextField,
  TextFieldMultiline,
} from "../page-components";
import UserContext from "../UserContext";
import { getLabel, isEmpty, useInitializePage } from "../utils/CommonUtils";
import { log } from "../utils/Logger";
import { usePrompt } from "../utils/Prompt";

/*
  shisanmei: String
  shisanshurui: Shisanshurui
  shozaibasho: String
  bikou: String
  _shisanshurui: String
  leavesusersID: ID! @index(name: "byLeavesUsers")
*/
export default function () {
  const navigate = useInitializePage("jitsubutsushisan", "title.add");

  const context = useContext(UserContext);

  const [shisanmei, setShisanmei] = useState("");
  const [shisanshurui, setShisanshurui] = useState("");
  const [shozaibasho, setShozaibasho] = useState("");
  const [bikou, setBikou] = useState("");

  const [shisanmeiError, setShisanmeiError] = useState(null);
  const [shisanshuruiError, setShisanshuruiError] = useState(null);
  const [shozaibashoError, setShozaibashoError] = useState(null);
  const [bikouError, setBikouError] = useState(null);
  const [isAdded, setIsAdded] = useState(false);

  const [update, setUpdate] = useState(false);
  usePrompt((location, action) => {
    if (action == "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  const addData = async () => {
    log.debug(shisanmei + "," + shisanshurui + "," + shozaibasho + "," + bikou);
    let valid = true;
    if (isEmpty(shisanmei)) {
      setShisanmeiError(getLabel("error.required"));
      valid = false;
    } else {
      setShisanmeiError(null);
    }
    if (isEmpty(shisanshurui)) {
      setShisanshuruiError(getLabel("error.required"));
      valid = false;
    } else {
      setShisanshuruiError(null);
    }
    if (valid) {
      //   console.log(context.leavesUser);
      log.debug("save start", shisanmei);
      try {
        setIsAdded(true);
        const saved = await DataStore.save(
          new LeavesJitsubutsuShisan({
            shisanshurui: shisanshurui,
            _shisanshurui: shisanshurui,
            shisanmei: shisanmei,
            shozaibasho: shozaibasho,
            bikou: bikou,
            leavesusersID: context.leavesUser.id,
          }),
        );
        log.debug("save done", saved);
        window.gtag("event", "FirstWriteComplete", {
          event_category: "Edit",
          event_label: "実物資産書込完了",
        });
        navigate(-1, { replace: true });
      } catch (e) {
        alert(e);
      }
    }
  };

  const shisanshuruiList = [];
  shisanshuruiList.push(
    <option key="default" value="">
      {getLabel("list.select")}
    </option>,
  );
  Object.keys(JitsubutsuShisanshurui).forEach((type) => {
    shisanshuruiList.push(
      <option key={type} value={type}>
        {getLabel("JitsubutsuShisanshurui." + type)}
      </option>,
    );
  });

  return (
    <SwapperContent label={getLabel("shinkitsuika")}>
      <SelectField
        isRequired
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("shisanshurui")}
        errorMessage={shisanshuruiError}
        children={<React.Fragment>{shisanshuruiList}</React.Fragment>}
        onChange={(event) => {
          setShisanshurui(event.target.value);
          setUpdate(true);
        }}
      />
      <TextField
        isRequired
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("shisanmei")}
        errorMessage={shisanmeiError}
        onChange={(event) => {
          setShisanmei(event.target.value);
          setUpdate(true);
        }}
      />
      <TextField
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("shozaibasho")}
        errorMessage={shozaibashoError}
        onChange={(event) => {
          setShozaibasho(event.target.value);
          setUpdate(true);
        }}
      />
      <TextFieldMultiline
        className={isMobile ? "" : "maxwidth800"}
        label={getLabel("bikou")}
        errorMessage={bikouError}
        onChange={(event) => {
          setBikou(event.target.value);
          setUpdate(true);
        }}
      />
      <ButtonCancelSave
        cancelOnClick={() => navigate(-1, { replace: true })}
        saveOnClick={() => addData()}
        isDisabled={isAdded}
      />
    </SwapperContent>
  );
}
