import { Text } from '@aws-amplify/ui-react';
import { isMobile, isTablet } from "react-device-detect";

export default function MindTypeCheckCategories(props) {
    if ( isMobile || isTablet ) {
        if (props.page == 1) {
            return(
                <div>
                 <Text
                   fontFamily="Inter"
                   fontSize="20px"
                   fontWeight="700"
                   color="rgba(0,0,0,1)"
                   lineHeight="21px"
                   textAlign="left"
                   display="block"
                   width="300px"
                   height="78px"
                   position="relative"
                   whiteSpace="pre-wrap"
                   top="18px"
                   left="68px"
                 >
                   ディーナーの人生満足尺度
                 </Text>
                </div>
            );
        }
        else if (props.page == 2) {
            return(
                <div>
                 <Text
                   fontFamily="Inter"
                   fontSize="21px"
                   fontWeight="700"
                   color="rgba(0,0,0,1)"
                   lineHeight="21px"
                   textAlign="left"
                   display="block"
                   width="190px"
                   height="40px"
                   position="relative"
                   whiteSpace="pre-wrap"
                   top="13px"
                   left="68px"
                 >
                   ポジティブ感情
                 </Text>
                 <Text
                   fontFamily="Inter"
                   fontSize="11px"
                   color="rgba(0,0,0,1)"
                   lineHeight="11px"
                   textAlign="left"
                   display="block"
                   width="210px"
                   height="30px"
                   position="relative"
                   whiteSpace="pre-wrap"
                   top="-3px"
                   left="70px"
                 >
                   今の気持ちに近いものを選んでください。
                 </Text>
                </div>
            );
        }
        else if (props.page == 3) {
            return(
                <div>
                 <Text
                   fontFamily="Inter"
                   fontSize="21px"
                   fontWeight="700"
                   color="rgba(0,0,0,1)"
                   lineHeight="21px"
                   textAlign="left"
                   display="block"
                   width="190px"
                   height="40px"
                   position="relative"
                   whiteSpace="pre-wrap"
                   top="13px"
                   left="68px"
                 >
                   ネガティブ感情
                 </Text>
                 <Text
                   fontFamily="Inter"
                   fontSize="11px"
                   color="rgba(0,0,0,1)"
                   lineHeight="11px"
                   textAlign="left"
                   display="block"
                   width="210px"
                   height="30px"
                   position="relative"
                   whiteSpace="pre-wrap"
                   top="-3px"
                   left="70px"
                 >
                   今の気持ちに近いものを選んでください。
                 </Text>
                </div>
            );
        }
        else if (props.page == 4) {
           return(
                <div>
                 <Text
                   fontFamily="Inter"
                   fontSize="18px"
                   fontWeight="700"
                   color="rgba(0,0,0,1)"
                   lineHeight="22px"
                   textAlign="left"
                   display="block"
                   width="190px"
                   height="78px"
                   position="relative"
                   whiteSpace="pre-wrap"
                   top="10px"
                   left="68px"
                 >
                   あなたはどのタイプ？<br/>
                   「幸せの４因子」
                 </Text>
                </div>
            );
        }
        else if (props.page == 5) {
           return(
                <div>
                 <Text
                   fontFamily="Inter"
                   fontSize="18px"
                   fontWeight="700"
                   color="rgba(0,0,0,1)"
                   lineHeight="22px"
                   textAlign="left"
                   display="block"
                   width="190px"
                   height="78px"
                   position="relative"
                   whiteSpace="pre-wrap"
                   top="10px"
                   left="68px"
                 >
                   あなたはどのタイプ？<br/>
                   「幸せの４因子」
                 </Text>
                </div>
            );
        }
    }
    else {
       if (props.page == 1) {
           return(
               <div>
                <Text
                  fontFamily="Inter"
                  fontSize="21px"
                  fontWeight="700"
                  color="rgba(0,0,0,1)"
                  lineHeight="21px"
                  textAlign="left"
                  display="block"
                  width="190px"
                  height="78px"
                  position="relative"
                  whiteSpace="pre-wrap"
                  margin-top="20px"
                  margin-left="20px"
                >
                  ディーナーの<br/>
                  人生満足尺度
                </Text>
               </div>
           );
       }
       else if (props.page == 2) {
           return(
               <div>
                <Text
                  fontFamily="Inter"
                  fontSize="21px"
                  fontWeight="700"
                  color="rgba(0,0,0,1)"
                  lineHeight="21px"
                  textAlign="left"
                  display="block"
                  width="190px"
                  height="40px"
                  position="relative"
                  whiteSpace="pre-wrap"
                  margin-top="20px"
                  margin-left="20px"
                >
                  ポジティブ感情
                </Text>
                <Text
                  fontFamily="Inter"
                  fontSize="14px"
                  color="rgba(0,0,0,1)"
                  lineHeight="14px"
                  textAlign="left"
                  display="block"
                  width="190px"
                  height="30px"
                  position="relative"
                  whiteSpace="pre-wrap"
                  margin-top="0px"
                  margin-left="20px"
                >
                  今の気持ちに近いものを<br/>
                  選んでください。
                </Text>
               </div>
           );
       }
       else if (props.page == 3) {
           return(
               <div>
                <Text
                  fontFamily="Inter"
                  fontSize="21px"
                  fontWeight="700"
                  color="rgba(0,0,0,1)"
                  lineHeight="21px"
                  textAlign="left"
                  display="block"
                  width="190px"
                  height="40px"
                  position="relative"
                  whiteSpace="pre-wrap"
                  margin-top="20px"
                  margin-left="20px"
                >
                  ネガティブ感情
                </Text>
                <Text
                  fontFamily="Inter"
                  fontSize="14px"
                  color="rgba(0,0,0,1)"
                  lineHeight="14px"
                  textAlign="left"
                  display="block"
                  width="190px"
                  height="30px"
                  position="relative"
                  whiteSpace="pre-wrap"
                  margin-top="0px"
                  margin-left="20px"
                >
                  今の気持ちに近いものを<br/>
                  選んでください。
                </Text>
               </div>
           );
       }
       else if (props.page == 4) {
          return(
               <div>
                <Text
                  fontFamily="Inter"
                  fontSize="18px"
                  fontWeight="700"
                  color="rgba(0,0,0,1)"
                  lineHeight="22px"
                  textAlign="left"
                  display="block"
                  width="190px"
                  height="78px"
                  position="relative"
                  whiteSpace="pre-wrap"
                  margin-top="20px"
                  margin-left="20px"
                >
                  あなたはどのタイプ？<br/>
                  「幸せの４因子」
                </Text>
               </div>
           );
       }
       else if (props.page == 5) {
          return(
               <div>
                <Text
                  fontFamily="Inter"
                  fontSize="18px"
                  fontWeight="700"
                  color="rgba(0,0,0,1)"
                  lineHeight="22px"
                  textAlign="left"
                  display="block"
                  width="190px"
                  height="78px"
                  position="relative"
                  whiteSpace="pre-wrap"
                  margin-top="20px"
                  margin-left="20px"
                >
                  あなたはどのタイプ？<br/>
                  「幸せの４因子」
                </Text>
               </div>
           );
       }
    }
}
