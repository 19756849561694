import React, { useEffect, useState, useContext } from "react";
import { BannerColum, Colum, SelectMenu } from "../ui-components";
import { Banner, LabelS, Description } from "../page-components";
import Amplify, { DataStore, Predicates } from "aws-amplify";
import { Flex, Text } from "@aws-amplify/ui-react";
import { LeavesHint } from "../models";
import { useInitializePage, getLabel } from "../utils/CommonUtils";
import { useSearchParams } from "react-router-dom";
import UserContext from "../UserContext";
import colum from "../assets/image/colum.jpg";
import { isMobile } from "react-device-detect";
import { log } from "../utils/Logger";

// const defaultRssUrl = "https://hiraql.tokyu-laviere.co.jp/column/rss.xml";
// const rssUrl = "https://www.ubsecure.jp/blog/rss.xml";
const defaultImageUrl = "https://hiraql-note.tokyu-laviere.co.jp/ogp.png";

/*
  url: String!
  title: String
  image: String
  type: String
*/
export default function () {
  const navigate = useInitializePage("hint");
  const context = useContext(UserContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoaded, setIsLoaded] = useState(false);
  const [dataList, setDataList] = useState([]);

  const toColum = (data) => {
    return (
      <React.Fragment key={"fragment" + data.data.id}>
        <Colum
          key={data.data.id}
          width="auto"
          className={isMobile ? "" : "pccomponent"}
          overrides={{
            "Flex.Flex[0].Flex[0].Flex[0]": {
              className: "pointer",
              height: "auto",
              onClick: () => window.open(data.data.url, "_blank"),
            },
            "Flex.Flex[0].Flex[0].Flex[0].Image[0]": {
              src: data.data.image,
            },
            "Flex.Flex[0].Flex[0].Flex[0].Text[0]": {
              children: data.data.title,
            },
            "Flex.Flex[0].Flex[0].Flex[1]": {
              className: "pointer",
              onClick: () => {
                data.menu = !data.menu;
                setDataList(dataList.map((d) => d));
              },
            },
            "Flex.Flex[0].Flex[0].Flex[1].Icon[0]": data.menu
              ? {}
              : {
                  color: "#fff",
                },
          }}
        />
        {data.menu ? (
          <div className={isMobile ? "" : "pccomponent"} style={{ display: "flex", justifyContent: "flex-end" }}>
            <SelectMenu
              key={"menu" + data.data.id}
              className={data.menu ? "colummenu fadein" : "colummenu"}
              width="fit-content"
              opacity="0"
              overrides={{
                "Flex.Flex[0].Text[0]": {
                  children: "お気に入りを解除",
                  color: "red",
                },
                "Flex.Flex[0]": {
                  className: "pointer",
                  onClick: () => {
                    deleteData(data.data);
                  },
                },
              }}
            />
          </div>
        ) : null}
      </React.Fragment>
    );
  };
  const deleteData = async (data) => {
    log.debug("delete data", data);
    await DataStore.delete(data);
    const dsData = await DataStore.query(LeavesHint);
    const list = [];
    dsData
      .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
      .forEach((data) => {
        // list.push(toColum(data));
        list.push({ menu: false, data: data });
      });
    setDataList(list);
  };

  useEffect(async () => {
    const queryUrl = searchParams.get("url");

    const dsData = await DataStore.query(LeavesHint);

    const list = [];
    if (queryUrl != null) {
      const bookmarkUrl = decodeURI(queryUrl);
      const alreadyData = dsData.filter((data) => data.url == bookmarkUrl);
      if (alreadyData.length == 0) {
        const regexShort = /(https:\/\/.*?\/)/g;
        const foundShort = bookmarkUrl.match(regexShort);
        let rssUrl;
        if (foundShort != null) {
          rssUrl = foundShort[0] + "column/rss.xml";
          try {
            await fetch(rssUrl);
          } catch (e) {
            rssUrl = foundShort[0] + "rss.xml";
            try {
              await fetch(rssUrl);
            } catch (e) {
              const regexLong = /(https:\/\/.*\/)/g;
              const foundLong = bookmarkUrl.match(regexLong);
              if (foundLong != null) {
                rssUrl = foundLong[0] + "rss.xml";
                try {
                  await fetch(rssUrl);
                  log.debug("add colum success", bookmarkUrl);
                } catch (e) {
                  rssUrl = null;
                }
              }
            }
          }
        }
        if (rssUrl) {
          const parser = new DOMParser();
          try {
            dsData
              .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
              .forEach((data) => {
                // list.push(toColum(data));
                list.push({ menu: false, data: data });
              });
            await fetch(rssUrl)
              .then((res) => res.text())
              .then((txt) => parser.parseFromString(txt, "text/xml"))
              .then((doc) => doc.querySelectorAll("item"))
              .then(async (items) => {
                const bkItems = Array.from(items).filter((item) => item.querySelector("guid").textContent == bookmarkUrl);
                if (bkItems.length > 0) {
                  const title = bkItems[0].querySelector("title").textContent;
                  const date = new Date(bkItems[0].querySelector("pubDate").textContent);
                  const pubDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
                  const imageElement = parser.parseFromString(bkItems[0].querySelector("description").textContent, "text/xml").querySelector("img");
                  const image = imageElement != null ? imageElement.getAttribute("src") : defaultImageUrl;
                  const category = Array.from(bkItems[0].querySelectorAll("category"))
                    .map((category) => {
                      return category.textContent;
                    })
                    .join(",");

                  const dsData2 = await DataStore.query(LeavesHint);
                  const alreadyData2 = dsData2.filter((data) => data.url == bookmarkUrl);
                  if (alreadyData2.length == 0) {
                    const newData = await DataStore.save(
                      new LeavesHint({
                        url: bookmarkUrl,
                        title: title,
                        image: image,
                        type: category,
                        publishdate: pubDate,
                        leavesusersID: context.leavesUser.id,
                      })
                    );
                    setDataList([{ menu: false, data: newData }, ...list]);
                    setIsLoaded(true);
                  }
                }
              });
          } catch (e) {
            log.debug("rss error", e);
            setDataList(list);
            setIsLoaded(true);
          }
        }
      }
      searchParams.delete("url");
      setSearchParams(searchParams);
    } else {
      dsData
        .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
        .forEach((data) => {
          // list.push(toColum(data));
          list.push({ menu: false, data: data });
        });
      setDataList(list);
      setIsLoaded(true);
    }

    // await DataStore.delete(LeavesHint, Predicates.ALL);
  }, []);
  const Colums = () => {
    const columList = [];
    dataList.forEach((data) => {
      columList.push(toColum(data));
    });
    return columList;
  };
  return (
    <div style={{ marginBottom: "32px", paddingTop: isMobile ? "0px" : "32px" }}>
      <Colums />
      {isLoaded && dataList.length == 0 ? (
        <Description margin="auto" paddingTop={isMobile ? "32px" : "0px"} width="fit-content" className="" text={getLabel("okiniiricolumnashi")} />
      ) : null}
    </div>
  );
}
