import { useCallback, useContext, useEffect } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";
import { I18n } from "aws-amplify";

/**
 * @source https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874
 */
export function useBlocker(Blocker, when = true) {
  const { navigator } = useContext(NavigationContext);
  const blocker = Blocker;

  useEffect(() => {
    if (!when) {
      return;
    }

    const unblock = navigator.block((Transition) => {
      const tx = Transition;
      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it. TODO: Figure out how to re-enable
          // this block if the transition is cancelled for some reason.
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });
    return unblock;
  }, [navigator, blocker, when]);
}

/**
 * @source https://github.com/remix-run/react-router/issues/8139#issuecomment-1021457943
 */
export function usePrompt(message, when = true) {
  const blocker = useCallback(
    (Transition) => {
      const tx = Transition;
      let response;
      if (typeof message === "function") {
        if (tx.location.state && tx.location.state.dialog != undefined) {
          message = (location, action) => {
            if (action === "POP") {
              return true;
            } else {
              if (location.state && !location.state.dialog) {
                return true;
              }
              return I18n.get("prompt");
            }
          };
        }

        response = message(tx.location, tx.action);
        if (typeof response === "string") {
          response = window.confirm(response);
        }
      } else {
        response = window.confirm(message);
      }
      if (response) {
        tx.retry();
      }
    },
    [message]
  );
  return useBlocker(blocker, when);
}
