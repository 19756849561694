import { ListTwolineA, TextButton, PCListSingleLineC, PCListSingleLineCTextButton } from "../ui-components";
import { isMobile } from "react-device-detect";
export default function (props) {
  let formatedData = props.value;
  let multi = false;
  if (props.value && props.value.indexOf("\n") >= 0) {
    multi = true;
  }
  if (props.type == "date") {
    const splitedNengetsu = props?.value?.split("-");
    formatedData = splitedNengetsu ? splitedNengetsu[0] + "年" + parseInt(splitedNengetsu[1]) + "月" + parseInt(splitedNengetsu[2]) + "日" : "";
  }
  if (props.isEmptyHide && !props.value) {
    return <></>;
  } else {
    if (isMobile) {
      return (
        <>
          <ListTwolineA
            width={"auto"}
            overrides={{
              "Flex.Flex[0].Text[0]": {
                children: props.label,
              },
              "Flex.Flex[0].Text[1]": {
                children: formatedData,
                textAlign: "justify",
                className: "word-break",
                whiteSpace: multi ? "break-spaces" : "",
                width: "fit-content",
                maxWidth: "100vw",
              },
            }}
          />
          {props.buttonLabel ? (
            <TextButton
              className="pointer"
              width="fit-content"
              margin="0 0 0 auto"
              top="-10px"
              padding="0px 12px"
              onClick={props.buttonOnClick}
              overrides={{
                "Flex.Text[0]": {
                  children: props.buttonLabel,
                },
              }}
            />
          ) : null}
        </>
      );
    } else {
      return props.buttonLabel ? (
        <PCListSingleLineCTextButton
          width={"auto"}
          className="pccomponent"
          margin="0 0 16px"
          overrides={{
            Category: {
              children: props.label,
            },
            Item: {
              children: formatedData,
              textAlign: "justify",
              className: "word-break",
              whiteSpace: multi ? "break-spaces" : "",
              width: "fit-content",
              maxWidth: "100vw",
            },
            PC_TextButton531329624: {
              className: "pointer",
              onClick: props.buttonOnClick,
              overrides: {
                "Text Button": {
                  children: props.buttonLabel,
                },
              },
            },
            PC_TextButton574415494: {
              display: "none",
            },
          }}
        />
      ) : (
        <PCListSingleLineC
          width={"auto"}
          className="pccomponent"
          overrides={{
            Category: {
              children: props.label,
            },
            Item: {
              children: formatedData,
              textAlign: "justify",
              className: "word-break",
              whiteSpace: multi ? "break-spaces" : "",
              width: "fit-content",
              maxWidth: "100vw",
            },
          }}
        />
      );
    }
  }
}
