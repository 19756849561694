import { API, Auth, DataStore, Storage } from "aws-amplify";
import * as Queries from "../graphql/queries";
import { LeavesSharedInfo, LeavesShareInfo } from "../models";
import { log } from "../utils/Logger";
import { v4 as uuidv4 } from "uuid";
import Hashids from "hashids";
const hashids = new Hashids("hiraql");

export const queryDatas = async (params, model) => {
  if (params.shareid) {
    const modelName = model.name;
    const sharedInfo = await DataStore.query(LeavesSharedInfo, (c) => c.id("eq", params.shareid));
    if (sharedInfo.length > 0) {
      const shareInfo = await getGraphqlQuery("getLeavesShareInfo", sharedInfo[0].shareInfoId);

      if (shareInfo) {
        // シェア対象に該当テーブルが含まれているかをチェック
        if (shareInfo.target.filter((t) => t.dataName == modelName).length > 0) {
          const list = await listGraphqlQuery("list" + modelName + "s", { leavesusersID: { eq: shareInfo.leavesusersID } });

          // 添付ファイル取得のための処理
          let identityid = undefined;
          if (
            list.length > 0 &&
            (modelName === "LeavesTaisetsunishitaihitohenomessage" ||
              modelName === "LeavesYuigonshonitsuite" ||
              modelName === "LeavesJibunshi" ||
              modelName === "LeavesKakeizu" ||
              modelName === "LeavesLifeFuture" ||
              modelName === "LeavesShuukan")
          ) {
            const session = await Auth.currentSession();
            let apiName = "AdminQueries";
            let path = "/getUser";
            let params = {
              queryStringParameters: {
                username: list[0].owner,
              },
              headers: {
                "Content-Type": "application/json",
                Authorization: session.getAccessToken().getJwtToken(),
              },
            };
            const user = await API.get(apiName, path, params);
            identityid = user.UserAttributes.filter((a) => a.Name === "profile")[0].Value;
          }

          return list.map((i) => {
            return { ...i, identityid: identityid };
          });
        }
      }
    }
  } else {
    return await DataStore.query(model);
  }

  return [];
};
export const queryData = async (params, model) => {
  if (params.shareid) {
    const modelName = model.name;
    const sharedInfo = await DataStore.query(LeavesSharedInfo, (c) => c.id("eq", params.shareid));
    if (sharedInfo.length > 0) {
      const shareInfo = await getGraphqlQuery("getLeavesShareInfo", sharedInfo[0].shareInfoId);
      const user = await getGraphqlQuery("getLeavesUsers", shareInfo?.leavesusersID);
      // シェア対象に該当テーブルが含まれているかをチェック
      if (shareInfo && user && (shareInfo.target.filter((t) => t.dataName == modelName).length > 0)) {
        let id = "";
        if (params.id) id = params.id;
        else {
          if (user[`leavesUsers${modelName}Id`]) id = user[`leavesUsers${modelName}Id`];
          else id = user[`leavesUsers${modelName}sId`];
        }
        const data = await getGraphqlQuery("get" + modelName, id);
        // 添付ファイル取得のための処理
        let identityid = undefined;
        if (
          data &&
          (modelName === "LeavesTaisetsunishitaihitohenomessage" ||
            modelName === "LeavesYuigonshonitsuite" ||
            modelName === "LeavesJibunshi" ||
            modelName === "LeavesKakeizu" ||
            modelName === "LeavesLifeFuture" ||
            modelName === "LeavesShuukan")
        ) {
          const session = await Auth.currentSession();
          let apiName = "AdminQueries";
          let path = "/getUser";
          let params = {
            queryStringParameters: {
              username: data.owner,
            },
            headers: {
              "Content-Type": "application/json",
              Authorization: session.getAccessToken().getJwtToken(),
            },
          };
          const user = await API.get(apiName, path, params);
          identityid = user.UserAttributes.filter((a) => a.Name === "profile")[0].Value;
        }

        return data ? [{ ...data, identityid: identityid }] : [];
        // }
      }
    }
  } else {
    if (params.id) return await DataStore.query(model, (c) => c.id("eq", params.id));
    else return await DataStore.query(model);
  }

  return [];
};

export const queryDataNonList = async (params, model) => {
  if (params.shareid) {
    const modelName = model.name;
    const sharedInfo = await DataStore.query(LeavesSharedInfo, (c) => c.id("eq", params.shareid));
    if (sharedInfo.length > 0) {
      const shareInfo = await getGraphqlQuery("getLeavesShareInfo", sharedInfo[0].shareInfoId);
      if (shareInfo) {
        const user = await getGraphqlQuery("getLeavesUsers", shareInfo?.leavesusersID);
        if (user) {
          if (user[modelName]) return [user[modelName]];
          else return [user[modelName + "s"]];
        }
      }
    }
  } else {
    return await DataStore.query(model);
  }

  return [];
};

export const isShare = (params) => {
  return params.shareid != undefined;
};

export const listObjects = async (params, model, item) => {
  const modelName = model?.name;
  if (params?.shareid) {
    const url = `private/${item?.identityid}/${modelName}/${item?.id}/`;
    try {
      await getObject(url);
      return [{ key: url }];
    } catch {
      return [];
    }
  } else {
    const list = await Storage.list(`${modelName}/${item?.id}/`, {
      level: "private",
    });
    return list;
  }
};
export const getObject = async (path) => {
  if (path?.endsWith("/")) {
    const session = await Auth.currentSession();
    //
    let params = {
      body: {
        path: path,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: session.getAccessToken().getJwtToken(),
      },
    };
    const response = await API.post("AdminQueries", "/getSignedUrl", params);
    return {
      fileName: getFileName(response.key),
      url: response.url,
    };
  } else {
    return {
      fileName: getFileName(path),
      url: await Storage.get(path, {
        level: "private",
      }),
    };
  }
};
const getFileName = (filename) => {
  var parts = filename?.split("/");
  return parts ? parts[parts.length - 1]: "";
};

export const generateUrl = async (id) => {
  const datas = await DataStore.query(LeavesShareInfo, (c) => c.id("eq", id));
  const data = datas[0];
  const newid = uuidv4().replaceAll("-", "");
  var encoded = hashids.encodeHex(newid);

  log.debug("data", data);

  const now = await getServerDate();
  now.setDate(now.getDate() + 7); // URLは7日間有効
  const nowstr = now.getFullYear() + "-" + ("0" + (now.getMonth() + 1)).slice(-2) + "-" + ("0" + now.getDate()).slice(-2);

  DataStore.save(
    LeavesShareInfo.copyOf(data, (updated) => {
      updated.shareId = newid;
      updated.shareIdExpiredDate = nowstr;
    })
  );
  log.debug("generate shareid", newid);
  return { url: window.location.origin + "/other/invited?id=" + encoded, expiredDate: nowstr };
};
export const getShareMessage = (url, expiredDate, name) => {
  const splitedNengetsu = expiredDate.split("-");
  const formatedNengetsu = splitedNengetsu[0] + "年" + parseInt(splitedNengetsu[1]) + "月" + parseInt(splitedNengetsu[2]) + "日";

  return (
    (name ? `${name}さん\n` : "") +
    "オンラインノート「HIRAQL NOTE」に招待します。\n" +
    "以下招待URLからアカウント作成またはログインをすると、私が共有したノートを確認できるようになります。\n" +
    "\n" +
    " ■招待URL\n" +
    `${url}\n` +
    `有効期限：${formatedNengetsu}\n` +
    "\n" +
    "■ノート参照の手順\n" +
    "①上記URLにアクセスし、アカウント作成またはログインをする\n" +
    "②メニュー「共有されたノート一覧」を開く\n" +
    "③共有されたノートが表示され、招待者が設定した公開日以降、読むことができるようになります\n" +
    "\n" +
    "■HIRAQL NOTEとは\n" +
    "自分のデータや意思を整理し、記していくためのオンラインノートです。人生でやりたいことの具体的なプランを立てたり、ヒントコラムやイベントを通じて感じたことを生活に持ち帰り、記録していくことができます。\n"
  );
};

export const listGraphqlQuery = async (query, filter) => {
  // console.log("query", query);
  let result = await API.graphql({
    query: Queries[query],
    variables: { filter: filter },
    authMode: "AWS_IAM",
  });
  const items = result.data[query].items;

  while (result.data[query].nextToken) {
    result = await API.graphql({
      query: Queries[query],
      variables: { filter: filter, nextToken: result.data[query].nextToken },
      authMode: "AWS_IAM",
    });
    result.data[query].items.forEach((i) => items.push(i));
  }
  return items.filter((i) => !i._deleted);
};
export const getGraphqlQuery = async (query, id) => {
  // console.log("query", query);
  let result = await API.graphql({
    query: Queries[query],
    variables: { id: id },
    authMode: "AWS_IAM",
  });
  const item = result.data[query];
  return item && item._deleted ? null : item;
};

export const isShareAccepted = async (id) => {
  const sharedInfo = await listGraphqlQuery("listLeavesSharedInfos", { shareInfoId: { eq: id } });
  return sharedInfo.length > 0;
};

export const getServerDate = async () => {
  const response = await fetch(window.location.origin, {
    method: "HEAD",
  });
  return new Date(response.headers.get("Date"));
};
