import { DataStore } from "aws-amplify";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { LeavesHosyousaimu, LeavesLoankariirekin } from "../models";
import { ListField } from "../page-components";
import {
  getLabel,
  isEmpty,
  isNotEmpty,
  isNotEmptyDate,
  isInvalidDateFormat,
  isValidNumberFormat,
  useInitializePage,
} from "../utils/CommonUtils";
import { TANPONOUMU, useDatalist } from "../utils/DatalistUtils";
import { usePrompt } from "../utils/Prompt";

function LoanEditPage() {
  const navigate = useInitializePage("loankariirekin", "title.update");
  const loanRef = useRef();
  const hosyoRef = useRef();
  const [isHosyo, setIsHosyo] = useState(false);
  const [loanData, setLoanData] = useState({
    kariiresaki: "",
    kariiresakinorenrakusaki: "",
    kariirebi: "",
    kariiregaku: "",
    hensaihouhou: "",
    kariirezandaka: "",
    kariirezandakakisaibi: "",
    kariiremokuteki: "",
    bikou: "",
  });
  const [hosyoData, setHosyoData] = useState({
    syusaimusya: "",
    syusaimusyanorenrakusaki: "",
    hosyoushitahi: "",
    hosyoushitakingaku: "",
    saikensya: "",
    saikensyanorenrakusaki: "",
    bikou: "",
  });
  const [loanDataError, setLoanDataError] = useState({
    kariiresakiError: "",
    kariirebiError: "",
    kariiregakuError: "",
    kariirezandakakisaibiError: "",
    kariirezandakaError: "",
  });
  const [hosyoDataError, setHosyoDataError] = useState({
    syusaimusyaError: "",
    hosyoushitahiError: "",
    hosyoushitakingakuError: "",
  });
  const params = useParams();
  const [update, setUpdate] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [TanponoumuDataList, setTanponoumu, getTanponoumu, hasErrorTanponoumu] =
    useDatalist({
      list: TANPONOUMU,
      dataUpdateHook: setUpdate,
      custemField: {
        value: "ARU",
        placeHolder: getLabel("aru.placeholder"),
      },
    });
  usePrompt((location, action) => {
    if (action === "POP") {
      return true;
    } else {
      return getLabel("prompt");
    }
  }, update);

  useEffect(() => {
    const getData = async () => {
      const dataLoan = await DataStore.query(LeavesLoankariirekin, (c) =>
        c.id("eq", params.id),
      );
      if (dataLoan?.length > 0) {
        loanRef.current = dataLoan[0];
        setLoanData(dataLoan[0]);
        setTanponoumu(dataLoan[0].tanponoumu);
        setIsHosyo(false);
        return;
      }
      const dataHosy = await DataStore.query(LeavesHosyousaimu, (c) =>
        c.id("eq", params.id),
      );
      if (dataHosy?.length > 0) {
        hosyoRef.current = dataHosy[0];
        setHosyoData(dataHosy[0]);
        setIsHosyo(true);
      }
    };
    getData();
    window.scrollTo(0, 0);
  }, [params, params.id]);

  const loanListField = useMemo(() => {
    return [
      {
        type: "textField",
        label: "kariiresaki",
        isRequire: true,
        placeHolder: "kinyuukikanmeinado",
        value: loanData?.kariiresaki,
        hasError: isNotEmpty(loanDataError?.kariiresakiError),
        errorMessage: loanDataError?.kariiresakiError,
      },
      {
        type: "textArea",
        label: "kariiresakinorenrakusaki",
        value: loanData?.kariiresakinorenrakusaki,
      },
      {
        type: "textField",
        label: "kariirebi",
        typeInput: "date",
        value: loanData?.kariirebi,
        hasError: isNotEmpty(loanDataError?.kariirebiError),
        errorMessage: loanDataError?.kariirebiError,
      },
      {
        type: "textField",
        label: "kariiregaku",
        typeInput: "number",
        maxLength: 100,
        value: loanData?.kariiregaku,
        hasError: isNotEmpty(loanDataError?.kariiregakuError),
        errorMessage: loanDataError?.kariiregakuError,
      },
      {
        type: "textField",
        label: "hensaihouhou",
        value: loanData?.hensaihouhou,
        placeHolder: "hutsuuyokinnado",
      },
      {
        type: "dataList",
        child: <TanponoumuDataList label="tanponoumu" />,
        hasError: hasErrorTanponoumu,
      },
      {
        type: "textField",
        label: "kariirezandaka",
        typeInput: "number",
        maxLength: 100,
        value: loanData?.kariirezandaka,
        hasError: isNotEmpty(loanDataError?.kariirezandakaError),
        errorMessage: loanDataError?.kariirezandakaError,
      },
      {
        type: "textField",
        label: "kariirezandakakisaibi",
        typeInput: "date",
        value: loanData?.kariirezandakakisaibi,
        hasError: isNotEmpty(loanDataError?.kariirezandakakisaibiError),
        errorMessage: loanDataError?.kariirezandakakisaibiError,
      },
      {
        type: "textField",
        label: "kariiremokuteki",
        value: loanData?.kariiremokuteki,
        placeHolder: "jyuutakuloannado",
      },
      { type: "textArea", label: "bikou", value: loanData?.bikou },
    ];
  }, [
    hasErrorTanponoumu,
    loanData?.bikou,
    loanData?.hensaihouhou,
    loanData?.kariirebi,
    loanData?.kariiregaku,
    loanData?.kariiremokuteki,
    loanData?.kariiresaki,
    loanData?.kariiresakinorenrakusaki,
    loanData?.kariirezandaka,
    loanData?.kariirezandakakisaibi,
    loanDataError?.kariirebiError,
    loanDataError?.kariiregakuError,
    loanDataError?.kariiresakiError,
    loanDataError?.kariirezandakaError,
    loanDataError?.kariirezandakakisaibiError,
  ]);

  const hosyoListField = useMemo(() => {
    return [
      {
        type: "textField",
        label: "syusaimusya",
        isRequire: true,
        value: hosyoData?.syusaimusya,
        placeHolder: "anatagahosyousitahitononamae",
        hasError: isNotEmpty(hosyoDataError?.syusaimusyaError),
        errorMessage: hosyoDataError?.syusaimusyaError,
      },
      {
        type: "textArea",
        label: "syusaimusyanorenrakusaki",
        value: hosyoData?.syusaimusyanorenrakusaki,
      },
      {
        type: "textField",
        label: "hosyoushitahi",
        typeInput: "date",
        value: hosyoData?.hosyoushitahi,
        hasError: isNotEmpty(hosyoDataError?.hosyoushitahiError),
        errorMessage: hosyoDataError?.hosyoushitahiError,
      },
      {
        type: "textField",
        label: "hosyoushitakingaku",
        typeInput: "number",
        maxLength: 100,
        value: hosyoData?.hosyoushitakingaku,
        hasError: isNotEmpty(hosyoDataError?.hosyoushitakingakuError),
        errorMessage: hosyoDataError?.hosyoushitakingakuError,
      },
      {
        type: "textField",
        label: "saikensya",
        placeHolder: "okanewokashitahitononamaeyakikanmei",
        value: hosyoData?.saikensya,
      },
      {
        type: "textArea",
        label: "saikensyanorenrakusaki",
        value: hosyoData?.saikensyanorenrakusaki,
      },
      {
        type: "textArea",
        label: "bikou",
        value: hosyoData?.bikou,
      },
    ];
  }, [
    hosyoData?.bikou,
    hosyoData?.hosyoushitahi,
    hosyoData?.hosyoushitakingaku,
    hosyoData?.saikensya,
    hosyoData?.saikensyanorenrakusaki,
    hosyoData?.syusaimusya,
    hosyoData?.syusaimusyanorenrakusaki,
    hosyoDataError?.hosyoushitahiError,
    hosyoDataError?.hosyoushitakingakuError,
    hosyoDataError?.syusaimusyaError,
  ]);

  const categoryField = useMemo(() => {
    if (isHosyo) {
      return hosyoListField;
    } else {
      return loanListField;
    }
  }, [hosyoListField, isHosyo, loanListField]);

  const listField = [
    {
      type: "ratioGroup",
      label: "torokunaiyo",
      value: isHosyo ? "hosyousaimu" : "loankariirekin",
      isDisabled: true,
      options: [
        {
          id: 0,
          text: getLabel("loankariirekin"),
          value: "loankariirekin",
        },
        {
          id: 1,
          text: getLabel("hosyousaimu"),
          value: "hosyousaimu",
        },
      ],
    },
    ...categoryField,
  ];

  const updateLoanData = useCallback(async () => {
    let valid = true;
    const dateFormatCheckKariirebi = isInvalidDateFormat(loanData?.kariirebi);
    const dateFormatCheckKariirezandakakisaibi = isInvalidDateFormat(
      loanData?.kariirezandakakisaibi,
    );
    const validNumberKariiregaku = isValidNumberFormat(loanData?.kariiregaku);
    const validNumberKariirezandaka = isValidNumberFormat(
      loanData?.kariirezandaka,
    );
    const error = { ...loanDataError };
    if (isEmpty(loanData?.kariiresaki)) {
      error.kariiresakiError = getLabel("error.required");
      valid = false;
    } else {
      error.kariiresakiError = "";
    }
    if (dateFormatCheckKariirebi) {
      error.kariirebiError = getLabel("error.dateformat");
      valid = false;
    } else {
      error.kariirebiError = "";
    }
    if (dateFormatCheckKariirezandakakisaibi) {
      error.kariirezandakakisaibiError = getLabel("error.dateformat");
      valid = false;
    } else {
      error.kariirezandakakisaibiError = "";
    }
    if (!validNumberKariiregaku) {
      error.kariiregakuError = getLabel("error.number");
      valid = false;
    } else {
      error.kariiregakuError = "";
    }
    if (!validNumberKariirezandaka) {
      error.kariirezandakaError = getLabel("error.number");
      valid = false;
    } else {
      error.kariirezandakaError = "";
    }
    if (hasErrorTanponoumu()) valid = false;

    setLoanDataError(error);
    if (valid) {
      setIsUpdated(true);
      await DataStore.save(
        LeavesLoankariirekin.copyOf(loanRef.current, (updated) => {
          updated.kariiresaki = loanData?.kariiresaki;
          updated.kariiresakinorenrakusaki = loanData?.kariiresakinorenrakusaki;
          updated.kariirebi = isNotEmptyDate(loanData?.kariirebi)
            ? loanData?.kariirebi
            : null;
          updated.kariiregaku = loanData?.kariiregaku;
          updated.hensaihouhou = loanData?.hensaihouhou;
          updated.tanponoumu = getTanponoumu();
          updated.kariirezandaka = loanData?.kariirezandaka;
          updated.kariirezandakakisaibi = isNotEmptyDate(
            loanData?.kariirezandakakisaibi,
          )
            ? loanData?.kariirezandakakisaibi
            : null;
          updated.kariiremokuteki = loanData?.kariiremokuteki;
          updated.bikou = loanData?.bikou;
        }),
      );
      navigate(-1, { replace: true });
    }
  }, [getTanponoumu, hasErrorTanponoumu, loanData, loanDataError, navigate]);

  const updateHosyoData = useCallback(async () => {
    let valid = true;
    const dateFormatCheckHosyoushitahi = isInvalidDateFormat(
      hosyoData?.hosyoushitahi,
    );
    const validNumberHosyoushitakingaku = isValidNumberFormat(
      hosyoData?.hosyoushitakingaku,
    );
    const error = { ...hosyoDataError };
    if (isEmpty(hosyoData?.syusaimusya)) {
      error.syusaimusyaError = getLabel("error.required");
      valid = false;
    } else {
      error.syusaimusyaError = "";
    }
    if (dateFormatCheckHosyoushitahi) {
      error.hosyoushitahiError = getLabel("error.dateformat");
      valid = false;
    } else {
      error.hosyoushitahiError = "";
    }
    if (!validNumberHosyoushitakingaku) {
      error.hosyoushitakingakuError = getLabel("error.number");
      valid = false;
    } else {
      error.hosyoushitakingakuError = "";
    }

    setHosyoDataError(error);
    if (valid) {
      setIsUpdated(true);
      await DataStore.save(
        LeavesLoankariirekin.copyOf(hosyoRef.current, (updated) => {
          updated.syusaimusya = hosyoData?.syusaimusya;
          updated.syusaimusyanorenrakusaki =
            hosyoData?.syusaimusyanorenrakusaki;
          updated.hosyoushitahi = isNotEmptyDate(hosyoData?.hosyoushitahi)
            ? hosyoData?.hosyoushitahi
            : null;
          updated.hosyoushitakingaku = hosyoData?.hosyoushitakingaku;
          updated.saikensya = hosyoData?.saikensya;
          updated.saikensyanorenrakusaki = hosyoData?.saikensyanorenrakusaki;
          updated.bikou = hosyoData?.bikou;
        }),
      );
      navigate(-1, { replace: true });
    }
  }, [hosyoData, hosyoDataError, navigate]);

  const updateData = useCallback(async () => {
    if (isHosyo) {
      updateHosyoData();
    } else {
      updateLoanData();
    }
  }, [isHosyo, updateHosyoData, updateLoanData]);

  return (
    <ListField
      label={getLabel("button.update")}
      list={listField}
      setState={isHosyo ? setHosyoData : setLoanData}
      setUpdate={setUpdate}
      onSave={updateData}
      onCancel={() => navigate(-1, { replace: true })}
      isDisabled={isUpdated}
    />
  );
}

export default LoanEditPage;
